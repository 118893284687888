import * as fromBirthDateActionCreator from "../action-creator/birth-date.action-creator";
import * as fromBirthDateActions from "../action/birth-date.action";
import * as fromDeathDateActions from "../action/death-action"

export interface DeathDateListItemState {
    mother_name_mar: string;
    father_name: string;
    child_fname_mar:string;
    gender1:string;
    Death_date:number;
    registration_no:number;
    
    
}  

export interface DeathDateListState {
    DeathDateList: Array<DeathDateListItemState>;
}

export const initialState: DeathDateListState = {
    DeathDateList: []
};

export function reducer(
    state = initialState,
    action: fromDeathDateActions.DEATHDateAction
): DeathDateListState {
    switch (action.type) {
        case fromDeathDateActions.GET_DEATH_DATE_LIST: {
            
            return { ...state }
        }
        case fromDeathDateActions.GET_DEATH_DATE_LIST_FAIL: {
    
            return { ...state }
        }
        case fromDeathDateActions.GET_DEATH_DATE_LIST_SUCCESS: {
            
            return { ...state, DeathDateList: action.payload.result, }
        }
        default: {
            return { ...state }
        }
    }

}


export const getDeathDateList = (state: DeathDateListState) => state.DeathDateList;
