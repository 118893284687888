import * as fromGroup from '../action/group.action';

export interface GroupState {

    serverErrorMessage: string;

    ChatPaginationCount: number;
    getGroupDetailsResult: {};
    getGroupChatArray: {}[];
    getGroupDetailsStatusCode: number;
    getGroupDetailsSuccessMessage: string;
    isGetGroupDetailsLoading: boolean;
    isGetGroupDetailsLoaded: boolean;
    
    getAllGroupsResult: {};
    getAllGroupsStatusCode: number;
    getAllGroupsSuccessMessage: string;
    isGetAllGroupsLoading: boolean;
    isGetAllGroupsLoaded: boolean;

    acceptPublicGroupInvitationResult: {};
    acceptPublicGroupInvitationStatusCode: number;
    acceptPublicGroupInvitationSuccessMessage: string;
    isAcceptPublicGroupInvitationLoading: boolean;
    isAcceptPublicGroupInvitationLoaded: boolean;

    createMessageResult: {};
    createMessageStatusCode: number;
    createMessageSuccessMessage: string;
    isCreateMessageLoading: boolean;
    isCreateMessageLoaded: boolean;

    getAllUserGroupsResult: {};
    getAllUserGroupsStatusCode: number;
    getAllUserGroupsSuccessMessage: string;
    isGetAllUserGroupsLoading: boolean;
    isGetAllUserGroupsLoaded: boolean;

}

export const initialState: GroupState = {

    serverErrorMessage: null,
    
    ChatPaginationCount: 0,
    getGroupDetailsResult: {},
    getGroupChatArray: [],
    getGroupDetailsStatusCode: 0,
    getGroupDetailsSuccessMessage: null,
    isGetGroupDetailsLoading: false,
    isGetGroupDetailsLoaded: false,
    
    getAllGroupsResult: {},
    getAllGroupsStatusCode: 0,
    getAllGroupsSuccessMessage: null,
    isGetAllGroupsLoading: false,
    isGetAllGroupsLoaded: false,

    acceptPublicGroupInvitationResult: {},
    acceptPublicGroupInvitationStatusCode: 0,
    acceptPublicGroupInvitationSuccessMessage: null,
    isAcceptPublicGroupInvitationLoading: false,
    isAcceptPublicGroupInvitationLoaded: false,

    createMessageResult: {},
    createMessageStatusCode: 0,
    createMessageSuccessMessage: null,
    isCreateMessageLoading: false,
    isCreateMessageLoaded: false,

    getAllUserGroupsResult: {},
    getAllUserGroupsStatusCode: 0,
    getAllUserGroupsSuccessMessage: null,
    isGetAllUserGroupsLoading: false,
    isGetAllUserGroupsLoaded: false

}

export function reducer(
  state = initialState,
  action: fromGroup.GroupAction
):
  GroupState {
  switch (action.type) {

    case fromGroup.GET_GROUP_DETAILS: {
      return {
        ...state,
        isGetGroupDetailsLoading: true,
        isGetGroupDetailsLoaded: false,
        getGroupDetailsSuccessMessage: null,
      };
    }
    case fromGroup.GET_GROUP_DETAILS_SUCCESS: {
      let statusCode = action.payload.code;
      let getGroupDetailsResult = state.getGroupDetailsResult;
      let getGroupChatArray = state.getGroupChatArray;//by vinita
      let getGroupDetailsSuccessMessage = state.getGroupDetailsSuccessMessage;
      let getGroupDetailsStatusCode = statusCode;
      let ChatPaginationCount = state.ChatPaginationCount;
      let data = [];
      switch (statusCode) {
        case 200:
          if(ChatPaginationCount == 0){
            getGroupDetailsResult = action.payload.result;
            getGroupChatArray = getGroupDetailsResult["chatDetails"];
            getGroupDetailsSuccessMessage = action.payload.message;
          }
         else{
          data = action.payload.result['chatDetails'];
          getGroupChatArray = [...getGroupChatArray, ...data];
          // getMerchantDetailsOfListing =  action.payload.result['groupDetails'];
         }
          break;

        case 500:
          getGroupDetailsSuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        isGetGroupDetailsLoading: false,
        isGetGroupDetailsLoaded: true,
        getGroupDetailsResult,
        getGroupChatArray,
        getGroupDetailsSuccessMessage,
        getGroupDetailsStatusCode
      };
    }
    case fromGroup.GET_GROUP_DETAILS_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isGetGroupDetailsLoading: false,
        isGetGroupDetailsLoaded: true,
      };
    }

    case fromGroup.SET_PAGINATION_COUNT_CHAT: {
      let ChatPaginationCount = action.payload.ChatPaginationCount;
      let getGroupDetailsResult = state.getGroupDetailsResult;
      if (ChatPaginationCount == 0) {

        getGroupDetailsResult = []
      }
      return {
        ...state,
        ChatPaginationCount,
        getGroupDetailsResult
      };
    }

    case fromGroup.GET_ALL_GROUPS: {
        return {
          ...state,
          isGetAllGroupsLoading: true,
          isGetAllGroupsLoaded: false,
          getAllGroupsSuccessMessage: null,
        };
    }
    case fromGroup.GET_ALL_GROUPS_SUCCESS: {
    let statusCode = action.payload.code;
    let getAllGroupsResult = state.getAllGroupsResult;
    let getAllGroupsSuccessMessage = state.getAllGroupsSuccessMessage;
    let getAllGroupsStatusCode = statusCode;
            
    switch (statusCode) {
        case 200:
        getAllGroupsResult = action.payload.result;
        getAllGroupsSuccessMessage = action.payload.message;
        break;

        case 500:
        getAllGroupsSuccessMessage = action.payload.message;
        break;
    }
    return {
        ...state,
        isGetAllGroupsLoading: false,
        isGetAllGroupsLoaded: true,
        getAllGroupsResult,
        getAllGroupsSuccessMessage,
        getAllGroupsStatusCode
    };
    }
    case fromGroup.GET_ALL_GROUPS_FAIL: {
    let statusCode = action.payload.status;
    let serverErrorMessage = state.serverErrorMessage
    if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
    }
    else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
    }
    return {
        ...state,
        serverErrorMessage,
        isGetAllGroupsLoading: false,
        isGetAllGroupsLoaded: true,
    };
    }

    case fromGroup.ACCEPT_PUBLIC_GROUP_INVITAION: {
        return {
          ...state,
          isAcceptPublicGroupInvitationLoading: true,
          isAcceptPublicGroupInvitationLoaded: false,
          acceptPublicGroupInvitationSuccessMessage: null,
        };
    }
    case fromGroup.ACCEPT_PUBLIC_GROUP_INVITAION_SUCCESS: {
        let statusCode = action.payload.code;
        let acceptPublicGroupInvitationResult = state.acceptPublicGroupInvitationResult;
        let acceptPublicGroupInvitationSuccessMessage = state.acceptPublicGroupInvitationSuccessMessage;
        let acceptPublicGroupInvitationStatusCode = statusCode;
                
        switch (statusCode) {
            case 200:
            acceptPublicGroupInvitationResult = action.payload.result;
            acceptPublicGroupInvitationSuccessMessage = action.payload.message;
            break;

            case 500:
            acceptPublicGroupInvitationSuccessMessage = action.payload.message;
            break;
        }
        return {
            ...state,
            isAcceptPublicGroupInvitationLoading: false,
            isAcceptPublicGroupInvitationLoaded: true,
            acceptPublicGroupInvitationResult,
            acceptPublicGroupInvitationSuccessMessage,
            acceptPublicGroupInvitationStatusCode
        };
    }
    case fromGroup.ACCEPT_PUBLIC_GROUP_INVITAION_FAIL: {
        let statusCode = action.payload.status;
        let serverErrorMessage = state.serverErrorMessage
        if (statusCode == 401 || statusCode == 0) {
            serverErrorMessage = action.payload.statusText;
        }
        else if (statusCode != 401 && statusCode != 0) {
            serverErrorMessage = action.payload.error.message;
        }
        return {
            ...state,
            serverErrorMessage,
            isAcceptPublicGroupInvitationLoading: false,
            isAcceptPublicGroupInvitationLoaded: true,
        };
    }

    case fromGroup.CREATE_MESSAGE: {
        return {
          ...state,
          isCreateMessageLoading: true,
          isCreateMessageLoaded: false,
          createMessageSuccessMessage: null,
        };
    }
    case fromGroup.CREATE_MESSAGE_SUCCESS: {
        let statusCode = action.payload.code;
        let createMessageResult = state.createMessageResult;
        let createMessageSuccessMessage = state.createMessageSuccessMessage;
        let createMessageStatusCode = statusCode;
                
        switch (statusCode) {
            case 200:
            createMessageResult = action.payload.result;
            createMessageSuccessMessage = action.payload.message;
            break;

            case 500:
            createMessageSuccessMessage = action.payload.message;
            break;
        }
        return {
            ...state,
            isCreateMessageLoading: false,
            isCreateMessageLoaded: true,
            createMessageResult,
            createMessageSuccessMessage,
            createMessageStatusCode
        };
    }
    case fromGroup.CREATE_MESSAGE_FAIL: {
        let statusCode = action.payload.status;
        let serverErrorMessage = state.serverErrorMessage
        if (statusCode == 401 || statusCode == 0) {
            serverErrorMessage = action.payload.statusText;
        }
        else if (statusCode != 401 && statusCode != 0) {
            serverErrorMessage = action.payload.error.message;
        }
        return {
            ...state,
            serverErrorMessage,
            isCreateMessageLoading: false,
            isCreateMessageLoaded: true,
        };
    }

    case fromGroup.GET_ALL_USER_GROUPS: {
        return {
          ...state,
          isGetAllUserGroupsLoading: true,
          isGetAllUserGroupsLoaded: false,
          getAllUserGroupsSuccessMessage: null,
        };
    }
    case fromGroup.GET_ALL_USER_GROUPS_SUCCESS: {
        let statusCode = action.payload.code;
        let getAllUserGroupsResult = state.getAllUserGroupsResult;
        let getAllUserGroupsSuccessMessage = state.getAllUserGroupsSuccessMessage;
        let getAllUserGroupsStatusCode = statusCode;
                
        switch (statusCode) {
            case 200:
            getAllUserGroupsResult = action.payload.result;
            getAllUserGroupsSuccessMessage = action.payload.message;
            break;

            case 500:
            getAllUserGroupsSuccessMessage = action.payload.message;
            break;
        }
        return {
            ...state,
            isGetAllUserGroupsLoading: false,
            isGetAllUserGroupsLoaded: true,
            getAllUserGroupsResult,
            getAllUserGroupsSuccessMessage,
            getAllUserGroupsStatusCode
        };
    }
    case fromGroup.GET_ALL_USER_GROUPS_FAIL: {
        let statusCode = action.payload.status;
        let serverErrorMessage = state.serverErrorMessage
        if (statusCode == 401 || statusCode == 0) {
            serverErrorMessage = action.payload.statusText;
        }
        else if (statusCode != 401 && statusCode != 0) {
            serverErrorMessage = action.payload.error.message;
        }
        return {
            ...state,
            serverErrorMessage,
            isGetAllUserGroupsLoading: false,
            isGetAllUserGroupsLoaded: true,
        };
    }

  }
  return state;
}

export const getChatPaginationCount = (state: GroupState) => state.ChatPaginationCount;
export const getGroupDetailsResult = (state: GroupState) => state.getGroupDetailsResult;
export const getGroupChatArray = (state: GroupState) => state.getGroupChatArray;//by vinita
export const getGroupDetailsSuccessMessage = (state: GroupState) => state.getGroupDetailsSuccessMessage;
export const getGroupDetailsStatusCode = (state: GroupState) => state.getGroupDetailsStatusCode;
export const isGetGroupDetailsLoading = (state: GroupState) => state.isGetGroupDetailsLoading;
export const isGetGroupDetailsLoaded = (state: GroupState) => state.isGetGroupDetailsLoaded;

export const getAllGroupsResult = (state: GroupState) => state.getAllGroupsResult;
export const getAllGroupsSuccessMessage = (state: GroupState) => state.getAllGroupsSuccessMessage;
export const getAllGroupsStatusCode = (state: GroupState) => state.getAllGroupsStatusCode;
export const isGetAllGroupsLoading = (state: GroupState) => state.isGetAllGroupsLoading;
export const isGetAllGroupsLoaded = (state: GroupState) => state.isGetAllGroupsLoaded;

export const acceptPublicGroupInvitationResult = (state: GroupState) => state.acceptPublicGroupInvitationResult;
export const acceptPublicGroupInvitationSuccessMessage = (state: GroupState) => state.acceptPublicGroupInvitationSuccessMessage;
export const acceptPublicGroupInvitationStatusCode = (state: GroupState) => state.acceptPublicGroupInvitationStatusCode;
export const isAcceptPublicGroupInvitationLoading = (state: GroupState) => state.isAcceptPublicGroupInvitationLoading;
export const isAcceptPublicGroupInvitationLoaded = (state: GroupState) => state.isAcceptPublicGroupInvitationLoaded;

export const createMessageResult = (state: GroupState) => state.createMessageResult;
export const createMessageSuccessMessage = (state: GroupState) => state.createMessageSuccessMessage;
export const createMessageStatusCode = (state: GroupState) => state.createMessageStatusCode;
export const isCreateMessageLoading = (state: GroupState) => state.isCreateMessageLoading;
export const isCreateMessageLoaded = (state: GroupState) => state.isCreateMessageLoaded;

export const getAllUserGroupsResult = (state: GroupState) => state.getAllUserGroupsResult;
export const getAllUserGroupsSuccessMessage = (state: GroupState) => state.getAllUserGroupsSuccessMessage;
export const getAllUserGroupsStatusCode = (state: GroupState) => state.getAllUserGroupsStatusCode;
export const isGetAllUserGroupsLoading = (state: GroupState) => state.isGetAllUserGroupsLoading;
export const isGetAllUserGroupsLoaded = (state: GroupState) => state.isGetAllUserGroupsLoaded;
