import { Action } from '@ngrx/store';
import { RESET } from '@ngrx/store-devtools/src/actions';

export const GET_FEEDS_LISTING = 'GET_FEEDS_LISTING';
export const GET_FEEDS_LISTING_SUCCESS = 'GET_FEEDS_LISTING_SUCCESS';
export const GET_FEEDS_LISTING_FAIL = 'GET_FEEDS_LISTING_FAIL';

export const GET_FEEDS_LISTING_EMERGENCY = 'GET_FEEDS_LISTING_EMERGENCY';
export const GET_FEEDS_LISTING_EMERGENCY_SUCCESS = 'GET_FEEDS_LISTING_EMERGENCY_SUCCESS';
export const GET_FEEDS_LISTING_EMERGENCY_FAIL = 'GET_FEEDS_LISTING_EMERGENCY_FAIL';

export const GET_ENGEAGEMENT_DETAILS = 'GET_ENGEAGEMENT_DETAILS';
export const GET_ENGEAGEMENT_DETAILS_SUCCESS = 'GET_ENGEAGEMENT_DETAILS_SUCCESS';
export const GET_ENGEAGEMENT_DETAILS_FAIL = 'GET_ENGEAGEMENT_DETAILS_FAIL';
export const SET_PAGINATION_COUNT = 'SET_PAGINATION_COUNT';

export const SET_PAGINATION_COUNT_EMERGENCY= 'SET_PAGINATION_COUNT_EMERGENCY';

export const RESET_FAVOURITE = 'RESET_FAVOURITE';

export const ADD_OR_REMOVE_BLOG_COMMENT = 'ADD_OR_REMOVE_BLOG_COMMENT';
export const ADD_OR_REMOVE_BLOG_COMMENT_SUCCESS = 'ADD_OR_REMOVE_BLOG_COMMENT_SUCCESS';
export const ADD_OR_REMOVE_BLOG_COMMENT_FAIL = 'ADD_OR_REMOVE_BLOG_COMMENT_FAIL';

export const REMOVE_BLOG_COMMENT = 'REMOVE_BLOG_COMMENT';
export const REMOVE_BLOG_COMMENT_SUCCESS = 'REMOVE_BLOG_COMMENT_SUCCESS';
export const REMOVE_BLOG_COMMENT_FAIL = 'REMOVE_BLOG_COMMENT_FAIL';

export const GET_ALL_BLOG_COMMENTS = 'GET_ALL_BLOG_COMMENTS';
export const GET_ALL_BLOG_COMMENTS_SUCCESS = 'GET_ALL_BLOG_COMMENTS_SUCCESS';
export const GET_ALL_BLOG_COMMENTS_FAIL = 'GET_ALL_BLOG_COMMENTS_FAIL';

export const GET_FEEDS_EMERGENCY = 'GET_FEEDS_EMERGENCY';
export const GET_FEEDS_EMERGENCY_SUCCESS = 'GET_FEEDS_EMERGENCY_SUCCESS';
export const GET_FEEDS_EMERGENCY_FAIL = 'GET_FEEDS_EMERGENCY_FAIL';
export const ADD_BLOG = 'ADD_BLOG';
export const ADD_BLOG_SUCCESS = 'ADD_BLOG_SUCCESS';
export const ADD_BLOG_FAIL = 'ADD_BLOG_FAIL';

export const ADD_FORUM = 'ADD_FORUM';
export const ADD_FORUM_SUCCESS = 'ADD_FORUM_SUCCESS';
export const ADD_FORUM_FAIL = 'ADD_FORUM_FAIL';

export const GET_FORUM_BY_USER = 'GET_FORUM_BY_USER';
export const GET_FORUM_BY_USER_SUCCESS = 'GET_FORUM_BY_USER_SUCCESS';
export const GET_FORUM_BY_USER_FAIL = 'GET_FORUM_BY_USER_FAIL';

export const UPDATE_FORUM = 'UPDATE_FORUM';
export const UPDATE_FORUM_SUCCESS = 'UPDATE_FORUM_SUCCESS';
export const UPDATE_FORUM_FAIL = 'UPDATE_FORUM_FAIL';

export const REMOVE_FORUM = 'REMOVE_FORUM';
export const REMOVE_FORUM_SUCCESS = 'REMOVE_FORUM_SUCCESS';
export const REMOVE_FORUM_FAIL = 'REMOVE_FORUM_FAIL';

export const REPORT_ABUSE = 'REPORT_ABUSE';
export const REPORT_ABUSE_SUCCESS = 'REPORT_ABUSE_SUCCESS';
export const REPORT_ABUSE_FAIL = 'REPORT_ABUSE_FAIL';

export const REPORT_ABUSE_COMMENT = 'REPORT_ABUSE_COMMENT';
export const REPORT_ABUSE_COMMENT_SUCCESS = 'REPORT_ABUSE_COMMENT_SUCCESS';
export const REPORT_ABUSE_COMMENT_FAIL = 'REPORT_ABUSE_COMMENT_FAIL';


export const REPORT_WEBINAR_ABUSE = 'REPORT_WEBINAR_ABUSE';
export const REPORT_WEBINAR_ABUSE_SUCCESS = 'REPORT_WEBINAR_ABUSE_SUCCESS';
export const REPORT_WEBINAR_ABUSE_FAIL = 'REPORT_WEBINAR_ABUSE_FAIL';

export const REPORT_WEBINAR_ABUSE_COMMENT = 'REPORT_WEBINAR_ABUSE_COMMENT';
export const REPORT_WEBINAR_ABUSE_COMMENT_SUCCESS = 'REPORT_WEBINAR_ABUSE_COMMENT_SUCCESS';
export const REPORT_WEBINAR_ABUSE_COMMENT_FAIL = 'REPORT_WEBINAR_ABUSE_COMMENT_FAIL';



export const REGISTRATION_COMPETITION = 'REGISTRATION_COMPETITION';
export const REGISTRATION_COMPETITION_SUCCESS = 'REGISTRATION_COMPETITION_SUCCESS';
export const REGISTRATION_COMPETITION_FAIL = 'REGISTRATION_COMPETITION_FAIL';

export const SUBMIT_COMPETITION = 'SUBMIT_COMPETITION';
export const SUBMIT_COMPETITION_SUCCESS = 'SUBMIT_COMPETITION_SUCCESS';
export const SUBMIT_COMPETITION_FAIL = 'SUBMIT_COMPETITION_FAIL';
export const CHECK_TIMELINE = 'CHECK_TIMELINE';

export const GET_USER_NOTIFICATION = 'GET_USER_NOTIFICATION';
export const GET_USER_NOTIFICATION_SUCCESS = 'GET_USER_NOTIFICATION_SUCCESS';
export const GET_USER_NOTIFICATION_FAIL = 'GET_USER_NOTIFICATION_FAIL';

export const GET_BLOG_LISTING_BY_USER_ID = 'GET_BLOG_LISTING_BY_USER_ID';
export const GET_BLOG_LISTING_BY_USER_ID_SUCCESS = 'GET_BLOG_LISTING_BY_USER_ID_SUCCESS';
export const GET_BLOG_LISTING_BY_USER_ID_FAIL ='GET_BLOG_LISTING_BY_USER_ID_FAIL';  

export const GET_FORUM_LISTING_BY_USER_ID = 'GET_FORUM_LISTING_BY_USER_ID';
export const GET_FORUM_LISTING_BY_USER_ID_SUCCESS = 'GET_FORUM_LISTING_BY_USER_ID_SUCCESS';
export const GET_FORUM_LISTING_BY_USER_ID_FAIL ='GET_FORUM_LISTING_BY_USER_ID_FAIL';  

export const GET_BLOG_BY_BLOG_ID = 'GET_BLOG_BY_BLOG_ID';
export const GET_BLOG_BY_BLOG_ID_SUCCESS = 'GET_BLOG_BY_BLOG_ID_SUCCESS';
export const GET_BLOG_BY_BLOG_ID_FAIL ='GET_BLOG_BY_BLOG_ID_FAIL';  

export const EDIT_BLOG = 'EDIT_BLOG';
export const EDIT_BLOG_SUCCESS = 'EDIT_BLOG_SUCCESS';
export const EDIT_BLOG_FAIL ='EDIT_BLOG_FAIL'; 

export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAIL ='DELETE_BLOG_FAIL'; 


export const GET_FORUM_BY_FORUM_ID = 'GET_FORUM_BY_FORUM_ID';
export const GET_FORUM_BY_FORUM_ID_SUCCESS = 'GET_FORUM_BY_FORUM_ID_SUCCESS';
export const GET_FORUM_BY_FORUM_ID_FAIL ='GET_FORUM_BY_FORUM_ID_FAIL';  

export const EDIT_FORUM = 'EDIT_FORUM';
export const EDIT_FORUM_SUCCESS = 'EDIT_FORUM_SUCCESS';
export const EDIT_FORUM_FAIL ='EDIT_FORUM_FAIL'; 

export const UPDATE_BLOG_PHOTO = 'UPDATE_BLOG_PHOTO';
export const UPDATE_BLOG_PHOTO_SUCCESS = 'UPDATE_BLOG_PHOTO_SUCCESS';
export const UPDATE_BLOG_PHOTO_FAIL ='UPDATE_BLOG_PHOTO_FAIL';

export const UPDATE_BLOG_PHOTO_FAV = 'UPDATE_BLOG_PHOTO_FAV';
export const UPDATE_BLOG_PHOTO_FAV_SUCCESS = 'UPDATE_BLOG_PHOTO_FAV_SUCCESS';
export const UPDATE_BLOG_PHOTO_FAV_FAIL ='UPDATE_BLOG_PHOTO_FAV_FAIL';

export const SET_PAGINATION_COUNT_BLOGS = 'SET_PAGINATION_COUNT_BLOGS';

export const SET_PAGINATION_COUNT_FORUMS = 'SET_PAGINATION_COUNT_FORUMS';
export const RESET_BLOG = 'RESET_BLOG';
export const RESET_FORUM = 'RESET_FORUM';

export class resetBlogList implements Action {
	readonly type = RESET_BLOG;
	constructor(public payload: any) { }
}
export class resetForumList implements Action {
	readonly type = RESET_FORUM;
	constructor(public payload: any) { }
}

export class UpdateBlogPhoto implements Action {
	readonly type = UPDATE_BLOG_PHOTO;
	constructor(public payload: any) { }
}
export class UpdateBlogPhotoSuccess implements Action {
	readonly type = UPDATE_BLOG_PHOTO_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateBlogPhotoFail implements Action {
	readonly type = UPDATE_BLOG_PHOTO_FAIL;
	constructor(public payload: any) { }
}

export class UpdateBlogPhotoFav implements Action {
	readonly type = UPDATE_BLOG_PHOTO_FAV;
	constructor(public payload: any) { }
}
export class UpdateBlogPhotoFavSuccess implements Action {
	readonly type = UPDATE_BLOG_PHOTO_FAV_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateBlogPhotoFavFail implements Action {
	readonly type = UPDATE_BLOG_PHOTO_FAV_FAIL;
	constructor(public payload: any) { }
}

export class GetFeedsListing implements Action {
	readonly type = GET_FEEDS_LISTING;
	constructor(public payload: any) { }
}

export class GetFeedsListingSuccess implements Action {
	readonly type = GET_FEEDS_LISTING_SUCCESS;
	constructor(public payload: any) { }
}

export class GetFeedsListingFail implements Action {
	readonly type = GET_FEEDS_LISTING_FAIL;
	constructor(public payload: any) { }
}

export class GetFeedsListingEmergency implements Action {
	readonly type = GET_FEEDS_LISTING_EMERGENCY;
	constructor(public payload: any) { }
}

export class GetFeedsListingEmergencySuccess implements Action {
	readonly type = GET_FEEDS_LISTING_EMERGENCY_SUCCESS;
	constructor(public payload: any) { }
}

export class GetFeedsListingEmergencyFail implements Action {
	readonly type = GET_FEEDS_LISTING_EMERGENCY_FAIL;
	constructor(public payload: any) { }
}

export class GetEngeagementDetails implements Action {
	readonly type = GET_ENGEAGEMENT_DETAILS;
	constructor(public payload: any) { }
}

export class CheckTimeline implements Action {
	readonly type = CHECK_TIMELINE;
	constructor(public payload: any) { }
}

export class GetEngeagementDetailsSuccess implements Action {
	readonly type = GET_ENGEAGEMENT_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}

export class GetEngeagementDetailsFail implements Action {
	readonly type = GET_ENGEAGEMENT_DETAILS_FAIL;
	constructor(public payload: any) { }
}

export class SetPaginationCount implements Action {
	readonly type = SET_PAGINATION_COUNT;
	constructor(public payload: any) { }
}

export class SetPaginationEmergencyCount implements Action {
	readonly type = SET_PAGINATION_COUNT_EMERGENCY;
	constructor(public payload: any) { }
}

export class ResetFavourite implements Action {
	readonly type = RESET_FAVOURITE;
	constructor(public payload: any) { }
}

export class AddOrRemoveBlogComment implements Action {
	readonly type = ADD_OR_REMOVE_BLOG_COMMENT;
	constructor(public payload: any) { }
}
export class AddOrRemoveBlogCommentSuccess implements Action {
	readonly type = ADD_OR_REMOVE_BLOG_COMMENT_SUCCESS;
	constructor(public payload: any) { }
}
export class AddOrRemoveBlogCommentFail implements Action {
	readonly type = ADD_OR_REMOVE_BLOG_COMMENT_FAIL;
	constructor(public payload: any) { }
}

export class GetAllBlogComments implements Action {
	readonly type = GET_ALL_BLOG_COMMENTS;
	constructor(public payload: any) {}
}
export class GetAllBlogCommentsSuccess implements Action {
	readonly type = GET_ALL_BLOG_COMMENTS_SUCCESS;
	constructor(public payload: any) {}
}
export class GetAllBlogCommentsFail implements Action {
	readonly type = GET_ALL_BLOG_COMMENTS_FAIL;
	constructor(public payload: any) {}
}

export class AddBlog implements Action {
	readonly type = ADD_BLOG;
	constructor(public payload: any) {}
}
export class AddBlogSuccess implements Action {
	readonly type = ADD_BLOG_SUCCESS;
	constructor(public payload: any) {}
}
export class AddBlogFail implements Action {
	readonly type = ADD_BLOG_FAIL;
	constructor(public payload: any) {}
}

export class AddForum implements Action {
	readonly type = ADD_FORUM;
	constructor(public payload: any) {}
}
export class AddForumSuccess implements Action {
	readonly type = ADD_FORUM_SUCCESS;
	constructor(public payload: any) {}
}
export class AddForumFail implements Action {
	readonly type = ADD_FORUM_FAIL;
	constructor(public payload: any) {}
}

export class UpdateForum implements Action {
	readonly type = UPDATE_FORUM;
	constructor(public payload: any) {}
}
export class UpdateForumSuccess implements Action {
	readonly type = UPDATE_FORUM_SUCCESS;
	constructor(public payload: any) {}
}
export class UpdateForumFail implements Action {
	readonly type = UPDATE_FORUM_FAIL;
	constructor(public payload: any) {}
}

export class GetForumByUser implements Action {
	readonly type = GET_FORUM_BY_USER;
	constructor(public payload: any) {}
}
export class GetForumByUserSuccess implements Action {
	readonly type = GET_FORUM_BY_USER_SUCCESS;
	constructor(public payload: any) {}
}
export class GetForumByUserFail implements Action {
	readonly type = GET_FORUM_BY_USER_FAIL;
	constructor(public payload: any) {}
}

export class RemoveForum implements Action {
	readonly type = REMOVE_FORUM;
	constructor(public payload: any) {}
}
export class RemoveForumSuccess implements Action {
	readonly type = REMOVE_FORUM_SUCCESS;
	constructor(public payload: any) {}
}
export class RemoveForumFail implements Action {
	readonly type = REMOVE_FORUM_FAIL;
	constructor(public payload: any) {}
}

export class ReportAbuse implements Action {
	readonly type = REPORT_ABUSE;
	constructor(public payload: any) {}
}
export class ReportAbuseSuccess implements Action {
	readonly type = REPORT_ABUSE_SUCCESS;
	constructor(public payload: any) {}
}
export class ReportAbuseFail implements Action {
	readonly type = REPORT_ABUSE_FAIL;
	constructor(public payload: any) {}
}



export class ReportAbuseComment implements Action {
	readonly type = REPORT_ABUSE_COMMENT;
	constructor(public payload: any) {}
}
export class ReportAbuseCommentSuccess implements Action {
	readonly type = REPORT_ABUSE_COMMENT_SUCCESS;
	constructor(public payload: any) {}
}
export class ReportAbuseCommentFail implements Action {
	readonly type = REPORT_ABUSE_COMMENT_FAIL;
	constructor(public payload: any) {}
}


export class ReportWebinarAbuse implements Action {
	readonly type = REPORT_WEBINAR_ABUSE;
	constructor(public payload: any) {}
}
export class ReportWebinarAbuseSuccess implements Action {
	readonly type = REPORT_WEBINAR_ABUSE_SUCCESS;
	constructor(public payload: any) {}
}
export class ReportWebinarAbuseFail implements Action {
	readonly type = REPORT_WEBINAR_ABUSE_FAIL;
	constructor(public payload: any) {}
}

export class ReportWebinarAbuseComment implements Action {
	readonly type = REPORT_WEBINAR_ABUSE_COMMENT;
	constructor(public payload: any) {}
}
export class ReportWebinarAbuseCommentSuccess implements Action {
	readonly type = REPORT_WEBINAR_ABUSE_COMMENT_SUCCESS;
	constructor(public payload: any) {}
}
export class ReportWebinarAbuseCommentFail implements Action {
	readonly type = REPORT_WEBINAR_ABUSE_COMMENT_FAIL;
	constructor(public payload: any) {}
}



export class GetFeedsEmergency implements Action {
	readonly type = GET_FEEDS_EMERGENCY;
	constructor(public payload: any) { }
  }
  
  export class GetFeedsEmergencySuccess implements Action {
	readonly type = GET_FEEDS_EMERGENCY_SUCCESS;
	constructor(public payload: any) { }
  }
  
  export class GetFeedsEmergencyFail implements Action {
	readonly type = GET_FEEDS_EMERGENCY_FAIL;
	constructor(public payload: any) { }
  }

  export class RegistrationCompetition implements Action {
	readonly type = REGISTRATION_COMPETITION;
	constructor(public payload: any) {}
}
export class RegistrationCompetitionSuccess implements Action {
	readonly type = REGISTRATION_COMPETITION_SUCCESS;
	constructor(public payload: any) {}
}
export class RegistrationCompetitionFail implements Action {
	readonly type = REGISTRATION_COMPETITION_FAIL;
	constructor(public payload: any) {}
}

export class SubmitCompetition implements Action {
	readonly type = SUBMIT_COMPETITION;
	constructor(public payload: any) {}
}
export class SubmitCompetitionSuccess implements Action {
	readonly type = SUBMIT_COMPETITION_SUCCESS;
	constructor(public payload: any) {}
}
export class SubmitCompetitionFail implements Action {
	readonly type = SUBMIT_COMPETITION_FAIL;
	constructor(public payload: any) {}
}

export class GetUserNotification implements Action {
	readonly type = GET_USER_NOTIFICATION;
	constructor(public payload: any) {}
}
export class GetUserNotificationSuccess implements Action {
	readonly type = GET_USER_NOTIFICATION_SUCCESS;
	constructor(public payload: any) {}
}
export class GetUserNotificationFail implements Action {
	readonly type = GET_USER_NOTIFICATION_FAIL;
	constructor(public payload: any) {}
}

export class GetBlogListingByUserId implements Action {
	readonly type = GET_BLOG_LISTING_BY_USER_ID;
	constructor(public payload: any) { }
}
export class SetPaginationCountBlogs implements Action {
	readonly type = SET_PAGINATION_COUNT_BLOGS;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}
export class SetPaginationCountForums implements Action {
	readonly type = SET_PAGINATION_COUNT_FORUMS;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}
export class GetBlogListingByUserIdSuccess implements Action {
	readonly type = GET_BLOG_LISTING_BY_USER_ID_SUCCESS;
	constructor(public payload: any) { }
}

export class GetBlogListingByUserIdFail implements Action {
	readonly type = GET_BLOG_LISTING_BY_USER_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetForumListingByUserId implements Action {
	readonly type = GET_FORUM_LISTING_BY_USER_ID;
	constructor(public payload: any) { }
}

export class GetForumListingByUserIdSuccess implements Action {
	readonly type = GET_FORUM_LISTING_BY_USER_ID_SUCCESS;
	constructor(public payload: any) { }
}

export class GetForumListingByUserIdFail implements Action {
	readonly type = GET_FORUM_LISTING_BY_USER_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetBlogByBlogId implements Action {
	readonly type = GET_BLOG_BY_BLOG_ID;
	constructor(public payload: any) { }
}

export class GetBlogByBlogIdSuccess implements Action {
	readonly type = GET_BLOG_BY_BLOG_ID_SUCCESS;
	constructor(public payload: any) { }
}

export class GetBlogByBlogIdFail implements Action {
	readonly type = GET_BLOG_BY_BLOG_ID_FAIL;
	constructor(public payload: any) { }
}

export class EditBlog implements Action {
	readonly type = EDIT_BLOG;
	constructor(public payload: any) { }
}

export class EditBlogSuccess implements Action {
	readonly type = EDIT_BLOG_SUCCESS;
	constructor(public payload: any) { }
}

export class EditBlogFail implements Action {
	readonly type = EDIT_BLOG_FAIL;
	constructor(public payload: any) { }
}

export class GetForumByForumId implements Action {
	readonly type = GET_FORUM_BY_FORUM_ID;
	constructor(public payload: any) { }
}

export class GetForumByForumIdSuccess implements Action {
	readonly type = GET_FORUM_BY_FORUM_ID_SUCCESS;
	constructor(public payload: any) { }
}

export class GetForumByForumIdFail implements Action {
	readonly type = GET_FORUM_BY_FORUM_ID_FAIL;
	constructor(public payload: any) { }
}

export class EditForum implements Action {
	readonly type = EDIT_FORUM;
	constructor(public payload: any) { }
}

export class EditForumSuccess implements Action {
	readonly type = EDIT_FORUM_SUCCESS;
	constructor(public payload: any) { }
}

export class EditForumFail implements Action {
	readonly type = EDIT_FORUM_FAIL;
	constructor(public payload: any) { }
}


export type FeedsAction = GetFeedsListing | GetFeedsListingSuccess | GetFeedsListingFail
	|	GetEngeagementDetails | GetEngeagementDetailsSuccess | GetEngeagementDetailsFail
	| GetFeedsListingEmergency | GetFeedsListingEmergencySuccess | GetFeedsListingEmergencyFail
	| SetPaginationCount | ResetFavourite | SetPaginationEmergencyCount
	| AddOrRemoveBlogComment | AddOrRemoveBlogCommentSuccess | AddOrRemoveBlogCommentFail
	| GetAllBlogComments | GetAllBlogCommentsSuccess | GetAllBlogCommentsFail
	| AddBlog | AddBlogSuccess | AddBlogFail
	| AddForum | AddForumSuccess | AddForumFail
	| UpdateForum | UpdateForumSuccess | UpdateForumFail
	| GetForumByUser | GetForumByUserSuccess | GetForumByUserFail
	| GetFeedsEmergency | GetFeedsEmergencySuccess | GetFeedsEmergencyFail
	| RemoveForum | RemoveForumSuccess | RemoveForumFail
	| ReportAbuse | ReportAbuseSuccess | ReportAbuseFail
	| RegistrationCompetition | RegistrationCompetitionSuccess | RegistrationCompetitionFail
	| SubmitCompetition | SubmitCompetitionSuccess | SubmitCompetitionFail | CheckTimeline
	| GetUserNotification | GetUserNotificationSuccess | GetUserNotificationFail
	| GetBlogListingByUserId | GetBlogListingByUserIdSuccess | GetBlogListingByUserIdFail
	| GetForumListingByUserId | GetForumListingByUserIdSuccess | GetForumListingByUserIdFail
	| GetBlogByBlogId | GetBlogByBlogIdSuccess | GetBlogByBlogIdFail
	| EditBlog | EditBlogSuccess | EditBlogFail
	| GetForumByForumId | GetForumByForumIdSuccess | GetForumByForumIdFail
	| EditForum | EditForumSuccess | EditForumFail
	| UpdateBlogPhoto | UpdateBlogPhotoSuccess | UpdateBlogPhotoFail
	| UpdateBlogPhotoFav | UpdateBlogPhotoFavSuccess | UpdateBlogPhotoFavFail
	| ReportAbuseComment | ReportAbuseCommentSuccess | ReportAbuseCommentFail
	| ReportWebinarAbuse | ReportWebinarAbuseSuccess | ReportWebinarAbuseFail 
	| ReportWebinarAbuseComment | ReportWebinarAbuseCommentSuccess | ReportWebinarAbuseCommentFail
	| SetPaginationCountBlogs | SetPaginationCountForums
	| resetBlogList | resetForumList;
