import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { map, switchMap, catchError, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { LogoutService } from "../api-services/logout.service";
import * as LogoutAction from "../action/logout.action";

@Injectable()
export class LogoutEffects {
  constructor(
    private actions$: Actions,
    private logoutService: LogoutService
  ) {}

  @Effect()
  logout$ = this.actions$.pipe(
    ofType<LogoutAction.Logout>(LogoutAction.LOGOUT),
    switchMap((action) => {
      return this.logoutService.logout(action.payload).pipe(
        map((Data) => new LogoutAction.LogoutSuccess(Data)),
        catchError((error) => of(new LogoutAction.LogoutFail(error)))
      );
    })
  );
}
