import { Action } from '@ngrx/store';

export const  GET_GROUP_DETAILS = 'GET_GROUP_DETAILS';
export const  GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const  GET_GROUP_DETAILS_FAIL = 'GET_GROUP_DETAILS_FAIL';

export const  GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const  GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const  GET_ALL_GROUPS_FAIL = 'GET_ALL_GROUPS_FAIL';

export const  ACCEPT_PUBLIC_GROUP_INVITAION = 'ACCEPT_PUBLIC_GROUP_INVITAION';
export const  ACCEPT_PUBLIC_GROUP_INVITAION_SUCCESS = 'ACCEPT_PUBLIC_GROUP_INVITAION_SUCCESS';
export const  ACCEPT_PUBLIC_GROUP_INVITAION_FAIL = 'ACCEPT_PUBLIC_GROUP_INVITAION_FAIL';

export const  CREATE_MESSAGE = 'CREATE_MESSAGE';
export const  CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const  CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL';

export const  GET_ALL_USER_GROUPS = 'GET_ALL_USER_GROUPS';
export const  GET_ALL_USER_GROUPS_SUCCESS = 'GET_ALL_USER_GROUPS_SUCCESS';
export const  GET_ALL_USER_GROUPS_FAIL = 'GET_ALL_USER_GROUPS_FAIL';

export const SET_PAGINATION_COUNT_CHAT = 'SET_PAGINATION_COUNT_CHAT';



export class SetPaginationCountChat implements Action {
	readonly type = SET_PAGINATION_COUNT_CHAT;
	constructor(public payload: any) { }
}


export class GetGroupDetails implements Action {
	readonly type = GET_GROUP_DETAILS;
	constructor(public payload: any) { }
}
export class GetGroupDetailsSuccess implements Action {
	readonly type = GET_GROUP_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}
export class GetGroupDetailsFail implements Action {
	readonly type = GET_GROUP_DETAILS_FAIL;
	constructor(public payload: any) { }
}

export class GetAllGroups implements Action {
	readonly type = GET_ALL_GROUPS;
	constructor(public payload: any) { }
}
export class GetAllGroupsSuccess implements Action {
	readonly type = GET_ALL_GROUPS_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAllGroupsFail implements Action {
	readonly type = GET_ALL_GROUPS_FAIL;
	constructor(public payload: any) { }
}

export class AcceptPublicGroupInvitation implements Action {
	readonly type =ACCEPT_PUBLIC_GROUP_INVITAION;
	constructor(public payload: any) { }
}
export class AcceptPublicGroupInvitationSuccess implements Action {
	readonly type =ACCEPT_PUBLIC_GROUP_INVITAION_SUCCESS;
	constructor(public payload: any) { }
}
export class AcceptPublicGroupInvitationFail implements Action {
	readonly type =ACCEPT_PUBLIC_GROUP_INVITAION_FAIL;
	constructor(public payload: any) { }
}

export class CreateMessage implements Action {
	readonly type = CREATE_MESSAGE;
	constructor(public payload: any) { }
}
export class CreateMessageSuccess implements Action {
	readonly type = CREATE_MESSAGE_SUCCESS;
	constructor(public payload: any) { }
}
export class CreateMessageFail implements Action {
	readonly type = CREATE_MESSAGE_FAIL;
	constructor(public payload: any) { }
}

export class GetAllUserGroups implements Action {
	readonly type = GET_ALL_USER_GROUPS;
	constructor(public payload: any) { }
}
export class GetAllUserGroupsSuccess implements Action {
	readonly type = GET_ALL_USER_GROUPS_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAllUserGroupsFail implements Action {
	readonly type = GET_ALL_USER_GROUPS_FAIL;
	constructor(public payload: any) { }
}

export type GroupAction =  GetGroupDetails |GetGroupDetailsSuccess |GetGroupDetailsFail
                             | GetAllGroups | GetAllGroupsSuccess | GetAllGroupsFail
                             | AcceptPublicGroupInvitation | AcceptPublicGroupInvitationSuccess | AcceptPublicGroupInvitationFail
                             | CreateMessage | CreateMessageSuccess | CreateMessageFail
                             | GetAllUserGroups | GetAllUserGroupsSuccess | GetAllUserGroupsFail | SetPaginationCountChat;