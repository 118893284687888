import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterContentChecked, HostListener } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { Images } from 'src/app/shared/config/images-config';
import { RegistrationComponent } from '../../login-management/registration/registration.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/localstorage.service';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { Validators, FormControl, FormGroup, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { changePasswordPopupComponent } from '../change-passward-popup/change-passward-popup.component';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { ApiCallService } from "src/app/shared/services/dynamic-api-calling.service";
import { environment } from 'src/environments/environment';
import { screenName,clickedOn } from 'src/app/shared/config/google-analytics-constants';


export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
		return (invalidCtrl || invalidParent);
	}
}
@Component({
	selector: 'app-display-profile',
	templateUrl: './display-profile.component.html',
	styleUrls: ['./display-profile.component.scss']
})
export class DisplayProfileComponent implements OnInit, OnDestroy, AfterContentChecked {
	images = Images;
	disable: boolean;
	data: any;
	date: any;
	updateData: any;
	UserProfileDetails: any;
	address: any;
	name: string;
	food: any;
	selectedList: any;
	List: any;
	initialPreferenceName: string[];
	userPreferenceList = [];
	status: any;
	changePreferrence: any;
	data1: string;
	public imagePath;
	imgURL: any;
	public message: string;
	fullName: any;
	DOB: any;
	email: any;
	area: any;
	home: any;
	landmark: any;
	aadharNumber: any;
	drivingLicence: any;
	mobileNumber: any;
	userId: any;
	isProfileLoading = false;
	isLoading = false;
	isLoaded = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	updateStatusCode: string;
	isUpdated = false;
	apiDetails = {};
	token: string;
	selectedLanguage: string;
	updatelabels: {};
	labels: {};
	isClicked: any;
	isNotification: any;
	isEmail: any;
	isSms: any;
	genderType: 'F' | 'M' | 'O';
	gender: any;
	imageUrl: any;
	middleName: any;
	lastName: any;
	firstName: any;

	preferenceList: any;
	initialPreference: any[];
	preferencesMenu: [string, unknown];
	preferencesValuelist: any[];
	updatePreferences: any;
	updatePreferencesList: any;
	preferencesUpadated: any[];
	preferenceStatus: boolean;
	preferences = [];
	userPreference: any[];
	registerLabels: {};

	cPasshHide = true;
	hide = true;
	hidepass = true;
	matcher = new MyErrorStateMatcher();
	pass = null;
	oldPass: any;
	confirmPass = null;
	newpassword = null;
	oldPassword = null;
	assignOldPassword = null;
	assignNewPassword = null;
	assignConfirmPassword = null;
	aadharCard: string;
	emailId: any;
	encrPassword: string;
	serverMessage: string;
	isServerMessage: boolean;
	isChange = false;
	statusCode: number;
	profileMessage: string;
	errorMessage: {};
	panelOpenState = false;
	encrOldPassword: string;
	encrPass: string;
	city: any;
	themeBackgroundColor = AppConstants.FEEDS_DARK_BACKGROUND;
	successMessages: {};
	get passwordInput() { return this.myForm.get('password'); }
	get password() { return this.myForm.get('cPassword'); }

	public oldPasswordRegx = /^[A-Za-z0-9`~!@#$%^&*()_+\-=:'<>?;',./]{8,}$/;
	// public oldPasswordRegx =/^\s*(?=[A-Za-z0-9`~!@#$%^&*()_+\-=:'<>?;',./]{8,20})(?=.*[A-Za-z0-9])(?=.*[`~!@#$%^&*()_+\-=:'<>?;',./]).*\s*$/;

	public passwordRegx = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
	checkPasswords: any;
	myForm: FormGroup;

	// zone and area variables
	zoneKey: any;
	zonesLabel: any;
	zoneObject: any;
	zone: any;
	areaKeyValue: string;
	areaArray: any;
	areaValue: any;
	areaKey: any;
	// areaLabels: any;
	isBlind;

	updatePasswordStatusCode: number = 0;
	updatePasswordErrorMessage: string = null;

	oldpassword = new FormControl('', [Validators.pattern(this.oldPasswordRegx)]);
	// @HostListener('window: beforeunload', ['$event']) unloadHandler(event: Event) {
	// 	event.returnValue = false;
	// }

	constructor(
		public dialog: MatDialog,
		private spinner: NgxSpinnerService,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private router: Router,
		private encrDecr: EncrDecrService,
		private ref: ChangeDetectorRef,
		private http: HttpClient,
		private localStorage: LocalStorageService,
		private toastr: ToastrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private apiCallService: ApiCallService,
		private formBuilder: FormBuilder) {
		this.confirmPass = null;
		this.pass = null;
		this.oldPassword = null;
		this.store.dispatch(new fromStore.ResetUpdatePassword());
		this.getUserDetails();
	}
	getUserDetails() {

		this.store.select(fromStore.verifyMobileNumberResponse)
			.subscribe((response) => {
				this.UserProfileDetails = response;
	
				
			});
	}
	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(
			screenName.PROFILE+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.PROFILE + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);

		// server error handling
		this.store.select(fromStore.getUserStateServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						// this.router.navigate(['/Login/login']);

					} else if (serverError === 'Unknown Error') {

					} else {
						this.serverMessage = serverError;
						this.isServerMessage = true;
						if (this.serverMessage) {
							console.error(this.serverMessage);
						}
					} {
						// this.serverMessage = serverError;
						// this.isServerMessage = true;
						// if (this.serverMessage) {
						// 	console.error(this.serverMessage);
						// }
					}
					//this.serverMessage = null;
				}
			});

		if (!this.localStorageService.getToken()) {
			this.router.navigate(['/cep/feeds']);
		}
		// profile image upload
		window.onload = function () {
			var profileImage = localStorage.getItem('profileImageData');
			if (profileImage !== null) {
				document.getElementById('FileInput').innerHTML = "<img src='" + profileImage + "'>";
			}
		};

		// update profile API response 
		this.store.select(fromStore.getUpdateProfileStatusCode)
			.subscribe((statusCode) => {
				this.statusCode = statusCode;
				if (this.statusCode === 200) {
					this.store.select(fromStore.getUpdateProfileErrorMessage)
						.subscribe((profileMessage) => {
							this.profileMessage = profileMessage;
							if (this.profileMessage != null) {
								this.dialog.closeAll();
								this.store.select(fromStore.getSuccessMessages)
									.subscribe(res => {
										this.successMessages = res;
										this.toastr.success(this.successMessages[this.selectedLanguage][this.profileMessage]);
									});
								this.store.dispatch(new fromStore.ResetUpdateProfile());
							}
						});
				} else if (statusCode === 612) {
					this.store.select(fromStore.getErrorMessages)
						.subscribe((errMessage) => {
							this.errorMessage = errMessage;
							if (this.errorMessage) {
								this.store.dispatch(new fromStore.ResetUpdateProfile());
							}
						});
				}
			});
			this.store
			.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
			  this.apiDetails=apiDetails;
			
			  
			});

		// validation for password and confirm password 
		this.myForm = this.formBuilder.group({
			password: ['', [Validators.pattern(this.passwordRegx)]],
			confirmPassword: ['']
		}, { validator: this.checkPassword });


		this.store.select(fromStore.getRegisterScreenLabels)
			.subscribe((labels) => {
				this.registerLabels = labels;
				const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
				const passwordMarMessage = 'आपला पासवर्ड हा किमान 8 कॅरेक्टर असणे आवश्यक आहे आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';
				this.registerLabels['en'].passwordLength = passwordEnMessage;
				this.registerLabels['mar'].passwordLength = passwordMarMessage;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getVerifyPasswordLoading)
			.subscribe((isProfileLoading) => {
				this.isProfileLoading = isProfileLoading;
			});

		// verify password response 
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.localStorageService.getToken() && this.localStorageService.getUserId()) {
				this.token = this.encrDecr.get(this.localStorageService.getToken());
				if (this.token) {
					this.store.select(fromStore.getSelectedLanguage)
						.subscribe((selectedLanguage) => {
							this.selectedLanguage = selectedLanguage;
							if (this.selectedLanguage) {
								this.store.select(fromStore.getVerifyPasswordResponse)
									.subscribe((data) => {
										if (data) {
											this.UserProfileDetails = data;
										
											
											this.zoneKey = this.UserProfileDetails.zone;
											this.areaKeyValue = this.UserProfileDetails.area;
											if (this.UserProfileDetails.emailId) {
												this.emailId = this.UserProfileDetails.emailId;
											} else {
												this.emailId = 'N/A';
											}
											if (this.UserProfileDetails.aadharCard) {
												this.aadharCard = this.UserProfileDetails.aadharCard;
											} else {
												this.aadharCard = 'N/A';
											}
											if (this.UserProfileDetails.drivingLicence) {
												this.drivingLicence = this.UserProfileDetails.drivingLicence;
											} else {
												this.drivingLicence = 'N/A';
											}
											if (this.UserProfileDetails.city) {
												this.city = this.UserProfileDetails.city;
											}
										}
										if (this.selectedLanguage) {
											if (this.zoneKey) {
												this.store.select(fromStore.getAreaAndZones)
													.subscribe((response: {}[]) => {
														if (response.length > 0) {
															this.zonesLabel = response[0][this.selectedLanguage];
														}
														for (var k = 0; k < this.zonesLabel.length; k++) {
															this.zoneObject = Object.entries(this.zonesLabel[k].zone);
															if (this.zoneKey === this.zoneObject[0][0]) {
																this.zone = this.zoneObject[0][1];
																this.areaArray = this.zonesLabel[k].areas;
																if (this.areaArray) {
																	if (Object.keys(this.areaArray).length > 0) {
																		Object.entries(this.areaArray).forEach((item, index) => {
																			if (this.areaKeyValue === item[0]) {
																				this.areaValue = item[1];
																				// this.areaKey = item[0];
																			}
																		});
																	}
																}
															}
														}
													});
											}
										}
										this.address = this.UserProfileDetails.address;
										this.gender = this.UserProfileDetails.gender;
										if (this.selectedLanguage === 'mar') {
											switch (this.gender) {
												case 'F':
													this.gender = 'स्त्री';
													break;
												case 'M':
													this.gender = 'पुरुष';
													break;
												case 'O':
													this.gender = 'इतर';
													break;
											}

										} else if (this.selectedLanguage === 'en') {
											switch (this.gender) {
												case 'F':
													this.gender = 'Female';
													break;
												case 'M':
													this.gender = 'Male';
													break;
												case 'O':
													this.gender = 'Other';
													break;
											}
										}
										this.preferences = this.UserProfileDetails.preferences;
										this.store.select(fromStore.getSelectedLanguage)
											.subscribe((language) => {
												this.selectedLanguage = language;

												this.store.select(fromStore.getPreferncesList)
													.subscribe((labels) => {
														this.preferenceList = labels;
														this.userPreferenceList = [];
														this.preferences = this.UserProfileDetails.preferences;
														if (this.preferences && this.preferences.length === 0) {
															Object.entries(this.preferenceList[this.selectedLanguage]).forEach((element) => {
																let preferenceData = {};
																preferenceData['status'] = false;
																preferenceData['choosePreference'] = element;
																this.userPreferenceList.push(preferenceData);
																this.preferencesValuelist = this.userPreferenceList;

															});
														}
													});

												if (this.preferences && this.preferences.length > 0) {
													Object.entries(this.preferenceList[this.selectedLanguage]).forEach((element) => {
														let preferenceData = {};
														preferenceData['status'] = false;
														preferenceData['choosePreference'] = element;
														this.userPreferenceList.push(preferenceData);
														this.preferencesValuelist = this.userPreferenceList;

													});

													this.preferences.forEach((selectedPreference) => {
														this.preferencesValuelist.forEach((el, i) => {
															if (this.preferencesValuelist[i].choosePreference[0] === selectedPreference) {
																this.preferencesValuelist[i].status = true;
															}
														});
													});
												}
											});
									});
							}
						});

				} else {
					this.router.navigate(['/cep/feeds']);
				}

			}
		}

		// profile screen labels
		this.store.select(fromStore.getprofileScreenLabels)
			.subscribe((labels) => {
				this.updatelabels = labels;
	
				
			});

		//  Api call for profile photo
		this.store.select(fromStore.updateProfilePhoto)
			.subscribe((Response) => {
				this.store.select(fromStore.uploadContentType)
					.subscribe((imageType) => {
						if (imageType == 'profile') {
							this.imageUrl = Response[0];
							if (this.imageUrl) {
								this.store.dispatch(new fromStore.UpdateProfileDetails({
									url: this.apiDetails['user'].base_url +
										this.apiDetails['user'].updateUser.url,
									method: this.apiDetails['user'].updateUser.method,
									body: {
										userId: this.UserProfileDetails.userId,
										firstName: this.UserProfileDetails.firstName,
										middleName: this.UserProfileDetails.middleName,
										lastName: this.UserProfileDetails.lastName,
										gender: this.UserProfileDetails.gender,
										DOB: this.UserProfileDetails.DOB,
										mobile: this.UserProfileDetails.mobile,
										city: this.UserProfileDetails.city,
										zone: this.zoneKey,
										area: this.areaKeyValue,
										emailId: this.UserProfileDetails.emailId,
										address: this.UserProfileDetails.address,
										isEmail: this.isEmail,
										isSMS: this.isSms,
										isNotification: this.isNotification,
										preferences: this.preferencesUpadated,
										profilePhoto: this.imageUrl
									}
								}));
							}
						}
					});
			});

		// change Password loading 
		this.store.select(fromStore.getIsUpdatePasswardLoading)
			.subscribe((res) => {
				this.isLoading = res;
			});

		// change password ApI response
		this.store.select(fromStore.getIsUpdatePasswardLoaded)
			.subscribe(res => {
				this.isLoaded = res;
				if (this.isLoaded) {
					this.store.select(fromStore.updatePasswordStatusCode)
						.subscribe((res) => {
							this.updatePasswordStatusCode = res;
							if (this.updatePasswordStatusCode === 200) {
								this.store.select(fromStore.getUpdatePassward)
									.subscribe(response => {
										this.updatePasswordErrorMessage = response;
										if (this.updatePasswordErrorMessage) {
											this.confirmPass = null;
											this.pass = null;
											this.oldPassword = null;
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(res => {
													this.successMessages = res;
													this.toastr.success(this.successMessages[this.selectedLanguage][this.updatePasswordErrorMessage]);
												});
										}
									});
								this.updatePasswordErrorMessage = null;
							}
							if (this.updatePasswordStatusCode === 201) {
								// this.confirmPass = null;
								// this.pass = null;
								// this.oldPassword = null;
								this.store.select(fromStore.getErrorMessages)
									.subscribe(msg => {
										this.selectedLanguage === "en" ? this.toastr.error("Invalid Old Password") :
											this.toastr.error("जुना पासवर्ड अवैध आहे");
									});
							}
						});
				}
			});
	}



	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	onCustomSelectChange(selectedOption: object) {
		// Handle the selected option object here.
		//console.log(selectedOption);
	  }


	/**
	 * Creatd by Akanksha J
	 * For redircting to change mobile number popup
	 */
	redirectToChangeMobileNumber() {
		this.store.dispatch(new fromStore.ResetChangeMobileNumber());
		this.router.navigate(['/Login/change-mobile-number']);
	}

	/**
	 * Creatd by Prajkta Patil
	 * For matching the password with confirm password
	 */
	checkPassword(group: FormGroup) {
		let pass = group.controls.password.value;
		let confirmPass = group.controls.confirmPassword.value;
		
		return pass === confirmPass ? null : { notSame: true };
	}

	getCurrentPasswordMessageBasedOnLanguage() {
		const passwordEnMessage = 'Enter your old password';
		const passwordMarMessage = 'तुमचा जुना पासवर्ड टाका.';
		return this.selectedLanguage === 'en' ? passwordEnMessage : passwordMarMessage;
	}


	/**
	 * Creatd by Prajkta patil
	 * For getting validation message for password
	 */
	getOldPasswordErrorMessage() {
		return this.oldpassword.hasError('pattern') ? this.getCurrentPasswordMessageBasedOnLanguage() : '';
	}

	trimPassword() {
		this.oldPassword = this.oldPassword.trim();
	  }
	/**
	 * Creatd by Prajkta patil
	 * For redircting edit profile popup
	 */
	editProfilePopup() {
		this.googleAnalyticsService.eventEmitter(screenName.PROFILE, {
			log: clickedOn.EDIT_BTN,
			screenName: screenName.PROFILE + "_SCREEN",
			clickedOn: clickedOn.EDIT_BTN,
			title: clickedOn.EDIT_BTN,
			time: new Date(),
			isInput: false,
		  })
		
		const dialogRef = this.dialog.open(RegistrationComponent, {
			data: {
				userId: this.UserProfileDetails.userId,
				firstName: this.UserProfileDetails.firstName,
				middleName: this.UserProfileDetails.middleName,
				lastName: this.UserProfileDetails.lastName,
				DOB: this.UserProfileDetails.DOB,
				emailId: this.UserProfileDetails.emailId,
				zone: this.zoneKey,
				area: this.areaValue,
				areaKey: this.areaKeyValue,
				city: this.UserProfileDetails.city,
				address: this.UserProfileDetails.address,
				aadharCard: this.UserProfileDetails.aadharCard,
				drivingLicence: this.UserProfileDetails.drivingLicence,
				gender: this.UserProfileDetails.gender,
				mobile: this.UserProfileDetails.mobile,
				preferences: this.preferencesUpadated,
			},
			autoFocus: false
		});
	}

	/**
	 * Created by Prajkta patil
	 * for detecting the file upload
	 */
	changeListener($event): void {
		this.postFile($event.target);
	}

	/**
	 * created by prajkta patil
	 * For showing error if error in file upload
	 */
	postFile(target: any) {
		throw new Error('Method not implemented.');
	}

	/**
	 * created by prajkta patil
	 * For Changing the profile pic of user 
	 */
	onSelectFile(event) {
		//this.googleAnalyticsService.eventEmitter('My Profile', 'click on change Profile icon', 'Click Event', 1);
		if (event.length === 0) {
			return;
		} else if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (event) => {
				this.imgURL = event.target['result'];
				let method = "post";
				let body = {
					userId: this.UserProfileDetails.userId,
					// userId: this.props.ProfileReducer.userdata.userId,
					profilePhoto: this.imgURL,
				};
				let url = environment.base_url + "/user/v1/updateUserprofileImage";
				this.apiCallService.apiCall(method, url, body).subscribe((data: any) => {
					
					if (data.code === 200) {
						// this.getUserDetails();
						window.location.reload()
					} else {

					}
				});
			};
		}
		const fd = new FormData();
		fd.append('userId', this.UserProfileDetails.userId);
		fd.append('type', 'profile');
		fd.append('image', event.target.files[0]);
		if (fd) {
			this.store.dispatch(new fromStore.UpdateProfilePhoto(
				{
					url: this.apiDetails['objectStorage'].base_url +
						this.apiDetails['objectStorage'].storeObject.url,
					method: this.apiDetails['objectStorage'].storeObject.method,
					body: fd,
					type: 'profile'
				}));
		}
	}

	if(initialPreferenceName) {
		return true;
	}

	/**
	 * created by prajkta patil
	 * For change prefernces on click
	 */
	changePreferenceChoice(preferenceStatus, index) {
		this.isChange = true;
		//this.googleAnalyticsService.eventEmitter('My Profile', 'Click on Preferences', 'Click Event', 1);
		this.userPreferenceList[index].status = !(preferenceStatus.status);
		let updatePreferencesList = [];
		this.preferencesValuelist.forEach(element => {
			this.updatePreferences = element;
			if (this.updatePreferences.status === true) {
				this.updatePreferences = this.updatePreferences.choosePreference[0];
				updatePreferencesList.push(this.updatePreferences);
			}
		});
		this.preferencesUpadated = updatePreferencesList;
	}

	/**
	 * created by prajkta patil
	 * For changing the email notification choice
	 */
	changeIsEmail(vdlchange) {
		this.isChange = true;
		//this.googleAnalyticsService.eventEmitter('My Profile', 'click on email notification', 'Click Event', 1);
		this.isEmail = vdlchange.checked;
	}

	/**
	 * created by prajkta patil
	 * For changing the sms notification choice
	 */
	updateIsSms(vdlchange) {
		this.isChange = true;
		//this.googleAnalyticsService.eventEmitter('My Profile', 'click on SMS notification', 'Click Event', 1);
		this.isSms = vdlchange.checked;
	}

	/**
	 * created by prajkta patil
	 * For changing the push notification choice
	 */
	updateIsNotification(vdlchange) {
		this.isChange = true;
		//this.googleAnalyticsService.eventEmitter('My Profile', 'click on push notification', 'Click Event', 1);
		this.isNotification = vdlchange.checked;
	}

	/**
	 * created by Shanu Jha
	 * To verify strength of password
	 */
	verifyPasswordStrength() {

		let PassForm = this.myForm.getRawValue()
		const passwordRegx: RegExp = this.passwordRegx;
		// const password: string = this.newpassword;
		const password: string = this.pass;
		const tested = passwordRegx.test(password);
		if (tested) {
			this.changePasswordPopup();
		} else {
			const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
			const passwordMarMessage = 'आपला पासवर्ड हा किमान 8 कॅरेक्टर असणे आवश्यक आहे आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';
			console.error(this.selectedLanguage === 'en' ? passwordEnMessage : passwordMarMessage);
		}

	}


	/**
	 * created by prajkta patil
	 * For redirecting to change password confirmation popup
	 */
	changePasswordPopup() {
		if (this.oldPassword != this.pass) {
			this.assignOldPassword = this.oldPassword;
			this.assignNewPassword = this.pass;
			// this.oldPassword = null;
			// this.pass = null;
			// this.confirmPass = null;
			this.encrOldPassword = this.encrDecr.set(this.assignOldPassword);
			this.encrPass = this.encrDecr.set(this.assignNewPassword);
			this.store.dispatch(new fromStore.ResetUpdatePassword());
			const dialogRef = this.dialog.open(changePasswordPopupComponent,
				{
					data: {
						message: 'Are you sure you want to change the passward',
						mobile: this.UserProfileDetails.mobile,
						oldPassword: this.encrOldPassword,
						password: this.encrPass
					},
					width:'350px',
					height:'200px'




				});
			this.panelOpenState = !this.panelOpenState;

		}else{
		this.selectedLanguage === 'en' ? this.toastr.error("Old Password cannot be same as new Password.") : 
			this.toastr.error("जुना पासवर्ड नवीन पासवर्डसारखा असू शकत नाही.")
		}
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
		this.confirmPass = null;
		this.pass = null;
		this.oldPassword = null;
	}



	ngOnDestroy() {

		this.googleAnalyticsService.eventEmitter(
			screenName.PROFILE + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.PROFILE + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			  inputData:{
				userId: this.UserProfileDetails.userId,
				firstName: this.UserProfileDetails.firstName,
				middleName: this.UserProfileDetails.middleName,
				lastName: this.UserProfileDetails.lastName,
				gender: this.UserProfileDetails.gender,
				DOB: this.UserProfileDetails.DOB,
				mobile: this.UserProfileDetails.mobile,
				city: this.UserProfileDetails.city,
				zone: this.zoneKey,
				area: this.areaKeyValue,
				emailId: this.UserProfileDetails.emailId,
				address: this.UserProfileDetails.address,
				isEmail: this.isEmail,
				isSMS: this.isSms,
				isNotification: this.isNotification,
				preferences: this.preferencesUpadated
			}
			}
		  );
		this.serverMessage = null;
		if (this.isChange) {
			this.store.dispatch(new fromStore.UpdateProfileDetails({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].updateUser.url,
				method: this.apiDetails['user'].updateUser.method,
				body: {
					userId: this.UserProfileDetails.userId,
					firstName: this.UserProfileDetails.firstName,
					middleName: this.UserProfileDetails.middleName,
					lastName: this.UserProfileDetails.lastName,
					gender: this.UserProfileDetails.gender,
					DOB: this.UserProfileDetails.DOB,
					mobile: this.UserProfileDetails.mobile,
					city: this.UserProfileDetails.city,
					zone: this.zoneKey,
					area: this.areaKeyValue,
					emailId: this.UserProfileDetails.emailId,
					address: this.UserProfileDetails.address,
					isEmail: this.isEmail,
					isSMS: this.isSms,
					isNotification: this.isNotification,
					preferences: this.preferencesUpadated
				}
			}));
		}
	}
}
