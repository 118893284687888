import { Component, OnInit } from '@angular/core';
import { ObjectInterface } from '../../../models/global-interface';
import { Images } from 'src/app/shared/config/images-config';
import { LocalStorageService } from '../../../services/localstorage.service';
import * as fromStore from '../../../../cep/store/index';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplyCouponPopupComponent } from 'src/app/shared/components/Merchant-flow/apply-coupon-popup/apply-coupon-popup.component'

@Component({
  selector: 'app-merchant-transactions',
  templateUrl: './merchant-transactions.component.html',
  styleUrls: ['./merchant-transactions.component.scss']
})
export class MerchantTransactionsComponent implements OnInit {

  /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * variable use
	 */
  merchantTransactionList: any;
  merchantTransactionLables: any;
  serverMessage:any;
  isServerErrorMessage;

	/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any;
	errorMessages: {};

  constructor(
    private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private encrDecr: EncrDecrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
		private dialog: MatDialog,
    
  ) {
    this.store.select(fromStore.getSelectedLanguage)
    .subscribe(result => {
      this.selectedLanguage = result
    });

    this.store.select(fromStore.getMyDealsLabels)
			.subscribe(labels => {
        this.merchantTransactionLables = labels;
      
			});
   }

  ngOnInit() {

	// server error handling
  this.store.select(fromStore.getMoreServicesNetworkError)
  .subscribe((serverErrorMessage) => {
    if (serverErrorMessage != null) {
      if (serverErrorMessage === 'InvalidCredentials') {
        this.router.navigate(['/Login/login']);
      } else {
        this.serverMessage = serverErrorMessage;
        this.isServerErrorMessage = true;
        console.error(this.serverMessage);
      }
    }
  });


    this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        const localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);       
      }
  });
  
  this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
    });
    
			if (Object.keys(this.apiDetails).length > 0 && this.userId) {
				this.store.dispatch(new fromStore.MerchantTransactionList({
				  url: this.apiDetails.user.base_url +
					this.apiDetails.user.merchantTransactions.url,
				  method: this.apiDetails.user.merchantTransactions.method,
				  body:{
							userId : this.userId
						}
				}));
			}

    this.store.select(fromStore.isMerchantTransactionListLoading)
    .subscribe(result => {
      this.isLoading = result
    });
    this.store.select(fromStore.isMerchantTransactionListLoaded)
    .subscribe(isLoaded => {
      if(isLoaded){
        this.store.select(fromStore.merchantTransactionListStatusCode)
        .subscribe(code => {
          if(code===200){
            this.store.select(fromStore.merchantTransactionListResult)
            .subscribe(result => {
              this.merchantTransactionList = result
              
              
            });
          }else if (code === 500) {
            this.store.select(fromStore.getErrorMessages)
              .subscribe((errorMessage) => {
                this.dataNotFoundMsg = "Data not found!";
                this.errorMessages = errorMessage;
              });
          } else {
            this.store.select(fromStore.getErrorMessages)
              .subscribe(errorMessages => {
                console.error(errorMessages[this.selectedLanguage].code);
              });
          }
        });
       
      }
    });
  }

  openPopUp(){
    
    this.dialog.open(ApplyCouponPopupComponent, {
      disableClose: true,
				maxWidth: '100vw',
				autoFocus: false,
			data: {
				transaction: true
			}
		})
  }


}
