import { Injectable } from '@angular/core';
import { EncrDecrUtil } from 'src/app/util/EncrDecr';
import { Router, ActivatedRoute ,NavigationEnd} from '@angular/router';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { LocalStorageService } from './shared/services/localstorage.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../app/cep/store/index';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInUser: string;
	// isLoggedIn = false;
	hasRedirected = false;
  isLogin = false;
  userId: string;
  apiDetails: {};
  isChatbot = true;
  isLoading = true;
//   public isLoggedIn = false;
  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService,
    private store: Store<fromStore.CEPState>,
    // private encrDecrUtil: EncrDecrUtil
    
  ) { }


  login(username: string, password: string): boolean {
    // if (username === 'admin' && password === 'admin123') {
    //   this.isLoggedIn = true;
    //   localStorage.setItem('isLoggedIn', 'true');
    //   return true;
    // } else {
    //   this.isLoggedIn = false;
    //   return false;
    // }

     // Check if the user is already logged in
     if (this.loggedInUser) {
      return false; // Prevent multiple logins
    }

    // Perform authentication logic here
    if (username === 'admin' && password === 'admin123') {
      this.loggedInUser = username; // Set the logged-in user
      return true; // Successful login
    } else {
      return false; // Failed login
    }
  }

  logout(): void {
    localStorage.removeItem('isLoggedIn');
    // this.isLoggedIn = false;
	  this.hasRedirected = false;
    this.loggedInUser = null;
  }

  isLoggedIn(): boolean {
    // Check if the user is logged in
    return !!this.loggedInUser;
  }

//   redirectIfNotLoggedIn(): boolean {
//     if (!this.isLoggedIn && !this.hasRedirected) {
//       this.hasRedirected = true;
//       this.router.navigate(['/login']);
//       return true;
//     }
//     return false;
//   }

redirectIfNotLoggedIn(): boolean {
	if (!this.isLoggedIn && !this.hasRedirected) {
	  this.hasRedirected = true;
	  this.router.navigate(['/login']);
	  return true;
	} else if (this.isLoggedIn && this.hasRedirected) {
	  this.hasRedirected = false;
	}
	return false;
}
  



//   logoutTheUser = async () => {

// 		const encrDecr = new EncrDecrUtil();

//        this.store.select(fromStore.getApiCallingList)
// 			.subscribe((apiDetails) => {
// 				this.apiDetails = apiDetails;
// 				if (Object.keys(this.apiDetails).length > 0) {
// 					this.store.dispatch(new fromStore.Logout({
// 						url: "https://api.pmccare.in/user/v1/logOut",
// 						method: "post",
// 						body: {
// 							userId: encrDecr.get(localStorage.getItem('userId'))
// 						}
// 					}));
// 				}
// 			});

		
// 		this.isLogin = false;
// 		this.isChatbot = true;
// 		if (window.location.pathname !== '/Login/login') {
// 			this.isLoading = true;
// 		} else {
// 			this.isLoading = false;
// 		}
// 		await this.router.navigate(['/Login/login']);
// 		await //this.googleAnalyticsService.eventEmitter('Logout', 'User Logout', 'Click Event', 1);
// 		await this.store.dispatch(new fromStore.ResetMobileNumber());
// 		await this.store.dispatch(new fromStore.ResetLogin());
// 		await this.store.dispatch(new fromStore.ResetUpdateProfile());
// 		await this.store.dispatch(new fromStore.ResetChangeMobileNumber());
// 		await this.store.dispatch(new fromStore.ResetForgetPassword());
// 		await this.store.dispatch(new fromStore.ResetUpdatePassword());
// 		await this.store.dispatch(new fromStore.ResetFeeds());
// 		await this.store.dispatch(new fromStore.ResetChatbot());
// 		await this.store.dispatch(new fromStore.ResetFavourite({}));
// 		await this.localStorageService.clearLocalStorage();
// 		localStorage.clear();
// 		this.userId = null;
// 	}


  

}
