import * as fromBirthDateActionCreator from "../action-creator/birth-date.action-creator";
import * as fromBirthDateActions from "../action/birth-date.action";

export interface BirthDateListItemState {
    mother_name_mar: string;
    father_name: string;
    child_fname_mar:string;
    gender1:string;
    birth_date:number;
    registration_no:number;
    
    
}  

export interface BirthDateListState {
    birthDateList: Array<BirthDateListItemState>;
}

export const initialState: BirthDateListState = {
    birthDateList: []
};

export function reducer(
    state = initialState,
    action: fromBirthDateActionCreator.birthDateAction
): BirthDateListState {
    switch (action.type) {
        case fromBirthDateActions.GET_BIRTH_DATE_LIST: {
            
            return { ...state }
        }
        case fromBirthDateActions.GET_BIRTH_DATE_LIST_FAIL: {
    
            return { ...state }
        }
        case fromBirthDateActions.GET_BIRTH_DATE_LIST_SUCCESS: {
            
            return { ...state, birthDateList: action.payload.result, }
        }
        default: {
            return { ...state }
        }
    }

}


export const getBirthDateList = (state: BirthDateListState) => state.birthDateList;
