import * as fromService from "../action/service.action";

export interface ServiceState {
  servicesServerErrorMessage: string;
  result: {};
  isServiceStateLoading: boolean;
  isServiceStateLoaded: boolean;

  getGrievanceListingResult: {};
  getGrievanceListingStatusCode: number;
  getGrievanceListingSuccessMessage: string;
  grievancePaginationCount: number;
  isGetGrievanceListingLoading: boolean;
  isGetGrievanceListingLoaded: boolean;

  addGrievanceResult: {};
  addGrievanceStatusCode: number;
  addGrievanceSuccessMessage: string;
  isAddGrievanceLoading: boolean;
  isAddGrievanceLoaded: boolean;

  grievanceDetailResult: {};
  grievanceDetailStatusCode: number;
  grievanceDetailSuccessMessage: string;
  isGrievanceDetailLoading: boolean;
  isGrievanceDetailLoaded: boolean;

  grievanceVoteResult: {};
  grievanceVoteStatusCode: number;
  grievanceVoteSuccessMessage: string;
  isGrievanceVoteLoading: boolean;
  isGrievanceVoteLoaded: boolean;

  grievanceCategoryResult: {};
  grievanceCategoryStatusCode: number;
  grievanceCategorySuccessMessage: string;
  isGrievanceCategoryLoading: boolean;
  isGrievanceCategoryLoaded: boolean;

  storeCoronaSurveyDetails: {};
  latitude: number;
  longitude: number;

  vaccinationCenterListResult: {};
  vaccinationCenterListStatusCode: number;
  vaccinationCenterListSuccessMessage: string;
  isVaccinationCenterListLoading: boolean;
  isVaccinationCenterListLoaded: boolean;

  strapiContent: [];
}

export const initialState: ServiceState = {
  result: {},
  isServiceStateLoading: false,
  isServiceStateLoaded: false,
  servicesServerErrorMessage: null,

  getGrievanceListingResult: {},
  getGrievanceListingStatusCode: 0,
  grievancePaginationCount: 0,
  getGrievanceListingSuccessMessage: null,
  isGetGrievanceListingLoading: false,
  isGetGrievanceListingLoaded: false,

  addGrievanceResult: {},
  addGrievanceStatusCode: 0,
  addGrievanceSuccessMessage: null,
  isAddGrievanceLoading: false,
  isAddGrievanceLoaded: false,

  grievanceDetailResult: {},
  grievanceDetailStatusCode: 0,
  grievanceDetailSuccessMessage: null,
  isGrievanceDetailLoading: false,
  isGrievanceDetailLoaded: false,

  grievanceVoteResult: {},
  grievanceVoteStatusCode: 0,
  grievanceVoteSuccessMessage: null,
  isGrievanceVoteLoading: false,
  isGrievanceVoteLoaded: false,

  grievanceCategoryResult: {},
  grievanceCategoryStatusCode: 0,
  grievanceCategorySuccessMessage: null,
  isGrievanceCategoryLoading: false,
  isGrievanceCategoryLoaded: false,

  vaccinationCenterListResult: {},
  vaccinationCenterListStatusCode: 0,
  vaccinationCenterListSuccessMessage: null,
  isVaccinationCenterListLoading: false,
  isVaccinationCenterListLoaded: false,

  storeCoronaSurveyDetails: {},
  latitude: 0,
  longitude: 0,
  strapiContent: [],
};

export function reducer(
  state = initialState,
  action: fromService.serviceAction
): ServiceState {
  switch (action.type) {
    case fromService.GET_SERVICE_DETAILS: {
      return {
        ...state,
        isServiceStateLoading: true,
        isServiceStateLoaded: false,
      };
    }
    case fromService.GET_SERVICE_DETAILS_SUCCESS: {
      let statusCode = action.payload.code;
      let result = state.result;
      let isServiceStateLoading = state.isServiceStateLoading;
      let isServiceStateLoaded = state.isServiceStateLoaded;
      switch (statusCode) {
        case 200:
          result = action.payload.result;
          isServiceStateLoading = false;
          isServiceStateLoaded = true;
          break;
        case 498:
          isServiceStateLoading = false;
          isServiceStateLoaded = true;
          break;
      }
      return {
        ...state,
        result,
        isServiceStateLoading,
        isServiceStateLoaded,
      };
    }
    case fromService.GET_SERVICE_DETAILS_FAIL: {
      let statusCode = action.payload.status;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
      };
    }

    case fromService.GET_GRIEVANCE_LISTING: {
      let grievancePaginationCount = state.grievancePaginationCount;
      let isSortBy = action.payload.isSortBy;
      let getGrievanceListingResult = state.getGrievanceListingResult;
      let isSortByUser = action.payload.isSortByUser;
      if (isSortByUser == true) {
        getGrievanceListingResult = [];
        grievancePaginationCount = 0;
      }
      if (isSortBy == true) {
        getGrievanceListingResult = [];
        grievancePaginationCount = 0;
      }
      return {
        ...state,
        isGetGrievanceListingLoading: true,
        grievancePaginationCount,
        getGrievanceListingResult,
        isGetGrievanceListingLoaded: false,
      };
    }
    case fromService.GET_GRIEVANCE_LISTING_SUCCESS: {
      let statusCode = action.payload.code;
      let getGrievanceListingResult = state.getGrievanceListingResult;
      let getGrievanceListingStatusCode = statusCode;
      let isGetGrievanceListingLoading = state.isGetGrievanceListingLoading;
      let isGetGrievanceListingLoaded = state.isGetGrievanceListingLoaded;
      let getGrievanceListingSuccessMessage =
        state.getGrievanceListingSuccessMessage;
      let paginationCount = state.grievancePaginationCount;
      let dataGrievance;
      switch (statusCode) {
        case 200:
          if (paginationCount == 0) {
            getGrievanceListingResult = action.payload.result;
          } else {
            dataGrievance = getGrievanceListingResult;
            getGrievanceListingResult = [
              ...dataGrievance,
              ...action.payload.result,
            ];
          }
          getGrievanceListingSuccessMessage = action.payload.message;
          isGetGrievanceListingLoading = false;
          isGetGrievanceListingLoaded = true;
          break;
        case 498:
          isGetGrievanceListingLoading = false;
          isGetGrievanceListingLoaded = true;
          break;
        case 500:
          getGrievanceListingSuccessMessage = action.payload.message;
          isGetGrievanceListingLoading = false;
          isGetGrievanceListingLoaded = true;
          break;
      }
      return {
        ...state,
        getGrievanceListingResult,
        getGrievanceListingStatusCode,
        getGrievanceListingSuccessMessage,
        isGetGrievanceListingLoading,
        isGetGrievanceListingLoaded,
      };
    }
    case fromService.GET_GRIEVANCE_LISTING_FAIL: {
      let statusCode = action.payload.status;
      let getGrievanceListingStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isGetGrievanceListingLoading: false,
        isGetGrievanceListingLoaded: true,
        getGrievanceListingStatusCode,
      };
    }

    case fromService.SET_PAGINATION_COUNT_GRIEVANCE: {
      let grievancePaginationCount = action.payload.grievancePaginationCount;
      let getGrievanceListingResult = state.getGrievanceListingResult;
      if (grievancePaginationCount == 0) {
        getGrievanceListingResult = [];
      }
      return {
        ...state,
        grievancePaginationCount,
        getGrievanceListingResult,
      };
    }

    case fromService.ADD_GRIEVANCE: {
      return {
        ...state,
        isAddGrievanceLoading: true,
        isAddGrievanceLoaded: false,
      };
    }
    case fromService.ADD_GRIEVANCE_SUCCESS: {
      let statusCode = action.payload.code;
      let addGrievanceResult = state.addGrievanceResult;
      let addGrievanceStatusCode = statusCode;
      let addGrievanceSuccessMessage = state.addGrievanceSuccessMessage;
      switch (statusCode) {
        case 200:
          addGrievanceResult = action.payload.result;
          addGrievanceSuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        addGrievanceResult,
        addGrievanceStatusCode,
        addGrievanceSuccessMessage,
        isAddGrievanceLoading: false,
        isAddGrievanceLoaded: true,
      };
    }
    case fromService.ADD_GRIEVANCE_FAIL: {
      let statusCode = action.payload.status;
      let addGrievanceStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isAddGrievanceLoading: false,
        isAddGrievanceLoaded: true,
        addGrievanceStatusCode,
      };
    }

    case fromService.GRIEVANCE_DETAIL: {
      return {
        ...state,
        isGrievanceDetailLoading: true,
        isGrievanceDetailLoaded: false,
      };
    }
    case fromService.GRIEVANCE_DETAIL_SUCCESS: {
      let statusCode = action.payload.code;
      let grievanceDetailResult = state.grievanceDetailResult;
      let grievanceDetailStatusCode = statusCode;
      let grievanceDetailSuccessMessage = state.grievanceDetailSuccessMessage;
      switch (statusCode) {
        case 200:
          grievanceDetailResult = action.payload.result;
          grievanceDetailSuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        grievanceDetailResult,
        grievanceDetailStatusCode,
        grievanceDetailSuccessMessage,
        isGrievanceDetailLoading: false,
        isGrievanceDetailLoaded: true,
      };
    }
    case fromService.GRIEVANCE_DETAIL_FAIL: {
      let statusCode = action.payload.status;
      let grievanceDetailStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isGrievanceDetailLoading: false,
        isGrievanceDetailLoaded: true,
        grievanceDetailStatusCode,
      };
    }

    case fromService.GRIEVANCE_VOTE: {
      return {
        ...state,
        isGrievanceVoteLoading: true,
        isGrievanceVoteLoaded: false,
      };
    }
    case fromService.GRIEVANCE_VOTE_SUCCESS: {
      let statusCode = action.payload.code;
      let grievanceVoteResult = state.grievanceVoteResult;
      let grievanceVoteStatusCode = statusCode;
      let grievanceVoteSuccessMessage = state.grievanceVoteSuccessMessage;
      switch (statusCode) {
        case 200:
          grievanceVoteResult = action.payload.result;
          grievanceVoteSuccessMessage = action.payload.message;
          break;
        case 500:
          grievanceVoteResult = action.payload.result;
          grievanceVoteSuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        grievanceVoteResult,
        grievanceVoteStatusCode,
        grievanceVoteSuccessMessage,
        isGrievanceVoteLoading: false,
        isGrievanceVoteLoaded: true,
      };
    }
    case fromService.GRIEVANCE_VOTE_FAIL: {
      let statusCode = action.payload.status;
      let grievanceVoteStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isGrievanceVoteLoading: false,
        isGrievanceVoteLoaded: true,
        grievanceVoteStatusCode,
      };
    }

    case fromService.GRIEVANCE_CATEGORY: {
      return {
        ...state,
        isGrievanceCategoryLoading: true,
        isGrievanceCategoryLoaded: false,
      };
    }
    case fromService.GRIEVANCE_CATEGORY_SUCCESS: {
      let statusCode = action.payload.code;
      let grievanceCategoryResult = state.grievanceCategoryResult;
      let grievanceCategoryStatusCode = statusCode;
      let grievanceCategorySuccessMessage =
        state.grievanceCategorySuccessMessage;
      switch (statusCode) {
        case 200:
          grievanceCategoryResult = action.payload.result;
          grievanceCategorySuccessMessage = action.payload.message;
          break;
        case 500:
          grievanceCategoryResult = action.payload.result;
          grievanceCategorySuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        grievanceCategoryResult,
        grievanceCategoryStatusCode,
        grievanceCategorySuccessMessage,
        isGrievanceCategoryLoading: false,
        isGrievanceCategoryLoaded: true,
      };
    }
    case fromService.GRIEVANCE_CATEGORY_FAIL: {
      let statusCode = action.payload.status;
      let grievanceCategoryStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isGrievanceCategoryLoading: false,
        isGrievanceCategoryLoaded: true,
        grievanceCategoryStatusCode,
      };
    }

    case fromService.VACCINATION_CENTER_LIST: {
      return {
        ...state,
        isVaccinationCenterListLoading: true,
        isVaccinationCenterListLoaded: false,
      };
    }
    case fromService.VACCINATION_CENTER_LIST_SUCCESS: {
      let statusCode = action.payload.code;
      let vaccinationCenterListResult = state.vaccinationCenterListResult;
      let vaccinationCenterListStatusCode = statusCode;
      let vaccinationCenterListSuccessMessage =
        state.vaccinationCenterListSuccessMessage;
      switch (statusCode) {
        case 200:
          vaccinationCenterListResult = action.payload.result;
          vaccinationCenterListSuccessMessage = action.payload.message;
          break;
        case 500:
          vaccinationCenterListResult = action.payload.result;
          vaccinationCenterListSuccessMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        vaccinationCenterListResult,
        vaccinationCenterListStatusCode,
        vaccinationCenterListSuccessMessage,
        isVaccinationCenterListLoading: false,
        isVaccinationCenterListLoaded: true,
      };
    }
    case fromService.VACCINATION_CENTER_LIST_FAIL: {
      let statusCode = action.payload.status;
      let vaccinationCenterListStatusCode = statusCode;
      let servicesServerErrorMessage = state.servicesServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        servicesServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        servicesServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        servicesServerErrorMessage,
        isVaccinationCenterListLoading: false,
        isVaccinationCenterListLoaded: true,
        vaccinationCenterListStatusCode,
      };
    }

    case fromService.RESET_VACCINATIONLIST: {
      return {
        ...state,
        vaccinationCenterListResult: [],
        vaccinationCenterListStatusCode: 0,
        servicesServerErrorMessage: null,
        isVaccinationCenterListLoading: false,
        isVaccinationCenterListLoaded: false,
      };
    }

    case fromService.STORE_CORONA_SURVEY_DETAILS: {
      let storeCoronaSurveyDetails = action.payload.surveyDetails;
      let latitude = action.payload.latitude;
      let longitude = action.payload.longitude;
      return {
        ...state,
        storeCoronaSurveyDetails,
        latitude,
        longitude,
      };
    }

    case fromService.FETCH_STRAPI_CONTENT:
      return {
        ...state,
        isServiceStateLoaded: false,
        isServiceStateLoading: true,
      }; //enable loading

    case fromService.FETCH_STRAPI_CONTENT_FAIL:
      return {
        ...state,
        isServiceStateLoaded: true,
        isServiceStateLoading: false,
      }; //enable loadinG and stop loading

    case fromService.FETCH_STRAPI_CONTENT_SUCCESS:
      return {
        ...state,
        strapiContent: action.payload.data,
        isServiceStateLoaded: true,
        isServiceStateLoading: false,
      }; //enable loading and stop loading and load data
  }
  return state;
}

export const getServiceResult = (state: ServiceState) => state.result;
export const getServiceStateLoading = (state: ServiceState) =>
  state.isServiceStateLoading;
export const getServiceStateLoaded = (state: ServiceState) =>
  state.isServiceStateLoaded;
export const getServicesServerErrorMessage = (state: ServiceState) =>
  state.servicesServerErrorMessage;

export const getGrievanceListingResult = (state: ServiceState) =>
  state.getGrievanceListingResult;
export const getGrievanceListingSuccessMessage = (state: ServiceState) =>
  state.getGrievanceListingSuccessMessage;
export const getGrievanceListingStatusCode = (state: ServiceState) =>
  state.getGrievanceListingStatusCode;
export const isGetGrievanceListingLoading = (state: ServiceState) =>
  state.isGetGrievanceListingLoading;
export const isGetGrievanceListingLoaded = (state: ServiceState) =>
  state.isGetGrievanceListingLoaded;
export const getGrievancePaginationCount = (state: ServiceState) =>
  state.grievancePaginationCount;

export const addGrievanceResult = (state: ServiceState) =>
  state.addGrievanceResult;
export const addGrievanceSuccessMessage = (state: ServiceState) =>
  state.addGrievanceSuccessMessage;
export const addGrievanceStatusCode = (state: ServiceState) =>
  state.addGrievanceStatusCode;
export const isAddGrievanceLoading = (state: ServiceState) =>
  state.isAddGrievanceLoading;
export const isAddGrievanceLoaded = (state: ServiceState) =>
  state.isAddGrievanceLoaded;

export const grievanceDetailResult = (state: ServiceState) =>
  state.grievanceDetailResult;
export const grievanceDetailSuccessMessage = (state: ServiceState) =>
  state.grievanceDetailSuccessMessage;
export const grievanceDetailStatusCode = (state: ServiceState) =>
  state.grievanceDetailStatusCode;
export const isGrievanceDetailLoading = (state: ServiceState) =>
  state.isGrievanceDetailLoading;
export const isGrievanceDetailLoaded = (state: ServiceState) =>
  state.isGrievanceDetailLoaded;

export const grievanceVoteResult = (state: ServiceState) =>
  state.grievanceVoteResult;
export const grievanceVoteSuccessMessage = (state: ServiceState) =>
  state.grievanceVoteSuccessMessage;
export const grievanceVoteStatusCode = (state: ServiceState) =>
  state.grievanceVoteStatusCode;
export const isGrievanceVoteLoading = (state: ServiceState) =>
  state.isGrievanceVoteLoading;
export const isGrievanceVoteLoaded = (state: ServiceState) =>
  state.isGrievanceVoteLoaded;

export const grievanceCategoryResult = (state: ServiceState) =>
  state.grievanceCategoryResult;
export const grievanceCategorySuccessMessage = (state: ServiceState) =>
  state.grievanceCategorySuccessMessage;
export const grievanceCategoryStatusCode = (state: ServiceState) =>
  state.grievanceCategoryStatusCode;
export const isGrievanceCategoryLoading = (state: ServiceState) =>
  state.isGrievanceCategoryLoading;
export const isGrievanceCategoryLoaded = (state: ServiceState) =>
  state.isGrievanceCategoryLoaded;

export const vaccinationCenterListResult = (state: ServiceState) =>
  state.vaccinationCenterListResult;
export const vaccinationCenterListSuccessMessage = (state: ServiceState) =>
  state.vaccinationCenterListSuccessMessage;
export const vaccinationCenterListStatusCode = (state: ServiceState) =>
  state.vaccinationCenterListStatusCode;
export const isVaccinationCenterListLoading = (state: ServiceState) =>
  state.isVaccinationCenterListLoading;
export const isVaccinationCenterListLoaded = (state: ServiceState) =>
  state.isVaccinationCenterListLoaded;

export const getCoronaSurveyDetails = (state: ServiceState) =>
  state.storeCoronaSurveyDetails;
export const getLatitude = (state: ServiceState) => state.latitude;
export const getLongitude = (state: ServiceState) => state.longitude;

export const strapiContentSelector = (state: ServiceState) =>
  state.strapiContent;
