import { Component, OnInit, ChangeDetectorRef, Input, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { FeedbackComponent } from '../../../shared/components/feedback/feedback.component';
import { EncrDecrService } from '../../../shared/services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Images } from '../../../shared/config/images-config'
import { ToastrService } from 'ngx-toastr';
import { ReportAbusePopupComponent } from '../../../shared/components/report-abuse-popup/report-abuse-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ObjectInterface } from '../../../shared/models/global-interface';
import { take } from 'rxjs/operators';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';

@Component({
  selector: 'app-details-webinar',
  templateUrl: './details-webinar.component.html',
  styleUrls: ['./details-webinar.component.scss']
})
export class DetailsWebinarComponent implements OnInit {
  image = Images;
  @Input() newsData: any;
  public feedsData: {};

  public newsDate;
  apiDetails : ObjectInterface;
  webinarId: any;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  isLoaded = false;
  isLoading = false;
  selectedLanguage: string;
  landingLables: {};
  webinarData:{};
  feedsDate: string;
  feedFilterLabels: {};
  feedLabels: {};
  userId: any;
  token: string;
  feedStatusCode: number;
  shareUrl: any;
  errMessage: string;
  feedsContent: any;
  videourl: SafeResourceUrl;
  video:any;
  height: any;
  width: any;
  postedByUser : any;
  webinarLabels: {};
  /**
	 * variable for webinar comment
	 */
	webinarComment: any;
  /**
	 * array for feed details result
	 */
  feedDetailsResultArray=[];
  UserProfileDetails: ObjectInterface;
/**
	 * favorite variable
	 */
	favorite: string;
  successMessages: {};
  feedDetailsComments: ObjectInterface[];
/**
	 * variable for comment date
	 */
  commentDate: any;
  isBlind;
  /**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	*/
  constructor(private activeParams: ActivatedRoute,
    private store: Store<fromStore.CEPState>,
    private changeDetectRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    public dialog: MatDialog,
		private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
		private apiCallService: ApiCallService,
    private router: Router) { }

    setVideoUrl(videoUrl: string): void {
      if (videoUrl) {
          let videoId = '';
  
          // Check for 'embed' link
          if (videoUrl.includes('/embed/')) {
              videoId = videoUrl.split('/embed/')[1];
          }
          // Check for standard 'watch' link
          else if (videoUrl.includes('watch?v=')) {
              videoId = videoUrl.split('watch?v=')[1];
          }
          // Check for short 'youtu.be' link
          else if (videoUrl.includes('youtu.be/')) {
              videoId = videoUrl.split('youtu.be/')[1];
          }
  
          if (videoId) {
              this.videourl = this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoId);
          } else {
              // Handle cases where the video ID could not be extracted
              console.error("Invalid YouTube URL");
          }
      } else {
          console.error("Video URL is undefined");
      }
  }

  ngOnInit() {

    this.googleAnalyticsService.eventEmitter(
			screenName.WEBINAR_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.WEBINAR_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
    this.activeParams.params.subscribe((params) => {
      this.webinarId = params.videoId;
     
    });
    const isMobile = this.deviceService.isMobile();
    if(isMobile){
      this.height = 300;
      this.width = screen.width;
    }
    else{
      this.width = 712;
      this.height=515
    }
    this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        let localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
        //this.googleAnalyticsService.eventEmitter('Dashboard', 'click on news by ' + this.userId, 'userLabel', 1);
      } else {
        this.userId = null;
        //this.googleAnalyticsService.eventEmitter('Dashboard', 'user click on news', 'userLabel', 1);
      }
    });
    this.store.select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
      this.shareUrl = this.apiDetails.shareUrl;
      this.store.dispatch(new fromStore.GetWebinarById({
        url: this.apiDetails['user'].base_url +
             this.apiDetails['user'].getWebinarById.url,
         method: this.apiDetails['user'].getWebinarById.method,
        body: {
          webinarId: this.webinarId,
          userId : this.userId
        }
      }))
      window.scrollTo(0, 0);
    });


    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
        this.store.select(fromStore.getLandingScreenLabels)
          .subscribe((labels) => {
            this.landingLables = labels;
          });
        this.store.select(fromStore.getfeedsFilterLabel)
          .subscribe((labels) => {
            this.feedFilterLabels = labels;
          });
        this.store.select(fromStore.getFeedsLabels)
          .subscribe((labels) => {
            this.feedLabels = labels;
          });
      });

      this.store
      .select(fromStore.getIsWebinarByIdLoading)
      .subscribe(isLoading => {
        this.isLoading = isLoading;
      });
      
      this.store.select(fromStore.getWebinarByIdResult)
      .subscribe((data) => {       
        this.webinarData = data;
        if(this.webinarData["createdName"]){
          this.postedByUser = this.webinarData["createdName"];
        }else{
          this.postedByUser = "N/A";
        }
        if (this.webinarData['favourite']) {
          this.favorite = 'favorite';
        } else {
          this.favorite = 'favorite_border';
        }

        
        if (this.webinarData['comment']) {
          this.feedDetailsResultArray = this.webinarData['comment']
    
            
          this.feedDetailsResultArray.forEach((element: any) => {
            if (element.comment.length > 50){
                element['visible'] = false;                
            }else{
              element['visible'] = true;              
            }         
          });
        }      
        this.video = this.webinarData["videoId"]
  
        if(this.video != undefined){
          
          this.setVideoUrl(this.video);
        }
        // this.videourl =  this.domSanitizer.bypassSecurityTrustResourceUrl(this.webinarData["videoId"])
      });

      this.store
      .select(fromStore.getWebinarLabels)
      .subscribe(webinarLabels => {
        this.webinarLabels = webinarLabels;
      });


      this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((data) => {        
				this.UserProfileDetails = data;
			});
  }




/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return this.splitSentance(span.textContent || span.innerText)
	  };

	  /**
	 * Created by Vinita Sharma
	 * to split the paragraph in sentances
	 */
	splitSentance(sen){
		if(!sen.match(/[^\.!\?]+[\.!\?]+/g)){
			return [sen]
		}else{
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
			speechSynthesis.cancel();
			var newmessage = this.extractContent(textToSpeak)	
			var voices = speechSynthesis.getVoices();
			
			
			for (let sentance = 0; sentance < newmessage.length; sentance++) {
				var message = new SpeechSynthesisUtterance(newmessage[sentance]);
				message.voice = voices[9];
				message.lang = 'hi-IN';
				message.volume = 1;
				speechSynthesis.speak(message);
			}
		}
	}


 /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	stopSpeaking(){
		speechSynthesis.cancel();
	  }






	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
	unMarkFavorite() {
		if (this.webinarData['favourite']) {
			this.webinarData = Object.assign({}, this.webinarData, { favourite: false });
			this.favorite = 'favorite_border';
      this.googleAnalyticsService.eventEmitter(screenName.WEBINAR_DETAILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.WEBINAR_DETAILS + "_SCREEN",
				SECTION: screenName.WEBINAR_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.webinarId.timelineId
			  })
		} else {
			this.webinarData = Object.assign({}, this.webinarData, { favourite: true });
			this.favorite = 'favorite';
      this.googleAnalyticsService.eventEmitter(screenName.WEBINAR_DETAILS, {
				log: clickedOn.LIKE,
				screenName: screenName.WEBINAR_DETAILS + "_SCREEN",
				SECTION: screenName.WEBINAR_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.webinarId.timelineId
			  })
		}

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavoriteWebinar({
          url: this.apiDetails['user'].base_url +
               this.apiDetails['user'].addToFavoriteWebinar.url,
          method: this.apiDetails['user'].addToFavoriteWebinar.method,
					body: {
						userId: this.userId,
            webinarId: this.webinarData['webinarId'],
					}
				}));
			}
    }
	}
 
	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/webinar/" +this.webinarData['webinarId']
		var metaTag  = {
			socialTitle: this.webinarData['en'].title,
			socialImageLink: this.webinarData['image']
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	/**
	 * Created by Vinita Sharma
	 * for reporting blog as abuse
	 */
	reportAsAbuse() {
    this.googleAnalyticsService.eventEmitter(screenName.WEBINAR_DETAILS, {
      log: clickedOn.REPORT_ABUSE,
      screenName: screenName.WEBINAR_DETAILS + "_SCREEN",
      SECTION: screenName.WEBINAR_DETAILS ,
      clickedOn: clickedOn.BUTTON,
      title: clickedOn.REPORT_ABUSE,
      time: new Date(),
      isInput: false,
      contentId:this.webinarId.timelineId
      })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				webinarId: this.webinarId,
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
  }
  
  /**
	 * Created By Vinita Sharma
	 * For reporting comment as abuse
	 */
	reportComment(data){
    this.googleAnalyticsService.eventEmitter(screenName.WEBINAR_DETAILS, {
      log: clickedOn.WEBINAR_COMMENT,
      screenName: screenName.WEBINAR_DETAILS + "_SCREEN",
      SECTION: screenName.WEBINAR_DETAILS ,
      clickedOn: clickedOn.BUTTON,
      title: clickedOn.WEBINAR_COMMENT,
      time: new Date(),
      isInput: false,
      contentId:this.webinarId.timelineId
      })

		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId : this.userId,
				webinarId : this.webinarId,
        commentId : data.commentId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}


  /**
	 * Created by Vinita Sharma
	 * for adding comment on webinar
	 */
	addWebinarComment(comment) {
    this.googleAnalyticsService.eventEmitter(screenName.WEBINAR_DETAILS, {
      log: clickedOn.ADDWEBINAR_COMMENT,
      screenName: screenName.WEBINAR_DETAILS + "_SCREEN",
      SECTION: screenName.WEBINAR_DETAILS ,
      clickedOn: clickedOn.BUTTON,
      title: clickedOn.ADDWEBINAR_COMMENT,
      time: new Date(),
      isInput: false,
      contentId:this.webinarId.timelineId
      })
		let commentData={};
		commentData['userId']= this.userId;
    commentData['updatedBy'] = this.UserProfileDetails.firstName;
    commentData['firstName'] = this.UserProfileDetails.firstName;
    commentData['middleName'] = this.UserProfileDetails.middleName
    commentData['lastName'] = this.UserProfileDetails.lastName
		commentData['comment']= comment;
		commentData['updatedAt'] = Date();
		commentData['engagementId']= this.webinarId;
    commentData['profilePhoto']= this.UserProfileDetails.profilePhoto;
    if(comment.length > 50){
      commentData['visible'] = false;      
    }else if(comment.length < 50){
       commentData['visible'] = true;       
    }
    
   this.feedDetailsResultArray.unshift(commentData);        

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
        
				this.webinarComment = '';
				this.store.dispatch(new fromStore.AddOrRemoveWebinarComment({
					url: this.apiDetails.userEngagement.base_url +
						this.apiDetails.userEngagement.comment.addComment.url,
					method: this.apiDetails.userEngagement.comment.addComment.method,
					body: {
						userId: this.userId,
						comment: comment,
						engagementId: this.webinarId,
						firstName: this.UserProfileDetails.firstName,
						middleName: this.UserProfileDetails.middleName,
						lastName: this.UserProfileDetails.lastName,
						profilePhoto: this.UserProfileDetails.profilePhoto
						
					}
				}));
				this.store.select(fromStore.AddOrRemoveWebinarCommentStatusCode)
				.subscribe(statusCode => {
          
					if (statusCode === 200) {
						this.store.select(fromStore.AddOrRemoveWebinarCommentErrorMessage)
							.subscribe((message) => {
                if (message) {
	
									this.store.select(fromStore.getSuccessMessages)
										.subscribe(res => {
											this.successMessages = res;
											this.toastr.success(this.successMessages[this.selectedLanguage][message]);
										});
								}
							});
					}
				});
			} else if (!this.userId) {
				this.router.navigate(['/Login/login']);
			}
		}		
	}
  convCommentDateFormat(x) {
		this.commentDate = new Date(x).toDateString();
		return this.commentDate;
	}

  ngAfterContentChecked() {
    this.changeDetectRef.detectChanges()
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.googleAnalyticsService.eventEmitter(
			screenName.WEBINAR_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.WEBINAR_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
  }

  clickOnShare() {
    //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of news', 'Click Event', 1);
  }
}
