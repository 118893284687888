import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";
// import {MatNativeDateModule} from '@angular/material';
import { MatDialogModule } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatTreeModule } from "@angular/material/tree";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ToastrModule } from "ngx-toastr";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { QRCodeModule } from "angularx-qrcode";

import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";
import { NgxPageScrollModule } from "ngx-page-scroll";

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider,
} from "angular-6-social-login";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HeaderComponent } from "./components/header/header.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AgmCoreModule } from "@agm/core";
import { DisplayProfileComponent } from "../cep/side-menu-bar/display-profile/display-profile.component";
import { ContactUsComponent } from "../cep/side-menu-bar/contact-us/contact-us.component";
import { NewsletterSubscriptionComponent } from "../cep/side-menu-bar/newsletter-subscription/newsletter-subscription.component";
import { AboutUsComponent } from "../cep/side-menu-bar/about-us/about-us.component";
import { CardBlogComponent } from "./components/card-blog/card-blog.component";
import { CardMostUsedServiceComponent } from "./components/card-most-used-service/card-most-used-service.component";
import { CardNotificationsComponent } from "./components/card-notifications/card-notifications.component";
import { CardEventComponent } from "./components/card-event/card-event.component";
import { CardNewsComponent } from "./components/card-news/card-news.component";
import { SliderBannerComponent } from "./components/slider-banner/slider-banner.component";
import { FeedsListComponent } from "./components/feeds-list/feeds-list.component";
import { CardArticleComponent } from "./components/card-article/card-article.component";
import { CardCompetitionComponent } from "./components/card-competition/card-competition.component";
import { RegistrationComponent } from "../cep/login-management/registration/registration.component";
import { API_KEY } from "../config/api-key.config";
import { FooterComponent } from "./components/footer/footer.component";

// import { MessagingService } from './services/messaging.service';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { environment } from "src/environments/environment";
import { CardCityInfoComponent } from "./components/card-city-info/card-city-info.component";
import { DetailsPageEventComponent } from "./components/details-page-event/details-page-event.component";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { PushNotificationsService } from "./services/push-notifications.service";
import { FeedsComponent } from "../cep/dashboard-management/feeds/feeds/feeds.component";
import { DetailsPageCompetitionComponent } from "./components/details-page-competition/details-page-competition.component";
import { DetailsPageNewsComponent } from "./components/details-page-news/details-page-news.component";
import { DetailsPageBlogComponent } from "./components/details-page-blog/details-page-blog.component";
import { DetailsPageArticleComponent } from "./components/details-page-article/details-page-article.component";
import { NgOtpInputModule } from "ng-otp-input";
import { DeviceDetectorModule } from "ngx-device-detector";
import { RedirectToStoreComponent } from "./components/redirect-to-store/redirect-to-store.component";
import { EncrDecrService } from "./services/encr-decr.service";
import { PopupQrScanComponent } from "./components/popup-qr-scan/popup-qr-scan.component";
import { ChatbotComponent } from "./components/chatbot/chatbot.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { StarRatingModule } from "angular-star-rating";
import {
  GooglePlaceModule,
  GooglePlaceDirective,
} from "ngx-google-places-autocomplete";
import { CardSurveyComponent } from "./components/card-survey/card-survey.component";
import { DetailsPageSurveyComponent } from "./components/details-page-survey/details-page-survey.component";
import { DetailsPageSliderComponent } from "./components/details-page-slider/details-page-slider.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MobileDevicePopupComponent } from "./components/mobile-device-popup/mobile-device-popup.component";
import { OtpVerificationChoicesComponent } from "../../app/cep/login-management/otp-verification-choices/otp-verification-choices.component";
import { ChatbotService } from "./services/chatbotService.service";
import { CommissionerInformationComponent } from "../cep/side-menu-bar/commissioner-information/commissioner-information.component";
import { CityInformationComponent } from "../cep/side-menu-bar/city-information/city-information.component";
import { TourismInformationComponent } from "../cep/side-menu-bar/tourism-information/tourism-information.component";
import { CorporatesInformationComponent } from "../cep/side-menu-bar/corporates-information/corporates-information.component";
import { ZoneInformationComponent } from "../cep/side-menu-bar/zone-information/zone-information.component";
import { DatePipe } from "@angular/common";
import { CardProductComponent } from "./components/card-product/card-product.component";
import { DetailsPageProductComponent } from "./components/details-page-product/details-page-product.component";
import { MerchantConfirmationPopupComponent } from "./components/Merchant-flow/merchant-confirmation-popup/merchant-confirmation-popup.component";
import { MerchantRegistrationFormComponent } from "../cep/login-management/merchant-registration-form/merchant-registration-form.component";
import { CardForumComponent } from "./components/card-forum/card-forum.component";
import { DetailsPageForumComponent } from "./components/details-page-forum/details-page-forum.component";
import { SelectPreferncesComponent } from "../cep/login-management/select-prefernces/select-prefernces.component";
import { IntroductionPopupComponent } from "./components/introduction-popup/introduction-popup.component";
import { TagInputModule } from "ngx-chips";
import { ReportAbusePopupComponent } from "./components/report-abuse-popup/report-abuse-popup.component";
import { DetailsPageCoronaSurveyComponent } from "./components/details-page-corona-survey/details-page-corona-survey.component";
import { RelatedArticleComponent } from "./components/related-article/related-article.component";
import { CategoryWiseMerchantListComponent } from "./components/Merchant-flow/category-wise-merchant-list/category-wise-merchant-list.component";
import { MyLoyaltyComponent } from "./components/Merchant-flow/my-loyalty/my-loyalty.component";
import { MyCartComponent } from "./components/Merchant-flow/my-cart/my-cart.component";
import { RemoveFromCartPopupComponent } from "./components/Merchant-flow/remove-from-cart-popup/remove-from-cart-popup.component";
import { MyDealsComponent } from "./components/Merchant-flow/my-deals/my-deals.component";
import { DealListingComponent } from "./components/Merchant-flow/deal-listing/deal-listing.component";
import { DealDetailPageComponent } from "./components/Merchant-flow/deal-detail-page/deal-detail-page.component";
import { MerchantDetailScreenComponent } from "./components/Merchant-flow/merchant-detail-screen/merchant-detail-screen.component";
import { ApplyCouponPopupComponent } from "./components/Merchant-flow/apply-coupon-popup/apply-coupon-popup.component";
import { AroundMeComponent } from "./components/Merchant-flow/around-me/around-me.component";
import { DealHistoryDetailPageComponent } from "./components/Merchant-flow/deal-history-detail-page/deal-history-detail-page.component";
import { ThankYouForDealsComponent } from "./components/thank-you-for-deals/thank-you-for-deals.component";
import { RemoveCartDataFromProductPopupComponent } from "./components/Merchant-flow/remove-cart-data-from-product-popup/remove-cart-data-from-product-popup.component";
import { AddDealsComponent } from "./components/Merchant-flow/add-deals/add-deals.component";
import { FeedbackPopupComponent } from "./components/Merchant-flow/feedback-popup/feedback-popup.component";
import { RatingModule } from "../../../ng-starrating";
import { SharedFavCardComponent } from "./components/shared-fav-card/shared-fav-card.component";
import { SocketioService } from "./services/socketio.service";
import { GroupComponent } from "./components/Merchant-flow/group/group.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AuthServiceTwitter } from "./services/auth-twitter.service";
import { WebinarListingComponent } from "../cep/side-menu-bar/webinar-listing/webinar-listing.component";
import { DetailsWebinarComponent } from "../cep/side-menu-bar/details-webinar/details-webinar.component";
import { TermsAndConditionComponent } from "./components/terms-and-condition/terms-and-condition.component";
// import { EditorComponent } from './components/editor/editor.component';
import { SharedFavWebinarCardComponent } from "./components/shared-fav-webinar-card/shared-fav-webinar-card.component";
import { SpeakForBlindPeopleComponent } from "./components/speak-for-blind-people/speak-for-blind-people.component";
import { MerchantTermsAndConditionsComponent } from "./components/merchant-terms-and-conditions/merchant-terms-and-conditions.component";
import { MerchantTransactionsComponent } from "./components/Merchant-flow/merchant-transactions/merchant-transactions.component";
import { TransactionFailedComponent } from "./components/transaction-failed/transaction-failed.component";
import { TransactionSuccessComponent } from "./components/transaction-success/transaction-success.component";
import { CardFeedsComponent } from "./components/card-feeds/card-feeds.component";
import { CollaborationQuicklinkComponent } from "./components/collaboration-quicklink/collaboration-quicklink.component";
import { UserlessHeaderComponent } from "./components/userless-header/userless-header.component";
import { PropertyCardComponent } from "./components/property-card/property-card.component";
import { WaterCardComponent } from "./components/water-card/water-card.component";
import { NumberedListComponent } from "./components/numbered-list/numbered-list.component";
import { HawkerCardComponent } from "./components/hawker-card/hawker-card.component";
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { OneClickRegistrationComponent } from "../cep/login-management/one-click-registration/one-click-registration.component";
import { OcrVerifyOtpComponent } from "../cep/login-management/ocr-verify-otp/ocr-verify-otp.component";
import { SuccessDialogComponent } from "../cep/login-management/success-dialog/success-dialog.component";
import { OfficerServiceItemComponent } from "./components/officer-service-item/officer-service-item.component";
import { InstructionPopupComponent } from './components/Merchant-flow/instruction-popup/instruction-popup.component';
import { TermsComponent } from './components/terms/terms.component';
import { PolicyComponent } from './components/policy/policy.component';
import { CustomGooglePlaceAutocompleteComponent } from './components/custom-google-place-autocomplete/custom-google-place-autocomplete.component';
import { WaterTaxPayPopupComponent } from './components/water-tax-pay-popup/water-tax-pay-popup.component';
import { ElectionPopupComponent } from './components/election-popup/election-popup.component';
import { PartialPropertyTaxPay } from "./components/partial-ptax-pay/partial-ptax-pay.component";
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';  

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("660134277797273"),
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "783347595560-t55rqomm4ag3q02b48cjl50p7982pb1h.apps.googleusercontent.com"
      ),
    },
  ]);
  return config;
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    HeaderComponent,
    CardFeedsComponent,
    RegistrationComponent,
    CardBlogComponent,
    CardMostUsedServiceComponent,
    CardNotificationsComponent,
    CardEventComponent,
    CardNewsComponent,
    DisplayProfileComponent,
    ContactUsComponent,
    NewsletterSubscriptionComponent,
    AboutUsComponent,
    CommissionerInformationComponent,
    CityInformationComponent,
    TourismInformationComponent,
    ZoneInformationComponent,
    CorporatesInformationComponent,
    SliderBannerComponent,
    FeedsListComponent,
    CardArticleComponent,
    CardCompetitionComponent,
    SliderBannerComponent,
    FooterComponent,
    CardCityInfoComponent,
    DetailsPageEventComponent,
    DetailsPageNewsComponent,
    DetailsPageBlogComponent,
    DetailsPageArticleComponent,
    DetailsPageCompetitionComponent,
    RedirectToStoreComponent,
    PopupQrScanComponent,
    ChatbotComponent,
    FeedbackComponent,
    CardSurveyComponent,
    DetailsPageSurveyComponent,
    DetailsPageSliderComponent,
    MobileDevicePopupComponent,
    OtpVerificationChoicesComponent,
    CardProductComponent,
    DetailsPageProductComponent,
    MerchantConfirmationPopupComponent,
    MerchantRegistrationFormComponent,
    CardForumComponent,
    DetailsPageForumComponent,
    SelectPreferncesComponent,
    IntroductionPopupComponent,
    DetailsPageCoronaSurveyComponent,
    RelatedArticleComponent,
    ReportAbusePopupComponent,
    DetailsPageCoronaSurveyComponent,
    CategoryWiseMerchantListComponent,
    MyLoyaltyComponent,
    MyCartComponent,
    RemoveFromCartPopupComponent,
    MyDealsComponent,
    DealListingComponent,
    DealDetailPageComponent,
    MerchantDetailScreenComponent,
    ApplyCouponPopupComponent,
    AroundMeComponent,
    DealHistoryDetailPageComponent,
    ThankYouForDealsComponent,
    RemoveCartDataFromProductPopupComponent,
    AddDealsComponent,
    FeedbackPopupComponent,
    SharedFavCardComponent,
    GroupComponent,
    WebinarListingComponent,
    DetailsWebinarComponent,
    TermsAndConditionComponent,
    // EditorComponent
    SharedFavWebinarCardComponent,
    SpeakForBlindPeopleComponent,
    MerchantTermsAndConditionsComponent,
    MerchantTransactionsComponent,
    // For Transactions
    TransactionFailedComponent,
    TransactionSuccessComponent,
    CollaborationQuicklinkComponent,
    // For Login Headers
    UserlessHeaderComponent,
    PropertyCardComponent,
    WaterCardComponent,
    NumberedListComponent,
    HawkerCardComponent,
    PrivacyPolicyComponent,
    OneClickRegistrationComponent,
    OcrVerifyOtpComponent,
    SuccessDialogComponent,
    OfficerServiceItemComponent,
    InstructionPopupComponent,
    TermsComponent,
    PolicyComponent,
    CustomGooglePlaceAutocompleteComponent,
    WaterTaxPayPopupComponent,
    ElectionPopupComponent,
    PartialPropertyTaxPay,
    
  ],

  imports: [
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    AngularEditorModule,
    MatButtonModule,
    // NgxSocialShareModule,
    RouterModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      titleClass: "toast-title",
      preventDuplicates: true,
      extendedTimeOut: 20000,
      closeButton: true,
      enableHtml: true,
    }),
    MatDialogModule,
    MatSelectModule,
    AngularSvgIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatCardModule,
    HttpClientModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatListModule,
    AgmCoreModule,
    GooglePlaceModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SocialLoginModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxSpinnerModule,
    MatTooltipModule,
    // InfiniteScrollModule,
    DeviceDetectorModule.forRoot(),
    NgOtpInputModule,
    MatChipsModule,
    NgxQRCodeModule,
    StarRatingModule.forRoot(),
    NgbModule,
    TagInputModule,
    RatingModule,
    QRCodeModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AngularFireAuthModule, 
  ],

  entryComponents: [
    RegistrationComponent,
    ContactUsComponent,
    NewsletterSubscriptionComponent,
    RedirectToStoreComponent,
    PopupQrScanComponent,
    ChatbotComponent,
    FeedbackComponent,
    MobileDevicePopupComponent,
    OtpVerificationChoicesComponent,
    MerchantConfirmationPopupComponent,
    SelectPreferncesComponent,
    IntroductionPopupComponent,
    ReportAbusePopupComponent,
    RemoveFromCartPopupComponent,
    ApplyCouponPopupComponent,
    RemoveCartDataFromProductPopupComponent,
    FeedbackPopupComponent,
    SpeakForBlindPeopleComponent,
    WaterTaxPayPopupComponent,
    PartialPropertyTaxPay
    
  ],

  providers: [
    GoogleAnalyticsService,
    EncrDecrService,
    PushNotificationsService,
    MatDatepickerModule,
    GooglePlaceModule,
    GooglePlaceDirective,
    ChatbotService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    },
    DatePipe,
    SocketioService,

    AuthServiceTwitter,
  ],

  exports: [
    AngularEditorModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatExpansionModule,
    MatTabsModule,
    ToastrModule,
    AngularSvgIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    HttpClientModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    HeaderComponent,
    CardBlogComponent,
    CardMostUsedServiceComponent,
    CardNotificationsComponent,
    CardNewsComponent,
    DisplayProfileComponent,
    RegistrationComponent,
    MatListModule,
    AgmCoreModule,
    MatBadgeModule,
    NgxSpinnerModule,
    SliderBannerComponent,
    CardEventComponent,
    FooterComponent,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatTooltipModule,
    QRCodeModule,
    FeedsListComponent,
    CardArticleComponent,
    CardCompetitionComponent,
    ContactUsComponent,
    NewsletterSubscriptionComponent,
    CardCityInfoComponent,
    DetailsPageNewsComponent,
    DetailsPageBlogComponent,
    DetailsPageArticleComponent,
    DetailsPageCompetitionComponent,
    DetailsPageEventComponent,
    ChatbotComponent,
    DeviceDetectorModule,
    MatChipsModule,
    GooglePlaceModule,
    RedirectToStoreComponent,
    NgxQRCodeModule,
    GooglePlaceDirective,
    FeedbackComponent,
    CardSurveyComponent,
    DetailsPageSliderComponent,
    OtpVerificationChoicesComponent,
    CardProductComponent,
    MerchantConfirmationPopupComponent,
    CardForumComponent,
    DetailsPageForumComponent,
    SelectPreferncesComponent,
    NgOtpInputModule,
    IntroductionPopupComponent,
    RelatedArticleComponent,
    TagInputModule,
    // InfiniteScrollModule,
    RatingModule,
    ReportAbusePopupComponent,
    RemoveFromCartPopupComponent,
    ApplyCouponPopupComponent,
    ThankYouForDealsComponent,
    RemoveCartDataFromProductPopupComponent,
    FeedbackPopupComponent,
    SharedFavCardComponent,
    AngularFireModule,
    AngularFireAuthModule,
    WebinarListingComponent,
    DetailsWebinarComponent,
    TermsAndConditionComponent,
    SharedFavWebinarCardComponent,
    SpeakForBlindPeopleComponent,
    // For Transactions
    TransactionFailedComponent,
    TransactionSuccessComponent,
    CardFeedsComponent,
    CollaborationQuicklinkComponent,
    // For Login Headers
    UserlessHeaderComponent,
    PropertyCardComponent,
    WaterCardComponent,
    NumberedListComponent,
    HawkerCardComponent,
    PrivacyPolicyComponent,
    OneClickRegistrationComponent,
    OcrVerifyOtpComponent,
    SuccessDialogComponent,
    OfficerServiceItemComponent,
    WaterTaxPayPopupComponent,
    PartialPropertyTaxPay
    
  ],
})
export class SharedModule {}
