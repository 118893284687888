import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as SearchAction from '../../store/action/search.action';
import { SearchService } from '../../store/api-services/search.service';
import {of} from 'rxjs';

@Injectable()

export class SearchEffect {
	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private actions$: Actions,
		private searchService: SearchService
	) {
	}

	apiSearchData$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<SearchAction.SearchData>(SearchAction.SEARCH_DATA),
		  switchMap((action) => {
			return this.searchService.getSearchResult(action.payload).pipe(
			  map(searchData => new SearchAction.SearchDataSuccess(searchData)),
			  catchError(error => of(new SearchAction.SearchDataFail(error)))
			);
		  })
		)
	  );
	
	  apiAutoSearchData$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<SearchAction.SearchData>(SearchAction.AUTO_SEARCH_DATA),
		  switchMap((action) => {
			return this.searchService.autoSearchResult(action.payload).pipe(
			  map(searchData => new SearchAction.AutoSearchDataSuccess(searchData)),
			  catchError(error => of(new SearchAction.AutoSearchDataFail(error)))
			);
		  })
		)
	  );

	// @Effect()
	// apiSearchData$ = this.actions$.pipe(
	// 		ofType<SearchAction.SearchData>(SearchAction.SEARCH_DATA),
	// 		switchMap((action) => {
	// 			return this.searchService.getSearchResult(action.payload).pipe(
	// 				map(searchData => new SearchAction.SearchDataSuccess(searchData)),
	// 				catchError(error => of(new SearchAction.SearchDataFail(error)))
	// 			);
	// 		})
	// 	);

	// @Effect()
	// apiAutoSearchData$ = this.actions$.pipe(
	// 		ofType<SearchAction.SearchData>(SearchAction.AUTO_SEARCH_DATA),
	// 		switchMap((action) => {
	// 			return this.searchService.autoSearchResult(action.payload).pipe(
	// 				map(searchData => new SearchAction.AutoSearchDataSuccess(searchData)),
	// 				catchError(error => of(new SearchAction.AutoSearchDataFail(error)))
	// 			);
	// 		})
	// 	);

}
