
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})
export class FavoriteService {

	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) {

	}
	getFavoriteDetails(request) {
		let method = request.method;
    let url = request.url;
    let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
  }
  markFavoriteCall(request) {
	let method = request.method;
    let url = request.url;
    let body = request.body;
	return this.apiCallService.apiCall(method, url, body);
	}
	markFavoriteWebinarCall(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
		}
}