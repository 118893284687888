import { Action } from '@ngrx/store';
import { GET_BIRTH_DATE_LIST, GET_BIRTH_DATE_LIST_FAIL, GET_BIRTH_DATE_LIST_SUCCESS } from '../action/birth-date.action';


export class GetBirthDateList implements Action {
	readonly type = GET_BIRTH_DATE_LIST;
	constructor(public payload: any) { }
}
export class GetBirthDateListSuccess implements Action {
	readonly type = GET_BIRTH_DATE_LIST_SUCCESS;
	constructor(public payload: any) { }
}
export class GetBirthDateListFail implements Action {
	readonly type = GET_BIRTH_DATE_LIST_FAIL;
	constructor(public payload: any) { }
}

export type birthDateAction = GetBirthDateList | GetBirthDateListSuccess | GetBirthDateListFail;