import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as  fromBirthDateReducer from  '../reducer/birth-date.reducer';

export const defaultState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.birthDate
);

export const getBirthDateListSelector = createSelector(
	defaultState,
	fromBirthDateReducer.getBirthDateList
);
