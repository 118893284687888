
import * as fromFavorite from '../../store/action/favorite.action';

export interface FavoriteState {
  result: [];
	isLoading:boolean;
	isLoaded:boolean;
	errMessage:string;
	statusCode: number;
	favoriteMessage:string;
	serverErrorMessage: string;

	fevoriteWebinarErrorMessage: String;
	isfevoriteWebinarLoading:boolean;
	fevoriteWebinarListStatusCode: number;
	isfevoriteWebinarLoaded:boolean;
	fevoriteWebinarResult:{};
}

export const initialState: FavoriteState = {
	result: [],
	isLoading: false,
	isLoaded: false,
	errMessage: null,
	statusCode: 0,
	favoriteMessage: null,
	serverErrorMessage: null,

	fevoriteWebinarErrorMessage: null,
	isfevoriteWebinarLoading:false,
	fevoriteWebinarListStatusCode: 0,
	isfevoriteWebinarLoaded:false,
	fevoriteWebinarResult:{},

};

export function reducer(
	state = initialState,
	action: fromFavorite.favoriteAction
): FavoriteState {
	switch (action.type) {
		case fromFavorite.GET_FAVORITE_DETAILS: {

			return {
				...state,
				isLoading:true,
				isLoaded:false
			};
		}
		case fromFavorite.GET_FAVORITE_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let result = state.result;
			let isLoaded = state.isLoaded;
			let isLoading = state.isLoading;
			let errMessage = state.errMessage;
			statusCode = action.payload.code;
			isLoaded = true;
			isLoading = false;
			switch(statusCode){
				case 200:
						result = action.payload.result;
						let data=[]
						result.forEach((element)=>{
							element= Object.assign({favourite: true}, element);
							data.push(element);
						});
						result= data as any;
					break;
					case 500:
						result = [];
						errMessage = action.payload.message
					break;
			}
			return {
				...state,
				result,
				isLoading,
				isLoaded,
				statusCode,
				errMessage
			};
		}
		case fromFavorite.GET_FAVORITE_DETAILS_FAIL: {
			let statusCode = action.payload.status;
			let serverErrorMessage = state.serverErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				serverErrorMessage = action.payload.statusText;
			}
			else {
				serverErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				serverErrorMessage
			};
		}

    	case fromFavorite.MARK_FAVORITE: {

			return {
				...state,
			};
		}
		case fromFavorite.MARK_FAVORITE_SUCCESS: {
			let statusCode = action.payload.code;
			let result = state.result;
			let favoriteMessage = null;
			let isLoaded = state.isLoaded;
			let isLoading = state.isLoading;
			statusCode = action.payload.code;
			switch(statusCode){
				case 600:
				case 601:
						favoriteMessage = action.payload.message;
				break;
					
			}
			return {
				...state,
				result,
				statusCode,
				favoriteMessage
			};
		}
		case fromFavorite.MARK_FAVORITE_FAIL: {
			let statusCode = action.payload.status;
			let serverErrorMessage = state.serverErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				serverErrorMessage = action.payload.statusText;
			}
			else {
				serverErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				serverErrorMessage
			};
		}

		case fromFavorite.MARK_FAVORITE_WEBINAR: {

			return {
				...state,
			};
		}
		case fromFavorite.MARK_FAVORITE_WEBINAR_SUCCESS: {
			let fevoriteWebinarListStatusCode = action.payload.code;
			let result = state.result;
			let fevoriteWebinarErrorMessage = null;
			let isfevoriteWebinarLoaded = state.isfevoriteWebinarLoaded;
			let isfevoriteWebinarLoading = state.isfevoriteWebinarLoading;
			fevoriteWebinarListStatusCode = action.payload.code;
			switch(fevoriteWebinarListStatusCode){
				case 600:
				case 601:
						fevoriteWebinarErrorMessage = action.payload.message;
				break;
					
			}
			return {
				...state,
				result,
				fevoriteWebinarListStatusCode,
				fevoriteWebinarErrorMessage
			};
		}
		case fromFavorite.MARK_FAVORITE_WEBINAR_FAIL: {
			let statusCode = action.payload.status;
			let serverErrorMessage = state.serverErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				serverErrorMessage = action.payload.statusText;
			}
			else {
				serverErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				serverErrorMessage
			};
		}
	}
	return state;
}

export const getFavoriteResult = (state: FavoriteState) => state.result;
export const getIsFavoriteLoading = (state: FavoriteState) => state.isLoading;
export const getIsFavoriteLoaded = (state: FavoriteState) => state.isLoaded;
export const getErrMessage = (state: FavoriteState) => state.errMessage;
export const getFavoriteMessage = (state: FavoriteState) => state.favoriteMessage;
export const getStatusCode = (state: FavoriteState) => state.statusCode;
export const getServerErrorMsg = (state: FavoriteState) => state.serverErrorMessage;

export const fevoriteWebinarErrorMessage = (state: FavoriteState) => state.fevoriteWebinarErrorMessage;
export const fevoriteWebinarListStatusCode = (state: FavoriteState) => state.fevoriteWebinarListStatusCode;
export const fevoriteWebinarResult = (state: FavoriteState) => state.fevoriteWebinarResult;