import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from '../../../../cep/store/index';
import { Store } from '@ngrx/store';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { LocalStorageService } from '../../../services/localstorage.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remove-cart-data-from-product-popup',
  templateUrl: './remove-cart-data-from-product-popup.component.html',
  styleUrls: ['./remove-cart-data-from-product-popup.component.scss']
})
export class RemoveCartDataFromProductPopupComponent implements OnInit, AfterContentChecked, OnDestroy {
	public images = Images;
	constants = AppConstants;
	deviceInfo = null;
	public topic;
	selectedLanguage;
	contactUsLabel;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading = false;
	isLoaded = false;
	contactNo: {};
	device: string = null;
	apiDetails: {};
	isNetworkError = false;
	contactUsResult: {};
	serverErrorMessage: string;
	shareUrl: any;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * user token
	 */
	successMessages: any;
	token: string;
	popupLabel: {};
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		public router: Router,
		private store: Store<fromStore.CEPState>,
		// private dialogRef: MatDialogRef<RemoveCartDataFromProductPopupComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private encrDecr: EncrDecrService,
		private localStorageService: LocalStorageService,
		private toastr: ToastrService
	) {
		// dialogRef.disableClose = true;
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
	}

	ngOnInit() {
		this.data = this.data.label
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
			if (Object.keys(this.apiDetails).length > 0) {
				this.shareUrl = this.apiDetails['shareUrl'];
			}
		});
		this.store.select(fromStore.getRemoveExistDeallabels)
		.subscribe(labels => {
			this.popupLabel = labels;
		});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});
			 // token from localstorage
		this.store.select(fromStore.getToken)
		.subscribe((token) => {
		  this.token = token;
		  if (this.token) {
			const localUserId = this.localStorageService.getUserId();
			this.userId = this.encrDecr.get(localUserId);
		  }
		});
		this.store.select(fromStore.getSuccessMessages)
		.subscribe(res => {
			this.successMessages = res;
		});
		
	}

	/**
	 * Created by Akshata C
	 * For remove cart data
	 */
	async removeFromCart() {
    if (Object.keys(this.apiDetails).length > 0) {
			await this.store.dispatch(new fromStore.EmptyCart({
			  url: this.apiDetails["user"].base_url +
				this.apiDetails["user"].cleanCart.url,
			  method: this.apiDetails["user"].cleanCart.method,
			  body:  {
				userId: this.userId
			  }
			}));
			this.store.select(fromStore.emptyCartStatusCode)
			.subscribe(code => {
				this.store.select(fromStore.emptyCartSuccessMessage)
				.subscribe(errorMessages => {
					if(errorMessages) {
			// this.toastr.success(errorMessages);
			this.toastr.success(this.successMessages[this.selectedLanguage][errorMessages]);
			const dialogClose = this.dialog.closeAll();
			this.router.navigate(['/cep/offers']);
          }
				});
			});
		}
	}

	/**
	 * Created by Akshata C
	 * For closing the popup
	 */
	closeDialog() {
	  this.dialog.closeAll();
	}

	ngAfterContentChecked() {
		// this.ref.detectChanges();
	}

	ngOnDestroy() {
		// this.serverErrorMessage = null;
	}
}



