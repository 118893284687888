import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromMoreServices from '../reducer/more-services.reducer';

export const getMoreServicesState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.moreServices
);

export const getMoreServicesNetworkError = createSelector(
	getMoreServicesState,
	fromMoreServices.moreServicesNetworkError
);
export const getDealsPaginationCount = createSelector(
	getMoreServicesState,
	fromMoreServices.getDealsPaginationCount
);


export const getMoreServicesResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getMoreServicesResult
);
export const getMoreServicesStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getMoreServicesStatusCode
);
export const getMoreServicesSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getMoreServicesSuccessMessage
);
export const isGetMoreServicesLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMoreServicesLoading
);
export const isGetMoreServicesLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMoreServicesLoaded
);

export const getCategoryServicesResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getCategoryServicesResult
);
export const getCategoryServicesStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getCategoryServicesStatusCode
);
export const getCategoryServicesSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getCategoryServicesSuccessMessage
);
export const isGetCategoryServicesLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetCategoryServicesLoading
);
export const isGetCategoryServicesLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetCategoryServicesLoaded
);

export const getAllEngagementByMerchantIdResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getAllEngagementByMerchantIdResult
);
export const getAllEngagementByMerchantIdStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getAllEngagementByMerchantIdStatusCode
);
export const getMerchantDetailsOfListing = createSelector(
	getMoreServicesState,
	fromMoreServices.getMerchantDetailsOfListing
);
export const getAllEngagementByMerchantIdSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getAllEngagementByMerchantIdSuccessMessage
);
export const isGetAllEngagementByMerchantIdLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetAllEngagementByMerchantIdLoading
);
export const isGetAllEngagementByMerchantIdLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetAllEngagementByMerchantIdLoaded
);

export const getDealDetailByDealIdResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getDealDetailByDealIdResult
);
export const getDealDetailByDealIdSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getDealDetailByDealIdSuccessMessage
);
export const getDealDetailByDealIdStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getDealDetailByDealIdStatusCode
);
export const isGetDealDetailByDealIdLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetDealDetailByDealIdLoading
);
export const isGetDealDetailByDealIdLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetDealDetailByDealIdLoaded
);

export const getMyOrderHistoryByIdResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyOrderHistoryByIdResult
);
export const getMyOrderHistoryByIdSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyOrderHistoryByIdSuccessMessage
);
export const getMyOrderHistoryByIdStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyOrderHistoryByIdStatusCode
);
export const isGetMyOrderHistoryByIdLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMyOrderHistoryByIdLoading
);
export const isGetMyOrderHistoryByIdLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMyOrderHistoryByIdLoaded
);

export const getMerchantByIdResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getMerchantByIdResult
);
export const getMerchantByIdSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getMerchantByIdSuccessMessage
);
export const getMerchantByIdStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getMerchantByIdStatusCode
);
export const isGetMerchantByIdLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMerchantByIdLoading
);
export const isGetMerchantByIdLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMerchantByIdLoaded
);

export const getUserCartResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getUserCartResult
);
export const getUserCartSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getUserCartSuccessMessage
);
export const getUserCartStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getUserCartStatusCode
);
export const isGetUserCartLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetUserCartLoading
);
export const isGetUserCartLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetUserCartLoaded
);

export const addItemToCartResult = createSelector(
	getMoreServicesState,
	fromMoreServices.addItemToCartResult
);
export const addItemToCartSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.addItemToCartSuccessMessage
);
export const addItemToCartStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.addItemToCartStatusCode
);
export const isAddItemToCartLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isAddItemToCartLoading
);
export const isAddItemToCartLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isAddItemToCartLoaded
);

export const removeItemFromCartResult = createSelector(
	getMoreServicesState,
	fromMoreServices.removeItemFromCartResult
);
export const removeItemFromCartSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.removeItemFromCartSuccessMessage
);
export const removeItemFromCartStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.removeItemFromCartStatusCode
);
export const isRemoveItemFromCartLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isRemoveItemFromCartLoading
);
export const isRemoveItemFromCartLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isRemoveItemFromCartLoaded
);

export const getMyLoyaltyPointsResult = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyLoyaltyPointsResult
);
export const getMyLoyaltyPointsSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyLoyaltyPointsSuccessMessage
);
export const getMyLoyaltyPointsStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.getMyLoyaltyPointsStatusCode
);
export const isGetMyLoyaltyPointsLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMyLoyaltyPointsLoading
);
export const isGetMyLoyaltyPointsLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isGetMyLoyaltyPointsLoaded
);

export const emptyCartResult = createSelector(
	getMoreServicesState,
	fromMoreServices.emptyCartResult
);
export const emptyCartSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.emptyCartSuccessMessage
);
export const emptyCartStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.emptyCartStatusCode
);
export const isEmptyCartLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isEmptyCartLoading
);
export const isEmptyCartLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isEmptyCartLoaded
);

export const applyCouponResult = createSelector(
	getMoreServicesState,
	fromMoreServices.applyCouponResult
);
export const applyCouponSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.applyCouponSuccessMessage
);
export const applyCouponStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.applyCouponStatusCode
);
export const isApplyCouponLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isApplyCouponLoading
);
export const isApplyCouponLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isEmptyCartLoaded
);

export const dealHistoryCartResult = createSelector(
	getMoreServicesState,
	fromMoreServices.dealHistoryCartResult
);
export const dealHistoryCartSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.dealHistoryCartSuccessMessage
);
export const dealHistoryCartStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.dealHistoryCartStatusCode
);
export const isDealHistoryCartLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isDealHistoryCartLoading
);
export const isDealHistoryCartLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isDealHistoryCartLoaded
);
export const startTransactionResult = createSelector(
	getMoreServicesState,
	fromMoreServices.startTransactionResult
);
export const startTransactionSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.startTransactionSuccessMessage
);
export const startTransactionStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.startTransactionStatusCode
);
export const isStartTransactionLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isStartTransactionLoading
);
export const isStartTransactionLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isStartTransactionLoaded
);

export const merchantTransactionListResult = createSelector(
	getMoreServicesState,
	fromMoreServices.merchantTransactionListResult
);
export const merchantTransactionListSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.merchantTransactionListSuccessMessage
);
export const merchantTransactionListStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.merchantTransactionListStatusCode
);
export const isMerchantTransactionListLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isMerchantTransactionListLoading
);
export const isMerchantTransactionListLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isMerchantTransactionListLoaded
);

export const addMerchantTransactionResult = createSelector(
	getMoreServicesState,
	fromMoreServices.addMerchantTransactionResult
);
export const addMerchantTransactionSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.addMerchantTransactionSuccessMessage
);
export const addMerchantTransactionStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.addMerchantTransactionStatusCode
);
export const addMerchantTransactionLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.addMerchantTransactionLoading
);
export const addMerchantTransactionLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.addMerchantTransactionLoaded
);

export const updateTransactionStatusResult = createSelector(
	getMoreServicesState,
	fromMoreServices.updateTransactionStatusResult
);
export const updateTransactionStatusSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.updateTransactionStatusSuccessMessage
);
export const updateTransactionStatusStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.updateTransactionStatusStatusCode
);
export const isUpdateTransactionStatusLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isUpdateTransactionStatusLoading
);
export const isUpdateTransactionStatusLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isUpdateTransactionStatusLoaded
);

export const addDealsResult = createSelector(
	getMoreServicesState,
	fromMoreServices.addDealsResult
);
export const addDealsSuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.addDealsSuccessMessage
);
export const addDealsStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.addDealsStatusCode
);
export const isAddDealsLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isAddDealsLoading
);
export const isAddDealsLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isAddDealsLoaded
);


export const redeemLoyaltyResult = createSelector(
	getMoreServicesState,
	fromMoreServices.redeemLoyaltyResult
);
export const redeemLoyaltySuccessMessage = createSelector(
	getMoreServicesState,
	fromMoreServices.redeemLoyaltySuccessMessage
);
export const redeemLoyaltyStatusCode = createSelector(
	getMoreServicesState,
	fromMoreServices.redeemLoyaltyStatusCode
);
export const isRedeemLoyaltyLoading = createSelector(
	getMoreServicesState,
	fromMoreServices.isRedeemLoyaltyLoading
);
export const isRedeemLoyaltyLoaded = createSelector(
	getMoreServicesState,
	fromMoreServices.isRedeemLoyaltyLoaded
);
