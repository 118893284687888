/***************************
 * Component -  most-used-services components
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ***********************************/
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RedirectToStoreComponent } from '../redirect-to-store/redirect-to-store.component';
import { MatDialog } from '@angular/material/dialog';
import { EncrDecrService } from '../../services/encr-decr.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-card-most-used-service',
  templateUrl: './card-most-used-service.component.html',
  styleUrls: ['./card-most-used-service.component.scss']
})
export class CardMostUsedServiceComponent implements OnInit {
  @Input() mostUsedService: any;
  image = Images;
  headerLabels = {};
  apiDetails: {};
  selectedLanguage: string;
  landingLables: {};
  timelineId: any;
  isServicesIframeOpen: boolean;
  isMobile: boolean;
  isDesktopDevice: boolean;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  isLoading: boolean;
  userId: any;
  token: any;
  feedsLabel: {};
  serverErrorMessage: string;
  serverMessage: boolean;
  confirmMsg: any;
  mobileNo: any;
  isBlind;
  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private store: Store<fromStore.CEPState>,
    private activeParams: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.activeParams.params.subscribe((params) => {
      this.timelineId = params.timelineId;
    });

    this.store
      .select(fromStore.getIsMostUsedServicesLoading)
      .subscribe(isLoading => {
        this.isLoading = isLoading;
      });

    // server error handling
    this.store.select(fromStore.getUserStateServerErrorMessage)
      .subscribe((serverErrorMessage) => {
        if (serverErrorMessage != null) {
          this.serverErrorMessage = serverErrorMessage
          if (this.serverErrorMessage) {
            this.serverMessage = true
            console.error(this.serverErrorMessage)
          }
        }
        this.serverErrorMessage = null;
      });

    this.store.select(fromStore.getHeaderLabels)
      .subscribe((labels) => {
        this.headerLabels = labels;
      });

    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
      });

    this.store.select(fromStore.getLandingScreenLabels)
      .subscribe((labels) => {
        this.landingLables = labels;
      });

    this.store.select(fromStore.getFeedsLabels)
      .subscribe((labels) => {
        this.feedsLabel = labels;
      });
    this.store.select(fromStore.getToken)
      .subscribe((token) => {
        this.token = token;
        if (this.token) {
          let localUserId = this.localStorageService.getUserId();
          this.userId = this.encrDecr.get(localUserId);
          if (this.userId) {
            this.store.select(fromStore.getApiCallingList)
              .subscribe((apiDetails) => {
                this.apiDetails = apiDetails;
                if (this.apiDetails && Object.keys(this.apiDetails).length > 0) {

                  this.store.dispatch(new fromStore.GetMostUsedServices({
                    url: this.apiDetails['user'].base_url +
                      this.apiDetails['user'].getMostUsedService.url,
                    method: this.apiDetails['user'].getMostUsedService.method,
                    body: {
                      "userId": this.userId
                    }
                  }));
                }
              });
          }
        } else {
          this.userId = null;
        }
      });

    this.store.select(fromStore.getVerifyPasswordResponse)
    .subscribe((result) => {
      if(result['mobile']){
        this.mobileNo = result['mobile'].split("+91")[1];
      }
    });

    this.store.select(fromStore.getMostUsedServicesResult)
      .subscribe((services) => {
        if (services['mostUsedService']) {
          this.mostUsedService = services['mostUsedService'];
          
        }
      });
  }

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }




  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  /**
   * Craeted by Ajay C
   * For redirecting to url links
   */
  openDialogForRedirect() {
    const dialogRef = this.dialog.open(RedirectToStoreComponent,
      {
        height: '450px',
        width: '350px',
        autoFocus: false
      });
  }

  /**
   * Craeted by Ajay C
   * For redirecting to new page based on type
   */
  openServicesUrl(selectedService) {
    this.isServicesIframeOpen = true;
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    if (this.isMobile) {
      this.openDialogForRedirect();
    }
    else {
      if (this.landingLables) {
      //   this.confirmMsg = this.landingLables[this.selectedLanguage].externalWebsiteThatOpensInANewBrowserTab;
      //   let conWindow = window.confirm(this.confirmMsg);
      // if (conWindow) {
        switch (selectedService.type) {
          case 'propertyTax':

            // window.open("http://propertytax.pcmcindia.gov.in:8080/pcmc/pcmc?wicket:bookmarkablePage=wicket-1:com.common.property.reports.RptMilkatKaracheBillnew");
            this.router.navigate(['cep/e-services/propertytax'])
            break;

          case 'waterTax':
            // window.open("http://water.pcmcindia.gov.in:37200/waterbillpayment.html");
            this.router.navigate(['cep/e-services/watertax'])
            break;

          case 'deathCertificate':
            // window.open("http://103.224.247.116:8080/BND/wicket/?wicket:bookmarkablePage=:com.probitysoft.bnd.search.pages.OnlineSearchDeathRegistration");
            this.router.navigate(['cep/e-services/search-death-date'])
            break;

          case 'birthCertificate':
            // window.open("http://103.224.247.116:8080/BND/wicket/?wicket:bookmarkablePage=:com.probitysoft.bnd.search.pages.OnlineSearchBirthRegistration");
            this.router.navigate(['cep/e-services/search-birth-date'])
            break;

          case 'avCalling':
            window.open("//web.whatsapp.com/send?phone=919607014391");
            break;

          case 'bsup':
            window.open("http://103.224.247.133:8085/BSUPN/Corporator");
            break;

          case 'grievance':
            // window.open("http://103.224.247.131:8085/Grievance/citizen/GrievanceApplication?MobileNo=" + this.mobileNo);
            this.router.navigate(['/cep/e-services/grievancelist'])
            break;
        }
      // }
    }
    }
  }
}
