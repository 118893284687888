import { Action } from '@ngrx/store';

export const ADD_TOKEN_USER_ID = 'ADD_TOKEN_USER_ID';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const GET_USER_STATE_RESPONSE_DETAILS = 'GET_USER_STATE_RESPONSE_DETAILS';
export const GET_USER_STATE_RESPONSE_DETAILS_SUCCESS = 'GET_USER_STATE_DETAILS_RESPONSE_SUCCESS';
export const GET_USER_STATE_RESPONSE_DETAILS_FAIL = 'GET_USER_STATE_DETAILS_RESPONSE_FAIL';

export const GET_EMPLOYEE_STATE = 'GET_EMPLOYEE_STATE';
export const GET_EMPLOYEE_STATE_SUCCESS = 'GET_EMPLOYEE_STATE_RESPONSE_SUCCESS';
export const GET_EMPLOYEE_STATE_FAIL = 'GET_EMPLOYEE_STATE_RESPONSE_FAIL';

export const VERIFY_PASSWORD = 'VERIFY_PASSWORD';
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS';
export const VERIFY_PASSWORD_FAIL = 'VERIFY_PASSWORD_FAIL';

export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const LOGIN_WITH_OTP_SUCCESS = 'LOGIN_WITH_OTP_SUCCESS';
export const LOGIN_WITH_OTP_FAIL = 'LOGIN_WITH_OTP_FAIL';

export const LOGIN_EMPLOYEE = 'LOGIN_EMPLOYEE';
export const LOGIN_EMPLOYEE_SUCCESS = 'LOGIN_EMPLOYEE_SUCCESS';
export const LOGIN_EMPLOYEE_FAIL = 'LOGIN_EMPLOYEE_FAIL';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAIL = 'FACEBOOK_LOGIN_FAIL';

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';

export const TWITTER_LOGIN = 'TWITTER_LOGIN';
export const TWITTER_LOGIN_SUCCESS = 'TWITTER_LOGIN_SUCCESS';
export const TWITTER_LOGIN_FAIL = 'TWITTER_LOGIN_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const FORGOT_PASSWORD_BY_EMAIL = 'FORGOT_PASSWORD_BY_EMAIL';
export const FORGOT_PASSWORD_BY_EMAIL_SUCCESS = 'FORGOT_PASSWORD_BY_EMAIL_SUCCESS';
export const FORGOT_PASSWORD_BY_EMAIL_FAIL = 'FORGOT_PASSWORD_BY_EMAIL_FAIL';

export const RESET_LOGIN = 'RESET_LOGIN';
export const RESET_MOBILE_NUMBER = 'RESET_MOBILE_NUMBER';
export const RESET_UPDATE_PROFILE = 'RESET_UPDATE_PROFILE';
export const RESET_CHANGE_MOBILE_NUMBER = 'RESET_CHANGE_MOBILE_NUMBER';
export const RESET_FORGET_PASSWORD = 'RESET_FORGET_PASSWORD';
export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';

export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_FAIL = 'UPDATE_PROFILE_DETAILS_FAIL';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE';
export const VALIDATE_REFERRAL_CODE_SUCCESS = 'VALIDATE_REFERRAL_CODE_SUCCESS';
export const VALIDATE_REFERRAL_CODE_FAIL = 'VALIDATE_REFERRAL_CODE_FAIL';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const GET_MOST_USED_SERVICES_LISTING = 'GET_MOST_USED_SERVICES_LISTING';
export const GET_MOST_USED_SERVICES_LISTING_SUCCESS = 'GET_MOST_USED_SERVICES_LISTING_SUCCESS';
export const GET_MOST_USED_SERVICES_LISTING_FAIL = 'GET_MOST_USED_SERVICES_LISTING_FAIL';
export const RESET_FEEDS = 'RESET_FEEDS';

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_FAIL = 'UPDATE_LANGUAGE_FAIL';

export const UPDATE_PASSWARD = 'UPDATE_PASSWARD';
export const UPDATE_PASSWARD_SUCCESS = 'UPDATE_PASSWARD_SUCCESS';
export const UPDATE_PASSWARD_FAIL = 'UPDATE_PASSWARD_FAIL';

export const UPDATE_PROFILE_PHOTO = 'UPDATE_PROFILE_PHOTO';
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS';
export const UPDATE_PROFILE_PHOTO_FAIL = 'UPDATE_PROFILE_PHOTO_FAIL';

export const FAV_IMAGE = 'FAV_IMAGE';
export const FAV_IMAGE_SUCCESS = 'FAV_IMAGE_SUCCESS';
export const FAV_IMAGE_FAIL = 'FAV_IMAGE_FAIL';

export const CHANGE_MOBILE_NUMBER = 'CHANGE_MOBILE_NUMBER ';
export const CHANGE_MOBILE_NUMBER_SUCCESS = 'CHANGE_MOBILE_NUMBER_SUCCESS';
export const CHANGE_MOBILE_NUMBER_FAIL = 'CHANGE_MOBILE_NUMBER_FAIL';

export const VERIFY_MOBILE_NUMBER = 'VERIFY_MOBILE_NUMBER';
export const VERIFY_MOBILE_NUMBER_SUCCESS = 'VERIFY_MOBILE_NUMBER_SUCCESS';
export const VERIFY_MOBILE_NUMBER_FAIL = 'VERIFY_MOBILE_NUMBER_FAIL';

export const GET_OTP_CHOICES = 'GET_OTP_CHOICES';
export const GET_OTP_CHOICES_SUCCESS = 'GET_OTP_CHOICES_SUCCESS';
export const GET_OTP_CHOICES_FAIL = 'GET_OTP_CHOICES_FAIL';

export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
export const TERMS_AND_CONDITIONS_SUCCESS = 'TERMS_AND_CONDITIONS_SUCCESS';
export const TERMS_AND_CONDITIONS_FAIL = 'TERMS_AND_CONDITIONS_FAIL';


export const MERCHANT_TERMS_AND_CONDITIONS = 'MERCHANT_TERMS_AND_CONDITIONS';
export const MERCHANT_TERMS_AND_CONDITIONS_SUCCESS = 'MERCHANT_TERMS_AND_CONDITIONS_SUCCESS';
export const MERCHANT_TERMS_AND_CONDITIONS_FAIL = 'MERCHANT_TERMS_AND_CONDITIONS_FAIL';


export const INTERESTED_TO_BE_MERCHANT = 'INTERESTED_TO_BE_MERCHANT';
export const INTERESTED_TO_BE_MERCHANT_SUCCESS = 'INTERESTED_TO_BE_MERCHANT_SUCCESS';
export const INTERESTED_TO_BE_MERCHANT_FAIL = 'INTERESTED_TO_BE_MERCHANT_FAIL';

export const INTERESTED_TO_BE_BRAND = 'INTERESTED_TO_BE_BRAND';
export const INTERESTED_TO_BE_BRAND_SUCCESS = 'INTERESTED_TO_BE_BRAND_SUCCESS';
export const INTERESTED_TO_BE_BRAND_FAIL = 'INTERESTED_TO_BE_BRAND_FAIL';

export const REGISTER_AS_MERCHANT = 'REGISTER_AS_MERCHANT';
export const REGISTER_AS_MERCHANT_SUCCESS = 'REGISTER_AS_MERCHANT_SUCCESS';
export const REGISTER_AS_MERCHANT_FAIL = 'REGISTER_AS_MERCHANT_FAIL';

export const REGISTER_AS_BRAND = 'REGISTER_AS_BRAND';
export const REGISTER_AS_BRAND_SUCCESS = 'REGISTER_AS_BRAND_SUCCESS';
export const REGISTER_AS_BRAND_FAIL = 'REGISTER_AS_BRAND_FAIL';

export const GET_ALL_BRAND = 'GET_ALL_BRAND';
export const GET_ALL_BRAND_SUCCESS = 'GET_ALL_BRAND_SUCCESS';
export const GET_ALL_BRAND_FAIL = 'GET_ALL_BRAND_FAIL';

export const SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER';
export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS = 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_TO_NEWSLETTER_FAIL = 'SUBSCRIBE_TO_NEWSLETTER_FAIL';

export const GO_TO_PAGE_ONLOGIN = 'GO_TO_PAGE_ONLOGIN';
export const NULL_ONLOGIN = 'NULL_ONLOGIN';

export const Go_TO_SERVICE = 'Go_TO_SERVICE';
export const NULL_SERVICE = 'NULL_SERVICE';
export const NULL_OTP_STATE = 'NULL_OTP_STATE';
export const FCM_TOKEN = 'FCM_TOKEN';
export const ADD_PASSWORD_CHECKSUM = 'ADD_PASSWORD_CHECKSUM';
export const ADD_OTP_CHECKSUM = 'ADD_OTP_CHECKSUM';
export class ChangeLanguage implements Action {
  readonly type = CHANGE_LANGUAGE;
  constructor(public payload: any) { }
}
export class GetUserStateResponseDetails implements Action {
	readonly type = GET_USER_STATE_RESPONSE_DETAILS;
	constructor(public payload: any) { }
}
export class GetUserStateResponseDetailsSuccess implements Action {
	readonly type = GET_USER_STATE_RESPONSE_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}
export class GetUserStateResponseDetailsFail implements Action {
	readonly type = GET_USER_STATE_RESPONSE_DETAILS_FAIL;
	constructor(public payload: any) { }
}

export class GetEmployeeState implements Action {
	readonly type = GET_EMPLOYEE_STATE;
	constructor(public payload: any) { }
}
export class GetEmployeeStateSuccess implements Action {
	readonly type = GET_EMPLOYEE_STATE_SUCCESS;
	constructor(public payload: any) { }
}
export class GetEmployeeStateFail implements Action {
	readonly type = GET_EMPLOYEE_STATE_FAIL;
	constructor(public payload: any) { }
}

export class SendOtp implements Action {
	readonly type = SEND_OTP;
	constructor(public payload: any) { }
}
export class SendOtpSuccess implements Action {
	readonly type = SEND_OTP_SUCCESS;
	constructor(public payload: any) { }
}
export class SendOtpFail implements Action {
	readonly type = SEND_OTP_FAIL;
	constructor(public payload: any) { }
}

export class VerifyOtp implements Action {
	readonly type = VERIFY_OTP;
	constructor(public payload: any) { }
}
export class VerifyOtpSuccess implements Action {
	readonly type = VERIFY_OTP_SUCCESS;
	constructor(public payload: any) { }
}
export class VerifyOtpFail implements Action {
	readonly type = VERIFY_OTP_FAIL;
	constructor(public payload: any) { }
}

export class FacebookLogin implements Action {
	readonly type = FACEBOOK_LOGIN;
	constructor(public payload: any) { }
}
export class FacebookLoginSuccess implements Action {
	readonly type = FACEBOOK_LOGIN_SUCCESS;
	constructor(public payload: any) { }
}
export class FacebookLoginFail implements Action {
	readonly type = FACEBOOK_LOGIN_FAIL;
	constructor(public payload: any) { }
}


export class GoogleLogin implements Action {
	readonly type = GOOGLE_LOGIN;
	constructor(public payload: any) { }
}
export class GoogleLoginSuccess implements Action {
	readonly type = GOOGLE_LOGIN_SUCCESS;
	constructor(public payload: any) { }
}
export class GoogleLoginFail implements Action {
	readonly type = GOOGLE_LOGIN_FAIL;
	constructor(public payload: any) { }
}

export class TwitterLogin implements Action {
	readonly type = TWITTER_LOGIN;
	constructor(public payload: any) { }
}
export class TwitterLoginSuccess implements Action {
	readonly type = TWITTER_LOGIN_SUCCESS;
	constructor(public payload: any) { }
}
export class TwitterLoginFail implements Action {
	readonly type = TWITTER_LOGIN_FAIL;
	constructor(public payload: any) { }
}


export class ForgotPassword implements Action {
	readonly type = FORGOT_PASSWORD;
	constructor(public payload: any) { }
}
export class ForgotPasswordSuccess implements Action {
	readonly type = FORGOT_PASSWORD_SUCCESS;
	constructor(public payload: any) { }
}
export class ForgotPasswordFail implements Action {
	readonly type = FORGOT_PASSWORD_FAIL;
	constructor(public payload: any) { }
}

export class ForgotPasswordByEmail implements Action {
	readonly type = FORGOT_PASSWORD_BY_EMAIL;
	constructor(public payload: any) { }
}
export class ForgotPasswordByEmailSuccess implements Action {
	readonly type = FORGOT_PASSWORD_BY_EMAIL_SUCCESS;
	constructor(public payload: any) { }
}
export class ForgotPasswordByEmailFail implements Action {
	readonly type = FORGOT_PASSWORD_BY_EMAIL_FAIL;
	constructor(public payload: any) { }
}

export class ResetLogin implements Action {
	readonly type = RESET_LOGIN;
}

export class ResetMobileNumber implements Action {
	readonly type = RESET_MOBILE_NUMBER;
}

export class ResetUpdatePassword implements Action {
	readonly type = RESET_UPDATE_PASSWORD;
}

export class VerifyPassword implements Action {
	readonly type = VERIFY_PASSWORD;
	constructor(public payload: any) { }
}
export class VerifyPasswordSuccess implements Action {
	readonly type = VERIFY_PASSWORD_SUCCESS;
	constructor(public payload: any) { }
}

export class AddPasswordChecksum implements Action {
	readonly type = ADD_PASSWORD_CHECKSUM;
	constructor(public payload: any) { }
}
export class VerifyPasswordFail implements Action {
	readonly type = VERIFY_PASSWORD_FAIL;
	constructor(public payload: any) { }
}

export class LoginWithOTP implements Action{
	readonly type = LOGIN_WITH_OTP;
	constructor(public payload: any) { }
}

export class LoginWithOTPSucess implements Action{
	readonly type = LOGIN_WITH_OTP_SUCCESS;
	constructor(public payload: any) { }
}
export class AddOTPChecksum implements Action{
	readonly type = ADD_OTP_CHECKSUM;
	constructor(public payload: any) { }
}
export class LoginWithOTPFail implements Action{
	readonly type = LOGIN_WITH_OTP_FAIL;
	constructor(public payload: any) { }
}

export class LoginEmployee implements Action{
	readonly type = LOGIN_EMPLOYEE;
	constructor(public payload: any) { }
}

export class LoginEmployeeSucess implements Action{
	readonly type = LOGIN_EMPLOYEE_SUCCESS;
	constructor(public payload: any) { }
}

export class LoginEmployeeFail implements Action{
	readonly type = LOGIN_EMPLOYEE_FAIL;
	constructor(public payload: any) { }
}

export class UpdateProfileDetails implements Action {
	readonly type = UPDATE_PROFILE_DETAILS;
	constructor(public payload: any) { }
}
export class UpdateProfileDetailsSuccess implements Action {
	readonly type = UPDATE_PROFILE_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateProfileDetailsFail implements Action {
	readonly type = UPDATE_PROFILE_DETAILS_FAIL;
	constructor(public payload: any) { }
}


export class validateReferralCode implements Action {
	readonly type = VALIDATE_REFERRAL_CODE;
	constructor(public payload: any) { }
}
export class validateReferralCodeSuccess implements Action {
	readonly type = VALIDATE_REFERRAL_CODE_SUCCESS;
	constructor(public payload: any) { }
}
export class validateReferralCodeFail implements Action {
	readonly type = VALIDATE_REFERRAL_CODE_FAIL;
	constructor(public payload: any) { }
}


export class RegisterUser implements Action {
	readonly type = REGISTER_USER;
	constructor(public payload: any) {
	}
}
export class RegisterUserSuccess implements Action {
	readonly type = REGISTER_USER_SUCCESS;
	constructor(public payload: any) { }
}
export class RegisterUserFail implements Action {
	readonly type = REGISTER_USER_FAIL;
	constructor(public payload: any) { }
}

export class GetUserDetails implements Action {
	readonly type = GET_USER_DETAILS;
	constructor(public payload: any) { }
}

export class GetUserDetailsSuccess implements Action {
	readonly type = GET_USER_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}

export class GetUserDetailsFail implements Action {
	readonly type = GET_USER_DETAILS_FAIL;
	constructor(public payload: any) { }
}

export class AddTokenUserId implements Action {
	readonly type = ADD_TOKEN_USER_ID;
	constructor(public payload: any) { }
}

export class GetMostUsedServices implements Action {
	readonly type = GET_MOST_USED_SERVICES_LISTING;
	constructor(public payload: any) { }
}

export class GetMostUsedServicesSuccess implements Action {
	readonly type = GET_MOST_USED_SERVICES_LISTING_SUCCESS;
	constructor(public payload: any) { }
}

export class GetMostUsedServicesFail implements Action {
	readonly type = GET_MOST_USED_SERVICES_LISTING_FAIL;
	constructor(public payload: any) { }
}
export class ResetFeeds implements Action {
	readonly type = RESET_FEEDS;
}

export class UpdateLanguage implements Action {
	readonly type = UPDATE_LANGUAGE;
	constructor(public payload: any) { }
}
export class UpdateLanguageSuccess implements Action {
	readonly type = UPDATE_LANGUAGE_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateLanguageFail implements Action {
	readonly type = UPDATE_LANGUAGE_FAIL;
	constructor(public payload: any) { }
}

export class getUpdatePasswardResponse implements Action {
	readonly type = UPDATE_PASSWARD;
	constructor(public payload: any) { }
}

export class getUpdatePasswardSuccessResponse implements Action {
	readonly type = UPDATE_PASSWARD_SUCCESS;
	constructor(public payload: any) { }
}

export class getUpdatePasswardFailResponse implements Action {
	readonly type = UPDATE_PASSWARD_FAIL;
	constructor(public payload: any) { }
}

export class UpdateProfilePhoto implements Action {
	readonly type = UPDATE_PROFILE_PHOTO;
	constructor(public payload: any) {
	}
}
export class UpdateProfilePhotoSuccess implements Action {
	readonly type = UPDATE_PROFILE_PHOTO_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateProfilePhotoFail implements Action {
	readonly type = UPDATE_PROFILE_PHOTO_FAIL;
	constructor(public payload: any) { }
}

export class FavImage implements Action {
	readonly type = FAV_IMAGE;
	constructor(public payload: any) {
	}
}
export class FavImageSuccess implements Action {
	readonly type = FAV_IMAGE_SUCCESS;
	constructor(public payload: any) { }
}
export class FavImageFail implements Action {
	readonly type = FAV_IMAGE_FAIL;
	constructor(public payload: any) { }
}


export class ChangeMobileNumber implements Action {
	readonly type = CHANGE_MOBILE_NUMBER;
	constructor(public payload: any) {
	}
}
export class ChangeMobileNumberSuccess implements Action {
	readonly type = CHANGE_MOBILE_NUMBER_SUCCESS;
	constructor(public payload: any) { }
}
export class ChangeMobileNumberFail implements Action {
	readonly type = CHANGE_MOBILE_NUMBER_FAIL;
	constructor(public payload: any) { }
}


export class VerifyMobileNumber implements Action {
	readonly type = VERIFY_MOBILE_NUMBER;
	constructor(public payload: any) {
	}
}
export class VerifyMobileNumberSuccess implements Action {
	readonly type = VERIFY_MOBILE_NUMBER_SUCCESS;
	constructor(public payload: any) { }
}
export class VerifyMobileNumberFail implements Action {
	readonly type = VERIFY_MOBILE_NUMBER_FAIL;
	constructor(public payload: any) { }
}

export class ResetUpdateProfile implements Action {
	readonly type = RESET_UPDATE_PROFILE;
}

export class ResetChangeMobileNumber implements Action {
	readonly type = RESET_CHANGE_MOBILE_NUMBER;
}

export class GetOtpChoices implements Action {
	readonly type = GET_OTP_CHOICES;
	constructor(public payload: any) { }
}
export class GetOtpChoicesSuccess implements Action {
	readonly type = GET_OTP_CHOICES_SUCCESS;
	constructor(public payload: any) { }
}
export class GetOtpChoicesFail implements Action {
	readonly type = GET_OTP_CHOICES_FAIL;
	constructor(public payload: any) { }
}

export class ResetForgetPassword implements Action {
	readonly type = RESET_FORGET_PASSWORD;
}

export class TermsAndConditions implements Action {
	readonly type = TERMS_AND_CONDITIONS;
	constructor(public payload: any) { }
}
export class TermsAndConditionsSuccess implements Action {
	readonly type = TERMS_AND_CONDITIONS_SUCCESS;
	constructor(public payload: any) { }
}
export class TermsAndConditionsFail implements Action {
	readonly type = TERMS_AND_CONDITIONS_FAIL;
	constructor(public payload: any) { }
}

export class MerchantTermsAndConditions implements Action {
	readonly type = MERCHANT_TERMS_AND_CONDITIONS;
	constructor(public payload: any) { }
}
export class MerchantTermsAndConditionsSuccess implements Action {
	readonly type = MERCHANT_TERMS_AND_CONDITIONS_SUCCESS;
	constructor(public payload: any) { }
}
export class MerchantTermsAndConditionsFail implements Action {
	readonly type = MERCHANT_TERMS_AND_CONDITIONS_FAIL;
	constructor(public payload: any) { }
}


export class InterestedToBeMerchant implements Action {
	readonly type = INTERESTED_TO_BE_MERCHANT;
	constructor(public payload: any) { }
}
export class InterestedToBeMerchantSuccess implements Action {
	readonly type = INTERESTED_TO_BE_MERCHANT_SUCCESS;
	constructor(public payload: any) { }
}
export class InterestedToBeMerchantFail implements Action {
	readonly type = INTERESTED_TO_BE_MERCHANT_FAIL;
	constructor(public payload: any) { }
}

export class InterestedToBeBrand implements Action {
	readonly type = INTERESTED_TO_BE_BRAND;
	constructor(public payload: any) { }
}
export class InterestedToBeBrandSuccess implements Action {
	readonly type = INTERESTED_TO_BE_BRAND_SUCCESS;
	constructor(public payload: any) { }
}
export class InterestedToBeBrandFail implements Action {
	readonly type = INTERESTED_TO_BE_BRAND_FAIL;
	constructor(public payload: any) { }
}

export class RegisterAsMerchant implements Action {
	readonly type = REGISTER_AS_MERCHANT;
	constructor(public payload: any) { }
}
export class RegisterAsMerchantSuccess implements Action {
	readonly type = REGISTER_AS_MERCHANT_SUCCESS;
	constructor(public payload: any) { }
}
export class RegisterAsMerchantFail implements Action {
	readonly type = REGISTER_AS_MERCHANT_FAIL;
	constructor(public payload: any) { }
}

export class RegisterAsBrand implements Action {
	readonly type = REGISTER_AS_BRAND;
	constructor(public payload: any) { }
}
export class RegisterAsBrandSuccess implements Action {
	readonly type = REGISTER_AS_BRAND_SUCCESS;
	constructor(public payload: any) { }
}
export class RegisterAsBrandFail implements Action {
	readonly type = REGISTER_AS_BRAND_FAIL;
	constructor(public payload: any) { }
}

export class GetAllBrand implements Action {
	readonly type = GET_ALL_BRAND;
	constructor(public payload: any) { }
}
export class GetAllBrandSuccess implements Action {
	readonly type = GET_ALL_BRAND_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAllBrandFail implements Action {
	readonly type = GET_ALL_BRAND_FAIL;
	constructor(public payload: any) { }
}

export class SubcribeToNewsLetter implements Action {
	readonly type = SUBSCRIBE_TO_NEWSLETTER;
	constructor(public payload: any) { }
  }
  
  export class SubcribeToNewsLetterSuccess implements Action {
	readonly type = SUBSCRIBE_TO_NEWSLETTER_SUCCESS;
	constructor(public payload: any) { }
  }
  
  export class SubcribeToNewsLetterFail implements Action {
	readonly type = SUBSCRIBE_TO_NEWSLETTER_FAIL;
	constructor(public payload: any) { }
  }

  export class GotoPageOnLogin implements Action {
	readonly type = GO_TO_PAGE_ONLOGIN;
	constructor(public payload: any) { }
  }

  export class NullOnLogin implements Action{
	readonly type = NULL_ONLOGIN;
  }

  export class GoToService implements Action {
	readonly type = Go_TO_SERVICE;
	constructor(public payload: any) { }
  }

  export class NullService implements Action {
	readonly type = NULL_SERVICE;
	constructor(public payload: any) { }
  }

  export class NullOTP implements Action{
	readonly type = NULL_OTP_STATE;
	constructor(public payload: any) { }
  }

  export class FcmToken implements Action{
	readonly type = FCM_TOKEN;
	constructor(public payload:any) { }
  }


export type loginAction = GetUserStateResponseDetails | GetUserStateResponseDetailsSuccess | GetUserStateResponseDetailsFail
	| SendOtp | SendOtpSuccess | SendOtpFail
	| VerifyPassword | VerifyPasswordSuccess | VerifyPasswordFail | AddPasswordChecksum
	| VerifyOtp | VerifyOtpSuccess | VerifyOtpFail
	| FacebookLogin | FacebookLoginSuccess	| FacebookLoginFail
	| GoogleLogin | GoogleLoginSuccess | GoogleLoginFail
	| TwitterLogin | TwitterLoginSuccess | TwitterLoginFail
	| ForgotPassword | ForgotPasswordSuccess | ForgotPasswordFail
	| ForgotPasswordByEmail | ForgotPasswordByEmailSuccess | ForgotPasswordByEmailFail
	| ResetLogin | ResetMobileNumber  | AddTokenUserId | ResetUpdateProfile | ResetChangeMobileNumber
	| ResetForgetPassword | ResetUpdatePassword
	| UpdateProfileDetails | UpdateProfileDetailsSuccess | UpdateProfileDetailsFail
	| FavImage | FavImageSuccess | FavImageFail
	| RegisterUser | RegisterUserSuccess | RegisterUserFail | validateReferralCode | validateReferralCodeSuccess | validateReferralCodeFail
	| GetUserDetails | GetUserDetailsSuccess | GetUserDetailsFail
	| GetMostUsedServices | GetMostUsedServicesSuccess | GetMostUsedServicesFail | ResetFeeds
	| UpdateLanguage | UpdateLanguageSuccess | UpdateLanguageFail
	| getUpdatePasswardResponse | getUpdatePasswardSuccessResponse | getUpdatePasswardFailResponse
	| UpdateProfilePhoto | UpdateProfilePhotoSuccess | UpdateProfilePhotoFail
	| ChangeMobileNumber | ChangeMobileNumberSuccess | ChangeMobileNumberFail
	| VerifyMobileNumber | VerifyMobileNumberSuccess | VerifyMobileNumberFail
	| GetOtpChoices | GetOtpChoicesSuccess | GetOtpChoicesFail
	| TermsAndConditions | TermsAndConditionsSuccess | TermsAndConditionsFail
	| MerchantTermsAndConditions | MerchantTermsAndConditionsSuccess | MerchantTermsAndConditionsFail
	| ChangeLanguage | InterestedToBeMerchant | InterestedToBeMerchantSuccess | InterestedToBeMerchantFail
	| InterestedToBeBrand | InterestedToBeBrandSuccess | InterestedToBeBrandFail
	| RegisterAsMerchant | RegisterAsMerchantSuccess | RegisterAsMerchantFail
	| RegisterAsBrand | RegisterAsBrandSuccess | RegisterAsBrandFail
	| GetAllBrand | GetAllBrandSuccess | GetAllBrandFail
	| SubcribeToNewsLetter | SubcribeToNewsLetterSuccess | SubcribeToNewsLetterFail | GotoPageOnLogin | LoginWithOTP | LoginWithOTPSucess | LoginWithOTPFail | AddOTPChecksum
	| GoToService | NullService | NullOTP | NullOnLogin | FcmToken | LoginEmployee | LoginEmployeeSucess | LoginEmployeeFail 
	| GetEmployeeState | GetEmployeeStateSuccess | GetEmployeeStateFail;
