import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})

export class MoreServicesService {

	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) { }

	getMoreServices(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getCategoryServices(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getAllEngagement(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getDealDetailByDealId(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getMyOrderHistoryId(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getMerchantById(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getUserCart(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	addItemToCart(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	removeItemFromCart(request){
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getMyLoyaltyPoints(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	
	emptyCart(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	applyCoupon(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	dealHistoryCartDetails(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	startTransaction(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	merchantTransactionList(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;		
		return this.apiCallService.apiCall(method, url, body);
	}

	addMerchantTransaction(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;		
		return this.apiCallService.apiCall(method, url, body);
	}

	updateTransactionStatus(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	addDeals(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	redeemLoyalty(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	getObjectStorageResponseForAddDeal(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
}
