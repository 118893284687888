import { Action } from "@ngrx/store";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(public payload: any) {}
}
export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
  constructor(public payload: any) {}
}
export class LogoutFail implements Action {
  readonly type = LOGOUT_FAIL;
  constructor(public payload: any) {}
}

export type LogoutAction = Logout | LogoutSuccess | LogoutFail;
