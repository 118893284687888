import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { getApiCallingList, getHeaderLabels, getNearMeMapLabels } from '../../../../cep/dashboard-management/store/reducer/constants.reducer';
import { cfcCentres, getIsBicycleLoaded } from '../../../../cep/dashboard-management/store/reducer/near-me-map.reducer';
import { GetCfcCentres, CfcCentres, getBicycleOnMap } from '../../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { MouseEvent } from '@agm/core';
import { DetailCardPopupComponent } from '../../../../cep/dashboard-management/near-me/detail-card-popup/detail-card-popup.component';
import { NearMeTypePopupComponent } from '../../../../cep/dashboard-management/near-me/near-me-type-popup/near-me-type-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../../shared/services/localstorage.service'
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';

// import {url} from '../../../../../assets/images/near-me/cfc.png';
@Component({
	selector: 'app-around-me',
	templateUrl: './around-me.component.html',
	styleUrls: ['./around-me.component.scss']
})
export class AroundMeComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	background = AppConstants.NEAR_ME_BACKGROUND;
	images = Images;
	name: any;
	showList: any;
	back: any;
	cfcCentre: any;
	locations = [];
	locationData: any;
	locationDetails: any;
	nearMeMapLabels: {};
	headerLabels: {};
	labelListing = [];
	selectedLanguage: string;
	isSelect = false;
	showCfcInfo: string = 'cfc';
	labelList: any;
	nearMeEnable: {};
	labelenableservice: {};
	nearMeEnablelabels = [];
	nearMeListHeading={};
	typeOfService: any;
	isLoading = false;
	color = 'primary';
	mode = "indeterminate";
	value = 50;
	icon?: string;
	public ngUnsubscribe: Subject<void> = new Subject<void>();
	loadingCfcList: boolean;
	apiDetails: {};
	isCfcLoading = false;
	lng: any;
	lat: any;
	showLocationDetailsOnCard: any;
	selectedMarker: any;
	zoom = 12;
	visible: any;
	markerPoint: any;
	markerLng: any;
	showDetailCard: any;
	detailCardInfo: any;
	CfcCode: any;
	nearMeData = [];
	exploreNearby: any;
	showBicycleInfo: 'bicycle';
	isLoaded = false;
	img: any;
	data:any;
	isMobile: boolean;
	deviceInfo: any;
	android: any;
	nearMeHeadingLabel: any;
	initlat = 18.62861241;
	initlng = 73.80478858;
	maplat: Window;
	mapLng: Window;
	isCfcLoaded = false;
	cardImg: any;
	isDataNotFound: boolean;
	isNearMeLoading = false;
	errorMessage = null;
	statusCode: any;
	device: string;
	emailId: any;
	address: any;
	mobileNo: any;
	locationName: any;
	type: any;
	description: any;
	locationAddress: any;
	serverMessage: string;
	serverErrorMessage: any;
	previous: any;
	markerImage: string;
	markerIcon: { url: string; scaledSize: { height: number; width: number; }; };
	isNearMeDataNotFound = false;
	hideChatBot: HTMLElement;
	position: any;
	isServerErrorMessage = false;
	dir: { origin: { latitude: number; longitude: number; }; destination: { latitude: any; longitude: any; }; };
	isCategoryLoading = false;
	isCategoryLoaded = false;
	categoryList = [];
	merchantDetailLabels = {};
	moreServiceLabels = {};

	constructor(
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private router: Router,
		private toastr: ToastrService,
		private localStorageService: LocalStorageService,
	) { }

	

	ngOnInit() {
		this.getUserLocation();

		// device detect
		const deviceInfo = this.deviceService.getDeviceInfo();
		this.isMobile = this.deviceService.isMobile();
		this.android = deviceInfo.os;
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktopDevice = this.deviceService.isDesktop();
		if (isMobile) {
			this.device = 'mobile';
		}
		else if (isTablet) {
			this.device = 'tablet';
		}
		else if (isDesktopDevice) {
			this.device = 'desktop';
		}

		// ---------------------------------------- server error handling
		// server error handling
		this.store.select(fromStore.getServerErrMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError == 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					}
					else {
						this.serverErrorMessage = serverError;
						this.isServerErrorMessage = true;
						if (this.serverErrorMessage) {
							console.error(this.serverErrorMessage);
						}
					}
					//this.serverErrorMessage = null;
				}
			});

		this.store.select(fromStore.getMoreServicesLabels)
			.subscribe((labels) => {
				if(labels)
					this.moreServiceLabels = labels
			});

		// --------------------------------------- select Api details
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if(Object.keys(this.apiDetails).length > 0){
					
					this.store.dispatch(new fromStore.GetAroundMeCategory({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].getCategoryName.url,
						method: this.apiDetails['user'].getCategoryName.method,
						body: {
							'distance': '15',
							"latitude": this.lat,
							"longitude": this.lng
						}
					}));
				}
			});
			
		// --------------------------------------------- near me data select
		this.store.select(fromStore.getAroundMeCategoryLoading)
			.subscribe((isLoading) => {
				this.isCategoryLoading = isLoading;
		});
		this.store.select(fromStore.getAroundMeCategoryLoaded)
			.subscribe((isLoaded) => {
				this.isCategoryLoaded = isLoaded;
				if(this.isCategoryLoaded){
					this.store.select(fromStore.getAroundMeCategoryList)
					.subscribe((categoryData) => {
					
						
						this.categoryList = categoryData;
				});
				}
		});
	
		
		// this.store.select(fromStore.getAroundMeLoaded)
		// 	.subscribe((isLoaded) => {
		// 		this.isLoaded = isLoaded;
		// 		if (this.isLoaded) {
		// 			this.store.select(fromStore.getAroundMeLocations)
		// 				.subscribe((nearMeOnMap) => {
		// 					this.nearMeData = nearMeOnMap;
		// 					if (this.nearMeData) {
		// 						let allLocations = [];
		// 						allLocations = this.nearMeData;
		// 						if (allLocations) {
		// 							this.locations = [];
		// 							allLocations.forEach((element: {}, index) => {
		// 								element = Object.assign({
		// 									icon: { url:  this.nearMeEnablelabels}
		// 								}, element);
		// 								this.locations.push(element);
		// 							});
		// 						}
		// 					}
		// 				});
		// 		}
		// 	});

			
		// ---------------------------------------------- select language
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});
		}


		/**
	 * Created by Kiran 
	 * For showing the location permission popup and getting the location of user 
	 */
	getUserLocation() {
		if (navigator) {
		
			navigator.geolocation.getCurrentPosition(pos => {
				this.lng = +pos.coords.longitude;
				this.lat = +pos.coords.latitude;
				
			},error => {
			
					this.lat = this.initlat;
					this.lng = this.initlng;
				});
		}
	}
		
	/**
	 * Created by Kiran 
	 * For displaying the list of near me type
	 */
	showListData(data) {
		
		this.showList = data;
		this.isSelect = true;
		this.nearMeListHeading = data;
		this.markerImage = data.image;
		this.markerIcon = { url: this.markerImage, scaledSize: { height: 30, width: 30} };
	
					this.store.dispatch(new fromStore.GetAroundMeOnMap({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].getAroundMeMerchant.url,
						method: this.apiDetails['user'].getAroundMeMerchant.method,
						body: {
							'distance': '15',
							'category': data.categoryId,
							"latitude": this.lat,
							"longitude": this.lng
						}
					}));

					this.store.select(fromStore.getAroundMeLoaded)
					.subscribe((isLoaded) => {
						this.isLoaded = isLoaded;
						if (this.isLoaded) {
							this.store.select(fromStore.getAroundMeLocations)
								.subscribe((nearMeOnMap) => {
								
									
									this.nearMeData = nearMeOnMap;
									if (nearMeOnMap.length > 0) {
											this.locations = [];
											nearMeOnMap.forEach((element: {}, index) => {
												element = Object.assign({
													icon: { url:  this.nearMeEnablelabels}
												}, element);
												this.locations.push(element);
											});
									}else{
										this.locations = [];
									}
								});
						}
					});


	}

	onClick(data){
    	let user = this.localStorageService.getItem("userId")
		if(user === null){
			
			this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/offers/around-me') )
			const dialogRef = this.dialog.open(LoginpopupComponent);
			// this.router.navigate(["/Login/login"])
		}else{

			this.showListData(data)
		}
	}
	// -------------------------------click on back arrow
	/**
	 * Created by Kiran 
	 * For redirecting to the list of nearme type
	 */
	redirectToMoreServices(){
		this.router.navigate(['/cep/offers']);
	}
	locationList(back) {
		this.showList = false;
		this.isDataNotFound = false;
		this.previous = null;
		this.locations = null;
		this.store.dispatch(new fromStore.resetServerErrorMessage());
	}
	//mouse over of Cfc card hightlight the marker
	/**
	 * Created by Kiran 
	 * For highlighting the marker on hover action on CFC card 
	 */
	updateColor(location: any, id) {
		this.locations.map((el, i) => {
			if (el.obj.geometry.coordinates[0] == location) {
				this.locations[i].icon = { url: this.markerImage, scaledSize: { height: 40, width: 40 } };
			}
		});
	}
	//mouse leave of Cfc card hightlight the marker
	/**
	 * Created by Kiran 
	 * For removing highlighting of marker on unhover action on CFC card 
	 */
	updateColorR(location: any) {
		this.locations.map((el, i) => {
			if (el.obj.geometry.coordinates[0] == location) {
				this.locations[i].icon = { url: this.markerImage, scaledSize: { height: 30, width: 30 } };
			}
		});
	}
	/**
	 * Created by Kiran 
	 * For mouse leave of near me card hightlight the marker
	 */
	updateMarkerOnHover(location: any) {
		this.locations.map((el, i) => {
			if (el.geometry.coordinates[0] == location) {
				this.locations[i].icon = 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000';
			}
		});
	}

	/**
	 * Created by Kiran 
	 * For mouse leave of near me card hightlight the marker
	 */
	updateMarker(location: any, icon: string, type) {

			this.locations.map((el, i) => {
				if (el.geometry.coordinates[0] == location) {
					this.locations[i].icon = { url: '../../../../../assets/images/near-me/cfc-marker.png', scaledSize: { height: 30, width: 30 } };
				}
			});

	}
	/**
	 * Created by Kiran 
	 * For opening the popup of other than CFC card
	 */
	openPopupNearMe(nearMeCardDetails, cardImg, geometry) {
		this.dialog.closeAll();
		this.detailCardInfo = nearMeCardDetails;
		this.cardImg = this.markerImage;
		this.showDetailCard = this.dialog.open(NearMeTypePopupComponent, {
			data: {
				type: this.detailCardInfo.displayCompanyName,
				address: this.detailCardInfo.headOfficeAddress,
				destination: this.detailCardInfo.headOfficeCity,
				img: this.detailCardInfo.logo,
				latitude: geometry.coordinates[1],
				longitude: geometry.coordinates[0],
				contactNo1: this.detailCardInfo.contactPersonMobileNumber,
				userId: this.detailCardInfo.userId,
				moreServices: true
			},
			autoFocus: false
		});
	}

	/**
	 * Created by Kiran 
	 * For opening the popup when clicked on marker 
	 */
	clickedMarker(infowindow) {
		if (this.previous) {
			this.previous.close();
		}
		this.previous = infowindow;
	}

	/**
	 * Created by Kiran 
	 * For redircting to google map if clicked on cfc direction icon
		  window.open('https://www.google.com/maps/dir/?api=1&dire
		  window.open('https://www.google.com/maps/dir/?api=1&dire
	 * For redircting to google map if clicked 
	 */
	redirectToGoogleMap(directionLongitude, directionLatitude) {
		var lat = this.lat;
		var lng = this.lng;
		  window.open('https://www.google.com/maps/dir/?api=1&directionLatitudeorigin='+lat+','+lng+'&destination='+directionLatitude+','+directionLongitude+'&travelmode=driving','_blank' );
	}

	mouseOver(index){
		let getImage = document.getElementById('img.'+ index);
		let getLabel = document.getElementById('label.'+ index);
		getImage['src'] = this.nearMeEnablelabels[index].webActiveImage;
		getLabel.classList.add('active-label-text');

	}

	mouseOut(index){
		let getImage = document.getElementById('img.'+ index);
		let getLabel = document.getElementById('label.'+ index);
		getImage['src'] = this.nearMeEnablelabels[index].webNonActiveImage;
		getLabel.classList.add('nearby-items');
		getLabel.classList.remove('active-label-text');

	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
		this.ref.detectChanges();
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
	}

	ngOnDestroy() {
		this.serverErrorMessage = null;
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.store.dispatch(new fromStore.resetServerErrorMessage());
	}
}

