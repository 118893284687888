import { Action, Store } from '@ngrx/store';

export const GET_SERVICE_DETAILS = 'GET_SERVICE_DETAILS';
export const GET_SERVICE_DETAILS_SUCCESS = 'GET_SERVICE_DETAILS_SUCCESS';
export const GET_SERVICE_DETAILS_FAIL = 'GET_SERVICE_DETAILS_FAIL';

export const GET_GRIEVANCE_LISTING = 'GET_GRIEVANCE_LISTING';
export const GET_GRIEVANCE_LISTING_SUCCESS = 'GET_GRIEVANCE_LISTING_SUCCESS';
export const GET_GRIEVANCE_LISTING_FAIL = 'GET_GRIEVANCE_LISTING_FAIL';

export const ADD_GRIEVANCE = 'ADD_GRIEVANCE';
export const ADD_GRIEVANCE_SUCCESS = 'ADD_GRIEVANCE_SUCCESS';
export const ADD_GRIEVANCE_FAIL = 'ADD_GRIEVANCE_FAIL';

export const GRIEVANCE_DETAIL = 'GRIEVANCE_DETAIL';
export const GRIEVANCE_DETAIL_SUCCESS = 'GRIEVANCE_DETAIL_SUCCESS';
export const GRIEVANCE_DETAIL_FAIL = 'GRIEVANCE_DETAIL_FAIL';

export const GRIEVANCE_VOTE = 'GRIEVANCE_VOTE';
export const GRIEVANCE_VOTE_SUCCESS = 'GRIEVANCE_VOTE_SUCCESS';
export const GRIEVANCE_VOTE_FAIL = 'GRIEVANCE_VOTE_FAIL';

export const GRIEVANCE_CATEGORY = 'GRIEVANCE_CATEGORY';
export const GRIEVANCE_CATEGORY_SUCCESS = 'GRIEVANCE_CATEGORY_SUCCESS';
export const GRIEVANCE_CATEGORY_FAIL = 'GRIEVANCE_CATEGORY_FAIL';

export const SET_PAGINATION_COUNT_GRIEVANCE = 'SET_PAGINATION_COUNT_GRIEVANCE';

export const STORE_CORONA_SURVEY_DETAILS = 'STORE_CORONA_SURVEY_DETAILS';

export const VACCINATION_CENTER_LIST = 'VACCINATION_CENTER_LIST';
export const VACCINATION_CENTER_LIST_SUCCESS = 'VACCINATION_CENTER_LIST_SUCCESS';
export const VACCINATION_CENTER_LIST_FAIL = 'VACCINATION_CENTER_LIST_FAIL';
export const RESET_VACCINATIONLIST = 'RESET_VACCINATIONLIST';

export const FETCH_STRAPI_CONTENT = 'FETCH STRAPI CONTENT LIST';
export const FETCH_STRAPI_CONTENT_FAIL = 'FETCH STRAPI CONTENT LIST FAILED';
export const FETCH_STRAPI_CONTENT_SUCCESS = 'FETCH STRAPI CONTENT LIST SUCCEEDED';

export class resetVaccinationList implements Action {
	readonly type = RESET_VACCINATIONLIST;
	constructor(public payload: any) { }
}

export class VaccinationCenterList implements Action {
	readonly type = VACCINATION_CENTER_LIST;
constructor(public payload: any) { }
}

export class VaccinationCenterListSuccess implements Action {
	readonly type = VACCINATION_CENTER_LIST_SUCCESS;
constructor(public payload: any) { }
}

export class VaccinationCenterListFail implements Action {
	readonly type = VACCINATION_CENTER_LIST_FAIL;
constructor(public payload: any) { }
}

export class GetServiceDetails implements Action {
		readonly type = GET_SERVICE_DETAILS;
	constructor(public payload: any) {}
}

export class SetPaginationCountForGrievance implements Action {
	readonly type = SET_PAGINATION_COUNT_GRIEVANCE;
	constructor(public payload: any) { }
}

export class GetServiceDetailsSuccess implements Action {
		readonly type = GET_SERVICE_DETAILS_SUCCESS;
	constructor(public payload: any) {}
}

export class GetServiceDetailsFail implements Action {
		readonly type = GET_SERVICE_DETAILS_FAIL;
	constructor(public payload: any) {}
}

export class GetGrievanceListing implements Action {
	readonly type = GET_GRIEVANCE_LISTING;
constructor(public payload: any) {}
}

export class GetGrievanceListingSuccess implements Action {
	readonly type = GET_GRIEVANCE_LISTING_SUCCESS;
constructor(public payload: any) {}
}

export class GetGrievanceListingFail implements Action {
	readonly type = GET_GRIEVANCE_LISTING_FAIL;
constructor(public payload: any) {}
}

export class AddGrievance implements Action {
	readonly type = ADD_GRIEVANCE;
constructor(public payload: any) { }
}

export class AddGrievanceSuccess implements Action {
	readonly type = ADD_GRIEVANCE_SUCCESS;
constructor(public payload: any) { }
}

export class AddGrievanceFail implements Action {
	readonly type = ADD_GRIEVANCE_FAIL;
constructor(public payload: any) { }
}

export class GrievanceDetail implements Action {
	readonly type = GRIEVANCE_DETAIL;
constructor(public payload: any) { }
}

export class GrievanceDetailSuccess implements Action {
	readonly type = GRIEVANCE_DETAIL_SUCCESS;
constructor(public payload: any) { }
}

export class GrievanceDetailFail implements Action {
	readonly type = GRIEVANCE_DETAIL_FAIL;
constructor(public payload: any) { }
}

export class GrievanceVote implements Action {
	readonly type = GRIEVANCE_VOTE;
constructor(public payload: any) { }
}

export class GrievanceVoteSuccess implements Action {
	readonly type = GRIEVANCE_VOTE_SUCCESS;
constructor(public payload: any) { }
}

export class GrievanceVoteFail implements Action {
	readonly type = GRIEVANCE_VOTE_FAIL;
constructor(public payload: any) { }
}

export class GrievanceCategory implements Action {
	readonly type = GRIEVANCE_CATEGORY;
constructor(public payload: any) { }
}

export class GrievanceCategorySuccess implements Action {
	readonly type = GRIEVANCE_CATEGORY_SUCCESS;
constructor(public payload: any) { }
}

export class GrievanceCategoryFail implements Action {
	readonly type = GRIEVANCE_CATEGORY_FAIL;
constructor(public payload: any) { }
}

export class StoreCoronaSurveyDetails implements Action {
	readonly type = STORE_CORONA_SURVEY_DETAILS;
	constructor(public payload: any) { }
}

//#region Strapi
export class FetchStrapiContent implements Action {
	readonly type = FETCH_STRAPI_CONTENT;
	constructor(public payload: any) { }
}

export class FetchStrapiContentFail implements Action {
	readonly type = FETCH_STRAPI_CONTENT_FAIL;
	constructor(public payload: any) { }
}

export class FetchStrapiContentSuccess implements Action {
	readonly type = FETCH_STRAPI_CONTENT_SUCCESS;
	constructor(public payload: any) { }
}

//#endregion Strapi

export type serviceAction = GetServiceDetails | GetServiceDetailsSuccess | GetServiceDetailsFail
							| GetGrievanceListing | GetGrievanceListingSuccess | GetGrievanceListingFail
							| AddGrievance | AddGrievanceSuccess | AddGrievanceFail
							| GrievanceDetail | GrievanceDetailSuccess | GrievanceDetailFail
							| GrievanceVote | GrievanceVoteSuccess | GrievanceVoteFail
							| GrievanceCategory | GrievanceCategorySuccess | GrievanceCategoryFail
							| StoreCoronaSurveyDetails | SetPaginationCountForGrievance
							| VaccinationCenterList | VaccinationCenterListSuccess | VaccinationCenterListFail | resetVaccinationList | FetchStrapiContent | FetchStrapiContentFail | FetchStrapiContentSuccess;
