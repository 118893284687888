import { Action } from '@ngrx/store';

export const SEARCH_DATA = 'SEARCH_DATA';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_FAIL = 'SEARCH_DATA_FAIL';

export const AUTO_SEARCH_DATA = 'AUTO_SEARCH_DATA';
export const AUTO_SEARCH_DATA_SUCCESS = 'AUTO_SEARCH_DATA_SUCCESS';
export const AUTO_SEARCH_DATA_FAIL = 'AUTO_SEARCH_DATA_FAIL';

export const RESET_SERACH = 'RESET_SERACH';
export const SEARCH_ROUTE = 'SEARCH_ROUTE';

export class SearchData implements Action {
	readonly type = SEARCH_DATA;
	/**
	 * Search data APi call
	 */
	constructor(public payload: any) { }
}

export class SearchDataSuccess implements Action {
	readonly type = SEARCH_DATA_SUCCESS;
	/**
	 * Search data APi call success
	 */
	constructor(public payload: any) { }
}

export class SearchDataFail implements Action {
	readonly type = SEARCH_DATA_FAIL;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}

export class AutoSearchData implements Action {
	readonly type = AUTO_SEARCH_DATA;
	/**
	 * Search data APi call
	 */
	constructor(public payload: any) { }
}

export class AutoSearchDataSuccess implements Action {
	readonly type = AUTO_SEARCH_DATA_SUCCESS;
	/**
	 * Search data APi call success
	 */
	constructor(public payload: any) { }
}

export class AutoSearchDataFail implements Action {
	readonly type = AUTO_SEARCH_DATA_FAIL;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}

export class ResetSearch implements Action {
	readonly type = RESET_SERACH;
}
export class SearchRoute implements Action {
	readonly type = SEARCH_ROUTE;
	/**
	 * Search route APi call
	 */
	constructor(public payload: any) { }
}
export type searchAction = SearchData | SearchDataSuccess | SearchDataFail 
							| AutoSearchData | AutoSearchDataSuccess | AutoSearchDataFail
							| ResetSearch | SearchRoute;
