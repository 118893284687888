import { Injectable } from '@angular/core';
import { createEffect , Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as ServiceAction from '../action/service.action';
import { ServicesService } from '../api-services/services.service';
import { of } from 'rxjs';

@Injectable()

export class ServiceEffects {
	constructor(
		private actions$: Actions,
		private ServicesService: ServicesService
	) {
	}


	apiDepartmentDetails$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.GetServiceDetails>(ServiceAction.GET_SERVICE_DETAILS),
		  switchMap((action) => {
			return this.ServicesService.getServices(action.payload).pipe(
			  map(departmentData => new ServiceAction.GetServiceDetailsSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.GetServiceDetailsFail(error)))
			);
		  })
		)
	  );
	
	  getGrievanceListing$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.GetGrievanceListing>(ServiceAction.GET_GRIEVANCE_LISTING),
		  switchMap((action) => {
			return this.ServicesService.getGrievanceListing(action.payload).pipe(
			  map(departmentData => new ServiceAction.GetGrievanceListingSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.GetGrievanceListingFail(error)))
			);
		  })
		)
	  );
	
	  addGrievance$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.AddGrievance>(ServiceAction.ADD_GRIEVANCE),
		  switchMap((action) => {
			return this.ServicesService.addGrievance(action.payload).pipe(
			  map(departmentData => new ServiceAction.AddGrievanceSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.AddGrievanceFail(error)))
			);
		  })
		)
	  );
	
	  grievanceDetail$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.GrievanceDetail>(ServiceAction.GRIEVANCE_DETAIL),
		  switchMap((action) => {
			return this.ServicesService.grievanceDetail(action.payload).pipe(
			  map(departmentData => new ServiceAction.GrievanceDetailSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.GrievanceDetailFail(error)))
			);
		  })
		)
	  );
	
	  grievanceVote$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.GrievanceVote>(ServiceAction.GRIEVANCE_VOTE),
		  switchMap((action) => {
			return this.ServicesService.grievanceVote(action.payload).pipe(
			  map(departmentData => new ServiceAction.GrievanceVoteSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.GrievanceVoteFail(error)))
			);
		  })
		)
	  );
	
	  grievanceCategory$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.GrievanceCategory>(ServiceAction.GRIEVANCE_CATEGORY),
		  switchMap((action) => {
			return this.ServicesService.grievanceCategory(action.payload).pipe(
			  map(departmentData => new ServiceAction.GrievanceCategorySuccess(departmentData)),
			  catchError(error => of(new ServiceAction.GrievanceCategoryFail(error)))
			);
		  })
		)
	  );
	
	  vaccinationCenterList$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.VaccinationCenterList>(ServiceAction.VACCINATION_CENTER_LIST),
		  switchMap((action) => {
			return this.ServicesService.vaccinationCenterList(action.payload).pipe(
			  map(departmentData => new ServiceAction.VaccinationCenterListSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.VaccinationCenterListFail(error)))
			);
		  })
		)
	  );
	
	  fetchStrapiContentEffect$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<ServiceAction.FetchStrapiContent>(ServiceAction.FETCH_STRAPI_CONTENT),
		  switchMap((action) => {
			return this.ServicesService.fetchStrapiContentListApi(action.payload).pipe(
			  map(departmentData => new ServiceAction.FetchStrapiContentSuccess(departmentData)),
			  catchError(error => of(new ServiceAction.FetchStrapiContentFail(error)))
			);
		  })
		)
	  );

	// @Effect()
	// apiDepartmentDetails$ = this.actions$.pipe(
	// 		ofType<ServiceAction.GetServiceDetails>(ServiceAction.GET_SERVICE_DETAILS),
	// 		switchMap((action) => {
	// 			return this.ServicesService.getServices(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.GetServiceDetailsSuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.GetServiceDetailsFail(error)))
	// 			);
	// 		})
	// 	);
	// @Effect()
	// getGrievanceListing$ = this.actions$.pipe(
	// 		ofType<ServiceAction.GetGrievanceListing>(ServiceAction.GET_GRIEVANCE_LISTING),
	// 		switchMap((action) => {
	// 			return this.ServicesService.getGrievanceListing(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.GetGrievanceListingSuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.GetGrievanceListingFail(error)))
	// 			);
	// 		})
	// 	);
	// @Effect()
	// addGrievance$ = this.actions$.pipe(
	// 		ofType<ServiceAction.AddGrievance>(ServiceAction.ADD_GRIEVANCE),
	// 		switchMap((action) => {
	// 			return this.ServicesService.addGrievance(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.AddGrievanceSuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.AddGrievanceFail(error)))
	// 			);
	// 		})
	// 	);
	// @Effect()
	// grievanceDetail$ = this.actions$.pipe(
	// 		ofType<ServiceAction.GrievanceDetail>(ServiceAction.GRIEVANCE_DETAIL),
	// 		switchMap((action) => {
	// 			return this.ServicesService.grievanceDetail(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.GrievanceDetailSuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.GrievanceDetailFail(error)))
	// 			);
	// 		})
	// 	);
	// @Effect()
	// grievanceVote$ = this.actions$.pipe(
	// 		ofType<ServiceAction.GrievanceVote>(ServiceAction.GRIEVANCE_VOTE),
	// 		switchMap((action) => {
	// 			return this.ServicesService.grievanceVote(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.GrievanceVoteSuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.GrievanceVoteFail(error)))
	// 			);
	// 		})
	// 	);
	// 	@Effect()
	// grievanceCategory$ = this.actions$.pipe(
	// 		ofType<ServiceAction.GrievanceCategory>(ServiceAction.GRIEVANCE_CATEGORY),
	// 		switchMap((action) => {
	// 			return this.ServicesService.grievanceCategory(action.payload).pipe(
	// 				map(departmentData => new ServiceAction.GrievanceCategorySuccess(departmentData)),
	// 				catchError(error => of(new ServiceAction.GrievanceCategoryFail(error)))
	// 			);
	// 		})
	// 	);
	// 	@Effect()
	// 	vaccinationCenterList$ = this.actions$.pipe(
	// 			ofType<ServiceAction.VaccinationCenterList>(ServiceAction.VACCINATION_CENTER_LIST),
	// 			switchMap((action) => {
	// 				return this.ServicesService.vaccinationCenterList(action.payload).pipe(
	// 					map(departmentData => new ServiceAction.VaccinationCenterListSuccess(departmentData)),
	// 					catchError(error => of(new ServiceAction.VaccinationCenterListFail(error)))
	// 				);
	// 			})
	// 		);
    // @Effect()
	// 	fetchStrapiContentEffect$ = this.actions$.pipe(
	// 			ofType<ServiceAction.FetchStrapiContent>(ServiceAction.FETCH_STRAPI_CONTENT),
	// 			switchMap((action) => {
	// 				return this.ServicesService.fetchStrapiContentListApi(action.payload).pipe(
	// 					map(departmentData => new ServiceAction.FetchStrapiContentSuccess(departmentData)),
	// 					catchError(error => of(new ServiceAction.FetchStrapiContentFail(error)))
	// 				);
	// 			})
	// 		);

}
