/*****************************************
 * Component -  forum component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 *****************************************/

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router } from '@angular/router';
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { ClipboardService } from '../../services/clipboard.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicLinksService } from '../../services/dynamic-links';
import { environment } from 'src/environments/environment';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { Content } from '@angular/compiler/src/render3/r3_ast';
@Component({
	selector: 'app-card-forum',
	templateUrl: './card-forum.component.html',
	styleUrls: ['./card-forum.component.scss']
})
export class CardForumComponent implements OnInit {
	@Input() forumData: any;
	image = Images;
	forumDate;
	selectedLanguage: string;
	landingLables: {};
	postDate: string;
	apiDetails: {};
	feedsDate: Date;
	todaysDate: Date;
	dateDiff: number;
	userId: any;
	token: string;
	favorite: string;
	timelineId: any;
	forumStartDate: string;
	forumEndtDate: string;
	forum: number;
	shareUrl: any;
	favbool:boolean;
	isBlind;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	 */
	constructor(
		private dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private apiCallService: ApiCallService,
		private encrDecr: EncrDecrService,
		private router: Router,
		private clipboardService: ClipboardService,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private googleAnalyticsService: GoogleAnalyticsService) { }

	ngOnInit() {
		
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

			if (this.forumData) {
				if (this.forumData.fav.filter( (item) => item === this.userId ).length > 0) {
					this.favorite = 'favorite';
					this.favbool  = true;
				} else {
					this.favorite = 'favorite_border';
					this.favbool = false;
				}
			}

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}


	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	onClick() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.FORUM_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.forumData.timelineId
		  })
		//   console.log("timeid from card",this.forumData.timelineId)
		// let user = this.localStorageService.getItem("userId")
		// if (user === null) {
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// } else {
			this.router.navigate(['/cep/feeds/forum/',this.forumData.timelineId ,'/feeds'])
		// }
	}

	/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText
	};
	/**
	 * Created by Akanksha J
	 * for reporting forum as abuse
	 */
	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.forumData.timelineId,
				engagementType: 'forum',
				engagementId: this.forumData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	unMarkFavorite() {
		if (this.favbool) {
			this.forumData = Object.assign({}, this.forumData, { favourite: false });
			this.favorite = 'favorite_border';
			this.favbool = false;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.FORUM_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.forumData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.forumData = Object.assign({}, this.forumData, { favourite: true });
			this.favorite = 'favorite';
			this.favbool = true;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.FORUM_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.forumData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.apiDetails) {
				
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavorite({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavourite.url,
						method: this.apiDetails['user'].addToFavourite.method,
						body: {
							userId: this.userId,
							engagementId: this.forumData.engagementId,
							engagementType: 'forum'
						}
					}));
				}
			}
		}
	}

		ngAfterContentChecked() {
			this.ref.detectChanges();
			this.forumStartDate = new Date(this.forumData.startDate).toDateString();
			this.forumEndtDate = new Date(this.forumData.endDate).toDateString();
			this.postDate = new Date(this.forumData.updatedAt).toDateString();
		}
		

		/**
		 * Created by Vinita Sharma'
		 * For generating dynamic link to share and redirect to new page to share post
		 */
		generateShareLink(media) {
			var url = this.shareUrl + "/cep/feeds/forum/" + this.forumData.timelineId
			var metaTag = {
				socialTitle: this.forumData.en.question,
				socialImageLink: ''
			}
			this.apiCallService.configFirebaseLink(url, metaTag, media)
		}


		onCardClick() {
			//this.googleAnalyticsService.eventEmitter('search', 'user click on forum', 'Click Event', 1);
		}

		
		async clickOnShare() {
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.SHARE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.FORUM_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.SHARE,
				time: new Date(),
				isInput: false,
				contentId:this.forumData.timelineId
			  })
			let url = environment.shareurl +  "/cep/feeds/forum/" + this.forumData.timelineId + '/feeds'
			const shareUrl = await this.dynamicLink.generateDynamicLink(url)
	
			const success = this.clipboardService.copyTextToClipboard(shareUrl)
			if (success) {
				this.toast.success("Link Copied to Clipboard")
			}else{
				this.toast.error("Unable to copy Link")
			}
	
		}


	}

