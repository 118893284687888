import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromDepartments from '../reducer/departments.reducer';

export const getDepartmentsState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.departments
);

export const getDepartmentStateLoading = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentsStateLoading
);

export const getDepartmentStateLoaded = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentsStateLoading
);

export const getDepartmentTypeResult = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentTypeResult
);

export const getDepartmentSubTypeResult = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentSubTypeResult
);

export const getDepartmentFAQResult = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentFAQResult
);

export const getDepartmentsErrorMessage = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentsErrorMessage
);

export const getDepartmentTypeStatusCode = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentTypeStatusCode
);

export const getDepartmentServerErrorMessage = createSelector(
	getDepartmentsState,
	fromDepartments.getDepartmentServerErrorMessage
);