/*******************************
 * Component -  My loyalty points
 * Created By - Neha R
 * Created on - 13/04/2020
 * Updated By - Neha R
 * Updated On - 13/04/2020
 *************************************/
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ObjectInterface } from '../../../models/global-interface';
import { EncrDecrService } from '../../../services/encr-decr.service';

@Component({
  selector: 'app-my-loyalty',
  templateUrl: './my-loyalty.component.html',
  styleUrls: ['./my-loyalty.component.scss']
})
export class MyLoyaltyComponent implements OnInit {
   /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
  userId: any;
  /**
	 * variable use
	 */
  loyaltyData: any;
  /**
	 * variable use
	 */
  loyaltyPoint: any;
  	/**
	 * my loyalty labels
	 */
	myLoyaltyLables: {}
	/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any
	errorMessages: {};
	isBlind;
  /**
	 * Angular method which runs on component initialization
	 */
  constructor( private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute
  ) { 
    this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
  }

  ngOnInit() {

     // token from localstorage
      this.store.select(fromStore.getToken)
      .subscribe((token) => {
        this.token = token;
        if (this.token) {
          const localUserId = this.localStorageService.getUserId();
          this.userId = this.encrDecr.get(localUserId);
        }
	});
    if (Object.keys(this.apiDetails).length > 0) {
      this.store.dispatch(new fromStore.GetMyLoyaltyPoints({
        url: this.apiDetails['user'].base_url +
          this.apiDetails['user'].myLoyalty.url,
        method: this.apiDetails['user'].myLoyalty.method,
        body:{
			userId : this.userId,
			isWebRequest: true
          }
      }));
    }
  
    // server error handling
    this.store.select(fromStore.getMoreServicesNetworkError)
      .subscribe((serverErrorMessage) => {
        if (serverErrorMessage != null) {
          if (serverErrorMessage === 'InvalidCredentials') {
            this.router.navigate(['/Login/login']);
          } else {
            this.serverMessage = serverErrorMessage;
            this.isServerErrorMessage = true;
            console.error(this.serverMessage);
          }
        }
	});
	this.store.select(fromStore.getMyLoyaltyLabels)
	.subscribe(labels => {
		this.myLoyaltyLables = labels;
	});

    this.store.select(fromStore.isGetMyLoyaltyPointsLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});

	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isGetMyLoyaltyPointsLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.getMyLoyaltyPointsStatusCode)
					.subscribe(code => {
						if (code === 200) {
							this.store.select(fromStore.getMyLoyaltyPointsResult)
							.subscribe(result => {
							this.loyaltyData = result;
							this.loyaltyPoint = this.loyaltyData.total;
							});
						} 
						else if (code === 500) {
							this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.dataNotFoundMsg = "no data";
								this.errorMessages = errorMessage;
							});
						} 
						else {
							this.store.select(fromStore.getMyLoyaltyPointsSuccessMessage)
							.subscribe(errorMessages => {
								if(errorMessages)
									console.error(errorMessages);
							});
						}
					});
				}
			});
		}
	});

	// this.store.select(fromStore.isRedeemLoyaltyLoading)
	// .subscribe(loading => {
	// 	this.isLoading = loading;
	// });

	// this.store.select(fromStore.getSelectedLanguage)
	// .subscribe(lang => {
	// 	this.selectedLanguage = lang;
	// 	if (this.selectedLanguage) {
	// 		this.store.select(fromStore.isRedeemLoyaltyLoaded)
	// 		.subscribe(loaded => {
	// 			if (loaded) {
	// 				this.store.select(fromStore.redeemLoyaltyStatusCode)
	// 				.subscribe(code => {
	// 					if (code === 200) {
	// 						this.store.select(fromStore.redeemLoyaltySuccessMessage)
	// 						.subscribe(message => {
	// 							if (message) {
	// 								this.store.select(fromStore.getSuccessMessages)
	// 								.subscribe(successMessage => {
	// 									// this.loyaltyData = result;
	// 									// this.loyaltyPoint = this.loyaltyData.total;
	// 									this.toastr.success(successMessage[this.selectedLanguage].message);
	// 								});
	// 							} 
	// 						});
	// 					}
	// 					else {
	// 						this.store.select(fromStore.redeemLoyaltySuccessMessage)
	// 						.subscribe(errorMessages => {
	// 							if(errorMessages)
	// 								console.error(errorMessages);
	// 						});
	// 					}
	// 				});
	// 			}
	// 		});
	// 	}
	// });
  }


  /**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }
  /**
   * Created by Neha
   * Tap to redeem
   */
  async tapToRedeem(redeemData){
	if (Object.keys(this.apiDetails).length > 0) {
		await this.store.dispatch(new fromStore.RedeemLoyalty({
		  url: this.apiDetails['user'].base_url +
			this.apiDetails['user'].redeemLoyalty.url,
		  method: this.apiDetails['user'].redeemLoyalty.method,
		  body:{
				engagementId : redeemData.engagementId,
				cartId : redeemData.cartId
			}
		}));

		this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isRedeemLoyaltyLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.redeemLoyaltyStatusCode)
					.subscribe(code => {
						if (code === 200) {
							this.store.select(fromStore.redeemLoyaltySuccessMessage)
							.subscribe(message => {
								if (message) {
									this.store.select(fromStore.getSuccessMessages)
									.subscribe(successMessage => {
										// this.loyaltyData = result;
										// this.loyaltyPoint = this.loyaltyData.total;
										this.toastr.success(successMessage[this.selectedLanguage].message);
									});
									this.store.dispatch(new fromStore.GetMyLoyaltyPoints({
										url: this.apiDetails['user'].base_url +
										  this.apiDetails['user'].myLoyalty.url,
										method: this.apiDetails['user'].myLoyalty.method,
										body:{
											userId : this.userId,
											isWebRequest: true
										  }
									  }));
								} 
							});
						}
						else {
							this.store.select(fromStore.redeemLoyaltySuccessMessage)
							.subscribe(errorMessages => {
								if(errorMessages)
									console.error(errorMessages);
							});
						}
					});
				}
			});
		}
	});

	  }
  }
  	/**
	 * angular method which gets called on page view initialization
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
}
