export const GET_DEATH_DATE_LIST = 'GET_DEATH_DATE_LIST';
export const GET_DEATH_DATE_LIST_SUCCESS = 'GET_DEATH_DATE_LIST_SUCCESS';
export const GET_DEATH_DATE_LIST_FAIL = 'GET_DEATH_DATE_LIST_FAIL';
import { Action } from '@ngrx/store';


export class GetDEATHDateList implements Action {
	readonly type = GET_DEATH_DATE_LIST;
	constructor(public payload: any) { }
}
export class GetDEATHDateListSuccess implements Action {
	readonly type = GET_DEATH_DATE_LIST_SUCCESS;
	constructor(public payload: any) { }
}
export class GetDEATHDateListFail implements Action {
	readonly type = GET_DEATH_DATE_LIST_FAIL;
	constructor(public payload: any) { }
}

export type DEATHDateAction = GetDEATHDateList | GetDEATHDateListSuccess | GetDEATHDateListFail;