import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as  fromAroundMeOnMap from  '../reducer/around-me.reducer';

export const getAroundMeOnMapState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.aroundMe
);

export const getAroundMeErrorMessage = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeErrorMessage
);

export const getAroundMeLocations = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeLocations
);

export const getAroundMeLoading = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeLoading
);

export const getAroundMeLoaded = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeLoaded
);

export const getAroundMeCategoryLoading = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeCategoryLoading
);

export const getAroundMeCategoryLoaded = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeCategoryLoaded
);

export const getAroundMeCategoryList = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeCategoryList
);

export const getaroundMeCategoryErrorMessage = createSelector(
	getAroundMeOnMapState,
	fromAroundMeOnMap.getAroundMeCategoryErrorMessage
);