import * as fromWebinarAction from '../../store/action/webinar.action';

export interface WebinarState {
	webinarServerErrorMessage: any;
	result: {}[];
	isLoading:boolean;
    isLoaded:boolean;
    webinarByIdResult: {};
	isWebinarByIdLoading:boolean;
	isWebinarByIdLoaded:boolean;
	webinarListStatusCode: number;
	webinarPaginationCount:number;

	addOrRemoveWebinarCommentResult: {};
	addOrRemoveWebinarCommentErrorMessage: string;
	isaddOrRemoveWebinarCommentLoading: boolean;
	isaddOrRemoveWebinarCommentLoaded: boolean;
	addOrRemoveWebinarCommentStatusCode: number;
  
	getAllWebinarCommentResult: [];
	getAllWebinarCommentErrorMessage: string;
	isGetAllWebinarCommentLoading: boolean;
	isGetAllWebinarCommentLoaded: boolean;
	getAllWebinarCommentStatusCode: number;
	serverErrorMessage: string;
	
	ReportAbuseWebinarCommentResult: {};
	ReportAbuseWebinarCommentErrorMessage: string;
	isReportAbuseWebinarCommentLoading: boolean;
	isReportAbuseWebinarCommentLoaded: boolean;
	ReportAbuseWebinarCommentStatusCode: number;
  

}

export const initialState: WebinarState = {
	result: [],
	isLoading:false,
	isLoaded:false,
    webinarServerErrorMessage: null,
	isWebinarByIdLoading:false,
	webinarListStatusCode: null,
	isWebinarByIdLoaded:false,
	webinarByIdResult:{},
	webinarPaginationCount:0,


	addOrRemoveWebinarCommentResult: {},
	addOrRemoveWebinarCommentErrorMessage: null,
	isaddOrRemoveWebinarCommentLoading: false,
	isaddOrRemoveWebinarCommentLoaded: false,
	addOrRemoveWebinarCommentStatusCode: 0,


	getAllWebinarCommentResult: [],
	getAllWebinarCommentErrorMessage: null,
	isGetAllWebinarCommentLoading: false,
	isGetAllWebinarCommentLoaded: false,
	getAllWebinarCommentStatusCode: 0,
	serverErrorMessage: null,

	ReportAbuseWebinarCommentResult: {},
	ReportAbuseWebinarCommentErrorMessage: null,
	isReportAbuseWebinarCommentLoading: false,
	isReportAbuseWebinarCommentLoaded: false,
	ReportAbuseWebinarCommentStatusCode: 0,


};

export function reducer(
	state = initialState,
	action: fromWebinarAction.webinarAction
): WebinarState {
	switch (action.type) {
		case fromWebinarAction.GET_WEBINAR_LIST: {

			return {
				...state,
				isLoading:true,
				isLoaded:false
			};
		}

		case fromWebinarAction.ADD_OR_REMOVE_WEBINAR_COMMENT: {

			
			return {
			  ...state,
			  addOrRemoveWebinarCommentResult: {},
			  isaddOrRemoveWebinarCommentLoading: true,
			  isaddOrRemoveWebinarCommentLoaded: false,
			};
		  }

		  case fromWebinarAction.ADD_OR_REMOVE_WEBINAR_COMMENT_SUCCESS: {
		
			  
			let addOrRemoveWebinarCommentStatusCode = action.payload.code;
			let addOrRemoveWebinarCommentResult = state.addOrRemoveWebinarCommentResult;
			let isaddOrRemoveWebinarCommentLoaded = state.isaddOrRemoveWebinarCommentLoaded;
			let isaddOrRemoveWebinarCommentLoading = state.isaddOrRemoveWebinarCommentLoading;
			let addOrRemoveWebinarCommentErrorMessage = state.addOrRemoveWebinarCommentErrorMessage;
	
			
			switch (addOrRemoveWebinarCommentStatusCode) {
			  case 200:
				addOrRemoveWebinarCommentResult = action.payload.result;
				isaddOrRemoveWebinarCommentLoading = false;
				isaddOrRemoveWebinarCommentLoaded = true;
				addOrRemoveWebinarCommentErrorMessage = action.payload.message;
				break;
	  
			  case 500:
				isaddOrRemoveWebinarCommentLoading = false;
				isaddOrRemoveWebinarCommentLoaded = true;
				addOrRemoveWebinarCommentErrorMessage = action.payload.message;
				break;
			}
	  
			return {
			  ...state,
			  addOrRemoveWebinarCommentResult,
			  isaddOrRemoveWebinarCommentLoading,
			  isaddOrRemoveWebinarCommentLoaded,
			  addOrRemoveWebinarCommentStatusCode,
			  addOrRemoveWebinarCommentErrorMessage
			};
		  }

		  case fromWebinarAction.ADD_OR_REMOVE_WEBINAR_COMMENT_FAIL: {
		
			  
			let statusCode = action.payload.status
			let serverErrorMessage = state.serverErrorMessage
			if (statusCode == 401 || statusCode == 0) {
			  serverErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
			  serverErrorMessage = action.payload.error.message;
			}
			return {
			  ...state,
			  serverErrorMessage,
			  isaddOrRemoveWebinarCommentLoading: false,
			  isaddOrRemoveWebinarCommentLoaded: true,
			};
		  }

		  case fromWebinarAction.GET_ALL_WEBINAR_COMMENTS: {
			return {
			  ...state,
			  getAllWebinarCommentResult: [],
			  isGetAllWebinarCommentLoading: true,
			  isGetAllWebinarCommentLoaded: false,
			};
		  }

		  case fromWebinarAction.GET_ALL_WEIBNAR_COMMENTS_SUCCESS: {
			let getAllWebinarCommentStatusCode = action.payload.code;
			let getAllWebinarCommentResult = state.getAllWebinarCommentResult;
			let isGetAllWebinarCommentLoaded = state.isGetAllWebinarCommentLoaded;
			let isGetAllWebinarCommentLoading = state.isGetAllWebinarCommentLoading;
			let getAllWebinarCommentErrorMessage = state.getAllWebinarCommentErrorMessage;
	  
			switch (getAllWebinarCommentStatusCode) {
			  case 200:
				getAllWebinarCommentResult = action.payload.result;
				isGetAllWebinarCommentLoading = false;
				isGetAllWebinarCommentLoaded = true;
				break;
	  
			  case 500:
				isGetAllWebinarCommentLoading = false;
				isGetAllWebinarCommentLoaded = true;
				getAllWebinarCommentErrorMessage = action.payload.message;
				break;
			}
	  
			return {
			  ...state,
			  getAllWebinarCommentResult,
			  isGetAllWebinarCommentLoading,
			  isGetAllWebinarCommentLoaded,
			  getAllWebinarCommentStatusCode,
			  getAllWebinarCommentErrorMessage
			};
		  }

		  case fromWebinarAction.GET_ALL_WEBINAR_COMMENTS_FAIL: {
			let statusCode = action.payload.status
			let serverErrorMessage = state.serverErrorMessage
			if (statusCode == 401 || statusCode == 0) {
			  serverErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
			  serverErrorMessage = action.payload.error.message;
			}
			return {
			  ...state,
			  serverErrorMessage,
			  isGetAllWebinarCommentLoading: false,
			  isGetAllWebinarCommentLoaded: true,
			};
		  }


		  case fromWebinarAction.REPORT_ABUSE_WEBINAR_COMMENT: {
			return {
			  ...state,
			  ReportAbuseWebinarCommentResult: {},
			  isReportAbuseWebinarCommentLoading: true,
			  isReportAbuseWebinarCommentLoaded: false,
			};
		  }
		  case fromWebinarAction.REPORT_ABUSE_WEBINAR_COMMENT_SUCCESS: {
			let ReportAbuseWebinarCommentStatusCode = action.payload.code;
			let ReportAbuseWebinarCommentResult = state.ReportAbuseWebinarCommentResult;
			let isReportAbuseWebinarCommentLoaded = state.isReportAbuseWebinarCommentLoaded;
			let isReportAbuseWebinarCommentLoading = state.isReportAbuseWebinarCommentLoading;
			let ReportAbuseWebinarCommentErrorMessage = state.ReportAbuseWebinarCommentErrorMessage;
			switch (ReportAbuseWebinarCommentStatusCode) {
			  case 200:
				ReportAbuseWebinarCommentResult = action.payload.result;
				isReportAbuseWebinarCommentLoading = false;
				isReportAbuseWebinarCommentLoaded = true;
				ReportAbuseWebinarCommentErrorMessage = action.payload.message;
				break;
	  
			  case 500:
				isReportAbuseWebinarCommentLoading = false;
				isReportAbuseWebinarCommentLoaded = true;
				ReportAbuseWebinarCommentErrorMessage = action.payload.message;
				break;
			}
	  
			return {
			  ...state,
			  ReportAbuseWebinarCommentResult,
			  isReportAbuseWebinarCommentLoading,
			  isReportAbuseWebinarCommentLoaded,
			  ReportAbuseWebinarCommentStatusCode,
			  ReportAbuseWebinarCommentErrorMessage
			};
		  }
		  case fromWebinarAction.REPORT_ABUSE_WEBINAR_COMMENT_FAIL: {
			let statusCode = action.payload.status
			let serverErrorMessage = state.serverErrorMessage
			if (statusCode == 401 || statusCode == 0) {
			  serverErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
			  serverErrorMessage = action.payload.error.message;
			}
			return {
			  ...state,
			  serverErrorMessage,
			  isReportAbuseWebinarCommentLoading: false,
			  isReportAbuseWebinarCommentLoaded: true,
			};
		  }

		case fromWebinarAction.GET_WEBINAR_LIST_SUCCESS: {
			let statusCode = action.payload.code;
			let result = state.result;
			let isLoaded = state.isLoaded;
			let isLoading = state.isLoading;
			let webinarServerErrorMessage = state.webinarServerErrorMessage;
			let webinarPaginationCount = state.webinarPaginationCount;
			let data = [];
			let webinarListStatusCode = state.webinarListStatusCode 
			if (statusCode == 200) {
				webinarListStatusCode = statusCode
				if (webinarPaginationCount == 0) {
					result = action.payload.result;
					
				  } else {
					data = result;
					result = [...data, ...action.payload.result];
				  }
				isLoaded = true;
				isLoading = false;
			} else{
				webinarListStatusCode = 500
				webinarServerErrorMessage = action.payload.message;
			}
			
			return {
				...state,
				result,
				webinarListStatusCode,
				webinarServerErrorMessage,
				isLoading,
				isLoaded
			};
		}
		case fromWebinarAction.GET_WEBINAR_LIST_FAIL: {
			let statusCode = action.payload.status
			let webinarServerErrorMessage = state.webinarServerErrorMessage
			if(statusCode == 401 || statusCode == 0) {
				webinarServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				webinarServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				webinarServerErrorMessage
			};
		}
		case fromWebinarAction.SET_PAGINATION_COUNT_WEBINAR: {
			let webinarPaginationCount = action.payload.webinarPaginationCount;
			let result = state.result;
			if (webinarPaginationCount == 0) {
	  
				result = []
			}
			return {
			  ...state,
			  webinarPaginationCount,
			  result
			};
		}
		
        case fromWebinarAction.GET_WEBINAR_BY_ID: {

			return {
				...state,
				isWebinarByIdLoading:true,
				isWebinarByIdLoaded:false
			};
		}

		case fromWebinarAction.GET_WEBINAR_BY_ID_SUCCESS: {
			let statusCode = action.payload.code;
			let webinarByIdResult = state.webinarByIdResult;
			let isWebinarByIdLoaded = state.isWebinarByIdLoaded;
			let isWebinarByIdLoading = state.isWebinarByIdLoading;
			if (statusCode == 200) {
				webinarByIdResult = action.payload.result;
				isWebinarByIdLoaded = true;
				isWebinarByIdLoading = false;
			}
			return {
				...state,
				webinarByIdResult,
				isWebinarByIdLoading,
				isWebinarByIdLoaded
			};
		}
		case fromWebinarAction.GET_WEBINAR_BY_ID_FAIL: {
			let statusCode = action.payload.status
			let webinarServerErrorMessage = state.webinarServerErrorMessage
			if(statusCode == 401 || statusCode == 0) {
				webinarServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				webinarServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				webinarServerErrorMessage
			};
		}
	}
	return state;
}

export const getWebinarListResult = (state: WebinarState) => state.result;
export const webinarListStatusCode = (state: WebinarState) => state.webinarListStatusCode;
export const getIsWebinarListLoading = (state: WebinarState) => state.isLoading;
export const getIsWebinarListLoaded = (state: WebinarState) => state.isLoaded;
export const getwebinarServerErrorMessage = (state: WebinarState) => state.webinarServerErrorMessage;

export const getWebinarByIdResult = (state: WebinarState) => state.webinarByIdResult;
export const getIsWebinarByIdLoading = (state: WebinarState) => state.isWebinarByIdLoading;
export const getIsWebinarByIdLoaded = (state: WebinarState) => state.isWebinarByIdLoaded;
export const getWebinarPaginationCount = (state: WebinarState) => state.webinarPaginationCount;


export const addOrRemoveWebinarCommentResult = (state: WebinarState) => state.addOrRemoveWebinarCommentResult;
export const addOrRemoveWebinarCommentStatusCode = (state: WebinarState) => state.addOrRemoveWebinarCommentStatusCode;
export const addOrRemoveWebinarCommentErrorMessage = (state: WebinarState) => state.addOrRemoveWebinarCommentErrorMessage;
export const isAddOrRemoveWebinarCommentLoading = (state: WebinarState) => state.isaddOrRemoveWebinarCommentLoading;
export const isAddOrRemoveWebinarCommentLoaded = (state: WebinarState) => state.isaddOrRemoveWebinarCommentLoaded;
 
export const getAllWebinarCommentResult = (state: WebinarState) => state.getAllWebinarCommentResult;
export const getAllWebinarCommentStatusCode = (state: WebinarState) => state.getAllWebinarCommentStatusCode;
export const getAllWebinarCommentErrorMessage = (state: WebinarState) => state.getAllWebinarCommentErrorMessage;
export const isGetAllWebinarCommentLoading = (state: WebinarState) => state.isGetAllWebinarCommentLoading;
export const isGetAllWebinarCommentLoaded = (state: WebinarState) => state.isGetAllWebinarCommentLoaded;

export const ReportAbuseWebinarCommentResult = (state: WebinarState) => state.ReportAbuseWebinarCommentResult;
export const ReportAbuseWebinarCommentStatusCode = (state: WebinarState) => state.ReportAbuseWebinarCommentStatusCode;
export const ReportAbuseWebinarCommentErrorMessage = (state: WebinarState) => state.ReportAbuseWebinarCommentErrorMessage;
export const isReportAbuseWebinarCommentLoading = (state: WebinarState) => state.isReportAbuseWebinarCommentLoading;
export const isReportAbuseWebinarCommentLoaded = (state: WebinarState) => state.isReportAbuseWebinarCommentLoaded;

