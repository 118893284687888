import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from "@ngrx/store";
import * as fromStore from "../../../cep/store/index";
@Component({
  selector: 'app-partial-ptax-pay',
  templateUrl:"./partial-ptax-pay.component.html",
  styleUrls: ["./partial-ptax-pay.component.scss"]
})
export class PartialPropertyTaxPay {
  partialAmount: string | number = '';
  totalDues: number;
  amountError: string;
  isAmountValid: boolean = false;
  selectedLanguage: any;
  labels: any;

  constructor(
    public dialogRef: MatDialogRef<PartialPropertyTaxPay>,
    private store: Store<fromStore.CEPState>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {this.totalDues = data.amountDue; }

ngOnInit(){
  this.store.select(fromStore.getSelectedLanguage).subscribe((language) => {
    this.selectedLanguage = language;
    if (this.selectedLanguage) {
      this.store
        .select(fromStore.getPropertyTaxLabels)
        .subscribe((labels) => {
          this.labels = labels;
        });
    }
  });
}

// partial payment let's keep the below validations 
// min 1rs (no 0.01, 0.05, 0.5) minimum it should be 1.00
// upto 2 decimal places 
// amount should be less or equal to due amount

// 
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateAmount(): void {
    const amountPattern = /^\d+$/; //condition changed from  upto 2 decimal places allowed to whole numbers 

    // Convert partialAmount to a number (if it's a string)
    const amount = parseFloat(this.partialAmount.toString());

    if (!amountPattern.test(this.partialAmount.toString())) {
      this.amountError = 'Please enter a whole number without decimals.';
      this.isAmountValid = false;
    } else if (amount < 1) {
      this.amountError = 'Amount should be at least 1';
      this.isAmountValid = false;
    } else if (amount >= this.totalDues) {
      this.amountError = `Amount should be less than ${this.totalDues}`;
      this.isAmountValid = false;
    } else {
      this.amountError = '';
      this.isAmountValid = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.isAmountValid) {
      this.dialogRef.close(this.partialAmount);
    }    
  }
}
