import { Injectable } from '@angular/core';
import {  Actions, ofType, createEffect  } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as  BirthDateActionCreator from '../action-creator/birth-date.action-creator';
import * as  BirthDateAction from '../action/birth-date.action';
import { Observable, of } from 'rxjs';
import { BirthDateService } from '../api-services/birth-date.service';


@Injectable()

export class BirthDateEffects {
	constructor(
		private actions$: Actions,
		private birthDateService: BirthDateService
  )
   { 
  }

  birthDateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BirthDateActionCreator.GetBirthDateList>(BirthDateAction.GET_BIRTH_DATE_LIST),
      switchMap((action) => {
        return this.birthDateService.getBirthDateList(action.payload).pipe(
          map(data => new BirthDateActionCreator.GetBirthDateListSuccess(data)),
          catchError(error => of(new BirthDateActionCreator.GetBirthDateListFail(error)))
        );
      })
    )
  );

	// @Effect()
  //   birthDateEffect$ = this.actions$.pipe(
  //     ofType< BirthDateActionCreator.GetBirthDateList>( BirthDateAction.GET_BIRTH_DATE_LIST),
  //     switchMap((action) => {
  //       return this.birthDateService.getBirthDateList(action.payload).pipe(
	// 				map(data => new  BirthDateActionCreator.GetBirthDateListSuccess(data)),
	// 				catchError(error => of(new  BirthDateActionCreator.GetBirthDateListFail(error)))
	// 			);
	// 		})
  //   );
}

