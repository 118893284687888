import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as feedListing from '../reducer/feeds.reducer';

export const getFeedsListingState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.feedListing
);

export const getTimelineListingErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getTimelineListingErrorMessage
);

export const getisFiltterAppled = createSelector(
	getFeedsListingState,
	feedListing.getisFiltterAppled
);

export const getFeedsListingResult = createSelector(
	getFeedsListingState,
	feedListing.getFeedsListingResult
);

export const getFeedsListingEmergencyResult = createSelector(
	getFeedsListingState,
	feedListing.getFeedsListingEmergencyResult
);

export const getTimeLineListingStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getTimeLineListingStatusCode
);

export const getTimeLineListingEmergencyStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getTimeLineListingEmergencyStatusCode
);

export const feedDetailsResult = createSelector(
	getFeedsListingState,
	feedListing.getFeedsDetailResult
);

export const isDetailsScreenLoading = createSelector(
	getFeedsListingState,
	feedListing.isDetailsScreenLoading
);

export const isDetailsScreenLoaded = createSelector(
	getFeedsListingState,
	feedListing.isDetailsScreenLoaded
);

export const getIsFeedsListLoaded = createSelector(
	getFeedsListingState,
	feedListing.getIsFeedsListLoaded
);

export const getIsFeedsListLoading = createSelector(
	getFeedsListingState,
	feedListing.getIsFeedsListLoading
);

export const getFeedsListingErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getFeedsListingErrorMessage
);

export const getIsFeedsListEmergencyLoaded = createSelector(
	getFeedsListingState,
	feedListing.getIsFeedsListEmergencyLoaded
);

export const getIsFeedsListEmergencyLoading = createSelector(
	getFeedsListingState,
	feedListing.getIsFeedsListEmergencyLoading
);

export const getTimelineListingEmergencyErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getTimelineListingEmergencyErrorMessage
);

export const getFeedsPaginationCount = createSelector(
	getFeedsListingState,
	feedListing.getFeedsPaginationCount
);

export const emergencyPaginationCount = createSelector(
	getFeedsListingState,
	feedListing.emergencyPaginationCount
);

export const feedListingStatusCode = createSelector(
	getFeedsListingState,
	feedListing.feedListingStatusCode
);

export const getFeedsServerErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getFeedsServerErrorMessage
);


export const getFilteredLabels = createSelector(
	getFeedsListingState,
	feedListing.getFilteredLabels
);

 
export const AddOrRemoveBlogCommentResult = createSelector(
	getFeedsListingState,
	feedListing.addOrRemoveBlogCommentResult
);
export const AddOrRemoveBlogCommentStatusCode = createSelector(
	getFeedsListingState,
	feedListing.addOrRemoveBlogCommentStatusCode
);
export const AddOrRemoveBlogCommentErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.addOrRemoveBlogCommentErrorMessage
);
export const isAddOrRemoveBlogCommentLoading = createSelector(
	getFeedsListingState,
	feedListing.isAddOrRemoveBlogCommentLoading
);
export const isAddOrRemoveBlogCommentLoaded = createSelector(
	getFeedsListingState,
	feedListing.isAddOrRemoveBlogCommentLoaded
);



export const getAllBlogCommentResult = createSelector(
	getFeedsListingState,
	feedListing.getAllBlogCommentResult
);
export const getAllBlogCommentStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getAllBlogCommentStatusCode
);
export const getAllBlogCommentErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getAllBlogCommentErrorMessage
);
export const isGetAllBlogCommentLoading = createSelector(
	getFeedsListingState,
	feedListing.isGetAllBlogCommentLoading
);
export const isGetAllBlogCommentLoaded = createSelector(
	getFeedsListingState,
	feedListing.isGetAllBlogCommentLoaded
);

export const getFeedsEmergencyResult = createSelector(
	getFeedsListingState,
	feedListing.getFeedsEmergencyResult
);
export const getFeedsEmergencyStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getFeedsEmergencyStatusCode
);
export const getFeedsEmergencyErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getFeedsEmergencyErrorMessage
);
export const isGetFeedsEmergencyLoading = createSelector(
	getFeedsListingState,
	feedListing.isGetFeedsEmergencyLoading
);
export const isGetFeedsEmergencyLoaded = createSelector(
	getFeedsListingState,
	feedListing.isGetFeedsEmergencyLoaded
);

export const addBlogResult = createSelector(
	getFeedsListingState,
	feedListing.addBlogResult
);
export const addBlogStatusCode = createSelector(
	getFeedsListingState,
	feedListing.addBlogStatusCode
);
export const addBlogErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.addBlogErrorMessage
);
export const isaddBlogLoading = createSelector(
	getFeedsListingState,
	feedListing.isAddBlogLoading
);
export const isaddBlogLoaded = createSelector(
	getFeedsListingState,
	feedListing.isAddBlogLoaded
);

export const addForumResult = createSelector(
	getFeedsListingState,
	feedListing.addForumResult
);
export const addForumStatusCode = createSelector(
	getFeedsListingState,
	feedListing.addForumStatusCode
);
export const addForumErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.addForumErrorMessage
);
export const isAddForumLoading = createSelector(
	getFeedsListingState,
	feedListing.isAddForumLoading
);
export const isAddForumLoaded = createSelector(
	getFeedsListingState,
	feedListing.isAddForumLoaded
);

export const updateForumResult = createSelector(
	getFeedsListingState,
	feedListing.updateForumResult
);
export const updateForumStatusCode = createSelector(
	getFeedsListingState,
	feedListing.updateForumStatusCode
);
export const updateForumErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.updateForumErrorMessage
);
export const isUpdateForumLoading = createSelector(
	getFeedsListingState,
	feedListing.isUpdateForumLoading
);
export const isUpdateForumLoaded = createSelector(
	getFeedsListingState,
	feedListing.isUpdateForumLoaded
);

export const removeForumResult = createSelector(
	getFeedsListingState,
	feedListing.removeForumResult
);
export const removeForumStatusCode = createSelector(
	getFeedsListingState,
	feedListing.removeForumStatusCode
);
export const removeForumErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.removeForumErrorMessage
);
export const isRemoveForumLoading = createSelector(
	getFeedsListingState,
	feedListing.isRemoveForumLoading
);
export const isRemoveForumLoaded = createSelector(
	getFeedsListingState,
	feedListing.isRemoveForumLoaded
);

export const GetForumByUserResult = createSelector(
	getFeedsListingState,
	feedListing.getForumByUserResult
);
export const GetForumByUserStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getForumByUserStatusCode
);
export const GetForumByUserErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getForumByUserErrorMessage
);
export const isGetForumByUserLoading = createSelector(
	getFeedsListingState,
	feedListing.isGetForumByUserLoading
);
export const isGetForumByUserLoaded = createSelector(
	getFeedsListingState,
	feedListing.isGetForumByUserLoaded
);


export const ReportAbuseResult = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseResult
);
export const ReportAbuseStatusCode = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseStatusCode
);
export const ReportAbuseErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseErrorMessage
);
export const isReportAbuseLoading = createSelector(
	getFeedsListingState,
	feedListing.isReportAbuseLoading
);
export const isReportAbuseLoaded = createSelector(
	getFeedsListingState,
	feedListing.isReportAbuseLoaded
);

export const ReportAbuseCommentResult = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseCommentResult
);
export const ReportAbuseCommentStatusCode = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseCommentStatusCode
);
export const ReportAbuseCommentErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.reportAbuseCommentErrorMessage
);
export const isReportAbuseCommentLoading = createSelector(
	getFeedsListingState,
	feedListing.isReportAbuseCommentLoading
);
export const isReportAbuseCommentLoaded = createSelector(
	getFeedsListingState,
	feedListing.isReportAbuseCommentLoaded
);

export const ReportWebinarAbuseResult = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseResult
);
export const ReportWebinarAbuseStatusCode = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseStatusCode
);
export const ReportWebinarAbuseErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseErrorMessage
);
export const isReportWebinarAbuseLoading = createSelector(
	getFeedsListingState,
	feedListing.isReportWebinarAbuseLoading
);
export const isReportWebinarAbuseLoaded = createSelector(
	getFeedsListingState,
	feedListing.isReportWebinarAbuseLoaded
);

export const ReportWebinarAbuseCommentResult = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseCommentResult
);
export const ReportWebinarAbuseCommentStatusCode = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseCommentStatusCode
);
export const ReportWebinarAbuseCommentErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.reportWebinarAbuseCommentErrorMessage
);
export const isReportWebinarAbuseCommentLoading = createSelector(
	getFeedsListingState,
	feedListing.isReportWebinarAbuseCommentLoading
);
export const isReportWebinarAbuseCommentLoaded = createSelector(
	getFeedsListingState,
	feedListing.isReportWebinarAbuseCommentLoaded
);

export const RegistrationCompetitionResult = createSelector(
	getFeedsListingState,
	feedListing.registrationCompetitionResult
);
export const RegistrationCompetitionStatusCode = createSelector(
	getFeedsListingState,
	feedListing.registrationCompetitionStatusCode
);
export const RegistrationCompetitionErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.registrationCompetitionErrorMessage
);
export const isRegistrationCompetitionLoading = createSelector(
	getFeedsListingState,
	feedListing.isRegistrationCompetitionLoading
);
export const isRegistrationCompetitionLoaded = createSelector(
	getFeedsListingState,
	feedListing.isRegistrationCompetitionLoaded
);

export const SubmitCompetitionResult = createSelector(
	getFeedsListingState,
	feedListing.submitCompetitionResult
);
export const SubmitCompetitionStatusCode = createSelector(
	getFeedsListingState,
	feedListing.submitCompetitionStatusCode
);
export const SubmitCompetitionErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.submitCompetitionErrorMessage
);
export const isSubmitCompetitionLoading = createSelector(
	getFeedsListingState,
	feedListing.isSubmitCompetitionLoading
);
export const isSubmitCompetitionLoaded = createSelector(
	getFeedsListingState,
	feedListing.isSubmitCompetitionLoaded
);
export const isTimelineChnaged = createSelector(
	getFeedsListingState,
	feedListing.isTimelineChnaged
);

export const getUserNotificationResult = createSelector(
	getFeedsListingState,
	feedListing.getUserNotificationResult
);
export const getUserNotificationStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getUserNotificationStatusCode
);
export const getUserNotificationErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getUserNotificationErrorMessage
);
export const isgetUserNotificationLoading = createSelector(
	getFeedsListingState,
	feedListing.isgetUserNotificationLoading
);
export const isgetUserNotificationLoaded = createSelector(
	getFeedsListingState,
	feedListing.isgetUserNotificationLoaded
);

export const getBlogListingByUserIdResult = createSelector(
	getFeedsListingState,
	feedListing.getBlogListingByUserIdResult
);
export const getBlogListingByUserIdStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getBlogListingByUserIdStatusCode
);
export const getBlogListingByUserIdErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getBlogListingByUserIdErrorMessage
);
export const isgetBlogListingByUserIdLoading = createSelector(
	getFeedsListingState,
	feedListing.isgetBlogListingByUserIdLoading
);
export const isgetBlogListingByUserIdLoaded = createSelector(
	getFeedsListingState,
	feedListing.isgetBlogListingByUserIdLoaded
);

export const getForumListingByUserIdResult = createSelector(
	getFeedsListingState,
	feedListing.getForumListingByUserIdResult
);
export const getForumListingByUserIdStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getForumListingByUserIdStatusCode
);
export const getForumListingByUserIdErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getForumListingByUserIdErrorMessage
);
export const isgetForumListingByUserIdLoading = createSelector(
	getFeedsListingState,
	feedListing.isgetForumListingByUserIdLoading
);
export const isgetForumListingByUserIdLoaded = createSelector(
	getFeedsListingState,
	feedListing.isgetForumListingByUserIdLoaded
);

export const getBlogByBlogIdResult = createSelector(
	getFeedsListingState,
	feedListing.getBlogByBlogIdResult
);
export const getBlogByBlogIdStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getBlogByBlogIdStatusCode
);
export const getBlogByBlogIdErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getBlogByBlogIdErrorMessage
);
export const isgetBlogByBlogIdLoading = createSelector(
	getFeedsListingState,
	feedListing.isgetBlogByBlogIdLoading
);
export const isgetBlogByBlogIdLoaded = createSelector(
	getFeedsListingState,
	feedListing.isgetBlogByBlogIdLoaded
);

export const editBlogResult = createSelector(
	getFeedsListingState,
	feedListing.editBlogResult
);
export const editBlogStatusCode = createSelector(
	getFeedsListingState,
	feedListing.editBlogStatusCode
);
export const editBlogErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.editBlogErrorMessage
);
export const iseditBlogLoading = createSelector(
	getFeedsListingState,
	feedListing.iseditBlogLoading
);
export const iseditBlogLoaded = createSelector(
	getFeedsListingState,
	feedListing.iseditBlogLoaded
);

export const getForumByForumIdResult = createSelector(
	getFeedsListingState,
	feedListing.getForumByForumIdResult
);
export const getForumByForumIdStatusCode = createSelector(
	getFeedsListingState,
	feedListing.getForumByForumIdStatusCode
);
export const getForumByForumIdErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.getForumByForumIdErrorMessage
);
export const isgetForumByForumIdLoading = createSelector(
	getFeedsListingState,
	feedListing.isgetForumByForumIdLoading
);
export const isgetForumByForumIdLoaded = createSelector(
	getFeedsListingState,
	feedListing.isgetForumByForumIdLoaded
);

export const editForumResult = createSelector(
	getFeedsListingState,
	feedListing.editForumResult
);
export const editForumStatusCode = createSelector(
	getFeedsListingState,
	feedListing.editForumStatusCode
);
export const editForumErrorMessage = createSelector(
	getFeedsListingState,
	feedListing.editForumErrorMessage
);
export const iseditForumLoading = createSelector(
	getFeedsListingState,
	feedListing.iseditForumLoading
);
export const iseditForumLoaded = createSelector(
	getFeedsListingState,
	feedListing.iseditForumLoaded
);

export const updateBlogPhoto = createSelector(
	getFeedsListingState,
	feedListing.updateBlogPhotoResponse
);
export const updateBlogPhotoStatusCode = createSelector(
	getFeedsListingState,
	feedListing.updateBlogPhotoStatusCode
);
export const isUpdateBlogPhotoLoading = createSelector(
	getFeedsListingState,
	feedListing.isUpdateBlogPhotoLoading
);
export const isUpdateBlogPhotoLoaded = createSelector(
	getFeedsListingState,
	feedListing.isUpdateBlogPhotoLoaded
);

export const updateBlogPhotoFav = createSelector(
	getFeedsListingState,
	feedListing.updateBlogPhotoFavResponse
);
export const updateBlogPhotoFavStatusCode = createSelector(
	getFeedsListingState,
	feedListing.updateBlogPhotoFavStatusCode
);
export const isUpdateBlogPhotoFavLoading = createSelector(
	getFeedsListingState,
	feedListing.isUpdateBlogPhotoFavLoading
);
export const isUpdateBlogPhotoFavLoaded = createSelector(
	getFeedsListingState,
	feedListing.isUpdateBlogPhotoFavLoaded
);

export const blogsPaginationCount = createSelector(
	getFeedsListingState,
	feedListing.blogsPaginationCount
);

export const forumsPaginationCount = createSelector(
	getFeedsListingState,
	feedListing.forumsPaginationCount
);