/*******************************
 * Component -  My Deal history
 * Created By - Neha R
 * Created on - 07/04/2020
 * Updated By - Neha R
 * Updated On - 07/04/2020
 *************************************/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ObjectInterface } from '../../../models/global-interface';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
@Component({
  selector: 'app-my-deals',
  templateUrl: './my-deals.component.html',
  styleUrls: ['./my-deals.component.scss']
})
export class MyDealsComponent implements OnInit {
  /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * variable use
	 */
	myDeal: any;
		/**
	 * my deals labels
	 */
	myDealsLables: {};
	/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any;
	errorMessages: {};
	isBlind;
	/**
	 * Angular method which runs on component initialization
	 */
  constructor(
    private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private googleAnalyticsService: GoogleAnalyticsService,

  ) {

  }

  ngOnInit() {
	this.googleAnalyticsService.eventEmitter(
		screenName.MY_DEALS+ "_SCREEN",
		{
		  log: clickedOn.LOG_SCREEN,
		  screenName: screenName.MY_DEALS + "_MOUNT",
		  screenStartTime: new Date(),
		  isScroll: false,
		  screenEndTime: "",
		  scrollValue: "",
		}
		);	
    // token from localstorage
		this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        const localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
      }
	});


	this.store.select(fromStore.getMyDealsLabels)
	.subscribe(labels => {
		this.myDealsLables = labels;
		
	});


    this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
			if (Object.keys(this.apiDetails).length > 0 && this.userId) {
				this.store.dispatch(new fromStore.GetMyOrderHistoryId({
				  url: this.apiDetails.user.base_url +
					this.apiDetails.user.myOrderHistory.url,
				  method: this.apiDetails.user.myOrderHistory.method,
				  body:{
							userId : this.userId
						}
				}));
			}
		});

    // server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
    .subscribe((serverErrorMessage) => {
      if (serverErrorMessage != null) {
        if (serverErrorMessage === 'InvalidCredentials') {
          this.router.navigate(['/Login/login']);
        } else {
          this.serverMessage = serverErrorMessage;
          this.isServerErrorMessage = true;
          console.error(this.serverMessage);
        }
      }
	});

	this.store.select(fromStore.isGetMyOrderHistoryByIdLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});



	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isGetMyOrderHistoryByIdLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.getMyOrderHistoryByIdStatusCode)
					.subscribe(code => {
						if(code) {
							if (code === 200) {
								this.store.select(fromStore.getMyOrderHistoryByIdResult)
								.subscribe(result => {
									this.myDeal = result;
                
								});
							} else if (code === 500) {

								this.store.select(fromStore.getErrorMessages)
	.subscribe((errorMessage) => {
		this.dataNotFoundMsg = "no data";
		this.errorMessages = errorMessage;
	});

							} else {
								this.store.select(fromStore.getErrorMessages)
								.subscribe(errorMessages => {
									console.error(errorMessages[this.selectedLanguage].code);
								});
							}
						}
					});
				}
			});
		}
	});

  }

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }

  returnMoreService(){
	this.router.navigate(['/cep/offers']);
  }
  redirectToDetailScreenOfDeal(item){
	this.googleAnalyticsService.eventEmitter(screenName.MY_DEALS, {
		log: clickedOn.DEALS_DETAILS,
		screenName: screenName.MY_DEALS + "_SCREEN",
		SECTION: screenName.MY_DEALS ,
		clickedOn: clickedOn.ADD_DEALS,
		title: clickedOn.ADD_DEALS,
		time: new Date(),
		isInput: false,
			  })
	  this.router.navigate(['/cep/my-deals/'+item.cartId+ '/'+item.scanValue]);
  }
  	/**
	 * Angualr method which runs after view initialization
	 */
	ngAfterViewInit() {
		window.scroll(0, 0);
	}

	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
				screenName.MY_DEALS + "_SCREEN",
				{
				  log: clickedOn.LOG_SCREEN,
				  screenName: screenName.MY_DEALS + "_UNMOUNT",
				  screenStartTime: "",
				  isScroll: false,
				  screenEndTime: new Date(),
				  scrollValue: "",
				}
			  );
}
}