import * as fromAroundMeOnMap from '../action/around-me.action';

export interface AroundMeOnMapState {
  serverErrorMessage: string;
  isLoadingAroundMe: boolean;
  isLoadedAroundMe: boolean;
  isloadingaroundMeCategory: boolean;
  isloadedaroundMeCategory: boolean;
  aroundMeData: [];
  aroudMeCategoryList:[];
  aroundMeCategoryErrorMessage: string;
  aroundMeErrorMessage: string;
  nearMeStatusCode: number;
  statusCode: number;
  cfcStatusCode:number;
}

export const initialState: AroundMeOnMapState = {
  isLoadingAroundMe: false,
  isLoadedAroundMe: false,
  isloadingaroundMeCategory: false,
  isloadedaroundMeCategory: false,
  aroudMeCategoryList:[],
  aroundMeData: [],
  aroundMeErrorMessage: null,
  aroundMeCategoryErrorMessage: null,
  nearMeStatusCode: 0,
  statusCode: 0,
  cfcStatusCode:0,
  serverErrorMessage: null
}

export function reducer(
  state = initialState,
  action: fromAroundMeOnMap.aroundMeAction
):
  AroundMeOnMapState {
  switch (action.type) {
    case fromAroundMeOnMap.GET_AROUND_ME: {
      return {
        ...state,
        isLoadingAroundMe: true,
        isLoadedAroundMe: false,
        aroundMeErrorMessage: null,
      };
    }
    case fromAroundMeOnMap.GET_AROUND_ME_SUCCESS: {
      let cfcStatusCode = action.payload.code;
      let isLoadingAroundMe = state.isLoadingAroundMe;
      let isLoadedAroundMe = state.isLoadedAroundMe;
      let aroundMeErrorMessage = state.aroundMeErrorMessage;
      let aroundMeData = state.aroundMeData;
      isLoadingAroundMe = false;
      isLoadedAroundMe = true;
      aroundMeErrorMessage = action.payload.message;     
      switch (cfcStatusCode) {
        case 200:
          aroundMeData = action.payload.result;
          isLoadingAroundMe = false;
          isLoadedAroundMe = true;
          cfcStatusCode = 200;
          break;
        case 603:
          break;
        case 608:
          break;
        case 604:
          isLoadingAroundMe = false;
          isLoadedAroundMe = true;
          aroundMeErrorMessage = action.payload.message;
          break;
        case 500:
          aroundMeData = action.payload.result;
          isLoadingAroundMe = false;
          isLoadedAroundMe = true;
          aroundMeErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        isLoadingAroundMe,
        isLoadedAroundMe,
        aroundMeData,
        aroundMeErrorMessage,
        cfcStatusCode
      };
    }
    case fromAroundMeOnMap.GET_AROUND_ME_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isLoadingAroundMe: false,
        isLoadedAroundMe: true,
      };
    }


    case fromAroundMeOnMap.GET_AROUND_ME_CATEGORY: {
      return {
        ...state,
        isloadingaroundMeCategory: true,
        isloadedaroundMeCategory: false,
        aroundMeCategoryErrorMessage: null,
      };
    }
    case fromAroundMeOnMap.GET_AROUND_ME_CATEGORY_SUCCESS: {
      let cfcStatusCode = action.payload.code;
      let isloadingaroundMeCategory = state.isloadingaroundMeCategory;
      let isloadedaroundMeCategory = state.isloadedaroundMeCategory;
      let aroundMeCategoryErrorMessage = state.aroundMeCategoryErrorMessage;
      let aroudMeCategoryList = state.aroudMeCategoryList;
      aroundMeCategoryErrorMessage = action.payload.aroundMeCategoryErrorMessage;
      switch (cfcStatusCode) {
        case 200:
          aroudMeCategoryList = action.payload.result;
          isloadingaroundMeCategory = false;
          isloadedaroundMeCategory = true;

          break;
        case 603:
          case 604:
            case 608:
          isloadingaroundMeCategory = false;
          isloadedaroundMeCategory = true;
          aroundMeCategoryErrorMessage = action.payload.message;
          break;

      }
      return {
        ...state,
        isloadingaroundMeCategory,
        isloadedaroundMeCategory,
        aroundMeCategoryErrorMessage,
        aroudMeCategoryList
      };
    }
    case fromAroundMeOnMap.GET_AROUND_ME_CATEGORY_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isloadingaroundMeCategory: false,
        isloadedaroundMeCategory: true,
      };
    }
  }
  return state;
}

export const getAroundMeLoading = (state: AroundMeOnMapState) => state.isLoadingAroundMe;
export const getAroundMeLoaded = (state: AroundMeOnMapState) => state.isLoadedAroundMe;
export const getAroundMeLocations = (state: AroundMeOnMapState) => state.aroundMeData;
export const getAroundMeErrorMessage = (state: AroundMeOnMapState) => state.aroundMeErrorMessage;

export const getAroundMeCategoryLoading = (state: AroundMeOnMapState) => state.isloadingaroundMeCategory;
export const getAroundMeCategoryLoaded = (state: AroundMeOnMapState) => state.isloadedaroundMeCategory;
export const getAroundMeCategoryList = (state: AroundMeOnMapState) => state.aroudMeCategoryList;
export const getAroundMeCategoryErrorMessage = (state: AroundMeOnMapState) => state.aroundMeCategoryErrorMessage;