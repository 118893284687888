import * as fromNearMeOnMap from '../action/near-me-map.actions';
import { state } from '@angular/animations';


export interface NearMeOnMapState {
  serverErrorMessage: string;
  isBicycleLoading: boolean;
  isBicycleLoaded: boolean;
  isLoadingCfcData: boolean;
  isLoadedCfcData: boolean;
  isloadingNearMeOnMap: boolean;
  isloadedNearMeOnMap: boolean;
  isBikeLoaded: boolean;
  isBikeLoading: boolean;
  cfcCentres: [];
  nearMeONMap: [];
  nearMeErrorMessage: string;
  cfcErrorMessage: string;
  getBicycleOnMap: [];
  getBikeOnMap: [];
  nearMeStatusCode: number;
  cfcStatusCode: number;
  statusCode: number;
  bicycleErrorMessage: string;
  bicycleStatusCode: number;
  bikeStatusCode: number;
  getBikeErrorMessage: string;
}

export const initialState: NearMeOnMapState = {
  isLoadingCfcData: false,
  isLoadedCfcData: false,
  isloadingNearMeOnMap: false,
  isloadedNearMeOnMap: false,
  isBicycleLoading: false,
  isBicycleLoaded: false,
  isBikeLoaded: false,
  isBikeLoading: false,
  cfcCentres: [],
  nearMeONMap: [],
  nearMeErrorMessage: null,
  cfcErrorMessage: null,
  getBicycleOnMap: [],
  getBikeOnMap: [],
  nearMeStatusCode: 0,
  cfcStatusCode: 0,
  statusCode: 0,
  bicycleErrorMessage: null,
  bicycleStatusCode: 0,
  bikeStatusCode: 0,
  getBikeErrorMessage: null,
  serverErrorMessage: null

}
export function reducer(
  state = initialState,
  action: fromNearMeOnMap.nearMeOMMapAction
):
  NearMeOnMapState {
  switch (action.type) {
    case fromNearMeOnMap.GET_CFC_CENTRES: {
      return {
        ...state,
        isLoadingCfcData: true,
        isLoadedCfcData: false,
        cfcErrorMessage: null,
      };
    }
    case fromNearMeOnMap.GET_CFC_CENTRES_SUCCESS: {
      let cfcCentres = action.payload.result;
      let cfcStatusCode = action.payload.code;
      let isLoadingCfcData = state.isLoadingCfcData;
      let isLoadedCfcData = state.isLoadedCfcData;
      let cfcErrorMessage = state.cfcErrorMessage;
      switch (cfcStatusCode) {
        case 200:
          cfcCentres = action.payload.result;
          isLoadingCfcData = false;
          isLoadedCfcData = true;
          cfcStatusCode = 200;

          break;
        case 603:
          isLoadingCfcData = false;
          isLoadedCfcData = true;
          cfcErrorMessage = action.payload.message;
          cfcStatusCode = 603;
          break;
        case 604:
          isLoadingCfcData = false;
          isLoadedCfcData = true;
          cfcErrorMessage = action.payload.message;
          cfcStatusCode = 604;
          break;
        case 608:
          isLoadingCfcData = false;
          isLoadedCfcData = true;
          cfcErrorMessage = action.payload.message;
          cfcStatusCode = 608;
          break;
      }
      return {
        ...state,
        isLoadingCfcData,
        isLoadedCfcData,
        cfcCentres,
        cfcErrorMessage,
        cfcStatusCode
      };
    }
    case fromNearMeOnMap.GET_CFC_CENTRES_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isLoadingCfcData: false,
        isLoadedCfcData: true,
      };
    }
    case fromNearMeOnMap.GET_NEAR_ME_ON_MAP: {
      return {
        ...state,
        isloadingNearMeOnMap: true,
        isloadedNearMeOnMap: false,
        nearMeErrorMessage: null,
      };
    }
    case fromNearMeOnMap.GET_NEAR_ME_ON_MAP_SUCCESS:
      {
        let nearMeONMap = action.payload.result;
        let nearMeStatusCode = action.payload.code;
        let nearMeErrorMessage = state.nearMeErrorMessage;
        let isloadingNearMeOnMap = state.isloadingNearMeOnMap;
        let isloadedNearMeOnMap = state.isloadedNearMeOnMap;
        switch (nearMeStatusCode) {
          case 200:
            nearMeONMap = action.payload.result;
            isloadingNearMeOnMap = false;
            isloadedNearMeOnMap = true;
            break;
          case 603:
            isloadingNearMeOnMap = false;
            isloadedNearMeOnMap = true;
            nearMeErrorMessage = action.payload.message;
            nearMeStatusCode = 603;
            break;
          case 604:
            isloadingNearMeOnMap = false;
            isloadedNearMeOnMap = true;
            nearMeErrorMessage = action.payload.message;
            nearMeStatusCode = 604;
            break;
          case 608:
            isloadingNearMeOnMap = false;
            isloadedNearMeOnMap = true;
            nearMeErrorMessage = action.payload.message;
            nearMeStatusCode = 608;
            nearMeONMap = null;
            break;
        }
        return {
          ...state,
          isloadingNearMeOnMap,
          isloadedNearMeOnMap,
          nearMeONMap,
          nearMeErrorMessage,
          nearMeStatusCode

        };
      }
    case fromNearMeOnMap.GET_NEAR_ME_ON_MAP_FAIL: {
      let nearMeStatusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (nearMeStatusCode == 401 || nearMeStatusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (nearMeStatusCode != 401 && nearMeStatusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isloadingNearMeOnMap: false,
        isloadedNearMeOnMap: true
      };
    }
    case fromNearMeOnMap.GET_BICYCLE_ON_MAP:
      {
        return {
          ...state,
          isBicycleLoading: true,
          isBicycleLoaded: false
        };
      }

    case fromNearMeOnMap.GET_BICYCLE_ON_MAP_SUCEESS: {
      let getBicycleOnMap = state.getBicycleOnMap;
      let bicycleStatusCode = action.payload.code;
      let isBicycleLoading = state.isBicycleLoading;
      let isBicycleLoaded = state.isBicycleLoaded;
      let bicycleErrorMessage = state.bicycleErrorMessage;
      switch (bicycleStatusCode) {
        case 200:
          getBicycleOnMap = action.payload.result;
          isBicycleLoading = false;
          isBicycleLoaded = true;
          break;
        case 608:
          isBicycleLoading = false;
          isBicycleLoaded = true;
          bicycleErrorMessage = action.payload.message;
          bicycleStatusCode = 608;
      }
      return {
        ...state,
        getBicycleOnMap,
        isBicycleLoading,
        isBicycleLoaded,
        bicycleErrorMessage,
        bicycleStatusCode
      };
    }
    case fromNearMeOnMap.GET_BICYCLE_ON_MAP_FAIL: {
      let bicycleStatusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (bicycleStatusCode == 401 || bicycleStatusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (bicycleStatusCode != 401 && bicycleStatusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isBicycleLoading: false,
        isBicycleLoaded: true
      };
    }
    case fromNearMeOnMap.GET_BIKE_ON_MAP: {
      return {
        ...state,
        isBikeLoading: true,
        isBikeLoaded: false
      };
    }

    case fromNearMeOnMap.GET_BIKE_ON_MAP_SUCCESS: {
      let getBikeOnMap = state.getBikeOnMap;
      let bikeStatusCode = action.payload.code;
      let isBikeLoading = state.isBikeLoading;
      let isBikeLoaded = state.isBikeLoaded;
      let getBikeErrorMessage = state.getBikeErrorMessage
      switch (bikeStatusCode) {
        case 200:
          getBikeOnMap = action.payload.result
          isBikeLoading = false;
          isBikeLoaded = true;
          break;

        case 608:
          bikeStatusCode = action.payload.code;
          isBikeLoading = false;
          isBikeLoaded = true;
          getBikeErrorMessage = action.payload.message
      }

      return {
        ...state,
        getBikeOnMap,
        isBikeLoading,
        isBikeLoaded,
        bikeStatusCode,
        getBikeErrorMessage
      };
    }

    case fromNearMeOnMap.GET_BIKE_ON_MAP_FAIL: {
      let bikeStatusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage
      if (bikeStatusCode == 401 || bikeStatusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (bikeStatusCode != 401 && bikeStatusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isBikeLoading: false,
        isBikeLoaded: true
      }
    }

    case fromNearMeOnMap.RESET_SERVER_ERROR_MESSAGE: {
      return {
        ...state,
        serverErrorMessage: null,
      }
    }
  }
  return state;
}

export const cfcCentres = (state: NearMeOnMapState) => state.cfcCentres;
export const cfcCentresLoading = (state: NearMeOnMapState) => state.isLoadingCfcData;
export const cfcCentresLoaded = (state: NearMeOnMapState) => state.isLoadedCfcData;
export const nearMeONMap = (state: NearMeOnMapState) => state.nearMeONMap;
export const isloadingNearMeOnMap = (state: NearMeOnMapState) => state.isloadingNearMeOnMap;
export const isloadedNearMeOnMap = (state: NearMeOnMapState) => state.isloadedNearMeOnMap;
export const cfcErrorMessage = (state: NearMeOnMapState) => state.cfcErrorMessage;
export const nearMeErrorMessage = (state: NearMeOnMapState) => state.nearMeErrorMessage;
export const getBicycleOnMap = (state: NearMeOnMapState) => state.getBicycleOnMap;
export const isBicycleLoading = (state: NearMeOnMapState) => state.isBicycleLoading;
export const getIsBicycleLoaded = (state: NearMeOnMapState) => state.isBicycleLoaded;
export const getEbikeOnMap = (state: NearMeOnMapState) => state.getBikeOnMap;
export const getIsBikeLoading = (state: NearMeOnMapState) => state.isBikeLoading;
export const getIsBikeLoaded = (state: NearMeOnMapState) => state.isBikeLoaded;
export const getNearMeStatusCode = (state: NearMeOnMapState) => state.nearMeStatusCode;
export const getcfcStatusCode = (state: NearMeOnMapState) => state.cfcStatusCode;
export const getBicycleStatusCode = (state: NearMeOnMapState) => state.bicycleStatusCode;
export const getBicycleErrorMessage = (state: NearMeOnMapState) => state.bicycleErrorMessage;
export const getBikeErrorMessage = (state: NearMeOnMapState) => state.getBikeErrorMessage;
export const getBikeStatusCode = (state: NearMeOnMapState) => state.bikeStatusCode;
export const getServerErrorMessage = (state: NearMeOnMapState) => state.serverErrorMessage