import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-form',
  templateUrl: './download-form.component.html',
  styleUrls: ['./download-form.component.scss']
})
export class DownloadFormComponent implements OnInit {
  familyPreview = 'https://sgy.pmc.gov.in/public/citizen/family_photo/'
  cardDetails: any;
  expiryDate: any;
  registerDate: any;
  cardUsageHistory: any[]; 

  constructor(
    private aRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private apiCallService: ApiCallService,
  ) { }

  ngOnInit() {
    this.aRoute.queryParams.subscribe((params: any) => {     
      if (params.card) {
        this.cardDetails = JSON.parse(params.card);
        // console.log("Card Details:", this.cardDetails);
        this.expiryDate = this.datePipe.transform(this.cardDetails.expiry_date, 'dd MMM, yyyy');
        this.registerDate = this.datePipe.transform(this.cardDetails.registeredDate, 'dd MMM, yyyy');
        this.cardUsageHistory = this.cardDetails.cardUsageHistory.card_usage;  
        // console.log("dfgh",this.cardUsageHistory)
        window.print();
      }
    }); 
  } 

}
