import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromWebinar from '../../store/reducer/webinar.reducer';

export const getWebinarState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.webinar
);

export const getWebinarListResult = createSelector(
	getWebinarState,
	fromWebinar.getWebinarListResult
);

export const webinarListStatusCode = createSelector(
	getWebinarState,
	fromWebinar.webinarListStatusCode
);

export const getIsWebinarListLoading = createSelector(
	getWebinarState,
	fromWebinar.getIsWebinarListLoading
);
export const getIsWebinarListLoaded = createSelector(
	getWebinarState,
	fromWebinar.getIsWebinarListLoaded
);

export const getWebinarByIdResult = createSelector(
	getWebinarState,
	fromWebinar.getWebinarByIdResult
);

export const getIsWebinarByIdLoading = createSelector(
	getWebinarState,
	fromWebinar.getIsWebinarByIdLoading
);
export const getIsWebinarByIdLoaded = createSelector(
	getWebinarState,
	fromWebinar.getIsWebinarByIdLoaded
);

export const getwebinarServerErrorMessage = createSelector(
	getWebinarState,
	fromWebinar.getwebinarServerErrorMessage
);
export const getWebinarPaginationCount = createSelector(
	getWebinarState,
	fromWebinar.getWebinarPaginationCount
);

 
export const AddOrRemoveWebinarCommentResult = createSelector(
	getWebinarState,
	fromWebinar.addOrRemoveWebinarCommentResult
);
export const AddOrRemoveWebinarCommentStatusCode = createSelector(
	getWebinarState,
	fromWebinar.addOrRemoveWebinarCommentStatusCode
);
export const AddOrRemoveWebinarCommentErrorMessage = createSelector(
	getWebinarState,
	fromWebinar.addOrRemoveWebinarCommentErrorMessage
);
export const isAddOrRemoveWebinarCommentLoading = createSelector(
	getWebinarState,
	fromWebinar.isAddOrRemoveWebinarCommentLoading
);
export const isAddOrRemoveWebinarCommentLoaded = createSelector(
	getWebinarState,
	fromWebinar.isAddOrRemoveWebinarCommentLoaded
);



export const getAllWebinarCommentResult = createSelector(
	getWebinarState,
	fromWebinar.getAllWebinarCommentResult
);
export const getAllWebinarCommentStatusCode = createSelector(
	getWebinarState,
	fromWebinar.getAllWebinarCommentStatusCode
);
export const getAllWebinarCommentErrorMessage = createSelector(
	getWebinarState,
	fromWebinar.getAllWebinarCommentErrorMessage
);
export const isGetAllWebinarCommentLoading = createSelector(
	getWebinarState,
	fromWebinar.isGetAllWebinarCommentLoading
);
export const isGetAllWebinarCommentLoaded = createSelector(
	getWebinarState,
	fromWebinar.isGetAllWebinarCommentLoaded
);


export const ReportAbuseWebinarCommentResult = createSelector(
	getWebinarState,
	fromWebinar.ReportAbuseWebinarCommentResult
);
export const ReportAbuseWebinarCommentStatusCode = createSelector(
	getWebinarState,
	fromWebinar.ReportAbuseWebinarCommentStatusCode
);
export const ReportAbuseWebinarCommentErrorMessage = createSelector(
	getWebinarState,
	fromWebinar.ReportAbuseWebinarCommentErrorMessage
);
export const isReportAbuseWebinarCommentLoading = createSelector(
	getWebinarState,
	fromWebinar.isReportAbuseWebinarCommentLoading
);
export const isReportAbuseWebinarCommentLoaded = createSelector(
	getWebinarState,
	fromWebinar.isReportAbuseWebinarCommentLoaded
);

