// import { Component, Input } from '@angular/core';
// import { FormGroup, FormControl } from '@angular/forms';
// import { ValidationService } from 'src/app/shared/validations/user-details.service';

/**
 * constatnts used through out the website
 */
export const AppConstants = {
	FEEDS_BACKGROUND: '#2F7CCE',
	FEEDS_DARK_BACKGROUND: '#1D4A7A',
	NEAR_ME_BACKGROUND: '#FF6D22',
	NEAR_ME_DARK_BACKGROUND: '#A43800',
	SERVICES_BACKGROUND: '#2A3B59',
	SERVICES_DARK_BACKGROUND: '#354791',
	CITY_INFO_BACKGROUND: '#1F8F55',
	CITY_INFO_DARK_BACKGROUND: '#14653B',
	facebookLink: 'https://www.facebook.com/PCMCSmartSarathi',
	instagramLink: 'https://www.instagram.com/pcmcsmartsarathi/',
	twitterLink: 'https://twitter.com/PCMCSarathi'
};

