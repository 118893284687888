/**
 * Google analytics messages
 */
export const screenName = {
  CHANGE_PASSWORD:'CHANGE_PASSWORD',
  OFFERS_SCREEN: "OFFERS_SCREEN",
  ADD_BLOG:"ADD_BLOG",
  STATIC_CONTENT: "STATIC_CONTENT",
  GRIEVANCE_LISTING: "GRIEVANCE_LISTING",
  GRIEVANCE_DETAILS: "GRIEVANCE_DETAILS",
  ADD_GRIEVANCE: "ADD_GRIEVANCE",
  DEATH_CERTIFICATE: "DEATH_CERTIFICATE",
  DEATH_CERTIFICATE_DETAIL: "DEATH_CERTIFICATE_DETAIL",
  BIRTH_CERTIFICATE: "BIRTH_CERTIFICATE",
  BIRTH_CERTIFICATE_DETAIL: "BIRTH_CERTIFICATE_DETAIL",
  PROPERTY_TAX: "PROPERTY_TAX",
  ADD_PROPERTY_TAX: "ADD_PROPERTY_TAX",
  PROPERTY_TAX_DETAILS: "PROPERTY_TAX_DETAILS",
  HAWKER_TAX: "HAWKER_TAX",
  ADD_HAWKER_TAX: "ADD_HAWKER_TAX",
  HAWKER_TAX_DETAIL: "HAWKER_TAX_DETAIL",
  FEEDS: "FEEDS",
  NEAR_ME: "NEAR_ME",
  PASSWORD: "PASSWORD",
  FORGOT_PASSWORD:"FORGOT_PASSWORD",
  OFFERS: "OFFERS",
  PMC_SERVICES: "PMC_SERVICES",
  PROFILE: "PROFILE",
  SELECT_LANG: "SELECT_LANG",
  ONBOARDING: "ONBOARDING",
  SPLASHSCREEN: "SPLASHSCREEN",
  REGISTRATION2: "REGISTRATION2",
  PREFERENCES: "PREFERENCES",
  MY_DEALS: "MY_DEALS",
  FAVOURITE: "FAVOURITE",

  ENTER_MOBILE_NUMBER: "ENTER_MOBILE_NUMBER",
  REPORT_ABUSE_BOX: "REPORT_ABUSE_BOX",

  SIDEBAR: "SIDEBAR",
  SIGNUP_SIGNIN: "SIGNUP_SIGNIN",
  REGISTRATION: "REGISTRATION",
  SETPASSWORD: "SETPASSWORD",
  REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
  ARTICLE:"ARTICAL",
  MY_BLOG: "MY_BLOG",
  BLOG_DETAILS: "BLOG_DETAILS",
  NEWS_DETAILS:"NEWS_DETAILS",
  CONTACT_US: "CONTACT_US",
  ABOUT_US: "ABOUT_US",
  TERM_AND_CONDITION: "TERM_AND_CONDITION",
  MY_FORUM: "MY_FORUM",
  ADD_FORUM: "ADD_FORUM",
  FORUM_DETAILS: "FORUM_DETAILS",
  WEBINAR: "WEBINAR",
  WEBINAR_DETAILS: "WEBINAR_DETAILS",
  EVENT_DETAILS: "EVENT_DETAILS",
  ABOUT: "ABOUT",
  ABOUT_COMMISSIONER_INFO: "ABOUT_COMMISSIONER_INFO",
  ABOUT_CITY_INFO: "ABOUT_CITY_INFO",
  ABOUT_TOURISM_INFO: "ABOUT_TOURISM_INFO",
  ABOUT_TOURISM_DETAILS: "ABOUT_TOURISM_DETAILS",
  WATER_TAX: "WATER_TAX",
  ADD_WATER_TAX: "ADD_WATER_TAX",
  WATER_TAX_DETAILS: "WATER_TAX_DETAILS",
  HUMBURGER: "HUMBURGER",
  NOTIFICATION: "NOTIFICATION",
  NEARMECOMPONENTS: "NEARMECOMPONENTS",
  SEARCHRESULT: "SEARCHRESULT",

  ARTICAL_DETAILS: "ARTICAL_DETAILS",
  SURVEY_DETAILS: "SURVEY_DETAILS",
  COMPETITION_DETAILS:"COMPETITION_DETAILS",
  HOME_PAGE: "HOME_PAGE",
  HEADER_COMPONENT: "HEADER_COMPONENT",
  FOOTER_COMPONENT: "FOOTER_COMPONENT",
  MY_GROUP: "MY_GROUP",
  OTP: "OTP",
  RELATED_ARTICLE: "RELATED_ARTICLE",
  LOGIN_OPTION:'LOGIN_OPTION',
  DEAL_LISTING:'DEAL_LISTING',
  DEAL_DEATILS:'DEAL_DEATILS',
  SHOP_BY_CATEGORY:'SHOP_BY_CATEGORY',
  DEALS_PAGE:'DEALS_PAGE',
  MY_CART:'MY_CART',
  ADD_DEALS:'ADD_DEALS',
  MERCHANT_REGISTRATION:'MERCHANT_REGISTRATION',
  TRENDING_DEALS:'TRENDING_DEALS',
  NEAR_BY_DEALS:'NEAR_BY_DEALS',
  ALL_DEALS:'ALL_DEALS',
  AVAIL_DEALS_POPUP:'AVAIL_DEALS_POPUP',
  ARTICLE_CARD:'ARTICLE_CARD',
  NEWS_CARD:"NEWS_CARD",
  BLOG_CARD:"BLOG_CARD",
  EVENT_CARD:"EVENT_CARD",
  COMPETITION_CARD:"COMPETITION_CARD",
  FORUM_CARD:"FORUM_CARD",
  SURVEY_CARD:"SURVEY_CARD",
  ZOO_BOOKING:'ZOO_BOOKING',
  BANNER:'BANNER',
  ELECTION_POPUP:'ELECTION_POPUP'
};

export const clickedOn = {  
  CATEGORYNAME:"CATEGORYNAME",
  BLOG_DETAILS:"BLOG_DETAILS",
  ADD_BLOG:"ADD_BLOG",
  ADD_IMAGE:"ADD_IMAGE",
  CHAT_DETAILS:"CHAT_DETAILS",
  CREATE_MSG:"CREATE_MSG",
  OPEN_QRSCANPOPUP:"OPEN_QRSCANPOPUP",
  ADD_DOCUMENT:"ADD_DOCUMENT",
  ADD_DEALS:"ADD_DEALS",
  DEALS_DETAILS:"DEALS_DETAILS",
  ADD_FORUM:"ADD_FORUM",
  FORUM_DETAILS:"FORUM_DETAILS",
  OPEN_POPUP:"OPEN_POPUP",
  ADDFORUMCOMMENT:"ADDFORUMCOMMENT",
  REMOVEFORUMCOMMENT:"REMOVEFORUMCOMMENT",
  WEBINAR_COMMENT:"WEBINAR_COMMENT",
  ADDWEBINAR_COMMENT:"ADDWEBINAR_COMMENT",
  FILTER:"FILTER",
  FEEDS_LIST:"FEEDS_LIST",
  PAY_NOW_BTN: "PAY_NOW_BTN",
  CONTINUE: "CONTINUE",
  LOG_EVENT: "EVENT",
  LOG_SCREEN: "SCREEN",
  CARD: "CARD",
  REPORT_ABUSE:"REPORT_ABUSE",
  SHOW_MORE:"SHOW MORE", 
  LIKE: "LIKE",
  UNLIKE:"UNLIKE",
  SUBMIT_BTN: "SUBMIT_BTN",
  ADD_BTN: "ADD_BTN",
  BACK_BTN: " BACK_BTN",
  DELETE_BTN: "DELETE_BTN",
  UPDATE_BTN: "UPDATE_BTN",
  REMOVE_BTN: "REMOVE_BTN",
  CANCEL: "CANCEL",
  BUTTON: "BUTTON",
  OK: "OK",
  YES: "YES",
  NO: "NO",
  LIST: "LIST",
  REGISTER_BTN: "REGISTER_BTN",
  LOGIN_BTN: "LOGIN_BTN",
  VERIFY_OTP_BTN: "VERIFY_OTP_BTN",
  SEND_OTP_BTN: "SEND_OTP_BTN",
  RESEND_OTP_BTN: "RESEND_OTP_BTN",
  SIGN_IN_WITH_OTP: "SIGN_IN_WITH_OTP_BTN",
  CHANGE_LANG:'CHANGE_LANG',
  TAKE_ME_TO_HOME: "TAKE_ME_TO_HOME_BTN",
  EDIT_BTN: "EDIT_BTN",
  ICON: "ICON",
  VIEW: "VIEW",
  VIEW_CERTIFICATE: "VIEW_CERTIFICATE",
  FORGOT_PASSWORD_BTN: "FORGOT_PASSWORD_BTN",
  CITIZEN_REGISTRATION: "CITIZEN_REGISTRATION",
  CITIZEN_LOGIN: "CITIZEN_LOGIN",
  OFFICER_LOGIN: "OFFICER_LOGIN",
  ADD_TO_CART:'ADD_TO_CART',
  ADD_ITEM:'ADD_ITEM',
  REMOVE_ITEM:'REMOVE_ITEM',
  NEAR_BY_DEALS:'NEAR_BY_DEALS',
  ALL_DEALS:'ALL_DEALS',
  AVAIL_DEALS_POPUP:'AVAIL_DEALS_POPUP',
  SHOP_BY_CATEGORY:'SHOP_BY_CATEGORY',
  TRENDING_DEALS:'TRENDING_DEALS',
  VIEW_DEAL:'VIEW_DEAL',
  MERCHANT_DETAILS:'MERCHANT_DETAILS',
  SHARE: "SHARE",
  PROCEED:'PROCEED',
  GO_TO_PAYMENT:'GO_TO_PAYMENT',
  BANNER_CLICK:'BANNER_CLICK',
  ELECTION_POPUP_CARD : 'ELECTION_POUP_CARD',
  PAY_PARTIAL_BTN : 'PAY_PARTIAL_BTN'

};

// export const clickedOn = {
//     LOG_EVENT: 'EVENT',
//     LOG_SCREEN: "SCREEN",
//     CARD: "CARD",
//     SHARE: "SHARE",
//     LIKE: "LIKE",
//     SUBMIT_BTN: "SUBMIT_BTN",
//     ADD_BTN: "ADD_BTN",
//     BACK_BTN: " BACK_BTN",
//     DELETE_BTN: "DELETE_BTN",
//     CANCEL: "CANCEL",
//     OK:'OK',
//     YES:'YES',
//     NO:'NO',
//     LIST:'LIST',
//     REGISTER_BTN: "REGISTER_BTN",
//     LOGIN_BTN  : 'LOGIN_BTN',
//     VERIFY_OTP_BTN  : 'VERIFY_OTP_BTN',
//     SEND_OTP_BTN  : 'SEND_OTP_BTN',
//     RESEND_OTP_BTN  : 'RESEND_OTP_BTN',
//     SIGN_IN_WITH_OTP : "SIGN_IN_WITH_OTP_BTN",
//     CONTINUE: "CONTINUE_BTN",
//     TAKE_ME_TO_HOME : "TAKE_ME_TO_HOME_BTN"
// }

