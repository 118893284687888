import { Action } from '@ngrx/store';

export const STORE_SELECTED_DEPARTMENT = 'STORE_SELECTED_DEPARTMENT ';

export const GET_DEPARTMENT_TYPE_DETAILS = 'GET_DEPARTMENT_TYPE_DETAILS';
export const GET_DEPARTMENT_TYPE_DETAILS_SUCCESS = 'GET_DEPARTMENT_TYPE_DETAILS_SUCCESS';
export const GET_DEPARTMENT_TYPE_DETAILS_FAIL = 'GET_DEPARTMENT_TYPE_DETAILS_FAIL';

export const GET_DEPARTMENT_SUBTYPE_DETAILS = 'GET_DEPARTMENT_SUBTYPE_DETAILS';
export const GET_DEPARTMENT_SUBTYPE_DETAILS_SUCCESS = 'GET_DEPARTMENT_SUBTYPE_DETAILS_SUCCESS';
export const GET_DEPARTMENT_SUBTYPE_DETAILS_FAIL = 'GET_DEPARTMENT_SUBTYPE_DETAILS_FAIL';

export const GET_DEPARTMENT_FAQ_DETAILS = 'GET_DEPARTMENT_FAQ_DETAILS';
export const GET_DEPARTMENT_FAQ_DETAILS_SUCCESS = 'GET_DEPARTMENT_FAQ_DETAILS_SUCCESS';
export const GET_DEPARTMENT_FAQ_DETAILS_FAIL = 'GET_DEPARTMENT_FAQ_DETAILS_FAIL';

export class StoreSelectedDepartment implements Action {
	readonly type = STORE_SELECTED_DEPARTMENT;
	constructor(public payload: any) { }
}

export class GetDepartmentTypeDetails implements Action {
	readonly type = GET_DEPARTMENT_TYPE_DETAILS;
	constructor(public payload: any) { }
}

export class GetDepartmentTypeDetailsSuccess implements Action {
	readonly type = GET_DEPARTMENT_TYPE_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}

export class GetDepartmentTypeDetailsFail implements Action {
	readonly type = GET_DEPARTMENT_TYPE_DETAILS_FAIL;
	constructor(public payload: any) { }
}


export class GetDepartmentSubTypeDetails implements Action {
	readonly type = GET_DEPARTMENT_SUBTYPE_DETAILS;
	constructor(public payload: any) { }
}

export class GetDepartmentSubTypeDetailsSuccess implements Action {
	readonly type = GET_DEPARTMENT_SUBTYPE_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}

export class GetDepartmentSubTypeDetailsFail implements Action {
	readonly type = GET_DEPARTMENT_SUBTYPE_DETAILS_FAIL;
	constructor(public payload: any) { }
}


export class GetDepartmentFAQDetails implements Action {
	readonly type = GET_DEPARTMENT_FAQ_DETAILS;
	constructor(public payload: any) { }
}

export class GetDepartmentFAQDetailsSuccess implements Action {
	readonly type = GET_DEPARTMENT_FAQ_DETAILS_SUCCESS;
	constructor(public payload: any) { }
}

export class GetDepartmentFAQDetailsFail implements Action {
	readonly type = GET_DEPARTMENT_FAQ_DETAILS_FAIL;
	constructor(public payload: any) { }
}

export type departmentsAction = GetDepartmentTypeDetails | GetDepartmentTypeDetailsSuccess | GetDepartmentTypeDetailsFail
	| GetDepartmentSubTypeDetails | GetDepartmentSubTypeDetailsSuccess | GetDepartmentSubTypeDetailsFail
	| GetDepartmentFAQDetails | GetDepartmentFAQDetailsSuccess | GetDepartmentFAQDetailsFail
	| StoreSelectedDepartment;
