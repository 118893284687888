import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  constructor(
    private http: HttpClient,
    private apiCallService: ApiCallService
  ) { }

  callApiRequestList() {
    let method = 'get';
    let url = environment.base_url + '/applicationConfig/v1/appConfiguration/getApidetails';
    // let url = 'https://api.pmccare.in/applicationConfig/v1/appConfiguration/getApidetails'
    return this.apiCallService.apiCall(method, url, null);
  }
  getConstantsLabels(apiListing) {
    let url = apiListing.api_details.applicationConfigurations.base_url +
      apiListing.api_details.applicationConfigurations.labels.getLabels.url;
    let method = apiListing.api_details.applicationConfigurations.labels.getLabels.method;
    return this.apiCallService.apiCall(method, url, null);
  }
  getLabelEnableDetails(request) {
    let method = request.method;
    let url = request.url;
    return this.apiCallService.apiCall(method, url, null);
  }

  getHotFeedback(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getChatbotKey(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getWeatherStatus(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }
}
