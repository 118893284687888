import { Component, OnInit, OnChanges, Input, AfterContentChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { DomSanitizer } from '@angular/platform-browser';
import { Images } from '../../config/images-config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
@Component({
	selector: 'app-details-page-survey',
	templateUrl: './details-page-survey.component.html',
	styleUrls: ['./details-page-survey.component.scss']
})

export class DetailsPageSurveyComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	image = Images;
	apiDetails: {};
	timelineId: any;
	isLoaded: boolean;
	feedsData: {};
	selectedLanguage: string;
	surveylink: any;
	feedLabels: {};
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	feedStatusCode: number;
	source: any;
	shareUrl: any;
	lng: number;
	allow = false;
	lat: number;
	isCorona = null;
	userData: {};
	mobile: any;
	name: any;
	loading = true;sda

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private activeParams: ActivatedRoute,
		private store: Store<fromStore.CEPState>,
		public sanitizer: DomSanitizer,
		private deviceService: DeviceDetectorService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private router: Router) { }

		/**
		 * Created by Akanksha J
		 * for getting user current location
		 */
		getUserLocation() {
			if (navigator) {
				navigator.geolocation.getCurrentPosition(pos => {
					this.lng = +pos.coords.longitude;
					this.lat = +pos.coords.latitude;
					if (this.lat !== undefined) {
						this.allow = true;
						this.loading = false;
					}
				},
					error => {
						this.loading = true;
						let confWindow = window.confirm('Please allow location permission to fill this survey');
						if (confWindow) {
							this.lat = 0;
							this.lng = 0;
							this.allow = true;
							this.loading = false;
						}
						else {
							this.router.navigate(['/cep/feeds']);
						}
					});
			}
		}

	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(
			screenName. SURVEY_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.SURVEY_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.activeParams.params.subscribe((params) => {
			this.timelineId = params.timelineId;
			this.isCorona = params.var;
			if (this.isCorona === 'true') {
				this.getUserLocation();
			}
		});

		this.store.select(fromStore.getVerifyPasswordResponse)
		.subscribe(response => {
			this.userData = response;
			this.mobile = this.userData['mobile'];
			this.name = this.userData['firstName'] + ' ' + this.userData['middleName'] + ' ' + this.userData['lastName'];
		});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});

		this.store.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoaded => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {
					this.store.select(fromStore.feedDetailsResult)
						.subscribe((feedsData) => {
							if (Object.keys(feedsData).length > 0) {
								this.feedsData = feedsData;
								if (Object.keys(this.feedsData).length !== 0) {
									if (this.isCorona == null) {
										this.surveylink = this.sanitizer.bypassSecurityTrustResourceUrl(feedsData[this.selectedLanguage]['link']);
										this.loading = false;
									}
									else if (this.isCorona === 'true') {
										this.surveylink = this.sanitizer.bypassSecurityTrustResourceUrl(feedsData[this.selectedLanguage]['link'] + '?n=' + this.name + '&lat=' + this.lat + '&long=' + this.lng + '&mob=' + this.mobile);
									}
								}
							}
						});
				}
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
					this.store.dispatch(new fromStore.GetEngeagementDetails({
						url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].timeline.getEngagement.url,
						method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
						body: {
							timelineId: this.timelineId,
						}
					}));
				}
			});
	}

	speakText(textToSpeak){
		// get all voices available
		var voices = speechSynthesis.getVoices();
		// convert text message to utterance to make it speak.
		var message = new SpeechSynthesisUtterance(textToSpeak);
		
		// defining the voice Google हिन्दी
		message.voice = voices[9];
		message.lang = 'hi-IN'
		message.volume = 1;
		speechSynthesis.cancel();
		speechSynthesis.speak(message);
	}


	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	ngAfterContentChecked() {
	}

	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.SURVEY_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.SURVEY_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		this.allow = false;
		this.isCorona = null;
		this.loading = false;
	}
}
