import { Injectable } from '@angular/core';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FeedsService {

  constructor(private http: HttpClient,
    private apiCallService: ApiCallService) { }

  getFeedsListing(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getFeedsListingEmergency(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getFeedDetails(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }
  
  addOrRemoveBlogComment(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }


  getAllBlogComment(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getFeedsEmergency(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }
  addBlog(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  addForum(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  updateForum(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  removeForum(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getForumByUser(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  reportAbuse(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  reportAbuseComment(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }


  reportWebinarAbuse(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  reportWebinarAbuseComment(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }


  registrationCompetition(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  submitCompetition(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  userNotification(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getBlogListingByUserId(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getForumListingByUserId(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getBlogByBlogId(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  editBlog(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  getForumByForumId(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

  editForum(request) {
    let method = request.method;
    let url = request.url;
    let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }

}

