/******
 * Component -  event component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */
import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Images } from '../../config/images-config';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ObjectInterface } from '../../models/global-interface';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router } from '@angular/router';
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { ClipboardService } from '../../services/clipboard.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicLinksService } from '../../services/dynamic-links';
import { environment } from 'src/environments/environment';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
@Component({
	selector: 'app-card-event',
	templateUrl: './card-event.component.html',
	styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit, AfterContentChecked {
	@Input() eventData: any;
	image = Images;
	eventDate;
	selectedLanguage;
	landingLables;
	postDate: any;
	favorite: string;
	apiDetails: any;
	todaysDate: any;
	feedsDate: Date;
	dateDiff: number;
	userId: string;
	token: any;
	endDate: string;
	startDate: string;
	shareUrl: any;
	eventImage: any;
	isBlind;
	feedsLabel: ObjectInterface;
	favbool:boolean;
	constructor(
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private apiCallService: ApiCallService,
		private router: Router,
		private clipboardService: ClipboardService,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private googleAnalyticsService: GoogleAnalyticsService) { }

	ngOnInit() {

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					this.userId = this.encrDecr.get(this.localStorageService.getUserId());
				} else {
					this.userId = null;
				}
			});

		if (this.eventData) {
			if (this.eventData.fav.filter((item) => item === this.userId).length > 0) {
				this.favorite = 'favorite';
				this.favbool = true;
				//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
			} else {
				this.favorite = 'favorite_border';
				this.favbool = false;
			}
		}

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedsLabel = labels;
					});
			});


		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}



	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	onClick() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.EVENT_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.eventData.timelineId
		  })
		// let user = this.localStorageService.getItem("userId")
		// if (user === null) {
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// } else {
		let user = this.localStorageService.getItem("userId")
		if (user === null) {
			this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
			const dialogRef = this.dialog.open(LoginpopupComponent);
			// this.router.navigate(["/Login/login"])
		} else {
			this.router.navigate(['/cep/feeds/event/', this.eventData.timelineId])
		}
	}



	/**
	 * Created by Akanksha J
	 * for reporting event as abuse
	 */
	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.eventData.timelineId,
				engagementType: 'competition',
				engagementId: this.eventData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	/**
	 * Craeted by Ajay C
	 * For unmarking the favourites
	 */
	unMarkFavorite() {
		if (this.favbool) {
			this.eventData = Object.assign({}, this.eventData, { favourite: false });
			this.favorite = 'favorite_border';
			this.favbool = false;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.EVENT_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.eventData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.eventData = Object.assign({}, this.eventData, { favourite: true });
			this.favorite = 'favorite';
			this.favbool = true;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.EVENT_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.eventData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails.shareUrl;
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.eventData.engagementId,
						engagementType: 'event'
					}
				}));
			}
		}
	}

	/**
	  * Created by Vinita Sharma'
	  * For generating dynamic link to share and redirect to new page to share post
	  */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/event/" + this.eventData.timelineId
		var metaTag = {
			socialTitle: this.eventData.en.heading,
			socialImageLink: this.eventData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.startDate = new Date(this.eventData.startDate).toDateString();
		this.endDate = new Date(this.eventData.endDate).toDateString();
		this.postDate = new Date(this.eventData.updatedAt).toDateString();
	}
	// clickOnShare() {
	// 	//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of event', 'Click Event', 1);
	// }

	async clickOnShare() {
		// //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of blog', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.EVENT_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.eventData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/event/" + this.eventData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}

}
