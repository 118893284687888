import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { CepComponent } from './cep/cep.component';
import { NoApiResponseComponent } from './shared/error-components/no-api-response/no-api-response.component';
import { LoginComponent } from './login/login.component';
import { EditorComponent } from './shared/components/editor/editor.component';
import { OneClickRegistrationComponent } from './cep/login-management/one-click-registration/one-click-registration.component';
import { OcrVerifyOtpComponent } from './cep/login-management/ocr-verify-otp/ocr-verify-otp.component';
import { SuccessDialogComponent } from './cep/login-management/success-dialog/success-dialog.component';
import { DownloadFormComponent } from './cep/side-menu-bar/SGY/download-form/download-form.component';


const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'cep'  },
	{ path: 'cep', component: CepComponent, loadChildren:  './cep/cep.module#CepModule' },
	{ path: 'Login', component: LoginComponent, loadChildren: '../app/login/login.module#LoginModule'},
	{ path: '404/no-page-found', component: NoApiResponseComponent },
	{ path: 'admin-editor/:type', component: EditorComponent },
	{ path: 'one-click-registration',component:OneClickRegistrationComponent },
	{path:'ocr',component:OcrVerifyOtpComponent},
	{path:'success-dialog',component:SuccessDialogComponent},
	{ path:'sgy-download',component:DownloadFormComponent }
];  


@NgModule({
	declarations: [
		CepComponent,
		LoginComponent,
		EditorComponent,
		NoApiResponseComponent,
		// SearchBirthDateComponent,
		// BirthDateDetailsComponent 
		 
	],
	imports: [
		RouterModule.forRoot(routes,
			{ onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' }),
		SharedModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
