import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Images } from "../../config/images-config";

enum STATES {
  PENDING,
  DUE,
  PROCESSING,
  PAID,
}

export interface IHawker {
  hawkerId: String,
  userId: String,
  hawkerName: String,
  hawkerOwnerName: null,
  hawkerOutstandingAmount: String,
  hawkerFormNo: null,
  hawkerMobile: null,
  hawkerPhotoPath: null,
  hawkerSpotName: String,
  hawkerSpotNo: String,
  hawkerWard: String,
  hawkerZone: String,
  hawkerCreatedDate: String,
  isDeleted: false
}

@Component({
  selector: 'app-hawker-card',
  templateUrl: './hawker-card.component.html',
  styleUrls: ['./hawker-card.component.scss']
})
export class HawkerCardComponent implements OnInit {

  @Input() data: IHawker;
  @Input() labels;
  @Input() selectedLanguage;
  states: typeof STATES = STATES;
  state: STATES = STATES.PENDING;
  AppImages: typeof Images = Images;
  @Input() progress: any;

  @Output() viewAction = new EventEmitter();
  @Output() payAction = new EventEmitter();
  imageBase = "https://api.pmccare.in/image/v1/PMC%2Fuser%2FU54381623904889125%2FImage%2Fmoney-icon";
  images = this.AppImages.money;
  default = this.AppImages.money.pending

  constructor() {}



  ngOnInit() {

  }

  ngAfterViewInit() {}

  getCardState(asset: IHawker) {
    const due = Number(asset.hawkerOutstandingAmount);
    if (!due) return (this.state = STATES.PENDING);
    if (due > 1) return (this.state = STATES.DUE);
    if (due < 1) return (this.state = STATES.PAID);
    //TODO: add state for processing payment
  }

  getIconByState(asset: IHawker) {
    if (!asset) return this.images.pending;

    const state = this.getCardState(asset);

    if (state === this.states.DUE) return this.images.due;
    if (state === this.states.PAID) return this.images.paid;
    if (state === this.states.PENDING) return this.images.pending;
    if (state === this.states.PROCESSING) return this.images.processing;
  }

  view(asset: IHawker) {
    this.viewAction.emit("view");
  }

  pay(asset: IHawker) {
    this.payAction.emit("pay");
  }

}
