import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../../cep/store/index';;
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  selectedLanguage: string;
	apiDetails: any;
	images = Images;
	aboutUsLabels: any;
	serverErrorMessage: string;
	isLoaded = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	serverMessage: string;
	isServerMessage: boolean;
	loadingTC = false;
	termsAndConditionData: any;
	labels: any;

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		public dialog: MatDialog
	) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

			

		// this.store.dispatch(new fromStore.GetApiRequestList({}));
	}

	ngOnInit() {

		// server error handling
		this.store.select(fromStore.getServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverError;
						if (this.serverMessage) {
							this.isServerMessage = true;
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
			});

			this.store
			.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
			  this.apiDetails=apiDetails;
			  //console.log("api",apiDetails);
			  
			});

		this.store.select(fromStore.getFooterLabels)
			.subscribe((footerLabel) => {
				this.labels = footerLabel;
			});

		this.store.dispatch(new fromStore.TermsAndConditions({
			// url: 'https://api.pmccare.in/info/v1/TermsAndCondtionsCtrl/getTermsAndConditions',
			url : this.apiDetails.info.base_url + this.apiDetails.info.getTermsAndConditions.url,
			method: 'get',
			body: {}
		}));
		this.store.select(fromStore.isTermsAndConditionsLoading)
			.subscribe(isloaded => {
				if (isloaded == true) {
					this.loadingTC = isloaded;
				}
				else {
					this.loadingTC = false;
				}
			})
		this.store.select(fromStore.isTermsAndConditionsLoaded)
			.subscribe(loaded => {
				// this.loadingTC=false;
				if (loaded) {
					this.store.select(fromStore.termsAndConditionsStatusCode)
						.subscribe(code => {
							if (code === 200) {
								this.store.select(fromStore.termsAndConditions)
									.subscribe(response => {
										this.termsAndConditionData = response;
										//console.log(response)
									})
							}
						})
				}
			})
	}
	/**
* angular method which gets called on page view initialization
*/
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

}
