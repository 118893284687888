import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PopupQrScanComponent } from 'src/app/shared/components/popup-qr-scan/popup-qr-scan.component';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
  
  

		
	

    constructor( public router: Router,private deviceService: DeviceDetectorService, public dialog: MatDialog,private titleService: Title ) { }

  ngOnInit() {
		this.titleService.setTitle('PMC CARE: OCR Success Page');
  }
  goToHomePage(){
    this.router.navigate(['/cep/home']);
  }
  OpenQRScanPopup() {
			this.dialog.open(PopupQrScanComponent, {
				autoFocus: false
			});
	}

}
