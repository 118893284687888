/**
 * Created by: Ajay C
 * Updated by: Akanksha J
 * Component: Chatbot sevice
 * Updated date: 20/12/2019
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  url: any;
  body: {};
  headerContent: any;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private http: HttpClient
  ) { }
  chatbotApiCalling(userId, url) {
    this.url = url;
    this.body = {
      "userId": userId,
      "latitude": "1",
      "longitude": "1"
    };
    this.headerContent = {
      'Content-Type': 'application/json'
    }

    return this.http.post(this.url, this.body, this.headerContent);
  }
}
