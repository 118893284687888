import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromSearch from '../../store/reducer/search.reducer';

export const getSearchState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.search
);

export const getSearchResult = createSelector(
	getSearchState,
	fromSearch.getSearchResult
);
export const getSearchLoading = createSelector(
	getSearchState,
	fromSearch.getSearchLoading
);
export const getSearchLoaded = createSelector(
	getSearchState,
	fromSearch.getSearchLoaded
);
export const getErrorMessage = createSelector(
	getSearchState,
	fromSearch.getErrorMessage
);

export const getAutoSearchResult = createSelector(
	getSearchState,
	fromSearch.getAutoSearchResult
);
export const getAutoSearchLoading = createSelector(
	getSearchState,
	fromSearch.getAutoSearchLoading
);
export const getAutoSearchLoaded = createSelector(
	getSearchState,
	fromSearch.getAutoSearchLoaded
);
export const getAutoErrorMessage = createSelector(
	getSearchState,
	fromSearch.getAutoErrorMessage
);

export const getIsSearch = createSelector(
	getSearchState,
	fromSearch.getIsSearch
);
export const getSearchText = createSelector(
	getSearchState,
	fromSearch.getSearchText
);
export const getUserIdFromSearch = createSelector(
	getSearchState,
	fromSearch.getUserIdFromSearch
)