import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from '../../../login/forgot-password/forgot-password.component';
import { ToastrService } from 'ngx-toastr';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { format } from 'url';
import { Images } from 'src/app/shared/config/images-config';
import { DatePipe } from '@angular/common';
import { SelectPreferncesComponent } from '../select-prefernces/select-prefernces.component';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { LocalStorageService } from 'src/app/shared/services/localstorage.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, AfterContentChecked, OnDestroy, AfterViewInit {
	@ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
	// passwordForm: FormGroup;
	// spinner properties
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	valueTC = 20;
	// date logic
	minDate = new Date(1920, 10, 1);
	currentDate = new Date();
	newDay = this.currentDate.getDate();
	newMonth = this.currentDate.getMonth();
	newYear = this.currentDate.getFullYear() - 18;
	maxDate = new Date(this.newYear, this.newMonth, this.newDay);
	// ngModel variable
	firstName = null;
	middleName = null;
	lastName = null;
	fullname = null;
	genderLabel: string;
	DOB: string;
	address = '';
	addressOne = null;
	addressTwo: string;
	landmarkInput = null;
	area: any;
	zoneValue: string;
	city: string;
	state = null;
	country: string;
	pinCode: string;
	emailId = null;
	aadharCard = null;
	pass = "";
	cpass = "";
	mobileNumber: string;
	drivingL: string = null;
	referrefBy: string = null;
	validateRefCodeUserId: any;
	validateRefStatusCode: any;
	isClicked = true;
	images = Images;
	inputReadonly = true;
	// update user variables
	result: any;
	user = {};
	isLoadingUpdateProfile = false;
	isLoading = false;
	isLoaded = false;
	loading = false;
	loaded = false;
	isChanged = true;

	date: any;
	registerMessage: string;
	selectedLanguage: string;
	registerLabels = {};
	updateData = {};
	apiDetails = {};
	UserProfileDetails = {};
	myForm: FormGroup;
	matcher = new MyErrorStateMatcher();
	statusCode = 0;
	registerStatusCode = 0;
	errMessage = null;
	errorMessage = null;
	profileMessage: string;

	otpInput: any;
	areaLabels = {};
	areaNames = [];
	zonesLabel = [];
	areaArray = {};

	areaValue = null;
	selected: any;

	googleLoginName: any;
	googleEmailId: any;
	facebookEmailId: any;
	facebookLoginName: any;

	cPasshHide = true;
	hidePassword = true;
	encrPassword: any;
	land = null;

	myModel = true;
	showTerm: boolean = false;
	updateform: boolean = false;

	// public nameRegx = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
	// public emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
	// public firstNameRegx = /^[A-Za-z]{1,25}$/;
	// public middleNameRegx = /^[A-Za-z]{1,25}$/;
	// public lastNameRegx = /^[A-Za-z]{1,25}$/;
	// public cityNameRegx = /^[A-Za-z]{1,25}$/;
	// public passwordRegx = /^[A-Za-z0-9`~!@#$%^&*()_+-=:"<>?;',./]{8,20}$/;
	// public drivingLicenceRegx = /^[A-Z]{2}[0-9]{13}$/;
	// public aadharRegx = /^\d{4}\d{4}\d{4}$/;

	public nameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	public emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,50}$/;
	public FirstNameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	// public FirstNameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	// public fullnameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|\\s]+$/;
	public middleNameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	public lastNameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	public cityNameRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?,./;'|]+$/;
	public addressRegx = /^[^!@#$%^&*)}{(_+-=~`:"<>?.;'|]+$/;
	public passwordRegx = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
	// public passwordRegx = /^[A-Za-z0-9`~!@#$%^&*()_+-=:'<>?;',./]{8,20}$/;
	public drivingLicenceRegx = /^[A-Z]{2}[0-9]{13}$/;
	public aadharRegx = /^\d{4}\d{4}\d{4}$/;
	public referredByCodeRegx = /\b[a-z0-9]{7}\b/;

	name = new FormControl('', [Validators.required, Validators.pattern(this.nameRegx), Validators.maxLength(50)]);
	email = new FormControl('', [Validators.required, Validators.pattern(this.emailRegx), Validators.maxLength(50)]);
	fName = new FormControl('', [Validators.required, Validators.pattern(this.FirstNameRegx), Validators.maxLength(50)]);
	// fLName = new FormControl('', [Validators.required, Validators.pattern(this.fullnameRegx), Validators.maxLength(50)]);
	mName = new FormControl('', [Validators.pattern(this.middleNameRegx), Validators.maxLength(50)]);
	lName = new FormControl('', [Validators.pattern(this.lastNameRegx), Validators.maxLength(50)]);
	drivingLicence = new FormControl('', [Validators.pattern(this.drivingLicenceRegx)]);
	referredByCode = new FormControl('', [Validators.pattern(this.referredByCodeRegx)]);
	aadhar = new FormControl('', [Validators.pattern(this.aadharRegx)]);
	checkBox = new FormControl('', [(control) => !control.value ? { required: true } : null]);
	bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
	bldgTwo = new FormControl('', [Validators.required, Validators.maxLength(50)]);
	// cityName = new FormControl('', [Validators.required, Validators.pattern(this.cityNameRegx), Validators.maxLength(50)]);
	addressName = new FormControl('', [Validators.required, Validators.maxLength(200)]);
	landmark = new FormControl('', [Validators.required, Validators.maxLength(200)]);
	// Area = new FormControl('', [Validators.required]);
	Area = new FormControl({ value: '', disabled: false });
	AreaDisable = new FormControl({ value: '', disabled: true });
	zone = new FormControl('', [Validators.required]);
	dateOfBirth = new FormControl('', [Validators.required]);
	gender = new FormControl('', [Validators.required]);
	newDaySelected: any;
	newMonthSelected: any;
	newYearSelected: number;
	serverMessage: string;
	registeredUserData: any;
	isServerMessage = false;
	zoneName: any;
	zoneArray: any[];
	zoneFromArray = [];
	isDisable = false;
	zonevalue: string;
	zoneKeyValue: [string, string][];
	onlyDate: any;
	zoneKey: any;
	zonevaluearray: string[];
	successMessages: {};
	footerLabels: {};
	updatelabels: any;
	twitterEmailId: any;
	twitterLoginName: any;
	errorReferalCode: any;
	toOpenTNC = false;
	loadingTC: boolean;
	termsAndConditionData: {};
	Addlat: any;
	Addlong: any;
	fcmToken = null;
	latLongReceived: boolean = false;

	options: any = {
		componentRestrictions: { country: 'IN' }
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private store: Store<fromStore.CEPState>,
		private router: Router,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<RegistrationComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		public datepipe: DatePipe,
		private localStorageService: LocalStorageService,
		private titleService: Title
	) {

		// this.myForm = new FormGroup({
		// 	password: new FormControl('', [
		// 	  Validators.required,
		// 	//   Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
		// 	// Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)
		// 	Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)

		// 	])
		//   });


		dialogRef.disableClose = true;

		// if data is there, zone value assignment
		if (this.data) {
			if (this.data.zone === 'other') {
				this.landmark = new FormControl({ value: '', disabled: true });
				this.bldgOne = new FormControl({ value: '', disabled: true });
				this.AreaDisable = new FormControl({ value: '', disabled: true });
				this.isDisable = true;
				this.area = null;
			}
			if (this.data.zone !== 'other' && this.data.zone === undefined) {
				this.bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
				this.landmark = new FormControl('', [Validators.required]);
				this.AreaDisable = new FormControl({ value: '', disabled: true });
				this.isDisable = false;
			}
			if (this.data.zone !== 'other' && this.data.zone !== undefined) {
				this.bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
				this.landmark = new FormControl('', [Validators.required]);
				this.AreaDisable = new FormControl('', [Validators.required]);
				this.isDisable = false;
			}
		}

		// server error handling
		this.store.select(fromStore.getUserStateServerErrorMessage)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage !== null) {
				
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else if(serverErrorMessage !== undefined){
						this.serverMessage = serverErrorMessage;
						this.isServerMessage = true;
						if (this.serverMessage) {
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
				serverErrorMessage = null;
			});


		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getprofileScreenLabels)
						.subscribe((labels) => {
							this.updatelabels = labels;
						});
				}
			});

		this.store.select(fromStore.getRegisterScreenLabels)
			.subscribe((labels) => {
				this.registerLabels = labels;				
				const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
				const passwordMarMessage = 'आपला पासवर्ड हा किमान 8 कॅरेक्टर असणे आवश्यक आहे आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';
				this.registerLabels['en'].passwordLength = passwordEnMessage;
				this.registerLabels['mar'].passwordLength = passwordMarMessage;
				const addressIsNotValidEn ='Please select address from dropdown.';
				const addressIsNotValidMar = 'कृपया ड्रॉपडाउनमधून पत्ता निवडा.';
				this.registerLabels['en'].youHaveEnteredInvalidCity = addressIsNotValidEn;
				this.registerLabels['mar'].youHaveEnteredInvalidCity = addressIsNotValidMar;
			});

		this.store.select(fromStore.getFooterLabels)
			.subscribe(res => {
				this.footerLabels = res;
			});

			this.store.select(fromStore.getFCMToken)
			.subscribe( (token) => {
				this.fcmToken = token
			} )

		// onboard(Admin) registration handling
		this.store.select(fromStore.verifyOtpResponse)
			.subscribe((registeredUserData) => {
		
				this.registeredUserData = registeredUserData;
				if ( Object.keys(this.registeredUserData).length > 0 ) {
					this.firstName = this.registeredUserData.firstName;
					this.middleName = this.registeredUserData.middleName;
					this.lastName = this.registeredUserData.lastName;
					this.DOB = this.registeredUserData.DOB;
					this.emailId = this.registeredUserData.emailId;
					this.genderLabel = this.registeredUserData.gender;
					this.zonevalue = this.registeredUserData.zone;
					this.areaValue = this.registeredUserData.area;
					if (this.registeredUserData.address) {
						this.addressOne = this.registeredUserData.address[0];
						this.landmarkInput = this.registeredUserData.address[1];
					}
					this.city = this.registeredUserData.city;
					this.address = this.registeredUserData.address;
					this.aadharCard = this.registeredUserData.aadharCard;
					this.drivingL = this.registeredUserData.drivingLicence;
					this.referrefBy = this.registeredUserData.referredByCode;
				}
			});

		// zone and area dropdown handling
		this.store.select(fromStore.getAreaAndZones)
			.subscribe((response: {}[]) => {
				if (response.length > 0) {
					this.zonesLabel = response[0][this.selectedLanguage];
				}
				this.zoneArray = [];
				for (let k = 0; k < this.zonesLabel.length; k++) {
					this.zoneArray.push(Object.values(this.zonesLabel[k].zone));
					if (this.registeredUserData && this.registeredUserData.zone) {
						this.zoneKeyValue = Object.entries(this.zonesLabel[k].zone);
						if (this.registeredUserData.zone === this.zoneKeyValue[0][0]) {
							this.zoneValue = this.zoneKeyValue[0][1];
							this.zoneFromArray = Object.values(this.zonesLabel[k].zone);
							if (this.zoneFromArray[0] === this.zoneValue) {
								this.areaArray = this.zonesLabel[k].areas;
							}
						}
						if (this.zoneValue && this.registeredUserData.area) {
							Object.entries(this.zonesLabel[k].areas).forEach(item => {
								if (this.registeredUserData.area === item[0]) {
									this.areaArray = this.zonesLabel[k].areas;
									this.area = item[1];
								}
							});
						}
					}
					if (this.data && this.data.zone) {
						this.zoneKeyValue = Object.entries(this.zonesLabel[k].zone);
						if (this.data.zone === this.zoneKeyValue[0][0]) {
							this.data.Zone = this.zoneKeyValue[0][1];
							this.zoneFromArray = Object.values(this.zonesLabel[k].zone);
							if (this.zoneFromArray[0] === this.data.Zone) {
								this.areaArray = this.zonesLabel[k].areas;
							}
						}
						if (this.data.Zone && this.data.area) {
							if (this.zonesLabel) {
								Object.entries(this.zonesLabel[k].areas).forEach(item => {
									if (this.data.areaKey === item[0]) {
										this.areaArray = this.zonesLabel[k].areas;
										this.data.area = item[1];
									}
								});
							}
						}
					}

				}
			});

		// hone/bldg and landmark disabled in initial registration page
		if (!this.data) {
			if (this.zoneValue === undefined) {
				this.landmark = new FormControl({ value: '', disabled: true });
				this.bldgOne = new FormControl({ value: '', disabled: true });
			} else if (this.zoneValue === undefined) {
				this.bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
				this.landmark = new FormControl('', [Validators.required]);
			}
		}

	}


	handleAddressChange(address: Address) {
		//console.log("Address chnage",address)
			this.address = address.formatted_address;
			this.Addlat = address.geometry.location.lat()
			this.Addlong = address.geometry.location.lng()
		
		this.checkLatLong()
	}

	handleDataAddressChange(address: Address) {	
		//console.log("Address chnage",address)
			this.data.address = address.formatted_address;
			this.Addlat = address.geometry.location.lat()
			this.Addlong = address.geometry.location.lng()
			
		
		this.checkLatLong();
	}

	checkLatLong(){
		//console.log(this.Addlat,this.Addlong)
		if (this.Addlat !== undefined  && this.Addlong !== undefined) {
			this.latLongReceived = true;
		  } else {
			this.latLongReceived = false;
		  }
	}

	verifyPasswordStrength() {

		// const passwordRegx: RegExp = this.passwordRegx;
		
		const passwordRegx: RegExp = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)		
		const password: string = this.pass;
		const tested = passwordRegx.test(password);

		if (tested) {			
			this.redirectToFeeds();
		} else {
			{
				const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
				const passwordMarMessage = 'आपला पासवर्ड हा किमान 8 कॅरेक्टर असणे आवश्यक आहे आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';

				console.error(this.selectedLanguage === 'en' ? passwordEnMessage : passwordMarMessage)
			}

		}

	}


	convertToArray(): Array<string> {
		if (this.areaArray) {
			return Object.keys(this.areaArray);
		}
	}


	// click on zone drop down function
	/**
	 * Created by Akanksha J
	 * For detecting the change in Zone selection
	 */
	onZoneValueChanged(_i) {
		this.areaArray = {};
		if (this.zoneValue || (this.data && this.data.Zone)) {
			for (var i = 0; i < this.zonesLabel.length; i++) {
				if (_i === i) {
					this.zonevaluearray = Object.keys(this.zonesLabel[i].zone);
					this.zonevalue = this.zonevaluearray[0];
					if (this.data && this.data.Zone) {
						this.data.zone = this.zonevaluearray[0];
					}
					this.zoneFromArray = Object.values(this.zonesLabel[i].zone);
					if ((this.zoneFromArray[0] === this.zoneValue) || (this.zoneFromArray[0] === this.data.Zone)) {
						this.areaArray = this.zonesLabel[i].areas;
					}

					if (this.zoneValue === 'Other' || this.zoneValue === 'इतर') {
						this.landmark = new FormControl({ value: '', disabled: true });
						this.bldgOne = new FormControl({ value: '', disabled: true });
						this.AreaDisable = new FormControl({ value: '', disabled: true });
						this.isDisable = true;
						this.area = null;
						this.land = null;
						this.addressOne = null;
					}
					if (this.zoneValue !== 'Other' && this.zoneValue !== 'इतर') {
						this.bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
						this.landmark = new FormControl('', [Validators.required]);
						this.AreaDisable = new FormControl('', [Validators.required]);
						this.isDisable = false;
					}

					if (this.data && this.data.zone === 'other') {
						this.landmark = new FormControl({ value: '', disabled: true });
						this.bldgOne = new FormControl({ value: '', disabled: true });
						this.AreaDisable = new FormControl({ value: '', disabled: true });
						this.isDisable = true;
						this.data.area = null;
						this.data.areaKey = null;
					}
					if (this.data && this.data.zone !== 'other') {
						this.bldgOne = new FormControl('', [Validators.required, Validators.maxLength(50)]);
						this.landmark = new FormControl('', [Validators.required]);
						this.AreaDisable = new FormControl('', [Validators.required]);
						this.isDisable = false;
					}
				}
			}
		}
	}

	// click on area drop down function
	/**
	 * Created by Akanksha J
	 * For detecting the change in Area selection
	 */
	selectedArea(i) {
		Object.keys(this.areaArray).forEach((keys, index) => {
			if (i === index) {
				this.areaValue = keys;
				if (this.data) {
					this.data.areaKey = keys;
				}
			}
		});
	}

	ngOnInit() {
		this.titleService.setTitle('PMC CARE: Citizen Registration Page');
		if (this.data) {
			if (this.data.areaKey !== 'other') {
				this.addressOne = this.data.address[0];
				this.landmarkInput = this.data.address[1];
			}
		}

		// this.passwordForm = this.formBuilder.group({
		// 	password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/)]]
		//   });

		// password and conform password match validation
		this.myForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.pattern(this.passwordRegx)]],
			confirmPassword: ['']
		}, { validator: this.checkPasswords });



		// facebook login data assignment
		this.store.select(fromStore.facebookLoginData)
			.subscribe((facebookLoginData) => {
				if (facebookLoginData) {
					this.facebookLoginName = facebookLoginData['name'];
					this.facebookEmailId = facebookLoginData['email'];
					this.firstName = this.facebookLoginName;
					this.emailId = this.facebookEmailId;
				}
			});

		// google login data assignment
		this.store.select(fromStore.googleLoginData)
			.subscribe((googleLoginData) => {
				if (googleLoginData) {
					this.googleLoginName = googleLoginData['name'];
					this.googleEmailId = googleLoginData['email'];
					this.firstName = this.googleLoginName;
					this.emailId = this.googleEmailId;
				}
			});

		// twitter login data assignment
		this.store.select(fromStore.twitterLoginData)
			.subscribe((twitterLoginData) => {

				if (twitterLoginData && twitterLoginData["user"]) {
					this.twitterLoginName = twitterLoginData["user"].displayName;
					this.twitterEmailId = twitterLoginData["user"].email;
					this.firstName = this.twitterLoginName;
					this.emailId = this.twitterEmailId;
				}
			});

		this.store.select(fromStore.registerUserLoading)
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getUserStateResponse)
			.subscribe((getUserStateResponse) => {
				this.mobileNumber = getUserStateResponse['mobile'];
				if(this.mobileNumber !== undefined){
					this.localStorageService.setItem("OTPMobile",this.mobileNumber)
				}else{
					let mob = this.localStorageService.getItem("OTPMobile")
					if(mob !== null){
						this.mobileNumber = mob;
					}
				}
			});

		// update profile response
		if (this.data) {
			this.store.select(fromStore.getUpdateProfileLoaded)
				.subscribe((isLoaded) => {
					this.isLoaded = isLoaded;
					if (this.isLoaded) {
						this.store.select(fromStore.getUpdateProfileStatusCode)
							.subscribe((statusCode) => {
								this.statusCode = statusCode;
								if (this.statusCode === 200) {
									this.store.select(fromStore.getUpdateProfileErrorMessage)
										.subscribe((profileMessage) => {
											this.profileMessage = profileMessage;
											if (this.profileMessage !== null) {
												this.dialog.closeAll();
												this.store.select(fromStore.getSuccessMessages)
													.subscribe(res => {
														this.successMessages = res;
														this.toastr.success(this.successMessages[this.selectedLanguage][this.profileMessage]);
														this.profileMessage = null;
													});
												this.store.dispatch(new fromStore.ResetUpdateProfile());
												this.profileMessage = null;
											}
										});
								} else if (statusCode === 612) {
									this.store.select(fromStore.getErrorMessages)
										.subscribe((errMessage) => {
											this.errorMessage = errMessage;
											if (this.errorMessage) {
												this.store.dispatch(new fromStore.ResetUpdateProfile());
											}
										});
								}
							});
					}

				});
		} else {
			this.store.select(fromStore.registerUserLoaded)
				.subscribe((response) => {
					this.isLoaded = response;
					if (this.isLoaded) {
						this.store.select(fromStore.registerStatusCode)
							.subscribe((statusCode) => {
								this.registerStatusCode = statusCode;
								if (this.registerStatusCode === 200) {
									this.store.select(fromStore.registerUserErrorMessage)
										.subscribe((registerMessage) => {
											this.registerMessage = registerMessage;
											if (this.registerMessage) {
												this.store.select(fromStore.getSuccessMessages)
													.subscribe(res => {
														this.successMessages = res;
														this.toastr.success(this.successMessages[this.selectedLanguage][this.registerMessage]);
														this.registerMessage = null;
													});
												this.dialog.closeAll();
												this.dialog.open(SelectPreferncesComponent, {
													disableClose: true,
													maxWidth: '100vw',
													autoFocus: false
												});
												// this.router.navigate(['/cep/feeds']);
												this.registerMessage = null;
											}
										});
								} else if (this.registerStatusCode === 0) {
									this.errorMessage = null;
								} else if (this.registerStatusCode === 202) {
									this.store.select(fromStore.getErrorMessages)
										.subscribe((registerMessage) => {
											this.errorMessage = registerMessage;
											if (this.errorMessage) {
												console.error(this.errorMessage[this.selectedLanguage][this.statusCode]);
												this.store.dispatch(new fromStore.ResetMobileNumber());
												this.errorMessage = null;
											}
										});
								} else if (this.registerStatusCode === 603) {
									this.store.select(fromStore.getErrorMessages)
										.subscribe((registerMessage) => {
											this.errorMessage = registerMessage;
											if (this.errorMessage) {
												console.error(this.errorMessage[this.selectedLanguage][this.registerStatusCode]);
												this.store.dispatch(new fromStore.ResetMobileNumber());
											}
										});
								} else {
									this.errMessage = null;
								}
							});
					}
				});
		}
	}

	// check box is checked or not function
	onChkChange() {
		this.isClicked = !this.isClicked;
		// this.openNewWindow();
	}


	// open terms and condition in new window
	/**
	 * Created by Akanksha J
	 * For opening Terms and condition in new window
	 */
	// async openNewWindow() {
	// 	this.showTerm = !this.showTerm;
	// 	this.store.dispatch(new fromStore.TermsAndConditions({
	// 		url: this.apiDetails['info'].base_url +
	// 		this.apiDetails['info'].getTermsAndConditions.url,
	// 		method: 'get',
	// 		body:{}
	// 	}));
	// 	this.store.select(fromStore.isTermsAndConditionsLoading)
	// 	.subscribe(isloaded =>{
	// 		if(isloaded == true){
	// 			this.loadingTC =  isloaded;
	// 		}
	// 		else{
	// 			this.loadingTC = false;
	// 		}
	// 	})
	// 	this.store.select(fromStore.isTermsAndConditionsLoaded)
	// 	.subscribe(loaded => {
	// 		// this.loadingTC=false;
	// 	  if (loaded) {
	// 		this.store.select(fromStore.termsAndConditionsStatusCode)
	// 		.subscribe(code => {
	// 			if (code === 200) {
	// 				this.store.select(fromStore.termsAndConditions)
	// 				.subscribe(response =>{
	// 					this.termsAndConditionData = response;
	// 				})
	// 			}})
	// 	  }})
	// }


	openTerms() {
		window.open('../../../assets/terms/terms-and-conditions.pdf');
	}
	openPolicy() {
		window.open('../../../assets/terms/privacy-policy.pdf');
	}
	// click on submit button function
	/**
	 * Created by Akanksha J
	 * for getting the register user response
	 */
	redirectToFeeds() {
		//this.googleAnalyticsService.eventEmitter('Registration details', 'click on Registration Submit', 'Click Event', 1);
		this.encrPassword = this.encrDecr.set(this.pass);
		if (this.zoneValue !== 'Other' && this.zoneValue !== 'इतर') {
			if (this.placesRef && this.placesRef.place) {
				this.land = this.placesRef.place.formatted_address;
			} else {
				this.land = this.landmarkInput;
			}
		}
		const reqBody = {
			firstName: this.firstName,
			middleName: this.middleName,
			lastName: this.lastName,
			gender: this.genderLabel,
			DOB: this.date,
			mobile: this.mobileNumber,
			address: [this.address],
			area: this.areaValue,
			zone: this.zonevalue,
			city: this.city,
			state: '',
			country: '',
			pinCode: '',
			emailId: this.emailId,
			drivingLicence: this.drivingL,
			referredBy: this.validateRefCodeUserId,
			aadharCard: this.aadhar.value,
			password: this.encrPassword,
			createdSource: 'Web',
			sourceLocation: 'Web',
			lang: this.selectedLanguage,
			lat: this.Addlat,
			long: this.Addlong,
			fcmToken:this.fcmToken !== null ? this.fcmToken : [] 
		};		

		this.store.dispatch(new fromStore.RegisterUser({
			url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].registerUser.url,
			method: this.apiDetails['user'].registerUser.method,
			body: reqBody
		}));
	}

	// functions for error messages******************
	getFirstNameErrorMessage() {
		return this.fName.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			this.fName.hasError('pattern') ? this.registerLabels[this.selectedLanguage].nameIsNotValid :
				'';
	}

	getMiddleNameErrorMessage() {
		return this.mName.hasError('pattern') ? this.registerLabels[this.selectedLanguage].nameIsNotValid :
			'';
	}
	getLastNameErrorMessage() {
		return this.lName.hasError('pattern') ? this.registerLabels[this.selectedLanguage].nameIsNotValid :
			'';
	}
	getNameErrorMessage() {
		return this.name.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			this.name.hasError('pattern') ? this.registerLabels[this.selectedLanguage].nameIsNotValid :
				'';
	}
	getEmailErrorMessage() {
		return this.email.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			this.email.hasError('pattern') ? this.registerLabels[this.selectedLanguage].emailIsNotValid :
				'';
	}
	getBldgOneErrorMessage() {
		return this.bldgOne.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getBldgTwoErrorMessage() {
		return this.bldgTwo.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getLandmarkErrorMessage() {
		return this.landmark.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			this.landmark.hasError('maxLength') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
				'';
	}
	// getCityErrorMessage() {
	// 	return this.cityName.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
	// 		this.cityName.hasError('pattern') ? this.registerLabels[this.selectedLanguage].cityNameIsNotValid :
	// 			'';
	// }

	getAddressErrorMessage() {
		return this.addressName.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			this.addressName.hasError('pattern') ? this.registerLabels[this.selectedLanguage].addressIsNotValid :
				'';
	}
	getGenderErrorMessage() {
		return this.gender.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getAreaErrorMessage() {
		return this.Area.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getZoneErrorMessage() {
		return this.zone.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getDobErrorMessage() {
		return this.dateOfBirth.hasError('required') ? this.registerLabels[this.selectedLanguage].thisFieldIsRequired :
			'';
	}
	getDrivingLicenceErrorMessage() {
		return this.drivingLicence.hasError('pattern') ? this.registerLabels[this.selectedLanguage].licenceIsNotValid :
			'';
	}
	getAadharErrorMessage() {
		return this.aadhar.hasError('pattern') ? this.registerLabels[this.selectedLanguage].aadharIsNotValid :
			'';
	}
	getCheckBoxErrorMessage() {
		return this.checkBox.hasError('required') ? 'Please accept Terms and Conditions' :
			'';
	}

	// onchange of referral code input field
	validateRefCode() {
		this.errorReferalCode == false;
		this.store.dispatch(new fromStore.validateReferralCode({
			url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].validateReferralCode.url,
			method: this.apiDetails['user'].validateReferralCode.method,
			body: { myReferralCode: this.referrefBy }
		}));
		this.store.select(fromStore.validateReferralCodeStatusCode)
			.subscribe(statusCode => {

				switch (statusCode) {
					case 200:
						this.store.select(fromStore.validateRefCodeResult)
							.subscribe(userId => {

								if (userId['campaignResult'] && userId['isCampaign']) {
									this.validateRefCodeUserId = userId['campaignResult']

								} else {
									this.validateRefCodeUserId = userId["referredBy"]
									

								}
							});
						break
					case 500:
						this.errorReferalCode = true;
						break
					default:

						this.errorReferalCode = false;
				}
			})
	}

	getRefferedByErrorMessage() {

		return this.referredByCode.hasError('pattern') ? this.registerLabels[this.selectedLanguage].referralCodeIsNotValid :
			'';

	}
	// function for close dialog
	/**
	 * created by Akanksha J
	 * for closing the registration popup
	 */
	closeDialog() {
		this.dialog.closeAll();
		this.store.dispatch(new fromStore.ResetLogin());

	}



	// close edit profile dialog
	/**
	 * Created by Akanksha J
	 * For closing the edit profile popup
	 */
	closeUpdateDialog() {
		this.dialog.closeAll();
	}

	ngAfterViewInit() {
		this.ref.detectChanges();
		window.scrollTo(0, 0)
		
	}

	// update button function in edit profile
	/**
	 * Created by Prajkta Patil
	 * For redirecting to update profile page 
	 */
	validate(data) {
		this.data.firstName
	}

	redirectToUpdate(data) {
		if ((typeof this.data.firstName === 'undefined') || this.data.firstName == null || this.data.firstName == '') {
			alert(this.registerLabels[this.selectedLanguage].nameIsNotValid)
		} else if ((typeof this.dateOfBirth === 'undefined') || this.dateOfBirth == null) {
			// alert("Please select your birth date");
		} else if ((typeof this.gender === 'undefined') || this.gender == null) {
			// alert("Please select gender");
		} else if ((typeof this.data.address === 'undefined') || this.data.address == null || this.data.address == '') {
			this.selectedLanguage === 'en' ? alert(" Residential Address is Not Valid") : alert("पत्ता वैध नाही")
		} else if (this.data.emailId === undefined || this.data.emailId === null || this.data.emailId === '' || !this.data.emailId.match(this.emailRegx)) {
			alert(this.registerLabels[this.selectedLanguage].emailIsNotValid)
		}
		else {
			//this.googleAnalyticsService.eventEmitter('Update Profile', 'click on update button', 'Click Event', 1);

			const reqbody = {
				userId: this.data.userId,
				fullname: this.data.fullname,
				firstName: this.data.firstName,
				// middleName: this.data.middleName,
				// lastName: this.data.lastName,
				gender: this.data.gender,
				DOB: this.date,
				// aadharCard: this.data.aadharCard,
				// drivingLicence: this.data.drivingLicence,
				// referredBy : this.data.referredByCode,
				mobile: this.data.mobile,
				// city: this.data.city,
				// area: this.data.areaKey,
				// zone: this.data.zone,
				emailId: this.data.emailId,
				address: this.data.address,
				// isEmail: this.data.isEmail,
				// isSMS: this.data.isSms,
				// isNotification: this.data.isNotification,
				// preferences: this.data.preferencesUpadated,
				// profilePhoto: this.data.imageUrl
				lat: this.Addlat,
				long: this.Addlong
			};
			this.store.select(fromStore.getApiCallingList)
				.subscribe((apiDetails) => {
					this.apiDetails = apiDetails;
					this.store.dispatch(new fromStore.UpdateProfileDetails({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].updateUser.url,
						method: this.apiDetails['user'].updateUser.method,
						body: reqbody
					}));
				});
			this.dialog.closeAll();
		}
	}

	ngOnDestroy() {
		this.errMessage = null;
		this.errorMessage = null;
		this.serverMessage = null;
		this.registerMessage = null;
		this.profileMessage = null;
	}

	// validation for check password
	/**
	 * Created by Akanksha J
	 * for matching the password and confirm password
	 */
	checkPasswords(group: FormGroup) {
		const pass = group.controls.password.value;
		const confirmPass = group.controls.confirmPassword.value;
		return pass === confirmPass ? null : { notSame: true };
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
		//console.log(!this.Addlat)

		if (this.DOB) {
			this.date = this.datepipe.transform(this.DOB, 'MM-dd-yyyy');
		}
		if (this.data && this.data.DOB) {
			this.date = this.datepipe.transform(this.data.DOB, 'MM-dd-yyyy');
		}
		if( this.address === undefined || this.address.length === 0){
			this.latLongReceived = false
			this.Addlat = null
			this.Addlong = null
		}
	}
}

function validateUppercaseLetter(value: any, string: any) {
	throw new Error('Function not implemented.');
}

