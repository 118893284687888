import { Action } from '@ngrx/store';
export const GET_CFC_CENTRES  = 'GET_CFC_CENTRES';
export const GET_CFC_CENTRES_SUCCESS = 'GET_CFC_CENTRES_SUCCESS';
export const GET_CFC_CENTRES_FAIL = 'GET_CFC_CENTRES_FAIL';
export const GET_NEAR_ME_ON_MAP  = 'GET_NEAR_ME';
export const GET_NEAR_ME_ON_MAP_SUCCESS = 'GET_NEAR_ME_ON_MAP_SUCCESS';
export const GET_NEAR_ME_ON_MAP_FAIL = 'GET_NEAR_ME_ON_MAP_FAIL';
export const GET_BICYCLE_ON_MAP = 'GET_BICYCLE_ON_MAP';
export const GET_BICYCLE_ON_MAP_SUCEESS = 'GET_BICYCLE_ON_MAP_SUCCESS';
export const GET_BICYCLE_ON_MAP_FAIL = 'GET_BICYCLE_ON_MAP_FAIL';
export const GET_BIKE_ON_MAP = 'GET_BIKE_ON_MAP';
export const GET_BIKE_ON_MAP_SUCCESS = 'GET_BIKE_ON_MAP_SUCCESS';
export const GET_BIKE_ON_MAP_FAIL = 'GET_BIKE_ON_MAP_FAIL';
export const RESET_SERVER_ERROR_MESSAGE = 'RESET_SERVER_ERROR_MESSAGE';
export class GetCfcCentres implements Action {
	readonly type = GET_CFC_CENTRES;
  constructor(public payload: any) {}  
}
export class GetCfcCentresSuccess implements Action {
	readonly type = GET_CFC_CENTRES_SUCCESS;
	constructor(public payload: any) {}
}

export class GetCfcCentresFail implements Action {
	readonly type = GET_CFC_CENTRES_FAIL;
	constructor(public payload: any) {}
}
export class GetNearMeOnMap implements Action {
	readonly type = GET_NEAR_ME_ON_MAP;
  constructor(public payload: any) {}  
}
export class GetNearMeOnMapSuccess implements Action {
	readonly type = GET_NEAR_ME_ON_MAP_SUCCESS;
	constructor(public payload: any) {}
}

export class GetNearMeOnMapFail implements Action {
	readonly type = GET_NEAR_ME_ON_MAP_FAIL;
	constructor(public payload: any) {}
}

export class GetBicycleOnMap implements Action{
	readonly type = GET_BICYCLE_ON_MAP;
	constructor(public payload : any){
	}
}
export class GetBicycleOnMapSuccess implements Action{
	readonly type = GET_BICYCLE_ON_MAP_SUCEESS;
	constructor(public payload : any){}
}

export class GetBicycleOnMapFail implements Action{
	readonly type = GET_BICYCLE_ON_MAP_FAIL;
	constructor(public payload : any){}
}

export class getBikeOnMap implements Action{
	readonly type = GET_BIKE_ON_MAP;
	constructor(public payload :any){}
}

export class getBikeOnMapSuccess implements Action{
	readonly type = GET_BIKE_ON_MAP_SUCCESS;
	constructor(public payload :any){}
}

export class getBikeOnMapFail implements Action{
	readonly type = GET_BIKE_ON_MAP_FAIL;
	constructor(public payload :any){}
}

export class resetServerErrorMessage implements Action{
	readonly type = RESET_SERVER_ERROR_MESSAGE;
}

export type nearMeOMMapAction =  GetCfcCentres | GetCfcCentresSuccess | GetCfcCentresFail |
GetNearMeOnMap | GetNearMeOnMapSuccess | GetNearMeOnMapFail |
GetBicycleOnMap | GetBicycleOnMapSuccess | GetBicycleOnMapFail | 
getBikeOnMap | getBikeOnMapSuccess |  getBikeOnMapFail |resetServerErrorMessage;
