import * as fromAboutUs from '../../store/action/about-us.action';

export interface AboutUsState {
	aboutUsServerErrorMessage: string;
	aboutUsResult: {};
	isAboutUsLoading: boolean;
	isAboutUsLoaded: boolean;

	commissionerDetailsServerErrorMessage: string;
	commissionerDetailsResult: {};
	isCommissionerDetailsLoading: boolean;
	isCommissionerDetailsLoaded: boolean;

	cityDetailsServerErrorMessage: string;
	cityDetailsResult: {};
	isCityDetailsLoading: boolean;
	isCityDetailsLoaded: boolean;

	tourismDetailsServerErrorMessage: string;
	tourismDetailsResult: {};
	isTourismDetailsLoading: boolean;
	isTourismDetailsLoaded: boolean;

	corporatesDetailsServerErrorMessage: string;
	corporatesDetailsResult: {};
	isCorporatesDetailsLoading: boolean;
	isCorporatesDetailsLoaded: boolean;

	zoneDetailsServerErrorMessage: string;
	zoneDetailsResult: {};
	isZoneDetailsLoading: boolean;
	isZoneDetailsLoaded: boolean;
}

export const initialState: AboutUsState = {
	aboutUsResult: {},
	isAboutUsLoading: false,
	isAboutUsLoaded: false,
	aboutUsServerErrorMessage: null,

	commissionerDetailsServerErrorMessage: null,
	commissionerDetailsResult: {},
	isCommissionerDetailsLoading: false,
	isCommissionerDetailsLoaded: false,

	cityDetailsServerErrorMessage: null,
	cityDetailsResult: {},
	isCityDetailsLoading: false,
	isCityDetailsLoaded: false,

	tourismDetailsServerErrorMessage: null,
	tourismDetailsResult: {},
	isTourismDetailsLoading: false,
	isTourismDetailsLoaded: false,

	corporatesDetailsServerErrorMessage: null,
	corporatesDetailsResult: {},
	isCorporatesDetailsLoading: false,
	isCorporatesDetailsLoaded: false,

	zoneDetailsServerErrorMessage: null,
	zoneDetailsResult: {},
	isZoneDetailsLoading: false,
	isZoneDetailsLoaded: false,
};

export function reducer(
	state = initialState,
	action: fromAboutUs.aboutUsAction
): AboutUsState {
	switch (action.type) {
		case fromAboutUs.GET_ABOUT_US_DETAILS: {

			return {
				...state,
				isAboutUsLoading: true,
				isAboutUsLoaded: false
			};
		}
		case fromAboutUs.GET_ABOUT_US_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let aboutUsResult = state.aboutUsResult;
			let isAboutUsLoaded = state.isAboutUsLoaded;
			let isAboutUsLoading = state.isAboutUsLoading;

			if (statusCode == 200) {
				aboutUsResult = action.payload.result;
				isAboutUsLoaded = true;
				isAboutUsLoading = false;
			}
			return {
				...state,
				aboutUsResult,
				isAboutUsLoading,
				isAboutUsLoaded
			};
		}
		case fromAboutUs.GET_ABOUT_US_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let aboutUsServerErrorMessage = state.aboutUsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				aboutUsServerErrorMessage = action.payload.statusText;
			}
			else {
				aboutUsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				aboutUsServerErrorMessage,
				isAboutUsLoading: false,
				isAboutUsLoaded: true
			};
		}

		case fromAboutUs.GET_COMMISSIONER_DETAILS: {

			return {
				...state,
				isCommissionerDetailsLoading: true,
				isCommissionerDetailsLoaded: false
			};
		}
		case fromAboutUs.GET_COMMISSIONER_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let commissionerDetailsResult = state.commissionerDetailsResult;
			let isCommissionerDetailsLoaded = state.isCommissionerDetailsLoaded;
			let isCommissionerDetailsLoading = state.isCommissionerDetailsLoading

			if (statusCode == 200) {
				commissionerDetailsResult = action.payload.result;
				isCommissionerDetailsLoaded = true;
				isCommissionerDetailsLoading = false;
			}
			return {
				...state,
				commissionerDetailsResult,
				isCommissionerDetailsLoading,
				isCommissionerDetailsLoaded
			};
		}
		case fromAboutUs.GET_COMMISSIONER_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let commissionerDetailsServerErrorMessage = state.commissionerDetailsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				commissionerDetailsServerErrorMessage = action.payload.statusText;
			}
			else {
				commissionerDetailsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				commissionerDetailsServerErrorMessage,
				isCommissionerDetailsLoaded: true,
				isCommissionerDetailsLoading: false
			};
		}

		case fromAboutUs.GET_CITY_DETAILS: {

			return {
				...state,
				isCityDetailsLoading: true,
				isCityDetailsLoaded: false
			};
		}
		case fromAboutUs.GET_CITY_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let cityDetailsResult = state.cityDetailsResult;
			let isCityDetailsLoaded = state.isCityDetailsLoaded;
			let isCityDetailsLoading = state.isCityDetailsLoading

			if (statusCode == 200) {
				cityDetailsResult = action.payload.result;
				isCityDetailsLoaded = true;
				isCityDetailsLoading = false;
			}
			return {
				...state,
				cityDetailsResult,
				isCityDetailsLoading,
				isCityDetailsLoaded
			};
		}
		case fromAboutUs.GET_CITY_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let cityDetailsServerErrorMessage = state.cityDetailsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				cityDetailsServerErrorMessage = action.payload.statusText;
			}
			else {
				cityDetailsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				cityDetailsServerErrorMessage,
				isCityDetailsLoaded: true,
				isCityDetailsLoading: false
			};
		}

		case fromAboutUs.GET_TOURISM_DETAILS: {

			return {
				...state,
				isTourismDetailsLoading: true,
				isTourismDetailsLoaded: false
			};
		}
		case fromAboutUs.GET_TOURISM_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let tourismDetailsResult = state.tourismDetailsResult;
			let isTourismDetailsLoaded = state.isTourismDetailsLoaded;
			let isTourismDetailsLoading = state.isTourismDetailsLoading

			if (statusCode == 200) {
				tourismDetailsResult = action.payload.result;
				isTourismDetailsLoaded = true;
				isTourismDetailsLoading = false;
			}
			return {
				...state,
				tourismDetailsResult,
				isTourismDetailsLoading,
				isTourismDetailsLoaded
			};
		}
		case fromAboutUs.GET_TOURISM_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let tourismDetailsServerErrorMessage = state.tourismDetailsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				tourismDetailsServerErrorMessage = action.payload.statusText;
			}
			else {
				tourismDetailsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				tourismDetailsServerErrorMessage,
				isTourismDetailsLoaded: true,
				isTourismDetailsLoading: false
			};
		}

		case fromAboutUs.GET_CORPORATES_DETAILS: {

			return {
				...state,
				isCorporatesDetailsLoading: true,
				isCorporatesDetailsLoaded: false
			};
		}
		case fromAboutUs.GET_CORPORATES_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let corporatesDetailsResult = state.corporatesDetailsResult;
			let isCorporatesDetailsLoaded = state.isCorporatesDetailsLoaded;
			let isCorporatesDetailsLoading = state.isCorporatesDetailsLoading

			if (statusCode == 200) {
				corporatesDetailsResult = action.payload.result;
				isCorporatesDetailsLoaded = true;
				isCorporatesDetailsLoading = false;
			}
			return {
				...state,
				corporatesDetailsResult,
				isCorporatesDetailsLoading,
				isCorporatesDetailsLoaded
			};
		}
		case fromAboutUs.GET_CORPORATES_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let corporatesDetailsServerErrorMessage = state.corporatesDetailsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				corporatesDetailsServerErrorMessage = action.payload.statusText;
			}
			else {
				corporatesDetailsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				corporatesDetailsServerErrorMessage,
				isCorporatesDetailsLoading: true,
				isCorporatesDetailsLoaded: false
			};
		}

		case fromAboutUs.GET_ZONE_DETAILS: {

			return {
				...state,
				isZoneDetailsLoading: true,
				isZoneDetailsLoaded: false
			};
		}
		case fromAboutUs.GET_ZONE_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let zoneDetailsResult = state.zoneDetailsResult;
			let isZoneDetailsLoaded = state.isZoneDetailsLoaded;
			let isZoneDetailsLoading = state.isZoneDetailsLoading

			if (statusCode == 200) {
				zoneDetailsResult = action.payload.result;
				isZoneDetailsLoaded = true;
				isZoneDetailsLoading = false;
			}
			return {
				...state,
				zoneDetailsResult,
				isZoneDetailsLoading,
				isZoneDetailsLoaded
			};
		}
		case fromAboutUs.GET_ZONE_DETAILS_FAIL: {

			let statusCode = action.payload.status;
			let zoneDetailsServerErrorMessage = state.zoneDetailsServerErrorMessage;
			if (statusCode == 401 || statusCode == 0) {
				zoneDetailsServerErrorMessage = action.payload.statusText;
			}
			else {
				zoneDetailsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				zoneDetailsServerErrorMessage
			};
		}


	}
	return state;
}

export const getAboutUsResult = (state: AboutUsState) => state.aboutUsResult;
export const getIsAboutUsLoading = (state: AboutUsState) => state.isAboutUsLoading;
export const getIsAboutUsLoaded = (state: AboutUsState) => state.isAboutUsLoaded;
export const getAboutUsServerErrorMessage = (state: AboutUsState) => state.aboutUsServerErrorMessage;

export const getCommissionerDetailsResult = (state: AboutUsState) => state.commissionerDetailsResult;
export const getIsCommissionerDetailsLoading = (state: AboutUsState) => state.isCommissionerDetailsLoading;
export const getIsCommissionerDetailsLoaded = (state: AboutUsState) => state.isCommissionerDetailsLoaded;
export const getCommissionerDetailsServerErrorMessage = (state: AboutUsState) => state.commissionerDetailsServerErrorMessage;

export const getCityDetailsResult = (state: AboutUsState) => state.cityDetailsResult;
export const getIsCityDetailsLoading = (state: AboutUsState) => state.isCityDetailsLoading;
export const getIsCityDetailsLoaded = (state: AboutUsState) => state.isCityDetailsLoaded;
export const getCityDetailsServerErrorMessage = (state: AboutUsState) => state.cityDetailsServerErrorMessage;

export const getTourismDetailsResult = (state: AboutUsState) => state.tourismDetailsResult;
export const getIsTourismDetailsLoading = (state: AboutUsState) => state.isTourismDetailsLoading;
export const getIsTourismDetailsLoaded = (state: AboutUsState) => state.isTourismDetailsLoaded;
export const getTourismDetailsServerErrorMessage = (state: AboutUsState) => state.tourismDetailsServerErrorMessage;

export const getZoneDetailsResult = (state: AboutUsState) => state.zoneDetailsResult;
export const getIsZoneDetailsLoading = (state: AboutUsState) => state.isZoneDetailsLoading;
export const getIsZoneDetailsLoaded = (state: AboutUsState) => state.isZoneDetailsLoaded;
export const getZoneDetailsServerErrorMessage = (state: AboutUsState) => state.zoneDetailsServerErrorMessage;

export const getCorporatesDetailsResult = (state: AboutUsState) => state.corporatesDetailsResult;
export const getIsCorporatesDetailsLoading = (state: AboutUsState) => state.isCorporatesDetailsLoading;
export const getIsCorporatesDetailsLoaded = (state: AboutUsState) => state.isCorporatesDetailsLoaded;
export const getCorporatesDetailsServerErrorMessage = (state: AboutUsState) => state.corporatesDetailsServerErrorMessage;

