import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../../cep/store/index";
import { ObjectInterface } from "src/app/shared/models/global-interface";
import { LocalStorageService } from "src/app/shared/services/localstorage.service";
import { EncrDecrService } from "src/app/shared/services/encr-decr.service";
import { ApiCallService } from "src/app/shared/services/dynamic-api-calling.service";
import { MatDialog } from "@angular/material/dialog";
import { Images } from "../../../../shared/config/images-config";

@Component({
  selector: "app-loginpopup",
  templateUrl: "./loginpopup.component.html",
  styleUrls: ["./loginpopup.component.scss"],
})
export class LoginpopupComponent implements OnInit {
  apiDetails: ObjectInterface;
  date: any;
  propertytax: any = [];
  userId: any;
  token: any;
  taxList = [];
  images = Images;
  transactionId = null;
  progress = "pending";
  orderId = null;
  interval: any;
  timeout: any;
  currentConsumerId: string;
  currentArrears: string;
  transactionData: any;
  selectedLanguage:any;
  labels:any;

  constructor(
    private router: Router,
    private store: Store<fromStore.CEPState>,
    private localStorageService: LocalStorageService,
    private apiCallService: ApiCallService,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.select(fromStore.getSelectedLanguage)
    .subscribe((language) => {
      this.selectedLanguage = language;
      if (this.selectedLanguage) {
        this.store.select(fromStore.getOnboardingLabels)
          .subscribe((labels) => {
            this.labels = labels;
            
          });
      }
    });
  }
  login(){
    this.router.navigate(['/Login/login']);
		const dialogClose = this.dialog.closeAll();
  }

  closeDialog() {
		const dialogClose = this.dialog.closeAll();
	}
}