import { Injectable } from '@angular/core';
import { createEffect , Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as LoginAction from '../action/login.action';
import { LoginService } from '../api-services/login.service';
import { of } from 'rxjs';

@Injectable()
export class UserstateResponseEffects {
  constructor(private actions$: Actions, private LoginService: LoginService) {}


  apiLoginDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetUserStateResponseDetails>(LoginAction.GET_USER_STATE_RESPONSE_DETAILS),
      switchMap((action) => {
        return this.LoginService.getUserStateResponseDetails(action.payload).pipe(
          map((userStateResponseData) =>
            new LoginAction.GetUserStateResponseDetailsSuccess(userStateResponseData)
          ),
          catchError((error) =>
            of(new LoginAction.GetUserStateResponseDetailsFail(error))
          )
        );
      })
    )
  );

  EmployeeState$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetEmployeeState>(LoginAction.GET_EMPLOYEE_STATE),
      switchMap((action) => {
        return this.LoginService.getEmployeeStateResponseDetails(action.payload).pipe(
          map((userStateResponseData) =>
            new LoginAction.GetEmployeeStateSuccess(userStateResponseData)
          ),
          catchError((error) =>
            of(new LoginAction.GetEmployeeStateFail(error))
          )
        );
      })
    )
  );

  sendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.SendOtp>(LoginAction.SEND_OTP),
      switchMap((action) => {
        return this.LoginService.sendOtp(action.payload).pipe(
          map((sendOtpData) => new LoginAction.SendOtpSuccess(sendOtpData)),
          catchError((error) => of(new LoginAction.SendOtpFail(error)))
        );
      })
    )
  );

  verifyOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.VerifyOtp>(LoginAction.VERIFY_OTP),
      switchMap((action) => {
        return this.LoginService.verifyOtp(action.payload).pipe(
          map((verifyOtpData) => new LoginAction.VerifyOtpSuccess(verifyOtpData)),
          catchError((error) => of(new LoginAction.VerifyOtpFail(error)))
        );
      })
    )
  );

  facebookLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.FacebookLogin>(LoginAction.FACEBOOK_LOGIN),
      switchMap((action) => {
        return this.LoginService.facebook(action.payload).pipe(
          map((facebookData) => new LoginAction.FacebookLoginSuccess(facebookData)),
          catchError((error) => of(new LoginAction.FacebookLoginFail(error)))
        );
      })
    )
  );

  twitterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.TwitterLogin>(LoginAction.TWITTER_LOGIN),
      switchMap((action) => {
        return this.LoginService.twitter(action.payload).pipe(
          map((twData) => new LoginAction.TwitterLoginSuccess(twData)),
          catchError((error) => of(new LoginAction.TwitterLoginFail(error)))
        );
      })
    )
  );

  googleLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GoogleLogin>(LoginAction.GOOGLE_LOGIN),
      switchMap((action) => {
        return this.LoginService.google(action.payload).pipe(
          map((googleData) => new LoginAction.GoogleLoginSuccess(googleData)),
          catchError((error) => of(new LoginAction.GoogleLoginFail(error)))
        );
      })
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.ForgotPassword>(LoginAction.FORGOT_PASSWORD),
      switchMap((action) => {
        return this.LoginService.forgotPassword(action.payload).pipe(
          map((forgotPasswordData) => new LoginAction.ForgotPasswordSuccess(forgotPasswordData)),
          catchError((error) => of(new LoginAction.ForgotPasswordFail(error)))
        );
      })
    )
  );

  forgotPasswordByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.ForgotPasswordByEmail>(LoginAction.FORGOT_PASSWORD_BY_EMAIL),
      switchMap((action) => {
        return this.LoginService.forgotPasswordByEmail(action.payload).pipe(
          map((forgotPasswordByEmailData) =>
            new LoginAction.ForgotPasswordByEmailSuccess(forgotPasswordByEmailData)
          ),
          catchError((error) =>
            of(new LoginAction.ForgotPasswordByEmailFail(error))
          )
        );
      })
    )
  );

  verifyPasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.VerifyPassword>(LoginAction.VERIFY_PASSWORD),
      switchMap((action) => {
        return this.LoginService.verifyPassword(action.payload).pipe(
          map((verifyPasswordData) =>
            new LoginAction.VerifyPasswordSuccess(verifyPasswordData)
          ),
          catchError((error) => of(new LoginAction.VerifyPasswordFail(error)))
        );
      })
    )
  );

  verifyLoginWithOTPEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.LoginWithOTP>(LoginAction.LOGIN_WITH_OTP),
      switchMap((action) => {
        return this.LoginService.loginWithOTP(action.payload).pipe(
          map((OTPData) => new LoginAction.LoginWithOTPSucess(OTPData)),
          catchError((error) => of(new LoginAction.LoginWithOTPFail(error)))
        );
      })
    )
  );
  
  verifyLoginEmployeeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.LoginEmployee>(LoginAction.LOGIN_EMPLOYEE),
      switchMap((action) => {
        return this.LoginService.loginEmployee(action.payload).pipe(
          map((OTPData) => new LoginAction.LoginEmployeeSucess(OTPData)),
          catchError((error) => of(new LoginAction.LoginEmployeeFail(error)))
        );
      })
    )
  );
  
  updateprofileApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.UpdateProfileDetails>(LoginAction.UPDATE_PROFILE_DETAILS),
      switchMap((action) => {
        return this.LoginService.UpdateProfileDetailsService(action.payload).pipe(
          map((updateUserProfileData) => 
            new LoginAction.UpdateProfileDetailsSuccess(updateUserProfileData)
          ),
          catchError((error) => 
            of(new LoginAction.UpdateProfileDetailsFail(error))
          )
        );
      })
    )
  );
  
  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.RegisterUser>(LoginAction.REGISTER_USER),
      switchMap((action) => {
        return this.LoginService.registerUser(action.payload).pipe(
          map((userData) => new LoginAction.RegisterUserSuccess(userData)),
          catchError((error) => of(new LoginAction.RegisterUserFail(error)))
        );
      })
    )
  );
  
  validateRefCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.validateReferralCode>(LoginAction.VALIDATE_REFERRAL_CODE),
      switchMap((action) => {
        return this.LoginService.validateReferralCode(action.payload).pipe(
          map((userData) => new LoginAction.validateReferralCodeSuccess(userData)),
          catchError((error) => of(new LoginAction.validateReferralCodeFail(error)))
        );
      })
    )
  );
  
  apiAboutUsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetUserDetails>(LoginAction.GET_USER_DETAILS),
      switchMap((action) => {
        return this.LoginService.getUserDetails(action.payload).pipe(
          map((userDetails) => new LoginAction.GetUserDetailsSuccess(userDetails)),
          catchError((error) => of(new LoginAction.GetUserDetailsFail(error)))
        );
      })
    )
  );
  
  apiRequestMostUsedServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetMostUsedServices>(LoginAction.GET_MOST_USED_SERVICES_LISTING),
      switchMap((action) => {
        return this.LoginService.getMostUsedServices(action.payload).pipe(
          map((mostUsedServices) => new LoginAction.GetMostUsedServicesSuccess(mostUsedServices)),
          catchError((error) => of(new LoginAction.GetMostUsedServicesFail(error)))
        );
      })
    )
  );
  
  updateLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.UpdateLanguage>(LoginAction.UPDATE_LANGUAGE),
      switchMap((action) => {
        return this.LoginService.updateLanguage(action.payload).pipe(
          map((updateLanguage) => new LoginAction.UpdateLanguageSuccess(updateLanguage)),
          catchError((error) => of(new LoginAction.UpdateLanguageFail(error)))
        );
      })
    )
  );
  
  updatePassward$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.getUpdatePasswardResponse>(LoginAction.UPDATE_PASSWARD),
      switchMap((action) => {
        return this.LoginService.getUpdatePasswardResponse(action.payload).pipe(
          map((updatePassward) => 
            new LoginAction.getUpdatePasswardSuccessResponse({
              ...updatePassward,
              onChangePasswordSuccess: action.payload.onChangePasswordSuccess,
            })
          )
        );
      })
    )
  );
  
  updateProfilePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.UpdateProfilePhoto>(LoginAction.UPDATE_PROFILE_PHOTO),
      switchMap((action) => {
        return this.LoginService.updateProfilePhoto(action.payload).pipe(
          map((updateProfilePhoto) => new LoginAction.UpdateProfilePhotoSuccess(updateProfilePhoto)),
          catchError((error) => of(new LoginAction.UpdateProfilePhotoFail(error)))
        );
      })
    )
  );
  
  favImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.FavImage>(LoginAction.FAV_IMAGE),
      switchMap((action) => {
        return this.LoginService.favImage(action.payload).pipe(
          map((favImage) => new LoginAction.FavImageSuccess(favImage)),
          catchError((error) => of(new LoginAction.FavImageFail(error)))
        );
      })
    )
  );
  
  changeMobileNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.ChangeMobileNumber>(LoginAction.CHANGE_MOBILE_NUMBER),
      switchMap((action) => {
        return this.LoginService.changeMobileNumber(action.payload).pipe(
          map((changeMobileNumber) => new LoginAction.ChangeMobileNumberSuccess(changeMobileNumber)),
          catchError((error) => of(new LoginAction.ChangeMobileNumberFail(error)))
        );
      })
    )
  );
  
  verifyMobileNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.VerifyMobileNumber>(LoginAction.VERIFY_MOBILE_NUMBER),
      switchMap((action) => {
        return this.LoginService.verifyMobileNumber(action.payload).pipe(
          map((verifyMobileNumber) => new LoginAction.VerifyMobileNumberSuccess(verifyMobileNumber)),
          catchError((error) => of(new LoginAction.VerifyMobileNumberFail(error)))
        );
      })
    )
  );
  
  getOtpChoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetOtpChoices>(LoginAction.GET_OTP_CHOICES),
      switchMap((action) => {
        return this.LoginService.getOtpChoices(action.payload).pipe(
          map((getOtpChoices) => new LoginAction.GetOtpChoicesSuccess(getOtpChoices)),
          catchError((error) => of(new LoginAction.GetOtpChoicesFail(error)))
        );
      })
    )
  );
  
  getTermsAndConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.TermsAndConditions>(LoginAction.TERMS_AND_CONDITIONS),
      switchMap((action) => {
        return this.LoginService.getTermsAndConditions(action.payload).pipe(
          map((getTermsAndConditions) => new LoginAction.TermsAndConditionsSuccess(getTermsAndConditions)),
          catchError((error) => of(new LoginAction.TermsAndConditionsFail(error)))
        );
      })
    )
  );
  
  getMerchantTermsAndConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.MerchantTermsAndConditions>(LoginAction.MERCHANT_TERMS_AND_CONDITIONS),
      switchMap((action) => {
        return this.LoginService.getMerchantTermsAndConditions(action.payload).pipe(
          map((getMerchantTermsAndConditions) => new LoginAction.MerchantTermsAndConditionsSuccess(getMerchantTermsAndConditions)),
          catchError((error) => of(new LoginAction.MerchantTermsAndConditionsFail(error)))
        );
      })
    )
  );
  
  interestedToBeMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.InterestedToBeMerchant>(LoginAction.INTERESTED_TO_BE_MERCHANT),
      switchMap((action) => {
        return this.LoginService.interestedToBeMerchant(action.payload).pipe(
          map((getInterestResponse) => new LoginAction.InterestedToBeMerchantSuccess(getInterestResponse)),
          catchError((error) => of(new LoginAction.InterestedToBeMerchantFail(error)))
        );
      })
    )
  );
  
  interestedToBeBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.InterestedToBeBrand>(LoginAction.INTERESTED_TO_BE_BRAND),
      switchMap((action) => {
        return this.LoginService.interestedToBeBrand(action.payload).pipe(
          map((getInterestResponse) => new LoginAction.InterestedToBeBrandSuccess(getInterestResponse)),
          catchError((error) => of(new LoginAction.InterestedToBeBrandFail(error)))
        );
      })
    )
  );
  
  registerAsMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.RegisterAsMerchant>(LoginAction.REGISTER_AS_MERCHANT),
      switchMap((action) => {
        return this.LoginService.registerAsMerchant(action.payload).pipe(
          map((getRegisterResponse) => new LoginAction.RegisterAsMerchantSuccess(getRegisterResponse)),
          catchError((error) => of(new LoginAction.RegisterAsMerchantFail(error)))
        );
      })
    )
  );
  
  registerAsBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.RegisterAsBrand>(LoginAction.REGISTER_AS_BRAND),
      switchMap((action) => {
        return this.LoginService.registerAsBrand(action.payload).pipe(
          map((getRegisterResponse) => new LoginAction.RegisterAsBrandSuccess(getRegisterResponse)),
          catchError((error) => of(new LoginAction.RegisterAsBrandFail(error)))
        );
      })
    )
  );
  
  getAllBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.GetAllBrand>(LoginAction.GET_ALL_BRAND),
      switchMap((action) => {
        return this.LoginService.getAllBrand(action.payload).pipe(
          map((getBrandResponse) => new LoginAction.GetAllBrandSuccess(getBrandResponse)),
          catchError((error) => of(new LoginAction.GetAllBrandFail(error)))
        );
      })
    )
  );
  

  subscribeToNewsletter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction.SubcribeToNewsLetter>(LoginAction.SUBSCRIBE_TO_NEWSLETTER),
      switchMap((action) => {
        return this.LoginService.subscribeToNewsletter(action.payload).pipe(
          map(
            (passwordResponse) =>
              new LoginAction.SubcribeToNewsLetterSuccess(passwordResponse)
          ),
          catchError((error) =>
            of(new LoginAction.SubcribeToNewsLetterFail(error))
          )
        );
      })
    )
  );

  // @Effect()
  // apiLoginDetails$ = this.actions$.pipe(
  //   ofType<LoginAction.GetUserStateResponseDetails>(
  //     LoginAction.GET_USER_STATE_RESPONSE_DETAILS
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.getUserStateResponseDetails(action.payload).pipe(
  //       map(
  //         (userStateResponseData) =>
  //           new LoginAction.GetUserStateResponseDetailsSuccess(
  //             userStateResponseData
  //           )
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.GetUserStateResponseDetailsFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // EmployeeState$ = this.actions$.pipe(
  //   ofType<LoginAction.GetEmployeeState>(
  //     LoginAction.GET_EMPLOYEE_STATE
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.getEmployeeStateResponseDetails(action.payload).pipe(
  //       map(
  //         (userStateResponseData) =>
  //           new LoginAction.GetEmployeeStateSuccess(
  //             userStateResponseData
  //           )
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.GetEmployeeStateFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // sendOtp$ = this.actions$.pipe(
  //   ofType<LoginAction.SendOtp>(LoginAction.SEND_OTP),
  //   switchMap((action) => {
  //     return this.LoginService.sendOtp(action.payload).pipe(
  //       map((sendOtpData) => new LoginAction.SendOtpSuccess(sendOtpData)),
  //       catchError((error) => of(new LoginAction.SendOtpFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // verifyOtp$ = this.actions$.pipe(
  //   ofType<LoginAction.VerifyOtp>(LoginAction.VERIFY_OTP),
  //   switchMap((action) => {

  //     return this.LoginService.verifyOtp(action.payload).pipe(
  //       map((verifyOtpData) => new LoginAction.VerifyOtpSuccess(verifyOtpData)),
  //       catchError((error) => of(new LoginAction.VerifyOtpFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // facebookLogin$ = this.actions$.pipe(
  //   ofType<LoginAction.FacebookLogin>(LoginAction.FACEBOOK_LOGIN),
  //   switchMap((action) => {
  //     return this.LoginService.facebook(action.payload).pipe(
  //       map(
  //         (facebookData) => new LoginAction.FacebookLoginSuccess(facebookData)
  //       ),
  //       catchError((error) => of(new LoginAction.FacebookLoginFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // twitterLogin$ = this.actions$.pipe(
  //   ofType<LoginAction.TwitterLogin>(LoginAction.TWITTER_LOGIN),
  //   switchMap((action) => {
  //     return this.LoginService.twitter(action.payload).pipe(
  //       map((twData) => new LoginAction.TwitterLoginSuccess(twData)),
  //       catchError((error) => of(new LoginAction.TwitterLoginFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // googleLogin$ = this.actions$.pipe(
  //   ofType<LoginAction.GoogleLogin>(LoginAction.GOOGLE_LOGIN),
  //   switchMap((action) => {
  //     return this.LoginService.google(action.payload).pipe(
  //       map((googleData) => new LoginAction.GoogleLoginSuccess(googleData)),
  //       catchError((error) => of(new LoginAction.GoogleLoginFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // forgotPassword$ = this.actions$.pipe(
  //   ofType<LoginAction.ForgotPassword>(LoginAction.FORGOT_PASSWORD),
  //   switchMap((action) => {
  //     return this.LoginService.forgotPassword(action.payload).pipe(
  //       map(
  //         (forgotPasswordData) =>
  //           new LoginAction.ForgotPasswordSuccess(forgotPasswordData)
  //       ),
  //       catchError((error) => of(new LoginAction.ForgotPasswordFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // forgotPasswordByEmail$ = this.actions$.pipe(
  //   ofType<LoginAction.ForgotPasswordByEmail>(
  //     LoginAction.FORGOT_PASSWORD_BY_EMAIL
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.forgotPasswordByEmail(action.payload).pipe(
  //       map(
  //         (forgotPasswordByEmailData) =>
  //           new LoginAction.ForgotPasswordByEmailSuccess(
  //             forgotPasswordByEmailData
  //           )
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.ForgotPasswordByEmailFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // verifyPasswordEffect$ = this.actions$.pipe(
  //   ofType<LoginAction.VerifyPassword>(LoginAction.VERIFY_PASSWORD),
  //   switchMap((action) => {
  //     return this.LoginService.verifyPassword(action.payload).pipe(
  //       map(
  //         (verifyPasswordData) =>
  //           new LoginAction.VerifyPasswordSuccess(verifyPasswordData)
  //       ),
  //       catchError((error) => of(new LoginAction.VerifyPasswordFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // verifyLoginWithOTPEffect$ = this.actions$.pipe(
  //   ofType<LoginAction.LoginWithOTP>(LoginAction.LOGIN_WITH_OTP),
  //   switchMap((action) => {
  //     return this.LoginService.loginWithOTP(action.payload).pipe(
  //       map(
  //         (OTPData) =>
  //           new LoginAction.LoginWithOTPSucess(OTPData)
  //       ),
  //       catchError((error) => of(new LoginAction.LoginWithOTPFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // verifyLoginEmployeeEffect$ = this.actions$.pipe(
  //   ofType<LoginAction.LoginEmployee>(LoginAction.LOGIN_EMPLOYEE),
  //   switchMap((action) => {
  //     return this.LoginService.loginEmployee(action.payload).pipe(
  //       map(
  //         (OTPData) =>
  //           new LoginAction.LoginEmployeeSucess(OTPData)
  //       ),
  //       catchError((error) => of(new LoginAction.LoginEmployeeFail(error)))
  //     );
  //   })
  // );


  // @Effect()
  // updateprofileApi$ = this.actions$.pipe(
  //   ofType<LoginAction.UpdateProfileDetails>(
  //     LoginAction.UPDATE_PROFILE_DETAILS
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.UpdateProfileDetailsService(action.payload).pipe(
  //       map(
  //         (updateUserProfileData) =>
  //           new LoginAction.UpdateProfileDetailsSuccess(updateUserProfileData)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.UpdateProfileDetailsFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // registerUser$ = this.actions$.pipe(
  //   ofType<LoginAction.RegisterUser>(LoginAction.REGISTER_USER),
  //   switchMap((action) => {
  //     return this.LoginService.registerUser(action.payload).pipe(
  //       map((userData) => new LoginAction.RegisterUserSuccess(userData)),
  //       catchError((error) => of(new LoginAction.RegisterUserFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // validateRefCode$ = this.actions$.pipe(
  //   ofType<LoginAction.validateReferralCode>(
  //     LoginAction.VALIDATE_REFERRAL_CODE
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.validateReferralCode(action.payload).pipe(
  //       map(
  //         (userData) => new LoginAction.validateReferralCodeSuccess(userData)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.validateReferralCodeFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // apiAboutUsDetails$ = this.actions$.pipe(
  //   ofType<LoginAction.GetUserDetails>(LoginAction.GET_USER_DETAILS),
  //   switchMap((action) => {
  //     return this.LoginService.getUserDetails(action.payload).pipe(
  //       map(
  //         (userDetails) => new LoginAction.GetUserDetailsSuccess(userDetails)
  //       ),
  //       catchError((error) => of(new LoginAction.GetUserDetailsFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // apiRequestMostUsedServices$ = this.actions$.pipe(
  //   ofType<LoginAction.GetMostUsedServices>(
  //     LoginAction.GET_MOST_USED_SERVICES_LISTING
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.getMostUsedServices(action.payload).pipe(
  //       map(
  //         (mostUsedServices) =>
  //           new LoginAction.GetMostUsedServicesSuccess(mostUsedServices)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.GetMostUsedServicesFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // updateLanguage$ = this.actions$.pipe(
  //   ofType<LoginAction.UpdateLanguage>(LoginAction.UPDATE_LANGUAGE),
  //   switchMap((action) => {
  //     return this.LoginService.updateLanguage(action.payload).pipe(
  //       map(
  //         (updateLanguage) =>
  //           new LoginAction.UpdateLanguageSuccess(updateLanguage)
  //       ),
  //       catchError((error) => of(new LoginAction.UpdateLanguageFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // updatePassward$ = this.actions$.pipe(
  //   ofType<LoginAction.getUpdatePasswardResponse>(LoginAction.UPDATE_PASSWARD),
  //   switchMap((action) => {
  //     return this.LoginService.getUpdatePasswardResponse(action.payload).pipe(
  //       map(
  //         (updatePassward) =>
  //           new LoginAction.getUpdatePasswardSuccessResponse({
  //             ...updatePassward,
  //             onChangePasswordSuccess: action.payload.onChangePasswordSuccess,
  //           })
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // updateProfilePhoto$ = this.actions$.pipe(
  //   ofType<LoginAction.UpdateProfilePhoto>(LoginAction.UPDATE_PROFILE_PHOTO),
  //   switchMap((action) => {
  //     return this.LoginService.updateProfilePhoto(action.payload).pipe(
  //       map(
  //         (updateProfilePhoto) =>
  //           new LoginAction.UpdateProfilePhotoSuccess(updateProfilePhoto)
  //       ),
  //       catchError((error) => of(new LoginAction.UpdateProfilePhotoFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // favImage$ = this.actions$.pipe(
  //   ofType<LoginAction.FavImage>(LoginAction.FAV_IMAGE),
  //   switchMap((action) => {
  //     return this.LoginService.favImage(action.payload).pipe(
  //       map((favImage) => new LoginAction.FavImageSuccess(favImage)),
  //       catchError((error) => of(new LoginAction.FavImageFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // changeMobileNumber$ = this.actions$.pipe(
  //   ofType<LoginAction.ChangeMobileNumber>(LoginAction.CHANGE_MOBILE_NUMBER),
  //   switchMap((action) => {
  //     return this.LoginService.changeMobileNumber(action.payload).pipe(
  //       map(
  //         (changeMobileNumber) =>
  //           new LoginAction.ChangeMobileNumberSuccess(changeMobileNumber)
  //       ),
  //       catchError((error) => of(new LoginAction.ChangeMobileNumberFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // verifyMobileNumber$ = this.actions$.pipe(
  //   ofType<LoginAction.VerifyMobileNumber>(LoginAction.VERIFY_MOBILE_NUMBER),
  //   switchMap((action) => {
  //     return this.LoginService.verifyMobileNumber(action.payload).pipe(
  //       map(
  //         (verifyMobileNumber) =>
  //           new LoginAction.VerifyMobileNumberSuccess(verifyMobileNumber)
  //       ),
  //       catchError((error) => of(new LoginAction.VerifyMobileNumberFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // getOtpChoices$ = this.actions$.pipe(
  //   ofType<LoginAction.GetOtpChoices>(LoginAction.GET_OTP_CHOICES),
  //   switchMap((action) => {
  //     return this.LoginService.getOtpChoices(action.payload).pipe(
  //       map(
  //         (getOtpChoices) => new LoginAction.GetOtpChoicesSuccess(getOtpChoices)
  //       ),
  //       catchError((error) => of(new LoginAction.GetOtpChoicesFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // getTermsAndConditions$ = this.actions$.pipe(
  //   ofType<LoginAction.TermsAndConditions>(LoginAction.TERMS_AND_CONDITIONS),
  //   switchMap((action) => {
  //     return this.LoginService.getTermsAndConditions(action.payload).pipe(
  //       map(
  //         (getTermsAndConditions) =>
  //           new LoginAction.TermsAndConditionsSuccess(getTermsAndConditions)
  //       ),
  //       catchError((error) => of(new LoginAction.TermsAndConditionsFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // getMerchantTermsAndConditions$ = this.actions$.pipe(
  //   ofType<LoginAction.MerchantTermsAndConditions>(
  //     LoginAction.MERCHANT_TERMS_AND_CONDITIONS
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.getMerchantTermsAndConditions(
  //       action.payload
  //     ).pipe(
  //       map(
  //         (getMerchantTermsAndConditions) =>
  //           new LoginAction.MerchantTermsAndConditionsSuccess(
  //             getMerchantTermsAndConditions
  //           )
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.MerchantTermsAndConditionsFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // interestedToBeMerchant$ = this.actions$.pipe(
  //   ofType<LoginAction.InterestedToBeMerchant>(
  //     LoginAction.INTERESTED_TO_BE_MERCHANT
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.interestedToBeMerchant(action.payload).pipe(
  //       map(
  //         (getInterestResponse) =>
  //           new LoginAction.InterestedToBeMerchantSuccess(getInterestResponse)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.InterestedToBeMerchantFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // interestedToBeBrand$ = this.actions$.pipe(
  //   ofType<LoginAction.InterestedToBeBrand>(LoginAction.INTERESTED_TO_BE_BRAND),
  //   switchMap((action) => {
  //     return this.LoginService.interestedToBeBrand(action.payload).pipe(
  //       map(
  //         (getInterestResponse) =>
  //           new LoginAction.InterestedToBeBrandSuccess(getInterestResponse)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.InterestedToBeBrandFail(error))
  //       )
  //     );
  //   })
  // );

  // @Effect()
  // registerAsMerchant$ = this.actions$.pipe(
  //   ofType<LoginAction.RegisterAsMerchant>(LoginAction.REGISTER_AS_MERCHANT),
  //   switchMap((action) => {
  //     return this.LoginService.registerAsMerchant(action.payload).pipe(
  //       map(
  //         (getRegisterResponse) =>
  //           new LoginAction.RegisterAsMerchantSuccess(getRegisterResponse)
  //       ),
  //       catchError((error) => of(new LoginAction.RegisterAsMerchantFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // registerAsBrand$ = this.actions$.pipe(
  //   ofType<LoginAction.RegisterAsBrand>(LoginAction.REGISTER_AS_BRAND),
  //   switchMap((action) => {
  //     return this.LoginService.registerAsBrand(action.payload).pipe(
  //       map(
  //         (getRegisterResponse) =>
  //           new LoginAction.RegisterAsBrandSuccess(getRegisterResponse)
  //       ),
  //       catchError((error) => of(new LoginAction.RegisterAsBrandFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // getAllBrand$ = this.actions$.pipe(
  //   ofType<LoginAction.GetAllBrand>(LoginAction.GET_ALL_BRAND),
  //   switchMap((action) => {
  //     return this.LoginService.getAllBrand(action.payload).pipe(
  //       map(
  //         (getAllBrandResponse) =>
  //           new LoginAction.GetAllBrandSuccess(getAllBrandResponse)
  //       ),
  //       catchError((error) => of(new LoginAction.GetAllBrandFail(error)))
  //     );
  //   })
  // );
  // @Effect()
  // subscribToNewsletter$ = this.actions$.pipe(
  //   ofType<LoginAction.SubcribeToNewsLetter>(
  //     LoginAction.SUBSCRIBE_TO_NEWSLETTER
  //   ),
  //   switchMap((action) => {
  //     return this.LoginService.subscribeToNewsletter(action.payload).pipe(
  //       map(
  //         (passwordResponse) =>
  //           new LoginAction.SubcribeToNewsLetterSuccess(passwordResponse)
  //       ),
  //       catchError((error) =>
  //         of(new LoginAction.SubcribeToNewsLetterFail(error))
  //       )
  //     );
  //   })
  // );
}
