import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { environment } from 'src/environments/environment';
import { EventEmitter, Output } from '@angular/core';
import { OfficerDashboardLinksComponent } from '../officer-dashboard-links/officer-dashboard-links.component';
import { Store } from "@ngrx/store";
import * as fromStore from "../../../store/index";
import { ToastrService } from 'ngx-toastr';
import { log } from 'console';
@Component({
  selector: 'app-salary-slip',
  templateUrl: './salary-slip.component.html',
  styleUrls: ['./salary-slip.component.scss']
})
export class SalarySlipComponent implements OnInit {
  UserProfileDetails: any
  selectedMonth: any;
  // selectedYear: number = 2023; 
  pdfUrl: string = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';

  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];


  // years: number[] = Array.from({ length: new Date().getFullYear() - 1983 }, (_, i) => 1984 + i); 


  constructor(private apiCallService: ApiCallService,
    private dialog: MatDialog, private dialogRef: MatDialogRef<OfficerDashboardLinksComponent>,
    private store: Store<fromStore.CEPState>,private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.store.select(fromStore.getVerifyPasswordResponse)
      .subscribe((data) => {
        if (data) {
          this.UserProfileDetails = data;
          //console.log("suvarna",this.UserProfileDetails);
          
        }
      });
      this.getMonthsYearDropdown()
  }

  getMonthsYearDropdown() {
    this.apiCallService.apiCall('post', environment.base_url + '/user/v1/SalarySlipCtrl/getData', {
      empCode: this.UserProfileDetails.empCode
    })
    .subscribe( (res:any) => {
      //console.log("Get months",res);
       this.months=res.result.data
      
    } )
  }

  setselectedMonth(month) {
    this.selectedMonth = month
  }

  onsubmit(){
    if(this.selectedMonth){
      this.apiCallService.apiCall('post', environment.base_url + '/user/v1/SalarySlipCtrl/sendEmail', {
        empCode: this.UserProfileDetails.empCode,
        ...this.selectedMonth
      })
      .subscribe( (res:any) => {
        //console.log("Get months",res);
         if(res.code === 200){
          this.toastr.success('salary slip send to your register mail-id')
         }
        
      } )
    }else{
      this.toastr.error('Please select month.');
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

}
