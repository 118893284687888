import { Component, OnInit, ChangeDetectorRef, Input, Pipe, OnDestroy, AfterContentChecked, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { Images } from '../../config/images-config';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectInterface } from '../../models/global-interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-details-page-article',
	templateUrl: './details-page-article.component.html',
	styleUrls: ['./details-page-article.component.scss']
})
export class DetailsPageArticleComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	@Input() articleData: any;
	image = Images;
	public feedsData: ObjectInterface;
	public articleDate;
	apiDetails: ObjectInterface;
	timelineId: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoaded = false;
	isLoading = false;
	selectedLanguage: string;
	landingLables: ObjectInterface;
	feedsDate: string;
	favorite: string;
	userId: string;
	token: any;
	feedFilterLabels: ObjectInterface;
	feedLabels: ObjectInterface;
	feedStatusCode: number;
	shareUrl: any;
	feedsContent: any;
	// dynamicLink;

	constructor(
		private activeParams: ActivatedRoute,
		private apiCallService: ApiCallService,
		private store: Store<fromStore.CEPState>,
		private changeDetectRef: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private domSanitizer: DomSanitizer,
		private googleAnalyticsService: GoogleAnalyticsService,
		private router: Router,
		private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService,
		private toastr: ToastrService,
	) { }

	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(
			screenName. ARTICAL_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.ARTICAL_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.activeParams.params.subscribe((params) => {
			this.timelineId = params.timelineId;
			this.store.dispatch(new fromStore.CheckTimeline({
				timelineId:this.timelineId
			}))
			
		});

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on article by ' + this.userId, 'userLabel', 1);
				} else {
					this.userId = null;
				}
			});
			this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
				
					this.store.select(fromStore.isTimelineChnaged)
			.subscribe((isTimeline) => {
					if(isTimeline && Object.keys(this.apiDetails).length > 0){
						this.shareUrl = this.apiDetails.shareUrl;

					this.store.dispatch(new fromStore.GetEngeagementDetails({
						url: this.apiDetails.userEngagement.base_url +
							this.apiDetails.userEngagement.timeline.getEngagement.url,
						method: this.apiDetails.userEngagement.timeline.getEngagement.method,
						body: {
							timelineId: this.timelineId,
							userId: this.userId
						}
					}))
					window.scrollTo(0, 0);
				}
				})
		this.store.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoaded => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {
					this.store.select(fromStore.feedDetailsResult)
						.subscribe((feedsData) => {
							this.feedsData = feedsData;
							if (Object.keys(this.feedsData).length !== 0) {
								// this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
								// this.feedsContent = this.feedsData[this.selectedLanguage].content;
								if (this.feedsData.favourite) {
									this.favorite = 'favorite';
								} else {
									this.favorite = 'favorite_border';
								}
							}
							this.feedsDate = new Date(this.feedsData.updatedAt).toDateString();
						});
				}
			});

		this.store
			.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoading => {
				this.isLoading = isLoading;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.feedFilterLabels = labels;
					});
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on article', 'userLabel', 1);
	}
/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText;
	  };
/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	async speakText(textToSpeak, t2){
		speechSynthesis.cancel();
		var newmessage = await this.extractContent(textToSpeak)	
		var sentanceList = newmessage.match(/[^\.!\?]+[\.!\?]+/g)
		sentanceList.unshift(t2); 	
		for (let sentance = 0; sentance < sentanceList.length; sentance++) {
			var voices = speechSynthesis.getVoices();
			var message = new SpeechSynthesisUtterance(sentanceList[sentance]);
			message.voice = voices[9];
			message.lang = 'hi-IN';
			message.volume = 1;
			speechSynthesis.speak(message);
		}
	}
	/**
	 * Created by Akanksha J
	 * for reporting blog as abuse
	 */
	REPORT_ABUSE() {
			this.googleAnalyticsService.eventEmitter(screenName.ARTICAL_DETAILS, {
				log: clickedOn.REPORT_ABUSE,
				screenName: screenName.ARTICAL_DETAILS + "_SCREEN",
				SECTION: screenName.ARTICAL_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.REPORT_ABUSE,
				time: new Date(),
				isInput: false,
				contentId:this.articleData.timelineId
			  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'article',
				engagementId: this.feedsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
	unMarkFavorite() {
		if (this.feedsData.favourite) {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: false });
			this.favorite = 'favorite_border';
			this.googleAnalyticsService.eventEmitter(screenName.ARTICAL_DETAILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.ARTICAL_DETAILS + "_SCREEN",
				SECTION: screenName.ARTICAL_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.articleData.timelineId
			  })
		} else {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: true });
			this.favorite = 'favorite';
			this.googleAnalyticsService.eventEmitter(screenName.ARTICAL_DETAILS, {
				log: clickedOn.LIKE,
				screenName: screenName.ARTICAL_DETAILS + "_SCREEN",
				SECTION: screenName.ARTICAL_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.articleData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails.shareUrl;
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.feedsData.engagementId,
						engagementType: 'article'
					}
				}));
			}
		}
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {	
		var url = this.shareUrl + '/cep/feeds/article/' + this.feedsData.timelineId
		var metaTag  = {
			socialTitle: this.feedsData.en.heading,
			socialImageLink: this.feedsData.imageFavourite[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)

	}

	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.ARTICAL_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.ARTICAL_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
		// if (this.userId && this.feedsData.feedback==false) {
		// 	const dialogRef = this.dialog.open(FeedbackComponent,
		// 		{
		// 			data: {
		// 				engagementId: this.feedsData.engagementId,
		// 				engagementType: this.feedsData.engagementType
		// 			},
		// 			maxHeight: '100vh',
		// 			maxWidth: '100vw',
		// 			autoFocus: false
		// 		});
		// }
	}

	async clickOnShare() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.ARTICAL_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/article/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}	}
}
