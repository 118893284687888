import { Action } from '@ngrx/store';

export const SET_HEADER_BACKGROUND_COLOR = 'SET_HEADER_BACKGROUND_COLOR';
export const GET_API_LIST = 'GET_API_LIST';
export const GET_API_LIST_SUCCESS = 'GET_API_LIST_SUCCESS';
export const GET_API_LIST_FAIL = 'GET_API_LIST_FAIL';

export const GET_CONSTANTS_LABEL_SUCCESS = 'GET_CONSTANTS_LABEL_SUCCESS';
export const GET_CONSTANTS_LABEL_FAIL = 'GET_CONSTANTS_LABEL_FAIL';

export const GET_LABEL_ENABLE_DETAILS = 'GET_LABEL_ENABLE_DETAILS';
export const GET_LABEL_ENABLE_DETAILS_SUCCESS = 'GET_LABEL_ENABLE_DETAILS_SUCCESS';
export const GET_LABEL_ENABLE_DETAILS_FAIL = 'GET_LABEL_ENABLE_DETAILS_FAIL';

export const GET_HOT_FEEDBACK = 'GET_HOT_FEEDBACK';
export const GET_HOT_FEEDBACK_SUCCESS = 'GET_HOT_FEEDBACK_SUCCESS';
export const GET_HOT_FEEDBACK_FAIL = 'GET_HOT_FEEDBACK_FAIL';

export const GET_CHATBOT_KEY = 'GET_CHATBOT_KEY';
export const GET_CHATBOT_KEY_SUCCESS = 'GET_CHATBOT_KEY_SUCCESS';
export const GET_CHATBOT_KEY_FAIL = 'GET_CHATBOT_KEY_FAIL';

export const GET_WEATHER_STATUS = 'GET_WEATHER_STATUS';
export const GET_WEATHER_STATUS_SUCCESS = 'GET_WEATHER_STATUS_SUCCESS';
export const GET_WEATHER_STATUS_FAIL = 'GET_WEATHER_STATUS_FAIL';


export const RESET_CHATBOT_ID = 'RESET_CHATBOT_ID';

export class SetHeaderBackgroundColor implements Action {
  readonly type = SET_HEADER_BACKGROUND_COLOR;
  constructor(public payload: any) { }
}
export class GetApiRequestList implements Action {
  readonly type = GET_API_LIST;
  constructor(public payload: any) { }
}
export class GetApiRequestListSuccess implements Action {
  readonly type = GET_API_LIST_SUCCESS;
  constructor(public payload: any) { }
}
export class GetApiRequestListFail implements Action {
  readonly type = GET_API_LIST_FAIL;
  constructor(public payload: any) { }
}
export class GetConstantsLabelSuccess implements Action {
  readonly type = GET_CONSTANTS_LABEL_SUCCESS;
  constructor(public payload: any) { }
}

export class GetConstantsLabelFail implements Action {
  readonly type = GET_CONSTANTS_LABEL_FAIL;
  constructor(public payload: any) { }
}


export class GetLabelEnableDetails implements Action {
  readonly type = GET_LABEL_ENABLE_DETAILS;
  constructor(public payload: any) { }
}
export class GetLabelEnableDetailsSuccess implements Action {
  readonly type = GET_LABEL_ENABLE_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}
export class GetLabelEnableDetailsFail implements Action {
  readonly type = GET_LABEL_ENABLE_DETAILS_FAIL;
  constructor(public payload: any) { }
}

export class GetHotFeedback implements Action {
  readonly type = GET_HOT_FEEDBACK;
  constructor(public payload: any) { }
}

export class GetHotFeedbackSuccess implements Action {
  readonly type = GET_HOT_FEEDBACK_SUCCESS;
  constructor(public payload: any) { }
}
export class GetHotFeedbackFail implements Action {
  readonly type = GET_HOT_FEEDBACK_FAIL;
  constructor(public payload: any) { }
}

export class GetChatbotKey implements Action {
  readonly type = GET_CHATBOT_KEY;
  constructor(public payload: any) { }
}

export class GetChatbotKeySuccess implements Action {
  readonly type = GET_CHATBOT_KEY_SUCCESS;
  constructor(public payload: any) { }
}

export class GetChatbotKeyFail implements Action {
  readonly type = GET_CHATBOT_KEY_FAIL;
  constructor(public payload: any) { }
}

export class GetWeatherStatus implements Action {
  readonly type = GET_WEATHER_STATUS;
  constructor(public payload: any) { }
}

export class GetWeatherStatusSuccess implements Action {
  readonly type = GET_WEATHER_STATUS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetWeatherStatusFail implements Action {
  readonly type = GET_WEATHER_STATUS_FAIL;
  constructor(public payload: any) { }
}

export class ResetChatbot implements Action {
  readonly type = RESET_CHATBOT_ID;
}

export type constantsAction = SetHeaderBackgroundColor | GetApiRequestList | GetApiRequestListSuccess | GetApiRequestListFail
  | GetConstantsLabelSuccess | GetConstantsLabelFail |
  GetLabelEnableDetails | GetLabelEnableDetailsSuccess | GetLabelEnableDetailsFail |
  GetHotFeedback | GetHotFeedbackSuccess | GetHotFeedbackFail | GetChatbotKey | GetChatbotKeySuccess | GetChatbotKeyFail
  | GetWeatherStatus | GetWeatherStatusSuccess | GetWeatherStatusFail | ResetChatbot;
