import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Images } from '../../../config/images-config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../cep/store/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-merchant-confirmation-popup',
	templateUrl: './merchant-confirmation-popup.component.html',
	styleUrls: ['./merchant-confirmation-popup.component.scss']
})
export class MerchantConfirmationPopupComponent implements OnInit {
	images = Images; 
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	token: string;
	userId: any;
	isMerchantLoading = false;
	isBrandLoading = false;
	apiDetails: {};
	chooseOption:string;
	option = new FormControl('', [Validators.required]);
	merchantAndBrandLabels: {};
	selectedLanguage: string;
	selectedOption: string;
	isBlind;
	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		public dialog: MatDialog,
		private toastr: ToastrService,
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private dialogRef: MatDialogRef<MerchantConfirmationPopupComponent>,
		private router: Router
	) {
		dialogRef.disableClose = true;
	}

	ngOnInit() {
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				}
			});

		this.store.select(fromStore.getIsInterestedToBeMerchantLoading)
			.subscribe(loading => {
				this.isMerchantLoading = loading;
			});

		this.store.select(fromStore.getIsInterestedToBeBrandLoading)
			.subscribe(loading => {
				this.isBrandLoading = loading;
			});

			this.store.select(fromStore.getSelectedLanguage)
			.subscribe(language => {
				this.selectedLanguage = language;
			});
			this.store.select(fromStore.getMerchantAndBrandLabels)
			.subscribe(labels => {
				this.merchantAndBrandLabels = labels;
			
			});
		
	}


/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }

	/**
	 * Created by Akanksha J
	 * For redirecting to Merchant Form
	 * Date: 22/01/2020
	 */
	redirectToMerchantForm() {
		
		
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					// this.store.dispatch(new fromStore.InterestedToBeMerchant({
					//   url: this.apiDetails['info'].base_url +
					//     this.apiDetails['info'].getWeatherInfo.url,
					//   method: this.apiDetails['info'].getWeatherInfo.method,
					// }));
					if (this.chooseOption === 'merchant-registration') {
						this.store.dispatch(new fromStore.InterestedToBeMerchant({
							// url: 'http://115.124.111.77:8001/v1/MerchantCtrl/isIntrestedForMerchant',
							// method: 'post',

							url: this.apiDetails['user'].base_url +
										this.apiDetails['user'].isIntrestedForMerchant.url,
							method: this.apiDetails['user'].isIntrestedForMerchant.method,
							body: {
								userId: this.userId
							}
						}));
						this.store.select(fromStore.getIsInterestedToBeMerchantLoaded)
						.subscribe(res => {
						if (res) {
							this.store.select(fromStore.getInterestedToBeMerchantStatusCode)
							.subscribe(statusCode => {
							if (statusCode === 200 || 1009) {
								this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].readyToBeacomeAMerchant);
								// this.router.navigate(['/cep/feeds/merchant-registration/new']);
								window.location.href = 'cep/feeds/merchant-registration/new'
								this.dialog.closeAll();
							} else {
								return;
							}
						});
					}
					});
					} else if (this.chooseOption === 'brand-registration') {
						this.store.dispatch(new fromStore.InterestedToBeBrand({
							url: this.apiDetails['user'].base_url +
										this.apiDetails['user'].isIntrestedForBrand.url,
							method: this.apiDetails['user'].isIntrestedForBrand.method,
							body: {
								userId: this.userId
							}
						}));
						this.store.select(fromStore.getIsInterestedToBeBrandLoaded)
						.subscribe(res => {
							if (res) {
							this.store.select(fromStore.getInterestedToBeBrandStatusCode)
							.subscribe(statusCode => {
							if (statusCode === 200 || 1009) {
								this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].readyToBeacomeABrand);
								this.router.navigate(['/cep/feeds/', this.chooseOption]);
								this.dialog.closeAll();
							} else {
								return;
							}
						});
				}
			});
					}
				}
			});
	}

	/**
	 * Created by Akanksha J
	 * For Closing the confirmation popup
	 * Date: 22/01/2020
	 */
	closeDialog() {
		this.dialog.closeAll();
	}

}
