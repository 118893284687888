import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, Input } from '@angular/core';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/localstorage.service';
import { Images } from '../../config/images-config';
import { Router } from '@angular/router';
import { EncrDecrService } from '../../services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
@Component({
	selector: 'app-card-survey',
	templateUrl: './card-survey.component.html',
	styleUrls: ['./card-survey.component.scss']
})
export class CardSurveyComponent implements OnInit, AfterContentChecked {
	selectedLanguage: string;
	landingLables: {};
	newsDate: string;
	@Input() surveyData: any;
	image = Images;
	feedsLabel: {};
	surveyLink;
	userId: any;
	token: string;
	apiDetails: {};
	shareUrl: any;
	favorite: string;
	isBlind;
	favbool:boolean;

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		private router: Router,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private apiCallService: ApiCallService,
		private dialog: MatDialog,
		private googleAnalyticsService: GoogleAnalyticsService) { }

	ngOnInit() {

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedsLabel = labels;
					});
			});

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

		if (this.surveyData) {
			if (this.surveyData.fav.filter((item) => item === this.userId).length > 0) {
				this.favorite = 'favorite';
				this.favbool = true;
			} else {
				this.favorite = 'favorite_border';
				this.favbool = false;
			}
		}

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});


		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}



	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	onClick() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.SURVEY_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.surveyData.timelineId
		  })
		// let user = this.localStorageService.getItem("userId")
		// if (user === null) {
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// } else {
		let user = this.localStorageService.getItem("userId")
		if (user === null) {
			this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
			const dialogRef = this.dialog.open(LoginpopupComponent);
			// this.router.navigate(["/Login/login"])
		} else {
			this.openSurvey()
		}
	}

	/**
	 * Created by Akanksha J
	 * for reporting survey as abuse
	 */
	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.surveyData.timelineId,
				engagementType: 'survey',
				engagementId: this.surveyData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}


	unMarkFavorite() {
		if (this.favbool) {
			this.surveyData = Object.assign({}, this.surveyData, { favourite: false });
			this.favorite = 'favorite_border';
			this.favbool = false;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.SURVEY_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.surveyData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.surveyData = Object.assign({}, this.surveyData, { favourite: true });
			this.favorite = 'favorite';
			this.favbool = true;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.SURVEY_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.surveyData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.apiDetails) {
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavorite({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavourite.url,
						method: this.apiDetails['user'].addToFavourite.method,
						body: {
							userId: this.userId,
							engagementId: this.surveyData.engagementId,
							engagementType: 'survey'
						}
					}));
				}
			}
		}
	}


	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + '/cep/feeds/survey/' + this.surveyData.timelineId
		var metaTag = {
			socialTitle: this.surveyData.en.heading,
			socialImageLink: ''
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	/**
	 * Angular method which runs on any content change
	 */
	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.newsDate = new Date(this.surveyData.updatedAt).toDateString();
	}
	clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of blog', 'Click Event', 1);
	}

	/**
	 * Craeted by Ajay C
	 * For Opening survey in Iframe
	 *
	 * Updated by Akanksha J
	 * Updated date: 25/03/2020
	 */
	openSurvey() {
		if (this.userId) {
			if (this.surveyData.isCorona === true) {
				this.router.navigate(['/cep/feeds/survey/' + this.surveyData.timelineId + '/' + this.surveyData.isCorona]);
			} else {
				this.router.navigate(['/cep/feeds/survey/' + this.surveyData.timelineId]);
			}
		} else {
			this.router.navigate(['/Login/login']);
		}
	}
}
