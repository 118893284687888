import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './cep/store/reducers.index';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects } from './cep/store';
import { AgmCoreModule } from '@agm/core';
import { API_KEY } from './config/api-key.config';
import { NgOtpInputModule } from 'ng-otp-input';
// import { CeiboShare } from 'ng2-social-share';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FacebookModule } from 'ngx-facebook';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { CacheInterceptor } from './cache.interceptor';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule } from '@angular/forms';
import { NgxUiLoaderHttpModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './shared/services/messaging-service';
import { AsyncPipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DownloadFormComponent } from './cep/side-menu-bar/SGY/download-form/download-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { PartialPropertyTaxPay } from './shared/components/partial-ptax-pay/partial-ptax-pay.component';
import { EnterOtpComponent } from './login/enter-otp/enter-otp.component';

// const messaging  = getMessaging(appConfig);

const ngxUiLoderConfig: NgxUiLoaderConfig = {
	bgsColor:'rgba(12,80,219,0.98)',
	bgsOpacity: 1,
	bgsSize: 40,
	bgsType: "ball-spin-clockwise",
	fgsColor: 'rgba(12,80,219,0.98)',
	fgsPosition: "center-center"
}

@NgModule({
	declarations: [
		AppComponent,
		DownloadFormComponent,	
		// EnterOtpComponent
	],
	
	imports: [
		NgxPageScrollCoreModule,
		BrowserModule,
		NgOtpInputModule,
		FormsModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		MatSnackBarModule,
		JwSocialButtonsModule,
		// HttpClientModule,
		StoreModule.forFeature('cep', reducers),
		FacebookModule.forRoot(),
		EffectsModule.forRoot(effects),
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				// strictStateImmutability: true,
				// strictActionImmutability: true
			}
		}),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
		AgmCoreModule.forRoot({
			apiKey: API_KEY.GOOGLE_MAP
		}),
		NgbModule,
		// AngularFontAwesomeModule
		NgxUiLoaderModule,
		NgxUiLoaderHttpModule.forRoot({
			showForeground: true,
			exclude:[environment.base_url + "/user/v1/GroupCtrl/getGroupDetails",environment.base_url + "/user/v1/MessageCtrl/createMessage",environment.base_url + "/user/v1/GroupCtrl/getAllUserGroups", environment.base_url + "/authenticationConfiguration/v1/checkAuthUser"]
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireMessagingModule,
		MatCheckboxModule,
		MatMenuModule,
        MatButtonModule,
		MatDialogModule
	],
	providers: [
		// { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
		MessagingService,AsyncPipe
	],
	bootstrap: [AppComponent],
	entryComponents:[PartialPropertyTaxPay]
})

export class AppModule { }
