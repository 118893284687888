import { Component, OnInit, OnChanges, AfterViewInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { IntroductionPopupComponent } from "../shared/components/introduction-popup/introduction-popup.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { EncrDecrService } from "../shared/services/encr-decr.service";
import { LocalStorageService } from "../shared/services/localstorage.service";
import { ToastrService } from "ngx-toastr";
import * as fromStore from "./store/index";
import { Store } from "@ngrx/store";
import { EncrDecrUtil } from "../util/EncrDecr";
import { LogoutService } from "../cep/dashboard-management/store/api-services/logout.service";
import { MobileDevicePopupComponent } from "../shared/components/mobile-device-popup/mobile-device-popup.component";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-cep",
  templateUrl: "./cep.component.html",
  styleUrls: ["./cep.component.scss"],
})
export class CepComponent implements OnInit, AfterViewInit, OnChanges {
  url: string;
  state: string = "default";
  apiDetails = {};

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromStore.CEPState>,
    private deviceService: DeviceDetectorService,
    private localStorage: LocalStorageService,
    private EncrDecr: EncrDecrService,
    private toastr: ToastrService,
    private logout: LogoutService
  ) {
    // Subscribe to router events
  
    router.events.subscribe(async (val) => {
      // window.scrollTo(0,0)
      if(this.localStorage.getItem("userId") !== null){
        const lastUpdateRouteTimeEncrypted =
        this.localStorage.getItem("uBBqiCLG0Q");
        let lastRouteUpdatedTime = 0;

      if (
        lastUpdateRouteTimeEncrypted === undefined ||
        lastUpdateRouteTimeEncrypted === null
      ) {
        lastRouteUpdatedTime = new Date().getTime();
        this.localStorage.setItem(
          "uBBqiCLG0Q",
          lastRouteUpdatedTime.toString()
        );
      } else {
        lastRouteUpdatedTime = parseInt(
          this.EncrDecr.get(lastUpdateRouteTimeEncrypted)
        );
      }

      // if it is 45 minutes past lastRouteUpdatedTime then logout the user, else save a new time
      const timeGapSinceLastRouteChange =
        new Date().getTime() - lastRouteUpdatedTime;
        // console.log("userID",this.localStorage.getItem("userId"))
        // console.log("New Event Triggered",)

      if (timeGapSinceLastRouteChange > 45*60*1000) {
        // Session Expiry Code
        this.logout_prenup();

      } else {
        this.localStorage.setItem(
          "uBBqiCLG0Q",
          EncrDecr.set(new Date().getTime().toString())
        );
      }
      }
    });
  }

  logout_prenup = async () => {
    const encrDecr = new EncrDecrUtil();


    this.store.select(fromStore.getApiCallingList).subscribe((apiDetails) => {
      this.apiDetails = apiDetails;

      
      if (Object.keys(this.apiDetails).length > 0) {
        this.store.dispatch(
          new fromStore.Logout({
            url: environment.base_url + "/user/v1/logOut",
            method: "post",
            body: {
              userId: encrDecr.get(this.localStorage.getItem("userId")),
            },
          })
        );
      }
    });

    await this.store.dispatch(new fromStore.ResetMobileNumber());
    await this.store.dispatch(new fromStore.ResetLogin());
    await this.store.dispatch(new fromStore.ResetUpdateProfile());
    await this.store.dispatch(new fromStore.ResetChangeMobileNumber());
    await this.store.dispatch(new fromStore.ResetForgetPassword());
    await this.store.dispatch(new fromStore.ResetUpdatePassword());
    await this.store.dispatch(new fromStore.ResetFeeds());
    await this.store.dispatch(new fromStore.ResetChatbot());
    await this.store.dispatch(new fromStore.ResetFavourite({}));
    this.toastr.error("You have been inactive for a while. You have been logged out")
    console.error("Session Expired");
    this.localStorage.clearLocalStorage();
    await this.router.navigate(["/cep/home"]);
  };

  /**
   * angular method which gets called on page initialization
   */
  ngOnInit() {
  
    this.dialog.closeAll();
    // let user = this.localStorage.getItem("userId")

    // if (user === null) {
    //   this.router.navigate(["/Login/login"]);
    //   console.error("Session Expired");
    //   this.localStorage.clearLocalStorage();
    // }
  }
  ngAfterViewInit() {
    if(this.deviceService.isMobile() && !window.location.pathname.includes('/cep/ofiice-zone') ){
    // this.dialog.open(MobileDevicePopupComponent);
    }
  }

  ngOnChanges() {
    this.url = this.router.url;
  }
}
