/**********************************
 * Component -  shared fav web component
 * Created By - Neha R
 * Updated By - Neha R
 * Updated On - 27/07/2020
 **********************************/
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';


@Component({
  selector: 'app-shared-fav-webinar-card',
  templateUrl: './shared-fav-webinar-card.component.html',
  styleUrls: ['./shared-fav-webinar-card.component.scss']
})
export class SharedFavWebinarCardComponent implements OnInit {
  @Input() webinarData: any;
  blogDate;
  image = Images;
  selectedLanguage: string;
  landingLables: {};
  favorite: string;
  apiDetails: {};
  feedsDate: Date;
  todaysDate: Date;
  dateDiff: number;
  userId: string;
  getUserId: string;
  token: string;
  articleData: any;
  shareUrl: any;
	feedLabels: {};

	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
  constructor(
    private dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private apiCallService: ApiCallService,
		private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    if (this.webinarData) {
			if (this.webinarData.favourite) {
				this.favorite = 'favorite';
			} else {
				this.favorite = 'favorite_border';
			}
		}
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
				this.store.select(fromStore.getLandingScreenLabels)
				.subscribe((labels) => {
					this.feedLabels = labels;
				});
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});
	}

/**
	 * Craeted by Ajay C
	 * For unmarking the favourites
	 */
	unMarkFavorite() {
		if (this.webinarData.favourite) {
			this.webinarData = Object.assign({}, this.webinarData, { favourite: false });
			this.favorite = 'favorite_border';
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.webinarData = Object.assign({}, this.webinarData, { favourite: true });
			this.favorite = 'favorite';
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.apiDetails) {
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavoriteWebinar({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavoriteWebinar.url,
						method: this.apiDetails['user'].addToFavoriteWebinar.method,
						body: {
							userId: this.userId,
							webinarId: this.webinarData.webinarId,
						}
					}));
				}
			}
		}
	}

/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url =  this.shareUrl+ '/cep/feeds/webinar/'+ this.webinarData.webinarId
		var metaTag  = {
			socialTitle: this.webinarData.en.title,
			socialImageLink: this.webinarData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	} 



	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.blogDate = new Date(this.webinarData.updatedAt).toDateString();
	}


	clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of product', 'Click Event', 1);
	}

}
