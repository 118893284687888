import * as fromSearch from '../../store/action/search.action';

export interface SearchState {
  searchList: {}[];
  isLoading: boolean;
  isLoaded: boolean;
  errorMessage: string;
  searchText: string;

  autoSearchList: {}[];
  isAutoSearchLoading: boolean;
  isAutoSearchLoaded: boolean;
  autoSearchErrorMessage: string;
  

  userId: string;
  isSearch: boolean;
  serverError: string;
}

export const initialState: SearchState = {
  searchList: [],
  isLoading: false,
  isLoaded: false,
  errorMessage: null,
  searchText: null,

  autoSearchList: [],
  isAutoSearchLoading: false,
  isAutoSearchLoaded: false,
  autoSearchErrorMessage: null,


  userId: null,
  isSearch: false,
  serverError: null
};

export function reducer(
  state = initialState,
  action: fromSearch.searchAction
): SearchState {
  switch (action.type) {
    case fromSearch.SEARCH_DATA: {

      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }
    case fromSearch.SEARCH_DATA_SUCCESS: {
      let statusCode = action.payload.code;
      let searchList = state.searchList;
      let isLoading = state.isLoading
      let isLoaded = state.isLoaded;
      let errorMessage = state.errorMessage;
      let data;
      switch (statusCode) {
        case 200:
          data = searchList;
          searchList =action.payload.result;
          isLoading = false
          isLoaded = true;
          break;

        case 500:
          isLoading = false
          isLoaded = true;
          searchList = [];
          errorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        searchList,
        isLoading,
        isLoaded,
        errorMessage
      };
    }
    case fromSearch.SEARCH_DATA_FAIL: {
      let statusCode = action.payload.status;
      let serverError = state.serverError;
      if (statusCode == 401 || statusCode == 0) {
        serverError = action.payload.statusText;
      }
      else {
        serverError = action.payload.error.message;
      }
      return {
        ...state,
        serverError
      };
    }

    case fromSearch.AUTO_SEARCH_DATA: {

      return {
        ...state,
        isAutoSearchLoading: true,
        isAutoSearchLoaded: false
      };
    }
    case fromSearch.AUTO_SEARCH_DATA_SUCCESS: {
      let statusCode = action.payload.code;
      let autoSearchList = state.autoSearchList;
      let isAutoSearchLoading = state.isAutoSearchLoading
      let isAutoSearchLoaded = state.isAutoSearchLoaded;
      let autoSearchErrorMessage = state.autoSearchErrorMessage;
      let data;
      switch (statusCode) {
        case 200:
          data = autoSearchList;
          autoSearchList =action.payload.result;
          isAutoSearchLoading = false
          isAutoSearchLoaded = true;
          break;

        case 500:
          isAutoSearchLoading = false
          isAutoSearchLoaded = true;
          autoSearchList = [];
          // autoSearchErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        autoSearchList,
        isAutoSearchLoading,
        isAutoSearchLoaded,
        autoSearchErrorMessage
      };
    }
    case fromSearch.AUTO_SEARCH_DATA_FAIL: {
      let statusCode = action.payload.status;
      let serverError = state.serverError;
      if (statusCode == 401 || statusCode == 0) {
        serverError = action.payload.statusText;
      }
      else {
        // serverError = action.payload.error.message;
      }
      return {
        ...state,
        serverError
      };
    }

    case fromSearch.SEARCH_ROUTE: {
      let isSearch = action.payload.isSearch;
      let searchText = action.payload.searchText;
      let userId = action.payload.userId;
      return {
        ...state,
        isSearch,
        searchText,
        userId
      };
    }
    case fromSearch.RESET_SERACH: {

      return {
        ...state,
        searchList: [],
        isLoading: false,
        isLoaded: false,
        errorMessage: null

      };
    }
  }
  return state;
}

export const getSearchResult = (state: SearchState) => state.searchList;
export const getSearchLoading = (state: SearchState) => state.isLoading;
export const getSearchLoaded = (state: SearchState) => state.isLoaded;
export const getErrorMessage = (state: SearchState) => state.errorMessage;

export const getAutoSearchResult = (state: SearchState) => state.autoSearchList;
export const getAutoSearchLoading = (state: SearchState) => state.isAutoSearchLoading;
export const getAutoSearchLoaded = (state: SearchState) => state.isAutoSearchLoaded;
export const getAutoErrorMessage = (state: SearchState) => state.autoSearchErrorMessage;

export const getIsSearch = (state: SearchState) => state.isSearch;
export const getSearchText = (state: SearchState) => state.searchText;
export const getUserIdFromSearch = (state: SearchState) => state.userId;
export const getServerError = (state: SearchState) => state.serverError;
