import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(
    private http: HttpClient,
    private apiCallService: ApiCallService
    ) { }
    getServices(request) {
      let method = request.method;
      let url = request.url;
      return this.apiCallService.apiCall(method, url, null);
    }
    getGrievanceListing(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    addGrievance(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    grievanceDetail(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    grievanceVote(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    grievanceCategory(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    vaccinationCenterList(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
    fetchStrapiContentListApi(request) {
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body);
    }
}





