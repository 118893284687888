import { Action } from '@ngrx/store';

export const  GET_AROUND_ME = 'GET_AROUND_ME';
export const  GET_AROUND_ME_SUCCESS = 'GET_AROUND_ME_SUCCESS';
export const  GET_AROUND_ME_FAIL = 'GET_AROUND_ME_FAIL';
export const  GET_AROUND_ME_CATEGORY = 'GET_AROUND_ME_CATEGORY';
export const  GET_AROUND_ME_CATEGORY_SUCCESS = 'GET_AROUND_ME_CATEGORY_SUCCESS';
export const  GET_AROUND_ME_CATEGORY_FAIL = 'GET_AROUND_ME_CATEGORY_FAIL';

export class GetAroundMeOnMap implements Action {
	readonly type = GET_AROUND_ME;
	constructor(public payload: any) { }
}
export class GetAroundMeOnMapSuccess implements Action {
	readonly type = GET_AROUND_ME_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAroundMeOnMapFail implements Action {
	readonly type = GET_AROUND_ME_FAIL;
	constructor(public payload: any) { }
}

export class GetAroundMeCategory implements Action {
	readonly type = GET_AROUND_ME_CATEGORY;
	constructor(public payload: any) { }
}
export class GetAroundMeCategorySuccess implements Action {
	readonly type = GET_AROUND_ME_CATEGORY_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAroundMeCategoryFail implements Action {
	readonly type = GET_AROUND_ME_CATEGORY_FAIL;
	constructor(public payload: any) { }
}

export type aroundMeAction = GetAroundMeOnMap | GetAroundMeOnMapSuccess | GetAroundMeOnMapFail
                             | GetAroundMeCategory | GetAroundMeCategorySuccess | GetAroundMeCategoryFail;