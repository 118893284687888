
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})
export class AboutUsService {

	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) {}

	getAboutUsDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

	getCommissionerDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

	getCityDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

	getTourismDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

	getZoneDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

	getCorporatesDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

}
