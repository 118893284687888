import * as fromLogout from "../action/logout.action";

export interface LogoutState {}

export const initialState: LogoutState = {};

export function reducer(
  state = initialState,
  action: fromLogout.LogoutAction
): LogoutState {
  switch(action.type){
    case fromLogout.LOGOUT: {
      
      return {...state}
    }
    case fromLogout.LOGOUT_FAIL: {
      
      return {...state}
    }
    case fromLogout.LOGOUT_SUCCESS: {
    
      return {...state}
    }
    default: {
    
      return {...state}
    }
  }

}
