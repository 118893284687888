/*********************************************
 * Created by: Shital W
 * Created Date:
 * Updated by: Akanksha J
 * Updated Date: 29/01/2020
 * Component: Select preference component
*****************************************************/
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
	selector: 'app-select-prefernces',
	templateUrl: './select-prefernces.component.html',
	styleUrls: ['./select-prefernces.component.scss']
})
export class SelectPreferncesComponent implements OnInit {
	/**
	 * spinner loading status
	 */
	loaded = false;
	/**
	 * to detect the change in preference selection
	 */
	isDisable = false;
	/**
	 * variable sets to true when API called
	 */
	apiCalled = false;
	/**
	 * to fetch selected language variable
	 */
	selectedLanguage: string;
	/**
	 * preference array
	 */
	preferences = [];
	/**
	 * update labels response object
	 */
	updatelabels: {};
	/**
	 * user profile details response object
	 */
	UserProfileDetails: any;
	/**
	 * single preference from user details
	 */
	updatePreferences: any;
	/**
	 * selected preferences list
	 */
	updatePreferencesList: any;
	/**
	 * preferences updated array
	 */
	preferencesUpadated: any[];
	/**
	 * preferences list
	 */
	preferenceList: any;
	/**
	 * preferences from user details
	 */
	preferencesValuelist: any[];
	/**
	 * preferences labels response object
	 */
	userPreferenceList = [];
	/**
	 * API details response object
	 */
	apiDetails = {};
	/** 
	 * registration page labels
	 */
	registerLabels: {};

	/**
	 * Spinner status
	 */
	isLoading = false;
	/**
	 * spinner mode
	 */
	mode = 'indeterminate';
	/**
	 * spinner value
	 */
	value = 50;
	/**
	 * mobile number from store
	 */
	mobileNumber: any;

	/**
	 * Angular methos which runs first in this component
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private router: Router,
		private titleService: Title
	) { }

	/**
	 * Angular lifecycle methos which runs on page initialization
	 */
	ngOnInit() {
		this.titleService.setTitle('PMC CARE: Select Prefrences');
		// profile screen labels
		this.store.select(fromStore.getprofileScreenLabels)
			.subscribe((labels) => {
				this.updatelabels = labels;
			});

		this.store.select(fromStore.getRegisterScreenLabels)
			.subscribe((labels) => {
				this.registerLabels = labels;
			});

		// get all api call list
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getUpdateProfileLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});

		this.store.select(fromStore.getUpdateProfileLoaded)
			.subscribe((isLoaded) => {
				if (isLoaded) {
					this.store.select(fromStore.getUpdateProfileStatusCode)
						.subscribe((statusCode) => {
							if (statusCode === 200) {
								if (window.location.pathname !== '/cep/home') {
									this.isLoading = true;
								} else {
									this.isLoading = false;
									this.dialog.closeAll();
								}
							} else {
								return;
							}
						});
				}

			});

		// Get user responce of the after registration
		this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {
				this.UserProfileDetails = response;
				this.preferences = this.UserProfileDetails.preferences;
				this.preferencesValuelist = this.preferences;
				this.store.select(fromStore.getSelectedLanguage)
					.subscribe((language) => {
						this.selectedLanguage = language;

						this.store.select(fromStore.getPreferncesList)
							.subscribe((labels) => {
								this.preferenceList = labels;
								this.userPreferenceList = [];
								this.preferences = this.UserProfileDetails.preferences;
								if (this.preferences && this.preferences.length === 0) {
									this.isDisable = true;
									Object.entries(this.preferenceList[this.selectedLanguage]).forEach((element) => {
										this.userPreferenceList.push({
											status: false,
											choosePreference: element
										});
										this.preferencesValuelist = this.userPreferenceList;
									});
								}
							});

						if (this.preferences && this.preferences.length > 0) {
							this.isDisable = false;
							Object.entries(this.preferenceList[this.selectedLanguage]).forEach((element) => {
								this.userPreferenceList.push({
									status: false,
									choosePreference: element
								});
								this.preferencesValuelist = this.userPreferenceList;
							});

							this.preferences.forEach((selectedPreference) => {
								this.preferencesValuelist.forEach((el, i) => {
									if (this.preferencesValuelist[i].choosePreference[0] === selectedPreference) {
										this.preferencesValuelist[i].status = true;
									}
								});
							});
						}
					});
			});

	}

	/**
	 * Created by Shital W
	 * For changing prefernces on click
	 */
	changePreferenceChoice(preferenceStatus, index) {
		// //this.googleAnalyticsService.eventEmitter('My Profile', 'Click on Preferences', 'Click Event', 1);
		this.userPreferenceList[index].status = !(preferenceStatus.status);
		const updatePreferencesList = [];
		this.preferencesValuelist.forEach(element => {
			this.updatePreferences = element;
			if (this.updatePreferences.status === true) {
				this.updatePreferences = this.updatePreferences.choosePreference[0];
				updatePreferencesList.push(this.updatePreferences);
			}
		});

		this.preferencesUpadated = updatePreferencesList;
		if (this.preferencesUpadated && this.preferencesUpadated.length > 0) {
			this.isDisable = false;
		} else if (this.preferencesUpadated && this.preferencesUpadated.length === 0) {
			this.isDisable = true;
		}
	}

	/**
	 * Created by Shital W
	 * For updating user preferences(API call)
	 */
	PreferenceUpdate() {
		if (!this.isDisable) {
			this.store.dispatch(new fromStore.UpdateProfileDetails({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].updateUser.url,
				method: this.apiDetails['user'].updateUser.method,
				body: {
					userId: this.UserProfileDetails.userId,
					firstName: this.UserProfileDetails.firstName,
					middleName: this.UserProfileDetails.middleName,
					lastName: this.UserProfileDetails.lastName,
					gender: this.UserProfileDetails.gender,
					DOB: this.UserProfileDetails.DOB,
					mobile: this.UserProfileDetails.mobile,
					city: this.UserProfileDetails.city,
					zone: this.UserProfileDetails.zone,
					area: this.UserProfileDetails.area,
					emailId: this.UserProfileDetails.emailId,
					address: this.UserProfileDetails.address,
					isEmail: this.UserProfileDetails.isEmail,
					isSMS: this.UserProfileDetails.isSms,
					isNotification: this.UserProfileDetails.isNotification,
					preferences: this.preferencesUpadated
				}
			}));
			this.apiCalled = true;
			this.router.navigate(['/cep/home']);
		}
	}
	goToFeed(){
		this.router.navigate(['/cep/home']);
	}

}
