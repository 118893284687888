/******
 * Component -  notification-block component 
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */
import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';

@Component({
 selector: 'app-card-notifications',
 templateUrl: './card-notifications.component.html',
 styleUrls: ['./card-notifications.component.scss']
})
export class CardNotificationsComponent implements OnInit, AfterContentChecked {
  token: string;
  userData: {};
  registerCode: any;
  isLogin = false;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
 constructor(private store: Store<fromStore.CEPState>,
  private localStorageService: LocalStorageService,
  private encrDecr: EncrDecrService,
  private ref:ChangeDetectorRef) { }

 ngOnInit() {
//   this.store.select(fromStore.getToken)
//   .subscribe((token) => {
//     this.token = token;
//     if(this.token){
//       this.localStorageService.setToken(this.userData['token']);
//     }
//   });
// let isToken = this.localStorageService.getToken();
// if(isToken){
//   this.token = this.encrDecr.get(isToken);
//   if(this.token){
//     if (this.token) {
//       this.isLogin = true;
//     } else{
//       this.isLogin = false;
//     }
//   }
// }

this.store.select(fromStore.getToken)
			.subscribe((token) => {
        this.token = token;
        if(this.token){
            this.isLogin = true;
          } else{
            this.isLogin = false;
        }
      });
 
 }
ngAfterContentChecked(){
  this.ref.detectChanges();
}
}
