import { Action } from '@ngrx/store';

export const GET_FAVORITE_DETAILS = 'GET_FAVORITE_DETAILS';
export const GET_FAVORITE_DETAILS_SUCCESS = 'GET_FAVORITE_DETAILS_SUCCESS';
export const GET_FAVORITE_DETAILS_FAIL = 'GET_FAVORITE_DETAILS_FAIL';
export const MARK_FAVORITE = 'MARK_FAVORITE';
export const MARK_FAVORITE_SUCCESS = 'MARK_FAVORITE_SUCCESS';
export const MARK_FAVORITE_FAIL = 'MARK_FAVORITE_FAIL';
export const MARK_FAVORITE_WEBINAR = 'MARK_FAVORITE_WEBINAR';
export const MARK_FAVORITE_WEBINAR_SUCCESS = 'MARK_FAVORITE_WEBINAR_SUCCESS';
export const MARK_FAVORITE_WEBINAR_FAIL = 'MARK_FAVORITE_WEBINAR_FAIL';

export class GetFavoriteDetails implements Action {
		readonly type = GET_FAVORITE_DETAILS;
	constructor(public payload: any) {}
}

export class GetFavoriteDetailsSuccess implements Action {
		readonly type = GET_FAVORITE_DETAILS_SUCCESS;
	constructor(public payload: any) {}
}

export class GetFavoriteDetailsFail implements Action {
		readonly type = GET_FAVORITE_DETAILS_FAIL;
	constructor(public payload: any) {}
}
export class MarkFavorite implements Action {
  readonly type = MARK_FAVORITE;
constructor(public payload: any) {}
}

export class MarkFavoriteSuccess implements Action {
  readonly type = MARK_FAVORITE_SUCCESS;
constructor(public payload: any) {}
}

export class MarkFavoriteFail implements Action {
  readonly type = MARK_FAVORITE_FAIL;
constructor(public payload: any) {}
}

export class MarkFavoriteWebinar implements Action {

	readonly type = MARK_FAVORITE_WEBINAR;
  constructor(public payload: any) {}
  }
  
  export class MarkFavoriteWebinarSuccess implements Action {
	readonly type = MARK_FAVORITE_WEBINAR_SUCCESS;
  constructor(public payload: any) {}
  }
  
  export class MarkFavoriteWebinarFail implements Action {
	readonly type = MARK_FAVORITE_WEBINAR_FAIL;
  constructor(public payload: any) {}
  }



export type favoriteAction = GetFavoriteDetails | GetFavoriteDetailsSuccess | GetFavoriteDetailsFail 
							  | MarkFavorite | MarkFavoriteSuccess | MarkFavoriteFail
							  | MarkFavoriteWebinar | MarkFavoriteWebinarSuccess | MarkFavoriteWebinarFail;
