/**********************************
 * Component -  blog component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 **********************************/
import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router } from '@angular/router';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from '../../services/clipboard.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';

import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-card-blog',
	templateUrl: './card-blog.component.html',
	styleUrls: ['./card-blog.component.scss']
})
export class CardBlogComponent implements OnInit, AfterContentChecked {
	@Input() blogData: any;
	blogDate;
	image = Images;
	selectedLanguage: string;
	landingLables: {};
	favorite: string;
	apiDetails: {};
	feedsDate: Date;
	todaysDate: Date;
	dateDiff: number;
	userId: string;
	getUserId: string;
	token: string;
	articleData: any;
	shareUrl: any;
	isBlind;
	favbool: boolean;
	dynamicLinks: any;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
	constructor(
		private dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private clipboardService: ClipboardService,
		private router: Router,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private googleAnalyticsService: GoogleAnalyticsService) { }

	ngOnInit() {

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

		if (this.blogData) {
			if (this.blogData.fav.filter((item) => item === this.userId).length > 0) {
				this.favorite = 'favorite';
				this.favbool = true;
			} else {
				this.favorite = 'favorite_border';
				this.favbool = false;
			}
		}

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}


	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	/**
	 * Created by Akanksha J
	 * for reporting blog as abuse
	 */
	onClick() {

		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.BLOG_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.blogData.timelineId
		  })
		// let user = this.localStorageService.getItem("userId")
		// if(user === null){
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// }else{
	
		this.router.navigate(['/cep/feeds/blog/', this.blogData.timelineId, '/feeds'])
		// }
	}

	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.blogData.timelineId,
				engagementType: 'blog',
				engagementId: this.blogData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	/**
	 * Craeted by Ajay C
	 * For unmarking the favourites
	 */
	unMarkFavorite() {
		
		if (this.favbool) {
			this.blogData = Object.assign({}, this.blogData, { favourite: false });
			this.favorite = 'favorite_border';
			this.favbool = false;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.BLOG_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.blogData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.blogData = Object.assign({}, this.blogData, { favourite: true });
			this.favorite = 'favorite';
			this.favbool = true;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.BLOG_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.blogData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.apiDetails) {
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavorite({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavourite.url,
						method: this.apiDetails['user'].addToFavourite.method,
						body: {
							userId: this.userId,
							engagementId: this.blogData.engagementId,
							engagementType: 'blog'
						}
					}));
				}
			}
		}
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	// generateShareLink(media) {
	// 	let url = this.shareUrl + "/cep/feeds/blog/" + this.blogData.timelineId
	// 	var metaTag  = {
	// 		socialTitle: this.blogData.en.heading,
	// 		socialImageLink: this.blogData.image[0]
	// 	}
	// 	this.apiCallService.configFirebaseLink(url, metaTag, media)
	// }



	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.blogDate = new Date(this.blogData.updatedAt).toDateString();
	}


	async clickOnShare() {
		// //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of blog', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.BLOG_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.blogData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/blog/" + this.blogData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}


}
