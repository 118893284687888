import {
	ActionReducer,
	ActionReducerMap,
	createFeatureSelector,
	createSelector,
	MetaReducer
} from '@ngrx/store';
import * as fromConstants from '../../cep/dashboard-management/store/reducer/constants.reducer';
import * as fromBirthDate from '../../cep/dashboard-management/store/reducer/birth-date.reducer';
import * as fromDeathDate from '../../cep/dashboard-management/store/reducer/death-date.reducer';

import * as fromMoreServices from '../../cep/dashboard-management/store/reducer/more-services.reducer';
import * as fromDepartments from '../../cep/dashboard-management/store/reducer/departments.reducer';
import * as fromService from '../../cep/dashboard-management/store/reducer/service.reducer';
import * as fromNearMeOnMap from '../../cep/dashboard-management/store/reducer/near-me-map.reducer';
import * as feedListing from '../../cep/dashboard-management/store/reducer/feeds.reducer';
import * as fromContactUsDetails from '../../cep/side-menu-bar/store/reducer/contact-us.reducer';
import * as fromAboutUsDetails from '../../cep/side-menu-bar/store/reducer/about-us.reducer';
import * as fromLogin from '../../cep/login-management/store/reducer/login.reducer';
import * as fromSearch from '../../cep/side-menu-bar/store/reducer/search.reducer';
import * as FromWebinar from '../../cep/side-menu-bar/store/reducer/webinar.reducer';

import * as fromFavorite from '../../cep/side-menu-bar/store/reducer/favorite.reducer';
import * as fromAroundMeOnMap from '../../cep/dashboard-management/store/reducer/around-me.reducer';
import * as fromGroup from '../../cep/dashboard-management/store/reducer/group.reducer';
import * as fromLogout from '../../cep/dashboard-management/store/reducer/logout.reducer';

import { environment } from 'src/environments/environment';

export interface CEPState {
	constants: fromConstants.ConstantsState;
	nearMeOnMap: fromNearMeOnMap.NearMeOnMapState;
	departments: fromDepartments.DepartmentsState;
	service: fromService.ServiceState;
	contactUs: fromContactUsDetails.ContactUsState;
	feedListing: feedListing.FeedsState;
	aboutUs: fromAboutUsDetails.AboutUsState;
	login: fromLogin.LoginState;
	search: fromSearch.SearchState;
	favorite: fromFavorite.FavoriteState;
	moreServices: fromMoreServices.MoreServicesState;
	aroundMe: fromAroundMeOnMap.AroundMeOnMapState;
	group: fromGroup.GroupState;
	logout: fromLogout.LogoutState;
	webinar:FromWebinar.WebinarState;
	birthDate: fromBirthDate.BirthDateListState;
	deathDate:fromDeathDate.DeathDateListState;
}


export const reducers: ActionReducerMap<CEPState> = {
	constants: fromConstants.reducer,
	departments: fromDepartments.reducer,
	service: fromService.reducer,
	nearMeOnMap: fromNearMeOnMap.reducer,
	contactUs: fromContactUsDetails.reducer,
	feedListing: feedListing.reducer,
	aboutUs: fromAboutUsDetails.reducer,
	login: fromLogin.reducer,
	search: fromSearch.reducer,
	favorite: fromFavorite.reducer,
	moreServices: fromMoreServices.reducer,
	aroundMe:fromAroundMeOnMap.reducer,
	group: fromGroup.reducer,
	logout: fromLogout.reducer,
	webinar:FromWebinar.reducer,
	birthDate: fromBirthDate.reducer,
	deathDate:fromDeathDate.reducer
};

export const metaReducers: MetaReducer<CEPState>[] = !environment.production ? [] : [];
export const getCEPState = createFeatureSelector<CEPState>('cep');

