/*******************************
 * Component -  More services
 * Created By - Akanksha J
 * Created on - 28/03/2020
 * Updated By - Akanksha J
 * Updated On - 31/03/2020
 *************************************/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Component({
	selector: 'app-category-wise-merchant-list',
	templateUrl: './category-wise-merchant-list.component.html',
	styleUrls: ['./category-wise-merchant-list.component.scss']
})
export class CategoryWiseMerchantListComponent implements OnInit, AfterViewInit {
	/**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails:any = {};
	/**
	 * category services response
	 */
	categoryServices: {};
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * merchant user id
	 */
	merchantUserId: any;
	/**
	 * variable from active route
	 */
	heading: any
	categoryLabels: {};
	errorMessages: {};
	categoryNames = []
	category:any;
	/**
	 * Angular method which runs on component initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private apiCallService: ApiCallService,
	) {
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
	}

	/**
	 * Angular method which runs on page initialization
	 */
	ngOnInit() {
		// token from localstorage
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				}
			});

			this.store.select(fromStore.getMoreServicesLabels)
			.subscribe(labels => {
				this.categoryLabels = labels;
				
			});

			this.getCategoryNames()

		// parameter from web path
		this.activeRoute.params.subscribe((param) => {
			this.data = param['categoryId'];
			this.heading = param['categoryEn']
			if (this.data) {
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.GetCategoryServices({
					  url: this.apiDetails['user'].base_url +
					    this.apiDetails['user'].getCategoryServiceToUserFeed.url,
					  method: this.apiDetails['user'].getCategoryServiceToUserFeed.method,
				  	  body: {
							userId : this.userId,
							sponsaredType : this.heading
						}
					}));
				}
			}
		});

		// server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverErrorMessage;
						this.isServerErrorMessage = true;
						console.error(this.serverMessage);
					}
				}
			});

		this.store.select(fromStore.isGetCategoryServicesLoading)
		.subscribe(loading => {
			this.isLoading = loading;
		});
		this.store.select(fromStore.getErrorMessages)
		.subscribe((errorMessage) => {
			this.errorMessages = errorMessage;
		});
		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
			if (this.selectedLanguage) {
				this.store.select(fromStore.isGetCategoryServicesLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getCategoryServicesStatusCode)
						.subscribe(code => {
							if (code === 200) {
								this.store.select(fromStore.getCategoryServicesResult)
								.subscribe(result => {
									this.categoryServices = result;
									
								});
							} else {
								this.store.select(fromStore.getErrorMessages)
								.subscribe(errorMessages => {
									console.error(errorMessages[this.selectedLanguage].code);
								});
							}
						});
					}
				});
			}
		});
	}

	getCategoryNames() {
		const method = 'get';
		const url = this.apiDetails.user.base_url + this.apiDetails.user.getCategoryName.url;
		const body = {}; 
	  
	this.apiCallService.apiCall(method, url, {}).subscribe(
		  (response: any) => {
		
			this.categoryNames = response.result;
			this.category = response.result.filter( (item:any) => item.en === this.heading )[0]
		  },
		  (error: any) => {
			console.error(error);
		  }
		);
	  }

	/**
	 * Created by Akanksha J
	 * For redirecting to deals listing page
	 */
	openDealListing(value) {
		this.merchantUserId = value;
		this.router.navigate(['/cep/deals/', this.merchantUserId]);
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to deal detail screen
	 */
	openDealsDetailPage(data) {
		this.router.navigate(['/cep/feeds/product/', data.engagementId]);
	}

	/**
	 * Angular method which runs after view initialization
	 */
	ngAfterViewInit() {
		window.scroll(0, 0);
	}

}
