import { Component, ElementRef, ViewChild, Inject, OnInit, OnChanges, ChangeDetectorRef, AfterViewInit, AfterContentInit, Renderer2, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from '../../config/app-constants';
import { ContactUsComponent } from 'src/app/cep/side-menu-bar/contact-us/contact-us.component';
import { NewsletterSubscriptionComponent } from 'src/app/cep/side-menu-bar/newsletter-subscription/newsletter-subscription.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { subscribeOn } from 'rxjs/operators';
import { from } from 'rxjs';
import { SocketioService } from '../../services/socketio.service';
import { MerchantConfirmationPopupComponent } from '../Merchant-flow/merchant-confirmation-popup/merchant-confirmation-popup.component';
import { SearchService } from 'src/app/cep/side-menu-bar/store/api-services/search.service';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { EncrDecrUtil } from 'src/app/util/EncrDecr';
import { environment } from 'src/environments/environment';

declare function chatbotAPI(userId): any;

export enum VisibilityState {
	Visible = 'visible',
	Hidden = 'hidden'
}

export enum Direction {
	None = 'None',
	Up = 'Up',
	Down = 'Down'
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	host: {
		'(document:click)': 'onClick($event)',
	}
})

export class HeaderComponent implements OnInit, AfterViewInit, AfterContentChecked {
	images = Images;

	backgroundColor = AppConstants.FEEDS_BACKGROUND;
	selectedLanguage = null;
	isSearch = false;
	searchText = '';
	isChatbot = true;
	headerLabels = {};
	drawerLabels = {};
	landingScreenLabels;
	showSelectedLanguage = '';
	themeBackgroundColor = AppConstants.FEEDS_DARK_BACKGROUND;
	url: string;
	apiDetails: {};
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoaded = false;
	isImage = false;
	isLoading = true;
	isErrorMessage: null;
	registerCode = 0;
	isLogin = false;
	fullName: string = null;
	firstName: string = null;
	middleName: string = null;
	lastName: string;
	userData:any;
	userLoaded = false;
	token: string;
	favMessage: string;
	facebookCode: number;
	googleCode: number;
	facebookData: {};
	googleData: {};
	splashScrren: {};
	userProfileImage: any;
	myReferralCode: string = null;//vinita
	updateLanguageResponse = null;
	currentLanguage: any;
	userId: string;
	chatbotResult: any;
	chatbotWebLink: SafeResourceUrl;
	lng: number;
	lat: number;
	isWeatherLoading = true;
	weatherStatusCode: number;
	weatherResponse: {};
	weatherImage: any;
	staticImages: {};
	weatherTemp: any;
	weatherDesc: any;
	isChatbotLoaded = false;
	isWeatherLoaded = false;
	isChatbotLoading = false;
	serverMessage: string;
	d: HTMLElement;
	hideChatBot: HTMLElement;
	chatbotUrl: any;
	aboutUsLabels: any;
	initlat = 18.62861241;
	initlng = 73.80478858;
	errorMessage: {};
	subNavClicked = false;
	chatbotWebLinkwithoutSecure: string;
	staticUrlsListForSubHeader = {};
	urlsArray: [];
	namesArray: [];
	subHeaderNames: {};
	subHeaderNamesArr = [];
	subHeaderUrl = String;
	names: unknown;
	function: any;
	isMobile: boolean;
	namesObj = {};
	urlsObj = {};
	subHeaderUrlsArr = [];
	carddescheight = 40;
	fontSizeSmall = 12;
	fontSizeNormal = 14;
	fontSizeMedium = 16;
	fontSizeCardHeading = 18;
	fontSizeSubHeading = 20;
	fontSizeHeading = 22;
	fontSizeMainHeading = 26;
	showSelectedFontSize = 'A';
	isDecreased = false;
	isIncreased = false;
	isDefault = true;
	activeFont = 'default';
	isNewsLetterSubscribe = false;
	isNewsletterRequest = false;
	emailId: any;
	notificationResult: any;
	notificationLoaded: any;
	msgSeen: any;
	currentDate: any;
	dateOfMsg: any;
	yesterday: any;
	eventGeneratedObj: any;
	noNotifications: any;
	disableNotificationButton = false;
	options = [];
	isMerchantRequest: any;
	isBrandRequest: any;
	shareUrl: any;
	isBlind;
	enableFeature;
	notificationPrvCnt = 0
	cardfooterartmt = 70;
	cardfootermt = 70;
	showHeader: boolean = false;
	enableZoo: boolean;

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private router: Router,
		private elementRef: ElementRef,
		private activeRoute: ActivatedRoute,
		private changeDetectRef: ChangeDetectorRef,
		private store: Store<fromStore.CEPState>,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private renderer: Renderer2,
		private deviceService: DeviceDetectorService,
		public sanitizer: DomSanitizer,
		private socketioService: SocketioService,
		private searchService: SearchService,
		private apiCallService: ApiCallService,

	) {
		// router.events.subscribe((val) => {
		// see also 
		// this.url = val['NavigationEnd']
		router.events.forEach((event) => {
		
			if (event instanceof NavigationEnd) {


			}
		});



		// });
	}
	/**
	 * Created by Akanksha J
	 * Foe replacing number by marathi numbers
	 */
	replace(num) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage === 'mar') {
					num = num.toString().replace(/1/g, '१');
					num = num.toString().replace(/2/g, '२');
					num = num.toString().replace(/3/g, '३');
					num = num.toString().replace(/4/g, '४');
					num = num.toString().replace(/5/g, '५');
					num = num.toString().replace(/6/g, '६');
					num = num.toString().replace(/7/g, '७');
					num = num.toString().replace(/8/g, '८');
					num = num.toString().replace(/9/g, '९');
					num = num.toString().replace(/0/g, '०');
				}
			});
		return num;
	}

	/**
		* Created by Akshata C
		* For getting mouse event to close subHeader
		*/
	onClick(event) {
		if (this.elementRef.nativeElement.contains(event.target)) {
			if (event.target.name !== 'other') {
				this.subNavClicked = false;
			}

		}
	}
	/**Created by Neha
	 * For auto search
	 */
	autoSearch($event: KeyboardEvent) {
		if (this.searchText.length % 3 == 0) {
			if (Object.keys(this.apiDetails).length > 0) {
				this.store.dispatch(new fromStore.AutoSearchData({
					//   url:'http://115.124.111.77:8002/v1/suggestions',
					url: this.apiDetails['userEngagement'].base_url + this.apiDetails['userEngagement']['timeline'].suggestions.url,
					method: this.apiDetails['userEngagement']['timeline'].suggestions.method,
					body: {
						searchValue: this.searchText,
						userId: this.userId
					}
				}));
			}
			this.store.select(fromStore.getAutoSearchLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getAutoSearchResult)
							.subscribe(result => {
								this.options = result
							});
					}
				});
		}
		// this.searchService.autoSearchResult({
		// 	url:'115.124.111.77:8002/v1/suggestions',
		// 	method:'post',
		// 	body:{
		// 		searchValue: this.searchText,
		// 		userId: this.userId
		// 	}
		// })

	}

	/**
	 * Created by Akanksha J
	 * For getting the users current location (Show popup)
	 */
	getUserLocation() {
		if (navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				this.lng = +pos.coords.longitude;
				this.lat = +pos.coords.latitude;
			},
				error => {
					this.lat = this.initlat;
					this.lng = this.initlng;
				});
		}
	}

	/**
	 * Angular method which runs when page initializes
	 */
	ngOnInit() {
		//socket connection
		// this.socketioService.listenForNotification();
		if (this.deviceService.isMobile() && window.location.pathname.includes('/cep/ofiice-zone') ||
		window.location.pathname.includes('/cep/terms-and-condition') || window.location.pathname.includes('/cep/privacy-policy')  ) {
		   this.showHeader = true
		console.log("ShowHeader",this.showHeader)

	   }

		this.currentDate = (new Date()).getDate() + '/' + (new Date()).getMonth() + '/' + (new Date()).getFullYear();
		this.yesterday = ((new Date()).getDate() - 1) + '/' + (new Date()).getMonth() + '/' + (new Date()).getFullYear();
		this.getUserLocation();
		// popup for web or app selection
		this.isMobile = this.deviceService.isMobile();
		this.store.select(fromStore.getAboutUsLabels)
			.subscribe(response => {
				this.aboutUsLabels = response[this.selectedLanguage];
			});
		this.store
			.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;

			});

		this.store.select(fromStore.verifyPasswordStatusCode)
			.subscribe(response => {
				if (response === 605) {
					this.localStorageService.clearLocalStorage();
				}
			});

		this.store.select(fromStore.getfeature)
			.subscribe( (res:any) => {
				this.enableZoo = res.zoobooking
			} )

		// check for search
		this.store.select(fromStore.getIsSearch)
			.subscribe((isSearch) => {
				this.isSearch = isSearch;
				if (this.isSearch) {
					this.store.select(fromStore.getSearchText)
						.subscribe((searchText) => {
							this.searchText = searchText;
						}
						);
				}
			});

		// server error handling
		this.serverMessage = null;
		this.store.select(fromStore.getUserStateServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverError;
						if (this.serverMessage) {
							console.error(this.serverMessage);
						}
						this.serverMessage = null;
					}
				}
			});




		// background colors setting
		this.elementRef.nativeElement.setAttribute('readonly', true);
		const element = this.elementRef.nativeElement;
		// element.style.setProperty('--fontSize12', this.fontSizeSmall);
		// element.style.setProperty('--fontSize14', this.fontSizeNormal);
		// element.style.setProperty('--fontSize20', this.fontSize22);
		// element.style.setProperty('--fontSize22', this.fontSizeHeading);
		// element.style.setProperty('--fontSize26', this.fontSizePageHeading);
		// this.store.select(fromStore.getBackgroundColor)
		// 	.subscribe((background) => {
		// 		if (Object.keys(background).length > 0) {
		// 			this.backgroundColor = background['bgColor'];
		// 			this.themeBackgroundColor = background['themeBackgroundColor'];
		// 			element.style.setProperty('--background', this.backgroundColor);
		// 			element.style.setProperty('--background-theme-color', this.themeBackgroundColor);
		// 		} else {
		// 			element.style.setProperty('--background', this.backgroundColor);
		// 			element.style.setProperty('--background-theme-color', this.themeBackgroundColor);
		// 		}
		// 	});

		// weather API loding and loaded
		this.store.select(fromStore.getIsWatherLoading)
			.subscribe((loading) => {
				this.isWeatherLoading = loading;
			});

		this.store.select(fromStore.getIsWatherLoaded)
			.subscribe((loaded) => {
				this.isWeatherLoaded = loaded;
				if (this.isWeatherLoaded) {
					this.store.select(fromStore.getWeatherStatusCode)
						.subscribe((res) => {
							this.weatherStatusCode = res;
							this.store.select(fromStore.getWeatherStatus)
								.subscribe((response) => {
									this.weatherResponse = response;
									if (Object.keys(this.weatherResponse).length > 0) {
										this.weatherTemp = Math.round(this.weatherResponse['main'].temp);
										this.weatherDesc = this.weatherResponse['weather'][0]['main'];
										// if (this.weatherResponse['weather'][0]['main'] === 'Clouds') {
										// 	this.weatherImage = this.images.coldDay;
										// }
										// else if (this.weatherResponse['weather'][0]['main'] === 'Clear') {
										// 	this.weatherImage = this.images.sunnyDay;
										// } else {
										// 	this.weatherImage = this.images.rainyDay;
										// }
									}
								});
						});
				}
			});

		// error messsage from API
		this.store.select(fromStore.getApiErrorMessage)
			.subscribe((message) => {
				this.isErrorMessage = message;
				if (this.isErrorMessage) {
					console.error(this.isErrorMessage);
				}
			});
		/**
		 * Created by neha
		 * For webinar Favorite
		 */
		this.store.select(fromStore.fevoriteWebinarListStatusCode)
			.subscribe(statusCode => {
				switch (statusCode) {
					case 600:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									this.toastr.success(this.errorMessage[this.selectedLanguage]['600']);
								}
								this.errorMessage = null;
							});
						break;

					case 601:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									this.toastr.success(this.errorMessage[this.selectedLanguage]['601']);
								}
								this.errorMessage = null;
							});
						break;

					case 500:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									console.error(this.errorMessage[this.selectedLanguage]['500']);
								}
								this.errorMessage = null;
							});
						break;

					default:
						this.errorMessage = null;
						break;
				}
			});

		// favourites success and error mesages
		this.store.select(fromStore.getFavouriteStatusCode)
			.subscribe((res) => {
				switch (res) {
					case 600:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									this.toastr.error(this.errorMessage[this.selectedLanguage]['600']);
								}
								this.errorMessage = null;

							});

						break;

					case 601:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									this.toastr.success(this.errorMessage[this.selectedLanguage]['601']);
								}
								this.errorMessage = null;
							});
						break;

					case 500:
						this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.errorMessage = errorMessage;
								if (Object.keys(this.errorMessage).length > 0) {
									console.error(this.errorMessage[this.selectedLanguage]['500']);
								}
								this.errorMessage = null;
							});
						break;

					default:
						this.errorMessage = null;
						break;
				}
			});


		// isLogin variable set to true
		// this.store.select(fromStore.registerStatusCode)
		// 	.subscribe((registerStatusCode) => {
		// 		this.registerCode = registerStatusCode;
		// 		if (this.registerCode === 200) {
		// 			this.isLogin = true;
		// 		}
		// 	});

		// facebook response
		this.store.select(fromStore.facebookStatusCode)
			.subscribe((facebookStatusCode) => {
				this.facebookCode = facebookStatusCode;
				if (this.facebookCode === 200) {
					// this.isLogin = true;
					//this.googleAnalyticsService.eventEmitter('Facebook Login', 'User login with facebook successfully', 'Click Event', 1);
					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((facebookLoginResponse) => {
							this.facebookData = facebookLoginResponse;
							this.userProfileImage = facebookLoginResponse['profilePhoto'];
							this.firstName = facebookLoginResponse['firstName'];
							this.middleName = facebookLoginResponse['middleName'];
							this.lastName = facebookLoginResponse['lastName'];
							this.fullName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
							let encrToken = this.encrDecr.set(facebookLoginResponse['token']);
							this.localStorageService.setToken(encrToken);
							let encrUserId = this.encrDecr.set(facebookLoginResponse['userId']);
							this.localStorageService.setUserId(encrUserId);

						});
				}
			});

		// google response
		this.store.select(fromStore.googleStatusCode)
			.subscribe((googleStatusCode) => {
				this.googleCode = googleStatusCode;
				if (this.googleCode === 200) {
					// this.isLogin = true;
					//this.googleAnalyticsService.eventEmitter('Google Login', 'User login with google successfully', 'Click Event', 1);
					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((googleLoginResponse) => {
							this.googleData = googleLoginResponse;
							this.userProfileImage = googleLoginResponse['profilePhoto'];
							this.firstName = googleLoginResponse['firstName'];
							this.middleName = googleLoginResponse['middleName'];
							this.lastName = googleLoginResponse['lastName'];
							this.fullName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
							let encrToken = this.encrDecr.set(googleLoginResponse['token']);
							this.localStorageService.setToken(encrToken);
							let encrUserId = this.encrDecr.set(googleLoginResponse['userId']);
							this.localStorageService.setUserId(encrUserId);

						});
				}
			});

		// token set and get from localstorage
		this.store.select(fromStore.registerUserLoaded)
			.subscribe((isLoaded) => {
				this.userLoaded = isLoaded;
				if (this.userLoaded) {
					this.store.select(fromStore.getToken)
						.subscribe((token) => {
							this.token = token;
							if (this.token) {
								//this.googleAnalyticsService.eventEmitter('Register', 'User register successfully', 'Click Event', 1);
								let encrToken = this.encrDecr.set(this.token);
								this.localStorageService.setToken(encrToken);
							}
						});

					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((getVerifyPasswordResponse) => {
							this.userData = getVerifyPasswordResponse;
							if (Object.keys(this.userData).length > 0) {
								if (this.userData['userId']) {
									this.userProfileImage = this.userData['profilePhoto'];
									// this.isLogin = true;
									this.firstName = getVerifyPasswordResponse['firstName'];
									this.middleName = getVerifyPasswordResponse['middleName'];
									this.lastName = getVerifyPasswordResponse['lastName'];
									this.emailId = getVerifyPasswordResponse['emailId'];
									this.isNewsLetterSubscribe = getVerifyPasswordResponse['isNewsLetterSubscribe'];
									this.isNewsletterRequest = getVerifyPasswordResponse['isNewsletterRequest'];
									let encrUserId = this.encrDecr.set(this.userData['userId']);
									this.isMerchantRequest = getVerifyPasswordResponse['isMerchantRequest'];
									this.isBrandRequest = getVerifyPasswordResponse['isBrandRequest'];
									this.localStorageService.setUserId(encrUserId);

								}
							}
						});
				}
			});

		// profile pic response
		this.store.select(fromStore.updateProfilePhoto)
			.subscribe((Response) => {
				this.store.select(fromStore.uploadContentType)
					.subscribe((imageType) => {
						if (imageType == 'profile') {
							this.userProfileImage = Response[0];
						}
					});
			});

		// profile display on header
		this.store.select(fromStore.getVerifyPasswordLoaded)
			.subscribe((isLoaded) => {
				this.userLoaded = isLoaded;
				if (this.userLoaded) {
					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((userData) => {
							this.userData = userData;
							this.userProfileImage = this.userData['profilePhoto'];
							this.myReferralCode = this.userData['myReferralCode'];
							this.store.select(fromStore.getToken)
								.subscribe((token) => {
									this.token = token;
									if (this.token) {

										//this.googleAnalyticsService.eventEmitter('Login', 'User Login successfully', 'Click Event', 1);
										let encrToken = this.encrDecr.set(this.token);
										this.localStorageService.setToken(encrToken);
									}
								});
							if (Object.keys(this.userData).length > 0) {
								// this.isLogin = true;
								this.firstName = userData['firstName'];
								this.middleName = userData['middleName'];
								this.lastName = userData['lastName'];
								this.emailId = userData['emailId'];
								this.isNewsLetterSubscribe = userData['isNewsLetterSubscribe'];
								this.isNewsletterRequest = userData['isNewsletterRequest'];
								let encrUserId = this.encrDecr.set(this.userData['userId']);
								this.isMerchantRequest = userData['isMerchantRequest'];
								this.isBrandRequest = userData['isBrandRequest'];
								this.localStorageService.setUserId(encrUserId);

							}
						});
				}
			});

		this.store.select(fromStore.getLoginWithOTPLoaded)
			.subscribe((loaded) => {
				this.userLoaded = loaded;
				if (this.userLoaded) {
					this.store.select(fromStore.getLoginWithOTPResponse)
						.subscribe((userData) => {
							this.userData = userData;
							this.userProfileImage = this.userData['profilePhoto'];
							this.myReferralCode = this.userData['myReferralCode'];
							this.store.select(fromStore.getToken)
								.subscribe((token) => {
									this.token = token;
									if (this.token) {
										//this.googleAnalyticsService.eventEmitter('Login', 'User Login successfully', 'Click Event', 1);
										let encrToken = this.encrDecr.set(this.token);
										this.localStorageService.setToken(encrToken);
									}
								});
							if (Object.keys(this.userData).length > 0) {
								// this.isLogin = true;
								this.firstName = userData['firstName'];
								this.middleName = userData['middleName'];
								this.lastName = userData['lastName'];
								this.emailId = userData['emailId'];
								this.isNewsLetterSubscribe = userData['isNewsLetterSubscribe'];
								this.isNewsletterRequest = userData['isNewsletterRequest'];
								let encrUserId = this.encrDecr.set(this.userData['userId']);
								this.isMerchantRequest = userData['isMerchantRequest'];
								this.isBrandRequest = userData['isBrandRequest'];
								this.localStorageService.setUserId(encrUserId);
							}
						})
				}
			})

		this.store.select(fromStore.getisloginEmployeeLoaded)
			.subscribe((loaded) => {
				this.userLoaded = loaded;
				if (this.userLoaded) {
					this.store.select(fromStore.getloginEmployeeResponse)
						.subscribe((userData) => {
							this.userData = userData;
							
							this.userProfileImage = this.userData['profilePhoto'];
							this.myReferralCode = this.userData['myReferralCode'];
							this.store.select(fromStore.getToken)
								.subscribe((token) => {
									this.token = token;
									if (this.token) {
										//this.googleAnalyticsService.eventEmitter('Login', 'User Login successfully', 'Click Event', 1);
										let encrToken = this.encrDecr.set(this.token);
										this.localStorageService.setToken(encrToken);
									}
								});
							if (Object.keys(this.userData).length > 0) {
								// this.isLogin = true;
								this.firstName = userData['firstName'];
								this.middleName = userData['middleName'];
								this.lastName = userData['lastName'];
								this.emailId = userData['emailId'];
								this.isNewsLetterSubscribe = userData['isNewsLetterSubscribe'];
								this.isNewsletterRequest = userData['isNewsletterRequest'];
								let encrUserId = this.encrDecr.set(this.userData['userId']);
								this.isMerchantRequest = userData['isMerchantRequest'];
								this.isBrandRequest = userData['isBrandRequest'];
								this.localStorageService.setUserId(encrUserId);
							}
						})
				}
			})


		// label loading and loaded variables and language select
		this.store.select(fromStore.getIsLabelLoading)
			.subscribe(async (isLoading) => {
				this.isLoading = isLoading;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.selectedLanguageFunc();
				if (this.selectedLanguage === 'mar') {
					element.style.setProperty('--language-font', 'Mukta-Regular');

					this.store.select(fromStore.getAboutUsLabels)
						.subscribe(response => {
							this.aboutUsLabels = response[this.selectedLanguage];
						});

					this.store.select(fromStore.getChatbotKeyLoaded)
						.subscribe(isLoaded => {
							this.isChatbotLoaded = isLoaded;
							if (this.isChatbotLoaded) {
								this.store.select(fromStore.getChatbotKeyResult)
									.subscribe((chatbotResult) => {
										this.chatbotResult = chatbotResult;
										if (Object.keys(this.apiDetails).length > 0) {
											if (this.chatbotResult && this.chatbotResult.floatBoatCryptedKeyForWeb) {
												//this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiDetails['info']['staticURL']['floatboatURLForWebsite'] + this.chatbotResult.floatBoatCryptedKeyForWeb + '&language=marathi');
												this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://floatbot.ai/hostedbot/?botId=5d9d8e4ee6293674bd56ea33&param=' + this.chatbotResult.floatBoatCryptedKeyForWeb + '&language=marathi');
											}
										}
									});
							}
						});
				} else {
					this.store.select(fromStore.getAboutUsLabels)
						.subscribe(response => {
							this.aboutUsLabels = response[this.selectedLanguage];
						});

					this.store.select(fromStore.getChatbotKeyLoaded)
						.subscribe(isLoaded => {
							this.isChatbotLoaded = isLoaded;
							if (this.isChatbotLoaded) {
								this.store.select(fromStore.getChatbotKeyResult)
									.subscribe((chatbotResult) => {
										this.chatbotResult = chatbotResult;
										if (Object.keys(this.apiDetails).length > 0) {
											if (this.chatbotResult && this.chatbotResult.floatBoatCryptedKeyForWeb) {
												//this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiDetails['info']['staticURL']['floatboatURLForWebsite'] + this.chatbotResult.floatBoatCryptedKeyForWeb + '&language=english');
												this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://floatbot.ai/hostedbot/?botId=5d9d8e4ee6293674bd56ea33&param=' + this.chatbotResult.floatBoatCryptedKeyForWeb + '&language=english');
											}
										}
									});
							}
						});

				}

				this.store.select(fromStore.getHeaderLabels)
					.subscribe((labels) => {
						this.headerLabels = labels;
					});

				this.store.select(fromStore.getSplashScreenLabels)
					.subscribe((labels) => {
						this.splashScrren = labels;
					});

				this.store.select(fromStore.getDrawerLabels)
					.subscribe((labels) => {
						this.drawerLabels = labels;
						this.selectedLanguageFunc();
					});

				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingScreenLabels = labels;
					});

				this.store.select(fromStore.getStaticImages)
					.subscribe((images) => {
						this.staticImages = images;
					});

				if (this.selectedLanguage) {
					this.store.select(fromStore.getStaticNamesListForSubHeader)
						.subscribe((names) => {
							if (names) {
								this.namesObj = names;
								if (Object.keys(this.namesObj).length > 0) {
									this.subHeaderNamesArr = [];
									this.subHeaderNames = this.namesObj[this.selectedLanguage];
									if (this.subHeaderNames) {
										Object.values(this.subHeaderNames).forEach((item, index) => {
											this.names = item;
											this.subHeaderNamesArr.push(item);
										});
									}
								}
							}
						});
				}

				this.store.select(fromStore.getStaticUrlsListForSubHeader)
					.subscribe((names) => {
						if (names) {
							this.urlsObj = names;
							if (this.urlsObj) {
								this.subHeaderUrlsArr = [];
								Object.values(this.urlsObj).forEach((item, index) => {
									this.names = item;
									this.subHeaderUrlsArr.push(item);

								});
							}
						}
					});

			});


		this.store.select(fromStore.getIsLabelLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (!this.isLoading && !this.isLoaded) {
					this.router.navigate(['/404/no-page-found']);
				}
				if (this.isLoaded) {
					this.store.select(fromStore.getApiCallingList)
						.subscribe((apiDetails) => {
							this.apiDetails = apiDetails;
							this.store.select(fromStore.getfeature)
								.subscribe((features) => {
									this.enableFeature = features['merchantFeature'];
								});
							if (Object.keys(this.apiDetails).length > 0) {
								this.store.dispatch(new fromStore.GetWeatherStatus({
									url: this.apiDetails['info'].base_url +
										this.apiDetails['info'].getWeatherInfo.url,
									method: this.apiDetails['info'].getWeatherInfo.method,
								}));

								let getToken = this.localStorageService.getToken();
								if (getToken) {
									if (this.selectedLanguage) {
										if (Object.keys(this.apiDetails).length > 0) {

										}
									}
									this.token = this.encrDecr.get(getToken);
									if (this.token) {
										this.store.dispatch(new fromStore.GetUserDetails({
											method: this.apiDetails['user'].getUserByUserId.method,
											url: this.apiDetails['user'].base_url + this.apiDetails['user'].getUserByUserId.url,
											body: {
												userId: this.encrDecr.get(this.localStorageService.getUserId())
											},
											token: this.token
										}));
									}
								}
							}

						});
				}
			});


		// updated language response
		this.store.select(fromStore.updateLanguage)
			.subscribe((updateLanguage) => {
				this.updateLanguageResponse = updateLanguage;
				if (this.updateLanguageResponse) {
					this.currentLanguage = this.updateLanguageResponse['lang'];
				}
			});

		// token from localstorage
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					this.isLogin = true;
				}
			});

		// api list response
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		// chatbot key loading variable
		this.store.select(fromStore.getChatbotKeyLoading)
			.subscribe((loading) => {
				this.isChatbotLoading = loading;
			});

		//notification list
		this.store.select(fromStore.isgetUserNotificationLoaded)
			.subscribe(loaded => {
				this.notificationLoaded = loaded;
				if (loaded) {
					this.store.select(fromStore.getUserNotificationStatusCode)
						.subscribe(code => {
							if (code) {
								if (code === 200) {
									this.store.select(fromStore.getUserNotificationResult)
										.subscribe(result => {
											if (result) {
												if (Object.keys(result).length < 10) {
													this.disableNotificationButton = true
												} else {
													this.disableNotificationButton = false
												}
												this.notificationResult = result;
										
											}

										});
								} else if (code === 500) {
	
									this.store.select(fromStore.getUserNotificationErrorMessage)
										.subscribe(errorMessages => {
											if (errorMessages) {
												this.noNotifications = errorMessages
											}
											// this.toastr.success(errorMessages);
										});
								} else {
									this.store.select(fromStore.getUserNotificationErrorMessage)
										.subscribe(errorMessages => {
											if (errorMessages)
												this.toastr.success(errorMessages);
										});
								}
							}
						});
				}
			});

		//listen event for notification

		this.socketioService.eventCallback$.subscribe(data => {
			this.eventGeneratedObj = data
			if (("count" in this.eventGeneratedObj) && ("date" in this.eventGeneratedObj) && (this.eventGeneratedObj.userId == this.userId)) {
				this.userData["cartCnt"] = this.eventGeneratedObj.count;
			}
			else if ("count" in this.eventGeneratedObj) {
				if (data["userId"] == this.userId) {
					this.noNotifications = null;
					this.userData["notificationCnt"] = 0;
				}
			}
			else if (("text" in this.eventGeneratedObj) && (this.eventGeneratedObj.particular.includes(this.userId))) {
				this.noNotifications = null;
				this.userData["notificationCnt"] = this.userData["notificationCnt"] + 1
				this.toastr.success(data["title"]);
				this.notificationResult.push(data)
			}
			else if (("count" in this.eventGeneratedObj) && ("date" in this.eventGeneratedObj) && (this.eventGeneratedObj.userId == this.userId)) {

				this.userData["cartCnt"] = this.eventGeneratedObj.count;
			}
		});





		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});
	}

	openOfficerZone() {
		this.router.navigate(['cep/ofiice-zone/dashboardLinks'], {
			queryParams: {
				token: this.userData.token,
				lang:this.selectedLanguage 
			}
		})
	}

	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	//   nearMe(){
	// 	if (localStorage.getItem('currentUser')) {
	//         // logged in so return true
	//         return true;
	//     }
	// 	else{
	// 		this.router.navigate(['/loginpopup']);
	// 	}
	//   }


	goToCollab() {
		this.router.navigate(["/cep/home"], {
			queryParams: {
				Collab: 'Collab'
			}
		})
	}

	/**
	 * Created By Akanksha J
	 * For changing the font size of website
	 * Created date: 24/03/2020
	 */
	changeFontSize(fontType) {
		this.activeFont = fontType;
		switch (fontType) {
			case 'default':
				this.fontSizeSmall = 12;
				this.carddescheight = 42;
				this.fontSizeNormal = 14;
				this.fontSizeMedium = 16;
				this.fontSizeCardHeading = 18;
				this.fontSizeSubHeading = 20;
				this.fontSizeHeading = 22;
				this.fontSizeMainHeading = 26;
				this.cardfooterartmt = 70;
				this.cardfootermt = 50;

				break;
			case 'increase':
				this.fontSizeSmall = 14;
				this.carddescheight = 47;
				this.fontSizeNormal = 16;
				this.fontSizeMedium = 18;
				this.fontSizeCardHeading = 20;
				this.fontSizeSubHeading = 22;
				this.fontSizeHeading = 24;
				this.fontSizeMainHeading = 28;
				this.cardfooterartmt = 48;
				this.cardfootermt = 22;
				break;
			case 'decrease':
				this.fontSizeSmall = 10;
				this.carddescheight = 35;
				this.fontSizeNormal = 12;
				this.fontSizeMedium = 14;
				this.fontSizeCardHeading = 16;
				this.fontSizeSubHeading = 18;
				this.fontSizeHeading = 20;
				this.fontSizeMainHeading = 24;
				this.cardfooterartmt = 70;
				this.cardfootermt = 50;
				break;
		}
		if (document.getElementsByTagName('body')[0]) {
			document.getElementsByTagName('body')[0].setAttribute('style', 'font-size:' + this.fontSizeNormal + 'px');
			Object.keys(document.getElementsByClassName('font-small')).forEach((element, index) => {
				document.getElementsByClassName('font-small')[index].setAttribute('style', 'font-size:' + this.fontSizeSmall + 'px');
			});
			Object.keys(document.getElementsByClassName('font-medium')).forEach((element, index) => {
				document.getElementsByClassName('font-medium')[index].setAttribute('style', 'font-size:' + this.fontSizeMedium + 'px');
			});
			Object.keys(document.getElementsByClassName('font-sub-heading')).forEach((element, index) => {
				document.getElementsByClassName('font-sub-heading')[index].setAttribute('style', 'font-size:' + this.fontSizeSubHeading + 'px');
			});
			Object.keys(document.getElementsByClassName('font-card-heading')).forEach((element, index) => {
				document.getElementsByClassName('font-card-heading')[index].setAttribute('style', 'font-size:' + this.fontSizeCardHeading + 'px');
			});
			Object.keys(document.getElementsByClassName('font-heading')).forEach((element, index) => {
				document.getElementsByClassName('font-heading')[index].setAttribute('style', 'font-size:' + this.fontSizeHeading + 'px');
			});
			Object.keys(document.getElementsByClassName('font-main-heading')).forEach((element, index) => {
				document.getElementsByClassName('font-main-heading')[index].setAttribute('style', 'font-size:' + this.fontSizeMainHeading + 'px');
			});
			Object.keys(document.getElementsByClassName('font-card-desc')).forEach((element, index) => {
				document.getElementsByClassName('font-card-desc')[index].setAttribute('style', 'height:' + this.carddescheight + 'px');
			});
			Object.keys(document.getElementsByClassName('post-footer')).forEach((element, index) => {
				document.getElementsByClassName('post-footer')[index].setAttribute('style', 'margin-top:' + this.cardfootermt + 'px');
			});
			Object.keys(document.getElementsByClassName('article-footer')).forEach((element, index) => {
				document.getElementsByClassName('article-footer')[index].setAttribute('style', 'margin-top:' + this.cardfooterartmt + 'px');
			});
		}
	}

	notificationFunction() {
		var x = document.getElementById("myDIV");
		var icon = document.getElementById("notification-icon")
		var headerId = document.getElementById("headerId")
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			this.notificationPrvCnt = 0
			x.style.display = "block";
			this.store.dispatch(new fromStore.GetUserNotification({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].getUserNotification.url,
				// url: "http://115.124.111.77:8001/v1/getUserNotification",
				method: this.apiDetails['user'].getUserNotification.method,
				// method: "post",
				body: {
					"particular": this.userId,
					"isNotification": true,
					"prevCnt": this.notificationPrvCnt
				}
			}));

			this.socketioService.eventCallback$.subscribe(data => {
				this.eventGeneratedObj = data
				if ("count" in this.eventGeneratedObj) {
					if (data["userId"] == this.userId) {
						this.noNotifications = null;
						this.userData["notificationCnt"] = 0;
					}
				}
			});
			
			document.addEventListener('click', (event) => {
				const targetNode = event.target as Node;
				// console.log("targetNode:",targetNode);
				// console.log("x:",x);
				// console.log("icon",icon)
				if (targetNode !== icon) {
					x.style.display = "none";
				}
			});

		}
		// x.addEventListener('click', function(event) {
		//   });
	}


	nextNotification(pagination) {
		if (pagination == 'next') {
			this.notificationPrvCnt = this.notificationPrvCnt + 1
		} else if (pagination == 'prv') {
			this.notificationPrvCnt = this.notificationPrvCnt - 1
		} else {
			this.notificationPrvCnt = this.notificationPrvCnt
		}

		this.store.dispatch(new fromStore.GetUserNotification({
			url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].getUserNotification.url,
			// url: "http://115.124.111.77:8001/v1/getUserNotification",
			method: this.apiDetails['user'].getUserNotification.method,
			// method: "post",
			body: {
				"particular": this.userId,
				"isNotification": true,
				"prevCnt": this.notificationPrvCnt
			}
		}));

		this.socketioService.eventCallback$.subscribe(data => {
			this.eventGeneratedObj = data
			if ("count" in this.eventGeneratedObj) {
				if (data["userId"] == this.userId) {
					this.noNotifications = null;
					this.userData["notificationCnt"] = 0;
				}
			}
		});

	}

	/**
	 * Created By Akshata C
	 * Notification section
	 * Created date: 20/05/2020
	 */
	returnedTime(updatedAtTime) {
		return (new Date(updatedAtTime)).getHours() + ':' + String((new Date(updatedAtTime)).getMinutes()).padStart(2, "0")
	}

	/**
	 * Created By Akshata C
	 * Notification section
	 * Created date: 21/05/2020
	 */
	returnedDate(updatedAtTime) {
		this.dateOfMsg = (new Date(updatedAtTime)).getDate() + '/' + (new Date(updatedAtTime)).getMonth() + '/' + (new Date(updatedAtTime)).getFullYear()
		if ((this.currentDate == this.dateOfMsg) && this.headerLabels) {
			return this.headerLabels[this.selectedLanguage].today
		} else if ((this.yesterday == this.dateOfMsg && this.headerLabels)) {
			return this.headerLabels[this.selectedLanguage].yesterday
		} else {
			return this.dateOfMsg;
			// return (new Date(updatedAtTime)).getDate() + '/' + (new Date(updatedAtTime)).getMonth() + '/' + (new Date(updatedAtTime)).getFullYear()
		}

	}

	/**
	 * Created by akshata C
	 * To fetch url links of subnav bar
	 */
	urlsData(i) {
		this.subHeaderUrlsArr.forEach((item, index) => {
			if (i == index) {
				this.subHeaderUrl = item;
			}
		});
	}

	/**
	 * Created by Akanksha j
	 * For opening become a merchant popup
	 */
	openMerchantForm() {
		this.dialog.open(MerchantConfirmationPopupComponent, {
			data: {
				disableClose: true,
				maxWidth: '100vw',
				autoFocus: false,
			},
			height: '186px',

		})
	}

	/**
	 * Created by Vinita sharma
	 * For openining and editing the merchant form 
	 */
	editMerchantForm() {
		this.router.navigate(['/cep/feeds/merchant-registration/edit']);
	}

	/**
	 * Created by Vinita sharma
	 * For openining and editing the merchant form 
	 */
	openMerchantTransaction() {
		this.router.navigate(['/cep/merchant-transaction']);
	}

	/**
	 * Created by Kiran G
	 * For opening a search textbox
	 */
	openSerach() {

		if (this.localStorageService.getItem("userId") != null) {
			if (this.token) {
				let localUserId = this.localStorageService.getUserId();
				this.userId = this.encrDecr.get(localUserId);
				//this.googleAnalyticsService.eventEmitter('search', 'Click on search icon by ' + this.userId, 'Click Event', 1);
			} else {
				//this.googleAnalyticsService.eventEmitter('search', 'Click on search icon', 'Click Event', 1);
			}
		}
		if (this.userId) {
			if ((this.searchText === '' || !this.searchText) && !this.isSearch) {
				this.isSearch = true;
				this.store.dispatch(new fromStore.SearchRoute({
					isSearch: true,
					searchText: null,
					userId: this.userId
				}));
			}
			else { }
		}
		else {
			if ((this.searchText === '' || !this.searchText) && !this.isSearch) {
				this.isSearch = true;
				this.store.dispatch(new fromStore.SearchRoute({
					isSearch: true,
					searchText: null
				}));
			}
			else { }
		}
	}

	/**
	 * Created by Kiran G
	 * For searching a paticular text in engagement
	 */
	searchResult() {
		let reqbody = {};
		if (this.userId) {
			reqbody = {
				searchValue: this.searchText,
				prevCnt: '0',
				userId: this.userId
			}
		}
		else {
			reqbody = {
				searchValue: this.searchText,
				prevCnt: '0'
			}
		}
		if (Object.keys(this.apiDetails).length > 0 && this.searchText !== '') {
			//this.googleAnalyticsService.eventEmitter('search', this.searchText, 'Click Event', 1);
			this.store.dispatch(new fromStore.ResetSearch());
			this.store.dispatch(new fromStore.SearchData({
				url: this.apiDetails['userEngagement'].base_url +
					this.apiDetails['userEngagement'].timeline.advancedSearch.url,
				method: this.apiDetails['userEngagement'].timeline.advancedSearch.method,
				body: reqbody
			}));
		}
		this.router.navigate(['/cep/search/', this.searchText]);
	}

	/**
	 * created by Kiran G
	 *
	 * updated by Akanksha J
	 * For redirecting to login screen
	 */
	redirectToLogin() {
		//this.googleAnalyticsService.eventEmitter('Login', 'Click on login button', 'Click Event', 1);
		this.router.navigate(['/Login/login']);
	}

	/**
		* Created by Akanksha J
		* For redirecting to others link popup
		*/
	redirectToNewTab(link) {
		window.open(link);
	}

	/**
	 * Created By Neha R
	 * For redirect to my Deal History
	 */
	openMyDealHistory() {
		this.router.navigate(['/cep/my-deals']);
	}

	/**
	 * Created By Priti P
	 * For redirect to my Deal History
	 */
	openDealHistory() {
		this.router.navigate(['/cep/merchant-deal', this.userId]);
	}

	/**
	 * Created by Neha R
	 * For redirect to my Loyalty
	 */
	openMyLoyalty() {
		this.router.navigate(['/cep/loyalty-points']);
	}

	/**
 * Created By Akshata C
 * For redirect to my cart page
 */
	openMyCart() {
		this.router.navigate(['/cep/my-cart']);
	}

	openGroup() {
		this.router.navigate(['/cep/group']);
	}
	openWebinar() {
		this.router.navigate(['/cep/feeds/webinar']);
	}

	openZooBooking(){
		this.router.navigate(['/cep/e-services/zoo-integration']);
	}

	openCityFaq() {
		this.router.navigate(['/cep/city-info']);
	}

	openLogin() {
		this.store.dispatch(new fromStore.GotoPageOnLogin('/cep/home'))
		this.router.navigate(["/Login/login"])
	}
	/**
	 * Created by Akanksha J
	 * For redirecting to contact us popup
	 */
	openContactUs(_label) {
		//this.googleAnalyticsService.eventEmitter('Contact Us', 'Click on Contact Us', 'Click Event', 1);
		const dialogRef = this.dialog.open(ContactUsComponent, {
			data: {
				disableClose: true,
				maxWidth: '100vw',
				autoFocus: false,
				label: _label
			}
		});
	}

	/**
 * Created by Akshata c
 * For redirecting to newsletter subscription popup
 */
	openNewsSubscription(_label) {
		//this.googleAnalyticsService.eventEmitter('Newsletter Subscription', 'Click on Newsletter Subscription', 'Click Event', 1);
		const dialogRef = this.dialog.open(NewsletterSubscriptionComponent,
			{
				data: {
					emailId: this.emailId,
					isNewsletterRequest: this.isNewsletterRequest,
					isNewsLetterSubscribe: this.isNewsLetterSubscribe,
					disableClose: true,
					maxWidth: '100vw',
					autoFocus: false,
					label: _label
				},

			});
	}

	/**
	 * Created by Kiran G
	 * For changing the language of Website
	 */
	changeLanguage(language) {
		this.isChatbot = true;
		if (language === 'en') {
			this.selectedLanguage = 'mar';
		} else {
			this.selectedLanguage = 'en';
		}
		//this.googleAnalyticsService.eventEmitter('Language', 'Change Language to ' + this.selectedLanguage, 'Click Event', 1);
		this.store.dispatch(new fromStore.ChangeLanguage({
			language: this.selectedLanguage
		}));
		let requestBody = {};
		if (this.userId) {
			requestBody = {
				userId: this.userData['userId'],
				latitude: this.lat,
				longitude: this.lng,
				lang: this.selectedLanguage
			};
		}
		this.selectedLanguageFunc();
		this.store.dispatch(new fromStore.GetChatbotKey({
			url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].floatBoatEncryKey.url,
			method: this.apiDetails['user'].floatBoatEncryKey.method,
			body: requestBody
		}));
		this.store.dispatch(new fromStore.UpdateLanguage({
			url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].updateLanguage.url,
			method: this.apiDetails['user'].updateLanguage.method,
			body: {
				userId: this.encrDecr.get(this.localStorageService.getUserId()),
				lang: this.selectedLanguage
			}
		}));
	}
	/**
		* Created by Kiran G
		* For change the language of header
		*/
	selectedLanguageFunc() {
		const lang = this.selectedLanguage;
		if (Object.keys(this.drawerLabels).length > 0) {
			if (lang === 'en') {
				this.showSelectedLanguage = this.drawerLabels['mar'].marathi;
			} else {
				this.showSelectedLanguage = this.drawerLabels['en'].english;
			}
		}
	}

	/**
	 * Created by Kiran G
	 * For redirecting to feeds page
	 */
	redirectToFeeds() {
		this.router.navigate(['/cep/home']);
	}

	/**
	 * Created by neha
	 * For redirecting to blog listing
	 */
	openBlogListing() {
		this.router.navigate(['/cep/my-blog-listing']);
	}
	/**
	 * Created by neha
	 * for redirecting to forum listing
	 */
	openForumListing() {
		this.router.navigate(['/cep/my-forum-listing']);
	}

	/**
		 * Created by Vinita
		 * for redirecting to MOM listing
		 */
	// openMomListing(){
	// 	this.router.navigate(['/cep/meetings']);
	// }

	/**
	 * Created by Akshata C
	 * to open subnavigation bax
	 */
	openSubNav() {
		this.subNavClicked = !this.subNavClicked;
	}

	/**
	 * Created by Kiran G
	 * For logging out the user
	 */
	logoutTheUser = async () => {

		const encrDecr = new EncrDecrUtil();
	

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.Logout({
						url: environment.base_url + "/user/v1/logOut",
						method: "post",
						body: {
							userId: encrDecr.get(localStorage.getItem('userId'))
						}
					}));
				}
			});


		this.isLogin = false;
		this.isChatbot = true;
		// this.store.dispatch( new fromStore.NullOnLogin() );
		this.store.dispatch(new fromStore.GotoPageOnLogin(null))
		await this.router.navigate(['/cep/home']);
		await //this.googleAnalyticsService.eventEmitter('Logout', 'User Logout', 'Click Event', 1);
		await this.store.dispatch(new fromStore.ResetMobileNumber());
		await this.store.dispatch(new fromStore.ResetLogin());
		await this.store.dispatch(new fromStore.ResetUpdateProfile());
		await this.store.dispatch(new fromStore.ResetChangeMobileNumber());
		await this.store.dispatch(new fromStore.ResetForgetPassword());
		await this.store.dispatch(new fromStore.ResetUpdatePassword());
		// await this.store.dispatch(new fromStore.ResetFeeds());
		await this.store.dispatch(new fromStore.ResetChatbot());
		await this.store.dispatch(new fromStore.ResetFavourite({}));
		await this.localStorageService.clearLocalStorage();
		localStorage.clear();
		this.userId = null;
	}

	/**
	 * Created  by Kiran G
	 * For Opening a chatbot in iframe
	 */
	openChatbot() {
		let requestBody = {};
		//this.googleAnalyticsService.eventEmitter('Chatbot', 'Open Chatbot ', 'Click Event', 1);
		this.isMobile = this.deviceService.isMobile();
		if (!this.isMobile) {
			if (this.userId) {
				requestBody = {
					userId: this.userData['userId'],
					latitude: this.lat,
					longitude: this.lng,
					lang: this.selectedLanguage
				};
			}

			this.store.dispatch(new fromStore.GetChatbotKey({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].floatBoatEncryKey.url,
				method: this.apiDetails['user'].floatBoatEncryKey.method,
				body: requestBody
			}));
			this.isChatbot = false;
		} else {
			const chatbotResponse = this.dialog.open(ChatbotComponent, {
				disableClose: true,
				maxWidth: '100vw',
				autoFocus: false
			});
		}
	}

	/**
	 * Created by Kiran G
	 * For sanitizing the chatbot link for security purpose
	 */
	srcURL() {
		if (this.chatbotWebLinkwithoutSecure) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(this.chatbotWebLinkwithoutSecure);
		}
	}

	/**
	 * Creatd by Kiran G
	 * For closing the chatbot popup
	 */
	closeChatbot() {
		//this.googleAnalyticsService.eventEmitter('Chatbot', 'Close Chatbot', 'Click Event', 1);
		this.isChatbot = true;
	}

	/**
	 * Angular method for detecting changes
	 */
	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	/**
	 * Angular method which gets run on view initialization
	 */
	ngAfterViewInit() {

	}

	/**
	 * Created by Ajay C
	 * For redirecting to feedback popup
	 */
	openFeedback() {
		if (this.isLogin) {
			const dialogRef = this.dialog.open(FeedbackComponent,
				{
					maxHeight: '100vh',
					maxWidth: '100vw',
					autoFocus: false
				});
		} else {
			this.router.navigate(['Login/login']);
		}
	}


	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {

		var url = this.shareUrl + '?' + this.myReferralCode
		var metaTag = {
			socialTitle: "Visit to the PCMC Smart Sarathi Website",
			socialImageLink: "https://iecos.enlightcloud.com:8443/v2.1/AUTH_1eef31df48f64a7aab2b45dab0274475/PCMCSmartSarathiStaging/user/U23501591363082638/category/U23501591363082638jpeqqcnrlicm"
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	// For copying the referral link to clipboared 
	// Created by Vinita Sharma
	// Date : 22 october 2020
	copyToClipboared(media) {
		var url = this.shareUrl + '?' + this.myReferralCode
		var metaTag = {
			socialTitle: "Visit to the PCMC Smart Sarathi Website",
			socialImageLink: "https://iecos.enlightcloud.com:8443/v2.1/AUTH_1eef31df48f64a7aab2b45dab0274475/PCMCSmartSarathiStaging/user/U23501591363082638/category/U23501591363082638jpeqqcnrlicm"
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	/**
	 * Created by kiran G
	 * For closing the search text box
	 */
	closeSearch() {
		// this.router.navigate(['/cep/home']);
		this.isSearch = false;
		this.searchText = null;
		this.store.dispatch(new fromStore.SearchRoute({
			isSearch: this.isSearch,
			searchText: null
		}));
	}
}
