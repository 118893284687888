import { Component, Input,Output,OnInit, Inject, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ObjectInterface } from '../../models/global-interface';
import { environment } from 'src/environments/environment';
import {Images} from "../../config/images-config";
import { IWater } from '../water-card/water-card.component';
import { BREAKPOINT } from '@angular/flex-layout';

enum STATES {
	PENDING,
	DUE,
	PROCESSING,
	PAID,
  }


@Component({
  selector: 'app-water-tax-pay-popup',
  templateUrl: './water-tax-pay-popup.component.html',
  styleUrls: ['./water-tax-pay-popup.component.scss']
})

export class WaterTaxPayPopupComponent implements OnInit {

  @Input() labels;
  @Input() selectedLanguage;
  states: typeof STATES = STATES;
  state: STATES = STATES.PENDING;
  AppImages: typeof Images = Images;
  @Input() progress: any;

  @Output() viewAction = new EventEmitter();
  @Output() payAction = new EventEmitter();
  @Output() selectedBankChange = new EventEmitter<string>()
  imageBase = "https://api.pmccare.in/image/v1/PMC%2Fuser%2FU54381623904889125%2FImage%2Fmoney-icon";
  images = this.AppImages.money;
  default = this.AppImages.money.pending
  apiDetails: ObjectInterface;
  isLoading:false;
  userId:any;  
  waterTaxlabels: {};
  token: string;
  bankname: string;
  extraLabels = { 
					en: {
						Heading:'Please select a payment gateway',					
						subTitle1: 'Powered by IndusInd Bank',
						value1: 'IndusInd',
						Title1: 'Billdesk: UPI, Credit Card, NetBanking, etc.',
						
						subTitle2: 'Powered by IDFC Bank',
						value2: 'IDFC',
						Title2: 'Billdesk: UPI, Credit Card, NetBanking, etc.',
						selectBank: "Select Bank"
					},
					mar:{
						Heading:'Please select a payment gateway',
						subTitle1: 'Powered by IndusInd Bank',
						value1: 'IndusInd',
						Title1: 'Billdesk: UPI, Credit Card, NetBanking, etc.',
						
						subTitle2: 'Powered by IDFC Bank',
						value2: 'IDFC',
						Title2: 'Billdesk: UPI, Credit Card, NetBanking, etc.',
						selectBank: "Select Bank"
					}
				}  				
	selectedBank: string;

  constructor(		
		@Inject(MAT_DIALOG_DATA) public data: IWater,
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<WaterTaxPayPopupComponent> ) {
			dialogRef.disableClose = true;
			this.selectedBank = this.bankname;
			
		}

  ngOnInit() {
    this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

			this.store
    .select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails=apiDetails;
      
	});

		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
		});
		this.store.select(fromStore.getWaterTaxLabels)
		.subscribe((labels) => {
			this.waterTaxlabels = labels;
			
		});
	}

	closeDialog() {
			this.dialogRef.close('User cancelled');
	}

	view(asset: IWater) {
		this.viewAction.emit("view");
	}
	onSelectedBankChange(selectedBank: string) {
		this.selectedBankChange.emit(selectedBank); // Emit the selected bank change event
	  }

	onBankSelected(){
		this.selectedBank = this.selectedBank;
	}


	pay(asset: IWater) {
		if (!this.selectedBank) {
			this.dialogRef.close('User cancelled');
		} else {
			this.dialogRef.close(this.selectedBank);
		}
	}



  }


