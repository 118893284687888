import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Images } from "../../config/images-config";
import { MatDialog } from '@angular/material/dialog';
import { WaterTaxPayPopupComponent } from "../water-tax-pay-popup/water-tax-pay-popup.component";


enum STATES {
  PENDING,
  DUE,
  PROCESSING,
  PAID,
}

export interface IWater {
  Consumer_No: string;
  Name: string;
  Address: string;
  Route_Code: string;
  Bill_Number: string;
  Arrears: string;
  Mobile_No: string;
  Email_id: string;
  status: string;
  category: string;
  Con_Size: string;
  Last_Receipt_Date: string;
  Last_Receipt_Amt: string;
  Last_Receipt_Chalan: string;
  AE_NAME: string;
  AE_ADDRESS: string;
  Meter_No: string;
  selectedBank: string;
}

@Component({
  selector: 'app-water-card',
  templateUrl: './water-card.component.html',
  styleUrls: ['./water-card.component.scss']
})
export class WaterCardComponent implements OnInit {

  @Input() data: IWater;
  @Input() labels;
  @Input() selectedLanguage;
  states: typeof STATES = STATES;
  state: STATES = STATES.PENDING;
  AppImages: typeof Images = Images;
  @Input() progress: any;

  @Output() viewAction = new EventEmitter();
  @Output() payAction = new EventEmitter<IWater>();
  @Output() selectedBankChange = new EventEmitter<string>();
  imageBase = "https://api.pmccare.in/image/v1/PMC%2Fuser%2FU54381623904889125%2FImage%2Fmoney-icon";
  images = this.AppImages.money;
  default = this.AppImages.money.pending

  constructor(private dialog:MatDialog) {}

  ngOnInit() {
    
  }

  ngAfterViewInit() {}

  getCardState(asset: IWater) {
    const due = Number(asset.Arrears);
    
    if (!due && due !== 0) return (this.state = STATES.PENDING);
    if (due > 1 || due === 1) return (this.state = STATES.DUE);
    if (due < 1) return (this.state = STATES.PAID);
    //TODO: add state for processing payment
  }

  getIconByState(asset: IWater) {
    if (!asset) return this.images.pending;

    const state = this.getCardState(asset);

    if (state === this.states.DUE) return this.images.due;
    if (state === this.states.PAID) return this.images.paid;
    if (state === this.states.PENDING) return this.images.pending;
    if (state === this.states.PROCESSING) return this.images.processing;
  }

  view(asset: IWater) {
    this.viewAction.emit("view");
  }

  // pay(asset: IWater) {
  //   console.log("paydata",asset)
  //   this.payAction.emit("pay");
  // }

  handlePayAction(data: IWater) {    
    this.payAction.emit(data);
  }

  onSelectedBankChange(selectedBank: string) {
    this.selectedBankChange.emit(selectedBank); 
  }

  openPaymentPopup(data:IWater): void {   
    const dialogRef = this.dialog.open(WaterTaxPayPopupComponent, {
      width : '500px',			
			disableClose: false,
      data: data 
    });

    dialogRef.afterClosed().subscribe( (res:any) => {   
      if(res !== 'User cancelled'){
        this.onSelectedBankChange(res);
        this.handlePayAction({...data,selectedBank:res})

      }
      
    } )

  }
}
