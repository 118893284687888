
import { ConstantsEffects } from '../../cep/dashboard-management/store/effects/constants.effect';
import { FeedsEffect } from '../../cep/dashboard-management/store/effects/feeds.effect';
import { DepartmentsEffects } from '../../cep/dashboard-management/store/effects/departments.effect';
import { ServiceEffects } from '../../cep/dashboard-management/store/effects/service.effect';
import { CfcCentresEffects } from '../../cep/dashboard-management/store/effects/near-me-map.effects';
import { MoreServiesEffects } from '../../cep/dashboard-management/store/effects/more-services.effect';
import { BirthDateEffects } from '../../cep/dashboard-management/store/effects/birth-date.effect';
import { DeathDateEffects } from '../../cep/dashboard-management/store/effects/death-date.effect';
import { GroupEffects } from '../../cep/dashboard-management/store/effects/group.effect';

import {ContactUsEffect} from '../../cep/side-menu-bar/store/effects/contact-us.effects';
import { AboutUsEffect} from '../../cep/side-menu-bar/store/effects/about-us.effect';
import { FavoriteEffect} from '../../cep/side-menu-bar/store/effects/favorite.effect';
import { SearchEffect} from '../../cep/side-menu-bar/store/effects/search.effect';
import { WebinarEffect} from '../../cep/side-menu-bar/store/effects/webinar.effect';

import { UserstateResponseEffects } from '../../cep/login-management/store/effects/login.effect';
import { AroundMeEffects } from '../dashboard-management/store/effects';
import { LogoutEffects } from '../dashboard-management/store/effects';


export const effects: any[] = [
  ConstantsEffects,
  DepartmentsEffects,
  ServiceEffects,
  CfcCentresEffects,
  FeedsEffect,
  ContactUsEffect,
  AboutUsEffect,
  FavoriteEffect,
  UserstateResponseEffects,
  SearchEffect,
  MoreServiesEffects,
  AroundMeEffects,
  LogoutEffects,
  GroupEffects,
  WebinarEffect,
  BirthDateEffects,
  DeathDateEffects
];

export * from '../../cep/dashboard-management/store/effects/constants.effect';
export * from '../../cep/dashboard-management/store/effects/departments.effect';
export * from '../../cep/dashboard-management/store/effects/service.effect';
export * from '../../cep/dashboard-management/store/effects/near-me-map.effects';
export * from '../../cep/dashboard-management/store/effects/feeds.effect';
export * from '../../cep/dashboard-management/store/effects/more-services.effect';
export * from '../../cep/dashboard-management/store/effects/around-me.effect';
export * from '../../cep/dashboard-management/store/effects/logout.effect';
export * from '../../cep/dashboard-management/store/effects/group.effect';
export * from '../../cep/dashboard-management/store/effects/birth-date.effect';

export * from '../../cep/side-menu-bar/store/effects/contact-us.effects';
export * from '../../cep/side-menu-bar/store/effects/about-us.effect';
export * from '../../cep/side-menu-bar/store/effects/favorite.effect';
export * from '../../cep/side-menu-bar/store/effects/search.effect';
export * from '../../cep/side-menu-bar/store/effects/webinar.effect';

export * from '../../cep/login-management/store/effects/login.effect';
