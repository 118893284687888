import { Action } from '@ngrx/store';

export const GET_CONTACT_US_DETAILS = 'GET_CONTACT_US_DETAILS';
export const GET_CONTACT_US_DETAILS_SUCCESS = 'GET_CONTACT_US_DETAILS_SUCCESS';
export const GET_CONTACT_US_DETAILS_FAIL = 'GET_CONTACT_US_DETAILS_FAIL';

export class GetContactUsDetails implements Action {
		readonly type = GET_CONTACT_US_DETAILS;
	constructor(public payload: any) {}
}

export class GetContactUsDetailsSuccess implements Action {
		readonly type = GET_CONTACT_US_DETAILS_SUCCESS;
	constructor(public payload: any) {}
}

export class GetContactUsDetailsFail implements Action {
		readonly type = GET_CONTACT_US_DETAILS_FAIL;
	constructor(public payload: any) {}
}

export type contactUsAction = GetContactUsDetails | GetContactUsDetailsSuccess | GetContactUsDetailsFail;
