/**
 * Created by Prajkta P
 * For showing options popup (web or mobile app)
 */
import { Component, OnInit } from '@angular/core';
import { Images } from '../../config/images-config';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { IntroductionPopupComponent } from '../introduction-popup/introduction-popup.component';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
	selector: 'app-mobile-device-popup',
	templateUrl: './mobile-device-popup.component.html',
	styleUrls: ['./mobile-device-popup.component.scss']
})
export class MobileDevicePopupComponent implements OnInit {
	/**
	 * fetched images from image config file
	 */
	images = Images;
	/**
	 * header labels
	 */
	headerLabels={};
	/**
	 * selected language variable from store
	 */
	selectedLanguage: string;
	token: string;
	userId: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	deviceType:string;
	
	/**
	 * Angular method that gets called on conponent initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<MobileDevicePopupComponent>,
		private localStorageService: LocalStorageService,
		private encrDecr:EncrDecrService,
		private deviceService: DeviceDetectorService,
		) {
			
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe(lang => {
				this.selectedLanguage = lang;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getHeaderLabels)
						.subscribe(res => {
							if(res) {
							
								this.headerLabels = res;
							}
							
						});
				}
			});
	}

	/**
	 * Angular method that gets called on page initialization
	 */
	ngOnInit() {

		
	// 	const userAgent = navigator.userAgent.toLowerCase();

		
		 
    // if (/android/.test(userAgent)) {
    //   this.deviceType = 'Android';
   
    // } else if (/iphone|ipad|ipod/.test(userAgent)) {
    //   this.deviceType = 'iOS';
      

    // } else {
    //   this.deviceType = 'Unknown';

    // }

	
	// }

	/**
	 * Created by Prajkta P
	 * For redirecting to mobile Application
	 */
	// redirectToMobileApp() {
	// 	// let temp = "https://pcmcsmartsarathi.page.link?apn=com.dataapp&link=https://article/T1573801890958";
	// 	let temp ="https://pcmcsmartsarathi.page.link?apn=com.dataapp&link=https://dashboard/";
	// 	window.open(temp);
	// }

	/**
	 * created by Akanksha J
	 * For Closing the popup
	 */
	// async closeDialog() {
	// 	await this.dialogRef.close();
	
	// 	this.store.select(fromStore.getToken).subscribe((token) => {
	// 	  this.token = token;
	// 	  if (this.token) {
	// 		this.userId = this.encrDecr.get(this.localStorageService.getUserId());
	// 		if (this.userId) {
	// 		  this.dialog.open(IntroductionPopupComponent);
	// 		}
	// 	}
	// 	});

	}
}
