import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})
export class WebinarService {

	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) {

	}
	getWebinarListing(request) {
		let method = request.method;
    let url = request.url;
    let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	getWebinarById(request) {
		let method = request.method;
    let url = request.url;
    let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	addOrRemoveWebinarComment(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	  }


	  getAllWebinarComment(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	  }

	  ReportAbuseWebinarComment(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	  }
	
	
}
