import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterContentChecked, OnDestroy {
	public images = Images;
	constants = AppConstants;
	deviceInfo = null;
	public topic;
	selectedLanguage;
	contactUsLabel;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading = false;
	isLoaded = false;
	contactNo: {};
	device: string = null;
	apiDetails: {};
	isNetworkError = false;
	contactUsResult: {};
	serverErrorMessage: string;

	/**
     *
     * angular method for declaring packages in variables
     *
     * which runs first in this particular component
    */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		public router: Router,
		private store: Store<fromStore.CEPState>,
		private dialogRef: MatDialogRef<ContactUsComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService
	) {
		dialogRef.disableClose = true;
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		// API call for contact us details
		this.store.dispatch(new fromStore.GetContactUsDetails({
			url: this.apiDetails['applicationConfigurations'].base_url +
				this.apiDetails['applicationConfigurations']['contactUs'].getContactUsDetails.url,
			method: this.apiDetails['applicationConfigurations']['contactUs'].getContactUsDetails.method
		}));
	}

	/**
	 * angular method which gets called on page initialization
	 */
	ngOnInit() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on Contact Us', 'Click Event', 1);

		// server error handling
		this.store.select(fromStore.getcontactUsServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverErrorMessage = serverError;
						if (this.serverErrorMessage) {
							console.error(this.serverErrorMessage);
						}
					}
					this.serverErrorMessage = null;
				}
			});

		this.store.select(fromStore.getIsContactUsLoading)
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
			});

		// response of API call
		this.store.select(fromStore.getIsContactUsLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (!this.isLoading && !this.isLoaded) {
					this.isNetworkError = false;
				}
				if (this.isLoaded) {
					if (this.data && (this.data.label === 'pcmcSmartSarathi')) {
						this.store.select(fromStore.getContactUsResult)
							.subscribe((labels) => {
								if (labels) {
									this.contactUsResult = labels['PCMCSmartSarathi'];
								}
							});
					}
					if (this.data && (this.data.label === 'sarathi')) {
						this.store.select(fromStore.getContactUsResult)
							.subscribe((labels) => {
								if (labels) {
									this.contactUsResult = labels['sarathi'];
								}
							});
					}
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getContactUsLabels)
					.subscribe((labels) => {
						this.contactUsLabel = labels;
					});
			});

		// device detection logic
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktopDevice = this.deviceService.isDesktop();
		if (isMobile) {
			this.device = 'mobile';
		} else if (isTablet) {
			this.device = 'tablet';
		} else if (isDesktopDevice) {
			this.device = 'desktop';
		}
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to a popup of feedback with support mail id
	 */
	redirectToFeedback() {
		this.dialog.closeAll();
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to twitter account
	 */
	redirectToTwitter() {
		window.open(this.contactUsResult['twitterLink']);
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to facebook account
	 */
	redirectToFacebook() {
		window.open(this.contactUsResult['facebookLink']);
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to instagram account
	 */
	redirectToInstagram() {
		window.open(this.contactUsResult['instagramLink']);
	}

	/**
	 * Created by Akanksha J
	 * For redirecting to linkedin account
	 */
	redirectToLinkedIn() {
		window.open(this.contactUsResult['linkedinLink']);
	}

	/**
	 * Created by Akanksha J
	 * For closing the popup
	 */
	closeDialog() {
		const dialogClose = this.dialog.closeAll();
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
	}

	ngOnDestroy() {
		this.serverErrorMessage = null;
	}
}
