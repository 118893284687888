import { Action } from '@ngrx/store';

export const GET_MORE_SERVICES = 'GET_MORE_SERVICES';
export const GET_MORE_SERVICES_SUCCESS = 'GET_MORE_SERVICES_SUCCESS';
export const GET_MORE_SERVICES_FAIL = 'GET_MORE_SERVICESFAIL';

export const GET_CATEGORY_SERVICES = 'GET_CATEGORY_SERVICES';
export const GET_CATEGORY_SERVICES_SUCCESS = 'GET_CATEGORY_SERVICES_SUCCESS';
export const GET_CATEGORY_SERVICES_FAIL = 'GET_CATEGORY_SERVICES_FAIL';

export const GET_ALL_ENGAGEMENT_BY_MERCHANT_ID = 'GET_ALL_ENGAGEMENT_BY_MERCHANT_ID';
export const GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_SUCCESS = 'GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_SUCCESS';
export const GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_FAIL = 'GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_FAIL';

export const GET_DEAL_DETAIL_BY_DEAL_ID = 'GET_DEAL_DETAIL_BY_DEAL_ID';
export const GET_DEAL_DETAIL_BY_DEAL_ID_SUCCESS = 'GET_DEAL_DETAIL_BY_DEAL_ID_SUCCESS';
export const GET_DEAL_DETAIL_BY_DEAL_ID_FAIL = 'GET_DEAL_DETAIL_BY_DEAL_ID_FAIL';

export const  GET_MY_ORDER_HISTORY_ID = 'GET_MY_ORDER_HISTORY_ID';
export const  GET_MY_ORDER_HISTORY_ID_SUCCESS = 'GET_MY_ORDER_HISTORY_ID_SUCCESS';
export const  GET_MY_ORDER_HISTORY_ID_FAIL = 'GET_MY_ORDER_HISTORY_ID_FAIL';

export const  GET_MERCHANT_BY_ID = 'GET_MERCHANT_BY_ID';
export const  GET_MERCHANT_BY_ID_SUCCESS = 'GET_MERCHANT_BY_ID_SUCCESS';
export const  GET_MERCHANT_BY_ID_FAIL = 'GET_MERCHANT_BY_ID_FAIL';

export const  GET_USER_CART = 'GET_USER_CART';
export const  GET_USER_CART_SUCCESS = 'GET_USER_CART_SUCCESS';
export const  GET_USER_CART_FAIL = 'GET_USER_CART_FAIL';

export const  ADD_ITEM_TO_CART_CART = 'ADD_ITEM_TO_CART_CART';
export const  ADD_ITEM_TO_CART_CART_SUCCESS = 'ADD_ITEM_TO_CART_CART_SUCCESS';
export const  ADD_ITEM_TO_CART_CART_FAIL = 'ADD_ITEM_TO_CART_CART_FAIL';

export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const REMOVE_ITEM_FROM_CART_SUCCESS = 'REMOVE_ITEM_FROM_CART_SUCCESS';
export const REMOVE_ITEM_FROM_CART_FAIL = 'REMOVE_ITEM_FROM_CART_FAIL';

export const RESET_DEAL = 'RESET_DEAL'
export const RESET_CART = 'RESET_CART'

export const  GET_MY_LOYALTY_POINTS = 'GET_MY_LOYALTY_POINTS';
export const  GET_MY_LOYALTY_POINTS_SUCCESS = 'GET_MY_LOYALTY_POINTS_SUCCESS';
export const  GET_MY_LOYALTY_POINTS_FAIL = 'GET_MY_LOYALTY_POINTS_FAIL';

export const  EMPTY_CART = ' EMPTY_CART';
export const  EMPTY_CART_SUCCESS = ' EMPTY_CART_SUCCESS';
export const  EMPTY_CART_FAIL = ' EMPTY_CART_FAIL';

export const  APPLY_COUPON = ' APPLY_COUPON';
export const  APPLY_COUPON_SUCCESS = ' APPLY_COUPON_SUCCESS';
export const  APPLY_COUPON_FAIL = ' APPLY_COUPON_FAIL';

export const  GET_DEAL_HISTORY_DETAILS_CART = 'GET_DEAL_HISTORY_DETAILS_CART';
export const  GET_DEAL_HISTORY_DETAILS_CART_SUCCESS = 'GET_DEAL_HISTORY_DETAILS_CART_SUCCESS';
export const  GET_DEAL_HISTORY_DETAILS_CART_FAIL = 'GET_DEAL_HISTORY_DETAILS_CART_FAIL';

export const  MERCHANT_TRANSACTION_LIST = 'MERCHANT_TRANSACTION_LIST';
export const  MERCHANT_TRANSACTION_LIST_SUCCESS = 'MERCHANT_TRANSACTION_LIST_SUCCESS';
export const  MERCHANT_TRANSACTION_LIST_FAIL = 'MERCHANT_TRANSACTION_LIST_FAIL';

export const  ADD_MERCHANT_TRANSACTION = 'ADD_MERCHANT_TRANSACTION';
export const  ADD_MERCHANT_TRANSACTION_SUCCESS = 'ADD_MERCHANT_TRANSACTION_SUCCESS';
export const  ADD_MERCHANT_TRANSACTION_FAIL = 'ADD_MERCHANT_TRANSACTION_FAIL';

export const  START_TRANSACTION = 'START_TRANSACTION';
export const  START_TRANSACTION_SUCCESS = 'START_TRANSACTION_SUCCESS';
export const  START_TRANSACTION_FAIL = 'START_TRANSACTION_FAIL';

export const  UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STSTUS';
export const  UPDATE_TRANSACTION_STATUS_SUCCESS = 'UPDATE_TRANSACTION_STSTUS_SUCCESS';
export const  UPDATE_TRANSACTION_STATUS_FAIL = 'UPDATE_TRANSACTION_STSTUS_FAIL';

export const  ADD_DEALS = 'ADD_DEALS';
export const  ADD_DEALS_SUCCESS = 'ADD_DEALS_SUCCESS';
export const  ADD_DEALS_FAIL = 'ADD_DEALS_FAIL';

export const  REDEEM_LOYALTY = 'REDEEM_LOYALTY';
export const  REDEEM_LOYALTY_SUCCESS = 'REDEEM_LOYALTY_SUCCESS';
export const  REDEEM_LOYALTY_FAIL = 'REDEEM_LOYALTY_FAIL';

export const SET_PAGINATION_COUNT_DEAL = 'SET_PAGINATION_COUNT_DEAL';

// export const NULL_CART = 'NULL_CART';

export class SetPaginationCountDeal implements Action {
	readonly type = SET_PAGINATION_COUNT_DEAL;
	constructor(public payload: any) { }
}

export class GetMoreServices implements Action {
	readonly type = GET_MORE_SERVICES;
	constructor(public payload: any) { }
}
export class GetMoreServicesSuccess implements Action {
	readonly type = GET_MORE_SERVICES_SUCCESS;
	constructor(public payload: any) { }
}
export class GetMoreServicesFail implements Action {
	readonly type = GET_MORE_SERVICES_FAIL;
	constructor(public payload: any) { }
}

export class GetCategoryServices implements Action {
	readonly type = GET_CATEGORY_SERVICES;
	constructor(public payload: any) { }
}
export class GetCategoryServicesSuccess implements Action {
	readonly type = GET_CATEGORY_SERVICES_SUCCESS;
	constructor(public payload: any) { }
}
export class GetCategoryServicesFail implements Action {
	readonly type = GET_CATEGORY_SERVICES_FAIL;
	constructor(public payload: any) { }
}

export class GetAllEngagementByMerchantId implements Action {
	readonly type = GET_ALL_ENGAGEMENT_BY_MERCHANT_ID;
	constructor(public payload: any) { }
}
export class GetAllEngagementByMerchantIdSuccess implements Action {
	readonly type = GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_SUCCESS;
	constructor(public payload: any) { }
}
export class GetAllEngagementByMerchantIdFail implements Action {
	readonly type = GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetDealDetailByDealId implements Action {
	readonly type = GET_DEAL_DETAIL_BY_DEAL_ID;
	constructor(public payload: any) { }
}
export class GetDealDetailByDealIdSuccess implements Action {
	readonly type = GET_DEAL_DETAIL_BY_DEAL_ID_SUCCESS;
	constructor(public payload: any) { }
}
export class GetDealDetailByDealIdFail implements Action {
	readonly type = GET_DEAL_DETAIL_BY_DEAL_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetMyOrderHistoryId implements Action {
	readonly type = GET_MY_ORDER_HISTORY_ID;
	constructor(public payload: any) { }
}
export class GetMyOrderHistoryIdSuccess implements Action {
	readonly type = GET_MY_ORDER_HISTORY_ID_SUCCESS;
	constructor(public payload: any) { }
}
export class GetMyOrderHistoryIdFail implements Action {
	readonly type = GET_MY_ORDER_HISTORY_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetMerchantById implements Action {
	readonly type = GET_MERCHANT_BY_ID;
	constructor(public payload: any) { }
}
export class GetMerchantByIdSuccess implements Action {
	readonly type = GET_MERCHANT_BY_ID_SUCCESS;
	constructor(public payload: any) { }
}
export class GetMerchantByIdFail implements Action {
	readonly type = GET_MERCHANT_BY_ID_FAIL;
	constructor(public payload: any) { }
}

export class GetUserCart implements Action {
	readonly type = GET_USER_CART;
	constructor(public payload: any) { }
}
export class GetUserCartSuccess implements Action {
	readonly type = GET_USER_CART_SUCCESS;
	constructor(public payload: any) { }
}
export class GetUserCartFail implements Action {
	readonly type = GET_USER_CART_FAIL;
	constructor(public payload: any) { }
}

export class AddItemToCart implements Action {
	readonly type = ADD_ITEM_TO_CART_CART;
	constructor(public payload: any) { }
}
export class AddItemToCartSuccess implements Action {
	readonly type = ADD_ITEM_TO_CART_CART_SUCCESS;
	constructor(public payload: any) { }
}
export class AddItemToCartFail implements Action {
	readonly type = ADD_ITEM_TO_CART_CART_FAIL;
	constructor(public payload: any) { }
}

export class RemoveItemFromCart implements Action {
	readonly type = REMOVE_ITEM_FROM_CART;
	constructor(public payload: any) { }
}

export class resetDeal implements Action {
	readonly type = RESET_DEAL;
	constructor(public payload: any) { }
}

export class resetCart implements Action {
	readonly type = RESET_CART;
	constructor(public payload: any) { }
}

export class RemoveItemFromCartSuccess implements Action {
	readonly type = REMOVE_ITEM_FROM_CART_SUCCESS;
	constructor(public payload: any) { }
}
export class RemoveItemFromCartFail implements Action {
	readonly type = REMOVE_ITEM_FROM_CART_FAIL;
	constructor(public payload: any) { }
}

export class GetMyLoyaltyPoints implements Action {
	readonly type = GET_MY_LOYALTY_POINTS;
	constructor(public payload: any) { }
}
export class GetMyLoyaltyPointsSuccess implements Action {
	readonly type = GET_MY_LOYALTY_POINTS_SUCCESS;
	constructor(public payload: any) { }
}
export class GetMyLoyaltyPointsFail implements Action {
	readonly type = GET_MY_LOYALTY_POINTS_FAIL;
	constructor(public payload: any) { }
}

export class EmptyCart implements Action {
	readonly type = EMPTY_CART;
	constructor(public payload: any) { }
}
export class EmptyCartSuccess implements Action {
	readonly type = EMPTY_CART_SUCCESS;
	constructor(public payload: any) { }
}
export class EmptyCartFail implements Action {
	readonly type = EMPTY_CART_FAIL;
	constructor(public payload: any) { }
}

export class ApplyCoupon implements Action {
	readonly type = APPLY_COUPON;
	constructor(public payload: any) { }
}
export class ApplyCouponSuccess implements Action {
	readonly type = APPLY_COUPON_SUCCESS;
	constructor(public payload: any) { }
}
export class ApplyCouponFail implements Action {
	readonly type = APPLY_COUPON_FAIL;
	constructor(public payload: any) { }
}

export class GetDealHistoryDetailsCart implements Action {
	readonly type = GET_DEAL_HISTORY_DETAILS_CART;
	constructor(public payload: any) { }
}
export class GetDealHistoryDetailsCartSuccess implements Action {
	readonly type = GET_DEAL_HISTORY_DETAILS_CART_SUCCESS;
	constructor(public payload: any) { }
}
export class GetDealHistoryDetailsCartFail implements Action {
	readonly type = GET_DEAL_HISTORY_DETAILS_CART_FAIL;
	constructor(public payload: any) { }
}

export class MerchantTransactionList implements Action {
	readonly type = MERCHANT_TRANSACTION_LIST;
	constructor(public payload: any) { }
}
export class MerchantTransactionListSuccess implements Action {
	readonly type =  MERCHANT_TRANSACTION_LIST_SUCCESS;
	constructor(public payload: any) { }
}
export class MerchantTransactionListFail implements Action {
	readonly type =  MERCHANT_TRANSACTION_LIST_FAIL;
	constructor(public payload: any) { }
}

export class AddMerchantTransaction implements Action {
	readonly type = ADD_MERCHANT_TRANSACTION;
	constructor(public payload: any) { }
}
export class AddMerchantTransactionSuccess implements Action {
	readonly type =  ADD_MERCHANT_TRANSACTION_SUCCESS;
	constructor(public payload: any) { }
}
export class AddMerchantTransactionFail implements Action {
	readonly type =  ADD_MERCHANT_TRANSACTION_FAIL;
	constructor(public payload: any) { }
}

export class StartTransaction implements Action {
	readonly type = START_TRANSACTION;
	constructor(public payload: any) { }
}
export class StartTransactionSuccess implements Action {
	readonly type = START_TRANSACTION_SUCCESS;
	constructor(public payload: any) { }
}
export class StartTransactionFail implements Action {
	readonly type = START_TRANSACTION_FAIL;
	constructor(public payload: any) { }
}

export class UpdateTransactionStatus implements Action {
	readonly type = UPDATE_TRANSACTION_STATUS;
	constructor(public payload: any) { }
}
export class UpdateTransactionStatusSuccess implements Action {
	readonly type = UPDATE_TRANSACTION_STATUS_SUCCESS;
	constructor(public payload: any) { }
}
export class UpdateTransactionStatusFail implements Action {
	readonly type = UPDATE_TRANSACTION_STATUS_FAIL;
	constructor(public payload: any) { }
}

export class AddDeals implements Action {
	readonly type = ADD_DEALS;
	constructor(public payload: any) { }
}
export class AddDealsSuccess implements Action {
	readonly type = ADD_DEALS_SUCCESS;
	constructor(public payload: any) { }
}
export class AddDealsFail implements Action {
	readonly type = ADD_DEALS_FAIL;
	constructor(public payload: any) { }
}

export class RedeemLoyalty implements Action {
	readonly type = REDEEM_LOYALTY;
	constructor(public payload: any) { }
}
export class RedeemLoyaltySuccess implements Action {
	readonly type = REDEEM_LOYALTY_SUCCESS;
	constructor(public payload: any) { }
}
export class RedeemLoyaltyFail implements Action {
	readonly type = REDEEM_LOYALTY_FAIL;
	constructor(public payload: any) { }
}

// export class nullCart implements Action {
// 	readonly type = NULL_CART;
// 	constructor(public payload: any) { }
// }


export type moreServicesAction = GetMoreServices | GetMoreServicesSuccess | GetMoreServicesFail
							| GetCategoryServices | GetCategoryServicesSuccess | GetCategoryServicesFail
							| GetAllEngagementByMerchantId | GetAllEngagementByMerchantIdSuccess | GetAllEngagementByMerchantIdFail
							| GetDealDetailByDealId | GetDealDetailByDealIdSuccess | GetDealDetailByDealIdFail
							| GetMyOrderHistoryId | GetMyOrderHistoryIdSuccess | GetMyOrderHistoryIdFail
							| GetMerchantById | GetMerchantByIdSuccess | GetMerchantByIdFail
							| GetUserCart | GetUserCartSuccess | GetUserCartFail
							| AddItemToCart | AddItemToCartSuccess | AddItemToCartFail
							| RemoveItemFromCart | RemoveItemFromCartSuccess | RemoveItemFromCartFail
							| GetMyLoyaltyPoints | GetMyLoyaltyPointsSuccess | GetMyLoyaltyPointsFail
							| EmptyCart | EmptyCartSuccess | EmptyCartFail
							| SetPaginationCountDeal | resetDeal | resetCart
							| ApplyCoupon | ApplyCouponSuccess | ApplyCouponFail
							| GetDealHistoryDetailsCart | GetDealHistoryDetailsCartSuccess | GetDealHistoryDetailsCartFail
							| StartTransaction | StartTransactionSuccess | StartTransactionFail
							| UpdateTransactionStatus | UpdateTransactionStatusSuccess | UpdateTransactionStatusFail
							| AddDeals | AddDealsSuccess | AddDealsFail
							| RedeemLoyalty | RedeemLoyaltySuccess | RedeemLoyaltyFail 
							| MerchantTransactionList | MerchantTransactionListSuccess | MerchantTransactionListFail
							| AddMerchantTransaction | AddMerchantTransactionSuccess | AddMerchantTransactionFail;
