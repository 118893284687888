import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
  providedIn: 'root'
})
export class NearMeMapService {
  latitude: any;
  longitude: any;
  type: any;
 

  constructor( private http: HttpClient,
    private apiCallService: ApiCallService) { }
   
    getCfcCentres(request) {
        let method = request.method;
        let url = request.url;
        let body = request.body
      return this.apiCallService.apiCall(method, url, body);
    }
    getNearMeOnMAP(request){
      let method = request.method;
      let url = request.url;
      let body = request.body
      return this.apiCallService.apiCall(method, url, body);
    }

    getBicycleOnMap(request){
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url, body)
    }

    getBikeOnMap(request){
      let method = request.method;
      let url = request.url;
      let body = request.body;
      return this.apiCallService.apiCall(method, url , body)
    }


}
