import { Component, OnInit, ChangeDetectorRef, Input, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { FeedbackComponent } from '../../../shared/components/feedback/feedback.component';
import { EncrDecrService } from '../../../shared/services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Images } from '../../../shared/config/images-config'
import { ReportAbusePopupComponent } from '../../../shared/components/report-abuse-popup/report-abuse-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ObjectInterface } from '../../../shared/models/global-interface';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';


@Component({
  selector: 'app-webinar-listing',
  templateUrl: './webinar-listing.component.html',
  styleUrls: ['./webinar-listing.component.scss']
})
export class WebinarListingComponent implements OnInit {
  images = Images;
  @Input() newsData: any;
  public WebinarListData = [];
  public newsDate;
  apiDetails : ObjectInterface;
  timelineId: any;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  isLoaded = false;
  isLoading = false;
  /**
	 * server error message
	 */
	serverMessage: string;
  selectedLanguage: string;
  landingLables= {};
  feedsDate: string;
  feedFilterLabels: {};
  feedLabels: {};
  userId: any;
  token: string;
  feedStatusCode: number;
  shareUrl: any;
  errMessage: string;
  feedsContent: any;
  dateOfMsg: any;
  isNewWebinarLoading = false;
	isSeeMoreButtonShow = true;
  webinarLabels: {};
  count = 0;
  isServerErrorMessage: boolean;
  noData= false;
  errorMessage: {};
  isBlind;
  /**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	*/
  constructor(private activeParams: ActivatedRoute,
    private store: Store<fromStore.CEPState>,
    private changeDetectRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
		private apiCallService: ApiCallService,   
    private router: Router) { }

  ngOnInit() {

    this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        let localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
        //this.googleAnalyticsService.eventEmitter('Dashboard', 'click on news by ' + this.userId, 'userLabel', 1);
      } else {
        this.userId = null;
        //this.googleAnalyticsService.eventEmitter('Dashboard', 'user click on news', 'userLabel', 1);
      }
    });
  	this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
        this.shareUrl = this.apiDetails.shareUrl;
			});
				
			// 		this.store.select(fromStore.isTimelineChnaged)
			// .subscribe((isTimeline) => {
			// 		if(isTimeline && Object.keys(this.apiDetails).length > 0){
			// 			this.shareUrl = this.apiDetails.shareUrl;

					this.store.dispatch(new fromStore.GetWebminarList({
            url: this.apiDetails['user'].base_url +
                 this.apiDetails['user'].getAllWebinar.url,
            method: this.apiDetails['user'].getAllWebinar.method,
            body:{
              // isDeleted:false,
              prevCnt:'0',
              // userId : this.userId
            }
					}))
				// 	window.scrollTo(0, 0);
				// }
				// });
          // server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
    .subscribe((serverErrorMessage) => {
      if (serverErrorMessage != null) {
        if (serverErrorMessage === 'InvalidCredentials') {
          this.router.navigate(['/Login/login']);
        } else {
          this.serverMessage = serverErrorMessage;
          this.isServerErrorMessage = true;
        }
      }
    });
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
     
      });
     
    this.store
      .select(fromStore.getIsWebinarListLoading)
      .subscribe(isLoading => {
        this.isLoading = isLoading;
        if (this.count ) {
          this.isNewWebinarLoading = true;
          this.isLoading = !isLoading;
        } else {
          this.isNewWebinarLoading = false;
          this.isLoading = isLoading;
        }
      });
      this.store.select(fromStore.getLandingScreenLabels)
      .subscribe((labels) => {
        this.landingLables = labels;
      });
      this.store
      .select(fromStore.getWebinarLabels)
      .subscribe(webinarLabels => {
        this.webinarLabels = webinarLabels;
      });
      this.store
      .select(fromStore.getWebinarPaginationCount)
      .subscribe(count => {
        this.count = count;
      });
      
    this.store.select(fromStore.getIsWebinarListLoaded)
      .subscribe(isLoaded => {
        this.isLoaded = isLoaded;
        if (this.isLoaded) {

          this.store.select(fromStore.getwebinarServerErrorMessage)
            .subscribe((error) => {
              this.errMessage = error;
              if (this.errMessage) {

              }
            })
            this.store.select(fromStore.webinarListStatusCode)
            .subscribe((code) => {
              if(code == 200){
                this.store.select(fromStore.getWebinarListResult)
                .subscribe((WebinarListData) => {
                  this.WebinarListData = WebinarListData;
                  if (this.WebinarListData) {
                    this.WebinarListData.forEach((element, i) => {
                      if (element.favourite) {
                        this.WebinarListData[i] = Object.assign({}, element, { favouriteIcon: 'favorite', favourite: true })
                      } else {
                        this.WebinarListData[i] = Object.assign({}, element, { favouriteIcon: 'favorite_border', favourite: false })
                      }
                    });
                  }
                  if (this.WebinarListData.length % 10 !== 0) {
                    this.isSeeMoreButtonShow = false;
                  }
                  // this.feedsDate = new Date(WebinarListData['updatedAt']).toDateString()
                });
              }
              if(code == 500){
                this.store.select(fromStore.getErrorMessages)
                .subscribe(res => {
                  this.errorMessage = res;
                  console.error(this.errorMessage[this.selectedLanguage][code]);
                  this.noData = true;
              })
              }
             })
            }
      });
  }

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }

  async onScroll(){
    await this.store.dispatch(new fromStore.SetPaginationCountWebinar({
			dealsPaginationCount: this.count + 1
		}));
			if (Object.keys(this.apiDetails).length > 0) {        
       await  this.store.dispatch(new fromStore.GetWebminarList({
          url: this.apiDetails['user'].base_url +
               this.apiDetails['user'].getAllWebinar.url,
          method: this.apiDetails['user'].getAllWebinar.method,
          body:{
            isDeleted:false,
            prevCnt: JSON.stringify(this.count)
          }
        }))	
		}
		if (this.WebinarListData.length % 10 !== 0) {
			this.isSeeMoreButtonShow = false;
		}

  }

  redirectToWebinarDetail(item){
    this.router.navigate(['/cep/feeds/webinar/'+item.webinarId]);
  }
  	/**
	 * Created By Akshata C
	 * Notification section
	 * Created date: 21/05/2020
	 */
	returnedDate(updatedAtTime) {
		this.dateOfMsg = (new Date(updatedAtTime)).getDate() + '/' + (new Date(updatedAtTime)).getMonth() + '/' + (new Date(updatedAtTime)).getFullYear()
		return this.dateOfMsg;
		}

	/**
	 * Created by Vinita Sharma
	 * for reporting blog as abuse
	 */
	REPORT_ABUSE(webinarData) {   
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				webinarId: webinarData.webinarId,
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
  }

/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media, data) {    
		var url = this.shareUrl + "/cep/feeds/webinar/" + data.webinarId
		var metaTag  = {
			socialTitle: data.en.title,
			socialImageLink: data.image
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}


    	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
  unMarkFavorite(data, i) {
    if (data.favourite) {
      this.WebinarListData[i] = Object.assign({}, data, 
        { favourite: false , favouriteIcon:'favorite_border'})   
    } else {
      this.WebinarListData[i] = Object.assign({}, data, 
        { favourite: true, favouriteIcon:'favorite' })
    }
    if (Object.keys(this.apiDetails).length > 0) {
      if (this.apiDetails) {
        if (this.userId) {
          this.store.dispatch(new fromStore.MarkFavoriteWebinar({
          url: this.apiDetails['user'].base_url +
               this.apiDetails['user'].addToFavoriteWebinar.url,
          method: this.apiDetails['user'].addToFavoriteWebinar.method,
          body: {
						userId: this.userId,
            webinarId: data['webinarId'],
					}
          }));
        }
      }
    }
  }


  ngAfterContentChecked() {
    this.changeDetectRef.detectChanges()
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {

  }

  clickOnShare() {
    //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of news', 'Click Event', 1);
  }

}
