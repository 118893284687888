import { Component, OnInit } from '@angular/core';
import { Images } from '../../config/images-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-api-response',
  templateUrl: './no-api-response.component.html',
  styleUrls: ['./no-api-response.component.scss']
})
export class NoApiResponseComponent implements OnInit {
  images=Images;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }

  /**
   * created by Ajay C
   * FOr redirecting to feeds page
   */
  redirectToHome(){
    this.router.navigate(['cep/home']);
  }
}
