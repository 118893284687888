import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from '../../../../cep/store/index';
import { Store } from '@ngrx/store';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { LocalStorageService } from '../../../services/localstorage.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-remove-from-cart-popup',
  templateUrl: './remove-from-cart-popup.component.html',
  styleUrls: ['./remove-from-cart-popup.component.scss']
})
export class RemoveFromCartPopupComponent implements OnInit, AfterContentChecked, OnDestroy {
	public images = Images;
	constants = AppConstants;
	deviceInfo = null;
	public topic;
	selectedLanguage;
	contactUsLabel;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading = false;
	isLoaded = false;
	contactNo: {};
	device: string = null;
	apiDetails: {};
	isNetworkError = false;
	contactUsResult: {};
	serverErrorMessage: string;
	shareUrl: any;
	myCartLables:{};
	/**
	 * user id
	 */
	userId: any;
	/**
	 * user token
	 */
	token: string;
	successMessages: {};
	errormessage: {};
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		public router: Router,
		private store: Store<fromStore.CEPState>,
		private dialogRef: MatDialogRef<RemoveFromCartPopupComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private encrDecr: EncrDecrService,
		private localStorageService: LocalStorageService,
		private toastr: ToastrService
	) {
		dialogRef.disableClose = true;
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
	}

	ngOnInit() {
		this.data = this.data.label
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
			if (Object.keys(this.apiDetails).length > 0) {
				this.shareUrl = this.apiDetails['shareUrl'];
			}
		});
		this.store.select(fromStore.getSuccessMessages)
		.subscribe(res => {
			this.successMessages = res;
		});
		this.store.select(fromStore.getErrorMessages)
		.subscribe(res => {
			this.errormessage = res;
		});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getMyCartLabels)
				.subscribe(labels => {
					this.myCartLables = labels;
				});
			});
			 // token from localstorage
		this.store.select(fromStore.getToken)
		.subscribe((token) => {
		  this.token = token;
		  if (this.token) {
			const localUserId = this.localStorageService.getUserId();
			this.userId = this.encrDecr.get(localUserId);
		  }
		});
		
	}

	/**
	 * Created by Akshata C
	 * For remove cart data
	 */
	async removeFromCart() {
		if (Object.keys(this.apiDetails).length > 0) {
			await this.store.dispatch(new fromStore.RemoveItemFromCart({
				url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].removeItemFromCart.url,
				method: this.apiDetails['user'].removeItemFromCart.method,
				body:  {
				userId: this.userId,
				engagementId: this.data.engagementId,
				quantity: this.data.quantity,
				loyaltySpend: 0
				}
			}));

			//toaster for remove cart
			this.store.select(fromStore.getSelectedLanguage)
			.subscribe(lang => {
				this.selectedLanguage = lang;
				if (this.selectedLanguage) {
							this.store.select(fromStore.removeItemFromCartStatusCode)
							.subscribe(code => {
							this.store.select(fromStore.removeItemFromCartSuccessMessage)
								.pipe(take(1))
								.subscribe(errorMessages => {
									if(errorMessages) {
										switch(code){
											case 200:
												this.toastr.success(this.successMessages[this.selectedLanguage][errorMessages]);
												this.router.navigate(['/cep/offers']);
												this.dialog.closeAll();
												break;
											default:
												this.toastr.success(this.errormessage[this.selectedLanguage][code]);
												this.router.navigate(['/cep/offers']);
												this.dialog.closeAll();
												break;
										}
									}
									
								});
							});
				}
			});
		}
	}

	/**
	 * Created by Akshata C
	 * Add to fav
	 */
	async addToFav() {
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails['shareUrl'];
			if (this.userId) {
				await this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails["user"].base_url +
						this.apiDetails["user"].addToFavourite.url,
					method: this.apiDetails["user"].addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.data.engagementId,
						engagementType: this.data.engagementType
					}
				}));
			}
		}
	}
	/**
	 * Created by Akshata C
	 * For closing the popup
	 */
	closeDialog() {
		const dialogClose = this.dialog.closeAll();
	}

	ngAfterContentChecked() {
		// this.ref.detectChanges();
	}

	ngOnDestroy() {
		this.store.dispatch(new fromStore.resetCart({
			cartReset:{}
		}))
		// this.serverErrorMessage = null;
	}
}


