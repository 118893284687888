import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromContactUs from '../../store/reducer/contact-us.reducer';

export const getContactUsState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.contactUs
);

export const getContactUsResult = createSelector(
	getContactUsState,
	fromContactUs.getContactUsResult
);

export const getIsContactUsLoading = createSelector(
	getContactUsState,
	fromContactUs.getIsContactUsLoading
);
export const getIsContactUsLoaded = createSelector(
	getContactUsState,
	fromContactUs.getIsContactUsLoaded
);

export const getcontactUsServerErrorMessage = createSelector(
	getContactUsState,
	fromContactUs.getcontactUsServerErrorMessage
);


