import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../cep/store/index';
@Injectable()
export class MessagingService {
    
        currentMessage = new BehaviorSubject(null);
        currentMessage$ = this.currentMessage.asObservable();

        constructor(private angularFireMessaging: AngularFireMessaging,private store: Store<fromStore.CEPState>,) {
            // this.angularFireMessaging.messaging.subscribe(
            //     (_messaging) => {
            //         _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            //         _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            //     }
            // )

            this.angularFireMessaging.messages.subscribe(
                (message) => {
                  console.log('New message received: ', message);
                  this.currentMessage.next(message);
                });
        } 
        requestPermission() {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                
                    this.store.dispatch( new fromStore.FcmToken({
                        token
                    }) )
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                }
            );
        } 
        receiveMessage() {
            this.angularFireMessaging.messages.subscribe(
                (payload) => {
                    console.log("New Message",payload)
                    this.currentMessage.next(payload);
                })
        }

        handleNotificationClick(url: string) {
            // Navigate to the URL mentioned in the notification
            window.open(url)
        
            // Call the log API to record the click event
            this.logNotificationClick(url);
          }
        
          logNotificationClick(url: string) {
            // Your logic to call the API to log the notification click
            console.log(`Notification clicked: ${url}`);
            // Example API call using HttpClient (you need to import HttpClient)
            // this.http.post('/api/logNotificationClick', { url }).subscribe();
          }

}