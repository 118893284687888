import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { MatDialog } from '@angular/material/dialog';
import { PopupQrScanComponent } from '../popup-qr-scan/popup-qr-scan.component';
import { AppConstants } from '../../config/app-constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FeedbackComponent } from '../feedback/feedback.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { Router } from '@angular/router';
import { termsAndConditionsStatusCode } from 'src/app/cep/login-management/store/reducer/login.reducer';
import { ToastrService } from 'ngx-toastr';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterContentChecked {
	images = Images;
	constants = AppConstants;
	isLoaded = false;
	selectedLanguage: string;
	contactUsLabels: any;
	drawer: {};
	apiDetails = {};
	contactDetails;
	url: string;
	drawerLabel: {};
	footerLabel: {};
	contactLabels: {};
	device: string;
	token: string;
	userId: string;
	isTAndC = false;
	termsAndConditionsStatusCode: number;
	termsAndConditions: {};
	aboutUsLabels: any;
	serverMessage: any;
	applicationUrls: {};
	landingLables: {};
	appLinks: {};
	isBlind;
	playStoreUrl = 'https://fxurl.co/rFshd';
	AppStoreUrl = 'https://fxurl.co/4IJJ123';
	constructor(
		private changeDetectRef: ChangeDetectorRef,
		private deviceService: DeviceDetectorService,
		private store: Store<fromStore.CEPState>,
		public dialog: MatDialog,
		public router: Router,
		private googleAnalyticsService: GoogleAnalyticsService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public toastr: ToastrService
	) { }

	ngOnInit() {
		this.store.select(fromStore.getAppLinks)
			.subscribe(res => {
				this.appLinks = res;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.GetContactUsDetails({
						method: this.apiDetails['applicationConfigurations'].contactUs.getContactUsDetails.method,
						url: this.apiDetails['applicationConfigurations'].base_url +
							this.apiDetails['applicationConfigurations'].contactUs.getContactUsDetails.url
					}));
				}
			});

		// server error handling
		this.serverMessage = null;
		this.store.select(fromStore.getcontactUsServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverError;
						if (this.serverMessage) {
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
			});

		this.store.select(fromStore.getIsContactUsLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getContactUsResult)
						.subscribe((labels) => {
							if (labels) {
								this.contactLabels = labels['PCMCSmartSarathi'];
								this.contactDetails = labels['sarathi'];
							}
						});
				}
			});

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on blog by ' + this.userId, 'userLabel', 1);
				} else {
					this.userId = null;
				}
			});

		this.store.select(fromStore.getIsLabelLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getLandingScreenLabels)
						.subscribe((tabLabels) => {
							this.drawer = tabLabels;
						});

					this.store.select(fromStore.getFeedsLabels)
						.subscribe(labels => {
							this.landingLables = labels;
						});

					this.store.select(fromStore.getContactUsLabels)
						.subscribe((res) => {
							this.contactUsLabels = res;
						});

					this.store.select(fromStore.getDrawerLabels)
						.subscribe((drawerLabels) => {
							this.drawerLabel = drawerLabels;
						});

					this.store.select(fromStore.getFooterLabels)
						.subscribe((footerLabel) => {
							this.footerLabel = footerLabel;
					
						});

					this.store.select(fromStore.getApplicationUrls)
						.subscribe(urls => {
							this.applicationUrls = urls;
						});
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getAboutUsLabels)
					.subscribe(response => {
						this.aboutUsLabels = response[this.selectedLanguage];
					});
			});

		// device detection code
		const deviceInfo = this.deviceService.getDeviceInfo();
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktopDevice = this.deviceService.isDesktop();
		if (isMobile) {
			this.device = 'mobile';
		} else if (isTablet) {
			this.device = 'tablet';
		} else if (isDesktopDevice) {
			this.device = 'desktop';
		}

	}



/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }




	// Terms and conditions link
	openNewWindow() {
		// window.open('../../../assets/terms/terms-and-conditions.pdf');
		this.router.navigate(['/cep/terms-condition'])
	}

	// Privacy Policy Link
	openNewWindow1(){
		// window.open('../../../assets/terms/privacy-policy.pdf');
		this.router.navigate(['/cep/policy'])
	}

	// QR scanner for play store
	OpenQRScanPopup() {
		const isMobile = this.deviceService.isMobile();
		// if (isMobile) {
		// 	window.location.href = 'https://pcmcsmartsarathi.page.link/jKYg';
		// } else {
			this.dialog.open(PopupQrScanComponent, {
				autoFocus: false
			});
		// }

	}

	//Tendor Open
	openTendor(){
		window.open('https://www.mahatenders.gov.in/nicgep/app')
	}

	// Footer social accounts links
	redirectToTwitter() {
		window.open('https://twitter.com/pmccarepune');
	}
	redirectToFacebookPage() {
		window.open("https://www.facebook.com/PMCCarePune");
	}
	redirectToInstagram() {
		window.open('https://www.instagram.com/pmccarepune/');
	}
	redirectToLinkedIn() {
		window.open(this.contactLabels['linkedinLink']);
	}

	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	// popup for feedback
	openFeedback() {
		if (this.userId) {
			const dialogRef = this.dialog.open(FeedbackComponent,
				{
					maxHeight: '100vh',
					maxWidth: '100vw',
					autoFocus: false
				});
		} else {
			this.router.navigate(['/Login/login']);
		}
	}
}
