import { Component, OnInit } from '@angular/core';
import { Images } from '../../config/images-config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupQrScanComponent } from '../popup-qr-scan/popup-qr-scan.component';

@Component({
  selector: 'app-redirect-to-store',
  templateUrl: './redirect-to-store.component.html',
  styleUrls: ['./redirect-to-store.component.scss']
})
export class RedirectToStoreComponent implements OnInit {
  images = Images;
  deviceInfo = null;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private deviceService: DeviceDetectorService,
    private dialogRef: MatDialogRef<RedirectToStoreComponent>,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const dialogClose = this.dialog.closeAll();
  }

  /**
   * Created by Ajay C
   * For redirecting to Appication on play store
   */
  openUrlForAndroid() {
    const isMobile = this.deviceService.isMobile();
		if (isMobile) {
			window.location.href = 'https://pcmcsmartsarathi.page.link/jKYg';
		} else {
			this.dialog.open(PopupQrScanComponent, {
				autoFocus: false
			});
		}
  }

/**
 * Created by Ajay C
 * For redirecting to Application on App store
 */
  openUrlForIphone() {
    const isMobile = this.deviceService.isMobile();
		if (isMobile) {
			window.location.href = 'https://pcmcsmartsarathi.page.link/jKYg';
		} else {
			this.dialog.open(PopupQrScanComponent, {
				autoFocus: false
			});
		}
  }

  /**
 * Created by Ajay C
 * For closing the popup
 */
  // closeDialog() {
  //   const dialogClose = this.dialog.closeAll();
  // }
}
