import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class DynamicLinksService {
    constructor(private http: HttpClient,) {}
    private apiUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${environment.firebase.apiKey}`;

async generateDynamicLink(destinationUrl: string) {
  let ios = {
    bundleId: 'com.pmc.pmccareios',
  }
  let android = {
    packageName: 'in.gov.pmc.pmccare',
  }
  let domainUriPrefix = 'https://pmccare.page.link'

  const requestBody = {
    longDynamicLink: `${domainUriPrefix}/?apn=${android.packageName}&ibi=${ios.bundleId}&link=${destinationUrl}`,
  };

  const response: any = await this.http.post(this.apiUrl, requestBody).toPromise();
    console.log("Response",response)
    return response.shortLink;

  // const requestBody = {
  //   "url": `${domainUriPrefix}/?apn=${android.packageName}&ibi=${ios.bundleId}&link=${destinationUrl}`,
  //   "title": "",
  //   "clientId": "64d33db5b14a1fd04deb7dac",
  //   "clientName": "PMC Care",
  //   "description": "",
  //   "loginUserId": "63c6418822d9b9ddee9cc927"
  // }

  // const response: any = await this.http.post('https://fxurl.co/v1/shorten', requestBody).toPromise();
  // console.log("URL ShortLink",requestBody.url)
  // return `https://fxurl.co/${response.shortCode}`;
  }
}
