import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})

export class GroupService {

	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) { }

	getGroupDetails(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
    }
    
	getAllGroups(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

    acceptPublicGroupInvitation(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
    }

    createMessage(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
    }

    getAllUserGroups(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
    }
}
