import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromListing from '../reducer/constants.reducer';

export const getListingState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.constants
);

export const getBackgroundColor = createSelector(
	getListingState,
	fromListing.getBackgroundColor
);

export const getBannerImages = createSelector(
	getListingState,
	fromListing.getBannerImages
);

export const getHeaderLabels = createSelector(
	getListingState,
	fromListing.getHeaderLabels
);

export const getApiCallingList = createSelector(
	getListingState,
	fromListing.getApiCallingList
);
export const getfeature = createSelector(
	getListingState,
	fromListing.getfeature
);
export const getAppLinks = createSelector(
	getListingState,
	fromListing.getAppLinks
);
export const getIsLabelLoading = createSelector(
	getListingState,
	fromListing.getIsLabelLoading
);
export const getIsLabelLoaded = createSelector(
	getListingState,
	fromListing.getIsLabelLoaded
);
export const NearMeMapLabels = createSelector(
	getListingState,
	fromListing.getNearMeMapLabels
);
export const getDrawerLabels = createSelector(
	getListingState,
	fromListing.getDrawerLabels
);
export const getfeedsFilterLabel = createSelector(
	getListingState,
	fromListing.getfeedsFilterLabel
);
export const getDeathLabels = createSelector(
	getListingState,
	fromListing.getDeathLabels
);
export const getbirthLabels = createSelector(
	getListingState,
	fromListing.getbirthLabels
);
export const getErrorMessages = createSelector(
	getListingState,
	fromListing.getErrorMessages
);
export const getSuccessMessages = createSelector(
	getListingState,
	fromListing.getSuccessMessages
);
export const getAreaLabels = createSelector(
	getListingState,
	fromListing.getAreaLabels
);

export const getAreaAndZones = createSelector(
	getListingState,
	fromListing.getAreaAndZones
);

export const getPasswordScreenLabels = createSelector(
	getListingState,
	fromListing.getPasswordScreenLabels
);

export const getSplashScreenLabels = createSelector(
	getListingState,
	fromListing.getSplashScreenLabel
);

export const getRegisterScreenLabels = createSelector(
	getListingState,
	fromListing.getRegisterScreenLabels
);

export const getOtpScreenLabels = createSelector(
	getListingState,
	fromListing.getOtpScreenLabels
);

export const getMobileScreenLabels = createSelector(
	getListingState,
	fromListing.getMobileScreenLabels
);

export const getLoginScreenLabels = createSelector(
	getListingState,
	fromListing.getLoginScreenLabels
);

export const getOnboardingLabels = createSelector(
	getListingState,
	fromListing.getOnboardingLabels
);

export const getLandingScreenLabels = createSelector(
	getListingState,
	fromListing.getLandingScreenLabels
);

export const getContactUsLabels = createSelector(
	getListingState,
	fromListing.getContactUsLabels
);

export const getAboutUsLabels = createSelector(
	getListingState,
	fromListing.getAboutUsLabels
);

export const getFeedsLabels = createSelector(
	getListingState,
	fromListing.getFeedsLabels
);

export const getVerifyOtpScreenLabels = createSelector(
	getListingState,
	fromListing.getVerifyOtpScreenLabels
);

export const getBlogLabels = createSelector(
  getListingState,
  fromListing.getBlogLabels
);
export const getForumLabels = createSelector(
  getListingState,
  fromListing.getForumLabels
)
export const getlabelEnableDetails = createSelector(
	getListingState,
	fromListing.getLabelEnableDetails
);
export const getServicesListLabel = createSelector(
	getListingState,
	fromListing.getServicesListLabel
);

export const getNearMeEnableLableLoading = createSelector(
	getListingState,
	fromListing.getNearMeEnableLableLoading
);

export const getApiErrorMessage = createSelector(
	getListingState,
	fromListing.getErrorMessage
);

export const getServiceHeadings = createSelector(
	getListingState,
	fromListing.getServiceHeadings
);

export const getCityInfoLabels = createSelector(
	getListingState,
	fromListing.getCityInfoLabels
);

export const getprofileScreenLabels = createSelector(
	getListingState,
	fromListing.getprofileScreenLabels
);

export const getFooterLabels = createSelector(
	getListingState,
	fromListing.getFooterLabels
);

export const getPropertyTaxLabels = createSelector(
	getListingState,
	fromListing.getPropertyTaxLabels
);

export const getWaterTaxLabels = createSelector(
	getListingState,
	fromListing.getWaterTaxLabels
);

export const getIntroducionImagesLabels = createSelector(
	getListingState,
	fromListing.getIntroducionImagesLabels
)
export const getMobileIntroducionImagesLabels = createSelector(
	getListingState,
	fromListing.getMobileIntroducionImagesLabels
)

export const getHotFeedbackResult = createSelector(
	getListingState,
	fromListing.getHotFeedbackResult
);

export const hotFeedbackStatus = createSelector(
	getListingState,
	fromListing.hotFeedbackStatus
);

export const hotFeedbackMessage = createSelector(
	getListingState,
	fromListing.hotFeedbackMessage
);


export const getHotFeedbackLoading = createSelector(
	getListingState,
	fromListing.getHotFeedbackLoading
);

export const getHotFeedbackLoaded = createSelector(
	getListingState,
	fromListing.getHotFeedbackLoaded
);

export const getPreferncesList = createSelector(
	getListingState,
	fromListing.getPreferncesList
);

export const getChatbotKeyResult = createSelector(
	getListingState,
	fromListing.getChatbotKeyResult
);

export const getChatbotKeyLoading = createSelector(
	getListingState,
	fromListing.getChatbotKeyLoading
);

export const getChatbotKeyLoaded = createSelector(
	getListingState,
	fromListing.getChatbotKeyLoaded
);

export const getStaticImages = createSelector(
	getListingState,
	fromListing.getStaticImages
);

export const getStaticNamesListForSubHeader = createSelector(
	getListingState,
	fromListing.getStaticNamesListForSubHeader
);

export const getStaticUrlsListForSubHeader = createSelector(
	getListingState,
	fromListing.getStaticUrlListForSubHeader
);

export const getWeatherStatus = createSelector(
	getListingState,
	fromListing.getWeatherStatus
);

export const getWeatherStatusCode = createSelector(
	getListingState,
	fromListing.getWeatherStatusCode
);

export const getWeatherErrorMessage = createSelector(
	getListingState,
	fromListing.getWeatherStatusErrorMessage
);

export const getIsWatherLoading = createSelector(
	getListingState,
	fromListing.getIsWatherLoading
);

export const getIsWatherLoaded = createSelector(
	getListingState,
	fromListing.getIsWatherLoaded
);

export const getBlockUserMessage = createSelector(
	getListingState,
	fromListing.getBlockUserMessage
);

export const getApplicationUrls = createSelector(
	getListingState,
	fromListing.getApplicationUrls
);

export const getMerchantAndBrandLabels = createSelector(
	getListingState,
	fromListing.getMerchantAndBrandLabels
);

export const getSortByListing =  createSelector(
	getListingState,
	fromListing.getSortByListing
);

export const getMoreServicesLabels =  createSelector(
	getListingState,
	fromListing.getMoreServicesLabels
);  

export const getMyCartLabels =  createSelector(
	getListingState,
	fromListing.getMyCartLabels
);

export const getMyDealsLabels =  createSelector(
	getListingState,
	fromListing.getMyDealsLabels
); 

export const getMyLoyaltyLabels =  createSelector(
	getListingState,
	fromListing.getMyLoyaltyLabels
); 

export const getAddDealsLabels =  createSelector(
	getListingState,
	fromListing.getAddDealsLabels
);  

export const getSortByLabels = createSelector(
	getListingState,
	fromListing.getSortByLabels
);

export const getGroupsLabels = createSelector(
	getListingState,
	fromListing.getGroupsLabels
);

export const getSortByGrievanceLabels = createSelector(
	getListingState,
	fromListing.getSortByGrievanceLabels
);

export const getGrievanceLabels = createSelector(
	getListingState,
	fromListing.getGrievanceLabels
);

export const getRemoveExistDeallabels = createSelector(
	getListingState,
	fromListing.getRemoveExistDeallabels
);
export const getNewsletterLabels = createSelector(
	getListingState,
	fromListing.getNewsletterLabels
);
export const getWebinarLabels = createSelector(
	getListingState,
	fromListing.getWebinarLabels
);
export const getComplanitDashboardLabels = createSelector(
	getListingState,
	fromListing.getComplanitDashboardLabels
);
export const getComplanitListLabels = createSelector(
	getListingState,
	fromListing.getComplanitListLabels
);
export const getDashboardLinks = createSelector(
	getListingState,
	fromListing.getDashboardLinks
);
export const getComplanitDetailsLabels = createSelector(
	getListingState,
	fromListing.getComplanitDetailsLabels
);

export const getActionDashboardLabels = createSelector(
	getListingState,
	fromListing.getActionDashboardLabels
);
export const getInsructionPopupLabels = createSelector(
	getListingState,
	fromListing.getInsructionPopupLabels
);
export const getSGYLabels = createSelector(
	getListingState,
	fromListing.getSGYLabels
);
export const getZooBookingLabels = createSelector(
	getListingState,
	fromListing.getZooBookingLabels
);
export const getDBTLabels = createSelector(
	getListingState,
	fromListing.getDBTLabels
);

export const getAntiRabiesVaccinatedLabels = createSelector(
	getListingState,
	fromListing.getAntiRabiesVaccinatedLabels
);
export const getCatAgeRangesLabels = createSelector(
	getListingState,
	fromListing.getCatAgeRangesLabels
);
export const getCatBreedsLabels = createSelector(
	getListingState,
	fromListing.getCatBreedsLabels
);
export const getCatColorsLabels = createSelector(
	getListingState,
	fromListing.getCatColorsLabels
);
export const getCatTenureLabels = createSelector(
	getListingState,
	fromListing.getCatTenureLabels
);
export const getDogAgeRangesLabels = createSelector(
	getListingState,
	fromListing.getDogAgeRangesLabels
);
export const getDogBreedsLabels = createSelector(
	getListingState,
	fromListing.getDogBreedsLabels
);
export const getDogColorsLabels = createSelector(
	getListingState,
	fromListing.getDogColorsLabels
);
export const getDogTenureLabels = createSelector(
	getListingState,
	fromListing.getDogTenureLabels
);
export const getGenderOptionsLabels = createSelector(
	getListingState,
	fromListing.getGenderOptionsLabels
);
export const getYesNoOptionsLabels = createSelector(
	getListingState,
	fromListing.getYesNoOptionsLabels
);
export const getHousingOwnershipLabels = createSelector(
	getListingState,
	fromListing.getHousingOwnershipLabels
);
export const getPetDocUploadlabels = createSelector(
	getListingState,
	fromListing.getPetDocUploadlabels
);
export const getDogApplicationLabels = createSelector(
	getListingState,
	fromListing.getDogApplicationLabels
);
export const getCatApplicationLabels = createSelector(
	getListingState,
	fromListing.getCatApplicationLabels
);
export const getPetHomePageLabels = createSelector(
	getListingState,
	fromListing.getPetHomePageLabels
);
