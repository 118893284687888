import { Action } from '@ngrx/store';

export const GET_ABOUT_US_DETAILS = 'GET_ABOUT_US_DETAILS';
export const GET_ABOUT_US_DETAILS_SUCCESS = 'GET_ABOUT_US_DETAILS_SUCCESS';
export const GET_ABOUT_US_DETAILS_FAIL = 'GET_ABOUT_US_DETAILS_FAIL';

export const GET_COMMISSIONER_DETAILS = 'GET_COMMISSIONER_DETAILS';
export const GET_COMMISSIONER_DETAILS_SUCCESS = 'GET_COMMISSIONER_DETAILS_SUCCESS';
export const GET_COMMISSIONER_DETAILS_FAIL = 'GET_COMMISSIONER_DETAILS_FAIL';

export const GET_CITY_DETAILS = 'GET_CITY_DETAILS';
export const GET_CITY_DETAILS_SUCCESS = 'GET_CITY_DETAILS_SUCCESS';
export const GET_CITY_DETAILS_FAIL = 'GET_CITY_DETAILS_FAIL';

export const GET_TOURISM_DETAILS = 'GET_TOURISM_DETAILS';
export const GET_TOURISM_DETAILS_SUCCESS = 'GET_TOURISM_DETAILS_SUCCESS';
export const GET_TOURISM_DETAILS_FAIL = 'GET_TOURISM_DETAILS_FAIL';

export const GET_CORPORATES_DETAILS = 'GET_CORPORATES_DETAILS';
export const GET_CORPORATES_DETAILS_SUCCESS = 'GET_CORPORATES_DETAILS_SUCCESS';
export const GET_CORPORATES_DETAILS_FAIL = 'GET_CORPORATES_DETAILS_FAIL';

export const GET_ZONE_DETAILS = 'GET_ZONE_DETAILS';
export const GET_ZONE_DETAILS_SUCCESS = 'GET_ZONE_DETAILS_SUCCESS';
export const GET_ZONE_DETAILS_FAIL = 'GET_ZONE_DETAILS_FAIL';


export class GetAboutUsDetails implements Action {
		readonly type = GET_ABOUT_US_DETAILS;
	constructor(public payload: any) {}
}
export class GetAboutUsDetailsSuccess implements Action {
		readonly type = GET_ABOUT_US_DETAILS_SUCCESS;
	constructor(public payload: any) {}
}
export class GetAboutUsDetailsFail implements Action {
		readonly type = GET_ABOUT_US_DETAILS_FAIL;
	constructor(public payload: any) {}
}


export class GetCommissionerDetails implements Action {
	readonly type = GET_COMMISSIONER_DETAILS;
constructor(public payload: any) {}
}
export class GetCommissionerDetailsSuccess implements Action {
	readonly type = GET_COMMISSIONER_DETAILS_SUCCESS;
constructor(public payload: any) {}
}
export class GetCommissionerDetailsFail implements Action {
	readonly type = GET_COMMISSIONER_DETAILS_FAIL;
constructor(public payload: any) {}
}


export class GetCityDetails implements Action {
	readonly type = GET_CITY_DETAILS;
constructor(public payload: any) {}
}
export class GetCityDetailsSuccess implements Action {
	readonly type = GET_CITY_DETAILS_SUCCESS;
constructor(public payload: any) {}
}
export class GetCityDetailsFail implements Action {
	readonly type = GET_CITY_DETAILS_FAIL;
constructor(public payload: any) {}
}


export class GetTourismDetails implements Action {
	readonly type = GET_TOURISM_DETAILS;
constructor(public payload: any) {}
}
export class GetTourismDetailsSuccess implements Action {
	readonly type = GET_TOURISM_DETAILS_SUCCESS;
constructor(public payload: any) {}
}
export class GetTourismDetailsFail implements Action {
	readonly type = GET_TOURISM_DETAILS_FAIL;
constructor(public payload: any) {}
}

export class GetCorporatesDetails implements Action {
	readonly type = GET_CORPORATES_DETAILS;
constructor(public payload: any) {}
}
export class GetCorporatesDetailsSuccess implements Action {
	readonly type = GET_CORPORATES_DETAILS_SUCCESS;
constructor(public payload: any) {}
}
export class GetCorporatesDetailsFail implements Action {
	readonly type = GET_CORPORATES_DETAILS_FAIL;
constructor(public payload: any) {}
}

export class GetZoneDetails implements Action {
	readonly type = GET_ZONE_DETAILS;
constructor(public payload: any) {}
}
export class GetZoneDetailsSuccess implements Action {
	readonly type = GET_ZONE_DETAILS_SUCCESS;
constructor(public payload: any) {}
}
export class GetZoneDetailsFail implements Action {
	readonly type = GET_ZONE_DETAILS_FAIL;
constructor(public payload: any) {}
}

export type aboutUsAction = GetAboutUsDetails | GetAboutUsDetailsSuccess | GetAboutUsDetailsFail
			| GetCommissionerDetails | GetCommissionerDetailsSuccess | GetCommissionerDetailsFail
			| GetCityDetails | GetCityDetailsSuccess | GetCityDetailsFail
			| GetTourismDetails | GetTourismDetailsSuccess | GetTourismDetailsFail
			| GetCorporatesDetails | GetCorporatesDetailsSuccess | GetCorporatesDetailsFail
			| GetZoneDetails | GetZoneDetailsSuccess | GetZoneDetailsFail;
