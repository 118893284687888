import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from '../../../shared/services/localstorage.service';

@Component({
  selector: 'app-introduction-popup',
  templateUrl: './introduction-popup.component.html',
  styleUrls: ['./introduction-popup.component.scss']
})
export class IntroductionPopupComponent implements OnInit {
  /**
   * selected Language variable
   */
  selectedLanguage;
  /**
   * landing label object
   */
  landingLables;
  isBlind;
  /**
   * popup images
   */
  isDesktopDevice=true;
  introImages: {};
  constructor(
    private dialogRef: MatDialogRef<IntroductionPopupComponent>,
    private store: Store<fromStore.CEPState>,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceDetectorService,
  ) {
    dialogRef.disableClose = false;
  }

  ngOnInit() {

    const dialogClose = this.dialog.closeAll();
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
    this.selectedLanguage === 'en' ? this.speakText("Welcome to Pune Smart City") : this.speakText("पुणे स्मार्ट सिटी मध्ये आपले स्वागत आहे")
        if (this.selectedLanguage) {
          this.isDesktopDevice = this.deviceService.isDesktop();
          if(this.isDesktopDevice){
            this.store.select(fromStore.getIntroducionImagesLabels)
            .subscribe((introImage) => {
              this.introImages = introImage;
            });
          }else{
            this.store.select(fromStore.getMobileIntroducionImagesLabels)
            .subscribe((introImage) => {
              this.introImages = introImage;
            
            });
          }
         
        }
      });
  }
/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }



  /**
   * Created by Akshata C
   * For closing the popup
   */
  // closeDialog() {
  //   const dialogClose = this.dialog.closeAll();
  //   //this.dialogRef.close();
  // }

}
