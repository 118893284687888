/**
 * Created by: Kiran G
 * Updated by: Akanksha J
 * Component:  Dynamic Api calling service
 * Updated date: 20/12/2019
 */

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as fromStore from '../../cep/store/index';
import { Store } from '@ngrx/store';
import { DeviceDetectorService} from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
// import { LogoutService } from 'src/app/cep/dashboard-management/store/api-services/logout.service';
import { EncrDecrService } from './encr-decr.service';
import { LocalStorageService } from './localstorage.service';
import { EncrDecrUtil } from 'src/app/util/EncrDecr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  token: string;
  userId: any;
  private validateInterval: any;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private http: HttpClient,
		private deviceService: DeviceDetectorService,
    private store: Store<fromStore.CEPState>,
    private toastr: ToastrService,
    private router: Router,
    private EncrDecr : EncrDecrService,
    private localStorage: LocalStorageService
  ) {

    this.store.select(fromStore.getToken)
      .subscribe((token) => {        
        this.token = token;  
        this.startValidationInterval();     
      });
      
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.validateInterval);
  }

  startValidationInterval() {
    if(this.token){
      this.validateInterval = setInterval(() => {
        this.validate(this.token);
      }, 3000);
    }
  }

  private apiCallInProgress: boolean = false;
  private logoutInProgress: boolean = false;

  async validate(token?: string): Promise<boolean> {  
      const encrDecr = new EncrDecrUtil();
      const encryptedAuthenticationKey = localStorage.getItem('authenticationKey');
      
      let authenticationKey = encryptedAuthenticationKey === null || encryptedAuthenticationKey === undefined ? null : JSON.parse(encrDecr.get(encryptedAuthenticationKey));  
    
      const currentTime = new Date().getTime();
    
      if (
          authenticationKey == null ||
          isNaN(parseInt(authenticationKey)) ||
          (currentTime - parseInt(authenticationKey)) >  3 * 1000
      ) {
          if (!this.apiCallInProgress) {
              this.apiCallInProgress = true;
              try {
                  if (this.logoutInProgress) {
                    this.apiCallInProgress = false; 
                    return false; 
                }
                  const headersOptions = new HttpHeaders({
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Methods': 'GET',
                      Authorization: token[0] === 'e' ? 'jwt ' + token : 'jwt ' + encrDecr.get(token) ,
                  });   
    
                  const response:any = await this.http.post(environment.base_url + '/authenticationConfiguration/v1/checkAuthUser', {}, { headers: headersOptions }).toPromise();                
                
                  if (!response) {
                      this.toastr.error("Error: Cannot verify user!");
                      this.apiCallInProgress = false;
                      return false;
                  }
    
                  if (response.code === 200) {                     
                      const newAuthenticationKey =JSON.stringify(new Date().getTime());
                      const encryptedNewAuthenticationKey = encrDecr.set(newAuthenticationKey);
                      localStorage.setItem('authenticationKey', encryptedNewAuthenticationKey);
                     
                      this.apiCallInProgress = false;
                      return true;
                  } 
                  else if (response.code === 401) {
                     
                      if (!this.logoutInProgress) {
                        const userId = encrDecr.get(this.localStorage.getItem("userId"));

                      const LogOUTOPTIONS = {
                          url: "https://api.digipmc.foxberry.link/user/v1/logOut",
                          method: "post",
                          body: {
                              userId: userId,
                          },
                      };
                    
                       this.http.post(LogOUTOPTIONS.url, LogOUTOPTIONS.body).subscribe( (res:any) => {
                        if(res.code === 1003){
                          this.token = null;
                          setTimeout(() => {
                                  window.location.href = '/cep/home';
                              //  this.router.navigate(['/Login/login'])
                          }, 100);
                          localStorage.clear();
                          this.toastr.error("Authentication expired");
                          this.apiCallInProgress = false;
                        }
                       } )
                       return false;
                      }
                  }
              } catch (error) {
                  console.error('Error validating token:', error);
                  this.apiCallInProgress = false;
                  return false;
              }
          } else {             
              // If API call is already in progress, wait for response
              return new Promise((resolve) => {
                  const interval = setInterval(() => {
                      if (!this.apiCallInProgress) {
                          clearInterval(interval);
                          resolve(this.validate(token));
                      }
                  }, 1000);
              });
          }
      } else {
          return false;
      }
  } 
  

  apiCall(method, url, body) {
    this.userId = this.localStorage.getItem("userId")
    if (this.userId !== undefined && this.userId !== null) {
      // Run validation in separate thread;
     
      // if(this.validate(this.token)){
        let bodyData = JSON.stringify(body);
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Authorization': 'jwt ' + this.token,
        })
      };
      if (method == 'get') {
        return this.http.get(url, HTTP_OPTIONS);

      } else if (method == 'post') {
        return this.http.post(url, bodyData, HTTP_OPTIONS);
      }
      // }
    }else{
      let bodyData = JSON.stringify(body);
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Authorization': 'jwt eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJVMzEzMDE1NjU5NDA0ODM2NzYiLCJtb2JpbGUiOiIrOTE5ODg1OTIzMTMwIiwidGltZSI6IjIwMTktMDgtMjNUMTA6MDc6MzcuNzEzWiIsImlhdCI6MTU2NjU1NDg1NywiZXhwIjoxNTgyMTA2ODU3fQ.AVxZw8feu0I4LZzajbhbIvbBA1eL-w0HSf82gNr8ceY'
        })
      };
      if (method == 'get') {
        return this.http.get(url, HTTP_OPTIONS);

      } else if (method == 'post') {
        return this.http.post(url, bodyData, HTTP_OPTIONS);
      }
    }
  }

  apiCallForObjectStorage(method, url, body) {
    if (this.token) {
      let bodyData = body;
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Authorization': 'jwt ' + this.token
        })
      }
      if (method == 'get') {
        return this.http.get(url, HTTP_OPTIONS);

      } else if (method == 'post') {
        return this.http.post(url, bodyData, HTTP_OPTIONS);
      }
    }
    else {
      let bodyData = body;
      const HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Authorization': 'jwt eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJVMzEzMDE1NjU5NDA0ODM2NzYiLCJtb2JpbGUiOiIrOTE5ODg1OTIzMTMwIiwidGltZSI6IjIwMTktMDgtMjNUMTA6MDc6MzcuNzEzWiIsImlhdCI6MTU2NjU1NDg1NywiZXhwIjoxNTgyMTA2ODU3fQ.AVxZw8feu0I4LZzajbhbIvbBA1eL-w0HSf82gNr8ceY'
        })
      }
      if (method == 'get') {
        return this.http.get(url, HTTP_OPTIONS);

      } else if (method == 'post') {
        return this.http.post(url, bodyData, HTTP_OPTIONS);
      }
    }
  }


  configFirebaseLink(shareUrl, metaTagInfo, socialMedia){
    let url = "https://cors-anywhere.herokuapp.com/https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCRBYGA-1OawDYmcrBKGWfv1-zUdaMZIWc"
    let body = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://pmccare.page.link",
        link: shareUrl,
        androidInfo: {
          androidPackageName: "in.gov.pmc.pmccare"
        },
        iosInfo: {
          iosBundleId: "com.pmc.pmccareios"
        },
        socialMetaTagInfo: metaTagInfo
      },
      suffix: {
        option: "SHORT"
      }
    }

    switch (socialMedia) {
			case 'facebook':
				this.apiCall('post', url, body).subscribe(dLink => {
					if(dLink){
						window.open("http://www.facebook.com/sharer.php?u=" + dLink['shortLink']);
					}
				})
				break;

			case 'twitter':
				this.apiCall('post', url, body).subscribe(dLink => {
					if(dLink){
						window.open("http://twitter.com/share?text=PCMC%20Link&url=" + dLink['shortLink']);
					}
				})
				break;

			case 'linkedin':
				this.apiCall('post', url, body).subscribe(dLink => {
					if(dLink){
						window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + dLink['shortLink']);
					}
				})
				break;

			case 'whatsapp':
        const isMobile = this.deviceService.isMobile();
        
				this.apiCall('post', url, body).subscribe(dLink => {
					if(dLink){
						if (isMobile) {
							window.open("whatsapp://send?text="+ dLink['shortLink'])
						} else {
              window.open("https://web.whatsapp.com/send?text=" + dLink['shortLink'])    
							// window.open("https://web.whatsapp.com/send?text=" + "Referral Code: " + referralCode + "%0AReferral Link: " + dLink['shortLink'])     
               
						}
					}
				})
				break;
    
        case 'copy':   
         var referralCode = shareUrl.split("?")[1]
          this.apiCall('post', url, body).subscribe(dLink => {
            if(dLink){
              var copyLink = "Referral Code: " + referralCode + "\nReferral Link: " + dLink['shortLink']              
              const elem = document.createElement('textarea');
            elem.value = copyLink ;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
            alert("Sharing Link copied to clipboared.")
            }
          })
          break;
        

			default:
				break;
		}
  }
}
