/************************************
 * Component -  article component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ************************************/
import { Component, OnInit, ChangeDetectorRef, Input, Directive } from '@angular/core';
import { Images } from '../../config/images-config';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { FacebookService } from 'ngx-facebook';
import { ObjectUnsubscribedError } from 'rxjs';
import { ObjectInterface } from '../../models/global-interface';
import { MatDialog } from '@angular/material/dialog';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router } from '@angular/router';
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
@Component({
	selector: 'app-card-article',
	templateUrl: './card-article.component.html',
	styleUrls: ['./card-article.component.scss'],


})
export class CardArticleComponent implements OnInit {
	@Input() articleData: any;
	url = location.href;
	text = `Jason Watmore's Blog | A Web Developer in Sydney`;
	imageUrl = 'http://jasonwatmore.com/_content/images/jason.jpg';
	articleDate;
	image = Images;
	landingLables: any;
	selectedLanguage: any;
	favorite: string;
	apiDetails: ObjectInterface;
	datenew;
	dateDiff: any;
	feedsDate: Date;
	todaysDate: Date;
	userId: string;
	token: string;
	windowObj: Window;
	public static FACEBOOK_URL = 'https://www.facebook.com/YourPageName';
	public static FACEBOOK_PAGE_ID = 'YourPageName';
	article: number;
	shareUrl: any;
	isBlind;
	favbool:boolean;
	constructor(
		private dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private googleAnalyticsService: GoogleAnalyticsService,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private meta: Meta,
		private clipboardService: ClipboardService,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private fbk: FacebookService,
		private apiCallService: ApiCallService,
		private router: Router,
		private metaService: Meta) {
		if (!window['fbAsyncInit']) {
			window['fbAsyncInit'] = function () {
				window['FB'].init({
					appId: 'your-app-id',
					autoLogAppEvents: true,
					xfbml: true,
					version: 'v3.0'
				});
			};
		}

		// load facebook sdk if required
		const url = 'https://connect.facebook.net/en_US/sdk.js';
		if (!document.querySelector(`script[src='${url}']`)) {
			let script = document.createElement('script');
			script.src = url;
			document.body.appendChild(script);
		}

		fbk.init({
			appId: '1289130497934062', cookie: true, status: true, xfbml: true, version: 'v5.0'
		});
	}

	ngOnInit() {
		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = '//connect.facebook.net/en_US/all.js';
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
		this.metaService.updateTag({ content: 'New Updated tags info' }, 'property=\'og:title\'');
		// this.metaService.updateTag({content:'Embed videos'}, "property ='twitter:card'");


		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

		if (this.articleData) {
			if (this.articleData.fav.filter((item) => item === this.userId).length > 0) {
				this.favorite = 'favorite';
				this.favbool = true;
				//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
			} else {
				this.favorite = 'favorite_border';
				this.favbool = false;
			}
		}

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});


		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}



	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}

	onClick() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.ARTICLE_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.articleData.timelineId
		  })
		// let user = this.localStorageService.getItem("userId")
		// if (user === null) {
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// } else {
		
			this.router.navigate(['/cep/feeds/article/',this.articleData.timelineId])
		// }
	}

	/**
	 * Created by Akanksha J
	 * for reporting article as abuse
	 */
	REPORT_ABUSE() {
		
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.articleData.timelineId,
				engagementType: 'article',
				engagementId: this.articleData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	/**
	 * Created by Ajay C
	 * For unmarking the post
	 */
	unMarkFavorite() {
		
		if (this.favbool) {
			this.articleData = Object.assign({}, this.articleData, { favourite: false });
			this.favorite = 'favorite_border';
			this.favbool = false
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.ARTICLE_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.articleData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.articleData = Object.assign({}, this.articleData, { favourite: true });
			this.favorite = 'favorite';
			this.favbool = true;
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.ARTICLE_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.articleData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.articleData.engagementId,
						engagementType: 'article'
					}
				}));
			}
		}
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/article/" + this.articleData.timelineId
		var metaTag = {
			socialTitle: this.articleData.en.heading,
			socialImageLink: this.articleData.imageFavourite[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.articleDate = new Date(this.articleData.updatedAt).toDateString();
	}

	async clickOnShare() {
		// //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of blog', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.ARTICLE_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.articleData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/article/" + this.articleData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}
}

