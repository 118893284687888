import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as M from "../../../../assets/materialize/js/materialize.min.js";

@Component({
  selector: 'app-details-page-slider',
  templateUrl: './details-page-slider.component.html',
  styleUrls: ['./details-page-slider.component.scss']
})
export class DetailsPageSliderComponent implements OnInit, AfterViewInit {
  @Input() postImages: any;
  options = { fullWidth: true, indicators: true };

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    var elems = document.querySelectorAll(".carousel");
    var instances = M.Carousel.init(elems, this.options);
  }

}
