import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable, MonoTypeOperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { API_KEY } from 'src/app/config/api-key.config';

declare var google: any;

@Component({
  selector: 'app-custom-google-place-autocomplete',
  templateUrl: './custom-google-place-autocomplete.component.html',
  styleUrls: ['./custom-google-place-autocomplete.component.scss'],
})
export class CustomGooglePlaceAutocompleteComponent {

  @Input() placeholder: string;
  @Input() APIKEY: string;
  @Input() value: any;
  @Output() selectionChanged = new EventEmitter<object>();

  selectedValue: any = null;
  searchText = '';
  showError = false

  // Define your options array here. You can fetch data from the API using APIKEY.
  options = []

  inputText: string = '' ;
  results: any[] = [];
  private searchTerms = new Subject<string>();

  constructor(
    private http: HttpClient,
    private mapLoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.inputText = this.value
    this.searchTerms.pipe(
      debounceTime(3000), // Delay for 5 seconds
      distinctUntilChanged(),
      switchMap(term => this.fetchPredictions(term))
    ).subscribe((results: any) => {
      this.results = results;

    });
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }


  ngAfterViewInit() {
    // this.autocomplete.valueChanges
    //   .pipe(debounceTime(3000))
    //   .pipe(distinctUntilChanged())
    //   .subscribe(model => (value) => {
    //     console.log('delayed key press value', value);
    //     console.log("Hiii")
    //     this.onSearchInputChange()
    //   });
  }


  async fetchPredictions(input) {

    if (this.inputText.length > 3 && this.inputText.length < 20) {
      try {
        var sessionToken = new google.maps.places.AutocompleteSessionToken();

        // Pass the token to the autocomplete service.
        var autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({
          input: this.inputText,
          sessionToken: sessionToken
        }, (display: any) => {
          if(display !== null && display.length > 0){
            // console.log("places", display)
            this.options = display
            this.showError = false
          }else{
            this.options = []
            this.showError = true
          }
        });

      } catch (error) {
        console.log(error)
      }
    } else {
      this.options = []
    }
  }

  async fetchPlacebyId(place) {
    const geocoder = new google.maps.Geocoder()
    var request = {
      placeId: place.place_id,
      fields: ["name", "formatted_address", "place_id", "geometry"]
    };

    geocoder.geocode({ placeId: place.place_id })
      .then( (res:any) => {
        let geometry = JSON.parse(JSON.stringify(res.results[0].geometry.location))
        this.selectionChanged.emit({
          address:res.results[0].formatted_address,
          lat:geometry.lat,
          long:geometry.lng
        })
      } )

    // var service = new google.maps.places.PlacesService(map);
    // service.getDetails(request, (place, status) => {
    //   if (
    //     status === google.maps.places.PlacesServiceStatus.OK &&
    //     place &&
    //     place.geometry &&
    //     place.geometry.location
    //   ) {
    //     console.log("place Details", place)
    //   }
    // });

  }

  onSelectionChange(item) {
    const selectedOption = item
    this.inputText = item.description
    this.options = []
    this.fetchPlacebyId(item)
  }

  ngOnDestroy() {

  }

}
