/*********************************
 * Component -  event details screen
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 **********************************/
import { Component, OnInit, Input, ChangeDetectorRef, Pipe, AfterContentChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { Images } from '../../config/images-config';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectInterface } from '../../models/global-interface';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-details-page-event',
	templateUrl: './details-page-event.component.html',
	styleUrls: ['./details-page-event.component.scss']
})
export class DetailsPageEventComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	image = Images;
	public feedsData: ObjectInterface;
	public eventDate;
	apiDetails: ObjectInterface;
	timelineId: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoaded = false;
	isLoading = false;
	message: string;
	selectedLanguage: string;
	landingLables: ObjectInterface;
	feedsDate: string;
	postDate: string;
	favorite: string;
	userId: string;
	token: string;
	feedFilterLabels: ObjectInterface;
	feedLabels: ObjectInterface;
	endDate: string;
	startDate: string;
	latlag: any;
	latitude: any;
	longitude: any;
	feedStatusCode: number;
	shareUrl: string;
	errMessage: string;
	feedsContent: any;

	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	 */
	constructor(
		private apiCallService: ApiCallService,
		private activeParams: ActivatedRoute,
		private store: Store<fromStore.CEPState>,
		private changeDetectRef: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public dialog: MatDialog,
		private domSanitizer: DomSanitizer,
		private deviceService: DeviceDetectorService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private router: Router,
		private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe(res => {
				this.selectedLanguage = res;
			});
	}

	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(
			screenName.EVENT_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.EVENT_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.activeParams.params.subscribe((params) => {
			this.timelineId = params.timelineId;
			this.store.dispatch(new fromStore.CheckTimeline({
				timelineId:this.timelineId
			}))
		});
		
		// getting token and user Id
		this.store.select(fromStore.getToken)
		.subscribe((token) => {
			this.token = token;
			if (this.token) {
				const localUserId = this.localStorageService.getUserId();
				this.userId = this.encrDecr.get(localUserId);
				//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on event by ' + this.userId, 'userLabel', 1);
			} else {
				this.userId = null;
			}
		});
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
			
				this.store.select(fromStore.isTimelineChnaged)
		.subscribe((isTimeline) => {
				if(isTimeline && Object.keys(this.apiDetails).length > 0){
					this.shareUrl = this.apiDetails.shareUrl;

				this.store.dispatch(new fromStore.GetEngeagementDetails({
					url: this.apiDetails.userEngagement.base_url +
						this.apiDetails.userEngagement.timeline.getEngagement.url,
					method: this.apiDetails.userEngagement.timeline.getEngagement.method,
					body: {
						timelineId: this.timelineId,
						userId: this.userId
					}
				}))
				window.scrollTo(0, 0);
			}
			})

	


		this.store.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoaded => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {

					this.store.select(fromStore.getFeedsServerErrorMessage)
						.subscribe((error) => {
							this.errMessage = error;
							if (this.errMessage) {

							}
						});


					// getting favouriyes details
					this.store.select(fromStore.feedDetailsResult)
						.subscribe((feedsData) => {
							this.feedsData = feedsData;
							if (Object.keys(this.feedsData).length !== 0) {
								try {
									this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
									if (this.feedsData.favourite) {
										this.favorite = 'favorite';
									} else {
										this.favorite = 'favorite_border';
									}
								} catch (err) { }
							}
							this.postDate = new Date(this.feedsData.updatedAt).toDateString();
							this.endDate = new Date(this.feedsData.endDate).toDateString();
							this.startDate = new Date(this.feedsData.startDate).toDateString();
							this.latlag = this.feedsData.geometry;
							if (this.latlag) {
								this.latitude = this.latlag.coordinates[0];
								this.longitude = this.latlag.coordinates[1];
							}
						});
				}
			});

		this.store
			.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoading => {
				this.isLoading = isLoading;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.feedFilterLabels = labels;
					});

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});

		this.store.select(fromStore.getFeedsListingErrorMessage)
			.subscribe(message => {
				this.message = message;
			});


		//this.googleAnalyticsService.eventEmitter('Dashboard', ' User click on event', 'userLabel', 1);
	}


/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		
		return this.splitSentance(span.textContent || span.innerText)
	  };

	  /**
	 * Created by Vinita Sharma
	 * to split the paragraph in sentances
	 */
	splitSentance(sen){
		if(!sen.match(/[^\.!\?]+[\.!\?]+/g)){
			return [sen]
		}else{
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak, t2){		
		speechSynthesis.cancel();
		var newmessage = this.extractContent(textToSpeak)	
		// var sentanceList = this.splitSentance(newmessage)
		var voices = speechSynthesis.getVoices();
		newmessage.unshift(t2);

		
		for (let sentance = 0; sentance < newmessage.length; sentance++) {
			var message = new SpeechSynthesisUtterance(newmessage[sentance]);
			message.voice = voices[9];
			message.lang = 'hi-IN';
			message.volume = 1;
			speechSynthesis.speak(message);
		}
	}
	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	/**
	 * Created by Akanksha J
	 * for reporting event as abuse
	 */
	REPORT_ABUSE() {
		this.googleAnalyticsService.eventEmitter(screenName.EVENT_DETAILS, {
			log: clickedOn.REPORT_ABUSE,
			screenName: screenName.EVENT_DETAILS + "_SCREEN",
			SECTION: screenName.EVENT_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REPORT_ABUSE,
			time: new Date(),
			isInput: false,
			contentId:this.eventDate.timelineId
		  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'event',
				engagementId: this.feedsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	
	/**
	 * Created by Ajay C
	 * For unmarking or marking as favourites
	 */
	unMarkFavorite() {
		if (this.feedsData.favourite) {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: false });
			this.favorite = 'favorite_border';
			this.googleAnalyticsService.eventEmitter(screenName.EVENT_DETAILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.EVENT_DETAILS + "_SCREEN",
				SECTION: screenName.EVENT_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.eventDate.timelineId
			  })
		} else {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: true });
			this.favorite = 'favorite';
			this.googleAnalyticsService.eventEmitter(screenName.EVENT_DETAILS, {
				log: clickedOn.LIKE,
				screenName: screenName.EVENT_DETAILS + "_SCREEN",
				SECTION: screenName.EVENT_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.eventDate.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.feedsData.engagementId,
						engagementType: 'event'
					}
				}));
			}
		}
	}


	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + '/cep/feeds/event/' + this.feedsData.timelineId
		var metaTag  = {
			socialTitle: this.feedsData.en.heading,
			socialImageLink: this.feedsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}





	/**
	 * Created by Ajay C
	 * For redirecting to google map to show event venue
	 */
	redirectToGoogleMap() {
		window.open('http://maps.google.com?q=' + this.latitude + ',' + this.longitude + '');
	}
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}


	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.EVENT_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.EVENT_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
		// if (this.userId && this.feedsData.feedback==false) {
		// 	const dialogRef = this.dialog.open(FeedbackComponent,
		// 		{
		// 			data: {
		// 				engagementId: this.feedsData.engagementId,
		// 				engagementType: this.feedsData.engagementType
		// 			},
		// 			maxHeight: '100vh',
		// 			maxWidth: '100vw',
		// 			autoFocus: false
		// 		});
		// }
	}

	async clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of event', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.EVENT_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/event/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}
	}
}
