import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Images } from 'src/app/shared/config/images-config';
import { LocalStorageService } from '../../../shared/services/localstorage.service';

@Component({
	selector: 'app-city-information',
	templateUrl: './city-information.component.html',
	styleUrls: ['./city-information.component.scss']
})
export class CityInformationComponent implements OnInit, OnDestroy, AfterViewInit {
	selectedLanguage: string;
	color = 'primary';
	images = Images;
	mode = 'indeterminate';
	value = 50;
	apiDetails: {};
	cityData: any;
	serverErrorMessage: any;
	isLoading: boolean;
	isLoaded = false;
	aboutUsLabels: any;
	isBlind;
/**
 *
 * angular method for declaring packages in variables
 *
 * which runs first in this particular component
 */
	constructor(
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private localStorageService: LocalStorageService,
		public dialog: MatDialog
	) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		// API call for City details
		this.store.dispatch(new fromStore.GetCityDetails({
			url: this.apiDetails['applicationConfigurations'].base_url +
				this.apiDetails['applicationConfigurations']['aboutUs'].getCityInfo.url,
			method: this.apiDetails['applicationConfigurations']['aboutUs'].getCityInfo.method
		}));
	}

	/**
	 * angular method which gets called on page initialization
	 */
	ngOnInit() {
		this.store.select(fromStore.getAboutUsLabels)
			.subscribe(response => {
				this.aboutUsLabels = response;
			});

		// server error handling
		this.store.select(fromStore.getCityDetailsServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverErrorMessage = serverError;
						if (this.serverErrorMessage) {
							console.error(this.serverErrorMessage);
						}
					}
					// this.serverErrorMessage = null;
				}
			});

		this.store.select(fromStore.getIsCityDetailsLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});

		// response of city details
		this.store.select(fromStore.getIsCityDetailsLoaded)
			.subscribe(loaded => {
				this.isLoaded = loaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getCityDetailsResult)
						.subscribe((res) => {
							this.cityData = res['cityInfo'];
						});
				}
			});
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }



	ngOnDestroy() {
		this.serverErrorMessage = null;
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

}
