import * as fromMoreServices from '../action/more-services.action';

export interface MoreServicesState {
	getMoreServicesResult: {};
	getMoreServicesStatusCode: number;
	getMoreServicesSuccessMessage: string;
	isGetMoreServicesLoading: boolean;
	isGetMoreServicesLoaded: boolean;

	getCategoryServicesResult: {};
	getCategoryServicesStatusCode: number;
	getCategoryServicesSuccessMessage: string;
	isGetCategoryServicesLoading: boolean;
	isGetCategoryServicesLoaded: boolean;

	getDealDetailByDealIdResult: {};
	getDealDetailByDealIdStatusCode: number;
	getDealDetailByDealIdSuccessMessage: string;
	isGetDealDetailByDealIdLoading: boolean;
	isGetDealDetailByDealIdLoaded: boolean;

	getAllEngagementByMerchantIdResult: {}[];
	getMerchantDetailsOfListing:{};
	getAllEngagementByMerchantIdStatusCode: number;
	dealsPaginationCount: number;
	getAllEngagementByMerchantIdSuccessMessage: string;
	isGetAllEngagementByMerchantIdLoading: boolean;
	isGetAllEngagementByMerchantIdLoaded: boolean;

	getMyOrderHistoryByIdResult: {};
	getMyOrderHistoryByIdStatusCode: number;
	getMyOrderHistoryByIdSuccessMessage: string;
	isGetMyOrderHistoryByIdLoading: boolean;
	isGetMyOrderHistoryByIdLoaded: boolean;

	getMerchantByIdResult: {};
	getMerchantByIdStatusCode: number;
	getMerchantByIdSuccessMessage: string;
	isGetMerchantByIdLoading: boolean;
	isGetMerchantByIdLoaded: boolean;

	getUserCartResult: {};
	getUserCartStatusCode: number;
	getUserCartSuccessMessage: string;
	isGetUserCartLoading: boolean;
	isGetUserCartLoaded: boolean;

	addItemToCartResult: {};
	addItemToCartStatusCode: number;
	addItemToCartSuccessMessage: string;
	isAddItemToCartLoading: boolean;
	isAddItemToCartLoaded: boolean;

	removeItemFromCartResult: {};
	removeItemFromCartStatusCode: number;
	removeItemFromCartSuccessMessage: string;
	isRemoveItemFromCartLoading: boolean;
	isRemoveItemFromCartLoaded: boolean;

	getMyLoyaltyPointsResult: {};
	getMyLoyaltyPointsStatusCode: number;
	getMyLoyaltyPointsSuccessMessage: string;
	isGetMyLoyaltyPointsLoading: boolean;
	isGetMyLoyaltyPointsLoaded: boolean;

	emptyCartResult: {};
	emptyCartStatusCode: number;
	emptyCartSuccessMessage: string;
	isEmptyCartLoading: boolean;
	isEmptyCartLoaded: boolean;

	applyCouponResult: {};
	applyCouponStatusCode: number;
	applyCouponSuccessMessage: string;
	isApplyCouponLoading: boolean;
	isApplyCouponLoaded: boolean;

	dealHistoryCartResult: {};
	dealHistoryCartStatusCode: number;
	dealHistoryCartSuccessMessage: string;
	isDealHistoryCartLoading: boolean;
	isDealHistoryCartLoaded: boolean;

	merchantTransactionListResult: {};
	merchantTransactionListStatusCode: number;
	merchantTransactionListSuccessMessage: string;
	isMerchantTransactionListLoading: boolean;
	isMerchantTransactionListLoaded: boolean;

	addMerchantTransactionResult: {};
	addMerchantTransactionStatusCode: number;
	addMerchantTransactionSuccessMessage: string;
	addMerchantTransactionLoading: boolean;
	addMerchantTransactionLoaded: boolean;


	startTransactionResult: {};
	startTransactionStatusCode: number;
	startTransactionSuccessMessage: string;
	isStartTransactionLoading: boolean;
	isStartTransactionLoaded: boolean;

	updateTransactionStatusResult: {};
	updateTransactionStatusStatusCode: number;
	updateTransactionStatusSuccessMessage: string;
	isUpdateTransactionStatusLoading: boolean;
	isUpdateTransactionStatusLoaded: boolean;

	addDealsResult: {};
	addDealsStatusCode: number;
	addDealsSuccessMessage: string;
	isAddDealsLoading: boolean;
	isAddDealsLoaded: boolean;

	redeemLoyaltyResult: {};
	redeemLoyaltyStatusCode: number;
	redeemLoyaltySuccessMessage: string;
	isRedeemLoyaltyLoading: boolean;
	isRedeemLoyaltyLoaded: boolean;

	moreServicesNetworkError: string;
}

export const initialState: MoreServicesState = {
	getMoreServicesResult: {},
	getMoreServicesStatusCode: 0,
	getMoreServicesSuccessMessage: null,
	isGetMoreServicesLoading: false,
	isGetMoreServicesLoaded: false,

	getCategoryServicesResult: {},
	getCategoryServicesStatusCode: 0,
	getCategoryServicesSuccessMessage: null,
	isGetCategoryServicesLoading: false,
	isGetCategoryServicesLoaded: false,

	getAllEngagementByMerchantIdResult: [],
	getMerchantDetailsOfListing:{},
	getAllEngagementByMerchantIdStatusCode: 0,
	dealsPaginationCount: 0,
	getAllEngagementByMerchantIdSuccessMessage: null,
	isGetAllEngagementByMerchantIdLoading: false,
	isGetAllEngagementByMerchantIdLoaded: false,

	getDealDetailByDealIdResult: {},
	getDealDetailByDealIdStatusCode: 0,
	getDealDetailByDealIdSuccessMessage: null,
	isGetDealDetailByDealIdLoading: false,
	isGetDealDetailByDealIdLoaded: false,

	getMyOrderHistoryByIdResult: {},
	getMyOrderHistoryByIdStatusCode: 0,
	getMyOrderHistoryByIdSuccessMessage: null,
	isGetMyOrderHistoryByIdLoading: false,
	isGetMyOrderHistoryByIdLoaded: false,

	getMerchantByIdResult: {},
	getMerchantByIdStatusCode: 0,
	getMerchantByIdSuccessMessage: null,
	isGetMerchantByIdLoading: false,
	isGetMerchantByIdLoaded: false,

	getMyLoyaltyPointsResult: {},
	getMyLoyaltyPointsStatusCode: 0,
	getMyLoyaltyPointsSuccessMessage: null,
	isGetMyLoyaltyPointsLoading: false,
	isGetMyLoyaltyPointsLoaded: false,
	
	getUserCartResult: {},
	getUserCartStatusCode: 0,
	getUserCartSuccessMessage: null,
	isGetUserCartLoading: false,
	isGetUserCartLoaded: false,

	addItemToCartResult: {},
	addItemToCartStatusCode: 0,
	addItemToCartSuccessMessage: null,
	isAddItemToCartLoading: false,
	isAddItemToCartLoaded: false,

	removeItemFromCartResult: {},
	removeItemFromCartStatusCode: 0,
	removeItemFromCartSuccessMessage: null,
	isRemoveItemFromCartLoading: false,
	isRemoveItemFromCartLoaded: false,

	emptyCartResult: {},
	emptyCartStatusCode: 0,
	emptyCartSuccessMessage: null,
	isEmptyCartLoading: false,
	isEmptyCartLoaded: false,

	applyCouponResult: {},
	applyCouponStatusCode: 0,
	applyCouponSuccessMessage: null,
	isApplyCouponLoading: false,
	isApplyCouponLoaded: false,

	dealHistoryCartResult: {},
	dealHistoryCartStatusCode: 0,
	dealHistoryCartSuccessMessage: null,
	isDealHistoryCartLoading: false,
	isDealHistoryCartLoaded: false,

	merchantTransactionListResult: {},
	merchantTransactionListStatusCode: 0,
	merchantTransactionListSuccessMessage: null,
	isMerchantTransactionListLoading: false,
	isMerchantTransactionListLoaded: false,

	addMerchantTransactionResult: {},
	addMerchantTransactionStatusCode: 0,
	addMerchantTransactionSuccessMessage: null,
	addMerchantTransactionLoading: false,
	addMerchantTransactionLoaded: false,


	startTransactionResult: {},
	startTransactionStatusCode: 0,
	startTransactionSuccessMessage: null,
	isStartTransactionLoading: false,
	isStartTransactionLoaded: false,

	updateTransactionStatusResult: {},
	updateTransactionStatusStatusCode: 0,
	updateTransactionStatusSuccessMessage: null,
	isUpdateTransactionStatusLoading: false,
	isUpdateTransactionStatusLoaded: false,

	addDealsResult: {},
	addDealsStatusCode: 0,
	addDealsSuccessMessage: null,
	isAddDealsLoading: false,
	isAddDealsLoaded: false,

	redeemLoyaltyResult: {},
	redeemLoyaltyStatusCode: 0,
	redeemLoyaltySuccessMessage: null,
	isRedeemLoyaltyLoading: false,
	isRedeemLoyaltyLoaded: false,

	moreServicesNetworkError: null
};

export function reducer(
	state = initialState,
	action: fromMoreServices.moreServicesAction
): MoreServicesState {
	switch (action.type) {
		case fromMoreServices.GET_MORE_SERVICES: {
			return {
				...state,
				isGetMoreServicesLoading: true,
				isGetMoreServicesLoaded: false
			};
		}
		case fromMoreServices.GET_MORE_SERVICES_SUCCESS: {
			let statusCode = action.payload.code;
			let getMoreServicesResult = state.getMoreServicesResult;
			let getMoreServicesSuccessMessage = state.getMoreServicesSuccessMessage;
			let getMoreServicesStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getMoreServicesResult = action.payload.result;
					getMoreServicesSuccessMessage = action.payload.message;
					break;
				case 500:
					getMoreServicesSuccessMessage = action.payload.message;
				break;
			}
			return {
				...state,
				getMoreServicesResult,
				getMoreServicesSuccessMessage,
				isGetMoreServicesLoading: false,
				isGetMoreServicesLoaded: true,
				getMoreServicesStatusCode
			};
		}
		case fromMoreServices.GET_MORE_SERVICES_FAIL: {
			let statusCode = action.payload.status;
			let getMoreServicesStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetMoreServicesLoading: false,
				isGetMoreServicesLoaded: true,
				getMoreServicesStatusCode
			};
		}

		case fromMoreServices.GET_CATEGORY_SERVICES: {
			return {
				...state,
				isGetCategoryServicesLoading: true,
				isGetCategoryServicesLoaded: false
			};
		}
		case fromMoreServices.GET_CATEGORY_SERVICES_SUCCESS: {
			let statusCode = action.payload.code;
			let getCategoryServicesResult = state.getCategoryServicesResult;
			let getCategoryServicesSuccessMessage = state.getCategoryServicesSuccessMessage;
			let getCategoryServicesStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getCategoryServicesResult = action.payload.result;
					getCategoryServicesSuccessMessage = action.payload.message;
					break;
			}
			return {
				...state,
				getCategoryServicesResult,
				getCategoryServicesSuccessMessage,
				isGetCategoryServicesLoading: false,
				isGetCategoryServicesLoaded: true,
				getCategoryServicesStatusCode
			};
		}
		case fromMoreServices.GET_CATEGORY_SERVICES_FAIL: {
			let statusCode = action.payload.status;
			let getCategoryServicesStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetCategoryServicesLoading: false,
				isGetCategoryServicesLoaded: true,
				getCategoryServicesStatusCode
			};
		}

		case fromMoreServices.RESET_DEAL:{
			return{
				...state,
				getAllEngagementByMerchantIdResult:[],
				getMerchantDetailsOfListing:{},
				getAllEngagementByMerchantIdStatusCode: 0,
				addItemToCartStatusCode: 0,
				dealsPaginationCount: 0,
				getAllEngagementByMerchantIdSuccessMessage: null,
				isGetAllEngagementByMerchantIdLoading: false,
				isGetAllEngagementByMerchantIdLoaded: false
			}
		}

		case fromMoreServices.RESET_CART:{
			return{
				...state,
				removeItemFromCartResult: {},
				removeItemFromCartStatusCode: 0,
				removeItemFromCartSuccessMessage: null,
				isRemoveItemFromCartLoading: false,
				isRemoveItemFromCartLoaded: false,
			}
		}

		case fromMoreServices.GET_ALL_ENGAGEMENT_BY_MERCHANT_ID: {
		
			let isSort = action.payload.isSort;
			let getAllEngagementByMerchantIdResult = state.getAllEngagementByMerchantIdResult
			let dealsPaginationCount = state.dealsPaginationCount;
			if(isSort==true){
				getAllEngagementByMerchantIdResult = []; 
				dealsPaginationCount = 0;
			}
			return {
				...state,
				isGetAllEngagementByMerchantIdLoading: true,
				getAllEngagementByMerchantIdResult,
				isGetAllEngagementByMerchantIdLoaded: false,
				dealsPaginationCount
			};
		}
		case fromMoreServices.GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_SUCCESS: {
			let statusCode = action.payload.code;
			let getAllEngagementByMerchantIdResult = state.getAllEngagementByMerchantIdResult;
	        let getMerchantDetailsOfListing = state.getMerchantDetailsOfListing;
			let getAllEngagementByMerchantIdSuccessMessage = state.getAllEngagementByMerchantIdSuccessMessage;
			let getAllEngagementByMerchantIdStatusCode = statusCode;
			let paginationCountDeal = state.dealsPaginationCount;
			let data = [];

		
			switch (statusCode) {
				case 200:
					if (paginationCountDeal == 0) {
						getAllEngagementByMerchantIdResult = action.payload.result['engagements'];
						getMerchantDetailsOfListing =  action.payload.result['merchant'];
						getAllEngagementByMerchantIdStatusCode = action.payload.code;
					  } else {
						data = action.payload.result['engagements'];

						
						getAllEngagementByMerchantIdResult = [...getAllEngagementByMerchantIdResult, ...data];
				
						getMerchantDetailsOfListing =  action.payload.result['merchant'];
					}
					getAllEngagementByMerchantIdSuccessMessage = action.payload.message;
					break;
				case 500:
					getAllEngagementByMerchantIdSuccessMessage = action.payload.message;
				break;
			}
			return {
				...state,
				getAllEngagementByMerchantIdResult,
				getMerchantDetailsOfListing,
				getAllEngagementByMerchantIdSuccessMessage,
				isGetAllEngagementByMerchantIdLoading: false,
				isGetAllEngagementByMerchantIdLoaded: true,
				getAllEngagementByMerchantIdStatusCode
			};
		}
		case fromMoreServices.GET_ALL_ENGAGEMENT_BY_MERCHANT_ID_FAIL: {
			let statusCode = action.payload.status;
			let getAllEngagementByMerchantIdStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetAllEngagementByMerchantIdLoading: false,
				isGetAllEngagementByMerchantIdLoaded: true,
				getAllEngagementByMerchantIdStatusCode
			};
		}
		case fromMoreServices.SET_PAGINATION_COUNT_DEAL: {

			let dealsPaginationCount = action.payload.dealsPaginationCount;
			let getAllEngagementByMerchantIdResult = state.getAllEngagementByMerchantIdResult;
			if (dealsPaginationCount == 0) {
	  
			  getAllEngagementByMerchantIdResult = []
			}
			return {
			  ...state,
			  dealsPaginationCount,
			  getAllEngagementByMerchantIdResult
			};
		  }

		case fromMoreServices.GET_DEAL_DETAIL_BY_DEAL_ID: {
			return {
				...state,
				isGetDealDetailByDealIdLoading: true,
				isGetDealDetailByDealIdLoaded: false
			};
		}
		case fromMoreServices.GET_DEAL_DETAIL_BY_DEAL_ID_SUCCESS: {
			let statusCode = action.payload.code;
			let getDealDetailByDealIdResult = state.getDealDetailByDealIdResult;
			let getDealDetailByDealIdSuccessMessage = state.getDealDetailByDealIdSuccessMessage;
			let getDealDetailByDealIdStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getDealDetailByDealIdResult = action.payload.result;
					getDealDetailByDealIdSuccessMessage = action.payload.message;
					break;
				case 500:
					getDealDetailByDealIdSuccessMessage = action.payload.message;
				break;
			}
			return {
				...state,
				getDealDetailByDealIdResult,
				getDealDetailByDealIdSuccessMessage,
				isGetDealDetailByDealIdLoading: false,
				isGetDealDetailByDealIdLoaded: true,
				getDealDetailByDealIdStatusCode
			};
		}
		case fromMoreServices.GET_DEAL_DETAIL_BY_DEAL_ID_FAIL: {
			let statusCode = action.payload.status;
			let getDealDetailByDealIdStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetDealDetailByDealIdLoading: false,
				isGetDealDetailByDealIdLoaded: true,
				getDealDetailByDealIdStatusCode
			};
		}

		case fromMoreServices.GET_MY_ORDER_HISTORY_ID: {
			return {
				...state,
				isGetMyOrderHistoryByIdLoading: true,
				isGetMyOrderHistoryByIdLoaded: false
			};
		}
		case fromMoreServices.GET_MY_ORDER_HISTORY_ID_SUCCESS: {
			let statusCode = action.payload.code;
			let getMyOrderHistoryByIdResult = state.getMyOrderHistoryByIdResult;
			let getMyOrderHistoryByIdSuccessMessage = state.getMyOrderHistoryByIdSuccessMessage;
			let getMyOrderHistoryByIdStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getMyOrderHistoryByIdResult = action.payload.result;
					getMyOrderHistoryByIdSuccessMessage = action.payload.message;
					break;
					case 500:
						getMyOrderHistoryByIdSuccessMessage = action.payload.message;
						break;
			}
			return {
				...state,
				getMyOrderHistoryByIdResult,
				getMyOrderHistoryByIdSuccessMessage,
				isGetMyOrderHistoryByIdLoading: false,
				isGetMyOrderHistoryByIdLoaded: true,
				getMyOrderHistoryByIdStatusCode
			};
		}
		case fromMoreServices.GET_MY_ORDER_HISTORY_ID_FAIL: {
			let statusCode = action.payload.status;
			let getMyOrderHistoryByIdStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetMyOrderHistoryByIdLoading: false,
				isGetMyOrderHistoryByIdLoaded: true,
				getMyOrderHistoryByIdStatusCode
			};
		}

		case fromMoreServices.GET_MERCHANT_BY_ID: {
			return {
				...state,
				isGetMerchantByIdLoading: true,
				isGetMerchantByIdLoaded: false
			};
		}
		case fromMoreServices.GET_MERCHANT_BY_ID_SUCCESS: {
			let statusCode = action.payload.code;
			let getMerchantByIdResult = state.getMerchantByIdResult;
			let getMerchantByIdSuccessMessage = state.getMerchantByIdSuccessMessage;
			let getMerchantByIdStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getMerchantByIdResult = action.payload.result;
					getMerchantByIdSuccessMessage = action.payload.message;
					break;
				case 500:
					getMerchantByIdSuccessMessage = action.payload.message;
					break;
			}
			return {
				...state,
				getMerchantByIdResult,
				getMerchantByIdSuccessMessage,
				isGetMerchantByIdLoading: false,
				isGetMerchantByIdLoaded: true,
				getMerchantByIdStatusCode
			};
		}
		case fromMoreServices.GET_MERCHANT_BY_ID_FAIL: {
			let statusCode = action.payload.status;
			let getMerchantByIdStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetMerchantByIdLoading: false,
				isGetMerchantByIdLoaded: true,
				getMerchantByIdStatusCode
			};
		}

		case fromMoreServices.GET_USER_CART: {
			return {
				...state,
				isGetUserCartLoading: true,
				isGetUserCartLoaded: false,
				// addItemToCartSuccessMessage: null
			};
		}
		case fromMoreServices.GET_USER_CART_SUCCESS: {
			let statusCode = action.payload.code;
			let getUserCartResult = state.getUserCartResult;
			let getUserCartSuccessMessage = state.getUserCartSuccessMessage;
			let getUserCartStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getUserCartResult = action.payload.result;
					getUserCartSuccessMessage = action.payload.message;
					break;
				case 500:
					getUserCartSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				getUserCartResult,
				getUserCartSuccessMessage,
				isGetUserCartLoading: false,
				isGetUserCartLoaded: true,
				getUserCartStatusCode
			};
		}
		case fromMoreServices.GET_USER_CART_FAIL: {
			let statusCode = action.payload.status;
			let getUserCartStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetUserCartLoading: false,
				isGetUserCartLoaded: true,
				getUserCartStatusCode
			};
		}

		case fromMoreServices.ADD_ITEM_TO_CART_CART: {
			// if(state.addItemToCartSuccessMessage)
			// 	state.addItemToCartSuccessMessage = null
			return {
				...state,
				// addItemToCartSuccessMessage: null,
				isAddItemToCartLoading: true,
				isAddItemToCartLoaded: false
			};
		}
		case fromMoreServices.ADD_ITEM_TO_CART_CART_SUCCESS: {
			let statusCode = action.payload.code;
			let addItemToCartResult = state.addItemToCartResult;
			let addItemToCartSuccessMessage = state.addItemToCartSuccessMessage;
			let addItemToCartStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					addItemToCartResult = action.payload.result;
					addItemToCartSuccessMessage = action.payload.message;
					break;
				// case 404:
				// 	addItemToCartResult = action.payload.result;
				// 	addItemToCartSuccessMessage = action.payload.message;
				// 	break;	
					case 100:
					addItemToCartSuccessMessage = action.payload.message;
					break;	
					case 101:
					addItemToCartSuccessMessage = action.payload.message;
					break;		
			}
			return {
				...state,
				addItemToCartResult,
				addItemToCartSuccessMessage,
				isAddItemToCartLoading: false,
				isAddItemToCartLoaded: true,
				addItemToCartStatusCode
			};
		}
		case fromMoreServices.ADD_ITEM_TO_CART_CART_FAIL: {
			let statusCode = action.payload.status;
			let addItemToCartStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isAddItemToCartLoading: false,
				isAddItemToCartLoaded: true,
				addItemToCartStatusCode
			};
		}


		case fromMoreServices.REMOVE_ITEM_FROM_CART: {
			return {
				...state,
				isRemoveItemFromCartLoading: true,
				isRemoveItemFromCartLoaded: false
			};
		}
		case fromMoreServices.REMOVE_ITEM_FROM_CART_SUCCESS: {
			let statusCode = action.payload.code;
			let removeItemFromCartResult = state.removeItemFromCartResult;
			let removeItemFromCartSuccessMessage = state.removeItemFromCartSuccessMessage;
			let removeItemFromCartStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					removeItemFromCartResult = action.payload.result;
					removeItemFromCartSuccessMessage = action.payload.message;
					break;
				case 500:
					removeItemFromCartSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				removeItemFromCartResult,
				removeItemFromCartSuccessMessage,
				isRemoveItemFromCartLoading: false,
				isRemoveItemFromCartLoaded: true,
				removeItemFromCartStatusCode
			};
		}
		case fromMoreServices.REMOVE_ITEM_FROM_CART_FAIL: {
			let statusCode = action.payload.status;
			let removeItemFromCartStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isRemoveItemFromCartLoading: false,
				isRemoveItemFromCartLoaded: true,
				removeItemFromCartStatusCode
			};
		}

		case fromMoreServices.GET_MY_LOYALTY_POINTS: {
			return {
				...state,
				isGetMyLoyaltyPointsLoading: true,
				isGetMyLoyaltyPointsLoaded: false
			};
		}
		case fromMoreServices.GET_MY_LOYALTY_POINTS_SUCCESS: {
			let statusCode = action.payload.code;
			let getMyLoyaltyPointsResult = state.getMyLoyaltyPointsResult;
			let getMyLoyaltyPointsSuccessMessage = state.getMyLoyaltyPointsSuccessMessage;
			let getMyLoyaltyPointsStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					getMyLoyaltyPointsResult = action.payload.result;
					getMyLoyaltyPointsSuccessMessage = action.payload.message;
					break;
					case 500:
						getMyLoyaltyPointsSuccessMessage = action.payload.message;
					break;
			}
			return {
				...state,
				getMyLoyaltyPointsResult,
				getMyLoyaltyPointsSuccessMessage,
				isGetMyLoyaltyPointsLoading: false,
				isGetMyLoyaltyPointsLoaded: true,
				getMyLoyaltyPointsStatusCode
			};
		}
		case fromMoreServices.GET_MY_LOYALTY_POINTS_FAIL: {
			let statusCode = action.payload.status;
			let getMyLoyaltyPointsStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isGetMyLoyaltyPointsLoading: false,
				isGetMyLoyaltyPointsLoaded: true,
				getMyLoyaltyPointsStatusCode
			};
		}

		case fromMoreServices.EMPTY_CART: {
			return {
				...state,
				isEmptyCartLoading: true,
				isEmptyCartLoaded: false
			};
		}
		case fromMoreServices.EMPTY_CART_SUCCESS: {
			let statusCode = action.payload.code;
			let emptyCartResult = state.emptyCartResult;
			let emptyCartSuccessMessage = state.emptyCartSuccessMessage;
			let emptyCartStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					emptyCartResult = action.payload.result;
					emptyCartSuccessMessage = action.payload.message;
					break;
				case 500:
					emptyCartSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				emptyCartResult,
				emptyCartSuccessMessage,
				isEmptyCartLoading: false,
				isEmptyCartLoaded: true,
				emptyCartStatusCode
			};
		}
		case fromMoreServices.EMPTY_CART_FAIL: {
			let statusCode = action.payload.status;
			let emptyCartStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isEmptyCartLoading: false,
				isEmptyCartLoaded: true,
				emptyCartStatusCode
			};
		}

		case fromMoreServices.APPLY_COUPON: {
			return {
				...state,
				isApplyCouponLoading: true,
				isApplyCouponLoaded: false
			};
		}
		case fromMoreServices.APPLY_COUPON_SUCCESS: {
			let statusCode = action.payload.code;
			let applyCouponResult = state.applyCouponResult;
			let applyCouponSuccessMessage = state.applyCouponSuccessMessage;
			let applyCouponStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					applyCouponResult = action.payload.result;
					applyCouponSuccessMessage = action.payload.message;
					break;
				case 500:
					applyCouponSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				applyCouponResult,
				applyCouponSuccessMessage,
				isApplyCouponLoading: false,
				isApplyCouponLoaded: true,
				applyCouponStatusCode
			};
		}
		case fromMoreServices.APPLY_COUPON_FAIL: {
			let statusCode = action.payload.status;
			let applyCouponStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isApplyCouponLoading: false,
				isApplyCouponLoaded: true,
				applyCouponStatusCode
			};
		}

		case fromMoreServices.GET_DEAL_HISTORY_DETAILS_CART: {
			return {
				...state,
				isDealHistoryCartLoading: true,
				isDealHistoryCartLoaded: false
			};
		}

		case fromMoreServices.GET_DEAL_HISTORY_DETAILS_CART_SUCCESS: {
			let dealHistoryCartResult = state.dealHistoryCartResult;
			let dealHistoryCartSuccessMessage = state.dealHistoryCartSuccessMessage;
			let dealHistoryCartStatusCode = action.payload.code;
			switch (dealHistoryCartStatusCode) {
				case 200:
					dealHistoryCartResult = action.payload.result;
					dealHistoryCartSuccessMessage = action.payload.message;
					break;
				case 500:
					dealHistoryCartSuccessMessage = action.payload.message;
					break;
			}
			return {
				...state,
				dealHistoryCartResult,
				dealHistoryCartSuccessMessage,
				isDealHistoryCartLoading: false,
				isDealHistoryCartLoaded: true,
				dealHistoryCartStatusCode
			};
		}
		case fromMoreServices.GET_DEAL_HISTORY_DETAILS_CART_FAIL: {
			let dealHistoryCartStatusCode = action.payload.status;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (dealHistoryCartStatusCode === 401 || dealHistoryCartStatusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (dealHistoryCartStatusCode !== 401 && dealHistoryCartStatusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isUpdateTransactionStatusLoading: false,
				isUpdateTransactionStatusLoaded: true,
				dealHistoryCartStatusCode
			};
		}
			
		
case fromMoreServices.START_TRANSACTION: {

	return {
		...state,
		isStartTransactionLoading: true,
		isStartTransactionLoaded: false
	};

}

case fromMoreServices.START_TRANSACTION_SUCCESS: {
	let statusCode = action.payload.code;
	let startTransactionResult = state.startTransactionResult;
	let startTransactionSuccessMessage = state.startTransactionSuccessMessage;
	let startTransactionStatusCode = statusCode;
	switch (statusCode) {
		case 200:
			startTransactionResult = action.payload.result;
			startTransactionSuccessMessage = action.payload.message;
			break;
		case 500:
			startTransactionSuccessMessage = action.payload.message;
			break;
	}

	return {
		...state,
		startTransactionResult,
		startTransactionSuccessMessage,
		isStartTransactionLoading: false,
		isStartTransactionLoaded: true,
		startTransactionStatusCode
	};
}
case fromMoreServices.START_TRANSACTION_FAIL: {
	let statusCode = action.payload.status;
	let startTransactionStatusCode = statusCode;
	let moreServicesNetworkError = state.moreServicesNetworkError;
	if (statusCode === 401 || statusCode === 0) {
		moreServicesNetworkError = action.payload.statusText;
	} else if (statusCode !== 401 && statusCode !== 0) {
		moreServicesNetworkError = action.payload.error.message;
	}
	return {
		...state,
		moreServicesNetworkError,
		isStartTransactionLoading: false,
		isStartTransactionLoaded: true,
		startTransactionStatusCode
	};
}


		case fromMoreServices.MERCHANT_TRANSACTION_LIST: {
			return {
				...state,
				isMerchantTransactionListLoading: true,
				isMerchantTransactionListLoaded: false
			};
		
		}
		
		case fromMoreServices.MERCHANT_TRANSACTION_LIST_SUCCESS: {
			let statusCode = action.payload.code;
			let merchantTransactionListResult = state.merchantTransactionListResult;
			let merchantTransactionListSuccessMessage = state.merchantTransactionListSuccessMessage;
			let merchantTransactionListStatusCode = statusCode;			
			switch (statusCode) {
				case 200:
					merchantTransactionListResult = action.payload.result;
					merchantTransactionListSuccessMessage = action.payload.message;
					break;
				case 500:
					merchantTransactionListSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				merchantTransactionListResult,
				merchantTransactionListSuccessMessage,
				isMerchantTransactionListLoading: false,
				isMerchantTransactionListLoaded: true,
				merchantTransactionListStatusCode
			};
		}
		case fromMoreServices.MERCHANT_TRANSACTION_LIST_FAIL: {
			let statusCode = action.payload.status;
			let merchantTransactionListStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isMerchantTransactionListLoading: false,
				isMerchantTransactionListLoaded: true,
				merchantTransactionListStatusCode
			};
		}

		case fromMoreServices.ADD_MERCHANT_TRANSACTION: {
			return {
				...state,
				addMerchantTransactionLoading: true,
				addMerchantTransactionLoaded: false
			};
		
		}
		
		case fromMoreServices.ADD_MERCHANT_TRANSACTION_SUCCESS: {
			let statusCode = action.payload.code;
			let addMerchantTransactionResult = state.addMerchantTransactionResult;
			let addMerchantTransactionSuccessMessage = state.addMerchantTransactionSuccessMessage;
			let addMerchantTransactionStatusCode = statusCode;			
			switch (statusCode) {
				case 200:
					addMerchantTransactionResult = action.payload.result;
					addMerchantTransactionSuccessMessage = action.payload.message;
					break;
				case 500:
					addMerchantTransactionSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				addMerchantTransactionResult,
				addMerchantTransactionSuccessMessage,
				addMerchantTransactionLoading: false,
				addMerchantTransactionLoaded: true,
				addMerchantTransactionStatusCode
			};
		}
		case fromMoreServices.ADD_MERCHANT_TRANSACTION_FAIL: {
			let statusCode = action.payload.status;
			let addMerchantTransactionStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				addMerchantTransactionLoading: false,
				addMerchantTransactionLoaded: true,
				addMerchantTransactionStatusCode
			};
		}

		case fromMoreServices.UPDATE_TRANSACTION_STATUS: {
			return {
				...state,
				isUpdateTransactionStatusLoading: true,
				isUpdateTransactionStatusLoaded: false
			};
		}
		case fromMoreServices.UPDATE_TRANSACTION_STATUS_SUCCESS: {
			let statusCode = action.payload.code;
			let updateTransactionStatusResult = state.updateTransactionStatusResult;
			let updateTransactionStatusSuccessMessage = state.updateTransactionStatusSuccessMessage;
			let updateTransactionStatusStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					updateTransactionStatusResult = action.payload.result;
					updateTransactionStatusSuccessMessage = action.payload.message;
					break;
				case 500:
					updateTransactionStatusSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				updateTransactionStatusResult,
				updateTransactionStatusSuccessMessage,
				isUpdateTransactionStatusLoading: false,
				isUpdateTransactionStatusLoaded: true,
				updateTransactionStatusStatusCode
			};
		}
		case fromMoreServices.UPDATE_TRANSACTION_STATUS_FAIL: {
			let statusCode = action.payload.status;
			let updateTransactionStatusStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isUpdateTransactionStatusLoading: false,
				isUpdateTransactionStatusLoaded: true,
				updateTransactionStatusStatusCode
			};
		}

		case fromMoreServices.ADD_DEALS: {
			return {
				...state,
				isAddDealsLoading: true,
				isAddDealsLoaded: false
			};
		}
		case fromMoreServices.ADD_DEALS_SUCCESS: {
			let statusCode = action.payload.code;
			let addDealsResult = state.addDealsResult;
			let addDealsSuccessMessage = state.addDealsSuccessMessage;
			let addDealsStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					addDealsResult = action.payload.result;
					addDealsSuccessMessage = action.payload.message;
					break;
				case 500:
					addDealsSuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				addDealsResult,
				addDealsSuccessMessage,
				isAddDealsLoading: false,
				isAddDealsLoaded: true,
				addDealsStatusCode
			};
		}
		case fromMoreServices.ADD_DEALS_FAIL: {
			let statusCode = action.payload.status;
			let addDealsStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isAddDealsLoading: false,
				isAddDealsLoaded: true,
				addDealsStatusCode
			};
		}

		case fromMoreServices.REDEEM_LOYALTY: {
			return {
				...state,
				isRedeemLoyaltyLoading: true,
				isRedeemLoyaltyLoaded: false
			};
		}
		case fromMoreServices.REDEEM_LOYALTY_SUCCESS: {
			let statusCode = action.payload.code;
			let redeemLoyaltyResult = state.redeemLoyaltyResult;
			let redeemLoyaltySuccessMessage = state.redeemLoyaltySuccessMessage;
			let redeemLoyaltyStatusCode = statusCode;
			switch (statusCode) {
				case 200:
					redeemLoyaltyResult = action.payload.result;
					redeemLoyaltySuccessMessage = action.payload.message;
					break;
				case 500:
					redeemLoyaltySuccessMessage = action.payload.message;
					break;
			}

			return {
				...state,
				redeemLoyaltyResult,
				redeemLoyaltySuccessMessage,
				isRedeemLoyaltyLoading: false,
				isRedeemLoyaltyLoaded: true,
				redeemLoyaltyStatusCode
			};
		}
		case fromMoreServices.REDEEM_LOYALTY_FAIL: {
			let statusCode = action.payload.status;
			let redeemLoyaltyStatusCode = statusCode;
			let moreServicesNetworkError = state.moreServicesNetworkError;
			if (statusCode === 401 || statusCode === 0) {
				moreServicesNetworkError = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				moreServicesNetworkError = action.payload.error.message;
			}
			return {
				...state,
				moreServicesNetworkError,
				isRedeemLoyaltyLoading: false,
				isRedeemLoyaltyLoaded: true,
				redeemLoyaltyStatusCode
			};
		}
	}
	return state;
}

export const getMoreServicesResult = (state: MoreServicesState) => state.getMoreServicesResult;
export const getMoreServicesSuccessMessage = (state: MoreServicesState) => state.getMoreServicesSuccessMessage;
export const getMoreServicesStatusCode = (state: MoreServicesState) => state.getMoreServicesStatusCode;
export const isGetMoreServicesLoading = (state: MoreServicesState) => state.isGetMoreServicesLoading;
export const isGetMoreServicesLoaded = (state: MoreServicesState) => state.isGetMoreServicesLoaded;

export const getCategoryServicesResult = (state: MoreServicesState) => state.getCategoryServicesResult;
export const getCategoryServicesSuccessMessage = (state: MoreServicesState) => state.getCategoryServicesSuccessMessage;
export const getCategoryServicesStatusCode = (state: MoreServicesState) => state.getCategoryServicesStatusCode;
export const isGetCategoryServicesLoading = (state: MoreServicesState) => state.isGetCategoryServicesLoading;
export const isGetCategoryServicesLoaded = (state: MoreServicesState) => state.isGetCategoryServicesLoaded;
export const getMerchantDetailsOfListing = (state: MoreServicesState) => state.getMerchantDetailsOfListing;

export const getAllEngagementByMerchantIdResult = (state: MoreServicesState) => state.getAllEngagementByMerchantIdResult;
export const getAllEngagementByMerchantIdSuccessMessage = (state: MoreServicesState) => state.getAllEngagementByMerchantIdSuccessMessage;
export const getAllEngagementByMerchantIdStatusCode = (state: MoreServicesState) => state.getAllEngagementByMerchantIdStatusCode;
export const getDealsPaginationCount = (state: MoreServicesState) => state.dealsPaginationCount;
export const isGetAllEngagementByMerchantIdLoading = (state: MoreServicesState) => state.isGetAllEngagementByMerchantIdLoading;
export const isGetAllEngagementByMerchantIdLoaded = (state: MoreServicesState) => state.isGetAllEngagementByMerchantIdLoaded;

export const getDealDetailByDealIdResult = (state: MoreServicesState) => state.getDealDetailByDealIdResult;
export const getDealDetailByDealIdSuccessMessage = (state: MoreServicesState) => state.getDealDetailByDealIdSuccessMessage;
export const getDealDetailByDealIdStatusCode = (state: MoreServicesState) => state.getDealDetailByDealIdStatusCode;
export const isGetDealDetailByDealIdLoading = (state: MoreServicesState) => state.isGetDealDetailByDealIdLoading;
export const isGetDealDetailByDealIdLoaded = (state: MoreServicesState) => state.isGetDealDetailByDealIdLoaded;

export const getMyOrderHistoryByIdResult = (state: MoreServicesState) => state.getMyOrderHistoryByIdResult;
export const getMyOrderHistoryByIdSuccessMessage = (state: MoreServicesState) => state.getMyOrderHistoryByIdSuccessMessage;
export const getMyOrderHistoryByIdStatusCode = (state: MoreServicesState) => state.getMyOrderHistoryByIdStatusCode;
export const isGetMyOrderHistoryByIdLoading = (state: MoreServicesState) => state.isGetMyOrderHistoryByIdLoading;
export const isGetMyOrderHistoryByIdLoaded = (state: MoreServicesState) => state.isGetMyOrderHistoryByIdLoaded;

export const getMerchantByIdResult = (state: MoreServicesState) => state.getMerchantByIdResult;
export const getMerchantByIdSuccessMessage = (state: MoreServicesState) => state.getMerchantByIdSuccessMessage;
export const getMerchantByIdStatusCode = (state: MoreServicesState) => state.getMerchantByIdStatusCode;
export const isGetMerchantByIdLoading = (state: MoreServicesState) => state.isGetMerchantByIdLoading;
export const isGetMerchantByIdLoaded = (state: MoreServicesState) => state.isGetMerchantByIdLoaded;

export const getUserCartResult = (state: MoreServicesState) => state.getUserCartResult;
export const getUserCartSuccessMessage = (state: MoreServicesState) => state.getUserCartSuccessMessage;
export const getUserCartStatusCode = (state: MoreServicesState) => state.getUserCartStatusCode;
export const isGetUserCartLoading = (state: MoreServicesState) => state.isGetUserCartLoading;
export const isGetUserCartLoaded = (state: MoreServicesState) => state.isGetUserCartLoaded;

export const addItemToCartResult = (state: MoreServicesState) => state.addItemToCartResult;
export const addItemToCartSuccessMessage = (state: MoreServicesState) => state.addItemToCartSuccessMessage;
export const addItemToCartStatusCode = (state: MoreServicesState) => state.addItemToCartStatusCode;
export const isAddItemToCartLoading = (state: MoreServicesState) => state.isAddItemToCartLoading;
export const isAddItemToCartLoaded = (state: MoreServicesState) => state.isAddItemToCartLoaded;

export const removeItemFromCartResult = (state: MoreServicesState) => state.removeItemFromCartResult;
export const removeItemFromCartSuccessMessage = (state: MoreServicesState) => state.removeItemFromCartSuccessMessage;
export const removeItemFromCartStatusCode = (state: MoreServicesState) => state.removeItemFromCartStatusCode;
export const isRemoveItemFromCartLoading = (state: MoreServicesState) => state.isRemoveItemFromCartLoading;
export const isRemoveItemFromCartLoaded = (state: MoreServicesState) => state.isRemoveItemFromCartLoaded;

export const getMyLoyaltyPointsResult = (state: MoreServicesState) => state.getMyLoyaltyPointsResult;
export const getMyLoyaltyPointsSuccessMessage = (state: MoreServicesState) => state.getMyLoyaltyPointsSuccessMessage;
export const getMyLoyaltyPointsStatusCode = (state: MoreServicesState) => state.getMyLoyaltyPointsStatusCode;
export const isGetMyLoyaltyPointsLoading = (state: MoreServicesState) => state.isGetMyLoyaltyPointsLoading;
export const isGetMyLoyaltyPointsLoaded = (state: MoreServicesState) => state.isGetMyLoyaltyPointsLoaded;

export const emptyCartResult = (state: MoreServicesState) => state.emptyCartResult;
export const emptyCartSuccessMessage = (state: MoreServicesState) => state.emptyCartSuccessMessage;
export const emptyCartStatusCode = (state: MoreServicesState) => state.emptyCartStatusCode;
export const isEmptyCartLoading = (state: MoreServicesState) => state.isEmptyCartLoading;
export const isEmptyCartLoaded = (state: MoreServicesState) => state.isEmptyCartLoaded;

export const applyCouponResult = (state: MoreServicesState) => state.applyCouponResult;
export const applyCouponSuccessMessage = (state: MoreServicesState) => state.applyCouponSuccessMessage;
export const applyCouponStatusCode = (state: MoreServicesState) => state.applyCouponStatusCode;
export const isApplyCouponLoading = (state: MoreServicesState) => state.isApplyCouponLoading;
export const isApplyCouponLoaded = (state: MoreServicesState) => state.isApplyCouponLoaded;

export const dealHistoryCartResult = (state: MoreServicesState) => state.dealHistoryCartResult;
export const dealHistoryCartSuccessMessage = (state: MoreServicesState) => state.dealHistoryCartSuccessMessage;
export const dealHistoryCartStatusCode = (state: MoreServicesState) => state.dealHistoryCartStatusCode;
export const isDealHistoryCartLoading = (state: MoreServicesState) => state.isDealHistoryCartLoading;
export const isDealHistoryCartLoaded = (state: MoreServicesState) => state.isDealHistoryCartLoaded;
export const startTransactionResult = (state: MoreServicesState) => state.startTransactionResult;
export const startTransactionSuccessMessage = (state: MoreServicesState) => state.startTransactionSuccessMessage;
export const startTransactionStatusCode = (state: MoreServicesState) => state.startTransactionStatusCode;
export const isStartTransactionLoading = (state: MoreServicesState) => state.isStartTransactionLoading;
export const isStartTransactionLoaded = (state: MoreServicesState) => state.isStartTransactionLoaded;

export const merchantTransactionListResult = (state: MoreServicesState) => state.merchantTransactionListResult;
export const merchantTransactionListSuccessMessage = (state: MoreServicesState) => state.merchantTransactionListSuccessMessage;
export const merchantTransactionListStatusCode = (state: MoreServicesState) => state.merchantTransactionListStatusCode;
export const isMerchantTransactionListLoading = (state: MoreServicesState) => state.isMerchantTransactionListLoading;
export const isMerchantTransactionListLoaded = (state: MoreServicesState) => state.isMerchantTransactionListLoaded;

export const addMerchantTransactionResult = (state: MoreServicesState) => state.addMerchantTransactionResult;
export const addMerchantTransactionSuccessMessage = (state: MoreServicesState) => state.addMerchantTransactionSuccessMessage;
export const addMerchantTransactionStatusCode = (state: MoreServicesState) => state.addMerchantTransactionStatusCode;
export const addMerchantTransactionLoading = (state: MoreServicesState) => state.addMerchantTransactionLoading;
export const addMerchantTransactionLoaded = (state: MoreServicesState) => state.addMerchantTransactionLoaded;

export const updateTransactionStatusResult = (state: MoreServicesState) => state.updateTransactionStatusResult;
export const updateTransactionStatusSuccessMessage = (state: MoreServicesState) => state.updateTransactionStatusSuccessMessage;
export const updateTransactionStatusStatusCode = (state: MoreServicesState) => state.updateTransactionStatusStatusCode;
export const isUpdateTransactionStatusLoading = (state: MoreServicesState) => state.isUpdateTransactionStatusLoading;
export const isUpdateTransactionStatusLoaded = (state: MoreServicesState) => state.isUpdateTransactionStatusLoaded;

export const addDealsResult = (state: MoreServicesState) => state.addDealsResult;
export const addDealsSuccessMessage = (state: MoreServicesState) => state.addDealsSuccessMessage;
export const addDealsStatusCode = (state: MoreServicesState) => state.addDealsStatusCode;
export const isAddDealsLoading = (state: MoreServicesState) => state.isAddDealsLoading;
export const isAddDealsLoaded = (state: MoreServicesState) => state.isAddDealsLoaded;

export const redeemLoyaltyResult = (state: MoreServicesState) => state.redeemLoyaltyResult;
export const redeemLoyaltySuccessMessage = (state: MoreServicesState) => state.redeemLoyaltySuccessMessage;
export const redeemLoyaltyStatusCode = (state: MoreServicesState) => state.redeemLoyaltyStatusCode;
export const isRedeemLoyaltyLoading = (state: MoreServicesState) => state.isRedeemLoyaltyLoading;
export const isRedeemLoyaltyLoaded = (state: MoreServicesState) => state.isRedeemLoyaltyLoaded;

export const moreServicesNetworkError = (state: MoreServicesState) => state.moreServicesNetworkError; 
