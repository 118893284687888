/*******************************
 * Component -  My Cart
 * Created By - Akshata c
 * Created on - 11/04/2020
 * Updated By - Neha R
 * Updated On - 11/04/2020
 *************************************/
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ObjectInterface } from '../../../models/global-interface';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { RemoveFromCartPopupComponent } from '../remove-from-cart-popup/remove-from-cart-popup.component';
import { ApplyCouponPopupComponent } from '../apply-coupon-popup/apply-coupon-popup.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss']
})
export class MyCartComponent implements OnInit {
  /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * variable use
	 */
	mycart: any;
	/**
	 * variable use
	 */
	cartDetails: any;
	/**
	 * Transaction Detail
	 */
	transactionDetail : any;
	myDeal: any
		/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any
	/**
	 * variable to set value to spend loyalty or remove loyalty
	 */
	loyaltyVar: boolean;
	/**
	 * my cart labels
	 */
	myCartLables: {}
	public ngUnsubscribe: Subject<void> = new Subject<void>();
	errorMessages: {};
	successMessages: {};
	isLoadingCart: boolean;
	userData: {};
	isBlind;
	/**
	 * Angular method which runs on component initialization
	 */
  constructor(
    private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private ref: ChangeDetectorRef,
  ) { 
    this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
  }

  ngOnInit() {
	this.loyaltyVar = false;
    // token from localstorage
		this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        const localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
      }
	});
	this.store.select(fromStore.getVerifyPasswordResponse)
	.subscribe((getVerifyPasswordResponse) => {
		this.userData = getVerifyPasswordResponse;
	});
	this.store.select(fromStore.getMyCartLabels)
	.subscribe(labels => {
		this.myCartLables = labels;
	});
	if (Object.keys(this.apiDetails).length > 0) {
		this.store.dispatch(new fromStore.GetUserCart({
		  url: this.apiDetails.user.base_url +
		    this.apiDetails.user.getUserCart.url,
		  method: this.apiDetails.user.getUserCart.method,
		  body:  {
			userId: this.userId
		  }
		}));
	}

    // server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
    .subscribe((serverErrorMessage) => {
      if (serverErrorMessage != null) {
        if (serverErrorMessage === 'InvalidCredentials') {
          this.router.navigate(['/Login/login']);
        } else {
          this.serverMessage = serverErrorMessage;
          this.isServerErrorMessage = true;
          console.error(this.serverMessage);
        }
      }
	});
	
	this.store.select(fromStore.isAddItemToCartLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});
	this.store.select(fromStore.isGetUserCartLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});
	this.store.select(fromStore.getErrorMessages)
	.subscribe((errorMessage) => {
		this.errorMessages = errorMessage;
	});
	//add into cart
	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isAddItemToCartLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.addItemToCartStatusCode)
					.subscribe((code:any) => {
						if (code === 200) {
							this.store.select(fromStore.addItemToCartResult)
							.subscribe(result => {
								this.mycart = result;
							});
						}else if( code === "You can't purchase more deal" ){
							this.selectedLanguage === 'en' ? this.toastr.error("You can't purchase more deal")
							: this.toastr.error("तुम्ही अधिक डील खरेदी करू शकत नाही")
						} 
						else{
						this.store.select(fromStore.getErrorMessages)
							.subscribe(errorMessages => {
								if(errorMessages)
									console.error(errorMessages[this.selectedLanguage].code);
							});
						}
					});
				}
			});
		}
	});

	this.store.select(fromStore.getSuccessMessages)
		.subscribe(res => {
			this.successMessages = res;
		});
		this.store.select(fromStore.isGetUserCartLoading)
		.subscribe(loading => {
			this.isLoadingCart = loading;
		});
	//get user cart details
	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isGetUserCartLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.getUserCartStatusCode)
					.subscribe(code => {
						if (code === 200) {
							this.store.select(fromStore.getUserCartResult)
							.subscribe(result => {
								this.cartDetails = result;
								this.data = this.cartDetails.data;
							});
						}
						else if (code === 500) {
						
							this.store.select(fromStore.getErrorMessages)
							.subscribe((errorMessage) => {
								this.dataNotFoundMsg = "no data";
								this.errorMessages = errorMessage;
							});
						} 
						// else {
						// 	this.store.select(fromStore.getUserCartSuccessMessage)
						// 	.subscribe(errorMessages => {
						// 		if(errorMessages)
						// 			console.error(errorMessages);
						// 	});
						// }
					});
				}
			});
		}
	});

	/**
	 * Created by neha
	 * Start Transaction
	 */

    this.store.select(fromStore.isStartTransactionLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});
  }


/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }


	/**
	 * Created by Akshata c
	 * For add data to cart
	 */ 
  async addToCartt(data, incOrDecr){
	  if (Object.keys(this.apiDetails).length > 0) {
		if(incOrDecr == 'actualPointsSpend') {
			this.loyaltyVar = true; 
				await this.store.dispatch(new fromStore.AddItemToCart({
					url: this.apiDetails.user.base_url +
					  this.apiDetails.user.addItemToTheCart.url,
					method: this.apiDetails.user.addItemToTheCart.method,
					body:  {
						userId: this.userId,
						engagementId: data.engagementId,
						quantity: data.quantity,
						loyaltySpend: data.loyaltySpend,
						is_web_loyalty_request: true
					}
				}));
		}
		else if(incOrDecr == 'actualPointsRemove') {
			this.loyaltyVar = true; 
			await this.store.dispatch(new fromStore.AddItemToCart({
				url: this.apiDetails.user.base_url +
				  this.apiDetails.user.addItemToTheCart.url,
				method: this.apiDetails.user.addItemToTheCart.method,
				body:  {
					userId: this.userId,
					engagementId: data.engagementId,
					quantity: data.quantity,
					loyaltySpend: 0,
					is_web_loyalty_request: true
				}
			}));
			this.loyaltyVar = false; 
		}
		else if(incOrDecr == true) {
			await this.store.dispatch(new fromStore.AddItemToCart({
				url: this.apiDetails.user.base_url +
				this.apiDetails.user.addItemToTheCart.url,
				method: this.apiDetails.user.addItemToTheCart.method,
				body:  {
					userId: this.userId,
					engagementId: data.engagementId,
					quantity: data.quantity + 1,
					// loyaltySpend: data.loyaltySpend
				}
		  	}));
		}
		else if(incOrDecr == false) {
			if(data.quantity == 1) {
				this.store.dispatch(new fromStore.RemoveItemFromCart({
					url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].removeItemFromCart.url,
					method: this.apiDetails['user'].removeItemFromCart.method,
					body:  {
					userId: this.userId,
					engagementId: data.engagementId,
					quantity: 0,
					loyaltySpend: 0
					}
				}));
				this.store.select(fromStore.removeItemFromCartStatusCode)
				.subscribe(code => {
					if (code === 200) {
						this.store.dispatch(new fromStore.GetUserCart({
							url: this.apiDetails.user.base_url +
								this.apiDetails.user.getUserCart.url,
							method: this.apiDetails.user.getUserCart.method,
							body:  {
								userId: this.userId
							}
						}));
					}
				});
			
			  }
			else {
				await this.store.dispatch(new fromStore.AddItemToCart({
					url: this.apiDetails.user.base_url +
					  this.apiDetails.user.addItemToTheCart.url,
					method: this.apiDetails.user.addItemToTheCart.method,
					body:  {
						userId: this.userId,
						engagementId: data.engagementId,
						quantity: data.quantity - 1,
						// loyaltySpend: data.loyaltySpend
					}
				}));
			}
		}

		/**
		 * Created by neha
		 * Start Transaction
		 */

	
		//get user cart details
		this.store.select(fromStore.isAddItemToCartLoaded)
		.subscribe(loaded => {
			if (loaded) {
				this.store.select(fromStore.addItemToCartStatusCode)
				// .pipe(take(1))
				.subscribe(code => {
					if(code) {
						if (code === 200) {
							this.store.dispatch(new fromStore.GetUserCart({
								url: this.apiDetails.user.base_url +
									this.apiDetails.user.getUserCart.url,
								method: this.apiDetails.user.getUserCart.method,
								body:  {
									userId: this.userId
								}
							}));
						}  else {
							this.store.select(fromStore.addItemToCartSuccessMessage)
							.subscribe(errorMessages => {
								if(errorMessages)
									console.error(errorMessages);
							});
						}
					}
				});
			}
		});
			
	}
  }

  /**created by neha
   * to start transaction
   */
  async startTransaction(){
	if (Object.keys(this.apiDetails).length > 0) {
		await this.store.dispatch(new fromStore.StartTransaction({
			url: this.apiDetails['user'].base_url +
			this.apiDetails['user'].startATransaction.url,
			method: this.apiDetails['user'].startATransaction.method,
			body:  {
				userId: this.userId,
			}
		}));
		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
		});

		this.store.select(fromStore.isStartTransactionLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.startTransactionStatusCode)
						.subscribe(code => {
							if(code) {
								if (code === 200) {
									this.store.select(fromStore.startTransactionResult)
									.subscribe(result => {
										this.transactionDetail = result;
									});
									// this.store.dispatch(new fromStore.EmptyCart({
									// 	url: this.apiDetails.user.base_url +
									// 	  this.apiDetails.user.cleanCart.url,
									// 	method: this.apiDetails.user.cleanCart.method,
									// 	body:  {
									// 	  userId: this.userId
									// 	}
									//   }));
									this.router.navigate(['/cep/thank-you/',this.transactionDetail.transactionId,this.transactionDetail.startFinalPrice]);
								}
								else {
									this.store.select(fromStore.getUserCartSuccessMessage)
									.subscribe(errorMessages => {
										if(errorMessages) {
											console.error(errorMessages);
										}
									});
								}
							}
						});
					}
				});
  	}
  }

  removeFromCart(data) {
	//this.googleAnalyticsService.eventEmitter('Remove item from cart', 'Click on remove item from cart', 'Click Event', 1);
		const dialogRef = this.dialog.open(RemoveFromCartPopupComponent, {
			data: {
				disableClose: true,
				maxWidth: '100vw',
				autoFocus: false,
				label: data
			}
		});
  }

  	/**
	 * Created by Akshata c
	 * For redirecting to Apply Coupon popup
	 */ 
	applyCoupon(_label) {
		//this.googleAnalyticsService.eventEmitter('Apply Coupon', 'Click on Apply Coupon', 'Click Event', 1);
		const dialogRef = this.dialog.open(ApplyCouponPopupComponent, {
			data: {
				disableClose: true,
				maxWidth: '100vw',
				autoFocus: false,
				label: _label
			}
		});
	}

	/**
	 * Created by Akshata c
	 * empty cart
	 */ 
	async clearCart() {
		if (Object.keys(this.apiDetails).length > 0) {
			await this.store.dispatch(new fromStore.EmptyCart({
			  url: this.apiDetails.user.base_url +
				this.apiDetails.user.cleanCart.url,
			  method: this.apiDetails.user.cleanCart.method,
			  body:  {
				userId: this.userId
			  }
			}));
			this.store.select(fromStore.emptyCartStatusCode)
			.subscribe(code => {
				this.store.select(fromStore.emptyCartSuccessMessage)
				.subscribe(errorMessages => {
					if(errorMessages) {
						// this.toastr.success(errorMessages);
						this.toastr.success(this.successMessages[this.selectedLanguage][errorMessages]);
					}
						
				});
			});
			this.router.navigate(['/cep/offers']);
		}
	}


  ngAfterContentChecked() {
	this.ref.detectChanges();
}
ngOnDestroy() {
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
	// this.store.dispatch(new fromStore.resetServerErrorMessage());
}
/**
	 * Angualr method which runs after view initialization
	 */
	ngAfterViewInit() {
		window.scroll(0, 0); 
	}
}

