import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})
export class ContactUsService {
/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) {

	}
	getContactUsDetails(request) {
		let method = request.method;
		let url = request.url;
		return this.apiCallService.apiCall(method, url, null);
	}

}
