import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as  fromNearMeOnMap from  '../reducer/near-me-map.reducer';

export const getNearMeOnMapState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.nearMeOnMap
);

export const CfcCentres = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.cfcCentres

);

export const getNearMeOnMap = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.nearMeONMap
);

export const cfcCentresLoading = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.cfcCentresLoading
);

export const loadingNearMeOnMap = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.isloadingNearMeOnMap
);

export const getIsloadedNearMeOnMap = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.isloadedNearMeOnMap
);

export const cfcCentresLoaded = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.cfcCentresLoaded
);

export const getBicycleOnMap = createSelector(
getNearMeOnMapState,
fromNearMeOnMap.getBicycleOnMap
);

export const getIsBicycleLoaded = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getIsBicycleLoaded
	);

export const getEbikeOnMap = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getEbikeOnMap
);

export const getIsBikeLoading = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getIsBikeLoading
);

export const getIsBikeLoaded = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getIsBikeLoaded
);

export const getIsBicycleLoading = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.isBicycleLoading
);

export const getnearMeErrorMessage = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.nearMeErrorMessage
);

export const getNearMeStatusCode = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getNearMeStatusCode
);

export const getcfcStatusCode = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getcfcStatusCode
);

export const getBicycleStatusCode = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getBicycleStatusCode
);

export const getBicycleErrorMessage = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getBicycleErrorMessage
);

export const getBikeErrorMessage = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getBikeErrorMessage
);

export const getBikeStatusCode = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getBikeStatusCode
);

export const getServerErrMessage = createSelector(
	getNearMeOnMapState,
	fromNearMeOnMap.getServerErrorMessage
);