import { ConstantsEffects } from './constants.effect';
import { FeedsEffect } from './feeds.effect';
export * from './constants.effect';
export * from './feeds.effect';
import { DepartmentsEffects } from './departments.effect';
import { CfcCentresEffects } from  './near-me-map.effects';
import { AroundMeEffects } from './around-me.effect';
import { LogoutEffects } from './logout.effect';

export const effects: any[] = [
  ConstantsEffects,
  DepartmentsEffects,
  CfcCentresEffects,
  FeedsEffect,
  AroundMeEffects,
  LogoutEffects,
];

export * from './constants.effect';
export * from './departments.effect';
export * from './near-me-map.effects';
export * from './feeds.effect';
export * from './around-me.effect';
export * from './logout.effect';
