import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	constructor(
		private http: HttpClient,
		private apiCallService: ApiCallService
	) { }
	getUserStateResponseDetails(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	getEmployeeStateResponseDetails(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	verifyPassword(response) {
		let method = response.method;
		let url = response.url;
		let body = response.body
		return this.apiCallService.apiCall(method, url, body);
	}
	loginWithOTP(response){
		let method = response.method;
		let url = response.url;
		let body = response.body
		return this.apiCallService.apiCall(method, url, body);
	}
	loginEmployee(response){
		let method = response.method;
		let url = response.url;
		let body = response.body
		return this.apiCallService.apiCall(method, url, body);
	}
	sendOtp(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	verifyOtp(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body
		let result = this.apiCallService.apiCall(method, url, body);

		return result
	}
	facebook(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	twitter(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	google(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	forgotPassword(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}
	forgotPasswordByEmail(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body
		return this.apiCallService.apiCall(method, url, body);
	}

	UpdateProfileDetailsService(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	registerUser(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}



	validateReferralCode(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}


	getUserDetails(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		let token = request.token;
		return this.apiCallService.apiCall(method, url, body);
	}

	getMostUsedServices(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	updateLanguage(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getUpdatePasswardResponse(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	updateProfilePhoto(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body;
		// return this.apiCallService.apiCallForObjectStorage(method, url, body);
		if(method === 'post'){
			return this.http.post(url,body)
		}
	}

	favImage(request) {
		let method = request.method;
		let url = request.url ;
		let body = request.body;
		return this.apiCallService.apiCallForObjectStorage(method, url, body);
	}

	changeMobileNumber(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	verifyMobileNumber(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getOtpChoices(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getTermsAndConditions(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getMerchantTermsAndConditions(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	interestedToBeMerchant(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	interestedToBeBrand(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	registerAsMerchant(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	registerAsBrand(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

	getAllBrand(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	subscribeToNewsletter(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	  }
}
