import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as  fromAroundMeOnMap from  '../reducer/group.reducer';

export const groupState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.group
);

export const getGroupDetailsSuccessMessage = createSelector(
	groupState,
	fromAroundMeOnMap.getGroupDetailsSuccessMessage
);

export const getGroupDetailsResult = createSelector(
	groupState,
	fromAroundMeOnMap.getGroupDetailsResult
);
//added by vinita
export const getGroupChatArray = createSelector(
	groupState,
	fromAroundMeOnMap.getGroupChatArray
);
//vinita
export const isGetGroupDetailsLoading = createSelector(
	groupState,
	fromAroundMeOnMap.isGetGroupDetailsLoading
);

export const isGetGroupDetailsLoaded = createSelector(
	groupState,
	fromAroundMeOnMap.isGetGroupDetailsLoaded
);

export const getGroupDetailsStatusCode = createSelector(
	groupState,
	fromAroundMeOnMap.getGroupDetailsStatusCode
);

export const getAllGroupsSuccessMessage = createSelector(
	groupState,
	fromAroundMeOnMap.getAllGroupsSuccessMessage
);

export const getAllGroupsResult = createSelector(
	groupState,
	fromAroundMeOnMap.getAllGroupsResult
);

export const isGetAllGroupsLoading = createSelector(
	groupState,
	fromAroundMeOnMap.isGetAllGroupsLoading
);

export const isGetAllGroupsLoaded = createSelector(
	groupState,
	fromAroundMeOnMap.isGetAllGroupsLoaded
);

export const getAllGroupsStatusCode = createSelector(
	groupState,
	fromAroundMeOnMap.getAllGroupsStatusCode
);

export const acceptPublicGroupInvitationSuccessMessage = createSelector(
	groupState,
	fromAroundMeOnMap.acceptPublicGroupInvitationSuccessMessage
);

export const acceptPublicGroupInvitationResult = createSelector(
	groupState,
	fromAroundMeOnMap.acceptPublicGroupInvitationResult
);

export const isAcceptPublicGroupInvitationLoading = createSelector(
	groupState,
	fromAroundMeOnMap.isAcceptPublicGroupInvitationLoading
);

export const isAcceptPublicGroupInvitationLoaded = createSelector(
	groupState,
	fromAroundMeOnMap.isAcceptPublicGroupInvitationLoaded
);

export const acceptPublicGroupInvitationStatusCode = createSelector(
	groupState,
	fromAroundMeOnMap.acceptPublicGroupInvitationStatusCode
);

export const createMessageSuccessMessage = createSelector(
	groupState,
	fromAroundMeOnMap.createMessageSuccessMessage
);

export const createMessageResult = createSelector(
	groupState,
	fromAroundMeOnMap.createMessageResult
);

export const isCreateMessageLoading = createSelector(
	groupState,
	fromAroundMeOnMap.isCreateMessageLoading
);

export const isCreateMessageLoaded = createSelector(
	groupState,
	fromAroundMeOnMap.isCreateMessageLoaded
);

export const createMessageStatusCode = createSelector(
	groupState,
	fromAroundMeOnMap.createMessageStatusCode
);

export const getAllUserGroupsSuccessMessage = createSelector(
	groupState,
	fromAroundMeOnMap.getAllUserGroupsSuccessMessage
);

export const getAllUserGroupsResult = createSelector(
	groupState,
	fromAroundMeOnMap.getAllUserGroupsResult
);

export const isGetAllUserGroupsLoading = createSelector(
	groupState,
	fromAroundMeOnMap.isGetAllUserGroupsLoading
);

export const isGetAllUserGroupsLoaded = createSelector(
	groupState,
	fromAroundMeOnMap.isGetAllUserGroupsLoaded
);

export const getAllUserGroupsStatusCode = createSelector(
	groupState,
	fromAroundMeOnMap.getAllUserGroupsStatusCode
);

export const getChatPaginationCount = createSelector(
	groupState,
	fromAroundMeOnMap.getChatPaginationCount
);