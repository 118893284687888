import * as feedsListing from '../action/feeds.action';
import { act } from '@ngrx/effects';

export interface FeedsState {
  feedsListingResult: {}[];
  timeLineListingStatusCode: number;
  isFeedsListingLoaded: boolean;
  isFeedsListingLoading: boolean;
  feedListingErrorMessage: string;
  isFiltterAppled: boolean;

  feedsListingEmergencyResult: {}[];
  timeLineListingEmergencyStatusCode: number;
  isFeedsListingEmergencyLoaded: boolean;
  isFeedsListingEmergencyLoading: boolean;
  feedListingEmergencyErrorMessage: string;

  feedsDetailResult: {};
  isDetailsScreenLoading: boolean;
  isDetailsScreenLoaded: boolean;
  errorMessage: string;
  feedsPaginationCount: number;
  emergencyPaginationCount : number;
  feedListingStatusCode: number;
  serverErrorMessage: string;

  filteredLabels: [];

  addOrRemoveBlogCommentResult: {};
  addOrRemoveBlogCommentErrorMessage: string;
  isaddOrRemoveBlogCommentLoading: boolean;
  isaddOrRemoveBlogCommentLoaded: boolean;
  addOrRemoveBlogCommentStatusCode: number;

  getAllBlogCommentResult: [];
  getAllBlogCommentErrorMessage: string;
  isGetAllBlogCommentLoading: boolean;
  isGetAllBlogCommentLoaded: boolean;
  getAllBlogCommentStatusCode: number;

  getFeedsEmergencyResult: [];
  getFeedsEmergencyErrorMessage: string;
  isGetFeedsEmergencyLoading: boolean;
  isGetFeedsEmergencyLoaded: boolean;
  getFeedsEmergencyStatusCode: number;
  addBlogResult: {};
  addBlogErrorMessage: string;
  isAddBlogLoading: boolean;
  isAddBlogLoaded: boolean;
  addBlogStatusCode: number;

  addForumResult: {};
  addForumErrorMessage: string;
  isAddForumLoading: boolean;
  isAddForumLoaded: boolean;
  addForumStatusCode: number;

  updateForumResult: {};
  updateForumErrorMessage: string;
  isUpdateForumLoading: boolean;
  isUpdateForumLoaded: boolean;
  updateForumStatusCode: number;

  removeForumResult: {};
  removeForumErrorMessage: string;
  isRemoveForumLoading: boolean;
  isRemoveForumLoaded: boolean;
  removeForumStatusCode: number;

  getForumByUserResult: {};
  getForumByUserErrorMessage: string;
  isGetForumByUserLoading: boolean;
  isGetForumByUserLoaded: boolean;
  getForumByUserStatusCode: number;

  reportAbuseResult: {};
  reportAbuseErrorMessage: string;
  isReportAbuseLoading: boolean;
  isReportAbuseLoaded: boolean;
  reportAbuseStatusCode: number;

  reportAbuseCommentResult: {};
  reportAbuseCommentErrorMessage: string;
  isReportAbuseCommentLoading: boolean;
  isReportAbuseCommentLoaded: boolean;
  reportAbuseCommentStatusCode: number;


  reportWebinarAbuseResult: {};
  reportWebinarAbuseErrorMessage: string;
  isReportWebinarAbuseLoading: boolean;
  isReportWebinarAbuseLoaded: boolean;
  reportWebinarAbuseStatusCode: number;

  reportWebinarAbuseCommentResult: {};
  reportWebinarAbuseCommentErrorMessage: string;
  isReportWebinarAbuseCommentLoading: boolean;
  isReportWebinarAbuseCommentLoaded: boolean;
  reportWebinarAbuseCommentStatusCode: number;

  registrationCompetitionResult: {};
  registrationCompetitionErrorMessage: string;
  isRegistrationCompetitionLoading: boolean;
  isRegistrationCompetitionLoaded: boolean;
  registrationCompetitionStatusCode: number;

  submitCompetitionResult: {};
  submitCompetitionErrorMessage: string;
  isSubmitCompetitionLoading: boolean;
  isSubmitCompetitionLoaded: boolean;
  submitCompetitionStatusCode: number;
  timelineId:string;
  isTimelineChnaged:boolean;

  getUserNotificationResult: {};
  getUserNotificationErrorMessage: string;
  isgetUserNotificationLoading: boolean;
  isgetUserNotificationLoaded: boolean;
  getUserNotificationStatusCode: number;

  getBlogListingByUserIdResult: {};
  getBlogListingByUserIdErrorMessage: string;
  isgetBlogListingByUserIdLoading: boolean;
  isgetBlogListingByUserIdLoaded: boolean;
  getBlogListingByUserIdStatusCode: number;

  getForumListingByUserIdResult: {};
  getForumListingByUserIdErrorMessage: string;
  isgetForumListingByUserIdLoading: boolean;
  isgetForumListingByUserIdLoaded: boolean;
  getForumListingByUserIdStatusCode: number;


  getBlogByBlogIdResult: {};
  getBlogByBlogIdErrorMessage: string;
  isgetBlogByBlogIdLoading: boolean;
  isgetBlogByBlogIdLoaded: boolean;
  getBlogByBlogIdStatusCode: number;

  editBlogResult: {};
  editBlogErrorMessage: string;
  iseditBlogLoading: boolean;
  iseditBlogLoaded: boolean;
  editBlogStatusCode: number;


    getForumByForumIdResult: {};
  getForumByForumIdErrorMessage: string;
  isgetForumByForumIdLoading: boolean;
  isgetForumByForumIdLoaded: boolean;
  getForumByForumIdStatusCode: number;

  editForumResult: {};
  editForumErrorMessage: string;
  iseditForumLoading: boolean;
  iseditForumLoaded: boolean;
  editForumStatusCode: number;

  updateBlogPhotoResponse: {};
  updateBlogPhotoStatusCode: number;
	isUpdateBlogPhotoLoading: boolean;
	isUpdateBlogPhotoLoaded: boolean;
  optionForFileUpload: string;
  
  updateBlogPhotoFavResponse: {};
  updateBlogPhotoFavStatusCode: number;
	isUpdateBlogPhotoFavLoading: boolean;
  isUpdateBlogPhotoFavLoaded: boolean;
  
  blogsPaginationCount: number;
  forumsPaginationCount: number
}

export const initialState: FeedsState = {
  feedsListingResult: [],
  isFeedsListingLoaded: false,
  isFeedsListingLoading: false,
  timeLineListingStatusCode: 0,
  feedListingErrorMessage: null,
  isFiltterAppled: false,

  feedsListingEmergencyResult: [],
  timeLineListingEmergencyStatusCode: 0,
  isFeedsListingEmergencyLoaded: false,
  isFeedsListingEmergencyLoading: false,
  feedListingEmergencyErrorMessage: null,

  feedsDetailResult: {},
  isDetailsScreenLoading: false,
  isDetailsScreenLoaded: false,
  errorMessage: null,
  feedsPaginationCount: 0,
  emergencyPaginationCount: 0,
  feedListingStatusCode: 0,
  serverErrorMessage: null,

  filteredLabels: [],

  addOrRemoveBlogCommentResult: {},
  addOrRemoveBlogCommentErrorMessage: null,
  isaddOrRemoveBlogCommentLoading: false,
  isaddOrRemoveBlogCommentLoaded: false,
  addOrRemoveBlogCommentStatusCode: 0,


  getAllBlogCommentResult: [],
  getAllBlogCommentErrorMessage: null,
  isGetAllBlogCommentLoading: false,
  isGetAllBlogCommentLoaded: false,
  getAllBlogCommentStatusCode: 0,

  getFeedsEmergencyResult: [],
  getFeedsEmergencyErrorMessage: null,
  isGetFeedsEmergencyLoading: false,
  isGetFeedsEmergencyLoaded: false,
  getFeedsEmergencyStatusCode: 0,

  addBlogResult: [],
  addBlogErrorMessage: null,
  isAddBlogLoading: false,
  isAddBlogLoaded: false,
  addBlogStatusCode: 0,

  addForumResult: [],
  addForumErrorMessage: null,
  isAddForumLoading: false,
  isAddForumLoaded: false,
  addForumStatusCode: 0,

  updateForumResult: [],
  updateForumErrorMessage: null,
  isUpdateForumLoading: false,
  isUpdateForumLoaded: false,
  updateForumStatusCode: 0,

  removeForumResult: [],
  removeForumErrorMessage: null,
  isRemoveForumLoading: false,
  isRemoveForumLoaded: false,
  removeForumStatusCode: 0,

  getForumByUserResult: {},
  getForumByUserErrorMessage: null,
  isGetForumByUserLoading: false,
  isGetForumByUserLoaded: false,
  getForumByUserStatusCode: 0,

  reportAbuseResult: {},
  reportAbuseErrorMessage: null,
  isReportAbuseLoading: false,
  isReportAbuseLoaded: false,
  reportAbuseStatusCode: 0,

  reportAbuseCommentResult: {},
  reportAbuseCommentErrorMessage: null,
  isReportAbuseCommentLoading: false,
  isReportAbuseCommentLoaded: false,
  reportAbuseCommentStatusCode: 0,

  reportWebinarAbuseResult: {},
  reportWebinarAbuseErrorMessage: null,
  isReportWebinarAbuseLoading: false,
  isReportWebinarAbuseLoaded: false,
  reportWebinarAbuseStatusCode: 0,

  reportWebinarAbuseCommentResult: {},
  reportWebinarAbuseCommentErrorMessage: null,
  isReportWebinarAbuseCommentLoading: false,
  isReportWebinarAbuseCommentLoaded: false,
  reportWebinarAbuseCommentStatusCode: 0,

  registrationCompetitionResult: {},
  registrationCompetitionErrorMessage: null,
  isRegistrationCompetitionLoading: false,
  isRegistrationCompetitionLoaded: false,
  registrationCompetitionStatusCode: 0,

  submitCompetitionResult: {},
  submitCompetitionErrorMessage: null,
  isSubmitCompetitionLoading: false,
  isSubmitCompetitionLoaded: false,
  submitCompetitionStatusCode: 0,
  timelineId:null,
  isTimelineChnaged: false,

  getUserNotificationResult: {},
  getUserNotificationErrorMessage: null,
  isgetUserNotificationLoading: false,
  isgetUserNotificationLoaded: false,
  getUserNotificationStatusCode: 0,

  getBlogListingByUserIdResult: {},
  getBlogListingByUserIdErrorMessage: null,
  isgetBlogListingByUserIdLoading: false,
  isgetBlogListingByUserIdLoaded: false,
  getBlogListingByUserIdStatusCode: 0,

  getForumListingByUserIdResult: {},
  getForumListingByUserIdErrorMessage: null,
  isgetForumListingByUserIdLoading: false,
  isgetForumListingByUserIdLoaded: false,
  getForumListingByUserIdStatusCode: 0,

  getBlogByBlogIdResult: {},
  getBlogByBlogIdErrorMessage: null,
  isgetBlogByBlogIdLoading: false,
  isgetBlogByBlogIdLoaded: false,
  getBlogByBlogIdStatusCode: 0,

  editBlogResult: {},
  editBlogErrorMessage: null,
  iseditBlogLoading: false,
  iseditBlogLoaded: false,
  editBlogStatusCode: 0,
  
  getForumByForumIdResult: {},
  getForumByForumIdErrorMessage: null,
  isgetForumByForumIdLoading: false,
  isgetForumByForumIdLoaded: false,
  getForumByForumIdStatusCode: 0,

  editForumResult: {},
  editForumErrorMessage: null,
  iseditForumLoading: false,
  iseditForumLoaded: false,
  editForumStatusCode: 0,

  updateBlogPhotoResponse: {},
	optionForFileUpload: null,
	isUpdateBlogPhotoLoading: false,
	isUpdateBlogPhotoLoaded: false,
  updateBlogPhotoStatusCode: 0,
  
  updateBlogPhotoFavResponse: {},
	updateBlogPhotoFavStatusCode: 0,
	isUpdateBlogPhotoFavLoading: false,
  isUpdateBlogPhotoFavLoaded: false,
  
  blogsPaginationCount: 0,
  forumsPaginationCount: 0


};

export function reducer(
	state = initialState,
	action: feedsListing.FeedsAction
): FeedsState {
  switch (action.type) {
    case feedsListing.GET_FEEDS_LISTING: {
      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      return {
        ...state,
        isFeedsListingLoaded: false,
        isFeedsListingLoading: true,
        filteredLabels
      };
    }
    case feedsListing.GET_FEEDS_LISTING_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isFeedsListingLoaded: true,
        isFeedsListingLoading: false
      };
    }
    case feedsListing.GET_FEEDS_LISTING_SUCCESS: {
      let isFiltterAppled = state.isFiltterAppled;
      let timeLineListingStatusCode = action.payload.code;
      let feedsListingResult = state.feedsListingResult;
      let isFeedsListingLoaded = state.isFeedsListingLoaded;
      let isFeedsListingLoading = state.isFeedsListingLoading;
      let feedListingErrorMessage = state.errorMessage;
      let paginationCount = state.feedsPaginationCount;
      let data;
      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      switch (timeLineListingStatusCode) {
        case 200:
          if (paginationCount == 0) {
            feedsListingResult = action.payload.result;
            timeLineListingStatusCode = action.payload.code;
          } else {
            data = feedsListingResult;
            feedsListingResult = [...data, ...action.payload.result];
          }
          isFiltterAppled = true;
          isFeedsListingLoading = false;
          isFeedsListingLoaded = true;
          break;

        case 500:
          timeLineListingStatusCode = action.payload.code;
          isFeedsListingLoading = false;
          isFeedsListingLoaded = true;
          isFiltterAppled = true;
          // feedsListingResult = [];
          feedListingErrorMessage = action.payload.message;
          break;

        case 604:
          timeLineListingStatusCode = action.payload.code;
          isFeedsListingLoading = false;
          isFeedsListingLoaded = true;
          feedListingErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        feedsListingResult,
        timeLineListingStatusCode,
        isFeedsListingLoaded,
        isFeedsListingLoading,
        feedListingErrorMessage,
        isFiltterAppled,
        filteredLabels
      };
    }

    case feedsListing.GET_FEEDS_LISTING_EMERGENCY: {
      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      return {
        ...state,
        isFeedsListingEmergencyLoaded: false,
        isFeedsListingEmergencyLoading: true,
        filteredLabels
      };
    }
    case feedsListing.GET_FEEDS_LISTING_EMERGENCY_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isFeedsListingEmergencyLoaded: true,
        isFeedsListingEmergencyLoading: false
      };
    }
    case feedsListing.GET_FEEDS_LISTING_EMERGENCY_SUCCESS: {
      let isFiltterAppled = state.isFiltterAppled;
      let timeLineListingEmergencyStatusCode = action.payload.code;
      let feedsListingEmergencyResult = state.feedsListingEmergencyResult;
      let isFeedsListingEmergencyLoaded = state.isFeedsListingEmergencyLoaded;
      let isFeedsListingEmergencyLoading = state.isFeedsListingEmergencyLoading;
      let feedListingErrorMessage = state.errorMessage;
      let data;
      let paginationCountEmergency = state.emergencyPaginationCount;

      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      switch (timeLineListingEmergencyStatusCode) {
        case 200:
          if (paginationCountEmergency == 0) {
            feedsListingEmergencyResult = action.payload.result;
            timeLineListingEmergencyStatusCode = action.payload.code;
          }
          else{
            data = feedsListingEmergencyResult;
            feedsListingEmergencyResult = [...data, ...action.payload.result];
          }
            isFiltterAppled = true;
            isFeedsListingEmergencyLoading = false;
            isFeedsListingEmergencyLoaded = true;
          
            break;

        case 500:
          timeLineListingEmergencyStatusCode = action.payload.code;
          isFeedsListingEmergencyLoading = false;
          isFeedsListingEmergencyLoaded = true;
          isFiltterAppled = true;
          // feedsListingEmergencyResult = [];
          feedListingErrorMessage = action.payload.message;
          break;

        case 604:
          timeLineListingEmergencyStatusCode = action.payload.code;
          isFeedsListingEmergencyLoading = false;
          isFeedsListingEmergencyLoaded = true;
          feedListingErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        feedsListingEmergencyResult,
        timeLineListingEmergencyStatusCode,
        isFeedsListingEmergencyLoaded,
        isFeedsListingEmergencyLoading,
        feedListingErrorMessage,
        isFiltterAppled,
        filteredLabels
      };
    }

   
    case feedsListing.GET_ENGEAGEMENT_DETAILS: {
      return {
        ...state,
        feedsDetailResult: {},
        isDetailsScreenLoading: true,
        isDetailsScreenLoaded: false,
      };
    }
    case feedsListing.GET_ENGEAGEMENT_DETAILS_SUCCESS: {
      let feedListingStatusCode = action.payload.code;
      let feedsDetailResult = state.feedsDetailResult;
      let isDetailsScreenLoaded = state.isDetailsScreenLoaded;
      let isDetailsScreenLoading = state.isDetailsScreenLoading;
      let errorMessage = state.errorMessage;

      switch (feedListingStatusCode) {
        case 200:
          feedsDetailResult = action.payload.result;
          isDetailsScreenLoading = false;
          isDetailsScreenLoaded = true;
          break;

        case 500:
          isDetailsScreenLoading = false;
          isDetailsScreenLoaded = true;
          errorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        feedsDetailResult,
        isDetailsScreenLoading,
        isDetailsScreenLoaded,
        feedListingStatusCode
      };
    }
    case feedsListing.GET_ENGEAGEMENT_DETAILS_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isDetailsScreenLoading: false,
        isDetailsScreenLoaded: true,
      };
    }

    case feedsListing.SET_PAGINATION_COUNT: {
      let feedsPaginationCount = action.payload.feedsPaginationCount;
      let feedsListingResult = state.feedsListingResult;
      if (feedsPaginationCount == 0) {

        feedsListingResult = []
      }
      return {
        ...state,
        feedsPaginationCount,
        feedsListingResult
      };
    }

    case feedsListing.SET_PAGINATION_COUNT_EMERGENCY: {
      let emergencyPaginationCount = action.payload.emergencyPaginationCount;
      let feedsListingEmergencyResult = state.feedsListingEmergencyResult;
      if (emergencyPaginationCount == 0) {

        feedsListingEmergencyResult = []
      }
      return {
        ...state,
        emergencyPaginationCount,
        feedsListingEmergencyResult
      };
    }

    case feedsListing.RESET_FAVOURITE: {
      let feedsListingResult = state.feedsListingResult;
      feedsListingResult.forEach(element => {
      })
      return {
        ...state,

      };
    }

    case feedsListing.ADD_OR_REMOVE_BLOG_COMMENT: {
      return {
        ...state,
        addOrRemoveBlogCommentResult: {},
        isaddOrRemoveBlogCommentLoading: true,
        isaddOrRemoveBlogCommentLoaded: false,
      };
    }
    case feedsListing.ADD_OR_REMOVE_BLOG_COMMENT_SUCCESS: {
      let addOrRemoveBlogCommentStatusCode = action.payload.code;
      let addOrRemoveBlogCommentResult = state.addOrRemoveBlogCommentResult;
      let isaddOrRemoveBlogCommentLoaded = state.isaddOrRemoveBlogCommentLoaded;
      let isaddOrRemoveBlogCommentLoading = state.isaddOrRemoveBlogCommentLoading;
      let addOrRemoveBlogCommentErrorMessage = state.addOrRemoveBlogCommentErrorMessage;

      switch (addOrRemoveBlogCommentStatusCode) {
        case 200:
          addOrRemoveBlogCommentResult = action.payload.result;
          isaddOrRemoveBlogCommentLoading = false;
          isaddOrRemoveBlogCommentLoaded = true;
          addOrRemoveBlogCommentErrorMessage = action.payload.message;
          break;

        case 500:
          isaddOrRemoveBlogCommentLoading = false;
          isaddOrRemoveBlogCommentLoaded = true;
          addOrRemoveBlogCommentErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        addOrRemoveBlogCommentResult,
        isaddOrRemoveBlogCommentLoading,
        isaddOrRemoveBlogCommentLoaded,
        addOrRemoveBlogCommentStatusCode,
        addOrRemoveBlogCommentErrorMessage
      };
    }
    case feedsListing.ADD_OR_REMOVE_BLOG_COMMENT_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isaddOrRemoveBlogCommentLoading: false,
        isaddOrRemoveBlogCommentLoaded: true,
      };
    }

    case feedsListing.GET_ALL_BLOG_COMMENTS: {
      return {
        ...state,
        getAllBlogCommentResult: [],
        isGetAllBlogCommentLoading: true,
        isGetAllBlogCommentLoaded: false,
      };
    }
    case feedsListing.GET_ALL_BLOG_COMMENTS_SUCCESS: {
      let getAllBlogCommentStatusCode = action.payload.code;
      let getAllBlogCommentResult = state.getAllBlogCommentResult;
      let isGetAllBlogCommentLoaded = state.isGetAllBlogCommentLoaded;
      let isGetAllBlogCommentLoading = state.isGetAllBlogCommentLoading;
      let getAllBlogCommentErrorMessage = state.getAllBlogCommentErrorMessage;

      switch (getAllBlogCommentStatusCode) {
        case 200:
          getAllBlogCommentResult = action.payload.result;
          isGetAllBlogCommentLoading = false;
          isGetAllBlogCommentLoaded = true;
          break;

        case 500:
          isGetAllBlogCommentLoading = false;
          isGetAllBlogCommentLoaded = true;
          getAllBlogCommentErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getAllBlogCommentResult,
        isGetAllBlogCommentLoading,
        isGetAllBlogCommentLoaded,
        getAllBlogCommentStatusCode,
        getAllBlogCommentErrorMessage
      };
    }
    case feedsListing.GET_ALL_BLOG_COMMENTS_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isGetAllBlogCommentLoading: false,
        isGetAllBlogCommentLoaded: true,
      };
    }

    case feedsListing.GET_FEEDS_EMERGENCY: {
      return {
        ...state,
        getFeedsEmergencyResult: [],
        isGetFeedsEmergencyLoading: true,
        isGetFeedsEmergencyLoaded: false,
      };
    }
    case feedsListing.GET_FEEDS_EMERGENCY_SUCCESS: {
      let getFeedsEmergencyStatusCode = action.payload.code;
      let getFeedsEmergencyResult = state.getFeedsEmergencyResult;
      let isGetFeedsEmergencyLoaded = state.isGetFeedsEmergencyLoaded;
      let isGetFeedsEmergencyLoading = state.isGetFeedsEmergencyLoading;
      let getFeedsEmergencyErrorMessage = state.getFeedsEmergencyErrorMessage;

      switch (getFeedsEmergencyStatusCode) {
        case 200:
          getFeedsEmergencyResult = action.payload.result;
          isGetFeedsEmergencyLoading = false;
          isGetFeedsEmergencyLoaded = true;
          break;

        case 500:
          isGetFeedsEmergencyLoading = false;
          isGetFeedsEmergencyLoaded = true;
          getFeedsEmergencyErrorMessage = action.payload.message;
      }
      return{
        ...state,
        getFeedsEmergencyResult,
        isGetFeedsEmergencyLoading,
        isGetFeedsEmergencyLoaded,
        getFeedsEmergencyStatusCode,
        getFeedsEmergencyErrorMessage,
      }
    }
    case feedsListing.ADD_BLOG: {
      return {
        ...state,
        addBlogResult: {},
        isAddBlogLoading: true,
        isAddBlogLoaded: false,
      };
    }
    case feedsListing.ADD_BLOG_SUCCESS: {
      let addBlogStatusCode = action.payload.code;
      let addBlogResult = state.addBlogResult;
      let isAddBlogLoaded = state.isAddBlogLoaded;
      let isAddBlogLoading = state.isAddBlogLoading;
      let addBlogErrorMessage = state.addBlogErrorMessage;
      switch (addBlogStatusCode) {
        case 200:
          addBlogResult = action.payload.result;
          isAddBlogLoading = false;
          isAddBlogLoaded = true;
          addBlogErrorMessage = action.payload.message;
          break;

        case 602:
          isAddBlogLoading = false;
          isAddBlogLoaded = true;
          addBlogErrorMessage = action.payload.message;
          break;

        case 950:
          isAddBlogLoading = false;
          isAddBlogLoaded = true;
          addBlogErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        addBlogResult,
        isAddBlogLoading,
        isAddBlogLoaded,
        addBlogStatusCode,
        addBlogErrorMessage
      };
    }
    case feedsListing.ADD_BLOG_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isAddBlogLoading: false,
        isAddBlogLoaded: true,
      };
    }

    case feedsListing.ADD_FORUM: {
      return {
        ...state,
        addForumResult: {},
        isAddForumLoading: true,
        isAddForumLoaded: false,
      };
    }
    case feedsListing.ADD_FORUM_SUCCESS: {
      let addForumStatusCode = action.payload.code;
      let addForumResult = state.addForumResult;
      let isAddForumLoaded = state.isAddForumLoaded;
      let isAddForumLoading = state.isAddForumLoading;
      let addForumErrorMessage = state.addForumErrorMessage;
      switch (addForumStatusCode) {
        case 200:
          addForumResult = action.payload.result;
          isAddForumLoading = false;
          isAddForumLoaded = true;
          addForumErrorMessage = action.payload.message;
          break;

        case 602: 
          isAddForumLoading = false;
          isAddForumLoaded = true;
          addForumErrorMessage = action.payload.message;
          break;

        case 603: 
          isAddForumLoading = false;
          isAddForumLoaded = true;
          addForumErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        addForumResult,
        isAddForumLoading,
        isAddForumLoaded,
        addForumStatusCode,
        addForumErrorMessage
      };
    }
    case feedsListing.ADD_FORUM_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isAddForumLoading: false,
        isAddForumLoaded: true,
      };
    }

    case feedsListing.UPDATE_FORUM: {
      return {
        ...state,
        updateForumResult: {},
        isUpdateForumLoading: true,
        isUpdateForumLoaded: false,
      };
    }
    case feedsListing.UPDATE_FORUM_SUCCESS: {
      let updateForumStatusCode = action.payload.code;
      let updateForumResult = state.updateForumResult;
      let isUpdateForumLoaded = state.isUpdateForumLoaded;
      let isUpdateForumLoading = state.isUpdateForumLoading;
      let updateForumErrorMessage = state.updateForumErrorMessage;
      switch (updateForumStatusCode) {
        case 200:
          updateForumResult = action.payload.result;
          isUpdateForumLoading = false;
          isUpdateForumLoaded = true;
          updateForumErrorMessage = action.payload.message;
          break;

        case 500:
          isUpdateForumLoading = false;
          isUpdateForumLoaded = true;
          updateForumErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        updateForumResult,
        isUpdateForumLoading,
        isUpdateForumLoaded,
        updateForumStatusCode,
        updateForumErrorMessage
      };
    }
    case feedsListing.GET_FEEDS_EMERGENCY_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isUpdateForumLoading: false,
        isUpdateForumLoaded: true,
      };
    }

    case feedsListing.GET_FORUM_BY_USER: {
      return {
        ...state,
        getForumByUserResult: {},
        isGetForumByUserLoading: true,
        isGetForumByUserLoaded: false,
      };
    }
    case feedsListing.GET_FORUM_BY_USER_SUCCESS: {
      let getForumByUserStatusCode = action.payload.code;
      let getForumByUserResult = state.getForumByUserResult;
      let isGetForumByUserLoaded = state.isGetForumByUserLoaded;
      let isGetForumByUserLoading = state.isGetForumByUserLoading;
      let getForumByUserErrorMessage = state.getForumByUserErrorMessage;
      switch (getForumByUserStatusCode) {
        case 200:
          getForumByUserResult = action.payload.result;
          isGetForumByUserLoading = false;
          isGetForumByUserLoaded = true;
          getForumByUserErrorMessage = action.payload.message;
          break;

        case 500:
          isGetForumByUserLoading = false;
          isGetForumByUserLoaded = true;
          getForumByUserErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getForumByUserResult,
        isGetForumByUserLoading,
        isGetForumByUserLoaded,
        getForumByUserStatusCode,
        getForumByUserErrorMessage
      };
    }
    case feedsListing.GET_FORUM_BY_USER_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isGetForumByUserLoading: false,
        isGetForumByUserLoaded: true,
      };
    }

    case feedsListing.REMOVE_FORUM: {
      return {
        ...state,
        removeForumResult: {},
        isRemoveForumLoading: true,
        isRemoveForumLoaded: false,
      };
    }
    case feedsListing.REMOVE_FORUM_SUCCESS: {
      let removeForumStatusCode = action.payload.code;
      let removeForumResult = state.removeForumResult;
      let isRemoveForumLoaded = state.isRemoveForumLoaded;
      let isRemoveForumLoading = state.isRemoveForumLoading;
      let removeForumErrorMessage = state.removeForumErrorMessage;
      switch (removeForumStatusCode) {
        case 200:
          removeForumResult = action.payload.result;
          isRemoveForumLoading = false;
          isRemoveForumLoaded = true;
          removeForumErrorMessage = action.payload.message;
          break;

        case 500:
          isRemoveForumLoading = false;
          isRemoveForumLoaded = true;
          removeForumErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        removeForumResult,
        isRemoveForumLoading,
        isRemoveForumLoaded,
        removeForumStatusCode,
        removeForumErrorMessage
      };
    }
    case feedsListing.REMOVE_FORUM_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isRemoveForumLoading: false,
        isRemoveForumLoaded: true,
      };
    }
    
    case feedsListing.REPORT_ABUSE: {
      return {
        ...state,
        reportAbuseResult: {},
        isReportAbuseLoading: true,
        isReportAbuseLoaded: false,
      };
    }
    case feedsListing.REPORT_ABUSE_SUCCESS: {
      let reportAbuseStatusCode = action.payload.code;
      let reportAbuseResult = state.reportAbuseResult;
      let isReportAbuseLoaded = state.isReportAbuseLoaded;
      let isReportAbuseLoading = state.isReportAbuseLoading;
      let reportAbuseErrorMessage = state.reportAbuseErrorMessage;
      switch (reportAbuseStatusCode) {
        case 200:
          reportAbuseResult = action.payload.result;
          isReportAbuseLoading = false;
          isReportAbuseLoaded = true;
          reportAbuseErrorMessage = action.payload.message;
          break;

        case 500:
          isReportAbuseLoading = false;
          isReportAbuseLoaded = true;
          reportAbuseErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        reportAbuseResult,
        isReportAbuseLoading,
        isReportAbuseLoaded,
        reportAbuseStatusCode,
        reportAbuseErrorMessage
      };
    }
    case feedsListing.REPORT_ABUSE_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isReportAbuseLoading: false,
        isReportAbuseLoaded: true,
      };
    }

    case feedsListing.REPORT_ABUSE_COMMENT: {
      return {
        ...state,
        reportAbuseCommentResult: {},
        isReportAbuseCommentLoading: true,
        isReportAbuseCommentLoaded: false,
      };
    }
    case feedsListing.REPORT_ABUSE_COMMENT_SUCCESS: {
      let reportAbuseCommentStatusCode = action.payload.code;
      let reportAbuseCommentResult = state.reportAbuseCommentResult;
      let isReportAbuseCommentLoaded = state.isReportAbuseCommentLoaded;
      let isReportAbuseCommentLoading = state.isReportAbuseCommentLoading;
      let reportAbuseCommentErrorMessage = state.reportAbuseCommentErrorMessage;
      switch (reportAbuseCommentStatusCode) {
        case 200:
          reportAbuseCommentResult = action.payload.result;
          isReportAbuseCommentLoading = false;
          isReportAbuseCommentLoaded = true;
          reportAbuseCommentErrorMessage = action.payload.message;
          break;

        case 500:
          isReportAbuseCommentLoading = false;
          isReportAbuseCommentLoaded = true;
          reportAbuseCommentErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        reportAbuseCommentResult,
        isReportAbuseCommentLoading,
        isReportAbuseCommentLoaded,
        reportAbuseCommentStatusCode,
        reportAbuseCommentErrorMessage
      };
    }
    case feedsListing.REPORT_ABUSE_COMMENT_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isReportAbuseCommentLoading: false,
        isReportAbuseCommentLoaded: true,
      };
    }
    






 
    case feedsListing.REPORT_WEBINAR_ABUSE: {
      return {
        ...state,
        reportWebinarAbuseResult: {},
        isReportWebinarAbuseLoading: true,
        isReportWebinarAbuseLoaded: false,
      };
    }
    case feedsListing.REPORT_WEBINAR_ABUSE_SUCCESS: {
      let reportWebinarAbuseStatusCode = action.payload.code;
      let reportWebinarAbuseResult = state.reportWebinarAbuseResult;
      let isReportWebinarAbuseLoaded = state.isReportWebinarAbuseLoaded;      
      let isReportWebinarAbuseLoading = state.isReportWebinarAbuseLoading;
      let reportWebinarAbuseErrorMessage = state.reportWebinarAbuseErrorMessage;
      switch (reportWebinarAbuseStatusCode) {
        case 200:
          reportWebinarAbuseResult = action.payload.result;
          isReportWebinarAbuseLoading = false;
          isReportWebinarAbuseLoaded = true;
          reportWebinarAbuseErrorMessage = action.payload.message;
          break;

        case 500:
          isReportWebinarAbuseLoading = false;
          isReportWebinarAbuseLoaded = true;
          reportWebinarAbuseErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        reportWebinarAbuseResult,
        isReportWebinarAbuseLoading,
        isReportWebinarAbuseLoaded,
        reportWebinarAbuseStatusCode,
        reportWebinarAbuseErrorMessage
      };
    }
    case feedsListing.REPORT_WEBINAR_ABUSE_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isReportWebinarAbuseLoading: false,
        isReportWebinarAbuseLoaded: true,
      };
    }

    case feedsListing.REPORT_WEBINAR_ABUSE_COMMENT: {
      return {
        ...state,
        reportWebinarAbuseCommentResult: {},
        isReportWebinarAbuseCommentLoading: true,
        isReportWebinarAbuseCommentLoaded: false,
      };
    }
    case feedsListing.REPORT_WEBINAR_ABUSE_COMMENT_SUCCESS: {
      let reportWebinarAbuseCommentStatusCode = action.payload.code;
      let reportWebinarAbuseCommentResult = state.reportWebinarAbuseCommentResult;
      let isReportWebinarAbuseCommentLoaded = state.isReportWebinarAbuseCommentLoaded;
      let isReportWebinarAbuseCommentLoading = state.isReportWebinarAbuseCommentLoading;
      let reportWebinarAbuseCommentErrorMessage = state.reportWebinarAbuseCommentErrorMessage;
      switch (reportWebinarAbuseCommentStatusCode) {
        case 200:
          reportWebinarAbuseCommentResult = action.payload.result;
          isReportWebinarAbuseCommentLoading = false;
          isReportWebinarAbuseCommentLoaded = true;
          reportWebinarAbuseCommentErrorMessage = action.payload.message;
          break;

        case 500:
          isReportWebinarAbuseCommentLoading = false;
          isReportWebinarAbuseCommentLoaded = true;
          reportWebinarAbuseCommentErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        reportWebinarAbuseCommentResult,
        isReportWebinarAbuseCommentLoading,
        isReportWebinarAbuseCommentLoaded,
        reportWebinarAbuseCommentStatusCode,
        reportWebinarAbuseCommentErrorMessage
      };
    }
    case feedsListing.REPORT_WEBINAR_ABUSE_COMMENT_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isReportWebinarAbuseCommentLoading: false,
        isReportWebinarAbuseCommentLoaded: true,
      };
    }

  
    case feedsListing.REGISTRATION_COMPETITION: {
      return {
        ...state,
        registrationCompetitionResult: {},
        isRegistrationCompetitionLoading: true,
        isRegistrationCompetitionLoaded: false,
      };
    }
    case feedsListing.REGISTRATION_COMPETITION_SUCCESS: {
      let registrationCompetitionStatusCode = action.payload.code;
      let registrationCompetitionResult = state.registrationCompetitionResult;
      let isRegistrationCompetitionLoaded = state.isRegistrationCompetitionLoaded;
      let isRegistrationCompetitionLoading = state.isRegistrationCompetitionLoading;
      let registrationCompetitionErrorMessage = state.registrationCompetitionErrorMessage;
      switch (registrationCompetitionStatusCode) {
        case 200:
          registrationCompetitionResult = action.payload.result;
          isRegistrationCompetitionLoading = false;
          isRegistrationCompetitionLoaded = true;
          registrationCompetitionErrorMessage = action.payload.message;
          break;

        case 500:
          isRegistrationCompetitionLoading = false;
          isRegistrationCompetitionLoaded = true;
          registrationCompetitionErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        registrationCompetitionResult,
        isRegistrationCompetitionLoading,
        isRegistrationCompetitionLoaded,
        registrationCompetitionStatusCode,
        registrationCompetitionErrorMessage
      };
    }
    case feedsListing.REGISTRATION_COMPETITION_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isRegistrationCompetitionLoading: false,
        isRegistrationCompetitionLoaded: true,
      };
    }

    case feedsListing.SUBMIT_COMPETITION: {
      return {
        ...state,
        submitCompetitionResult: {},
        isSubmitCompetitionLoading: true,
        isSubmitCompetitionLoaded: false,
      };
    }
    case feedsListing.SUBMIT_COMPETITION_SUCCESS: {
      let submitCompetitionStatusCode = action.payload.code;
      let submitCompetitionResult = state.submitCompetitionResult;
      let isSubmitCompetitionLoaded = state.isSubmitCompetitionLoaded;
      let isSubmitCompetitionLoading = state.isSubmitCompetitionLoading;
      let submitCompetitionErrorMessage = state.submitCompetitionErrorMessage;
      switch (submitCompetitionStatusCode) {
        case 200:
          submitCompetitionResult = action.payload.result;
          isSubmitCompetitionLoading = false;
          isSubmitCompetitionLoaded = true;
          submitCompetitionErrorMessage = action.payload.message;
          break;

        case 500:
          isSubmitCompetitionLoading = false;
          isSubmitCompetitionLoaded = true;
          submitCompetitionErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        submitCompetitionResult,
        isSubmitCompetitionLoading,
        isSubmitCompetitionLoaded,
        submitCompetitionStatusCode,
        submitCompetitionErrorMessage
      };
    }
    case feedsListing.SUBMIT_COMPETITION_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isSubmitCompetitionLoading: false,
        isSubmitCompetitionLoaded: true,
      };
    }
    case feedsListing.CHECK_TIMELINE: {
      let timelineId = state.timelineId;
      let isTimelineChnaged = state.isTimelineChnaged;
      if (timelineId == action.payload.timelineId) {
        isTimelineChnaged = false;
      }else{
        timelineId = action.payload.timelineId;
        isTimelineChnaged = true;
      }
      return {
        ...state,
        isTimelineChnaged,
        timelineId
      };
    }

    case feedsListing.GET_USER_NOTIFICATION: {
      return {
        ...state,
        getUserNotificationResult: {},
        isgetUserNotificationLoading: true,
        isgetUserNotificationLoaded: false,
      };
    }
    case feedsListing.GET_USER_NOTIFICATION_SUCCESS: {
      let getUserNotificationStatusCode = action.payload.code;
      let getUserNotificationResult = state.getUserNotificationResult;
      let isgetUserNotificationLoaded = state.isgetUserNotificationLoaded;
      let isgetUserNotificationLoading = state.isgetUserNotificationLoading;
      let getUserNotificationErrorMessage = state.getUserNotificationErrorMessage;

      switch (getUserNotificationStatusCode) {
        
        case 200:
          getUserNotificationResult = action.payload.result;
          isgetUserNotificationLoading = false;
          isgetUserNotificationLoaded = true;
          break;

        case 500:
          isgetUserNotificationLoading = false;
          isgetUserNotificationLoaded = true;
          getUserNotificationErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getUserNotificationResult,
        isgetUserNotificationLoading,
        isgetUserNotificationLoaded,
        getUserNotificationErrorMessage,
        getUserNotificationStatusCode
      };
    }
    case feedsListing.GET_USER_NOTIFICATION_FAIL: {
      let statusCode = action.payload.status
      let getUserNotificationErrorMessage = state.getUserNotificationErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        getUserNotificationErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        getUserNotificationErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        getUserNotificationErrorMessage,
        isgetUserNotificationLoading: false,
        isgetUserNotificationLoaded: true,
      };
    }


    case feedsListing.GET_BLOG_LISTING_BY_USER_ID: {
      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      return {
        ...state,
        isgetBlogListingByUserIdLoaded: false,
        isgetBlogListingByUserIdLoading: true,
        filteredLabels
      };
    }
    case feedsListing.GET_BLOG_LISTING_BY_USER_ID_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isgetBlogListingByUserIdLoaded: true,
        isgetBlogListingByUserIdLoading: false
      };
    }
    case feedsListing.RESET_BLOG:{
			return{
				...state,
				getBlogListingByUserIdResult:[],
				getBlogListingByUserIdStatusCode: 0,
				blogsPaginationCount: 0,
				getBlogListingByUserIdErrorMessage: null,
				isgetBlogListingByUserIdLoading: false,
				isgetBlogListingByUserIdLoaded: false
			}
    }
    case feedsListing.RESET_FORUM:{
			return{
				...state,
				getForumListingByUserIdResult:[],
				getForumListingByUserIdStatusCode: 0,
				forumsPaginationCount: 0,
				getForumListingByUserIdErrorMessage: null,
				isgetForumListingByUserIdLoading: false,
				isgetForumListingByUserIdLoaded: false
			}
		}
    case feedsListing.GET_BLOG_LISTING_BY_USER_ID_SUCCESS: {
      let getBlogListingByUserIdStatusCode = action.payload.code;
      let getBlogListingByUserIdResult = state.getBlogListingByUserIdResult;
      let isgetBlogListingByUserIdLoaded = state.isgetBlogListingByUserIdLoaded;
      let isgetBlogListingByUserIdLoading = state.isgetBlogListingByUserIdLoading;
      let getBlogListingByUserIdErrorMessage = state.errorMessage;
      let blogsPaginationCount = state.blogsPaginationCount;
      let data;
      // let filteredLabels = state.filteredLabels;
      // filteredLabels = action.payload.filteredLabels;
      switch (getBlogListingByUserIdStatusCode) {
        case 200:
          if (blogsPaginationCount == 0) {
            getBlogListingByUserIdResult = action.payload.result;
            getBlogListingByUserIdStatusCode = action.payload.code;
          } else {
            data = getBlogListingByUserIdResult;
            getBlogListingByUserIdResult = [...data, ...action.payload.result];
          }
          isgetBlogListingByUserIdLoading = false;
          isgetBlogListingByUserIdLoaded = true;
          break;

        case 500:
          getBlogListingByUserIdStatusCode = action.payload.code;
          isgetBlogListingByUserIdLoading = false;
          isgetBlogListingByUserIdLoaded = true;
          // getBlogListingByUserIdResult = [];
          getBlogListingByUserIdErrorMessage = action.payload.message;
          break;

        case 604:
          getBlogListingByUserIdStatusCode = action.payload.code;
          isgetBlogListingByUserIdLoading = false;
          isgetBlogListingByUserIdLoaded = true;
          getBlogListingByUserIdErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getBlogListingByUserIdResult,
        getBlogListingByUserIdStatusCode,
        isgetBlogListingByUserIdLoaded,
        isgetBlogListingByUserIdLoading,
        getBlogListingByUserIdErrorMessage,
        // filteredLabels
      };
    }

    case feedsListing.GET_FORUM_LISTING_BY_USER_ID: {
      let filteredLabels = state.filteredLabels;
      filteredLabels = action.payload.filteredLabels;
      return {
        ...state,
        isgetForumListingByUserIdLoaded: false,
        isgetForumListingByUserIdLoading: true,
        filteredLabels
      };
    }
    case feedsListing.GET_FORUM_LISTING_BY_USER_ID_FAIL: {
      let statusCode = action.payload.status;
      let serverErrorMessage = state.serverErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isgetForumListingByUserIdLoaded: true,
        isgetForumListingByUserIdLoading: false
      };
    }
    case feedsListing.GET_FORUM_LISTING_BY_USER_ID_SUCCESS: {
      let getForumListingByUserIdStatusCode = action.payload.code;
      let getForumListingByUserIdResult = state.getForumListingByUserIdResult;
      let isgetForumListingByUserIdLoaded = state.isgetForumListingByUserIdLoaded;
      let isgetForumListingByUserIdLoading = state.isgetForumListingByUserIdLoading;
      let getForumListingByUserIdErrorMessage = state.errorMessage;
      let forumsPaginationCount = state.forumsPaginationCount;
      let data;
      // let filteredLabels = state.filteredLabels;
      // filteredLabels = action.payload.filteredLabels;
      switch (getForumListingByUserIdStatusCode) {
        case 200:
          if (forumsPaginationCount == 0) {
            getForumListingByUserIdResult = action.payload.result;
            getForumListingByUserIdStatusCode = action.payload.code;
          } else {
            data = getForumListingByUserIdResult;
            getForumListingByUserIdResult = [...data, ...action.payload.result];
          }
          isgetForumListingByUserIdLoading = false;
          isgetForumListingByUserIdLoaded = true;
          break;

        case 500:
          getForumListingByUserIdStatusCode = action.payload.code;
          isgetForumListingByUserIdLoading = false;
          isgetForumListingByUserIdLoaded = true;
          // getForumListingByUserIdResult = [];
          getForumListingByUserIdErrorMessage = action.payload.message;
          break;

        case 604:
          getForumListingByUserIdStatusCode = action.payload.code;
          isgetForumListingByUserIdLoading = false;
          isgetForumListingByUserIdLoaded = true;
          getForumListingByUserIdErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getForumListingByUserIdResult,
        getForumListingByUserIdStatusCode,
        isgetForumListingByUserIdLoaded,
        isgetForumListingByUserIdLoading,
        getForumListingByUserIdErrorMessage,
        // filteredLabels
      };
    }
   
    case feedsListing.GET_BLOG_BY_BLOG_ID: {
      return {
        ...state,
        getBlogByBlogIdResult: {},
        isgetBlogByBlogIdLoading: true,
        isgetBlogByBlogIdLoaded: false,
      };
    }
    case feedsListing.GET_BLOG_BY_BLOG_ID_SUCCESS: {
      let getBlogByBlogIdStatusCode = action.payload.code;
      let getBlogByBlogIdResult = state.getBlogByBlogIdResult;
      let isgetBlogByBlogIdLoaded = state.isgetBlogByBlogIdLoaded;
      let isgetBlogByBlogIdLoading = state.isgetBlogByBlogIdLoading;
      let getBlogByBlogIdErrorMessage = state.errorMessage;
      let updateBlogPhotoResponse = state.updateBlogPhotoResponse;
      let updateBlogPhotoFavResponse = state.updateBlogPhotoFavResponse;
      switch (getBlogByBlogIdStatusCode) {
        case 200:
          getBlogByBlogIdResult = action.payload.result;
          updateBlogPhotoResponse = getBlogByBlogIdResult['image'];
          updateBlogPhotoFavResponse = getBlogByBlogIdResult['imageFavourite']
          isgetBlogByBlogIdLoading = false;
          isgetBlogByBlogIdLoaded = true;
          break;

        case 500:
          isgetBlogByBlogIdLoading = false;
          isgetBlogByBlogIdLoaded = true;
          getBlogByBlogIdErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getBlogByBlogIdResult,
        updateBlogPhotoResponse,
        getBlogByBlogIdErrorMessage,
        isgetBlogByBlogIdLoading,
        isgetBlogByBlogIdLoaded,
        getBlogByBlogIdStatusCode
      };
    }
    case feedsListing.GET_BLOG_BY_BLOG_ID_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isgetBlogByBlogIdLoading: false,
        isgetBlogByBlogIdLoaded: true,
      };
    }

    case feedsListing.EDIT_BLOG: {
      return {
        ...state,
        editBlogResult: {},
        iseditBlogLoading: true,
        iseditBlogLoaded: false,
      };
    }
    case feedsListing.EDIT_BLOG_SUCCESS: {
      let editBlogStatusCode = action.payload.code;
      let editBlogResult = state.editBlogResult;
      let iseditBlogLoaded = state.iseditBlogLoaded;
      let iseditBlogLoading = state.iseditBlogLoading;
      let editBlogErrorMessage = state.errorMessage;
      switch (editBlogStatusCode) {
        case 200:
          editBlogResult = action.payload.result;
          iseditBlogLoading = false;
          iseditBlogLoaded = true;
          editBlogErrorMessage = action.payload.message;
          break;

          case 602:
            iseditBlogLoading = false;
            iseditBlogLoaded = true;
            editBlogErrorMessage = action.payload.message;
            break;
  
          case 950:
            iseditBlogLoading = false;
            iseditBlogLoaded = true;
            editBlogErrorMessage = action.payload.message;
            break;
      }

      return {
        ...state,
        editBlogResult,
        editBlogErrorMessage,
        iseditBlogLoading,
        iseditBlogLoaded,
        editBlogStatusCode
      };
    }
    case feedsListing.EDIT_BLOG_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        iseditBlogLoading: false,
        iseditBlogLoaded: true,
      };
    }

    case feedsListing.GET_FORUM_BY_FORUM_ID: {
      return {
        ...state,
        getForumByForumIdResult: {},
        isgetForumByForumIdLoading: true,
        isgetForumByForumIdLoaded: false,
      };
    }
    case feedsListing.GET_FORUM_BY_FORUM_ID_SUCCESS: {
      let getForumByForumIdStatusCode = action.payload.code;
      let getForumByForumIdResult = state.getForumByForumIdResult;
      let isgetForumByForumIdLoaded = state.isgetForumByForumIdLoaded;
      let isgetForumByForumIdLoading = state.isgetForumByForumIdLoading;
      let getForumByForumIdErrorMessage = state.errorMessage;
      switch (getForumByForumIdStatusCode) {
        case 200:
          getForumByForumIdResult = action.payload.result;
          isgetForumByForumIdLoading = false;
          isgetForumByForumIdLoaded = true;
          break;

        case 500:
          isgetForumByForumIdLoading = false;
          isgetForumByForumIdLoaded = true;
          getForumByForumIdErrorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        getForumByForumIdResult,
        getForumByForumIdErrorMessage,
        isgetForumByForumIdLoading,
        isgetForumByForumIdLoaded,
        getForumByForumIdStatusCode
      };
    }
    case feedsListing.GET_FORUM_BY_FORUM_ID_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        isgetForumByForumIdLoading: false,
        isgetForumByForumIdLoaded: true,
      };
    }

    case feedsListing.EDIT_FORUM: {
      return {
        ...state,
        editForumResult: {},
        iseditForumLoading: true,
        iseditForumLoaded: false,
      };
    }
    case feedsListing.EDIT_FORUM_SUCCESS: {
      let editForumStatusCode = action.payload.code;
      let editForumResult = state.editForumResult;
      let iseditForumLoaded = state.iseditForumLoaded;
      let iseditForumLoading = state.iseditForumLoading;
      let editForumErrorMessage = state.errorMessage;
      switch (editForumStatusCode) {
        case 200:
          editForumResult = action.payload.result;
          iseditForumLoading = false;
          iseditForumLoaded = true;
          editForumErrorMessage = action.payload.message;
          break;

          case 602:
            iseditForumLoading = false;
            iseditForumLoaded = true;
            editForumErrorMessage = action.payload.message;
            break;
  
          case 950:
            iseditForumLoading = false;
            iseditForumLoaded = true;
            editForumErrorMessage = action.payload.message;
            break;
      }

      return {
        ...state,
        editForumResult,
        editForumErrorMessage,
        iseditForumLoading,
        iseditForumLoaded,
        editForumStatusCode
      };
    }
    case feedsListing.EDIT_FORUM_FAIL: {
      let statusCode = action.payload.status
      let serverErrorMessage = state.serverErrorMessage
      if (statusCode == 401 || statusCode == 0) {
        serverErrorMessage = action.payload.statusText;
      }
      else if (statusCode != 401 && statusCode != 0) {
        serverErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        serverErrorMessage,
        iseditForumLoading: false,
        iseditForumLoaded: true,
      };
    }

    case feedsListing.UPDATE_BLOG_PHOTO: {
			let optionForFileUpload = action.payload.option;
			return {
				...state,
        optionForFileUpload,
				// uploadContentType,
				isUpdateBlogPhotoLoading: true,
				isUpdateBlogPhotoLoaded: false
			};
		}
		case feedsListing.UPDATE_BLOG_PHOTO_SUCCESS: {
      let statusCode = action.payload.code;
      let getBlogByBlogIdResult = state.getBlogByBlogIdResult;
			let updateBlogPhotoResponse = state.updateBlogPhotoResponse;
			let isUpdateBlogPhotoLoading = state.isUpdateBlogPhotoLoading;
      let isUpdateBlogPhotoLoaded = state.isUpdateBlogPhotoLoaded;
		  let updateBlogPhotoStatusCode = 0;
			switch (statusCode) {
				case 200:
          // if(modeOfBlog == true){
            updateBlogPhotoResponse = action.payload.result;
            isUpdateBlogPhotoLoaded = true;
            isUpdateBlogPhotoLoading = false;
          // }
          // else{
            // getBlogByBlogIdResult['image'] = action.payload.result;
          // }
						updateBlogPhotoStatusCode = action.payload.code;
						break;
			}
		
			return {
				...state,
				updateBlogPhotoResponse,
        updateBlogPhotoStatusCode,
        getBlogByBlogIdResult,
				isUpdateBlogPhotoLoading,
				isUpdateBlogPhotoLoaded,
			};
		}
		case feedsListing.UPDATE_BLOG_PHOTO_FAIL: {

			const statusCode = action.payload.status;
			let serverErrorMessage = state.serverErrorMessage;
			if (statusCode === 401 || statusCode === 0) {
				serverErrorMessage = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				serverErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				serverErrorMessage
			};
    }
    
    case feedsListing.UPDATE_BLOG_PHOTO_FAV: {
			return {
        ...state,
				// uploadContentType,
				isUpdateBlogPhotoFavLoading: true,
				isUpdateBlogPhotoFavLoaded: false
			};
		}
		case feedsListing.UPDATE_BLOG_PHOTO_FAV_SUCCESS: {
      let statusCode = action.payload.code;
			let updateBlogPhotoFavResponse = state.updateBlogPhotoFavResponse;
			let isUpdateBlogPhotoFavLoading = state.isUpdateBlogPhotoFavLoading;
      let isUpdateBlogPhotoFavLoaded = state.isUpdateBlogPhotoFavLoaded;
		  let updateBlogPhotoFavStatusCode = 0;
			switch (statusCode) {
				case 200:
            updateBlogPhotoFavResponse = action.payload.result;
            updateBlogPhotoFavStatusCode = action.payload.code;
            isUpdateBlogPhotoFavLoaded = true;
            isUpdateBlogPhotoFavLoading = false;
						break;
			}
		
			return {
				...state,
				updateBlogPhotoFavResponse,
        updateBlogPhotoFavStatusCode,
				isUpdateBlogPhotoFavLoading,
				isUpdateBlogPhotoFavLoaded,
			};
		}
		case feedsListing.UPDATE_BLOG_PHOTO_FAV_FAIL: {

			const statusCode = action.payload.status;
			let serverErrorMessage = state.serverErrorMessage;
			if (statusCode === 401 || statusCode === 0) {
				serverErrorMessage = action.payload.statusText;
			} else if (statusCode !== 401 && statusCode !== 0) {
				serverErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				serverErrorMessage
			};
		}
    case feedsListing.SET_PAGINATION_COUNT_BLOGS: {
			let blogsPaginationCount = action.payload.blogsPaginationCount;
			let getBlogListingByUserIdResult = state.getBlogListingByUserIdResult;
			if (blogsPaginationCount == 0) {
				getBlogListingByUserIdResult = []
			}
			return {
			  ...state,
			  blogsPaginationCount,
			  getBlogListingByUserIdResult
			};
    }
    
    case feedsListing.SET_PAGINATION_COUNT_FORUMS: {
			let forumsPaginationCount = action.payload.forumsPaginationCount;
			let getForumListingByUserIdResult = state.getForumListingByUserIdResult;
			if (forumsPaginationCount == 0) {
				getForumListingByUserIdResult = []
			}
			return {
			  ...state,
			  forumsPaginationCount,
			  getForumListingByUserIdResult
			};
		}

  }

  return state;
}

export const getFeedsListingResult = (state: FeedsState) => state.feedsListingResult;
export const getIsFeedsListLoading = (state: FeedsState) => state.isFeedsListingLoading;
export const getIsFeedsListLoaded = (state: FeedsState) => state.isFeedsListingLoaded;
export const getTimeLineListingStatusCode = (state: FeedsState) => state.timeLineListingStatusCode;
export const getTimelineListingErrorMessage = (state: FeedsState) => state.feedListingErrorMessage;
export const getisFiltterAppled = (state: FeedsState) => state.isFiltterAppled;

export const getFeedsListingEmergencyResult = (state: FeedsState) => state.feedsListingEmergencyResult;
export const getIsFeedsListEmergencyLoading = (state: FeedsState) => state.isFeedsListingEmergencyLoading;
export const getIsFeedsListEmergencyLoaded = (state: FeedsState) => state.isFeedsListingEmergencyLoaded;
export const getTimeLineListingEmergencyStatusCode = (state: FeedsState) => state.timeLineListingEmergencyStatusCode;
export const getTimelineListingEmergencyErrorMessage = (state: FeedsState) => state.feedListingEmergencyErrorMessage;
export const emergencyPaginationCount = (state: FeedsState) => state.emergencyPaginationCount;

export const getFeedsDetailResult = (state: FeedsState) => state.feedsDetailResult;
export const isDetailsScreenLoading = (state: FeedsState) => state.isDetailsScreenLoading;
export const isDetailsScreenLoaded = (state: FeedsState) => state.isDetailsScreenLoaded;
export const getFeedsListingErrorMessage = (state: FeedsState) => state.errorMessage;
export const getFeedsPaginationCount = (state: FeedsState) => state.feedsPaginationCount;
export const feedListingStatusCode = (state: FeedsState) => state.feedListingStatusCode;
export const getFeedsServerErrorMessage = (state: FeedsState) => state.serverErrorMessage;

export const getFilteredLabels = (state: FeedsState) => state.filteredLabels;

export const addOrRemoveBlogCommentResult = (state: FeedsState) => state.addOrRemoveBlogCommentResult;
export const addOrRemoveBlogCommentStatusCode = (state: FeedsState) => state.addOrRemoveBlogCommentStatusCode;
export const addOrRemoveBlogCommentErrorMessage = (state: FeedsState) => state.addOrRemoveBlogCommentErrorMessage;
export const isAddOrRemoveBlogCommentLoading = (state: FeedsState) => state.isaddOrRemoveBlogCommentLoading;
export const isAddOrRemoveBlogCommentLoaded = (state: FeedsState) => state.isaddOrRemoveBlogCommentLoaded;
 
export const getAllBlogCommentResult = (state: FeedsState) => state.getAllBlogCommentResult;
export const getAllBlogCommentStatusCode = (state: FeedsState) => state.getAllBlogCommentStatusCode;
export const getAllBlogCommentErrorMessage = (state: FeedsState) => state.getAllBlogCommentErrorMessage;
export const isGetAllBlogCommentLoading = (state: FeedsState) => state.isGetAllBlogCommentLoading;
export const isGetAllBlogCommentLoaded = (state: FeedsState) => state.isGetAllBlogCommentLoaded;

export const getFeedsEmergencyResult = (state: FeedsState) => state.getFeedsEmergencyResult;
export const getFeedsEmergencyStatusCode = (state: FeedsState) => state.getFeedsEmergencyStatusCode;
export const getFeedsEmergencyErrorMessage = (state: FeedsState) => state.getFeedsEmergencyErrorMessage;
export const isGetFeedsEmergencyLoading = (state: FeedsState) => state.isGetFeedsEmergencyLoading;
export const isGetFeedsEmergencyLoaded = (state: FeedsState) => state.isGetFeedsEmergencyLoaded;
export const addBlogResult = (state: FeedsState) => state.addBlogResult;
export const addBlogStatusCode = (state: FeedsState) => state.addBlogStatusCode;
export const addBlogErrorMessage = (state: FeedsState) => state.addBlogErrorMessage;
export const isAddBlogLoading = (state: FeedsState) => state.isAddBlogLoading;
export const isAddBlogLoaded = (state: FeedsState) => state.isAddBlogLoaded;

export const addForumResult = (state: FeedsState) => state.addForumResult;
export const addForumStatusCode = (state: FeedsState) => state.addForumStatusCode;
export const addForumErrorMessage = (state: FeedsState) => state.addForumErrorMessage;
export const isAddForumLoading = (state: FeedsState) => state.isAddForumLoading;
export const isAddForumLoaded = (state: FeedsState) => state.isAddForumLoaded;

export const updateForumResult = (state: FeedsState) => state.updateForumResult;
export const updateForumStatusCode = (state: FeedsState) => state.updateForumStatusCode;
export const updateForumErrorMessage = (state: FeedsState) => state.updateForumErrorMessage;
export const isUpdateForumLoading = (state: FeedsState) => state.isUpdateForumLoading;
export const isUpdateForumLoaded = (state: FeedsState) => state.isUpdateForumLoaded;

export const removeForumResult = (state: FeedsState) => state.removeForumResult;
export const removeForumStatusCode = (state: FeedsState) => state.removeForumStatusCode;
export const removeForumErrorMessage = (state: FeedsState) => state.removeForumErrorMessage;
export const isRemoveForumLoading = (state: FeedsState) => state.isRemoveForumLoading;
export const isRemoveForumLoaded = (state: FeedsState) => state.isRemoveForumLoaded;

export const getForumByUserResult = (state: FeedsState) => state.getForumByUserResult;
export const getForumByUserStatusCode = (state: FeedsState) => state.getForumByUserStatusCode;
export const getForumByUserErrorMessage = (state: FeedsState) => state.getForumByUserErrorMessage;
export const isGetForumByUserLoading = (state: FeedsState) => state.isGetForumByUserLoading;
export const isGetForumByUserLoaded = (state: FeedsState) => state.isGetForumByUserLoaded;

export const reportAbuseResult = (state: FeedsState) => state.reportAbuseResult;
export const reportAbuseStatusCode = (state: FeedsState) => state.reportAbuseStatusCode;
export const reportAbuseErrorMessage = (state: FeedsState) => state.reportAbuseErrorMessage;
export const isReportAbuseLoading = (state: FeedsState) => state.isReportAbuseLoading;
export const isReportAbuseLoaded = (state: FeedsState) => state.isReportAbuseLoaded;

export const reportAbuseCommentResult = (state: FeedsState) => state.reportAbuseCommentResult;
export const reportAbuseCommentStatusCode = (state: FeedsState) => state.reportAbuseCommentStatusCode;
export const reportAbuseCommentErrorMessage = (state: FeedsState) => state.reportAbuseCommentErrorMessage;
export const isReportAbuseCommentLoading = (state: FeedsState) => state.isReportAbuseCommentLoading;
export const isReportAbuseCommentLoaded = (state: FeedsState) => state.isReportAbuseCommentLoaded;

export const reportWebinarAbuseResult = (state: FeedsState) => state.reportWebinarAbuseResult;
export const reportWebinarAbuseStatusCode = (state: FeedsState) => state.reportWebinarAbuseStatusCode;
export const reportWebinarAbuseErrorMessage = (state: FeedsState) => state.reportWebinarAbuseErrorMessage;
export const isReportWebinarAbuseLoading = (state: FeedsState) => state.isReportWebinarAbuseLoading;
export const isReportWebinarAbuseLoaded = (state: FeedsState) => state.isReportWebinarAbuseLoaded;

export const reportWebinarAbuseCommentResult = (state: FeedsState) => state.reportWebinarAbuseCommentResult;
export const reportWebinarAbuseCommentStatusCode = (state: FeedsState) => state.reportWebinarAbuseCommentStatusCode;
export const reportWebinarAbuseCommentErrorMessage = (state: FeedsState) => state.reportWebinarAbuseCommentErrorMessage;
export const isReportWebinarAbuseCommentLoading = (state: FeedsState) => state.isReportWebinarAbuseCommentLoading;
export const isReportWebinarAbuseCommentLoaded = (state: FeedsState) => state.isReportWebinarAbuseCommentLoaded;

export const registrationCompetitionResult = (state: FeedsState) => state.registrationCompetitionResult;
export const registrationCompetitionStatusCode = (state: FeedsState) => state.registrationCompetitionStatusCode;
export const registrationCompetitionErrorMessage = (state: FeedsState) => state.registrationCompetitionErrorMessage;
export const isRegistrationCompetitionLoading = (state: FeedsState) => state.isRegistrationCompetitionLoading;
export const isRegistrationCompetitionLoaded = (state: FeedsState) => state.isRegistrationCompetitionLoaded;

export const submitCompetitionResult = (state: FeedsState) => state.submitCompetitionResult;
export const submitCompetitionStatusCode = (state: FeedsState) => state.submitCompetitionStatusCode;
export const submitCompetitionErrorMessage = (state: FeedsState) => state.submitCompetitionErrorMessage;
export const isSubmitCompetitionLoading = (state: FeedsState) => state.isSubmitCompetitionLoading;
export const isSubmitCompetitionLoaded = (state: FeedsState) => state.isSubmitCompetitionLoaded;
export const isTimelineChnaged = (state: FeedsState) => state.timelineId;

export const getUserNotificationResult = (state: FeedsState) => state.getUserNotificationResult;
export const getUserNotificationStatusCode = (state: FeedsState) => state.getUserNotificationStatusCode;
export const getUserNotificationErrorMessage = (state: FeedsState) => state.getUserNotificationErrorMessage;
export const isgetUserNotificationLoading = (state: FeedsState) => state.isgetUserNotificationLoading;
export const isgetUserNotificationLoaded = (state: FeedsState) => state.isgetUserNotificationLoaded;

export const getBlogListingByUserIdResult = (state: FeedsState) => state.getBlogListingByUserIdResult;
export const getBlogListingByUserIdStatusCode = (state: FeedsState) => state.getBlogListingByUserIdStatusCode;
export const getBlogListingByUserIdErrorMessage = (state: FeedsState) => state.getBlogListingByUserIdErrorMessage;
export const isgetBlogListingByUserIdLoading = (state: FeedsState) => state.isgetBlogListingByUserIdLoading;
export const isgetBlogListingByUserIdLoaded = (state: FeedsState) => state.isgetBlogListingByUserIdLoaded;

export const getForumListingByUserIdResult = (state: FeedsState) => state.getForumListingByUserIdResult;
export const getForumListingByUserIdStatusCode = (state: FeedsState) => state.getForumListingByUserIdStatusCode;
export const getForumListingByUserIdErrorMessage = (state: FeedsState) => state.getForumListingByUserIdErrorMessage;
export const isgetForumListingByUserIdLoading = (state: FeedsState) => state.isgetForumListingByUserIdLoading;
export const isgetForumListingByUserIdLoaded = (state: FeedsState) => state.isgetForumListingByUserIdLoaded;

export const getBlogByBlogIdResult = (state: FeedsState) => state.getBlogByBlogIdResult;
export const getBlogByBlogIdStatusCode = (state: FeedsState) => state.getBlogByBlogIdStatusCode;
export const getBlogByBlogIdErrorMessage = (state: FeedsState) => state.getBlogByBlogIdErrorMessage;
export const isgetBlogByBlogIdLoading = (state: FeedsState) => state.isgetBlogByBlogIdLoading;
export const isgetBlogByBlogIdLoaded = (state: FeedsState) => state.isgetBlogByBlogIdLoaded;

export const editBlogResult = (state: FeedsState) => state.editBlogResult;
export const editBlogStatusCode = (state: FeedsState) => state.editBlogStatusCode;
export const editBlogErrorMessage = (state: FeedsState) => state.editBlogErrorMessage;
export const iseditBlogLoading = (state: FeedsState) => state.iseditBlogLoading;
export const iseditBlogLoaded = (state: FeedsState) => state.iseditBlogLoaded;

export const getForumByForumIdResult = (state: FeedsState) => state.getForumByForumIdResult;
export const getForumByForumIdStatusCode = (state: FeedsState) => state.getForumByForumIdStatusCode;
export const getForumByForumIdErrorMessage = (state: FeedsState) => state.getForumByForumIdErrorMessage;
export const isgetForumByForumIdLoading = (state: FeedsState) => state.isgetForumByForumIdLoading;
export const isgetForumByForumIdLoaded = (state: FeedsState) => state.isgetForumByForumIdLoaded;

export const editForumResult = (state: FeedsState) => state.editForumResult;
export const editForumStatusCode = (state: FeedsState) => state.editForumStatusCode;
export const editForumErrorMessage = (state: FeedsState) => state.editForumErrorMessage;
export const iseditForumLoading = (state: FeedsState) => state.iseditForumLoading;
export const iseditForumLoaded = (state: FeedsState) => state.iseditForumLoaded;

export const updateBlogPhotoResponse = (state: FeedsState) => state.updateBlogPhotoResponse;
export const updateBlogPhotoStatusCode = (state: FeedsState) => state.updateBlogPhotoStatusCode;
export const isUpdateBlogPhotoLoading = (state: FeedsState) => state.isUpdateBlogPhotoLoading;
export const isUpdateBlogPhotoLoaded = (state: FeedsState) => state.isUpdateBlogPhotoLoaded;

export const updateBlogPhotoFavResponse = (state: FeedsState) => state.updateBlogPhotoFavResponse;
export const updateBlogPhotoFavStatusCode = (state: FeedsState) => state.updateBlogPhotoFavStatusCode;
export const isUpdateBlogPhotoFavLoading = (state: FeedsState) => state.isUpdateBlogPhotoFavLoading;
export const isUpdateBlogPhotoFavLoaded = (state: FeedsState) => state.isUpdateBlogPhotoFavLoaded;

export const blogsPaginationCount = (state: FeedsState) => state.blogsPaginationCount;
export const forumsPaginationCount = (state: FeedsState) => state.forumsPaginationCount;