import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromAboutUs from '../../store/reducer/about-us.reducer';

export const getAboutUsState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.aboutUs
);

export const getAboutUsResult = createSelector(
	getAboutUsState,
	fromAboutUs.getAboutUsResult
);
export const getIsAboutUsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsAboutUsLoaded
);
export const getIsAboutUsLoading = createSelector(
	getAboutUsState,
	fromAboutUs.getIsAboutUsLoading
);
export const getAboutUsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getAboutUsServerErrorMessage
);


export const getCommissionerDetailsResult  = createSelector(
	getAboutUsState,
	fromAboutUs.getCommissionerDetailsResult 
);
export const getIsCommissionerDetailsLoading= createSelector(
	getAboutUsState,
	fromAboutUs.getIsCommissionerDetailsLoading
);
export const getIsCommissionerDetailsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsCommissionerDetailsLoaded
);
export const getCommissionerDetailsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getCommissionerDetailsServerErrorMessage
);

export const getCityDetailsResult  = createSelector(
	getAboutUsState,
	fromAboutUs.getCityDetailsResult 
);
export const getIsCityDetailsLoading = createSelector(
	getAboutUsState,
	fromAboutUs.getIsCityDetailsLoading
);
export const getIsCityDetailsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsCityDetailsLoaded
);
export const getCityDetailsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getCityDetailsServerErrorMessage
);

export const getTourismDetailsResult = createSelector(
	getAboutUsState,
	fromAboutUs.getTourismDetailsResult
);
export const getIsTourismDetailsLoading = createSelector(
	getAboutUsState,
	fromAboutUs.getIsTourismDetailsLoading
);
export const getIsTourismDetailsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsTourismDetailsLoaded
);
export const getTourismDetailsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getTourismDetailsServerErrorMessage
);

export const getCorporatesDetailsResult = createSelector(
	getAboutUsState,
	fromAboutUs.getCorporatesDetailsResult
);
export const getIsCorporatesDetailsLoading = createSelector(
	getAboutUsState,
	fromAboutUs.getIsCorporatesDetailsLoading
);
export const getIsCorporatesDetailsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsCorporatesDetailsLoaded
);
export const getCorporatesDetailsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getCorporatesDetailsServerErrorMessage
);

export const getZoneDetailsResult = createSelector(
	getAboutUsState,
	fromAboutUs.getZoneDetailsResult
);
export const getIsZoneDetailsLoading = createSelector(
	getAboutUsState,
	fromAboutUs.getIsZoneDetailsLoading
);
export const getIsZoneDetailsLoaded = createSelector(
	getAboutUsState,
	fromAboutUs.getIsZoneDetailsLoaded
);
export const getZoneDetailsServerErrorMessage = createSelector(
	getAboutUsState,
	fromAboutUs.getZoneDetailsServerErrorMessage
);




