import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromFavorite from '../../store/reducer/favorite.reducer';

export const getFavoriteState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.favorite
);

export const getFavoriteResult = createSelector(
	getFavoriteState,
	fromFavorite.getFavoriteResult
);
export const getIsFavoriteLoaded = createSelector(
	getFavoriteState,
	fromFavorite.getIsFavoriteLoaded
);
export const getIsFavoriteLoading = createSelector(
	getFavoriteState,
	fromFavorite.getIsFavoriteLoading
);

export const getFavoriteErrMessage = createSelector(
	getFavoriteState,
	fromFavorite.getErrMessage
);

export const getFavoriteMessage = createSelector(
	getFavoriteState,
	fromFavorite.getFavoriteMessage
);
export const getFavouriteStatusCode = createSelector(
	getFavoriteState,
	fromFavorite.getStatusCode
);
export const getServerErrorMessage = createSelector(
	getFavoriteState,
	fromFavorite.getServerErrorMsg
)


export const fevoriteWebinarErrorMessage = createSelector(
	getFavoriteState,
	fromFavorite.fevoriteWebinarErrorMessage
);
export const fevoriteWebinarListStatusCode = createSelector(
	getFavoriteState,
	fromFavorite.fevoriteWebinarListStatusCode
);
export const fevoriteWebinarResult = createSelector(
	getFavoriteState,
	fromFavorite.fevoriteWebinarResult
)