import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
  
export class LoginComponent implements OnInit, OnChanges {
  url: string;
  state: string = 'default';
  constructor(
    private router: Router
  ) {


  }

  ngOnInit() {

  }
  ngOnChanges() {
    this.url = this.router.url;
  }
}