import { Action } from '@ngrx/store';

export const GET_WEBINAR_LIST = 'GET_WEBINAR_LIST';
export const GET_WEBINAR_LIST_SUCCESS = 'GET_WEBINAR_LIST_SUCCESS';
export const GET_WEBINAR_LIST_FAIL = 'GET_WEBINAR_LIST_FAIL';
export const GET_WEBINAR_BY_ID = 'GET_WEBINAR_BY_ID';
export const GET_WEBINAR_BY_ID_SUCCESS = 'GET_WEBINAR_BY_ID_SUCCESS';
export const GET_WEBINAR_BY_ID_FAIL = 'GET_WEBINAR_BY_ID_FAIL';
export const SET_PAGINATION_COUNT_WEBINAR = 'SET_PAGINATION_COUNT_WEBINAR';

export const ADD_OR_REMOVE_WEBINAR_COMMENT = 'ADD_OR_REMOVE_WEBINAR_COMMENT';
export const ADD_OR_REMOVE_WEBINAR_COMMENT_SUCCESS = 'ADD_OR_REMOVE_WEBINAR_COMMENT_SUCCESS';
export const ADD_OR_REMOVE_WEBINAR_COMMENT_FAIL = 'ADD_OR_REMOVE_WEBINAR_COMMENT_FAIL';

export const REMOVE_WEBINAR_COMMENT = 'REMOVE_WEBINAR_COMMENT';
export const REMOVE_WEBINAR_COMMENT_SUCCESS = 'REMOVE_WEBINAR_COMMENT_SUCCESS';
export const REMOVE_WEBINAR_COMMENT_FAIL = 'REMOVE_WEBINAR_COMMENT_FAIL';

export const GET_ALL_WEBINAR_COMMENTS = 'GET_ALL_WEBINAR_COMMENTS';
export const GET_ALL_WEIBNAR_COMMENTS_SUCCESS = 'GET_ALL_WEIBNAR_COMMENTS_SUCCESS';
export const GET_ALL_WEBINAR_COMMENTS_FAIL = 'GET_ALL_WEBINAR_COMMENTS_FAIL';

export const REPORT_ABUSE_WEBINAR_COMMENT = 'REPORT_ABUSE_WEBINAR_COMMENT';
export const REPORT_ABUSE_WEBINAR_COMMENT_SUCCESS = 'REPORT_ABUSE_WEBINAR_COMMENT_SUCCESS';
export const REPORT_ABUSE_WEBINAR_COMMENT_FAIL = 'REPORT_ABUSE_WEBINAR_COMMENT_FAIL';


export class GetWebminarList implements Action {
	readonly type = GET_WEBINAR_LIST;
	/**
	 * Search data APi call
	 */
	constructor(public payload: any) { }
}

export class GetWebminarListSuccess implements Action {
	readonly type = GET_WEBINAR_LIST_SUCCESS;
	/**
	 * Search data APi call success
	 */
	constructor(public payload: any) { }
}

export class GetWebminarListFail implements Action {
	readonly type = GET_WEBINAR_LIST_FAIL;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}
export class SetPaginationCountWebinar implements Action {
	readonly type = SET_PAGINATION_COUNT_WEBINAR;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}
export class GetWebinarById implements Action {
	readonly type = GET_WEBINAR_BY_ID;
	/**
	 * Search data APi call
	 */
	constructor(public payload: any) { }
}

export class GetWebinarByIdSuccess implements Action {
	readonly type = GET_WEBINAR_BY_ID_SUCCESS;
	/**
	 * Search data APi call success
	 */
	constructor(public payload: any) { }
}

export class GetWebinarByIdFail implements Action {
	readonly type = GET_WEBINAR_BY_ID_FAIL;
	/**
	 * Search data APi call Fail
	 */
	constructor(public payload: any) { }
}

export class AddOrRemoveWebinarComment implements Action {
	readonly type = ADD_OR_REMOVE_WEBINAR_COMMENT;
	constructor(public payload: any) { }
}
export class AddOrRemoveWebinarCommentSuccess implements Action {
	readonly type = ADD_OR_REMOVE_WEBINAR_COMMENT_SUCCESS;
	constructor(public payload: any) { }
}
export class AddOrRemoveWebinarCommentFail implements Action {
	readonly type = ADD_OR_REMOVE_WEBINAR_COMMENT_FAIL;
	constructor(public payload: any) { }
}

export class GetAllWebinarComments implements Action {
	readonly type = GET_ALL_WEBINAR_COMMENTS;
	constructor(public payload: any) {}
}
export class GetAllWebinarCommentsSuccess implements Action {
	readonly type = GET_ALL_WEIBNAR_COMMENTS_SUCCESS;
	constructor(public payload: any) {}
}
export class GetAllWebinarCommentsFail implements Action {
	readonly type = GET_ALL_WEBINAR_COMMENTS_FAIL;
	constructor(public payload: any) {}
}


export class ReportAbuseWebinarComment implements Action {
	readonly type = REPORT_ABUSE_WEBINAR_COMMENT;
	constructor(public payload: any) {}
}
export class ReportAbuseWebinarCommentSuccess implements Action {
	readonly type = REPORT_ABUSE_WEBINAR_COMMENT_SUCCESS;
	constructor(public payload: any) {}
}
export class ReportAbuseWebinarCommentFail implements Action {
	readonly type = REPORT_ABUSE_WEBINAR_COMMENT_FAIL;
	constructor(public payload: any) {}
}


export type webinarAction = GetWebminarList | GetWebminarListSuccess | GetWebminarListFail |
GetWebinarById | GetWebinarByIdSuccess | GetWebinarByIdFail | SetPaginationCountWebinar | AddOrRemoveWebinarComment | 
AddOrRemoveWebinarCommentSuccess | AddOrRemoveWebinarCommentFail | GetAllWebinarComments | GetAllWebinarCommentsSuccess | GetAllWebinarCommentsFail | ReportAbuseWebinarComment | ReportAbuseWebinarCommentSuccess | ReportAbuseWebinarCommentFail
;
