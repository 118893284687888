/**
 * Created by Neha R
 * component for adding new deal
 * Updated by : Vinita Sharma 
 * Description of updates : removed code from ngoninit function (to check the statud code of add deal api response and redrect to the more services page)
 * 							added it to the addDeal function.
 */
import { Component, OnInit } from '@angular/core';
import * as fromStore from '../../../../cep/store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ObjectInterface } from '../../../models/global-interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
interface ApiResponse {
	code: number;
	result: any[]; 
  }
  

@Component({
  selector: 'app-add-deals',
  templateUrl: './add-deals.component.html',
  styleUrls: ['./add-deals.component.scss']
})
export class AddDealsComponent implements OnInit {
  	/**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * loading status of spinner
	 */
	isLoadingSpinner: boolean;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * api details from dynamic api calling
	 */
	apiDetails: ObjectInterface;
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * category services response
	 */
	dealDetail: ObjectInterface;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
  	userId: any;
  	/**
	 * Share Url variable for social sharing
	 */
	shareUrl: any;
 	 /**
	 *  preference list of user
	 */
	preferenceList: any;
	/**
	 *  array for preference list of user
	 */
	userPreferenceList = [];
		/**
	 *  array for preference list of user
	 */
	userPreferenceListKey = [];
  	/**
	 * category field
	 */
	category: string;
	/**
	 *  variable for marathi editor 
	 */
	marathiFeature: string;
	/**
	 *  variable for english editor 
	 */
	englishFeature: string;
	/**
	 *  variable for marathi Description 
	 */
	marathiDescription: string;
	/**
	 *  variable for english Description 
	 */
	englishDescription: string;
	/**
	 *  variable for marathiTitle
	 */
	marathiName: string;
	/**
	 *  variable for englishTitle
	 */
	englishName: string;
	/**
		 * variable for user name
		 */
	userName: string;
		/**
		 *  variable for successMessages
		 */
	successMessages: {};
  	/**
	 *  statusCode variable
	 */
	statusCode: number;
	/**
	 * Variable for image
	 */
	imageUrl: '';
	/**
	 * Variable for server error message
	 */
	serverErrorMessage: String;
	/**
	 * Variable for tags
	 */
	items: any;
   /**
	 * variable for price
	 */
	dealPrice: number;
	 /**
	 * variable for Commision in percentage
	 */
	dealCommission: number;
	 /**
	 * variable for Amount
	 */
	dealAmount: number;
	 /**
	 * variable for units of deal
	 */
	dealProduct: number;
	 /**
	 * variable for Seller name
	 */
	dealSellerName: String;
	 /**
	 * variable for Available deal
	 */
	dealAvailableProduct: number;
	/**
	 * Variable for end Date
	 */
	endDate: any;
	maxDealPerPerson: any;
	/**
	 * Variable for start Date
	 */
	startDate:any;
	categoryArray = [];
	/**
	 * To get current Date
	 */
	currentDate = new Date();
	selectedCategoryInKey: any;
	/**
	 * Labels
	 */
	addDealsLabels: {};
	/**
	 * Variable for quantity
	 */
	quantity: any;
	imageUrlBanner: '';
	isLoadingPhoto: boolean;
	isLoadingBannerPhoto: boolean;
	blogLabels: {};
	englishTandC;
	marathiTandC;
	



	
  constructor(
    private store: Store<fromStore.CEPState>,
		private router: Router,
		private httpClient: HttpClient,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private apiCallService: ApiCallService,  
    private deviceService: DeviceDetectorService,
  ) {
    this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				if (language) {
					this.selectedLanguage = language;
				}
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		// this.store.select(fromStore.getBlogLabels)
		// 	.subscribe(res => {
		// 		if (res) {
		// 			this.blogLabels = res;
		// 		}
		// 	});
   }

  ngOnInit() {

	this.googleAnalyticsService.eventEmitter(
		screenName.ADD_DEALS+ "_SCREEN",
		{
		  log: clickedOn.LOG_SCREEN,
		  screenName: screenName.ADD_DEALS + "_MOUNT",
		  screenStartTime: new Date(),
		  isScroll: false,
		  screenEndTime: "",
		  scrollValue: "",
		}
		);	
		this.categoryNames()
		this.store.select(fromStore.getSelectedLanguage)
    .subscribe(lang => {
      if (lang) {
        this.selectedLanguage = lang;
        this.store.select(fromStore.getPreferncesList)
          .subscribe((labels) => {
            this.preferenceList = labels;
			
            this.userPreferenceList = [];
            if (this.preferenceList) {
              Object.values(this.preferenceList[this.selectedLanguage]).forEach((element) => {
                this.userPreferenceList.push(element);
              });
            }
          });
      }
    });

  // token from localstorage
  this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        let localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
      }
    });

	this.store.select(fromStore.getBlogLabels)
	.subscribe(res => {
		if (res) {
			this.blogLabels = res;
		}
	});
	this.store.select(fromStore.getAddDealsLabels)
	.subscribe(labels => {
		this.addDealsLabels = labels;

	});

	// get verify password
	this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {
				this.userName = response['firstName'];
				this.dealSellerName = response['firstName'];
			});

    // server error handling
		this.store.select(fromStore.getFeedsServerErrorMessage)
    .subscribe((serverError: any) => {
      if (serverError === 'InvalidCredentials') {
        this.router.navigate(['/login/login']);
      } else {
        this.serverMessage = serverError;
        if (this.serverMessage) {
          console.error(this.serverMessage);
        }
        this.serverMessage = null;
      }

    });

	this.store.select(fromStore.isAddDealsLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});

	this.store.select(fromStore.isUpdateProfilePhotoLoading)
	.subscribe(loading => {
		this.isLoadingSpinner = loading;
	});

	
    	//  Api call for profile photo
	// 	this.store.select(fromStore.updateProfilePhoto)
    // .subscribe((response) => {
    //   if (response) {
	// 	this.imageUrl = response;
    //   }
	// });
	
	// this.store.select(fromStore.favImage)
    // .subscribe((response) => {
    //   if (response) {
	// 	this.imageUrlBanner = response;
    //   }
    // });

  }
  

	/**
	 * Created by Neha r
	 * For adding new deal
	 */
	addDeals() {
		this.googleAnalyticsService.eventEmitter(screenName.ADD_DEALS, {
			log: clickedOn.ADD_DOCUMENT,
			screenName: screenName.ADD_DEALS + "_SCREEN",
			SECTION: screenName.ADD_DEALS ,
			clickedOn: clickedOn.ADD_DEALS,
			title: clickedOn.ADD_DEALS,
			time: new Date(),
			isInput: false,
				  })
		
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				if (this.imageUrl) {
					// API call to add deal
					this.store.dispatch(new fromStore.AddDeals({
						url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].product.addProduct.url,
						method: this.apiDetails['userEngagement'].product.addProduct.method,
						body: {
							userId: this.userId,
							type: this.selectedCategoryInKey,
							createdBy: this.userName,
							en: {
								productName: this.englishName,
								description: this.englishDescription,
								feature: this.englishFeature,
								TandC: this.englishTandC
							},
							mar: {
								productName: this.marathiName,
								description: this.marathiDescription,
								feature: this.marathiFeature,
								TandC: this.marathiTandC
							},
							price: this.dealPrice,
							commissionInPercentage: this.dealCommission,
							totalAmount: this.dealAmount,
							sellerName: this.dealSellerName,
							// unitOfProducts: this.dealProduct,
							// available: this.dealAvailableProduct,
							imageFavourite: this.imageUrlBanner,
							tags: this.items,
							startDate:this.startDate,
							endDate: this.endDate,
							image: this.imageUrl,
							quantity: this.quantity,
							maxDealPerPerson: this.maxDealPerPerson

						}	
					}));
					// to check status of response and redirect to more-services page after adding deal
					this.store.select(fromStore.addDealsStatusCode)
						.subscribe((statusCode) => {
							this.statusCode = statusCode;							
							switch(this.statusCode) {
								case 200:
									this.store.select(fromStore.addDealsSuccessMessage)
									.subscribe(response => {
										this.store.select(fromStore.getSuccessMessages)
										.subscribe(res => {
											if (res) {
												this.successMessages = res;
												
												
												this.toastr.success(this.successMessages[this.selectedLanguage][response]);
												this.router.navigate(['/cep/offers'])
											}
										});
									});
									break;
								case 602:
									this.store.select(fromStore.addDealsSuccessMessage)
									.subscribe(response => {
										this.store.select(fromStore.getErrorMessages)
										.subscribe(res => {
											if (res) {
												this.successMessages = res;
												console.error(this.successMessages[this.selectedLanguage][response]);
											}
										});
									});
									break;
								case 950:
									this.store.select(fromStore.addDealsSuccessMessage)
									.subscribe(response => {
										this.store.select(fromStore.getErrorMessages)
										.subscribe(res => {
											if (res) {
												this.successMessages = res;
												console.error(this.successMessages[this.selectedLanguage][response]);
											}
										});
									});
									break;
							}
						});
				}
			} 
			else if (!this.userId) {
					this.router.navigate(['/Login/login']);
			}
		}
  	}
  
  /**
	 * array initialization for images
	 */
	urls = new Array<string>();

	/**
	 * Created by Neha R
	 * For displaying image and store object API call
	 */
	// async preview(event) {
	
	// 		const files = event.target.files;
	// 		if (files) {
	// 		  for (let i = 0; i < files.length; i++) {
	// 			const file = files[i];
	// 			if (file.size > 800000) {
	// 			  // Display error message and clear input
	// 			  this.selectedLanguage === 'en'
	// 				? alert('Upload image or PDF of size 800kb or less')
	// 				: alert('800kb किंवा त्यापेक्षा कमी आकाराची प्रतिमा किंवा पीडीएफ अपलोड करा');
	// 			  event.target.value = null;
	// 			  return;
	// 			} else {
	// 			  this.isLoadingBannerPhoto = true;
	// 			  const fd = new FormData();
	// 			  fd.append('userId', this.userId);
	// 			  fd.append('type', 'grievanceImage');
	// 			  fd.append('link', '');
	// 			  fd.append('file', file, file.name);
		  
	// 			  // Upload the file to the server using HttpClient
	// 			  const link = this.apiDetails['objectStorage'].base_url + this.apiDetails['objectStorage'].storeObject.url;
	// 			  this.httpClient.post(link, fd).subscribe(
	// 				(res: any) => {
	// 				
	// 				  if (res.code === 200) {
	// 					this.imageUrl = res.result[0]
	// 					this.isLoadingBannerPhoto = false;
	// 				  } else {
	// 					this.selectedLanguage === 'en'
	// 					  ? alert('This file format is not supported')
	// 					  : alert('हा फाईल फॉर्मॅट समर्थित नाही');
	// 					this.isLoadingBannerPhoto = false;
	// 					event.target.value = null;
	// 					return;
	// 				  }
	// 				},
	// 				(error: any) => {
	// 				  // Handle error
	// 				  console.error('Error uploading file:', error);
	// 				  this.isLoadingBannerPhoto = false;
	// 				  event.target.value = null;
	// 				}
	// 			  );
	// 			}
	// 		  }
	// 		}
	// 	}

	async preview(event) {
			const files = event.target.files;
			if (files) {
			  for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (file.size > 800000) {
				  // Display error message and clear input
				  this.selectedLanguage === 'en'
					? alert('Upload image or PDF of size 800kb or less')
					: alert('800kb किंवा त्यापेक्षा कमी आकाराची प्रतिमा किंवा पीडीएफ अपलोड करा');
				  event.target.value = null;
				  return;
				} else {
				  this.isLoadingPhoto = true;
				  const fd = new FormData();
				  fd.append('userId', this.userId);
				  fd.append('type', 'grievanceImage');
				  fd.append('link', '');
				  fd.append('file', file);
		  
				  // Upload the file to the server using HttpClient
				  const link = this.apiDetails['objectStorage'].base_url + this.apiDetails['objectStorage'].storeObject.url;
				  this.httpClient.post<ApiResponse>(link, fd).subscribe(
					(res: ApiResponse) => {
					  
					  if (res.code === 200) {
						this.imageUrl = res.result[0];
						this.isLoadingPhoto = false;
					  } else {
						this.selectedLanguage === 'en'
						  ? alert('This file format is not supported')
						  : alert('हा फाईल फॉर्मॅट समर्थित नाही');
						this.isLoadingPhoto = false;
						event.target.value = null; 
						return;
					  }
					},
					(error: any) => {
					  // Handle error
					  console.error('Error uploading file:', error);
					  this.isLoadingBannerPhoto = false;
					  event.target.value = null;
					}
				  );
				}
			}
		}  

	
	  
	}
	
		  

	async prview(event) {
	
		const files = event.target.files;
		if (files) {
		  for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.size > 800000) {
			  // Display error message and clear input
			  this.selectedLanguage === 'en'
				? alert('Upload image or PDF of size 800kb or less')
				: alert('800kb किंवा त्यापेक्षा कमी आकाराची प्रतिमा किंवा पीडीएफ अपलोड करा');
			  event.target.value = null;
			  return;
			} else {
			  this.isLoadingBannerPhoto = true;
			  const fd = new FormData();
			  fd.append('userId', this.userId);
			  fd.append('type', 'grievanceImage');
			  fd.append('link', '');
			  fd.append('file', file);
	  
			  // Upload the file to the server using HttpClient
			  const link = this.apiDetails['objectStorage'].base_url + this.apiDetails['objectStorage'].storeObject.url;
			  this.httpClient.post<ApiResponse>(link, fd).subscribe(
				(res: ApiResponse) => {
				  if (res.code === 200) {
					this.imageUrlBanner = res.result[0];
					this.isLoadingBannerPhoto = false;
				  } else {
					this.selectedLanguage === 'en'
					  ? alert('This file format is not supported')
					  : alert('हा फाईल फॉर्मॅट समर्थित नाही');
					this.isLoadingBannerPhoto = false;
					event.target.value = null;
					return;
				  }
				},
				(error: any) => {
				  // Handle error
				  console.error('Error uploading file:', error);
				  this.isLoadingBannerPhoto = false;
				  event.target.value = null;
				}
			  );
			}
		}
	}  


  
}
		  
	objectStorageFunction(imageArray){
		this.store.dispatch(new fromStore.UpdateProfilePhoto(
			{
				url: this.apiDetails['objectStorage'].base_url +
					this.apiDetails['objectStorage'].storeObject.url,
				method: this.apiDetails['objectStorage'].storeObject.method,
				body: imageArray,
				type:'addDealImage'
			}));
	}

	 /**
	 * array initialization for images
	 */
	urlsBanner = new Array<string>();

	/**
	 * Created by neha r
	 * For displaying banner
	 */
	async preview1(event){		
		const fdImages = new FormData();
		this.urlsBanner = [];
		await fdImages.append('userId', this.userId);
		await fdImages.append('type', 'image');
		var imageArray=[];

		let files = event.target.files;
	
		var i = 0;
		if (files && fdImages) {
			for (var i = 0; i < files.length; i++) {
				var cu = (i + 1) + '';
				fdImages.append(cu, files[i]);
			}
		}

		if (files && fdImages) {
			for (let file of files) {
				let reader = new FileReader();
				reader.onload = (e: any) => {
					var cu = (i + 1) + '';
					fdImages.append(cu, file);
					
					imageArray.push(fdImages);
					this.urlsBanner.push(e.target.result);
					i++;
				};
				
				reader.readAsDataURL(file);
			}
			await this.objectStorageFunctionForBanner(fdImages);
			await this.store.select(fromStore.favImageResponseLoading)
			.subscribe(isLoading => {
				this.isLoadingBannerPhoto = isLoading;
			});
		}
	}
	objectStorageFunctionForBanner(imageArray){
		this.store.dispatch(new fromStore.FavImage(
			{
				url: this.apiDetails['objectStorage'].base_url +
					this.apiDetails['objectStorage'].storeObject.url,
				method: this.apiDetails['objectStorage'].storeObject.method,
				body: imageArray,
				option:'addDealImageBanner'
			}));
	}
	/**
	 * Created by Neha R
	 * For removing image from array
	 */
	removeImage(i) {
		this.imageUrl = '';
	}

		/**
	 * Created by Neha R
	 * For removing image from array
	 */
	removeImageBanner(i) {
		this.urlsBanner.splice(i, 1);
		this.imageUrlBanner = '';
	}

	/**
	 * Angular method which gets called on page destroy
	 */
	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.ADD_DEALS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.ADD_DEALS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		this.serverErrorMessage = null;
	}

	/**
	 * Created by neha
	 * to get category key
	 */
	categoryNames(){
		// this.userPreferenceListKey = [];
		// Object.keys(this.preferenceList[this.selectedLanguage]).forEach((element) => {
		// 	this.userPreferenceListKey.push(element);
		//   });
		// for (let i = 0; i < this.userPreferenceList.length; i++) {
		// 	if(this.userPreferenceList[i] == value){
		// 		this.selectedCategoryInKey = this.userPreferenceListKey[i]
		// 	}		
		// }

		this.googleAnalyticsService.eventEmitter(screenName.ADD_DEALS, {
			log: clickedOn.CATEGORYNAME,
			screenName: screenName.ADD_DEALS + "_SCREEN",
			SECTION: screenName.ADD_DEALS ,
			clickedOn: clickedOn.CATEGORYNAME,
			title: clickedOn.CATEGORYNAME,
			time: new Date(),
			isInput: false,
				  })
		const method = 'get';
		const url = this.apiDetails.user.base_url + this.apiDetails.user.getCategoryName.url;
		const body = {};

		this.apiCallService.apiCall(method, url, {}).subscribe(
			(response: any) => {
				this.categoryArray = response.result;
			},
			(error: any) => {
				console.error(error);
			}
		);
	}

	/**
	 * Angular method which gets calld on page view initalizaton
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
}
