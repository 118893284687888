import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class LoadshCompareService {

  constructor(private encrDecrService: EncrDecrService) { }

  compareObjects(obj1: any, obj2: any): boolean {  
    // const { validate, ...obj1WithoutValidate } = obj1;

    const decryptedObj2 = JSON.parse(this.decryptObject(obj2).decryptedKey);

    // console.log("Loadash validate",obj1,decryptedObj2)

    return isEqual(obj1, decryptedObj2);
  }

  private decryptObject(obj: any): any {
    
    const decryptedKey = this.encrDecrService.get(obj);
    return { ...obj, decryptedKey };
  }
}
