/************************************
 * Component -  news card component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ************************************/
import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Images } from '../../config/images-config';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/localstorage.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { EncrDecrService } from '../../services/encr-decr.service';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router } from '@angular/router';
import { LoginpopupComponent } from 'src/app/cep/dashboard-management/e-services/loginpopup/loginpopup.component';
import { ClipboardService } from '../../services/clipboard.service';
import { ToastrService } from 'ngx-toastr';
import { DynamicLinksService } from '../../services/dynamic-links';
import { environment } from 'src/environments/environment';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
@Component({
	selector: 'app-card-news',
	templateUrl: './card-news.component.html',
	styleUrls: ['./card-news.component.scss']
})

export class CardNewsComponent implements OnInit, AfterContentChecked {
	@Input() newsData: any;
	image = Images;
	selectedLanguage:string;
	newsDate;
	landingLables
	apiDetails: {};
	shareUrl: any;
	token: string;
	userId: any;
	favorite: string;
  feedsDate: Date;
  todaysDate: Date;
  dateDiff: number;
  
  getUserId: string;
  
  articleData: any;
  
	isBlind;
	
	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private apiCallService: ApiCallService,
		private clipboardService: ClipboardService,
		private router: Router,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private googleAnalyticsService: GoogleAnalyticsService) {
	}

	ngOnInit() {

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});

			if (this.newsData) {
				if (this.newsData.fav.filter( (item) => item === this.userId ).length > 0) {
					this.favorite = 'favorite';
				} else {
					this.favorite = 'favorite_border';
				}
			}

			this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});

			this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

			this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

			});
	}
	

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }

	  onClick() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.VIEW,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.NEWS_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.VIEW,
			time: new Date(),
			isInput: false,
			contentId:this.newsData.timelineId
		  })
		// let user = this.localStorageService.getItem("userId")
		// if (user === null) {
		// 	this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/feeds') )
		// 	const dialogRef = this.dialog.open(LoginpopupComponent);
		// 	// this.router.navigate(["/Login/login"])
		// } else {

			this.router.navigate(['/cep/feeds/news/', this.newsData.timelineId])
		// }
	}

	/**
	 * Created by Akanksha J
	 * for reporting news as abuse
	 */
	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.newsData.timelineId,
				engagementType: 'news',
				engagementId: this.newsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/news/" + this.newsData.timelineId
		var metaTag  = {
			socialTitle: this.newsData.en.heading,
			socialImageLink: this.newsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	unMarkFavorite() {
	
		
		if (this.newsData.favourite) {
			this.newsData = Object.assign({}, this.newsData, { favourite: false });
			this.favorite = 'favorite_border';
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.NEWS_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.newsData.timelineId
			  })
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.newsData = Object.assign({}, this.newsDate, { favourite: true });
			this.favorite = 'favorite';
			this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
				log: clickedOn.LIKE,
				screenName: screenName.FEEDS + "_SCREEN",
				SECTION: screenName.NEWS_CARD ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.newsData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			
			if (this.apiDetails) {
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavorite({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavourite.url,
						method: this.apiDetails['user'].addToFavourite.method,
						body: {
							userId: this.userId,
							engagementId: this.newsData.engagementId,
							engagementType: 'news'
						}
					}));
				}
			}
		}
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.newsDate = new Date(this.newsData.updatedAt).toDateString();
	}
	// clickOnShare() {
	// 	//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of news', 'Click Event', 1);
	// }
	async clickOnShare() {
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.NEWS_CARD ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.newsData.timelineId
		  })
	
		let url = environment.shareurl +  "/cep/feeds/news/" + this.newsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}
}
