import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
@Component({
  selector: 'app-collaboration-quicklink',
  templateUrl: './collaboration-quicklink.component.html',
  styleUrls: ['./collaboration-quicklink.component.scss']
})
export class CollaborationQuicklinkComponent implements OnInit {
  @Input() item: any;
  selectedLanguage:any;
  drawerLabels = {};
  constructor(
		private store: Store<fromStore.CEPState>,
  ) { }

  ngOnInit() {
    this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;

			});

      this.store.select(fromStore.getDrawerLabels)
      .subscribe((labels) => {
        this.drawerLabels = labels;
    
      });
  }

}
