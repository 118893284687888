/**
 * Created by: Shanu J
 * Util: Encryption decryption file
 * Created date: 30 June 2022
 */

import * as CryptoJS from "crypto-js";

export class EncrDecrUtil {
  key = "";
  iv = "";
  /**
   * angular method for declaring packages in variables
   *
   * which runs first in this particulat component
   */
  constructor() {
    this.key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    this.iv = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
  }

  //The set method is use for encrypt the value.

  set(value) {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      this.key,
      {
        iv: this.iv,
      }
    );

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(value) {
    var decrypted = CryptoJS.AES.decrypt(value, this.key, {
      iv: this.iv,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
