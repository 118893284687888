import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ApiCallService } from "src/app/shared/services/dynamic-api-calling.service";
import { EncrDecrUtil } from "src/app/util/EncrDecr";
import { map, switchMap, catchError, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  constructor(
    private http: HttpClient,
    private apiCallService: ApiCallService
  ) {}

  logout(request) {
    let method = request.method;
		let url = request.url;
		let body = request.body;
    return this.apiCallService.apiCall(method, url, body);
  }
}
