import { Component, OnInit, Inject } from '@angular/core';
import { Images } from '../../config/images-config';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-popup-qr-scan',
  templateUrl: './popup-qr-scan.component.html',
  styleUrls: ['./popup-qr-scan.component.scss']
})
export class PopupQrScanComponent implements OnInit {
  elementType: 'url' | 'canvas' | 'img' = 'url';

  value: string = 'https://play.google.com/store/apps/details?id=com.dataapp&hl=en_IN';
  url: string = 'https://apps.apple.com/in/app/pcmc-smart-sarathi/id1495025693';
  images = Images;
  playStoreUrl = 'https://fxurl.co/rFshd';
  AppStoreUrl = 'https://fxurl.co/4IJJ123';

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PopupQrScanComponent>,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  /**
   * Created by Prajta P
   * For closing thr popup
   */
  closeDialog() {
    const dialogClose = this.dialog.closeAll();
  }
}
