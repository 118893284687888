import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromService from '../reducer/service.reducer';

export const getServiceState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.service
);

export const getServiceResult = createSelector(
	getServiceState,
	fromService.getServiceResult
);

export const getServiceStateLoading = createSelector(
	getServiceState,
	fromService.getServiceStateLoading
);

export const getServiceStateLoaded = createSelector(
	getServiceState,
	fromService.getServiceStateLoaded
);

export const getServicesServerErrorMessage = createSelector(
	getServiceState,
	fromService.getServicesServerErrorMessage
);


export const getCoronaSurveyDetails = createSelector(
	getServiceState,
	fromService.getCoronaSurveyDetails
);

export const getLatitude = createSelector(
	getServiceState,
	fromService.getLatitude
);

export const getLongitude = createSelector(
	getServiceState,
	fromService.getLongitude
);

export const getGrievancePaginationCount = createSelector(
	getServiceState,
	fromService.getGrievancePaginationCount
);
export const getGrievanceListingResult = createSelector(
	getServiceState,
	fromService.getGrievanceListingResult
);
export const getGrievanceListingStatusCode = createSelector(
	getServiceState,
	fromService.getGrievanceListingStatusCode
);
export const getGrievanceListingSuccessMessage = createSelector(
	getServiceState,
	fromService.getGrievanceListingSuccessMessage
);
export const isGetGrievanceListingLoading = createSelector(
	getServiceState,
	fromService.isGetGrievanceListingLoading
);
export const isGetGrievanceListingLoaded = createSelector(
	getServiceState,
	fromService.isGetGrievanceListingLoaded
);

export const addGrievanceResult = createSelector(
	getServiceState,
	fromService.addGrievanceResult
);
export const addGrievanceStatusCode = createSelector(
	getServiceState,
	fromService.addGrievanceStatusCode
);
export const addGrievanceSuccessMessage = createSelector(
	getServiceState,
	fromService.addGrievanceSuccessMessage
);
export const isAddGrievanceLoading = createSelector(
	getServiceState,
	fromService.isAddGrievanceLoading
);
export const isAddGrievanceLoaded = createSelector(
	getServiceState,
	fromService.isAddGrievanceLoaded
);

export const grievanceDetailResult = createSelector(
	getServiceState,
	fromService.grievanceDetailResult
);
export const grievanceDetailStatusCode = createSelector(
	getServiceState,
	fromService.grievanceDetailStatusCode
);
export const grievanceDetailSuccessMessage = createSelector(
	getServiceState,
	fromService.grievanceDetailSuccessMessage
);
export const isGrievanceDetailLoading = createSelector(
	getServiceState,
	fromService.isGrievanceDetailLoading
);
export const isGrievanceDetailLoaded = createSelector(
	getServiceState,
	fromService.isGrievanceDetailLoaded
);

export const grievanceVoteResult = createSelector(
	getServiceState,
	fromService.grievanceVoteResult
);
export const grievanceVoteStatusCode = createSelector(
	getServiceState,
	fromService.grievanceVoteStatusCode
);
export const grievanceVoteSuccessMessage = createSelector(
	getServiceState,
	fromService.grievanceVoteSuccessMessage
);
export const isGrievanceVoteLoading = createSelector(
	getServiceState,
	fromService.isGrievanceVoteLoading
);
export const isGrievanceVoteLoaded = createSelector(
	getServiceState,
	fromService.isGrievanceVoteLoaded
);

export const grievanceCategoryResult = createSelector(
	getServiceState,
	fromService.grievanceCategoryResult
);
export const grievanceCategoryStatusCode = createSelector(
	getServiceState,
	fromService.grievanceCategoryStatusCode
);
export const grievanceCategorySuccessMessage = createSelector(
	getServiceState,
	fromService.grievanceCategorySuccessMessage
);
export const isGrievanceCategoryLoading = createSelector(
	getServiceState,
	fromService.isGrievanceCategoryLoading
);
export const isGrievanceCategoryLoaded = createSelector(
	getServiceState,
	fromService.isGrievanceCategoryLoaded
);

export const vaccinationCenterListResult = createSelector(
	getServiceState,
	fromService.vaccinationCenterListResult
);
export const vaccinationCenterListStatusCode = createSelector(
	getServiceState,
	fromService.vaccinationCenterListStatusCode
);
export const vaccinationCenterListSuccessMessage = createSelector(
	getServiceState,
	fromService.vaccinationCenterListSuccessMessage
);
export const isVaccinationCenterListLoading = createSelector(
	getServiceState,
	fromService.isVaccinationCenterListLoading
);
export const isVaccinationCenterListLoaded = createSelector(
	getServiceState,
	fromService.isVaccinationCenterListLoaded
);
export const strapiContent = createSelector(
  getServiceState,
  fromService.strapiContentSelector
)



