import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as fromLogin from '../reducer/login.reducer';

export const getLoginState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.login
);

export const getSelectedLanguage = createSelector(
	getLoginState,
	fromLogin.getSelectedLanguage
);
export const getUserStateResponse = createSelector(
	getLoginState,
	fromLogin.getUserStateResponse
);
export const getUserStateLoading = createSelector(
	getLoginState,
	fromLogin.getUserStateLoading
);
export const getUserStateLoaded = createSelector(
	getLoginState,
	fromLogin.getUserStateLoaded
);
export const getUserErrorMessage = createSelector(
	getLoginState,
	fromLogin.userErrorMessage
);
export const userStateStatusCode = createSelector(
	getLoginState,
	fromLogin.userStateStatusCode
);
export const getMobileNumber = createSelector(
	getLoginState,
	fromLogin.mobileNumber
);

export const getEmployeeStateResponse = createSelector(
	getLoginState,
	fromLogin.getEmployeeStateResponse
);
export const getisEmployeeStateLoading = createSelector(
	getLoginState,
	fromLogin.getisEmployeeStateLoading
);
export const getisEmployeeStateLoaded = createSelector(
	getLoginState,
	fromLogin.getisEmployeeStateLoaded
);
export const getemployeeErrorMessage = createSelector(
	getLoginState,
	fromLogin.getemployeeErrorMessage
);
export const getEmployeeStateStatusCode = createSelector(
	getLoginState,
	fromLogin.getEmployeeStateStatusCode
);
export const getemployeeId = createSelector(
	getLoginState,
	fromLogin.getemployeeId
);

export const sendOtpSuccessCode = createSelector(
	getLoginState,
	fromLogin.sendOtpSuccessCode
);
export const sendOtpResponse = createSelector(
	getLoginState,
	fromLogin.sendOtpResponse
);
export const isSendOtpLoading = createSelector(
	getLoginState,
	fromLogin.isSendOtpLoading
);
export const isSendOtpLoaded = createSelector(
	getLoginState,
	fromLogin.isSendOtpLoaded
);
export const sendOtpErrorMessage = createSelector(
	getLoginState,
	fromLogin.sendOtpErrorMessage
);

export const getVerifyOtpSuccessCode = createSelector(
	getLoginState,
	fromLogin.getVerifyOtpSuccessCode
);

export const verifyOtpResponse = createSelector(
	getLoginState,
	fromLogin.verifyOtpResponse
);
export const isverifyOtpLoading = createSelector(
	getLoginState,
	fromLogin.isverifyOtpLoading
);
export const verifyOtpStatusCode = createSelector(
	getLoginState,
	fromLogin.verifyOtpStatusCode
);
export const isverifyOtpLoaded = createSelector(
	getLoginState,
	fromLogin.isverifyOtpLoaded
);
export const verifyOtpErrorMessage = createSelector(
	getLoginState,
	fromLogin.verifyOtpErrorMessage
);
export const fetchOtp = createSelector(
	getLoginState,
	fromLogin.fetchOtp
);

export const facebookLoginResponse = createSelector(
	getLoginState,
	fromLogin.facebookLoginResponse
);
export const facebookLoginLoading = createSelector(
	getLoginState,
	fromLogin.facebookLoginLoading
);
export const facebookLoginLoaded = createSelector(
	getLoginState,
	fromLogin.facebookLoginLoaded
);
export const facebookErrorMessage = createSelector(
	getLoginState,
	fromLogin.facebookLoginErrorMessage
);
export const facebookStatusCode = createSelector(
	getLoginState,
	fromLogin.facebookStatusCode
);
export const facebookLoginData = createSelector(
	getLoginState,
	fromLogin.facebookLoginData
);

export const twitterLoginResponse = createSelector(
	getLoginState,
	fromLogin.twitterLoginResponse
);
export const twitterLoginLoading = createSelector(
	getLoginState,
	fromLogin.twitterLoginLoading
);
export const twitterLoginLoaded = createSelector(
	getLoginState,
	fromLogin.twitterLoginLoaded
);
export const twitterErrorMessage = createSelector(
	getLoginState,
	fromLogin.twitterLoginErrorMessage
);
export const twitterStatusCode = createSelector(
	getLoginState,
	fromLogin.twitterStatusCode
);
export const twitterLoginData = createSelector(
	getLoginState,
	fromLogin.twitterLoginData
);

export const googleLoginResponse = createSelector(
	getLoginState,
	fromLogin.googleLoginResponse
);
export const googleLoginLoading = createSelector(
	getLoginState,
	fromLogin.googleLoginLoading
);
export const googleLoginLoaded = createSelector(
	getLoginState,
	fromLogin.googleLoginLoaded
);
export const googleErrorMessage = createSelector(
	getLoginState,
	fromLogin.googleLoginErrorMessage
);
export const googleStatusCode = createSelector(
	getLoginState,
	fromLogin.googleStatusCode
);
export const googleLoginData = createSelector(
	getLoginState,
	fromLogin.googleLoginData
);

export const forgotPasswordResponse = createSelector(
	getLoginState,
	fromLogin.forgotPasswordResponse
);
export const isForgotPasswordLoading = createSelector(
	getLoginState,
	fromLogin.isForgotPasswordLoading
);
export const isForgotPasswordLoaded = createSelector(
	getLoginState,
	fromLogin.isForgotPasswordLoaded
);
export const forgotPasswordErrorMessage = createSelector(
	getLoginState,
	fromLogin.forgotPasswordErrorMessage
);
export const forgotPasswordValidate = createSelector(
	getLoginState,
	fromLogin.forgotPasswordValidate

)

export const forgotPasswordByEmailResponse = createSelector(
	getLoginState,
	fromLogin.forgotPasswordByEmailResponse
);
export const isForgotPasswordByEmailLoading = createSelector(
	getLoginState,
	fromLogin.isForgotPasswordByEmailLoading
);
export const isForgotPasswordByEmailLoaded = createSelector(
	getLoginState,
	fromLogin.isForgotPasswordByEmailLoaded
);
export const forgotPasswordByEmailErrorMessage = createSelector(
	getLoginState,
	fromLogin.forgotPasswordByEmailErrorMessage
);

export const getVerifyPasswordResponse = createSelector(
	getLoginState,
	fromLogin.getVerifyPasswordResponse
);
export const getVerifyPasswordLoading = createSelector(
	getLoginState,
	fromLogin.getVerifyPasswordLoading
);
export const getVerifyPasswordLoaded = createSelector(
	getLoginState,
	fromLogin.getVerifyPasswordLoaded
);
export const getVerifyPasswordErrorMessage = createSelector(
	getLoginState,
	fromLogin.getVerifyPasswordErrorMessage
);
export const verifyPasswordStatusCode = createSelector(
	getLoginState,
	fromLogin.verifyPasswordStatusCode
);
export const verifyPasswordsValidate = createSelector(
	getLoginState,
	fromLogin.verifyPasswordsValidate
);
export const getverifyPasswordCheckSum = createSelector(
	getLoginState,
	fromLogin.getverifyPasswordCheckSum
);
// ---------------------
export const getLoginWithOTPResponse = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPResponse
);

export const getLoginWithOTPLoading = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPLoading
);

export const getLoginWithOTPLoaded = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPLoaded
);

export const getLoginWithOTPStatusCode = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPStatusCode
);

export const getLoginWithOTPValidate = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPValidate
);

export const getLoginWithOTPCheckSum = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPCheckSum
);

export const getLoginWithOTPErrorMessage = createSelector(
	getLoginState,
	fromLogin.getLoginWithOTPErrorMessage
);

export const getloginEmployeeResponse = createSelector(
	getLoginState,
	fromLogin.getloginEmployeeResponse
);

export const getisloginEmployeeLoading = createSelector(
	getLoginState,
	fromLogin.getisloginEmployeeLoading
);

export const getisloginEmployeeLoaded = createSelector(
	getLoginState,
	fromLogin.getisloginEmployeeLoaded
);

export const getloginEmployeeStatusCode = createSelector(
	getLoginState,
	fromLogin.getloginEmployeeStatusCode
);

export const getloginEmployeeValidate = createSelector(
	getLoginState,
	fromLogin.getloginEmployeeValidate
);


export const getloginEmployeeErrorMessage = createSelector(
	getLoginState,
	fromLogin.getloginEmployeeErrorMessage
);

export const getUpdateProfileResponse = createSelector(
	getLoginState,
	fromLogin.getUpdateProfile
);
export const getUpdateProfileLoading = createSelector(
	getLoginState,
	fromLogin.getUpdateProfileLoading
);
export const getUpdateProfileLoaded = createSelector(
	getLoginState,
	fromLogin.getUpdateProfileLoaded
);

export const registerUserResponse = createSelector(
	getLoginState,
	fromLogin.registerUserResponse
);
export const registerStatusCode = createSelector(
	getLoginState,
	fromLogin.registerStatusCode
);
export const registerUserLoading = createSelector(
	getLoginState,
	fromLogin.registerUserLoading
);
export const registerUserLoaded = createSelector(
	getLoginState,
	fromLogin.registerUserLoaded
);
export const registerUserErrorMessage = createSelector(
	getLoginState,
	fromLogin.registerUserErrorMessage
);









export const validateRefCodeResponse = createSelector(
	getLoginState,
	fromLogin.validateRefCodeResponse
);

export const validateReferralCodeErrorMessage = createSelector(
	getLoginState,
	fromLogin.validateReferralCodeErrorMessage
);

export const validateRefCodeResult = createSelector(
	getLoginState,
	fromLogin.validateRefCodeResult
);


export const validateReferralCodeStatusCode = createSelector(
	getLoginState,
	fromLogin.validateReferralCodeStatusCode
);





export const getUserDetails = createSelector(
	getLoginState,
	fromLogin.getUserDetails
);

export const getUserDetailsLoading = createSelector(
	getLoginState,
	fromLogin.getUserDetailsLoading
);
export const getUserDetailsLoaded = createSelector(
	getLoginState,
	fromLogin.getUserDetailsLoaded
);
export const getUserDetailsErrorMessage = createSelector(
	getLoginState,
	fromLogin.getUserDetailsErrorMessage
);
export const getToken = createSelector(
	getLoginState,
	fromLogin.getToken
);
export const getUpdateProfileStatusCode = createSelector(
	getLoginState,
	fromLogin.getUpdateProfileStatusCode
);
export const getUpdateProfileErrorMessage = createSelector(
	getLoginState,
	fromLogin.getUpdateProfileErrorMessage
);

export const getIsUserProfileLoading = createSelector(
	getLoginState,
	fromLogin.getIsUserProfileLoading
);

export const getMostUsedServicesResult = createSelector(
	getLoginState,
	fromLogin.getMostUsedServicesResult
);

export const getIsMostUsedServicesLoaded = createSelector(
	getLoginState,
	fromLogin.getIsMostUsedServicesLoaded
);

export const getIsMostUsedServicesLoading = createSelector(
	getLoginState,
	fromLogin.getIsMostUsedServicesLoading
);

export const updateLanguage = createSelector(
	getLoginState,
	fromLogin.updateLanguage
);
export const updateLanguageStatusCode = createSelector(
	getLoginState,
	fromLogin.updateLanguageStatusCode
);

export const getUpdatePassward = createSelector(
	getLoginState,
	fromLogin.getUpdatePasswardResponse
);
export const getIsUpdatePasswardLoading = createSelector(
	getLoginState,
	fromLogin.getIsUpdatePasswardLoading
);
export const getIsUpdatePasswardLoaded = createSelector(
	getLoginState,
	fromLogin.getIsUpdatePasswardLoaded
);
export const updatePasswordErrorMessage = createSelector(
	getLoginState,
	fromLogin.updatePasswordErrorMessage
);
export const updatePasswordStatusCode = createSelector(
	getLoginState,
	fromLogin.updatePasswordStatusCode
);
export const updatePasswordResponse = createSelector(
	getLoginState,
	fromLogin.updatePasswordResponse
)
export const updatePasswordValidate = createSelector(
	getLoginState,
	fromLogin.updatePasswordValidate
);

export const updateProfilePhoto = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponse
);
export const updateProfilePhotoStatusCode = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoStatusCode
);
export const updateProfilePhotoResponseCertificate = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseCertificate
);
export const updateProfilePhotoResponseProperty = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseProperty
);
export const isUpdateProfilePhotoLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoLoading
);
export const isUpdateProfilePhotoLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoLoaded
);
export const isUpdateProfilePhotoPropertyLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoPropertyLoading
);
export const isUpdateProfilePhotoPropertyLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoPropertyLoaded
);
export const updateProfilePhotoResponseGst = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseGst
);
export const isUpdateProfilePhotoGstLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoGstLoading
);
export const isUpdateProfilePhotoGstLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoGstLoaded
);
export const updateProfilePhotoResponsePan = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponsePan
);
export const isUpdateProfilePhotoPanLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoPanLoading
);
export const isUpdateProfilePhotoPanLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoPanLoaded
);
export const updateProfilePhotoResponseFss = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseFss
);
export const isUpdateProfilePhotoFssLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoFssLoading
);
export const isUpdateProfilePhotoFssLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoFssLoaded
);
export const updateProfilePhotoResponseShopAct = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseShopAct
);
export const isUpdateProfilePhotoShopActLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoShopActLoading
);
export const isUpdateProfilePhotoShopActLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoShopActLoaded
);
export const updateProfilePhotoResponseUdyogAadhar = createSelector(
	getLoginState,
	fromLogin.updateProfilePhotoResponseUdyogAadhar
);
export const isUpdateProfilePhotoUdyogAadharLoading = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoUdyogAadharLoading
);
export const isUpdateProfilePhotoUdyogAadharLoaded = createSelector(
	getLoginState,
	fromLogin.isUpdateProfilePhotoUdyogAadharLoaded
);
export const favImage = createSelector(
	getLoginState,
	fromLogin.favImageResponse
);
export const favImageResponseLoading = createSelector(
	getLoginState,
	fromLogin.favImageResponseLoading
);
export const favImageResponseLoaded = createSelector(
	getLoginState,
	fromLogin.favImageResponseLoaded
);

export const changeMobileNumberResponse = createSelector(
	getLoginState,
	fromLogin.changeMobileNumberResponse
);
export const changeMobileNumberStatusCode = createSelector(
	getLoginState,
	fromLogin.changeMobileNumberStatusCode
);
export const changeMobileNumberErrorMessage = createSelector(
	getLoginState,
	fromLogin.changeMobileNumberErrorMessage
);
export const changeMobileNumbrLoading = createSelector(
	getLoginState,
	fromLogin.changeMobileNumbrLoading
);
export const changeMobileNumbrLoaded = createSelector(
	getLoginState,
	fromLogin.changeMobileNumbrLoaded
);

export const verifyMobileNumberResponse = createSelector(
	getLoginState,
	fromLogin.verifyMobileNumberResponse
);
export const verifyMobileNumberStatusCode = createSelector(
	getLoginState,
	fromLogin.verifyMobileNumberStatusCode
);
export const verifyMobileNumberErrorMessage = createSelector(
	getLoginState,
	fromLogin.verifyMobileNumberErrorMessage
);
export const verifyMobileNumbrLoading = createSelector(
	getLoginState,
	fromLogin.verifyMobileNumbrLoading
);
export const verifyMobileNumbrLoaded = createSelector(
	getLoginState,
	fromLogin.verifyMobileNumbrLoaded
);

export const getOtpChoicesResponse = createSelector(
	getLoginState,
	fromLogin.getOtpChoicesResponse
);
export const getOtpChoicesStatusCode = createSelector(
	getLoginState,
	fromLogin.getOtpChoicesStatusCode
);
export const getOtpChoicesErrorMessage = createSelector(
	getLoginState,
	fromLogin.getOtpChoicesErrorMessage
);
export const getOtpChoicesLoading = createSelector(
	getLoginState,
	fromLogin.getOtpChoicesLoading
);
export const getOtpChoicesLoaded = createSelector(
	getLoginState,
	fromLogin.getOtpChoicesLoaded
);


export const termsAndConditions = createSelector(
	getLoginState,
	fromLogin.termsAndConditions
);
export const termsAndConditionsStatusCode = createSelector(
	getLoginState,
	fromLogin.termsAndConditionsStatusCode
);
export const termsAndConditionsErrorMessage = createSelector(
	getLoginState,
	fromLogin.termsAndConditionsErrorMessage
);
export const isTermsAndConditionsLoading = createSelector(
	getLoginState,
	fromLogin.isTermsAndConditionsLoading
);
export const isTermsAndConditionsLoaded = createSelector(
	getLoginState,
	fromLogin.isTermsAndConditionsLoaded
);

export const merchantTermsAndConditions = createSelector(
	getLoginState,
	fromLogin.merchantTermsAndConditions
);
export const merchantTermsAndConditionsStatusCode = createSelector(
	getLoginState,
	fromLogin.merchantTermsAndConditionsStatusCode
);
export const merchantTermsAndConditionsErrorMessage = createSelector(
	getLoginState,
	fromLogin.merchantTermsAndConditionsErrorMessage
);
export const merchantTermsAndConditionsLoading = createSelector(
	getLoginState,
	fromLogin.merchantTermsAndConditionsLoading
);
export const merchantTermsAndConditionsLoaded = createSelector(
	getLoginState,
	fromLogin.merchantTermsAndConditionsLoaded
);

export const getUserStateServerErrorMessage = createSelector(
	getLoginState,
	fromLogin.getUserStateServerErrorMessage
);

export const getInterestedToBeMerchantErrorMessage = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeMerchantErrorMessage
);
export const getIsInterestedToBeMerchantLoading = createSelector(
	getLoginState,
	fromLogin.getIsInterestedToBeMerchantLoading
);
export const getIsInterestedToBeMerchantLoaded = createSelector(
	getLoginState,
	fromLogin.getIsInterestedToBeMerchantLoaded
);
export const getInterestedToBeMerchantStatusCode = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeMerchantStatusCode
);
export const getInterestedToBeMerchantResponse = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeMerchantResponse
);

export const getInterestedToBeBrandErrorMessage = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeBrandErrorMessage
);
export const getIsInterestedToBeBrandLoading = createSelector(
	getLoginState,
	fromLogin.getIsInterestedToBeBrandLoading
);
export const getIsInterestedToBeBrandLoaded = createSelector(
	getLoginState,
	fromLogin.getIsInterestedToBeBrandLoaded
);
export const getInterestedToBeBrandStatusCode = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeBrandStatusCode
);
export const getInterestedToBeBrandResponse = createSelector(
	getLoginState,
	fromLogin.getInterestedToBeBrandResponse
);


export const getRegisterAsMerchantErrorMessage = createSelector(
	getLoginState,
	fromLogin.getRegisterAsMerchantErrorMessage
);
export const getIsRegisterAsMerchantLoading = createSelector(
	getLoginState,
	fromLogin.getIsRegisterAsMerchantLoading
);
export const getIsRegisterAsMerchantLoaded = createSelector(
	getLoginState,
	fromLogin.getIsRegisterAsMerchantLoaded
);
export const getRegisterAsMerchantStatusCode = createSelector(
	getLoginState,
	fromLogin.getRegisterAsMerchantStatusCode
);

export const getRegisterAsBrandErrorMessage = createSelector(
	getLoginState,
	fromLogin.getRegisterAsBrandErrorMessage
);
export const getIsRegisterAsBrandLoading = createSelector(
	getLoginState,
	fromLogin.getIsRegisterAsBrandLoading
);
export const getIsRegisterAsBrandLoaded = createSelector(
	getLoginState,
	fromLogin.getIsRegisterAsBrandLoaded
);
export const getRegisterAsBrandStatusCode = createSelector(
	getLoginState,
	fromLogin.getRegisterAsBrandStatusCode
);


export const getAllBrandErrorMessage = createSelector(
	getLoginState,
	fromLogin.getAllBrandErrorMessage
);
export const getIsGetAllBrandLoading = createSelector(
	getLoginState,
	fromLogin.getIsGetAllBrandLoading
);
export const getIsGetAllBrandLoaded = createSelector(
	getLoginState,
	fromLogin.getIsGetAllBrandLoaded
);
export const getAllBrandStatusCode = createSelector(
	getLoginState,
	fromLogin.getAllBrandStatusCode
);
export const getAllBrandResponse = createSelector(
	getLoginState,
	fromLogin.getAllBrandResponse
);


export const getNewsletterSubscriptionStatusCode = createSelector(
	getLoginState,
	fromLogin.getNewsletterSubscriptionStatusCode
);
export const getNewsletterSubscriptionErrorMessage = createSelector(
	getLoginState,
	fromLogin.getNewsletterSubscriptionErrorMessage
);
export const isNewsletterSubscriptionLoading = createSelector(
	getLoginState,
	fromLogin.isNewsletterSubscriptionLoading
);
export const isNewsletterSubscriptionLoaded = createSelector(
	getLoginState,
	fromLogin.isNewsletterSubscriptionLoaded
);
export const uploadContentType = createSelector(
	getLoginState,
	fromLogin.uploadContentType
);

export const gotoPageOnLogin = createSelector(
	getLoginState,
	fromLogin.gotoPageOnLogin
);

export const gotoService = createSelector(
	getLoginState,
	fromLogin.gotoService
);

export const getFCMToken = createSelector(
	getLoginState,
	fromLogin.getFCMToken
);
