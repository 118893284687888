import { Injectable } from '@angular/core';
import { createEffect , Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as webinarAction from '../../store/action/webinar.action';
import { SearchService } from '../../store/api-services/search.service';
import {of} from 'rxjs';
import { WebinarService } from '../api-services/webinar.service';

@Injectable()

export class WebinarEffect {
	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private actions$: Actions,
		private webinarService: WebinarService
	) {
	}


	apiWebinarListData$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<webinarAction.GetWebminarList>(webinarAction.GET_WEBINAR_LIST),
		  switchMap((action) => {
			return this.webinarService.getWebinarListing(action.payload).pipe(
			  map(webinarList => new webinarAction.GetWebminarListSuccess(webinarList)),
			  catchError(error => of(new webinarAction.GetWebminarListFail(error)))
			);
		  })
		)
	  );
	
	  apiWebinarByIdData$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<webinarAction.GetWebinarById>(webinarAction.GET_WEBINAR_BY_ID),
		  switchMap((action) => {
			return this.webinarService.getWebinarListing(action.payload).pipe(
			  map(webinarById => new webinarAction.GetWebinarByIdSuccess(webinarById)),
			  catchError(error => of(new webinarAction.GetWebinarByIdFail(error)))
			);
		  })
		)
	  );
	
	  AddOrRemoveBlogComment$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<webinarAction.AddOrRemoveWebinarComment>(webinarAction.ADD_OR_REMOVE_WEBINAR_COMMENT),
		  switchMap((action) => {
			return this.webinarService.addOrRemoveWebinarComment(action.payload).pipe(
			  map(webinarCommentData => new webinarAction.AddOrRemoveWebinarCommentSuccess(webinarCommentData)),
			  catchError(error => of(new webinarAction.AddOrRemoveWebinarCommentFail(error)))
			);
		  })
		)
	  );
	
	  getAllBlogComment$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<webinarAction.GetAllWebinarComments>(webinarAction.GET_ALL_WEBINAR_COMMENTS),
		  switchMap((action) => {
			return this.webinarService.getAllWebinarComment(action.payload).pipe(
			  map(webinarCommentData => new webinarAction.GetAllWebinarCommentsSuccess(webinarCommentData)),
			  catchError(error => of(new webinarAction.GetAllWebinarCommentsFail(error)))
			);
		  })
		)
	  );
	
	  ReportAbuseWebinarComment$ = createEffect(() =>
		this.actions$.pipe(
		  ofType<webinarAction.ReportAbuseWebinarComment>(webinarAction.REPORT_ABUSE_WEBINAR_COMMENT),
		  switchMap((action) => {
			return this.webinarService.ReportAbuseWebinarComment(action.payload).pipe(
			  map(abuseCommentDetails => new webinarAction.ReportAbuseWebinarCommentSuccess(abuseCommentDetails)),
			  catchError(error => of(new webinarAction.ReportAbuseWebinarCommentFail(error)))
			);
		  })
		)
	  );

	// @Effect()
	// apiWebinarListData$ = this.actions$.pipe(
	// 		ofType<webinarAction.GetWebminarList>(webinarAction.GET_WEBINAR_LIST),
	// 		switchMap((action) => {
	// 			return this.webinarService.getWebinarListing(action.payload).pipe(
	// 				map(webinarList => new webinarAction.GetWebminarListSuccess(webinarList)),
	// 				catchError(error => of(new webinarAction.GetWebminarListFail(error)))
	// 			);
	// 		})
	// 	);

    //     @Effect()
    //     apiWebinarByIdData$ = this.actions$.pipe(
    //             ofType<webinarAction.GetWebinarById>(webinarAction.GET_WEBINAR_BY_ID),
    //             switchMap((action) => {
    //                 return this.webinarService.getWebinarListing(action.payload).pipe(
    //                     map(webinarById => new webinarAction.GetWebinarByIdSuccess(webinarById)),
    //                     catchError(error => of(new webinarAction.GetWebinarByIdFail(error)))
    //                 );
    //             })
	// 		);
			

	// 		@Effect()
	// 		AddOrRemoveBlogComment$ = this.actions$.pipe(
	// 			ofType<webinarAction.AddOrRemoveWebinarComment>(webinarAction.ADD_OR_REMOVE_WEBINAR_COMMENT),
	// 			switchMap((action) => {
	// 				return this.webinarService.addOrRemoveWebinarComment(action.payload).pipe(
	// 					map(webinarCommentData => new webinarAction.AddOrRemoveWebinarCommentSuccess(webinarCommentData)),
	// 					catchError(error => of(new webinarAction.AddOrRemoveWebinarCommentFail(error)))
	// 				);
	// 			})
	// 		);

	// 		@Effect()
	// 		getAllBlogComment$ = this.actions$.pipe(
	// 			ofType<webinarAction.GetAllWebinarComments>(webinarAction.GET_ALL_WEBINAR_COMMENTS),
	// 			switchMap((action) => {
	// 				return this.webinarService.getAllWebinarComment(action.payload).pipe(
	// 					map(webinarCommentData => new webinarAction.GetAllWebinarCommentsSuccess(webinarCommentData)),
	// 					catchError(error => of(new webinarAction.GetAllWebinarCommentsFail(error)))
	// 				);
	// 			})
	// 		);


	// 		@Effect()
	// 		ReportAbuseWebinarComment$ = this.actions$.pipe(
	// 			ofType<webinarAction.ReportAbuseWebinarComment>(webinarAction.REPORT_ABUSE_WEBINAR_COMMENT),
	// 			switchMap((action) => {
	// 				return this.webinarService.ReportAbuseWebinarComment(action.payload).pipe(
	// 					map(abuseCommentDetails => new webinarAction.ReportAbuseWebinarCommentSuccess(abuseCommentDetails)),
	// 					catchError(error => of(new webinarAction.ReportAbuseWebinarCommentFail(error)))
	// 				);
	// 			})
	// 		);
}
