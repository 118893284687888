import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OnClickEvent, OnRatingChangeEven, OnHoverRatingChangeEvent } from 'angular-star-rating/star-rating-struct';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
	rate;
	apiDetails: any;
	feedbackComment: string = null;
	token: any;
	UserProfileDetails: any;
	userName: any;
	mobileNo: any;
	userId: any;
	feedsLabels: {};
	selectedLanguage: string;
	dataFromBackPage: any;
	engagementId = null;
	engagementType = null;
	successMessages: {};
	isBlind;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
	constructor(
		public dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private dialogRef: MatDialogRef<FeedbackComponent>,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (data) {
			this.dataFromBackPage = data;
			this.engagementId = this.dataFromBackPage['engagementId'];
			this.engagementType = this.dataFromBackPage['engagementType'];
		}
		dialogRef.disableClose = true;
	}

	ngOnInit() {
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getFeedsLabels)
			.subscribe((labels) => {
				this.feedsLabels = labels;
			});

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.localStorageService.getToken()) {
				this.token = this.encrDecr.get(this.localStorageService.getToken());
				if (this.token) {
					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((data) => {
							this.UserProfileDetails = data;
							this.userName = this.UserProfileDetails.firstName;
							this.mobileNo = this.UserProfileDetails.mobile;
							this.userId = this.UserProfileDetails.userId;
						}
						);
				}
			}
		}
	}

	/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }



	changeRating(doc) {
		this.rate = doc.srcElement.value;
	}


	/**
	 * Created by Akanksha J
	 * For closing the popup
	 */
	cancleButton() {
		this.dialog.closeAll();
	}

	onClickResult: OnClickEvent;
	onHoverRatingChangeResult: OnHoverRatingChangeEvent;
	onRatingChangeResult: OnRatingChangeEven;
	// onclick value of star-rating
	onClick = ($event: OnClickEvent) => {
		this.onClickResult = $event;
	}

	// start rating change value
	onRatingChange = ($event: OnRatingChangeEven) => {
		this.onRatingChangeResult = $event;
	}
	// on hover star rating value
	onHoverRatingChange = ($event: OnHoverRatingChangeEvent) => {
		this.onHoverRatingChangeResult = $event;
	}

	// call hot feedback API when user click on submit feedback
	hotFeedbackApiCall() {
		if (this.userId) {
			if(this.rate || this.feedbackComment !== null || this.feedbackComment !== ''){
				this.store.dispatch(new fromStore.GetHotFeedback({
					url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].addHotFeedback.url,
					method: this.apiDetails['user'].addHotFeedback.method,
					body: {
						userId: this.userId,
						// hotFeedbackValue: this.onClickResult.rating,
						hotFeedbackValue: this.rate,
						mobile: this.mobileNo,
						firstName: this.userName,
						comment: this.feedbackComment,
						engagementId: this.engagementId,
						engagementType: this.engagementType
					}
				}));
	
				this.store.select(fromStore.hotFeedbackStatus)
				.subscribe(statusCode => {
					if (statusCode === 200) {
						this.store.select(fromStore.hotFeedbackMessage)
						.subscribe(message => {
							if(message){
								this.store.select(fromStore.getSuccessMessages)
								.subscribe(res => {
									this.successMessages = res;
									this.toastr.success(this.successMessages[this.selectedLanguage][message]);
								});
							}
						});
					}
				})
				this.dialog.closeAll();
			}else{
				this.selectedLanguage === 'en' ? alert("Comment or give start rating") : alert("कमेंट द्या किंवा स्टार रेटिंग द्या")
			}
		}

	}

}
