import * as fromDepartments from '../action/departments.action';

export interface DepartmentsState {
	departmentServerErrorMessage: string;
	result: {};
	typeResult: [];
	subTypeResult: [];
	FAQResult: [];
	isDepartmentStateLoading: boolean;
	isDepartmentStateLoaded: boolean;
	errorMessage: string;
	statusCode: number;
}

export const initialState: DepartmentsState = {
	result: {},
	typeResult: [],
	subTypeResult: [],
	FAQResult: [],
	isDepartmentStateLoading: false,
	isDepartmentStateLoaded: false,
	errorMessage: null,
	statusCode: 0,
	departmentServerErrorMessage: null,
};

export function reducer(
	state = initialState,
	action: fromDepartments.departmentsAction
): DepartmentsState {
	switch (action.type) {
		case fromDepartments.GET_DEPARTMENT_TYPE_DETAILS: {
			return {
				...state,
				isDepartmentStateLoading: true,
				isDepartmentStateLoaded: false,
				errorMessage: null
			};
		}

		case fromDepartments.GET_DEPARTMENT_TYPE_DETAILS_SUCCESS: {
			let code = action.payload.code;
			let typeResult = state.typeResult;
			let isDepartmentStateLoading = state.isDepartmentStateLoading;
			let isDepartmentStateLoaded = state.isDepartmentStateLoaded;
			let errorMessage = null;
			let statusCode = code;
			switch (code) {
				case 200:
					typeResult = action.payload.result;
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					statusCode = action.payload.code;
					break;
				case 498:
					statusCode = 498;
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
				case 499:
					statusCode = 499;
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
			}
			return {
				...state,
				typeResult,
				isDepartmentStateLoading,
				isDepartmentStateLoaded,
				errorMessage,
				statusCode
			};
		}

		case fromDepartments.GET_DEPARTMENT_TYPE_DETAILS_FAIL: {
			let statusCode = action.payload.status;
			let departmentServerErrorMessage = state.departmentServerErrorMessage
			if (statusCode == 401 || statusCode == 0) {
				departmentServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				departmentServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				departmentServerErrorMessage,
				isDepartmentStateLoading: false,
				isDepartmentStateLoaded: true,
				statusCode
			};
		}


		case fromDepartments.GET_DEPARTMENT_SUBTYPE_DETAILS: {
			return {
				...state,
				isDepartmentStateLoading: true,
				isDepartmentStateLoaded: false,
				errorMessage: null
			};
		}

		case fromDepartments.GET_DEPARTMENT_SUBTYPE_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let subTypeResult = state.subTypeResult;
			let isDepartmentStateLoading = state.isDepartmentStateLoading;
			let isDepartmentStateLoaded = state.isDepartmentStateLoaded;
			let errorMessage = null;
			switch (statusCode) {
				case 200:
					subTypeResult = action.payload.result;
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					break;
				case 604:
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
				case 498:
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
			}
			return {
				...state,
				subTypeResult,
				isDepartmentStateLoading,
				isDepartmentStateLoaded,
				errorMessage,
				statusCode
			};
		}

		case fromDepartments.GET_DEPARTMENT_SUBTYPE_DETAILS_FAIL: {
			let statusCode = action.payload.status;
			let departmentServerErrorMessage = state.departmentServerErrorMessage
			if (statusCode == 401 || statusCode == 0) {
				departmentServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				departmentServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				departmentServerErrorMessage,
				isDepartmentStateLoading: false,
				isDepartmentStateLoaded: true
			};
		}

		case fromDepartments.GET_DEPARTMENT_FAQ_DETAILS: {
			return {
				...state,
				isDepartmentStateLoading: true,
				isDepartmentStateLoaded: false,
				errorMessage: null
			};
		}

		case fromDepartments.GET_DEPARTMENT_FAQ_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let FAQResult = state.FAQResult;
			let isDepartmentStateLoading = state.isDepartmentStateLoading;
			let isDepartmentStateLoaded = state.isDepartmentStateLoaded;
			let errorMessage = null;
			switch (statusCode) {
				case 200:
					FAQResult = action.payload.result;
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					break;
				case 604:
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
				case 498:
					isDepartmentStateLoading = false;
					isDepartmentStateLoaded = true;
					errorMessage = action.payload.message;
					break;
			}
			return {
				...state,
				FAQResult,
				isDepartmentStateLoading,
				isDepartmentStateLoaded,
				errorMessage,
				statusCode
			};
		}

		case fromDepartments.GET_DEPARTMENT_FAQ_DETAILS_FAIL: {
			let statusCode = action.payload.status;
			let departmentServerErrorMessage = state.departmentServerErrorMessage
			if (statusCode == 401 || statusCode == 0) {
				departmentServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				departmentServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				departmentServerErrorMessage,
				isDepartmentStateLoading: false,
				isDepartmentStateLoaded: true
			};
		}
	}
	return state;
}

export const getDepartmentTypeResult = (state: DepartmentsState) => state.typeResult;
export const getDepartmentSubTypeResult = (state: DepartmentsState) => state.subTypeResult;
export const getDepartmentFAQResult = (state: DepartmentsState) => state.FAQResult;
export const getDepartmentsStateLoading = (state: DepartmentsState) => state.isDepartmentStateLoading;
export const getDepartmentsStateLoaded = (state: DepartmentsState) => state.isDepartmentStateLoaded;
export const getDepartmentsErrorMessage = (state: DepartmentsState) => state.errorMessage;
export const getDepartmentTypeStatusCode = (state: DepartmentsState) => state.statusCode;
export const getDepartmentServerErrorMessage = (state: DepartmentsState) => state.departmentServerErrorMessage;


