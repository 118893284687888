import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/localstorage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
content:any;
type:any;
  constructor(
    private localStorageService:LocalStorageService,
    private activeParams:ActivatedRoute
  
  ) { 
   
  }

  ngOnInit() {
    this.activeParams.params.subscribe((params) => {
      this.type = params.type;
      this.content = this.localStorageService.getContentEnglishMarathi(this.type);
    });
  }
  saveConetntToLocalStorage(){
    this.localStorageService.setContentEnglishMarathi(this.type,this.content)
  }
}
