import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../../../cep/store/reducers.index';
import * as  fromDeathDateReducer from  "../reducer/death-date.reducer";

export const DeathState = createSelector(
	fromFeature.getCEPState,
	(state: fromFeature.CEPState) => state.deathDate
);

export const getDathDateListSelector = createSelector(
	DeathState,
	fromDeathDateReducer.getDeathDateList
);
