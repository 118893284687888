import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Images } from 'src/app/shared/config/images-config';

@Component({
	selector: 'app-tourism-information',
	templateUrl: './tourism-information.component.html',
	styleUrls: ['./tourism-information.component.scss']
})
export class TourismInformationComponent implements OnInit, OnDestroy, AfterViewInit {
	selectedLanguage: string;
	isLoading: boolean;
	images = Images;
	serverErrorMessage: any;
	isLoaded = false;
	tourismData: any;
	apiDetails: {};
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	aboutUsLabels: any;
	serverMessage: string;
	isServerMessage: boolean;

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		public dialog: MatDialog,
	) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.dispatch(new fromStore.GetTourismDetails({
			url: this.apiDetails['applicationConfigurations'].base_url +
				this.apiDetails['applicationConfigurations']['aboutUs'].getTourismInfo.url,
			method: this.apiDetails['applicationConfigurations']['aboutUs'].getTourismInfo.method
		}));
	}

	/**
	 * angular method which gets called on page initialization
	 */
	ngOnInit() {

		// server error handling
		this.store.select(fromStore.getServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError == 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverError;
						if (this.serverMessage) {
							this.isServerMessage = true;
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
			});

		this.store.select(fromStore.getAboutUsLabels)
			.subscribe(response => {
				this.aboutUsLabels = response;
			});

		this.store.select(fromStore.getIsTourismDetailsLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});

		this.store.select(fromStore.getTourismDetailsServerErrorMessage)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					this.serverErrorMessage = serverErrorMessage;
					console.error(this.serverErrorMessage);
				}
			});

		this.store.select(fromStore.getIsTourismDetailsLoaded)
			.subscribe(loaded => {
				this.isLoaded = loaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getTourismDetailsResult)
						.subscribe((res) => {
							this.tourismData = res['tourismInfo'];
						});
				}
			});
	}


	ngOnDestroy() {
		this.serverErrorMessage = null;
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

}
