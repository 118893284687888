/**
 * Created by: Kiran G
 * Updated by: Akanksha J
 * Component: Local storage service
 * Updated date: 20/12/2019
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  userId = 'userId';
  userToken = 'userToken';
  selectedLanguage = 'selectedLanguage';
  editorContentEnglish = 'editorContentEnglish';
  editorContentMarathi = 'editorContentMarathi';
  isBlind = 'false';
  setToken(token) {
    localStorage.setItem(this.userToken, token);
  }
  getToken() {
    return localStorage.getItem(this.userToken);
  }

  setUserId(userId) {
    localStorage.setItem(this.userId, userId);
  }

  setToBlind(isBlind){
    localStorage.setItem(this.isBlind, isBlind);
  }
  getIsBlind(){
    return localStorage.getItem(this.isBlind);
  }

  getUserId() {   
    return localStorage.getItem(this.userId);
  }
  getContentEnglishMarathi(type) {
    
    if (type == 'en')
    return localStorage.getItem(this.editorContentEnglish);
  else if (type == 'mar')
  return localStorage.getItem(this.editorContentMarathi);
    
  }
  setContentEnglishMarathi(type, content) {
    if (type == 'en')
      localStorage.setItem(this.editorContentEnglish, content);
    else if (type == 'mar')
      localStorage.setItem(this.editorContentMarathi, content);
  }
  clearLocalStorage() {
    localStorage.clear();
  }
  getItem(item: string){
    return localStorage.getItem(item);
  }

  setItem(item: string, value: string) {  
    localStorage.setItem(item, value);
  }
  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor() { }
}