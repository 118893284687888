import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase';

@Injectable({
	providedIn: 'root'
})
export class AuthServiceTwitter {

	constructor(
		public afAuth: AngularFireAuth, // Inject Firebase auth service
	) { }

	// Sign in with Twitter
		TwitterAuth() {
			return new Promise<any>((resolve, reject) => {
			  let provider = new firebase.auth.TwitterAuthProvider();
			
			  this.afAuth
			  .signInWithPopup(provider)
			  .then(res => {
				resolve(res);
			  }, err => {
				
				reject(err);
			  })
			})
	}

	// Auth logic to run auth providers
		AuthLogin(provider) {
		return this.afAuth.signInWithPopup(provider)
			.then((result) => {
			}).catch((error) => {
			});
	}

}

