/**
 * Created by: Kiran G
 * Updated by: Akanksha J
 * Component: Encryption decryption file
 * Updated date: 20/12/2019
 */

import { Injectable } from '@angular/core';
// import * as CryptoJS from '@angularjs-crypto';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor() { }

  //The set method is use for encrypt the value.

  set(value) {
    var key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
    var iv = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        iv: iv
      });

    return encrypted.toString();
  }


  //The get method is use for decrypt the value.
  get(value) {
    var key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
    var iv = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
    var decrypted = CryptoJS.AES.decrypt(value, key,
      {
        iv: iv
      });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
};