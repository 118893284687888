
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { ObjectInterface } from '../../../models/global-interface';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { FeedbackComponent } from '../../feedback/feedback.component';
import { ReportAbusePopupComponent } from  '../../report-abuse-popup/report-abuse-popup.component';
import { RemoveCartDataFromProductPopupComponent } from '../remove-cart-data-from-product-popup/remove-cart-data-from-product-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Location } from '@angular/common';
import { screenName,clickedOn } from 'src/app/shared/config/google-analytics-constants';

@Component({
	selector: 'app-deal-detail-page',
	templateUrl: './deal-detail-page.component.html',
	styleUrls: ['./deal-detail-page.component.scss']
})
export class DealDetailPageComponent implements OnInit {
	/**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * api details from dynamic api calling
	 */
	apiDetails: ObjectInterface;
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * category services response
	 */
	dealDetail: ObjectInterface;
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * merchant user id
	 */
	merchantUserId: any;
	/**
	 * Image path
	 */
	value: any;
	/**
	 * Variable for Feature
	 */
	dealDetailFeature: any;
	/**
	 * Share Url variable for social sharing
	 */
	shareUrl: any;
	/**
	 * Store cart value
	 */
	mycart: {};
	/**
	 * Variable for label
	 */
	dealDetailLabels: {};
	dealTermsAndConditions;
	/**
	 * Variable for data not found
	 */
	dataNotFoundMsg: any;
	result:{};
	servicesResponse: any;
	successMessages: any;
	errorMessage: {};
	isQuantity= false;
	landingLables: {};
	isBlind;
	/**
	 * Angular method which runs on component initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private deviceService: DeviceDetectorService,
		private domSanitizer: DomSanitizer,
		private googleAnalyticsService: GoogleAnalyticsService,
		private apiCallService: ApiCallService,
		private location:Location
	) {
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
	}

	/**
	 * Angular method which runs on page initialization
	 */
  	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS,
			{
				log: clickedOn.LOG_SCREEN,
				screenName: screenName.DEAL_DEATILS + "_MOUNT",
				screenStartTime: new Date(),
				isScroll: false,
				screenEndTime: "",
				scrollValue: "",
			}
		)

    	// token from localstorage
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on Deal by ' + this.userId, 'userLabel', 1);
				}
		});

		this.store.select(fromStore.getMoreServicesLabels)
					.subscribe(labels => {
						this.dealDetailLabels = labels;
				
					});

		// parameter from web path
		this.activeRoute.params.subscribe((param) => {
		
			
			this.data = param['engagementId'];
			this.merchantUserId = param['merchantUserId'];
			if (this.data) {
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
					this.store.dispatch(new fromStore.GetDealDetailByDealId({
						url: this.apiDetails.userEngagement.base_url +
							this.apiDetails.userEngagement.approval.getDealsDetails.url,
						method: this.apiDetails.userEngagement.approval.getDealsDetails.method,
						body: {
							engagementId : this.data,
							userId : this.userId
						}
					}));
				}
			}
		});
		this.store.select(fromStore.getLandingScreenLabels)
		.subscribe((labels) => {
		  this.landingLables = labels;
		});

		// this.store.select(fromStore.getAllEngagementByMerchantIdStatusCode)
		// 		.subscribe(code => {
		// 			if (code === 200) {
		// 				this.store.select(fromStore.getAllEngagementByMerchantIdResult)
		// 				.subscribe(result => {
		// 					this.result = result;
		// 				});
		// 			} else if (code === 500) {
		// 				this.store.select(fromStore.getAllEngagementByMerchantIdSuccessMessage)
		// 				.subscribe(errorMessages => {
		// 					this.dataNotFoundMsg = errorMessages;
		// 				});
		// 			} 
		// 		});

		// this.store.select(fromStore.isAddItemToCartLoading)
		// .subscribe(loading => {
		// 	this.isLoading = loading;
		// });

		//add into cart

		// this.store.select(fromStore.getSelectedLanguage)
		// .subscribe(lang => {
		// 	this.selectedLanguage = lang;
		// 	if (this.selectedLanguage) {
		// 		this.store.select(fromStore.isAddItemToCartLoaded)
		// 		.subscribe(loaded => {
		// 			if (loaded) {
		// 				this.store.select(fromStore.addItemToCartStatusCode)
		// 				.subscribe(code => {
		// 					if (code === 200) {
		// 						this.store.select(fromStore.addItemToCartResult)
		// 						.subscribe(result => {
		// 							this.mycart = result;
		// 							this.toastr.success('Deal added to the cart successfully')
		// 						});
		// 					}
		// 					//  else {
		// 					// this.store.select(fromStore.getErrorMessages)
		// 					// 	.subscribe(errorMessages => {
		// 					// 		if(errorMessages) {
		// 					// 			
		// 					// 			console.error(errorMessages[this.selectedLanguage].code);
		// 					// 		}
									
		// 					// 	});
		// 					// }
		// 				});
		// 			}
		// 		});
		// 	}
		// });
		// server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
		.subscribe((serverErrorMessage) => {
			if (serverErrorMessage != null) {
				if (serverErrorMessage === 'InvalidCredentials') {
				this.router.navigate(['/Login/login']);
				} else {
				this.serverMessage = serverErrorMessage;
				this.isServerErrorMessage = true;
				console.error(this.serverMessage);
				}
			}
		});

		this.store.select(fromStore.getSuccessMessages)
		.subscribe(res => {
			this.successMessages = res;
		});

		this.store.select(fromStore.isGetDealDetailByDealIdLoading)
		.subscribe(loading => {
			this.isLoading = loading;
		});

		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
			if (this.selectedLanguage) {
				this.store.select(fromStore.isGetDealDetailByDealIdLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getDealDetailByDealIdStatusCode)
						.subscribe(code => {
							if(code) {
								if (code === 200) {
									this.store.select(fromStore.getDealDetailByDealIdResult)
									.subscribe(result => {
										this.dealDetail = result;
										if(this.dealDetail.quantity == 0){
											this.isQuantity = true;
										}
									
										this.dealDetailFeature = this.domSanitizer.bypassSecurityTrustHtml(this.dealDetail[this.selectedLanguage].feature);
									
									});
								} else if (code === 500) {
									this.store.select(fromStore.getDealDetailByDealIdSuccessMessage)
									.subscribe(errorMessages => {
										this.dataNotFoundMsg = errorMessages;
									});
								} else {
									this.store.select(fromStore.getErrorMessages)
									.subscribe(errorMessages => {
										if(errorMessages)
										console.error(errorMessages[this.selectedLanguage].code);
									});
								}
							}
						});
					}
				});
			}
		});
	  }
	  
	
/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;	
		return this.splitSentance(span.textContent || span.innerText)
	  };

	  /**
	 * Created by Vinita Sharma
	 * to split the paragraph in sentances
	 */
	splitSentance(sen){
		if(!sen.match(/[^\.!\?]+[\.!\?]+/g)){		
			return [sen]
		}else{
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
			speechSynthesis.cancel();
			var newmessage = this.extractContent(textToSpeak)	
			var voices = speechSynthesis.getVoices();
			
			for (let sentance = 0; sentance < newmessage.length; sentance++) {
				var message = new SpeechSynthesisUtterance(newmessage[sentance]);
				message.voice = voices[9];
				message.lang = 'hi-IN';
				message.volume = 1;
				speechSynthesis.speak(message);
			}
		}
	}

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	stopSpeaking(){
		speechSynthesis.cancel();
	  }


	   /**
	 * Created by Akanksha J
	 * for reporting news as abuse
	 */
	REPORT_ABUSE() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS, {
			log: clickedOn.REPORT_ABUSE,
			screenName: screenName.DEAL_DEATILS + "_SCREEN",
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REPORT_ABUSE,
			time: new Date(),
			isInput: false,
			contentId:this.dealDetail.engagementId
		  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.dealDetail['timelineId'],
				engagementType: this.dealDetail['engagementType'],
				engagementId: this.dealDetail['engagementId']
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
  }
  
	/**
	 * created by neha
	 * to open selected an image 
	 */
	selectImage(path){
		this.value = path;
	}

	goBack(){
		this.location.back()
	}

	saveDeal(){
		
		this.router.navigate(['/cep/my-deals'])
	}
	/**
	 * Created by Akanksha J
	 * FOr redirecting to merchant details screen
	 */
	openMerchantDetail() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS, {
			log: clickedOn.MERCHANT_DETAILS,
			screenName: screenName.DEAL_DEATILS + "_SCREEN",
			clickedOn: clickedOn.VIEW,
			title: clickedOn.MERCHANT_DETAILS,
			time: new Date(),
			isInput: false,
			merchantId:this.dealDetail.userId
		  })
		this.router.navigate(['/cep/merchant-details/', this.dealDetail.userId]);
	}
  
	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
	
		
		var url = this.shareUrl + "/cep/feeds/product/" + this.dealDetail.engagementId
		var metaTag  = {
			socialTitle: this.dealDetail.en.productName,
			socialImageLink: this.dealDetail.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	/** 
	 * created by neha
	 * for share
	 */
	clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of article', 'Click Event', 1);
	}

	/**
	 * Created b y neha
	 * Add to cart
	 */
	async addToCart(){
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS, {
			log: clickedOn.ADD_TO_CART,
			screenName: screenName.DEAL_DEATILS + "_SCREEN",
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.ADD_TO_CART,
			time: new Date(),
			isInput: false,
			contentId:this.dealDetail.engagementId
		  })
		if (Object.keys(this.apiDetails).length > 0) {
			await this.store.dispatch(new fromStore.AddItemToCart({
				url: this.apiDetails.user.base_url +
				this.apiDetails.user.addItemToTheCart.url,
				method: this.apiDetails.user.addItemToTheCart.method,
				body:  {
					userId: this.userId,
					engagementId: this.dealDetail.engagementId,
					quantity: 1
				}
			}));
		 this.store.select(fromStore.isAddItemToCartLoaded)
			.subscribe(loaded => {
				if (loaded) {
				this.store.select(fromStore.addItemToCartStatusCode)
				.subscribe( (code:any) => {
					if(code) {
						if (code === 200) {
							this.store.select(fromStore.addItemToCartSuccessMessage)
								.subscribe(msg => {
									if(msg) {
										// this.mycart = result;
										this.toastr.success(this.successMessages[this.selectedLanguage][msg]);
										// this.toastr.success(msg)
									}
									
								});
						} else if (code == 404) {
								this.dialog.open(RemoveCartDataFromProductPopupComponent, {
								data: {
									disableClose: true,
									maxWidth: '100vw',
									autoFocus: false,
									label: this.data
								}
							});
						}else if( code === "You can't purchase more deal" ){
							this.selectedLanguage === 'en' ? this.toastr.error("You can't purchase more deal")
							: this.toastr.error("तुम्ही अधिक डील खरेदी करू शकत नाही")
						}	 else if (code === 101 || code === 100) {
							this.store.select(fromStore.getErrorMessages)
							.subscribe(res => {
								this.errorMessage = res;
								console.error(this.errorMessage[this.selectedLanguage][code]);
							});
						}
					}
				});
			}
		});
		}
	}

	/**
	 * created by neha
	 * Mark As a favourite
	 */
	markAsFavourite(){
		if(this.dealDetail.favourite == false){
			this.dealDetail.favourite = true;
			this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS, {
				log: clickedOn.LIKE,
				screenName: screenName.DEAL_DEATILS + "_SCREEN",
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.dealDetail.engagementId
			  })
		}
		else{
			this.dealDetail.favourite = false;
			this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.DEAL_DEATILS + "_SCREEN",
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.dealDetail.engagementId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails.shareUrl;
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.dealDetail.engagementId,
						engagementType: this.dealDetail.engagementType,
					}
				}));
			}
		}

	}
	/**
	 * angular method which gets called on page view initialization
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
	/**
	 * created by Akshata c
	 * feedback popup
	 */
	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_DEATILS,
			{
				log: clickedOn.LOG_SCREEN,
				screenName: screenName.DEAL_DEATILS + "_UNMOUNT",
				screenStartTime: "",
				isScroll: false,
				screenEndTime: new Date(),
				scrollValue: "",
			}
		)
	}


}
