import { Component, OnInit, ChangeDetectorRef, Input, Pipe, OnDestroy, AfterContentChecked, AfterViewInit } from '@angular/core';
import * as fromStore from '../../../cep/store/index';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { Images } from '../../config/images-config';
import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectInterface } from '../../models/global-interface';
import { ToastrService } from 'ngx-toastr';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-details-page-competition',
	templateUrl: './details-page-competition.component.html',
	styleUrls: ['./details-page-competition.component.scss']
})
export class DetailsPageCompetitionComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	@Input() competitionData: any;
	public feedsData;
	public competitionDate;
	apiDetails: ObjectInterface;
	timelineId: any;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoaded = false;
	isLoading = false;
	selectedLanguage: string;
	landingLables: ObjectInterface;
	feedsDate: string;
	postDate: string;
	favorite: string;
	userId: string;
	token: string;
	feedFilterLabels: ObjectInterface;
	feedLabels: ObjectInterface;
	images = Images;
	startDate: string;
	endDate: string;
	lat: any;
	lng: any;
	latlag: any;
	latitude: any;
	longitude: any;
	feedStatusCode: number;
	shareUrl: any;
	errMessage: any;
	feedsContent: any;
	/**
	 * Variable for image
	 */
	imageUrl: {};
	startDateRegistration: string;
	registrationEndDate: string;
	userName: any;
	mobile: any;
	citizenProfilePic: any;
	isRegistrationLoading: boolean;
	result: {};
	successMessages: {};
	isSubmitLoading: boolean;
	fileData: File;

	constructor(
		private activeParams: ActivatedRoute,
		private store: Store<fromStore.CEPState>,
		private changeDetectRef: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private domSanitizer: DomSanitizer,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private apiCallService: ApiCallService,
		private router: Router,
		private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService) { }

	ngOnInit() {

		this.googleAnalyticsService.eventEmitter(
			screenName. COMPETITION_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.COMPETITION_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.activeParams.params.subscribe((params) => {
			this.timelineId = params.timelineId;
			this.store.dispatch(new fromStore.CheckTimeline({
				timelineId:this.timelineId
			}))
		})

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on competition by ' + this.userId, 'userLabel', 1);
				} else {
					this.userId = null;
				}
			});
			this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {
				this.userName = response['firstName'];
				this.mobile = response['mobile'];
				this.citizenProfilePic = response['profilePhoto'];
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
				
					this.store.select(fromStore.isTimelineChnaged)
			.subscribe((isTimeline) => {
					if(isTimeline && Object.keys(this.apiDetails).length > 0){
						this.shareUrl = this.apiDetails.shareUrl;

					this.store.dispatch(new fromStore.GetEngeagementDetails({
						url: this.apiDetails.userEngagement.base_url +
							this.apiDetails.userEngagement.timeline.getEngagement.url,
						method: this.apiDetails.userEngagement.timeline.getEngagement.method,
						body: {
							timelineId: this.timelineId,
							userId: this.userId
						}
					}))
					window.scrollTo(0, 0);
				}
				})
		

		this.store
			.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoading => {
				this.isLoading = isLoading;
				if (this.isLoading) {
					this.spinner.show();
				} else {
					this.spinner.hide();
				}
			});

		this.store.select(fromStore.isDetailsScreenLoading)
			.subscribe(isLoaded => {
				this.isLoaded = isLoaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getFeedsServerErrorMessage)
						.subscribe((error) => {
							this.errMessage = error;
							if (this.errMessage) {

							}
						});

					this.store.select(fromStore.feedDetailsResult)
						.subscribe((feedsData) => {
							this.feedsData = feedsData;
							if (Object.keys(this.feedsData).length !== 0) {
								this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
								if (this.feedsData.favourite) {
									this.favorite = 'favorite';
								} else {
									this.favorite = 'favorite_border';
								}
							}
							this.startDate = new Date(this.feedsData.startDate).toDateString();
							this.endDate = new Date(this.feedsData.endDate).toDateString();
							this.startDateRegistration = new Date(this.feedsData.registrationStartDate).toDateString();
							this.registrationEndDate = new Date(this.feedsData.registrationEndDate).toDateString();
							this.postDate = new Date(this.feedsData.updatedAt).toDateString();
							this.latlag = this.feedsData.geometry;
							if (this.latlag) {
								this.latitude = this.latlag.coordinates[0];
								this.longitude = this.latlag.coordinates[1];
							}
						});
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.feedFilterLabels = labels;
					});
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});

		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on competition', 'userLabel', 1);
	}

/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return this.splitSentance(span.textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim() || span.innerText)
	  };

	  /**
	 * Created by Vinita Sharma
	 * to split the paragraph in sentances
	 */
	splitSentance(sen){
		
		if(!sen.match(/[^\.!\?]+[\.!\?]+/g)){
			return [sen]
		}else{
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak, t2){		
				
		speechSynthesis.cancel();
		var newmessage = this.extractContent(textToSpeak)	
		var voices = speechSynthesis.getVoices();
		newmessage.unshift(t2);

		
		for (let sentance = 0; sentance < newmessage.length; sentance++) {			
			var message = new SpeechSynthesisUtterance(newmessage[sentance]);
			message.voice = voices[9];
			message.lang = 'hi-IN';
			message.volume = 1;
			speechSynthesis.speak(message);
		}
	}


	/**
	 * Created by Akanksha J
	 * for reporting competition as abuse
	 */
	REPORT_ABUSE() {
		this.googleAnalyticsService.eventEmitter(screenName.COMPETITION_DETAILS, {
			log: clickedOn.REPORT_ABUSE,
			screenName: screenName.COMPETITION_DETAILS + "_SCREEN",
			SECTION: screenName.COMPETITION_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REPORT_ABUSE,
			time: new Date(),
			isInput: false,
			contentId:this.competitionData.timelineId
		  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'competiton',
				engagementId: this.feedsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	
	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
	unMarkFavorite() {
		if (this.feedsData.favourite) {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: false });
			this.favorite = 'favorite_border';
			this.googleAnalyticsService.eventEmitter(screenName.COMPETITION_DETAILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.COMPETITION_DETAILS + "_SCREEN",
				SECTION: screenName.COMPETITION_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.competitionData.timelineId
			  })
		} else {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: true });
			this.favorite = 'favorite';
			this.googleAnalyticsService.eventEmitter(screenName.COMPETITION_DETAILS, {
				log: clickedOn.LIKE,
				screenName: screenName.COMPETITION_DETAILS + "_SCREEN",
				SECTION: screenName.COMPETITION_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.competitionData.timelineId
			  })
		}
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails.shareUrl;
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails.user.base_url +
						this.apiDetails.user.addToFavourite.url,
					method: this.apiDetails.user.addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.feedsData.engagementId,
						engagementType: 'competition'
					}
				}));
			}
		}
	}

	/**
	 * Created by Ajay C'
	 * For redirecting to google map to show competition direction
	 */
	redirectToGoogleMap() {
		window.open('http://maps.google.com?q=' + this.latitude + ',' + this.longitude + '');
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url =  this.shareUrl + '/cep/feeds/competition/' + this.feedsData.timelineId
		var metaTag  = {
			socialTitle: this.feedsData.en.heading,
			socialImageLink: this.feedsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
	/**
	 * array initialization for images
	 */
	urls = new Array<string>();

	/**
	 * Created by Neha R
	 * For displaying image and store object API call
	 */
	preview(event) {
		this.fileData = <File>event.target.files[0].name;
		const fdImages = new FormData();
		this.urls = [];
		fdImages.append('userId', this.userId);
		fdImages.append('type', 'image');

		let files = event.target.files;
		var i = 0;
		if (files && fdImages) {
			for (let file of files) {
				let reader = new FileReader();
				reader.onload = (e: any) => {
					var cu = (i + 1) + '';
					fdImages.append(cu, file);
					this.store.dispatch(new fromStore.UpdateProfilePhoto(
						{
							url: this.apiDetails['objectStorage'].base_url +
								this.apiDetails['objectStorage'].storeObject.url,
							method: this.apiDetails['objectStorage'].storeObject.method,
							body: fdImages,
							type:'competitionContent'
						}));
					this.urls.push(e.target.result);
					i++;
				};
				reader.readAsDataURL(file);
			}
		}
	}

	// /**
	//  * Created by Neha R
	//  * For removing image from array
	//  */
	// removeImage(i) {
	// 	this.urls.splice(i, 1);
	// 	this.imageUrl = this.urls;
	// }

	/**`
	 * created by neha
	 * api call of register competition
	 */
	registerCompetition(){
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
					this.store.dispatch(new fromStore.RegistrationCompetition({
						url: this.apiDetails.userEngagement.base_url +
							this.apiDetails.userEngagement.competition.registrationCompetition.url,
						method: this.apiDetails.userEngagement.competition.registrationCompetition.method,
						body: {
							userId: this.userId,
							mobile: this.mobile,
							firstName: this.userName,
							profilePhoto: this.citizenProfilePic,
							engagementId: this.feedsData.engagementId
						}
					}));
				}

			});

		this.store.select(fromStore.isRegistrationCompetitionLoading)
		.subscribe(loading => {
			this.isRegistrationLoading = loading;
		});

		this.store.select(fromStore.isRegistrationCompetitionLoaded)
		.subscribe(loaded => {
			if (loaded) {
				this.store.select(fromStore.RegistrationCompetitionStatusCode)
				.subscribe(code => {
					if (code === 200) {
						this.store.select(fromStore.RegistrationCompetitionErrorMessage)
						.subscribe(response => {
							this.store.select(fromStore.getSuccessMessages)
								.subscribe(res => {
									if (res) {
										this.successMessages = res;
										this.toastr.success(this.successMessages[this.selectedLanguage][response]);
										this.router.navigateByUrl('/cep/feeds');
									}
									else{
										this.successMessages = res;
										console.error(this.successMessages[this.selectedLanguage][response]);
									}
								});
						});
					}  
				});
			}
		});
	}

	/**
	 * Created by neha
	 * To submit call
	 */
	submitCompetition(){
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
					if(this.urls){
						this.store.dispatch(new fromStore.SubmitCompetition({
							url: this.apiDetails.userEngagement.base_url +
								this.apiDetails.userEngagement.competition.submitCompetition.url,
							method: this.apiDetails.userEngagement.competition.submitCompetition.method,
							body: {
								userId: this.userId,
								submission: this.urls,
								engagementId: this.feedsData.engagementId
							}
						}));
					}
				}
			});
		this.store.select(fromStore.isSubmitCompetitionLoading)
		.subscribe(loading => {
			this.isSubmitLoading = loading;
		});

		this.store.select(fromStore.isSubmitCompetitionLoaded)
		.subscribe(loaded => {
			if (loaded) {
				this.store.select(fromStore.SubmitCompetitionStatusCode)
				.subscribe(code => {
					if (code === 200) {
						this.store.select(fromStore.SubmitCompetitionErrorMessage)
						.subscribe(response => {
							this.store.select(fromStore.getSuccessMessages)
								.subscribe(res => {
									if (res) {
										this.successMessages = res;
										this.toastr.success(this.successMessages[this.selectedLanguage][response]);
										this.router.navigateByUrl('/cep/feeds');
									}
									else{
										this.successMessages = res;
										console.error(this.successMessages[this.selectedLanguage][response]);
									}
								});
						});
					}  
				});
			}
		});

	}

	ngOnDestroy() {

		this.googleAnalyticsService.eventEmitter(
			screenName.COMPETITION_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.COMPETITION_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
		// if (this.userId && this.feedsData.feedback==false) {
		// 	const dialogRef = this.dialog.open(FeedbackComponent,
		// 		{
		// 			data: {
		// 				engagementId: this.feedsData.engagementId,
		// 				engagementType: this.feedsData.engagementType
		// 			},
		// 			maxHeight: '100vh',
		// 			maxWidth: '100vw',
		// 			autoFocus: false
		// 		});
		// }
	}


	async clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of competition', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.COMPETITION_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/competition/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}
	}
}
