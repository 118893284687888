import * as fromContactUs from '../../store/action/contact-us.action';

export interface ContactUsState {
	contactUsServerErrorMessage: any;
	result: {};
	isLoading:boolean;
	isLoaded:boolean;
}

export const initialState: ContactUsState = {
	result: {},
	isLoading:false,
	isLoaded:false,
	contactUsServerErrorMessage: null,
};

export function reducer(
	state = initialState,
	action: fromContactUs.contactUsAction
): ContactUsState {
	switch (action.type) {
		case fromContactUs.GET_CONTACT_US_DETAILS: {

			return {
				...state,
				isLoading:true,
				isLoaded:false
			};
		}

		case fromContactUs.GET_CONTACT_US_DETAILS_SUCCESS: {
			let statusCode = action.payload.code;
			let result = state.result;
			let isLoaded = state.isLoaded;
			let isLoading = state.isLoading;
			if (statusCode == 200) {
				result = action.payload.result;
				isLoaded = true;
				isLoading = false;
			}
			return {
				...state,
				result,
				isLoading,
				isLoaded
			};
		}
		case fromContactUs.GET_CONTACT_US_DETAILS_FAIL: {
			let statusCode = action.payload.status
			let contactUsServerErrorMessage = state.contactUsServerErrorMessage
			if(statusCode == 401 || statusCode == 0) {
				contactUsServerErrorMessage = action.payload.statusText;
			}
			else if (statusCode != 401 && statusCode != 0) {
				contactUsServerErrorMessage = action.payload.error.message;
			}
			return {
				...state,
				contactUsServerErrorMessage
			};
		}

	}
	return state;
}

export const getContactUsResult = (state: ContactUsState) => state.result;
export const getIsContactUsLoading = (state: ContactUsState) => state.isLoading;
export const getIsContactUsLoaded = (state: ContactUsState) => state.isLoaded;
export const getcontactUsServerErrorMessage = (state: ContactUsState) => state.contactUsServerErrorMessage;